// Tracking Dashboard

.invite-upload {
    > .card-header {
        .back-arrow {
            top: -4px;
        }
    }
}

.tracker-cards {
    background: rgba(163, 219, 89, 0.12) !important;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
    .form-label {
      color: #5e5873;
    }
    .icon {
        width: 50px;
        img {
            max-width: 50px;
        }
    }    
    .card_title {
        width: calc(100% - 50px);
        padding-left: 20px;
    }
}
.tracking-breadcrumb {
    display: inline-block;    
    .cursor-pointer {
       text-decoration: underline;
    }
    .active {
        background-color: var(--primaryColor);
        border: 1px solid var(--primaryColor);
        padding: 4px 10px 7px 30px;
        margin-top: 20px;
        margin-right: 33px;
        margin-bottom: 8px;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        display: inline-flex;
        min-width: 190px;
        max-width: 190px;
        justify-content: center;
        position: relative;
        color: #000000;
        font-size: 14px;
        &::before {
            content: "";
            position: absolute;
            top: -2px;
            width: 0px;
            height: 0px;
            left: -1px;
            border: 18px solid transparent;
            border-left: 20px solid #f7f6f0;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0px;
            width: 0px;
            height: 0px;
            z-index: 2;
            border: 16px solid transparent;
            border-left: 20px solid var(--primaryColor);
            right: -34px;
        }
    }
    label {
        font-size: 14px;
        color: var(--bs-body-color);
    }
}

  
@media(max-width: 767px){
    .tracker-cards {
      background: rgba(163, 219, 89, 0.06) !important;
      .icon {
        width: 40px;
            img {
                max-width: 40px;
            }
        }   
    }
    .tracking-breadcrumb {
        .active {
            padding: 7px 10px 7px 22px;
            min-width: unset;
            max-width: 160px;
            font-size: 12px;
            margin-right: 25px;
            &::before {
                border: 18px solid transparent;
                border-left: 12px solid #f7f6f0;
            }
            &::after {
                border: 16px solid transparent;
                border-left: 12px solid var(--primaryColor);
                right: -26.5px;
            }
        }
        label {
            font-size: 12px;
        }
    }
}