// .sync-font-be-font-n {
//     font-family: 'Be Font N';
// }

.sync-font-BridgetteFont4-Regular {
    font-family: 'BridgetteFont4 Regular';
}

.sync-font-BeFontN3-Regular {
    font-family: 'BeFontN3 Regular';
}

.sync-font-segoe-ui {
    font-family: 'Segoe UI'
}

.sync-font-arial {
    font-family: 'Arial,Helvetica,sans-serif'
}

.sync-font-courier-new {
    font-family: 'Courier New,Courier,monospace'
}

.sync-font-georgia {
    font-family: 'Georgia,serif'
}

.sync-font-impact {
    font-family: "Impact", "Haettenschweiler", "Arial Narrow Bold", sans-serif
}

.sync-font-tahoma {
    font-family: 'Tahoma,Geneva,sans-serif'
}

.sync-font-times-new-roman {
    font-family: 'Times New Roman,Times,serif'
}

.sync-font-verdana {
    font-family: "Verdana", "Geneva", "Tahoma", sans-serif
}