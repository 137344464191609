/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap-extended/include';
@import '../../@core/scss/base/components/include';


// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
// @import url('https://fonts.cdnfonts.com/css/gotham-6');

@import "./header.scss";
@import "./authentication.scss";

// font-family: 'Roboto', sans-serif;
// font-family: 'Agdasima', sans-serif;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Oswald', sans-serif;
// font-family: 'Belanosima', sans-serif;
// font-family: 'Playfair Display', serif;
// font-family: 'Dancing Script', cursive;
// font-family: 'Teko', sans-serif;
// font-family: 'Caveat', cursive;

@font-face {
	font-family: 'BeFontN3 Regular';
	src: url('../fonts/BeFontN3-Regular.eot');
	src: local('☺'), url('../fonts/BeFontN3-Regular.woff') format('woff'), 
	url('../fonts/BeFontN3-Regular.ttf') format('truetype'), 
	url('../fonts/BeFontN3-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
} 
@font-face {
	font-family: 'BridgetteFont4 Regular';
	src: url('../fonts/BridgetteFont4-Regular.eot');
	src: local('☺'), url('../fonts/BridgetteFont4-Regular.woff') format('woff'), 
	url('../fonts/BridgetteFont4-Regular.ttf') format('truetype'), 
	url('../fonts/BridgetteFont4-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;   
} 

:root {
  --primaryColor: #a3db59;
  --primaryColorDark: #7d9943;
  --formElementRadius: 16px;
  --formElementSpacing: 20px;
}

body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

.modal-open {
  padding-right: 0px !important;
}
.member-form > div {
  display: -webkit-box;
}
.member-form > div > div {
  width: 40%;
  padding: 0px 10px 0px 0px;
}

.add-user-btn {
  padding-top: 20px;
}
.max-w-90 {
  max-width: 90%;
}
.react-trello-lane {
  header {
    span {
      width: 100%;
    }
  }
  .main-card-shadow {
    box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
  }
}
.weighted-text {
  color: $body-color;
  font-weight: 500;
}

.left-stage {
  margin-right: 10px;
}

.right-stage {
  margin-left: 10px;
}

.card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.ms-4px {
  margin-left: 4px;
}
.me-4px {
  margin-right: 4px;
}
.template-editor-wrapper {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.editor-class {
  padding: 0.571rem 1rem;
}

.auth-login-form .add-company-btn {
  padding-top: 32px;
}
.auth-login-form .update-status-btn {
  padding-top: 32px;
}

// ----file-------
.file-preview {
  position: relative;

  span {
    border: 1px dashed #000000;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 12px;
    display: block;
    text-align: center;
  }

  input {
    &#attach-image,
    &#attach-pdf {
      cursor: pointer;
      padding: 16px;
      border-radius: 8px;
      position: absolute;
      opacity: 0;
      width: 100%;
      top: 0px;
    }
  }
}
// --------------file end -----------------

.document-header-items {
  display: flex;
  justify-content: space-between;
  .result-toggler {
    display: flex;
    align-items: center;
    .search-results {
      font-weight: $font-weight-bold;
      color: $headings-color;
    }
  }
}

.form-boarder {
  // border-radius: 21px;
  border-radius: 0.428rem;
  border: 2px solid #ebe9f1 !important;
  padding: 10px;
}

.pipeline-boarder {
  border-radius: 0.428rem;
  border: 2px solid #ebe9f1 !important;
  padding: 20px;
  width: 100%;
}

.image-upload-wrapper {
  .spinner-border {
    margin-right: 10px;
  }
}

.form-card-title,
.document-card-title {
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.mass-email-card-title {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.form-loader-wrapper {
  height: 450px;
}

.admin-detail-save-btn {
  width: 100px !important;
}

.add-contact-group {
  .select__menu-list {
    overflow-y: scroll;
    max-height: 120px;
  }
}

.form-email-editor-wrapper,
.form-notification-editor-wrapper {
  min-height: 200px;
  .quill-editor-wrapper,
  .ql-container {
    min-height: 175px;
  }
}

.quill-border {
  .ql-container.ql-snow {
    border: none;
  }
}
.group-ei,
.group-pipeline-ei {
  margin-right: 5px;
}
.add-q-wrapper {
  margin-bottom: 20px;
  .contact-add-question-btn {
    position: relative !important;
    bottom: -34px !important;
  }
}
.template-select-wrapper {
  .select__control {
    // border: 1px solid rgb(216, 214, 222);
    border: 1px solid #d8d6de !important;
  }
  margin-bottom: 5px;
}

.group-select-border__control,
.group-select-border__control:hover,
.tag-select-border__control,
.tag-select-border__control:hover {
  // border: 1px solid #82868b;
  border-color: #ffffff;
  border: 1px solid #d8d6de !important;
}
.item-table-title {
  margin-right: 10px;
}

.mention-wrapper__input:focus:valid,
.mention-wrapper__input:focus.is-valid {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.mention-wrapper__input:focus {
  background-color: #fff;
  border-color: #a3db59 !important;
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
}
.mention-wrapper__input:focus {
  color: #6e6b7b;
  outline: 0;
}

.unseen-message-container {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  bottom: 20px;
}

.unseen-message-container::before,
.unseen-message-container::after {
  content: '';
  flex: 1;
  height: 2px;
  background: #ccc;
  margin: 0 10px;
}
.mentions__mention {
  z-index: 1;
  position: relative;
}

.pipeline-group-navbar-w {
  ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      margin-right: 10px;
      padding: 10px;
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
      border-radius: 0.428rem;
      &.active {
        background: linear-gradient(118deg, #a3db59, rgba(71, 194, 120, 0.7));
        color: white;
      }
      .title {
        margin-right: 8px;

        white-space: pre;
      }
      .group-ei {
        stroke: #64c664;
      }

      &.active svg path,
      &.active svg polyline,
      &:hover svg path,
      &:hover svg polyline {
        stroke: #ffffff;
      }
    }
    li:hover {
      background: linear-gradient(118deg, #a3db59, rgba(71, 194, 120, 0.7));
      color: white;
    }
    .add-group-btn {
      border: 1px solid #82868b;
      display: flex;
      align-items: center;
      .add-icon {
        margin-right: 10px;
      }
    }
    .add-group-btn:hover {
      color: white !important;
      svg {
        stroke: white;
      }
    }
  }
}
.stage-header {
  align-items: center;
}
.stage-grid-view-wrapper {
  .card-header {
    padding: 1.5rem 0 1.5rem 0;
  }
  .card-body {
    padding: 1.5rem 0 1.5rem 0;
  }
}
.item-table-search {
  padding: 0;
  width: 250px;
}

.no-data-found {
  font-size: 18px;
}
.chang-log-empty {
  font-size: 15px;
}

.change-log-list-wrapper {
  margin-left: 20px;
  font-size: 15px;
  .list-tem {
    margin-bottom: 5px;
  }
}

// ---------------------------
.custom_select_wrapper {
  position: relative;
  .focus-out {
    overflow: hidden;
    // z-index: 1;
    opacity: 10;
    transition: visibility 0s, opacity 0.5s linear;
    position: absolute;
    white-space: nowrap;
    top: 9px;
    left: 11px;
    max-width: calc(133% - 100px);
  }

  #react-select-3-placeholder {
    max-width: calc(133% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .c-pt {
    padding-top: 5px;
  }
  .__value-container--has-value {
    margin-top: 5px;
  }
  .__multi-value {
    color: white;
  }
  .__multi-value__label {
    color: white;
  }
}
.cs_form_label {
  pointer-events: none;
}

.fcw_value + .cs_form_label {
  font-size: 17px;
  background-color: white;
  color: #a3db59;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px 5px;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 100px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 10;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  visibility: visible;
  opacity: 1;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
  transition-property: all;
}
#custom_select_box_id {
  // z-index: 2;
}

.group-select-border__control {
  // width: 200px;
  width: 100%;
}
.active-user-label {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.permissions-wrapper {
  .permission-header {
    margin-bottom: 5px;
  }
  .p-title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .pc-ml {
    margin-left: 10px;
  }
  .p-checkbox {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .child-list {
    margin-bottom: 3px;
  }
}
.profile-img {
  object-fit: cover;
}

.permission-bg {
  padding: 20px;
  background-color: #f9f9f9;
  // background-color: #f8f8f8;
  border-radius: 10px;
  width: calc(100% - 26px);
  margin: 0px auto;
  border: 2px solid #ebe9f1;
}
.child-parent {
  margin-left: -8px !important;
  margin-right: -8px !important;
  .permission-child {
    width: 25%;
    padding: 0px 8px;
  }
}
.contact-archive-tab {
  &.cml {
    margin-left: 20px;
  }
  label {
    padding: 10px 10px !important;
  }
}

// ---------- calender --------------->>start

$fc-calendar-height: 650px;
$fc-sidebar-width: 18rem;
$fc-event-font-weight: 600;
$fc-event-font-size: 0.8rem;
$fc-event-padding-x: 0.5rem;
$fc-event-padding-y: 0.2rem;
$fc-toolbar-btn-padding: 0.438rem 0.5rem;
$fc-day-toggle-btn-padding: 0.55rem 1.5rem;

// Full calendar styles
.fc {
  // Toolbar
  .fc-toolbar {
    flex-wrap: wrap;
    flex-direction: row !important;
    .fc-prev-button,
    .fc-next-button {
      display: inline-block;
      background-color: transparent;
      border-color: transparent;
      .fc-icon {
        color: $body-color;
      }
      &:hover,
      &:active,
      &:focus {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
    .fc-prev-button {
      padding-left: 0 !important;
    }
    .fc-toolbar-chunk div:first-child {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .fc-button {
      padding: $fc-toolbar-btn-padding;
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
    .fc-button-group {
      .fc-button {
        text-transform: capitalize;
        &:focus {
          box-shadow: none;
        }
      }
      .fc-button-primary {
        &:not(.fc-prev-button):not(.fc-next-button) {
          // background-color: rgba($primary, 0.1);
          background-color: transparent;
          border-color: $primary;
          color: $primary;
          &.fc-button-active,
          &:hover {
            background-color: rgba($color: $primary, $alpha: 0.2) !important;
            border-color: $primary !important;
            color: $primary;
          }
        }
        &.fc-sidebarToggle-button {
          border: 0;

          i,
          svg {
            height: 21px;
            width: 21px;
            font-size: 21px;
          }
        }
      }
      .fc-sidebarToggle-button {
        padding-left: 0;
        background-color: transparent !important;
        color: $body-color !important;

        &:not(.fc-prev-button):not(.fc-next-button):hover {
          background-color: transparent !important;
        }

        & + div {
          margin-left: 0;
        }
      }
      .fc-dayGridMonth-button,
      .fc-timeGridWeek-button,
      .fc-timeGridDay-button,
      .fc-listMonth-button {
        padding: $fc-day-toggle-btn-padding;

        &:last-child,
        &:first-child {
          border-radius: $btn-border-radius;
        }
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    & > * > :not(:first-child) {
      margin-left: 0rem;
    }
    .fc-toolbar-title {
      margin-left: 0.25rem;
    }
    .fc--button:empty,
    .fc-toolbar-chunk:empty {
      display: none;
    }
  }

  // Calendar head & body common
  tbody td,
  thead th {
    border-color: $border-color;
    &.fc-col-header-cell {
      border-right: 0;
      border-left: 0;
    }
  }

  .fc-view-harness {
    min-height: $fc-calendar-height;
  }

  // Daygrid
  .fc-scrollgrid-section-liquid > td {
    border-bottom: 0;
  }
  .fc-daygrid-event-harness {
    .fc-event {
      font-size: $fc-event-font-size;
      font-weight: $fc-event-font-weight;
      padding: $fc-event-padding-y $fc-event-padding-x;
    }
    // & + .fc-daygrid-event-harness {
    //   margin-top: 0.3rem !important;
    // }
  }
  // .fc-daygrid-day-bottom {
  //   margin-top: 0.3rem !important;
  // }
  .fc-daygrid-day {
    padding: 5px;
    .fc-daygrid-day-top {
      flex-direction: row;
    }
  }

  // All Views Event
  .fc-daygrid-day-number,
  .fc-timegrid-slot-label-cushion,
  .fc-list-event-time {
    color: $body-color;
  }
  .fc-day-today {
    background: $body-bg !important;
    background-color: $body-bg !important;
  }

  // Timegrid
  .fc-timegrid {
    .fc-scrollgrid-section {
      .fc-col-header-cell,
      .fc-timegrid-axis {
        border-color: $border-color;
        border-left: 0;
        border-right: 0;
      }
      .fc-timegrid-axis {
        border-color: $border-color;
      }
    }
    .fc-timegrid-axis {
      &.fc-scrollgrid-shrink {
        .fc-timegrid-axis-cushion {
          text-transform: capitalize;
          color: $text-muted;
        }
      }
    }
    .fc-timegrid-slots {
      .fc-timegrid-slot {
        height: 3rem;
        .fc-timegrid-slot-label-frame {
          text-align: center;
          .fc-timegrid-slot-label-cushion {
            text-transform: uppercase;
          }
        }
      }
    }
    .fc-timegrid-divider {
      display: none;
    }
  }

  // List View
  .fc-list {
    border-color: $border-color;
    .fc-list-day-cushion {
      background: $body-bg;
    }
    .fc-list-event {
      &:hover {
        td {
          background-color: $body-bg;
        }
      }
      td {
        border-color: $border-color;
      }
    }
  }
}

// App Calendar specific
.app-calendar {
  position: relative;
  border-radius: $card-border-radius;
  margin-bottom: 2rem;
  .app-calendar-sidebar {
    position: absolute;
    left: calc(-#{$fc-sidebar-width} - 1.2rem);
    width: $fc-sidebar-width;
    height: 100%;
    z-index: 5;
    background-color: $card-bg;
    border-right: 1px solid $border-color;
    flex-basis: $fc-sidebar-width;
    transition: all 0.2s, background 0s, border 0s;
    &.show {
      left: 0;
    }
    .sidebar-content-title {
      font-size: 0.85rem;
      color: $text-muted;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
    .select-all,
    .input-filter {
      & ~ label {
        color: $headings-color;
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
  }

  // right sidebar area styles
  .event-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    z-index: 15;
    transform: translateX(120%);
    transition: all 0.3s ease-in-out;

    .card {
      height: calc(100dvh - 12.96rem);
      height: calc(var(--vh, 1vh) * 100 - 12.96rem);
      border-radius: 0 $border-radius-sm $border-radius-sm 0;

      .close-bar {
        cursor: pointer;
      }

      .todo-item-action {
        width: 6rem;

        .todo-item-info,
        .todo-item-favorite,
        .dropdown {
          cursor: pointer;
          line-height: 1.5;
        }
        .dropdown .dropdown-menu .dropdown-item {
          padding: 0.14rem 1.428rem;
        }
        .dropdown-toggle::after {
          display: none;
        }
      }
    }

    &.show {
      transform: translateX(0);
    }
  }

  .fc-toolbar {
    h2 {
      font-size: 1.45rem;
    }
  }
  .fc-header-toolbar {
    margin-bottom: 1.75rem !important;
  }
  .fc-view-harness {
    margin: 0 -1.6rem;
  }
  .fc-scrollgrid {
    border-color: $border-color;
  }
  .fc-day-past,
  .fc-day-future {
    .fc-daygrid-day-number {
      color: $text-muted;
    }
  }
  .fc-popover {
    box-shadow: $box-shadow;
    .fc-popover-header {
      background: transparent;
      padding: 0.5rem;
      .fc-popover-title,
      .fc-popover-close {
        color: $headings-color;
      }
    }
    .fc-popover-body {
      *:not(:last-of-type) {
        margin-bottom: 0.3rem;
      }
    }
  }

  .fc {
    .fc-event {
      .fc-event-main {
        color: inherit !important;
      }
    }
  }
  .fc-list-event {
    background: transparent !important;
  }



  @media (min-width: 992px) {
    .app-calendar-sidebar {
      position: static;
      height: auto;
      box-shadow: none !important;

      .flatpickr-days {
        background-color: transparent;
      }
    }
  }
}

// Event Sidebar
.event-sidebar {
  .select2-selection__choice__remove {
    &:before {
      top: 40% !important;
    }
  }
}

// Horizontal Menu
.horizontal-layout {
  .app-calendar {
    margin-bottom: 1rem;
  }
}

// Media Queries
@media (max-width: 992px) {
  .fc {
    .fc-sidebarToggle-button {
      font-size: 0;
    }
  }
}

@media (min-width: 992px) {
  .fc {
    .fc-sidebarToggle-button {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .app-calendar {
    .fc {
      .fc-header-toolbar {
        .fc-toolbar-chunk:last-of-type {
          margin-top: 1rem;
        }
      }
    }
  }
}

.event-contact-card {
  margin: 5px;
}
.view-icon-cml {
  margin-left: 58px;
}

// .sidebar-company-icon {
//   path {
//     fill: #625f6e;
//   }
// }

.manage-group-cl-wrapper {
  .card {
    margin-bottom: 0;
    box-shadow: unset;
  }
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: unset !important;
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none !important;
}

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  border: 1px solid var(--fc-border-color, #ddd) !important;
}
.fc .fc-button-group > button:not(:last-child) {
  border-right: 0;
}
.app-calendar .fc-view-harness {
  margin: 0;
}
.full-calender-wrapper {
  .fc {
    padding-bottom: 8px;
  }
}

.fc-popover-body::-webkit-scrollbar {
  width: 10px;
}
.fc-popover-body {
  max-height: 160px;
  overflow-y: auto;
}

/* Track */
.fc-popover-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.fc-popover-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.fc-popover-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fc-v-event .fc-event-main {
  color: unset !important;
  border-color: unset !important;
  font-size: 0.8rem;
  font-weight: 600;
}
.fc-h-event .fc-event-main {
  color: unset !important;
  border-color: unset !important;
  font-size: 0.8rem;
  font-weight: 600;
}
.event-bg-light-primary {
  background-color: #eeedfd !important;
  color: #a3db59 !important;
  border-color: #dec8df !important;
  font-size: 0.8rem;
  font-weight: 600;
  .fc-list-event-dot {
    border-color: #a3db59 !important;
  }
}
.event-bg-light-success {
  background-color: #e5f8ed !important;
  color: #28c76f !important;
  border-color: #d1f3e0 !important;
  font-size: 0.8rem;
  font-weight: 600;
  .fc-list-event-dot {
    border-color: #28c76f !important;
  }
}
.event-bg-light-danger {
  background-color: #fceaea !important;
  color: #ea5455 !important;
  border-color: #fadbdb !important;
  font-size: 0.8rem;
  font-weight: 600;
  .fc-list-event-dot {
    border-color: #ea5455 !important;
  }
}
.event-bg-light-warning {
  background-color: #fff3e8 !important;
  color: #ff9f43 !important;
  border-color: #ffead7 !important;
  font-size: 0.8rem;
  font-weight: 600;
  .fc-list-event-dot {
    border-color: #ff9f43 !important;
  }
}
.event-bg-light-info {
  background-color: #e0f9fc !important;
  color: #00cfe8 !important;
  border-color: #c9f4fa !important;
  font-size: 0.8rem;
  font-weight: 600;
  .fc-list-event-dot {
    border-color: #00cfe8 !important;
  }
}
.fc-daygrid-event-dot {
  display: none;
}
// ---------- calender --------------->> end

.flatpickr-wrapper {
  display: block !important;
}
.contact-card-text {
  margin-left: 5px;
  // width: 230px;
  // width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rsvp-card-text {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-label {
  font-weight: 500;
}
.contact-search-wrapper {
  input {
    padding: 10px 10px;
    border: 1px solid #d8d6de;
  }
}

.event-contact-list-wrapper {
  margin: 0px -4px;
  &.row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.2) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.2) !important;
  }
}
.event-contact-list-parent {
  padding-bottom: 0 !important;
}

.task-manager-scheduler {
  min-height: 100%;
  // min-height: 37vh;
}

.event-underline-label {
  border-bottom: 1px solid #5e5873;
}

.custom-loader {
  animation: none !important;
  border-width: 0 !important;
}

.color-picker-wrapper {
  border: 1px solid #82868b;
  border-radius: 0.357rem;

  width: 75px;
  height: 35px;
  position: relative;
  cursor: pointer;
}
.event-color-picker {
  border-radius: 0.357rem;
  position: absolute;
  width: 67px;
  height: 27px;
  top: 3px;
  right: 3px;
}

.close-color-picker-div {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.add-contact-modal {
  .card {
    margin-bottom: 0;
  }
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  margin: 0 auto;
}

.event-label {
  .select__menu {
    z-index: 15;
  }
}

.collapse-bar-header {
  align-items: center;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  border-radius: 0.428rem;
  border: 1px solid !important;
  color: 262528;
  background-color: #e7e7e7 !important;
  border-color: #262528 !important;
}
.rsvp-detail-wrapper {
  .no-of-guest {
    align-items: center;
    padding: 5px;
    justify-content: space-between;
    background-color: #f1f1f1;
  }
}

.add-contact-title {
  &.hide-title {
    visibility: hidden;
  }
}

.contact-card-wrapper {
  position: relative;
  height: calc(100% - 1.5rem);
  &.unsubscribed__contact__wp {
    background-color: #ebebeb;
  }
}

.ribbon-2 {
  color: white;
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  // background: #bd1550;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

.group-select-border__input-container {
  z-index: 2;
}

.contact-card-checkbox {
  input {
    cursor: pointer;
  }
}
.card-detail-wrapper {
  flex-wrap: wrap;
  .text-wrapper {
    overflow: hidden;
    width: calc(100% - 56px);
  }
}

.card-info {
  width: 85%;
  flex-wrap: wrap;
}
.group-select-border__placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rsvp-card-wrapper {
  .rsvp-card-info {
    width: 100%;
    overflow: hidden;
  }
  .text-wrapper {
    width: calc(100% - 56px);
  }
}
.group-select-border__menu {
  // BLN
  z-index: 20 !important;
  // z-index: 4 !important;
}
.event-contact-table-w {
  .card-body {
    padding: 0 !important;
  }
}

.import-contacts .bs-stepper .bs-stepper-header {
  justify-content: center;
}

.import-contacts .bs-stepper {
  box-shadow: none;
}

.update-rsvp-form {
  .auth-wrapper {
    max-width: 400px;
    margin: 0 auto;
    .switch-label {
      margin: 0 10px;
    }
  }
}
.schedule-repeat-field-wrapper {
  width: 100%;
}
.schedule-repeat-label {
  margin-top: 22px;
  margin-left: 5px;
}

.event-days button {
  color: #ffffff;
  border: 1px solid #f1f3f4;
  background-color: #f1f3f4;
  color: #80868b;
  border-radius: 50%;
  flex-flow: row wrap;
  height: 35px;
  margin: 0 4px 6px;
  width: 35px;
}

.event-days button {
  &.active {
    border: 1px solid #a3db59;
    color: #ffffff;
    background-color: #a3db59;
  }
}

.chang-log-count {
  right: -3px !important;
  top: -4px !important;
}

.rdw-editor-main {
  border: 1px solid white !important;
}

.import-instructions {
  width: 50%;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.dropzone.import-contact {
  padding: 30px 20px;
  margin: 10px auto !important;
  width: 70%;
  border: 1px dashed #a3db59;
}

.select__control {
  border-color: #ffffff;
  border: 1px solid #d8d6de !important;
}

.email-template-preview {
  height: 80%;
  .modal-content {
    height: 100%;
  }
  .modal-body {
    max-height: 100%;
    overflow-y: auto !important;
  }
}
.sms-template-preview {
  height: 80%;
  .modal-body {
    max-height: 100%;
    overflow-y: auto !important;
  }
}

.email-editor-modal.modal-dialog {
  .email-editor {
    height: 500px;
  }
}

.email-editor-modal.modal-dialog.editor-open {
  max-width: fit-content;
}

.email-editor {
  height: 90%;
}
.select__menu {
  z-index: 15 !important;
}
.scheduled-mail-view-info {
  justify-content: space-between;
}

.scheduled-mail-view-info-child {
  &.first {
    width: 50%;
    position: relative;
  }
  &.second {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
  }
  &.first:after {
    --tw-content: ':';
    content: var(--tw-content);
    position: absolute;
    margin-right: 5px;
    right: 0;
    top: 0;
  }
}

.header-back-btn {
  margin-left: 20px;
}

.scheduled__view__modal__body {
  .template-title {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
  }
}
.print-note-details {
  display: none;
}

@media print {
  .print-note-details {
    display: block;
    margin-top: 10px;
    padding: 10px;
  }
}


.contact-note-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact-note,
.sms-template-card-wrapper,
.email-template-card-wrapper {
  height: calc(100% - 1.5rem);
  .title {
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.mail-template-modal-wrapper {
  p {
    margin-bottom: 0;
  }
}
.remove-phone-field-btn-wp {
  .remove-phone-field-btn {
    &.cursor-not-allowed {
      cursor: not-allowed;
    }
    padding: 8px;
  }
}

.scheduled-detail-wp {
  background-color: #efefef;
}

[data-column-id='scheduled-mass-sms-action'],
[data-column-id='scheduled-mass-mail-action'] {
  display: flex;
  justify-content: flex-end;
}
.rdt_TableHead {
  [data-column-id='scheduled-mass-sms-action'],
  [data-column-id='scheduled-mass-mail-action'] {
    justify-content: flex-start;
  }
}

.add-product-modal {
  .loader {
    min-height: 250px;
  }
}
.add-product-category-modal {
  .loader {
    min-height: 250px;
  }
  .card {
    box-shadow: unset !important;
    .card-body {
      padding: 0 !important;
    }
    .card-header {
      padding-left: 0;
    }
  }
}
.add-customer-modal {
  .loader {
    min-height: 250px;
  }
}
.invoice-form-wrapper,
.invoice-preview-card-wp {
  .company-logo {
    height: 35px;
    width: 35px;
  }
  .DraftEditor-editorContainer {
    border-color: white !important;
  }
  .rdw-editor-main {
    border: unset !important;
  }
}

.invoice-card-wrapper {
  .date-label {
    width: 105px;
  }
  .c__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

.p__category__modal {
  .react-dataTable {
    height: 400px;
    overflow-y: auto;
  }
}

.ecommerce-application .product-checkout.list-view {
  grid-template-columns: unset !important;
}

.quote-card-wp {
  .qt-inc {
    width: 35px;
    height: 30px;
  }
  .qt-input {
    input {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
    height: 30px;
    width: 40px;
    input {
      max-height: 100%;
    }
  }
  .price-input {
    height: 30px;
    width: 70px;
    input {
      max-height: 100%;
    }
  }
  .quote-pro-img {
    max-width: 60px !important;
    height: 60px;
  }
}

.c-error {
  width: 100%;
  font-size: 0.857rem;
  color: #ea5455;
}

// ==========================================

.add__payment__btn__wp {
  margin-top: 0;
}

.add__payment__modal_wp {
  .card__form {
    input,
    .StripeElement {
      display: block;
      margin: 10px 0 10px 0;
      max-width: 500px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: 'Source Code Pro', monospace;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      background: white;
    }

    input::placeholder {
      color: #aab7c4;
    }

    input:focus,
    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }
  }

  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .StripeElement.PaymentRequestButton {
    height: 40px;
  }
}

.fit__content__spinner__wp {
  display: flex;
  justify-content: center;
  position: absolute;
  height: 85%;
  width: 100%;
  align-items: center;
}
.no__record__found__wp {
  display: flex;
  justify-content: center;
  position: absolute;
  height: 85%;
  width: 100%;
  align-items: center;
}
.invoice__header__form__field__wp {
  .react-select {
    width: 100%;
    max-width: 11.21rem;
  }
}

.invoice-preview-wrapper {
  .invoice-data-table-wp .first-col {
    width: 40%;
    max-width: 40%;
  }
  .inner-table {
    border: 1px solid;
    border-color: #ebe9f1;
  }
}

.change__bill__status__history__modal_wp {
  textarea {
    min-height: 150px;
  }
}

.public-invoice-preview-wrapper {
  .invoice-preview-card-wp {
    margin-left: 2rem;
    margin-top: 2rem;
  }
  .invoice-action-wrapper {
    margin-right: 2rem;
    margin-top: 2rem;
  }
}

.blank-layout-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: calc(50vh - 20px);
}

.invoice__preview__card__col > div {
  height: 100%;
  padding-bottom: 2rem;
}

.billing__status__notes__wp {
  max-height: 300px;
  overflow-y: auto;
}

.fancy__scrollbar,
.cate-status-pipeline-wrapper{
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $gray-300;
    border-radius: 30px;
  }
}
.sidebar {
  .sidebar__list__title {
    display: block;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sidebar__list__title__wp {
    max-width: 70%;
    width: 70%;
    .align-middle {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .list__item {
    width: 100%;
  }
}

.color__picker__wp {
  input {
    width: 70px;
    height: 38px;
  }
}

.menu__collapse {
  display: flex;
  align-items: center;
  height: 80px !important;
  padding: 0 !important;
  .brand-logo {
    // margin-top: 1rem !important;
    margin-left: 23px;
  }
}

.navbar-search {
  margin-right: 10px;
  position: relative;
  width: 270px;
  input {
    box-sizing: border-box;
    background-image: url('../images/icons/searchicon.png');
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 10px 20px 10px 45px;
    border: none;
    border-bottom: 1px solid #ddd;
    width: 100%;
    &:focus {
      outline: 2px solid #ddd;
      border-radius: 5px;
    }
  }
  .nav-search-content {
    position: absolute;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 5px 10px #e9e9e9;
    top: calc(100% + 1px);
    max-height: 300px;
    overflow-y: auto;
    .nav-search-item {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      &:hover {
        background-color: #f7f7f7;
      }
      img {
        width: 32px;
        height: 32px;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
      }
      .nav-search-item-cn {
        width: calc(100% - 43px);
        .user-name {
          font-size: 15px;
          line-height: 16px;
          font-weight: 500;
          margin-bottom: 2px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .company-name {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          color: #555;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .search-icon {
    display: none;
  }
  .back-arrow {
    display: none;
  }
}

.file__drop__zone_wp {
  .file__card {
    padding: 5px;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    position: relative;
    .file__preview__sm {
      height: 100px;
      img {
        border-radius: 0.357rem;
      }
    }
    .handle__image__edit__icon {
      margin-left: 5px;
    }
    .file__edit__field {
      padding: 3px 3px !important;
    }
    .image__edit__wp {
      margin-top: 5px;
    }
    .close__icon {
      z-index: 1;
      position: absolute;
      right: -10px;
      top: -10px;
    }
    .file__preview__wp {
      position: relative;

      .overlay {
        border-radius: 0.357rem;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        &:hover {
          opacity: 1;
        }
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .file-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.note-export {
  width: 90%;
}

.checklist__template__modal,
.add__task__modal {
  .remove__checklist__icon {
    margin-top: 16px;
  }
  .checklist__list__wrapper {
    .accordion-item {
      box-shadow: 0 2px 15px 0 rgba($black, 0.15) !important;
    }
  }
}

.task__filter__dropdown {
  .filter__icon_wrapper {
    margin-top: 7px;
  }
}

// .grecaptcha-badge {
//   visibility: hidden;
// }

// ************* Checklist Start *******************
.checklist__wp {
  .accordion-margin {
    width: 100%;
  }
  .input_wrapper {
    textarea.form-control {
      padding: 0.5rem 1rem !important;
    }
    textarea {
      height: 55px;
      min-height: 55px;
      border: none;
    }
  }
}
// ************* Checklist End *******************

.display__one__line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact__send__mail__modal {
  height: 100%;
  .modal-content {
    height: 100%;
  }
  .send__mail__form {
    // display: flex;
    // flex-flow: column;
    height: 100%;
  }
  .editor__wp {
    flex: 1 1 auto;
    height: 100%;
  }
}

.table__page__limit__control {
  border-color: #888888;
}

.border-left-0 {
  border-left: 0 !important;
}

// Print Blank page Isuue
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

.sender__email__dd__wp {
  .select__option--is-disabled {
    color: #6e6b7b !important;
    background: unset !important;
    opacity: 0.7;
  }
}

.create__mass__email__wp {
  .contact__list__navbar {
    background-color: #e1e1e1;
    padding: 10px 10px;
    border-radius: 0.428rem;
  }
}

.checklist_cross_btn {
  transform: translate(20px, -24px);
}

.contact__checklist__wrapper {
  .checklist__card {
    border-radius: 0.428rem;
    border: 2px solid #ebe9f1 !important;
    box-shadow: unset !important;
  }
}

// .sortable-chosen {
//   transform: translate(0, 0);
// }

.task-heading-tooltip {
  .tooltip-inner {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 80px !important;
  }
}

// new-ui css start
body {
  // background-color: #f5f9fc !important;
  background-color: #f7f6f0 !important;
}
.grecaptcha-badge {
  z-index: 999;
}
*{
  word-break:break-word !important;
}
*:focus {
  outline: none;
}
body .e-disabled {
	opacity: 1;
}
.btn-primary {
  color: #000000 !important;
}
// .text-primary{
//   color:var(--primaryColor) !important;
// }
// .btn-primary,
// .btn-primary:active{
//   border-color:var(--primaryColor) !important;
//   background-color:var(--primaryColor) !important;
//   color:#000000 !important;
// }
// .btn-primary:hover:not(.disabled):not(:disabled){
//   box-shadow: 0 8px 25px -8px var(--primaryColor);
// }
// .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):focus{
//   color:var(--primaryColor);
// }
// .btn-outline-primary{
//   border:1px solid var(--primaryColor) !important;
// }
// a{
//   color:var(--primaryColor);
// }

.fancy-scrollbar,
.rdw-dropdown-optionwrapper,
.company-form-page
  .list-view-card-wrapper
  .rdt_Table_wrapper
  .react-dataTable
  > div,
.custom-select__menu-list,
.dropdown-menu,
.table__page__limit__menu-list,
.fc-list .fc-scroller,
.email-details-page .email-details-page-scroll-area{
  scrollbar-color: #bfbfbf #ebebeb;
  scrollbar-width: thin;
}
.fancy-scrollbar::-webkit-scrollbar,
.rdw-dropdown-optionwrapper::-webkit-scrollbar,
.company-form-page
  .list-view-card-wrapper
  .rdt_Table_wrapper
  .react-dataTable
  > div::-webkit-scrollbar,
.custom-select__menu-list::-webkit-scrollbar,
.dropdown-menu::-webkit-scrollbar,
.table__page__limit__menu-list::-webkit-scrollbar,
.fc-list .fc-scroller::-webkit-scrollbar,
.email-details-page .email-details-page-scroll-area::-webkit-scrollbar{
  width: 5px;
}
.fancy-scrollbar::-webkit-scrollbar-track,
.rdw-dropdown-optionwrapper::-webkit-scrollbar-track,
.company-form-page
  .list-view-card-wrapper
  .rdt_Table_wrapper
  .react-dataTable
  > div::-webkit-scrollbar-track,
.custom-select__menu-list::-webkit-scrollbar-track,
.dropdown-menu::-webkit-scrollbar-track,
.table__page__limit__menu-list::-webkit-scrollbar-track,
.fc-list .fc-scroller::-webkit-scrollbar-track,
.email-details-page .email-details-page-scroll-area::-webkit-scrollbar-track{
  background: #ebebeb;
}
.fancy-scrollbar::-webkit-scrollbar-thumb,
.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb,
.company-form-page
  .list-view-card-wrapper
  .rdt_Table_wrapper
  .react-dataTable
  > div::-webkit-scrollbar-thumb,
.custom-select__menu-list::-webkit-scrollbar-thumb,
.dropdown-menu::-webkit-scrollbar-thumb,
.table__page__limit__menu-list::-webkit-scrollbar-thumb,
.fc-list .fc-scroller::-webkit-scrollbar-thumb,
.email-details-page .email-details-page-scroll-area::-webkit-scrollbar-thumb{
  background: #bfbfbf;
}
.fancy-scrollbar::-webkit-scrollbar-thumb:hover,
.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb:hover,
.company-form-page
  .list-view-card-wrapper
  .rdt_Table_wrapper
  .react-dataTable
  > div::-webkit-scrollbar-thumb:hover,
.custom-select__menu-list::-webkit-scrollbar-thumb:hover,
.dropdown-menu::-webkit-scrollbar-thumb:hover,
.table__page__limit__menu-list::-webkit-scrollbar-thumb:hover,
.fc-list .fc-scroller::-webkit-scrollbar-thumb:hover,
.email-details-page
  .email-details-page-scroll-area::-webkit-scrollbar-thumb:hover{
  background: #979797;
}

.hide-scrollbar::-webkit-scrollbar,
.contact__card__wrapper
  .contact__checklist__wrapper
  .compnay-checklist-folder-wrapper
  > .card-body::-webkit-scrollbar,
.assign__select__box .custom-select__value-container::-webkit-scrollbar,
.fc-list .fc-scroller::-webkit-scrollbar,
.modal .modal-dialog.modal-dialog-mobile .modal-body::-webkit-scrollbar,
.tribute-container ul::-webkit-scrollbar{
  display: none;
}
.hide-scrollbar,
.contact__card__wrapper
  .contact__checklist__wrapper
  .compnay-checklist-folder-wrapper
  > .card-body,
.assign__select__box .custom-select__value-container,
.fc-list .fc-scroller,
.modal .modal-dialog.modal-dialog-mobile .modal-body,
.tribute-container ul{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// new scroll bar design

.fancyScrollbarRound::-webkit-scrollbar,
.mentions__suggestions__list::-webkit-scrollbar,
.addGroupMember__modal .addedMember__list:empty ~ .infinite-scroll-component__outerdiv .infinite-scroll-component::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar{
  width: 6px;
}
.fancyScrollbarRound::-webkit-scrollbar-track,
.mentions__suggestions__list::-webkit-scrollbar-track,
.addGroupMember__modal .addedMember__list:empty ~ .infinite-scroll-component__outerdiv .infinite-scroll-component::-webkit-scrollbar-track,
.infinite-scroll-component::-webkit-scrollbar-track{
  border-radius: 4px;
  // background-color: #e7e7e7;
  // border: 1px solid #cacaca;
  // box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
}
.fancyScrollbarRound::-webkit-scrollbar-thumb,
.mentions__suggestions__list::-webkit-scrollbar-thumb,
.addGroupMember__modal .addedMember__list:empty ~ .infinite-scroll-component__outerdiv .infinite-scroll-component::-webkit-scrollbar-thumb,
.infinite-scroll-component::-webkit-scrollbar-thumb{
  border-radius: 8px;
  background-color:#bfbfbf;
}

.fancyScrollbarRoundVertical::-webkit-scrollbar {
  height: 3px;
}
.fancyScrollbarRoundVertical::-webkit-scrollbar-track {
  border-radius: 4px;
  // background-color: #e7e7e7;
  // border: 1px solid #cacaca;
  // box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
}
.fancyScrollbarRoundVertical::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color:#bfbfbf;
}

html.overflow-hide {
  overflow: hidden;
}

.sidenav-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  opacity: 1 !important;
  transition: 0s !important;
  animation: unset !important;
}

// skeleton-loader
.skeletonBox {
  background-color: #dddbdd;
  border-radius: 20px;
  display: block;
  height: 12px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.skeletonBox:after {
  content: '';
  transform: translateX(0%);
  -webkit-animation: skeleton__animation__glow 2s infinite;
  animation: skeleton__animation__glow 2s infinite;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.2) 20%,
    hsla(0, 0%, 100%, 0.5) 60%,
    hsla(0, 0%, 100%, 0)
  );
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@keyframes skeleton__animation__glow {
  to {
    transform: translateX(100%);
  }
}
// skeleton-loader-end

.ps__thumb-y {
  width: 3px;
  background-color: #cccccc;
}
.ps .ps__rail-y:hover,
.ps__rail-y.ps--clicking {
  background-color: transparent !important;
  width: 10px;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y.ps--clicking > .ps__thumb-y {
  width: 6px;
  background-color: #c6c6c6;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  opacity: 1 !important;
  z-index:999;
}

.loading-round {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loading-round;
  animation-timing-function: linear;
  border-radius: 50%;
  height: 0;
  padding: 7px;
  position: relative;
  width: 0;
  border: 3px solid #d5d5d5;
  border-right: 3px solid var(--primaryColor);
}
@keyframes loading-round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.component-loader {
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page__round__loader {
  -webkit-animation: page__round__loader 1s linear infinite;
  animation: page__round__loader 1s linear infinite;
  background-color: initial;
  border-bottom: 4px solid var(--primaryColor);
  border-left: 4px solid #f4f4f4;
  border-radius: 50%;
  border-right: 4px solid #f4f4f4;
  border-top: 4px solid var(--primaryColor);
  height: 75px;
  width: 75px;
}
@keyframes page__round__loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.site-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  background-color: #ffffff;
  z-index: 99999;
  .inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100px;
    }
  }
}
.site-loader .first {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 1050;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-first;
  animation-timing-function: linear;
}
@keyframes site-loader-first {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 1050;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 2100;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 2100;
    fill: var(--primaryColor);
  }
  to {
    stroke-dashoffset: 2100;
    fill: var(--primaryColor);
  }
}
.site-loader .second {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 742;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-second;
  animation-timing-function: linear;
}
@keyframes site-loader-second {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 742;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 1484;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 1484;
    fill: var(--primaryColor);
  }
  to {
    stroke-dashoffset: 1484;
    fill: var(--primaryColor);
  }
}
.site-loader .third {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 710;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-third;
  animation-timing-function: linear;
}
@keyframes site-loader-third {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 710;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 1420;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 1420;
    fill: var(--primaryColor);
  }
  to {
    stroke-dashoffset: 1420;
    fill: var(--primaryColor);
  }
}
.site-loader .fourth {
  stroke: var(--primaryColor);
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 430;
  stroke-dashoffset: 0;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-fourth;
  animation-timing-function: linear;
}
@keyframes site-loader-fourth {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 430;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 860;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 860;
    fill: var(--primaryColor);
  }
  to {
    stroke-dashoffset: 860;
    fill: var(--primaryColor);
  }
}
.site-loader .first1 {
  stroke: #000000;
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 150;
  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-first-1;
  animation-timing-function: linear;
}
@keyframes site-loader-first-1 {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 150;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 300;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 300;
    fill: #000000;
  }
  to {
    stroke-dashoffset: 300;
    fill: #000000;
  }
}
.site-loader .second1 {
  stroke: #000000;
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 570;
  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-second-1;
  animation-timing-function: linear;
}
@keyframes site-loader-second-1 {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 570;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 1140;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 1140;
    fill: #000000;
  }
  to {
    stroke-dashoffset: 1140;
    fill: #000000;
  }
}
.site-loader .third1 {
  stroke: #8dc247;
  stroke-width: 5px;
  fill: transparent;
  stroke-dasharray: 470;
  stroke-dashoffset: 0;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: site-loader-third-1;
  animation-timing-function: linear;
}
@keyframes site-loader-third-1 {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  20% {
    stroke-dashoffset: 470;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 940;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 940;
    fill: #8dc247;
  }
  to {
    stroke-dashoffset: 940;
    fill: #8dc247;
  }
}

.footer {
  min-height: unset !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  p {
    margin-bottom: 0px;
  }
}
.header-navbar.floating-nav ~ .footer {
  margin-left: 260px;
}

// comman css start
body.modal-open {
  height: 100dvh;
  overflow-y: hidden;
}
a {
  color: var(--primaryColorDark);
}

.phoneInputOnlyView{
  .react-tel-input{
    .flag-dropdown{
      display:none;
    }
    input.form-control{
      padding:0px 0px 0px 0px;
      border:none !important;
      color:rgba(0,0,0,0.87) !important;
      font-size:13px !important;
      cursor:auto;
    }
  }
}

body .content.app-content {
  padding-top: 84px !important;
  padding-left: 15px;
  padding-right: 15px;
  min-height: calc(100dvh - 50px);
}

.selected-text-new-task-btn-wrapper {
  width: 136px;
  height: 48px;
  background-color: black;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-30px);
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border: 10px solid transparent;
    border-top: 10px solid #000000;
    bottom: -18px;
  }
  .new-task-btn {
    font-size: 14px;
    padding: 8px 16px !important;
  }
}

.card {
  box-shadow: unset;
  border-radius: 8px;
  border: 1px solid #eae8e1;
  margin-bottom: 4px;
  .card-header {
    background-color: #ffffff !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    .card-title {
      font-weight: 600 !important;
    }
  }
}

.importSuccess-wrapper {
  padding: 40px 0px;
  .img-wrapper {
    width: 400px;
    max-width: 100%;
    margin: 0px auto;
    position: relative;
    left: -10px;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
    .tick-animated-img {
      position: absolute;
      top: 32%;
      width: 240px;
      right: 16%;
    }
  }
  .text {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
    color: #000000;
  }
}

.fancy-ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  .item {
    position: relative;
    padding-left: 36px;
    font-size: 16px;
    color: #404040;
    margin-bottom: 12px;
    font-weight: 500;
    &:last-child {
      margin-bottom: 0px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      width: 12px;
      height: 8px;
      left: 8px;
      border-left: 2px solid var(--primaryColorDark);
      border-bottom: 2px solid var(--primaryColorDark);
      transform: rotate(-45deg);
    }
    &:after {
      content: '';
      position: absolute;
      top: -2px;
      left: 0px;
      width: 28px;
      height: 28px;
      font-size: 12px;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primaryColor);
      color: var(--primaryColorDark);
      border-radius: 50%;
      font-weight: 600;
      opacity: 0.2;
    }
  }
  &.with-number {
    counter-reset: fancyUl;
    .item {
      &:before {
        counter-increment: fancyUl;
        content: counter(fancyUl);
        position: absolute;
        top: -2px;
        left: 0px;
        width: 28px;
        height: 28px;
        font-size: 12px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        font-weight: 600;
        color: var(--primaryColorDark);
        border-left: none;
        border-bottom: none;
        transform: rotate(0deg);
      }
    }
  }
}

// datetime custom picker
.flatpickr-calendar {
  width: 350px !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}
.flatpickr-month {
  height: auto !important;
}
.flatpickr-current-month {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  transform: unset !important;
  position: static !important;
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: auto !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
  .flatpickr-monthDropdown-months {
    width: calc(50% - 5px) !important;
    text-align: center;
    margin: 0px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #000000 !important;
    height: 36px !important;
    padding: 2px 10px !important;
    border-radius: 6px !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  .numInputWrapper {
    width: calc(50% - 5px) !important;
    padding: 0px !important;
    border-radius: 6px !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
    .numInput {
      padding: 2px 10px !important;
      width: 100% !important;
      height: 100% !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      color: #000000 !important;
      text-align: center;
      height: 36px !important;
    }
  }
}
.flatpickr-next-month {
  width: 36px;
  height: 36px !important;
  background-color: #fff;
  box-shadow: 2px 2px 8px #f0f0f0;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  padding: 0px 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px !important;
  right: 12px !important;
  &:hover {
    background-color: #000000 !important;
    color: #ffffff !important;
    border: 1px solid #000000;
  }
}
.flatpickr-prev-month {
  width: 36px;
  height: 36px !important;
  background-color: #fff;
  box-shadow: 2px 2px 8px #f0f0f0;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  padding: 0px 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px !important;
  left: 12px !important;
  &:hover {
    background-color: #000000 !important;
    color: #ffffff !important;
    border: 1px solid #000000;
  }
}
.flatpickr-rContainer {
  width: 100%;
  .flatpickr-weekdays {
    margin-top: 0px;
    padding: 0px 8px;
    .flatpickr-weekday {
      color: #b1b1b1 !important;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
}
.flatpickr-days {
  width: 100% !important;
  .dayContainer {
    justify-content: flex-start !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px 8px;
    .flatpickr-day {
      width: 14.28% !important;
      max-width: 14.28% !important;
      background-color: transparent !important;
      border: none !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #000000 !important;
      &.prevMonthDay,
      &.nextMonthDay {
        color: #b1b1b1 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 38px;
        height: 38px;
        background-color: transparent;
        border-radius: 50%;
        z-index: -1;
        transition: 0.5s;
        border: 1px solid transparent;
      }
      &:hover {
        &::before {
          background-color: #f2f2f2;
        }
      }
      &.today {
        &::before {
          border: 1px solid var(--primaryColor);
        }
      }
      &.selected {
        position: relative;
        &::before {
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);
        }
        &:hover {
          &::before {
            background-color: var(--primaryColor);
            border: 1px solid var(--primaryColor);
          }
        }
      }
    }
  }
}

.flatpickr-time {
  .numInputWrapper {
    width: calc(50% - 34px) !important;
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
    .numInput {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #000000 !important;
    }
    .arrowUp {
      padding: 0px !important;
      top: calc(50% + 1px) !important;
      transform: translateY(-50%) rotate(90deg);
      right: calc(50% - 32px) !important;
      width: 16px !important;
      height: 16px !important;
      border: none !important;
      border-radius: 4px;
      &:after {
        top: 5px !important;
        left: 4px !important;
      }
    }
    .arrowDown {
      padding: 0px !important;
      top: calc(50% + 1px) !important;
      transform: translateY(-50%) rotate(90deg);
      left: calc(50% - 32px) !important;
      width: 16px !important;
      height: 16px !important;
      border: none !important;
      border-radius: 4px;
      &:after {
        top: 7px !important;
        left: 4px !important;
      }
    }
  }
  .flatpickr-time-separator {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    width: 8px !important;
    text-align: center !important;
  }
  .flatpickr-am-pm {
    width: 60px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    transition: 0.5s;
    &:hover {
      background-color: #f2f2f2 !important;
    }
  }
}

.flatpickr-calendar.rangeMode {
  .flatpickr-day.inRange,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.selected.endRange {
    box-shadow: unset !important;
    &:before {
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      border-radius: 0px;
      opacity: 0.2;
      transition: 0s;
    }
  }
  .flatpickr-day.selected.startRange {
    &:before {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: var(--primaryColor) !important;
      opacity: 1;
      transition: 0s;
    }
  }
  .flatpickr-day.selected.endRange {
    &:before {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background-color: var(--primaryColor) !important;
      opacity: 1;
      transition: 0s;
    }
  }
}
// datetime custom picker end

// badge css start
.badge {
  padding: 8px 16px;
  font-size: 14px;
}
// badge css end

// form element css start
.input-group {
  .input-group-text {
    background-color: #ffffff;
  }
  input:focus + .input-group-text {
    background-color: #ffffff;
  }
}
.form-label {
  margin-bottom: 5px;
  color: var(--primaryColorDark);
  font-weight: 600;
  font-size: 16px;
}
input.form-control {
  // padding: 11px 14px 13px 14px;
  // border-radius: 10px;
  padding: 8px 14px 8px 14px;
  border-radius: 7px;
  font-size: 16px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  font-size: 14px;
  height: 41.2px;
  &:focus {
    border: 1px solid var(--primaryColor);
  }
}
input.form-control[disabled] {
  background-color: #f9f9f9;
  color: #b1b1b1;
}
textarea.form-control {
  // padding: 11px 14px 13px 14px;
  // border-radius: 10px;
  padding: 8px 14px 8px 14px;
  border-radius: 7px;
  font-size: 16px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  font-size: 14px;
  height: 100px;
  width: 100%;
  outline: none;
  &:focus {
    border: 1px solid var(--primaryColor);
  }
}
textarea.form-control[disabled] {
  background-color: #f9f9f9;
  color: #b1b1b1;
}
.mention-wrapper__input {
  padding: 7px 14px 9px 14px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  border: 1px solid #e8e8e8 !important;
  background-color: #ffffff !important;
  &:focus {
    border: 1px solid var(--primaryColor) !important;
  }
}
.mention-wrapper__input::placeholder {
  opacity: 1 !important;
  color: #b9b9c3 !important;
}
.mention-wrapper__input[disabled] {
  background-color: #f9f9f9 !important;
}
textarea.form-control {
  padding: 11px 14px 13px 14px !important;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  font-size: 16px;
  height: 100px;
}
.form-element-icon-wrapper {
  position: relative;
  svg {
    // width: 20px;
    // height: 20px;
    // position: absolute;
    // top: 12px;
    // left: 13px;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 11px;
    left: 12px;
  }
  input {
    padding-left: 40px;
  }
}
.react-tel-input {
  input.form-control {
    // padding: 12px 14px 12px 48px;
    padding: 9px 14px 10px 48px;
    // border-radius: 10px;
    border-radius: 7px;
    font-size: 16px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    height: auto;
    line-height: 20px;
    &:focus {
      border: 1px solid var(--primaryColor);
    }
  }
  .flag-dropdown {
    border: none !important;
    border-right: 1px solid #e8e8e8 !important;
    background-color: #ffffff !important;
    top: 1px !important;
    bottom: unset !important;
    height: calc(100% - 2px);
    left: 1px;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    .selected-flag {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }
}
.select__control {
  padding: 4px 3px;
  border-radius: 10px;
  font-size: 14px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  &.select__control--is-focused {
    border: 1px solid var(--primaryColor) !important;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
  }
}

.react-select {
  position: relative;
}

.form-error {
  font-size: 15px;
  margin-top: 5px;
  color: red;
  display: block;
}

.select__control {
  .select__placeholder {
    color: #b9b9c3 !important;
    font-size: 16px;
  }
}

.form-check-input:checked[type='checkbox'] {
  background-size: 72%;
}

.radio-switch-toggle-btn-wrapper {
  .radio-btn-repeater {
    padding-left: 0px;
    margin-right: 30px !important;
    position: relative;
    input {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      left: 0px;
      cursor: pointer;
      margin-left: 0px;
    }
    .form-check-label {
      padding-right: 65px;
      position: relative;
      font-size: 13px;
      font-weight: 600;
      color: #000;
      &:before {
        content: '';
        width: 54px;
        height: 28px;
        display: inline-block;
        transition: 0.5s;
        position: absolute;
        top: -2px;
        background-color: #fff;
        border-radius: 60px;
        // box-shadow: inset 0 0 4px #cecece;
        border: 1px solid #e4e4e4;
        right: 0px;
        transition: 0.5s;
        cursor: pointer;
      }
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        width: 20px;
        height: 20px;
        transition: 0.5s;
        right: 29px;
        border-radius: 50%;
        background-color: #eaeaea;
        transition: 0.5s;
        cursor: pointer;
      }
    }
    input:checked + .form-check-label:before {
      background-color: var(--primaryColor);
      border: 1px solid transparent;
    }
    input:checked + .form-check-label:after {
      right: 6px;
      background-color: #ffffff;
      transform: unset;
    }
  }
}

.customRadio-btn-wrapper {
  .radio-btn-repeater {
    position: relative;
    padding-left: 0px;
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: transparent;
      opacity: 0;
    }
    .form-label {
      position: relative;
      padding-left: 30px;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ccc;
      }
      &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 0;
      }
    }
    input:checked + .form-label {
      &:before {
        background-color: var(--primaryColor);
        border: 2px solid var(--primaryColor);
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

.checkbox-switch-toggle-btn-wrapper {
  .checkbox-btn-repeater {
    padding-left: 0px;
    margin-right: 30px !important;
    position: relative;
    input {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      left: 0px;
      cursor: pointer;
      margin-left: 0px;
    }
    .form-check-label {
      padding-right: 65px;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      &:before {
        content: '';
        width: 54px;
        height: 28px;
        display: inline-block;
        transition: 0.5s;
        position: absolute;
        top: -2px;
        background-color: #fff;
        border-radius: 60px;
        // box-shadow: inset 0 0 4px #cecece;
        border: 1px solid #e4e4e4;
        right: 0px;
        transition: 0.5s;
        cursor: pointer;
      }
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        width: 20px;
        height: 20px;
        transition: 0.5s;
        right: 29px;
        border-radius: 50%;
        background-color: #eaeaea;
        transition: 0.5s;
        cursor: pointer;
      }
    }
    input:checked + .form-check-label:before {
      background-color: var(--primaryColor);
      border: 1px solid transparent;
    }
    input:checked + .form-check-label:after {
      right: 6px;
      background-color: #ffffff;
    }
  }
}

.select__multi-value,
.custom-select__multi-value {
  background-color: var(--primaryColor);
  border-radius: 5px !important;
  margin-right: 5px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.select__multi-value .select__multi-value__label,
.custom-select__multi-value .custom-select__multi-value__label {
  color: #000000 !important;
}
.select__multi-value__remove,
.custom-select__multi-value__remove {
  background-color: transparent;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  color: #000000 !important;
}
.custom-select__multi-value {
  margin: 0px;
  margin-right: 5px !important;
  .custom-select__multi-value__label {
    border-radius: 0.357rem;
    padding: 0.26rem 0.6rem;
    font-size: 0.85rem;
  }
  .custom-select__multi-value__remove {
    padding-left: 0;
    padding-right: 0.5rem;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    background-color: transparent;
    svg {
      height: 0.85rem;
      width: 0.85rem;
    }
  }
}

.select__menu .select__menu-list .select__option.select__option--is-focused,
.select__menu
  .select__menu-list
  .react-select__option.select__option--is-focused,
.select__menu
  .react-select__menu-list
  .select__option.select__option--is-focused,
.select__menu
  .react-select__menu-list
  .react-select__option.select__option--is-focused,
.react-select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.react-select__menu
  .select__menu-list
  .react-select__option.select__option--is-focused,
.react-select__menu
  .react-select__menu-list
  .select__option.select__option--is-focused,
.react-select__menu
  .react-select__menu-list
  .react-select__option.select__option--is-focused {
  color: var(--primaryColorDark) !important;
}

.editor-class {
  position: relative;
  z-index: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 3;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: #e1e1e1;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: #ffffff;
}
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before {
  border-top-color: #e1e1e1;
}
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::after {
  border-top-color: #f7f7f7;
}
.react-datepicker-popper {
  z-index: 5;
}
.react-datepicker {
  border: none;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  .react-datepicker__aria-live {
    display: none;
  }
  .react-datepicker__navigation {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 2px 2px 8px #f0f0f0;
    transition: 0.5s;
    position: absolute;
    font-size: 0px;
    .react-datepicker__navigation-icon {
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-left: 2px solid #000000;
      border-bottom: 2px solid #000000;
    }
    &:hover {
      .react-datepicker__navigation-icon {
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
    }
    &.react-datepicker__navigation--previous {
      top: 9px;
      left: 10px;
      .react-datepicker__navigation-icon {
        left: 13px;
        top: 11px;
        transform: rotate(45deg);
      }
    }
    &.react-datepicker__navigation--next {
      top: 9px;
      right: 10px;
      .react-datepicker__navigation-icon {
        left: 10px;
        top: 12px;
        transform: rotate(-135deg);
      }
    }
    &:hover {
      background-color: #000000;
      border: 1px solid #000000;
      .react-datepicker__navigation-icon {
        &:before {
          border-color: #ffffff;
        }
      }
    }
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding: 0px;
    .react-datepicker__header__dropdown {
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background-color: #dfdfdf;
      }
      .react-datepicker__month-dropdown-container,
      .react-datepicker__year-dropdown-container {
        width: 50%;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 10px;
        position: relative;
        .react-datepicker__month-read-view,
        .react-datepicker__year-read-view {
          display: flex;
          align-items: center;
          visibility: visible !important;
        }
        .react-datepicker__month-read-view--selected-month,
        .react-datepicker__year-read-view--selected-year {
          font-size: 14px;
          font-weight: 600;
          order: -1;
          margin-right: 6px;
        }
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
          position: relative;
          left: 0px;
          top: -1px;
          border-top: 1px solid #000000;
          border-right: 1px solid #000000;
          width: 8px;
          height: 8px;
          transform: rotate(135deg);
        }
        .react-datepicker__year-read-view--down-arrow {
          top: -2px;
        }
        .react-datepicker__month-dropdown {
          width: 100%;
          top: 100%;
          left: 0px;
          border: 1px solid #dfdfdf;
          background-color: #f7f7f7;
          border-radius: 0px;
          position: absolute;
          top: 100%;
          left: 0px;
          width: 100%;
          .react-datepicker__month-option {
            font-size: 14px;
            padding: 5px 10px;
            text-align: left;
            &.react-datepicker__month-option--selected_month {
              position: relative;
              .react-datepicker__month-option--selected {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                left: unset;
              }
            }
            &:hover {
              background-color: #eaeaea;
            }
          }
        }
        .react-datepicker__year-dropdown {
          width: 100%;
          top: 100%;
          left: 0px;
          border: 1px solid #dfdfdf;
          background-color: #f7f7f7;
          border-radius: 0px;
          position: absolute;
          top: 100%;
          left: 0px;
          width: 100%;
          .react-datepicker__year-option {
            font-size: 14px;
            padding: 5px 10px;
            text-align: center;
            position: relative;
            min-height: 30px;
            &.react-datepicker__year-option--selected_year {
              position: relative;
              .react-datepicker__year-option--selected {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                left: unset;
              }
            }
            &:hover {
              background-color: #eaeaea;
            }
          }
          .react-datepicker__navigation {
            background-color: transparent;
            width: 24px;
            height: 24px;
            border: none;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            &:before {
              content: '';
              position: absolute;
              top: 8px;
              left: calc(50% - 4px);
              transform: translateX(-50%);
              width: 8px;
              height: 8px;
              border-left: 1px solid #000000;
              border-bottom: 1px solid #000000;
              transform: rotate(45deg);
            }
            &.react-datepicker__navigation--years-upcoming {
              &:before {
                transform: rotate(135deg);
                top: 10px;
              }
            }
            &.react-datepicker__navigation--years-previous {
              &:before {
                transform: rotate(-45deg);
                top: 11px;
              }
            }
          }
        }
      }
    }
    .react-datepicker__current-month {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
      padding: 14px 48px;
      text-align: center;
    }
    .react-datepicker__day-names {
      padding: 8px 8px 0px 8px;
      display: flex;
      justify-content: center;
      .react-datepicker__day-name {
        padding: 2px;
        width: 14.28%;
        font-size: 14px !important;
        font-weight: 600 !important;
        color: #b1b1b1 !important;
        margin: 0px 0px;
        text-align: center;
      }
    }
  }
  .react-datepicker__month {
    margin: 0px;
    padding: 8px 8px 8px 8px;
  }
  .react-datepicker__day {
    margin: 0px;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    width: 36px;
    height: 36px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 2px 4px;
    &:hover {
      background-color: #f2f2f2;
    }
    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
      background-color: var(--primaryColor);
    }
    &.react-datepicker__day--outside-month {
      pointer-events: none;
      color: #b1b1b1 !important;
    }
  }
  .react-datepicker__input-time-container {
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .react-datepicker-time__caption {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
    }
    .react-datepicker-time__input {
      margin-left: 0px;
    }
    input.react-datepicker-time__input {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      background-color: #ffffff;
      border-radius: 7px;
      padding: 5px 10px;
      border: 1px solid #e6e6e6;
    }
  }
}

.form-range::-moz-range-track {
  background-color: #f5f5f5;
  height: 12px;
}
.form-range::-moz-range-thumb {
  background-color: var(--primaryColor);
  width: 22px;
  height: 22px;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 1px #f8f8f8, 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
.form-range:focus::-moz-range-thumb {
  background-color: var(--primaryColor);
  width: 22px;
  height: 22px;
  border: 1px solid #ffffff;
  box-shadow: 2px 2px 10px #d0d0d0;
}
.form-range{
  -webkit-appearance: none;
  appearance: none; 
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 16px;
}
.form-range::-webkit-slider-runnable-track{
  height: 12px;
  background: #f5f5f5;
  // background: #ffffff;
  border-radius: 16px;
}
.form-range::-webkit-range-thumb{
  background-color:var(--primaryColor);
}
.form-range::-webkit-slider-thumb,
.form-range:focus::-webkit-slider-thumb{
  -webkit-appearance: none;
  appearance: none; 
  background-color: var(--primaryColor);
  width: 22px;
  height: 22px;
  border: 1px solid #ffffff;
  box-shadow: 2px 2px 10px #d0d0d0;
  position: relative;
  top:-2px;
  opacity:1 !important;
}

body .mentions.mentions--multiLine {
  margin-top: 0px;
  margin-bottom: 0px;
  .mentions__control {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    font-family: inherit !important;
    font-size: 16px;
    .mentions__highlighter {
      padding: 7px 12px 10px 12px;
      min-height: 100px;
    }
    textarea {
      padding: 7px 12px 10px 12px;
      &::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
    }
    .mentions__mention {
      position: relative;
      background-color: var(--primaryColor);
      position: relative;
      left: -3px;
      top: -2px;
    }
  }
}
.mentions__suggestions {
  z-index: 99 !important;
}
.mentions__suggestions__list {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background: white;
  max-height: 270px;
  overflow-y:auto;
  .mentions__suggestions__item {
    border-bottom: 1px solid #eee;
    padding: 8px 12px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    height: 38px;
    &:last-child {
      border-bottom: none;
    }
    .mentions__suggestions__item__display {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      .mentions__suggestions__item__highlight {
        font-weight: 600;
      }
    }
    &:hover,
    &.mentions__suggestions__item--focused {
      background-color: #eee;
    }
  }
}

.tribute-container {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  ul {
    background-color: transparent !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 270px;
    overflow-y: auto;
  }
  li {
    border-bottom: 1px solid #eee;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    width: 100%;
    max-width: 280px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    &:last-child {
      border-bottom: none;
    }
    &.highlight {
      background-color: #eee;
    }
  }
}
// form element css end

.no-record-found-table {
  .img-wrapper {
    img {
      width: 400px;
      height: auto;
      display: block;
      max-width: 100%;
      margin: 0px auto;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }
  .text {
    font-size: 16px;
    color: grey;
    width: 350px;
    max-width:100%;
    text-align: center;
    margin: 0px auto;
    margin-top: 7px;
  }
}

.no-data-found {
  padding: 20px 10px;
  .img-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    margin: 0px auto;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .title {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
}

.no-data-wrapper {
  // background-color:rgba(33, 22, 145,0.1);
  border-radius: 12px;
  .icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px auto;
    margin-bottom: 12px;
    padding: 12px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--primaryColor);
      opacity: 0.1;
    }
    svg {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
    }
  }
  .text {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: var(--primaryColor);
    margin-bottom: 0px;
    width: 100%;
    display: block;
  }
}

.dropdown-menu {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-height: 260px;
  overflow-y: auto;
}
.dropdown-item {
  width: 100% !important;
  color: #000000 !important;
  &:hover,
  &:focus {
    background-color: rgba(168, 168, 168, 0.12);
    color: var(--primaryColorDark) !important;
  }
}

.rdw-editor-wrapper {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  .rdw-editor-toolbar {
    border: none;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 6px 2px 6px;
    margin-bottom: 0px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    & > div {
      margin-bottom: 0px !important;
    }
    .rdw-option-wrapper {
      min-width: 32px;
      height: 32px;
      margin: 0px 4px 4px 0px !important;
      border: none;
      border-radius: 5px;
      box-shadow: unset !important;
      &:hover {
        background-color: #f0f0f0;
      }
      &.rdw-option-active {
        background-color: #f0f0f0;
      }
    }
    .rdw-dropdown-wrapper {
      box-shadow: unset;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      font-size: 14px;
      margin-bottom: 4px;
      .rdw-dropdown-carettoopen {
        border-top: 4px solid black;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
      }
      .rdw-dropdown-carettoclose {
        border-bottom: 4px solid black;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
      }
      .rdw-dropdown-selectedtext {
        padding: 0 18px 0px 7px;
        span {
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:hover {
        background-color: #f0f0f0;
      }
      &.rdw-fontsize-dropdown {
        min-width: 50px;
      }
    }
  }
  .rdw-dropdown-optionwrapper {
    width: 100%;
    top: 2px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    .rdw-dropdownoption-default {
      min-height: unset;
      padding: 4px 7px !important;
      line-height: 18px;
      &.rdw-dropdownoption-highlighted {
        background-color: #f0f0f0;
      }
      &.rdw-dropdownoption-active {
        background-color: var(--primaryColor);
        color: #ffffff;
      }
      &.rdw-fontsize-option {
        justify-content: flex-start;
      }
    }
  }
  .public-DraftStyleDefault-block {
    margin: 0px !important;
  }
  .rdw-emoji-modal {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 6px;
    width: 218px;
  }
  .rdw-link-modal {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 8px;
    height: 200px;
    .rdw-link-modal-label {
      color: #7d9943;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 4px !important;
    }
    input {
      padding: 4px 8px;
      border-radius: 6px;
      border: 1px solid #e8e8e8;
      background-color: #f9f9f9;
      color: #6e6b7b;
      appearance: none;
      font-weight: 400;
      font-size: 12px;
      margin-top: 0px;
      height: 28px !important;
      margin-bottom: 10px;
      &::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
      &:focus {
        border: 1px solid var(--primaryColor);
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        background-color: #fff;
      }
    }
    .rdw-link-modal-buttonsection {
      width: 100%;
      margin: 0px;
      display: flex;
      justify-content: space-between;
      .rdw-link-modal-btn {
        margin: 0px !important;
        width: calc(50% - 5px) !important;
        &:first-child {
          background-color: var(--primaryColor);
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: #fff;
          border: 1px solid var(--primaryColor);
          &:hover {
            box-shadow: 0 8px 25px -8px var(--primaryColor);
            border: none;
          }
        }
        &:nth-child(2) {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: #6c6c6c;
          border: 1px solid #82868b;
          &:hover {
            box-shadow: 0 8px 25px -8px #82868b;
            border: 1px solid #82868b;
          }
        }
      }
    }
    .rdw-link-modal-target-option {
      display: flex;
      align-items: center;
      font-size: 13px;
      position: relative;
      margin-bottom: 10px;
      input {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100% !important;
        opacity: 0;
        z-index: 2;
        margin: 0px;
        padding: 0px;
        cursor: pointer;
      }
      span {
        position: relative;
        margin: 0px;
        padding-left: 24px;
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 18px;
          height: 18px;
          border-radius: 5px;
          background-color: #eaeaea;
        }
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 4px;
          width: 10px;
          height: 6px;
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(-45deg);
          transition: 0.5s;
          opacity: 0;
        }
      }
      input:checked + span {
        &:before {
          background-color: var(--primaryColor);
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .rdw-embedded-modal {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 8px;
    height: 185px;
    .rdw-image-mandatory-sign {
      display: none;
    }
    input {
      padding: 4px 8px;
      border-radius: 6px;
      border: 1px solid #e8e8e8;
      background-color: #f9f9f9;
      color: #6e6b7b;
      appearance: none;
      font-weight: 400;
      font-size: 12px;
      margin-top: 0px;
      height: 28px !important;
      margin-bottom: 10px;
      width: 100% !important;
      &::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
      &:focus {
        border: 1px solid var(--primaryColor);
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        background-color: #fff;
      }
    }
    .rdw-embedded-modal-size {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0px;
      &:before {
        content: 'Dimension (w/h)';
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        display: block;
        margin-bottom: 5px;
      }
      & > span {
        display: block;
        width: calc(50% - 6px);
      }
    }
    .rdw-embedded-modal-header {
      .rdw-embedded-modal-header-option {
        display: block;
        width: 100% !important;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      .rdw-embedded-modal-header-label {
        display: none;
      }
    }
    .rdw-embedded-modal-btn-section {
      width: 100%;
      margin: 0px;
      display: flex;
      justify-content: space-between;
      .rdw-embedded-modal-btn {
        margin: 0px !important;
        width: calc(50% - 5px) !important;
        &:first-child {
          background-color: var(--primaryColor);
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: #fff;
          border: 1px solid var(--primaryColor);
          &:hover {
            box-shadow: 0 8px 25px -8px var(--primaryColor);
            border: none;
          }
        }
        &:nth-child(2) {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: #6c6c6c;
          border: 1px solid #82868b;
          &:hover {
            box-shadow: 0 8px 25px -8px #82868b;
            border: 1px solid #82868b;
          }
        }
      }
    }
  }
  .rdw-image-modal {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 8px;
    height: 185px;
    .rdw-image-mandatory-sign {
      display: none !important;
    }
    input {
      padding: 4px 8px;
      border-radius: 6px;
      border: 1px solid #e8e8e8;
      background-color: #f9f9f9;
      color: #6e6b7b;
      appearance: none;
      font-weight: 400;
      font-size: 12px;
      margin-top: 0px;
      height: 28px !important;
      margin-bottom: 10px;
      width: 100% !important;
      &::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
      &:focus {
        border: 1px solid var(--primaryColor);
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        background-color: #fff;
      }
    }
    .rdw-image-modal-size {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 0px;
      margin: 0px;
      margin-bottom: 4px;
      &:before {
        content: 'Dimension (w/h)';
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        display: block;
        margin-bottom: 5px;
      }
      input {
        display: block;
        width: calc(50% - 6px) !important;
      }
    }
    .rdw-image-modal-header {
      margin: 0px !important;
      margin-bottom: 7px !important;
      .rdw-image-modal-header-option {
        display: block;
        width: 100% !important;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      .rdw-image-modal-header-label {
        display: none;
      }
    }
    .rdw-image-modal-btn-section {
      width: 100%;
      margin: 0px;
      display: flex;
      justify-content: space-between;
      .rdw-image-modal-btn {
        margin: 0px !important;
        width: calc(50% - 5px) !important;
        &:first-child {
          background-color: var(--primaryColor);
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: #fff;
          border: 1px solid var(--primaryColor);
          &:hover {
            box-shadow: 0 8px 25px -8px var(--primaryColor);
            border: none;
          }
        }
        &:nth-child(2) {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: #6c6c6c;
          border: 1px solid #82868b;
          &:hover {
            box-shadow: 0 8px 25px -8px #82868b;
            border: 1px solid #82868b;
          }
        }
      }
    }
  }
  .rdw-colorpicker-modal {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 8px;
    width: 165px;
    .rdw-colorpicker-modal-header {
      display: flex;
      flex-wrap: wrap;
      margin-top: -6px;
      .rdw-colorpicker-modal-style-label {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        width: 50%;
        border-bottom: 2px solid transparent;
        padding: 6px 0px;
        &.rdw-colorpicker-modal-style-label-active {
          color: var(--primaryColor);
          border-bottom: 2px solid var(--primaryColor);
        }
      }
    }
    .rdw-colorpicker-modal-options {
      margin-bottom: 0px;
    }
  }
  .rdw-editor-main{
    min-height:225px !important;
  }
}

.imgupload-dropzon {
  border: 2px dashed var(--primaryColor);
  border-radius: 12px;
  // background-color:#f5f9fc;
  background-color: #fafff4;
  display: flex;
  align-items: center;
  padding: 12px;
  .left-preview {
    width: 110px;
    height: 110px;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
    .fileIcon-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        border-radius: 10px;
        opacity: 0.2;
      }
      svg {
        position: relative;
        z-index: 2;
        color: var(--primaryColor);
      }
    }
  }
  .right-details {
    width: calc(100% - 110px);
    padding-left: 15px;
    .title {
      font-size: 20px;
      color: var(--primaryColor);
      font-weight: 600;
    }
  }
  .image-upload-wrapper {
    .upload-btn,
    .reset-btn {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px !important;
    }
    .upload-btn {
      margin-right: 12px;
    }
    .reset-btn {
      background-color: #ffffff !important;
      color: #6c6c6c !important;
    }
  }
}

// card css start
.card-header {
  background-color: #f9f9f9;
  padding: 14px 20px;
  border-bottom: 1px solid #eaeaea;
  .card-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #000000 !important;
  }
}
// card css end

// thankyou-wrapper
.thankyou-wrapper{
  .icon-wrapper{
    width:105px;
    height:105px;
    margin:0px auto 0px auto;
    position: relative;
    padding:15px;
    margin-bottom:15px;
    svg{
      fill:var(--primaryColor);
      position: relative;
      z-index:2;
    }
    &:before{
      content:"";
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      border-radius:50%;
      background-color:var(--primaryColor);
      opacity:0.1;
    }
  }
  .title{
    font-size:24px;
    text-align:center;
    margin-bottom:10px;
    font-weight:600;
    color:#000000;
    margin-top:24px;
  }
  .discription-text{
    font-size:16px;
    text-align:center;
    font-weight:400;
  }
  .submit-btn-wrapper{
    display:flex;
    justify-content:center;
    margin-top:25px;
  }
}
// thankyou-wrapper-end

.modal {
  .modal-header {
    position: relative;
    padding: 14px 52px 14px 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    .modal-title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }
    .btn-close {
      position: absolute;
      top: 50%;
      right: 9px;
      transform: translate(0px, -50%) !important;
      margin: 0px;
      box-shadow: unset;
      border-radius: 50%;
      background-color: transparent;
      transition: 0.5s;
      &:hover {
        background-color: #e6e6e6;
      }
    }
  }
  .modal-content {
    border-radius: 14px;
  }
  .modal-body {
    padding: 20px 20px;
  }
  .modal-footer {
    padding: 15px 20px;
    .auth-login-form {
      margin: 0px;
      .btn-primary {
        padding-left: 12px;
        padding-right: 12px;
        width: 148px !important;
      }
    }
    .btn-danger {
      background-color: #ffffff !important;
      color: #6c6c6c !important;
      border-color: #82868b !important;
      font-size: 14px;
      font-weight: 500;
      margin: 0px;
      padding-left: 12px;
      padding-right: 12px;
      width: 148px;
      &:hover {
        box-shadow: 0 8px 25px -8px #82868b !important;
      }
    }
    & > *:last-child {
      margin-left: 12px;
    }
  }
}

// thankyou-modal
.thankyou-modal{
  &.modal-dialog{
    width:460px;
    max-width:calc(100% - 30px);
    margin:0px auto !important;
    padding-right:0px !important;
  }
  .modal-content{
    border-radius:25px;
  }
  .modal-header{
    padding:0px 0px;
    width:34px;
    height:34px;
    background-color:transparent;
    position:absolute;
    top:8px;
    right:8px;
    z-index:3;
    .modal-title{
      display:none;
    }
    .btn-close{
      position:static;
      transform:unset !important;
    }
  }
  .modal-body{
    padding:40px 20px;
  }
}
// thankyou-modal-end

.section-title-second-heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #000000 !important;
}

.dropdown-menu[data-popper-placement]:not([data-popper-placement^='top-']) {
  top: 3px !important;
}

// react select css start
.table__page__limit .table__page__limit__dynamic__control,
.table__page__limit .table__page__limit__dynamic__control:hover,
.table__page__limit div[class$='control'],
.table__page__limit div[class$='control']:hover {
  background-color: #f6f6f6;
  background-color: #f6f6f6;
  border-radius: 6px;
  border-style: none;
  // min-height: 44px;
  min-height: 41.2px;
  // padding: 7px 38px 7px 16px;
  padding: 4px 38px 5px 16px;
  box-shadow: unset !important;
  cursor: pointer;
}
.table__page__limit
  .table__page__limit__dynamic__control--menu-is-open.table__page__limit__dynamic__control,
.table__page__limit
  .table__page__limit__dynamic__control--menu-is-open.table__page__limit__dynamic__control:hover {
  padding-bottom: 6px;
  padding-top: 6px;
}
.table__page__limit .table__page__limit__dynamic__value-container,
.table__page__limit div[class$='ValueContainer'] {
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 0;
  padding-right: 10px !important;
  position: relative;
  width: 100%;
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value,
.table__page__limit div[class$='ValueContainer'] div[class$='multiValue'] {
  background-color: #1f1490;
  background-color: #1f1490;
  border-radius: 6px;
  display: inline-flex;
  margin: 0 5px 5px 0;
  padding: 4px 22px 3px 10px;
  position: relative;
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value:nth-last-of-type(2),
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']:nth-last-of-type(2) {
  margin-right: 10px;
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:first-child,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:first-child {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 0;
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:last-child,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:last-child {
  height: 12px;
  padding: 0;
  position: absolute;
  right: 6px;
  top: 6px;
  width: 12px;
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:last-child:before,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:last-child:before {
  content: '';
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:last-child:after,
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:last-child:before,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:last-child:after,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:last-child:before {
  content: '';
  background-color: #fff;
  border-radius: 10px;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 10px;
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:last-child:after,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:last-child:after {
  content: '';
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.table__page__limit
  .table__page__limit__dynamic__value-container
  .table__page__limit__dynamic__multi-value
  > div:last-child:hover,
.table__page__limit
  div[class$='ValueContainer']
  div[class$='multiValue']
  > div:last-child:hover {
  background-color: #fff3;
}
.table__page__limit .table__page__limit__dynamic__placeholder,
.table__page__limit div[class$='placeholder'] {
  color: #babbbc;
  font-weight: 400;
  font-size: 16px;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.table__page__limit
  .table__page__limit__dynamic__value-container--is-multi
  .table__page__limit__dynamic__placeholder {
  position: relative;
  top: -1px;
}
.table__page__limit .table__page__limit__dynamic__single-value,
.table__page__limit div[class$='singleValue'] {
  color: black;
  font-weight: 400;
  font-size: 16px;
}
.table__page__limit
  .table__page__limit__dynamic__control--menu-is-open
  .table__page__limit__dynamic__value-container--has-value {
  display: flex;
}
.table__page__limit .table__page__limit__dynamic__input,
.table__page__limit .table__page__limit__dynamic__input-container,
.table__page__limit div[class$='Input'] {
  font-weight: 400;
  font-size: 16px !important;
  height: 30px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.table__page__limit
  .table__page__limit__dynamic__multi-value
  + .table__page__limit__dynamic__input-container,
.table__page__limit
  .table__page__limit__dynamic__multi-value
  + .table__page__limit__dynamic__input-container
  .table__page__limit__dynamic__input,
.table__page__limit div[class$='multiValue'] + div[class$='Input'] {
  position: static;
  width: auto;
}
.table__page__limit
  .table__page__limit__dynamic__multi-value
  + .table__page__limit__dynamic__input-container
  .table__page__limit__dynamic__input
  input,
.table__page__limit
  .table__page__limit__dynamic__multi-value
  + .table__page__limit__dynamic__input-container
  input,
.table__page__limit
  .table__page__limit__dynamic__value-container--is-multi
  .table__page__limit__dynamic__input-container
  .table__page__limit__dynamic__input
  input,
.table__page__limit
  .table__page__limit__dynamic__value-container--is-multi
  .table__page__limit__dynamic__input-container
  input,
.table__page__limit div[class$='multiValue'] div[class$='Input'] input,
.table__page__limit div[class$='multiValue'] + div[class$='Input'] input {
  position: relative !important;
  top: -2px;
}
.table__page__limit .table__page__limit__dynamic__indicator-separator,
.table__page__limit span[class$='indicatorSeparator'] {
  display: none;
}
.table__page__limit .table__page__limit__dynamic__indicators,
.table__page__limit div[class$='IndicatorsContainer'] {
  cursor: pointer;
  margin-right: -28px;
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:last-child,
.table__page__limit div[class$='indicatorContainer']:last-child {
  height: 20px;
  padding: 0;
  position: relative;
  width: 20px;
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:last-child:before,
.table__page__limit div[class$='indicatorContainer']:last-child:before {
  content: '';
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  left: calc(50% - 3px);
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:last-child:after,
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:last-child:before,
.table__page__limit div[class$='indicatorContainer']:last-child:after,
.table__page__limit div[class$='indicatorContainer']:last-child:before {
  background-color: #000000;
  border-radius: 10px;
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9px;
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:last-child:after,
.table__page__limit div[class$='indicatorContainer']:last-child:after {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  left: calc(50% + 3px);
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(2),
.table__page__limit div[class$='indicatorContainer']:nth-last-of-type(2) {
  cursor: pointer;
  height: 20px;
  padding: 0;
  position: relative;
  width: 20px;
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(2):before,
.table__page__limit
  div[class$='indicatorContainer']:nth-last-of-type(2):before {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  content: '';
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(2):after,
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(2):before,
.table__page__limit div[class$='indicatorContainer']:nth-last-of-type(2):after,
.table__page__limit
  div[class$='indicatorContainer']:nth-last-of-type(2):before {
  background-color: black;
  border-radius: 10px;
  content: '';
  height: 1px;
  left: calc(50% - 1px);
  position: absolute;
  top: 50%;
  width: 12px;
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(2):after,
.table__page__limit div[class$='indicatorContainer']:nth-last-of-type(2):after {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  content: '';
}
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(
    2
  ):hover:after,
.table__page__limit
  .table__page__limit__dynamic__indicator-separator:nth-last-of-type(
    2
  ):hover:before,
.table__page__limit
  div[class$='indicatorContainer']:nth-last-of-type(2):hover:after,
.table__page__limit
  div[class$='indicatorContainer']:nth-last-of-type(2):hover:before {
  background-color: #ff3c3c;
  content: '';
}
.table__page__limit .table__page__limit__dynamic__indicator-separator svg,
.table__page__limit div[class$='indicatorContainer'] svg {
  opacity: 0 !important;
}
.table__page__limit .table__page__limit__dynamic__svg,
.table__page__limit svg[class$='Svg'] {
  fill: #000;
  opacity: 0;
}
.table__page__limit .table__page__limit__dynamic__menu,
.table__page__limit div[class$='menu'] {
  background-color: #ffffff;
  border-top-color: #e5e5e5;
  border-top-left-radius: 0;
  border-top-width: 1px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  margin-bottom: 0;
  margin-top: 2px;
}
.table__page__limit .table__page__limit__dynamic__option,
.table__page__limit div[class$='option'] {
  background-color: initial;
  border-bottom-color: #e5e5e5;
  border-bottom-color: #e5e5e5;
  border-bottom-width: 1px;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
}
.table__page__limit
  .table__page__limit__dynamic__option.table__page__limit__dynamic__option--is-focused,
.table__page__limit
  .table__page__limit__dynamic__option.table__page__limit__dynamic__option--is-focused:focus,
.table__page__limit
  .table__page__limit__dynamic__option.table__page__limit__dynamic__option--is-focused:hover,
.table__page__limit .table__page__limit__dynamic__option:focus,
.table__page__limit .table__page__limit__dynamic__option:hover,
.table__page__limit
  div[class$='option'].table__page__limit__dynamic__option--is-focused,
.table__page__limit
  div[class$='option'].table__page__limit__dynamic__option--is-focused:focus,
.table__page__limit
  div[class$='option'].table__page__limit__dynamic__option--is-focused:hover,
.table__page__limit div[class$='option']:focus,
.table__page__limit div[class$='option']:hover {
  background-color: #f2f2f2;
}
.table__page__limit
  .table__page__limit__option.table__page__limit__option--is-selected,
.table__page__limit
  .table__page__limit__option.table__page__limit__option--is-selected:hover {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
}
.table__page__limit .table__page__limit__dynamic__option:last-child,
.table__page__limit div[class$='option']:last-child {
  border-bottom-width: 0;
}
.table__page__limit .table__page__limit__dynamic__option.css-9gakcf-option,
.table__page__limit
  .table__page__limit__dynamic__option.table__page__limit__dynamic__option--is-selected,
.table__page__limit div.css-9gakcf-option[class$='option'] {
  background-color: #e8f1f8 !important;
  color: var(--primaryColor) !important;
}
.table__page__limit
  .table__page__limit__dynamic__value-container.table__page__limit__dynamic__value-container--is-multi {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  position: relative;
  top: 2px;
}
.table__page__limit
  .table__page__limit__dynamic__value-container--is-multi
  .table__page__limit__dynamic__input-container {
  position: static;
}
.table__page__limit
  .table__page__limit__dynamic__value-container--is-multi
  .table__page__limit__dynamic__placeholder
  + .table__page__limit__dynamic__input-container {
  font-weight: 400;
  font-size: 16px;
  height: 30px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.table__page__limit
  .dropdown__position__static
  .table__page__limit__dynamic__menu-list {
  max-height: 208px;
}
.transparent__formField .ip__input {
  background-color: initial;
  border-color: #e5e5e5;
}
.transparent__formField .ip__input:focus {
  border-color: var(--primaryColor);
}
.table__page__limit__hasIcon,
.ip__form__hasIcon {
  position: relative;
}
.table__page__limit__hasIcon .i__Icon,
.ip__form__hasIcon .i__Icon {
  transform: unset;
  left: 8px;
  opacity: 0.5;
  position: absolute;
  top: 9px;
}
.table__page__limit__hasIcon .i__Icon svg path[fill],
.ip__form__hasIcon .i__Icon svg path[fill] {
  fill: #787878;
}
.table__page__limit__hasIcon .i__Icon svg path[stroke],
.ip__form__hasIcon .i__Icon svg path[stroke] {
  stroke: #787878;
}
.table__page__limit__hasIcon div[class$='control'],
.ip__form__hasIcon .ip__input {
  padding-left: 35px;
}
.table__page__limit.ip__form__hasIcon div[class$='control'] {
  padding-left: 36px;
}

.table__page__limit .table__page__limit__control {
  background-color: transparent !important;
  border: 1px solid #e8e8e8 !important;
}
// react select css end

// custom-react-select
.custom-select__control {
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  // border-radius: 10px;
  // min-height: 49px !important;
  min-height: 41.2px !important;
  border-radius: 7px;
  height: auto;
  // padding-left: 15px;
  padding-left: 12px;
  width: 100% !important;
  .custom-select__placeholder {
    color: #b9b9c3;
    font-size: 16px;
    margin: 0px;
    white-space:pre;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .custom-select__single-value {
    margin: 0px;
    font-size: 14px;
    color: #6e6b7b;
  }
  .custom-select__value-container {
    padding: 0px;
  }
  .custom-select__value-container--is-multi {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .custom-select__input-container {
    padding: 0px;
    margin: 0px;
  }
  .custom-select__indicator-separator {
    display: none;
  }
  .custom-select__indicator {
    color: grey;
    padding: 0px;
  }
}
.custom-select__control.custom-select__control--is-focused {
  box-shadow: unset;
  border: 1px solid var(--primaryColor);
}
.custom-select__menu-portal,
.custom-select__menu{
  // z-index: 5 !important;
  z-index: 1051 !important;
}
.custom-select__menu {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-top: 2px;
  .custom-select__option {
    font-size: 15px;
    color: grey;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-select__option.custom-select__option--is-focused {
    background-color: #f2f2f2;
    color: var(--primaryColorDark) !important;
  }
  .custom-select__option.custom-select__option--is-selected {
    background-color: var(--primaryColor) !important;
    color: #ffffff !important;
  }
  .custom-select__option.custom-select__option--is-selected{
    .avatar{
      background-color:#ffffff !important;
      .avatar-content{
        color:var(--primaryColor);
      }
    }
  }
}
.custom-select__clear-indicator {
  padding: 0px 0px;
  margin-right: 2px;
}
.custom-select__dropdown-indicator {
  margin-right: 7px;
}

.custom_select_wrapper {
  .cs_form_label {
    color: var(--primaryColor);
    font-size: 14px;
    left: 11px;
    top: -9px;
    background-color: white;
    padding: 0px 5px;
    transform: scale(1);
    z-index: 0;
    &.focus-out {
      top: 13px;
      pointer-events: none;
      opacity: 0;
      transition: unset !important;
    }
  }
}
// custom-react-select end

.react-paginate {
  .page-item {
    background-color: #f3f2f7;
    border-radius: 0px !important;
    &.active {
      .page-link {
        background-color: var(--primaryColor) !important;
        color: #ffffff !important;
        &:hover {
          background-color: var(--primaryColor) !important;
          color: #ffffff !important;
        }
      }
    }
  }
  .page-link {
    border-radius: 50% !important;
    min-width: 30px !important;
    height: 30px;
    border-radius: 0px;
    padding: 3px 7px !important;
    border-top-left-radius: 100px !important;
    border-top-right-radius: 100px !important;
    border-bottom-left-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
    transition: 0.5s;
    &:hover {
      background-color: #e1e1e1;
      color: #000000;
    }
  }
  .page-item:nth-child(2),
  .page-item:last-child(3) {
    .page-link {
      border-radius: 50% !important;
      width: 30px;
      height: 30px;
      border-radius: 0px;
      padding: 0px !important;
      border-top-left-radius: 50% !important;
      border-top-right-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  }
  .page-item:nth-child(2) {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .page-item:nth-last-child(2) {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .prev-item,
  .next-item {
    border-radius: 50% !important;
    .page-link {
      border-radius: 50% !important;
      width: 30px;
      height: 30px;
      border-radius: 0px;
      padding: 0px !important;
      &::after {
        position: relative;
        left: 1px;
      }
    }
  }
  .prev-item {
    .page-link {
      &::after {
        left: -1px;
      }
    }
  }
}

.rdt_Table {
  border: 1px solid #ececec;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  &:empty {
    border: none;
  }
  input.form-check-input{
    cursor: pointer;
  }
  .no-record-found-table {
    margin: 30px 0px;
  }
  .rdt_TableHead {
    .form-check-input:checked[type='checkbox'] {
      border: 1px solid #ffffff;
      // background-color: #ffffff;
    }
    .rdt_TableHeadRow {
      border-bottom: 1px solid #ececec;
      background-color: var(--primaryColor);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .rdt_TableCol {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      .rdt_TableCol_Sortable {
        &:hover {
          opacity: 1 !important;
          .__rdt_custom_sort_icon__ {
            opacity: 1 !important;
            svg {
              opacity: 1 !important;
            }
          }
        }
      }
      &:hover {
        color: #000000;
      }
    }
  }
  .rdt_TableBody {
    .rdt_TableRow {
      border-bottom: 1px solid #ececec;
      transition: 0.5s;
      &:last-child {
        border-bottom: none;
      }
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
      &:hover {
        box-shadow: 0px 0px 6px #c6c6c6;
        position: relative;
        z-index: 2;
      }
      .rdt_TableCell {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .action-btn-wrapper {
      display: flex;
      align-items: center;
      .action-btn {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        margin-right: 5px;
        border-radius: 50%;
        transition: 0.5s;
        cursor: pointer;
        padding: 5px;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          background-color: #e3e3e3;
        }
        &.print-btn {
          padding: 6px;
        }
      }
    }
  }
}
.rdt_Table_footer {
  margin-top: 14px;
  .react-paginate {
    margin: 0px !important;
    padding-right: 0px !important;
  }
  .form-label {
    white-space: pre;
    margin-right: 10px;
    margin-bottom: 1px;
  }
}
.rdt_Table_wrapper {
  p {
    margin-bottom: 0px;
  }
  .search-box-wrapper {
    margin-bottom: 13px;
    margin-top: 20px;
    width: 300px;
    max-width: 100%;
    input {
      margin: 0px !important;
      // padding: 10px 14px 12px 40px;
      padding: 7px 14px 9px 40px;
      border-radius: 7px;
    }
  }
  .rdt_Table_infobar {
    position: relative;
    position: relative;
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 20px;
    .inner-cn {
      position: relative;
      z-index: 2;
      color: #000000;
      font-size: 16px;
      .text-primary {
        color: var(--primaryColorDark) !important;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.1;
      border-radius: 8px;
    }
  }
  .switch-checkbox {
    .switch-design {
      width: 50px;
      height: 25px;
      &:before {
        top: 4px;
        width: 17px;
        height: 17px;
      }
    }
  }
  .switch-checkbox input:checked + .switch-design::before {
    left: 28px;
  }
  .user-list-first-name {
    display: flex;
    align-items: center;
    width: 100%;
    .name {
      width: auto;
      max-width: 100%;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        color: var(--primaryColorDark);
        text-decoration: underline;
      }
    }
    .badge-dot-danger {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #bbb;
      flex-shrink: 0;
      margin-left: 10px;
    }
  }
  .action-btn {
    .spinner-border {
      width: 16px;
      height: 16px;
      border-width: 2px;
    }
  }
  .table__page__limit div[class$="control"]{
    padding:4px 28px 5px 10px;
  }
  .table__page__limit div[class$="IndicatorsContainer"]{
    margin-right: -24px;
  }
  .table__page__limit div[class$="ValueContainer"]{
    padding-right:6px !important;
  }
}

.action-btn-wrapper {
  .action-btn {
    .spinner-border {
      width: 16px;
      height: 16px;
      border-width: 2px;
    }
  }
}

// loyal-table-new
.loyal-table-new{
  .table-responsive{
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    overflow:hidden;
  }
  .table-responsive{
    border:1px solid #ececec;
    width:100%;
    overflow:hidden;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
  }
  .table{
    width:100%;
    thead{
      tr{
        th{
          color:#000000;
          font-size:16px;
          font-weight:600;
          background-color:var(--primaryColor) !important;
          padding:12px 15px;
          text-transform:capitalize;
          &:first-child{
            border-top-left-radius:10px;
          }
          &:last-child{
            border-top-right-radius:10px;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          color:#6e6b7b;
          font-size:16px;
          font-weight:400;
          padding:12px 15px;
          border-bottom:1px solid #ececec;
        }
        &:nth-child(even){
          td{
            background-color:#f9f9f9;
          }
        }
      }
    }
    .drag-icon-wrapper{
      width:30px;
      height:30px;
      position: relative;
      cursor: grab !important;
      .drag-icon{
        position: absolute;
        top: 50%;
        width: 20px;
        &:first-child{
          left: calc(50% - 4px);
          transform: translate(-50%, -50%);
        }
        &:last-child{
          left: calc(50% + 4px);
          transform: translate(-50%, -50%);
        }
      }
    }
    .action-btn-wrapper{
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      .action-btn{
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        border-radius: 50%;
        cursor: pointer;
        padding:7px;
        margin-right: 5px;
        svg{
          width:100%;
          height:100%;
          color:#000000;
        }
        &:last-child{
          margin-right:0px;
        }
        &:hover{
          background-color:#e3e3e3;
        }
      }
    }
  }
  .rdt_Table_footer{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:20px;
    .pagination{
      display:inline-flex;
    }
    .table__page__limit{
      width:100px;
    }
  }
  .no-record-found-table{
    padding:35px 0px;
  }
}
// loyal-table-new-end

.swal2-popup {
  padding-bottom: 0px;
  padding: 40px 40px;
  border-radius: 24px;
  .swal2-icon {
    margin: 0px;
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    margin: 0px auto 25px auto;
    .swal2-icon-content {
      color: var(--primaryColor);
    }
  }
  .swal2-title {
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    color: #000000;
    font-weight: 700;
  }
  .swal2-html-container {
    margin: 0px;
    padding: 10px 0px;
    line-height: 25px;
  }
  .swal2-actions {
    margin-top: 0px;
    width: 260px;
    max-width: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: center;
    margin-top: 24px;
    .swal2-confirm {
      width: calc(50% - 6px);
      margin-right: 6px;
    }
    .swal2-cancel {
      margin-left: 0px !important;
      background-color: #ffffff !important;
      color: #6c6c6c !important;
      border: 1px solid #82868b !important;
      width: calc(50% - 6px);
      margin-left: 6px !important;
      &:hover {
        box-shadow: 0 8px 25px -8px #82868b !important;
      }
    }
  }
}

.custom-fileupload {
  border: 2px dashed #a3db59;
  border-radius: 10px;
  background-color: rgba(163, 219, 89, 0.04);
  .inner-wrapper {
    padding: 15px 15px;
  }
  .outsite-CN {
    p {
      margin-bottom: 6px;
    }
  }
  .uploded-items {
    .file-details {
      width: calc(100% - 20px);
      padding-right: 10px;
      display: flex;
      align-items: center;
    }
    .item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--primaryColor);
      padding-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
        border-bottom: none;
        padding-bottom: 0px;
      }
      .file-preview {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .file-name-size {
        font-size: 16px;
        width: calc(100% - 30px);
        padding-left: 10px;
        white-space: pre;
        overflow: hidden;
        p {
          margin-bottom: 0px;
        }
        .file-name{
          white-space:pre;
          overflow:hidden;
          text-overflow:ellipsis;
          max-width:100%;
        }
      }
    }
    .remove-btn {
      width: 20px;
      height: 20px;
      padding: 2px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .attachment-wrapper {
    margin-top: 20px;
    justify-content: center;
    .file__card {
      width: 190px;
      .inner-border-wrapper {
        background-color: #ffffff;
        .inner-wrapper {
          padding-top: 90%;
        }
      }
    }
  }
  .ln-btn {
    display: none;
  }
}

// browse file css start
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: transparent;
}
.form-control::file-selector-button {
  margin: 0px;
  padding: 0px;
  margin-right: 12px;
}
// browse file css end

.tabbing-header-wrapper {
  position: relative;
  margin-bottom: 15px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 90px);
    height: 1px;
    background-color: #d0d0d0;
  }
  .view-btn.active {
    background-color: var(--primaryColor) !important;
    color: #000000 !important;
  }
}
.horizontal-tabbing {
  margin-bottom: 0px;
  display: block;
  margin-top: -5px;
  white-space: pre;
  overflow-x: auto;
  // width:100px;
  // overflow-x:auto;
  // white-space:pre;
  .nav-item {
    display: inline-block;
    .nav-link {
      padding: 12px 20px;
      border: 1px solid #d0d0d0;
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-right: 10px;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    .nav-link.active {
      background-color: #ffffff;
      border: 1px solid var(--primaryColor);
      border-bottom: none;
      &::after {
        display: none;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
    &:last-child {
      .nav-link {
        margin-right: 0px;
      }
    }
  }
}

.react-tel-input {
  .country-list {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
    background-color: #fff !important;
    border: 1px solid #e8e8e8;
    border-radius: 10px !important;
    margin: 5px 0px !important;
    li.country {
      padding: 12px 12px;
      &.highlight {
        background-color: #9cd256;
        color: #fff;
        .flag {
          border: 1px solid white;
        }
        .dial-code {
          color: #ffffff !important;
        }
      }
    }
  }
}

.compnay-checklist-folder-wrapper {
  .card-header {
    .create-folder-link {
      font-size: 14px;
      color: var(--primaryColorDark);
      transition: 0.5s;
      display: inline-block;
      cursor: pointer;
      padding: 0.786rem 1.5rem;
      border-radius: 0.358rem;
      position: relative;
      top: 2px;
      &:hover {
        color: var(--primaryColorDark);
        background-color: #eaeaea;
      }
    }
  }
}

.add-checklist-modal {
  .modal-footer {
    .btn-primary {
      width: 148px !important;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
}

.horizontal-button-tab {
  display: inline-flex;
  align-items: center;
  .view-btn {
    // height: 49px;
    height: 41.2px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    padding: 4px 20px;
    color: var(--primaryColorDark);
    &:hover {
      color: var(--primaryColorDark) !important;
    }
    &.active {
      background-color: var(--primaryColor) !important;
      color: #000000 !important;
    }
  }
}

.attachment-wrapper {
  margin: 0px -10px;
  display: flex;
  flex-wrap: wrap;
  .file__card {
    padding: 0px 10px;
    width: 170px;
    margin-bottom: 20px;
    .video-icon-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ececec;
      border-radius: 5px;
      svg {
        color: #000000;
        width: 70px;
        height: 70px;
      }
    }
    .inner-border-wrapper {
      padding: 5px;
      border-radius: 10px;
      border: 1px solid #cacaca;
      position: relative;
      .action-btn-wrapper {
        display: none;
      }
    }
    .close-btn {
      position: absolute;
      width: 26px;
      height: 26px;
      background-color: #fff;
      border-radius: 50%;
      top: -10px;
      right: -10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      z-index: 4;
      svg {
        width: 100%;
      }
    }
    .inner-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;
      .file__preview {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
        }
      }
      .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        transition: 0.5s;
        .action-btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .action-btn {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          transition: 0.4s;
          margin-right: 5px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
      &:hover {
        .overlay {
          opacity: 1;
          pointer-events: auto;
          z-index: 2;
        }
      }
    }
  }
  .image__edit__wp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px 3px 5px;
    .file-name {
      width: calc(100% - 27px);
      padding-right: 6px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      color: #000000;
      font-size: 14px;
    }
    .file__edit__field {
      padding: 4px 6px;
      font-size: 12px;
      border-radius: 5px;
      min-height: unset;
      height: 26px;
      width: calc(100% - 30px);
    }
    .edit-btn,
    .save-btn {
      width: 26px;
      height: 26px;
      padding: 4px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      cursor: pointer;
      svg {
        width: 100%;
      }
      &:hover {
        background-color: var(--primaryColor);
        color: #ffffff;
        svg {
          color: #ffffff !important;
        }
      }
    }
  }
}

.document-preview-modal {
  margin: 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100dvh;
  min-height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  transition: unset !important;
  .modal-content {
    background-color: transparent;
    height: 100% !important;
    .modal-header {
      background-color: transparent;
      padding: 0px;
      display: inline-block;
      position: relative;
      z-index: 8;
      .modal-title {
        display: none;
      }
      .btn-close {
        background-color: #ffffff;
        position: absolute;
        top: 26px;
        right: 8px;
      }
    }
    .modal-body {
      padding: 58px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
      }
      video {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .doc-view{
    // overflow-y: auto !important;
    .table{
      background-color:#ffffff;
      border-radius: 10px;
      overflow: hidden;
      thead{
        background-color:var(--primaryColor);
        th{
          background-color:var(--primaryColor) !important;
          color:#000000;
          padding:14px 14px;
          &:first-child{
            border-top-left-radius:10px;
          }
          &:last-child{
            border-top-right-radius:10px;
          }
        }
      }
      tbody{
        tr{
          &:nth-child(odd){
            background-color:#f9f9f9;
          }
        }
      }
      td{
        padding:12px 14px;
      }
    }
  }
}

.vertical-tab-wrapper {
  .vertical-tab-item {
    padding: 10px 13px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 1px solid #e8e8e8;
    // &:last-child{
    //   border-bottom:none;
    // }
    &:hover {
      background-color: #f2f2f2;
    }
    &.active {
      background-color: var(--primaryColor);
      color: #000000;
      &:hover {
        background-color: var(--primaryColor);
        color: #000000;
      }
    }
  }
}

// select-boxes-dropdown-start
.selects-custom-dropdown-wrapper {
  display: inline-block;
  position: relative;
  z-index: 5;
  .selects-custom-dropdown {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 300px;
    padding-top: 5px;
    z-index: 5;
    // display:none;
    .inner-wrapper {
      width: 100%;
      background-color: #ffffff;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      max-height: 320px;
      overflow-y: auto;
      .selects-custom-dropdown-item {
        padding: 10px 10px;
        border-bottom: 1px solid #e8e8e8;
        .custom_select_wrapper {
          width: 100%;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  &:hover {
    .task-manager-filters {
      display: block;
    }
  }
}
.task-manager-top-header {
  .selects-custom-dropdown {
    .selects-custom-dropdown {
      .inner-wrapper {
        max-height: 400px;
      }
    }
  }
}
// select-boxes-dropdown-end

.rdw-suggestion-dropdown {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 8px;
  min-width: 180px;
  margin-bottom: 10px;
  .rdw-suggestion-option {
    border-bottom: 1px solid #eee;
    font-size: 14px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    display: block;
    font-weight: 500;
    color: #000000;
    padding: 8px 12px;
    min-height:38px;
    &:hover,
    &.rdw-suggestion-option-active {
      background-color: #eee;
    }
  }
}
.rdw-mention-link {
  position: relative;
  background-color: transparent;
  color: var(--bs-body-color);
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--primaryColor);
    opacity:0;
  }
  span {
    position: relative;
    z-index: 2;
  }
  &:hover {
    color: var(--primaryColorDark);
  }
}

.fr-toolbar {
  width: 100% !important;
  .fr-btn-grp {
    display: flex;
    flex-wrap: wrap;
  }
}

// froala-editor
.fr-box {
  .fr-quick-insert {
    display: none;
  }
  .fr-toolbar,
  .fr-wrapper,
  .fr-second-toolbar {
    border: 1px solid #e8e8e8 !important;
  }
  .fr-toolbar {
    border-bottom: none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding: 8px 10px 5px 10px;
    z-index:1 !important;
    .fr-newline {
      display: none;
    }
    .fr-btn-grp {
      margin: 0px;
      display: flex;
      flex-wrap: wrap;
      .fr-command {
        &.fr-btn {
          margin: 0px 4px 4px 0px !important;
          height: 32px;
          min-width: 32px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          float: none;
          padding: 0px 5px;
          border-left: none !important;
          svg {
            margin: 0px !important;
          }
          &:hover {
            background-color: #f0f0f0;
          }
          &.fr-active {
            background-color: #f0f0f0;
            svg path {
              fill: #000000 !important;
            }
          }
        }
      }
      & > .fr-btn.fr-dropdown {
        &:after {
          position: static;
          margin-left: 3px;
        }
      }
      .fr-btn-wrap {
        margin: 0px 4px 4px 0px !important;
        height: 32px;
        min-width: 32px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        float: none;
        padding: 0px 5px;
        border-left: none !important;
        .fr-command {
          &.fr-btn {
            // width: auto;
            // height: auto;
            width:100%;
            height: auto;
            min-width: unset;
            display: inline-block !important;
            border-radius: 0px;
            margin: 0px 0px 0px 0px !important;
            padding: 0px 0px;
            border-left: none !important;
            &:hover {
              background-color: transparent !important;
            }
          }
          &.fr-btn.fr-dropdown {
            margin-left: 12px !important;
            position: relative;
            top: -1px;
            &:after {
              top: 0px;
              right: 0px;
            }
          }
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  .fr-second-toolbar {
    border-top: none !important;
    #fr-logo {
      display: none;
    }
    .fr-counter {
      font-family: inherit;
      font-size: 16px;
    }
  }
  .fr-wrapper {
    min-height: 150px;
    // & > div[style]:first-child{
    //   display:none !important;
    // }
  }
  .fr-element.fr-view {
    padding: 15px 15px !important;
  }
  .fr-placeholder {
    padding: 15px 15px 0px 15px !important;
    font-family: inherit !important;
    margin-top: 0px !important;
  }
  .fr-dropdown-menu {
    transform: translateY(7px);
    .fr-dropdown-wrapper {
      .fr-dropdown-content {
        ul.fr-dropdown-list {
          padding: 4px 0px !important;
          min-width: 60px !important;
          li a {
            padding: 7px 12px !important;
            line-height: normal !important;
            color: #000000 !important;
            &:hover {
              background-color: #f0f0f0 !important;
            }
            &.fr-active {
              background-color: var(--primaryColor) !important;
              &:hover {
                background-color: var(--primaryColor) !important;
              }
            }
          }
        }
      }
    }
  }
  .fr-popup {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    button[data-cmd='imageManager'] {
      display: none !important;
    }
    .fr-input-line {
      input {
        padding: 8px 14px 8px 14px !important;
        border-radius: 7px !important;
        border: 1px solid #e8e8e8 !important;
        background-color: #ffffff !important;
        height: 41.2px !important;
        &:focus {
          box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
          border: 1px solid var(--primaryColor) !important;
        }
      }
      label {
        left: 12px;
        top: 27px;
        font-family: inherit !important;
      }
      input.fr-not-empty + label {
        left: 12px !important;
        top: 9px !important;
      }
    }
    .fr-checkbox {
      padding: 0px 5px 0px 0px;
      svg {
        margin-left: 0px;
        margin-top: 0px;
        position: relative;
        top: 3px;
        left: 3px;
      }
      input:checked + span {
        background-color: var(--primaryColor);
        border: 1px solid var(--primaryColor);
      }
    }
    .fr-action-buttons {
      .fr-command {
        padding: 10px 14px;
        font-size: 15px;
        color: #000000;
        border-radius: 6px;
        &.fr-submit {
          background-color: var(--primaryColor);
          color: #000000;
        }
      }
    }
    .fr-color-set {
      padding: 12px !important;
      padding-bottom: 0px !important;
      .fr-selected-color {
        font-size: 0px;
        box-shadow: inset 0 0 2px #000;
      }
    }
    .fr-color-hex-layer {
      padding: 12px !important;
      padding-top: 0px !important;
      float: none;
      display: flex;
      align-items: center;
      .fr-action-buttons {
        width: auto !important;
        height: auto !important;
        float: none !important;
        .fr-submit {
          width: 41.2px;
          height: 41.2px;
          padding: 0px 0px;
          border-radius: 6px !important;
          font-weight: 500;
        }
      }
    }
    .fr-emoticon-container {
      padding: 10px 10px;
      display: flex;
      flex-wrap: wrap;
      .fr-emoticon {
        width: 22px;
        height: 22px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    & > p:last-child {
      font-size: 0px;
      margin-bottom: 0px;
      opacity: 0 !important;
    }
  }
}
.froala-page-break {
  border-bottom: 1px dashed #cecdcd;
  position: relative;
  width:100%;
  margin:10px 0px;
  br{
    display:none;
  }
  &:before {
  content: 'Page Break';
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50px);
    background-color: white;
    z-index: 2;
    font-size: 12px;
    padding: 0px 10px;
  }
}
// HELLO
// .froala-display-CN-watermark-remove{
//   p:last-child{
//     display:none !important;
//   }
// }
// froala-editor-end

// syncfusion 
body{
  .loyal-pageBreak{
    color:#eaeaea;
    font-size:16px;
    position: relative;
    padding:10px 0px;
    text-align:left;
    // .text{
    //   background-color:#ffffff;
    //   position: relative;
    //   z-index:3;
    //   padding:0px 8px;
    //   display:inline-block;
    // }
    // &::before{
    //   content:"";
    //   position:absolute;
    //   top:50%;
    //   transform:translateY(-50%);
    //   left:0px;
    //   width:100%;
    //   border-bottom:1px dashed #ddd;
    // }
  }
  .e-mention.e-popup{
    border-radius:8px;
    overflow:hidden;
    .e-dropdownbase{
      .e-list-item{
        padding: 4px 10px;
        font-size: 16px;
        position: relative;
        height:40px;
        &::before{
          content:attr(data-value);
          position:absolute;
          top:0px;
          left:0px;
          width:100%;
          height:100%;
          white-space:pre;
          overflow:hidden;
          text-overflow:ellipsis;
          padding:4px 12px;
          z-index:2;
          text-indent:0px;
        }
        &.e-active{
          background-color:var(--primaryColor);
          color:#000000 !important;
        }
        table{
          display:none !important;
        }
      }
    }
  }
  
  .e-mention{
    .e-mention-chip{
      background-color:transparent;
    }
  }
  .e-richtexteditor{
    .e-rte-content{
      .e-content{
        a{
          color:#212529;
        }
      }
    }
    .e-source-content{
      .e-content{
        a{
          color:#212529;
        }
      }
    }
  }
  
  // e-richtexteditor
  .e-richtexteditor{
    border-radius: 7px !important;
    border: 1px solid #e8e8e8 !important;
    overflow:hidden;
    .e-rte-toolbar.e-rte-tb-float{
      position:static;
    }
    .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-float{
      position:static !important;
    }
    // &.e-rte-full-screen{
    //   width:100vw !important;
    //   height:100dvh !important;
    // }
  }
  .e-richtexteditor.e-rte-tb-expand .e-rte-content{
    border-bottom:none !important;
  }
  .e-richtexteditor .e-rte-content .e-content{
    min-height:400px;
  }
  .e-rte-image.e-imgcenter{
    width: 100% !important;
    max-width:100% !important;
    object-fit: contain;
    object-position: center;
  }
  .e-rte-image.e-imgleft{
    width: 100% !important;
    max-width:100% !important;
    object-fit: contain;
    object-position:left;
  }
  .e-rte-image.e-imgright{
    width: 100% !important;
    max-width:100% !important;
    object-fit: contain;
    object-position:right;
  }
  .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:focus .e-icons{
    color:#6c757d;
  }
  .e-richtexteditor{
    .e-toolbar-wrapper{
      background:transparent;
      background-color:transparent;
      .e-toolbar,
      .e-toolbar.e-extended-toolbar{
        background:transparent;
        background-color:transparent;
        .e-toolbar-pop{
          background:transparent;
        }
        .e-hor-nav{
          &:hover{
            background-color:#000000;
            .e-icons{
              color:#ffffff !important;
            }
          }
        }
        .e-toolbar-items{
          background:transparent;
          background-color:transparent;
          .e-tbar-btn{
            background:transparent;
            &:hover{
              background:#f8f9fa;
              .e-rte-dropdown-btn-text{
                color:#000000;
              }
              .e-icons{
                color:#000000;
              }
            }
          }
          .e-rte-fontcolor-dropdown:hover .e-rte-color-content:hover,
          .e-rte-fontcolor-dropdown:hover .e-caret:hover,
          .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:hover,
          .e-rte-backgroundcolor-dropdown:hover .e-caret:hover{
            background:#f8f9fa;
          }
          .e-dropdown-btn.e-active{
            background:#f8f9fa;
            .e-rte-dropdown-btn-text{
              color:#000000;
            }
            .e-icons{
              color:#000000;
            }
          }
          .e-toolbar-item{
            &.e-overlay{
              background-color:transparent;
            }
            &.e-active{
              .e-tbar-btn{
                background-color:var(--primaryColor);
                .e-icons{
                  color:#000000;
                }
              }
            }
            .e-tbar-btn{
              &:focus{
                color:#6c757d;
              }
            }
          }
        }
        .e-toolbar-extended{
          .e-tbar-btn{
            background:transparent;
            &:hover{
              background:#f8f9fa;
              .e-rte-dropdown-btn-text{
                color:#000000;
              }
              .e-icons{
                color:#000000;
              }
            }
          }
          .e-rte-fontcolor-dropdown:hover .e-rte-color-content:hover,
          .e-rte-fontcolor-dropdown:hover .e-caret:hover,
          .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:hover,
          .e-rte-backgroundcolor-dropdown:hover .e-caret:hover,
          .e-rte-numberformatlist-dropdown:hover .e-rte-list-primary-content:hover,
          .e-rte-numberformatlist-dropdown:hover .e-caret:hover,
          .e-rte-bulletformatlist-dropdown:hover .e-rte-list-primary-content:hover,
          .e-rte-bulletformatlist-dropdown:hover .e-caret:hover{
            background:#f8f9fa;
          }
          .e-dropdown-btn.e-active{
            background:#f8f9fa;
            .e-rte-dropdown-btn-text{
              color:#000000;
            }
            .e-icons{
              color:#000000;
            }
          }
          .e-toolbar-item{
            &.e-overlay{
              background-color:transparent !important;
            }
            &.e-active{
              .e-tbar-btn{
                background-color:var(--primaryColor);
                .e-icons{
                  color:#000000;
                }
              }
            }
          }
        }
      }
    }
  }
  .e-rte-quick-popup{
    border-radius:12px;
    overflow:hidden;
    box-shadow:0 2px 4px -1px rgba(0,0,0,.2),0 1px 10px -1px rgba(0,0,0,.12),0 4px 4px rgba(0,0,0,.14);
    .e-rte-quick-toolbar{
      background-color:white;
      border:none;
      border-radius:12px;
      .e-toolbar-items{
        background:transparent;
        background-color:transparent;
        .e-tbar-btn{
          background:transparent;
          &:hover{
            background:#f8f9fa;
            .e-rte-dropdown-btn-text{
              color:#000000 !important;
            }
            .e-icons{
              color:#000000 !important;
            }
          }
        }
        .e-rte-fontcolor-dropdown:hover .e-rte-color-content:hover,
        .e-rte-fontcolor-dropdown:hover .e-caret:hover,
        .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:hover,
        .e-rte-backgroundcolor-dropdown:hover .e-caret:hover{
          background:#f8f9fa;
        }
        .e-dropdown-btn.e-active{
          background:#f8f9fa;
          .e-rte-dropdown-btn-text{
            color:#000000 !important;
          }
          .e-icons{
            color:#000000 !important;
          }
        }
        .e-toolbar-item{
          &.e-overlay{
            background-color:transparent;
          }
          &.e-active{
            .e-tbar-btn{
              background-color:var(--primaryColor);
              border:none !important;
              .e-icons{
                color:#000000 !important;
              }
            }
          }
          .e-tbar-btn{
            &:focus{
              color:#6c757d;
            }
          }
        }
      }
    }
  }
  .e-dropdown-popup{
    border-radius:7px !important;
    overflow:hidden;
    box-shadow:1px 1px 3px 0px #cfcfcfe6,-1px -1px 2px 0px #ffffffe6,1px -1px 2px 0px #cfcfcf33,-1px 1px 2px 0px #cfcfcf33;
    ul{
      border-radius:7px !important;
      padding-left:0px;
      margin-bottom:0px;
      .e-item{
        &:hover{
          background-color:#f8f9fa;
        }
        &.e-active{
          background-color:var(--primaryColor);
          color:#000000;
          .e-menu-icon{
            color:#000000;
          }
        }
      }
    }
  }

  .e-dialog{
    box-shadow:1px 1px 3px 0px #cfcfcfe6, -1px -1px 2px 0px #ffffffe6, 1px -1px 2px 0px #cfcfcf33, -1px 1px 2px 0px #cfcfcf33;
    .e-dlg-header-content{
      display:flex;
      align-items:center;
      padding:8px 10px 8px 12px;
      .e-dlg-header{
        line-height:normal;
        font-size: 20px;
        font-weight:600;
        width:calc(100% - 32px);
        padding-right:15px;
        padding-bottom:4px;
      }
      .e-btn.e-dlg-closeicon-btn{
        bottom:0px;
        padding:0px 0px;
        width:32px;
        height:32px;
        left:0px;
        padding-bottom:4px;
        float:none;
        order:2;
        .e-btn-icon{
          margin-top:0px;
          width:auto;
        }
        &:hover{
          background-color:#f8f9fa;
          border-radius:50%;
        }
      }
    }
    .e-img-uploadwrap{
      .e-droptext{
        height:120px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        .e-browsebtn{
          top:0px !important;
          margin-top:14px !important;
        }
      }
    }
    // after syncfusion comman issue put it's inside e-dialog before it was outside of e-dialog
    .e-file-menu {
      margin-top: 12px;
    }
    .e-icon-dlg-close{
      color:#000000;
    }
    .e-rte-label label{
      font-size:14px;
      font-weight:600;
      margin-bottom:5px;
      display:block;
      width:100%;
    }
    .e-input{
      padding:3px 10px 5px 10px !important;
      border-radius: 7px !important;
      border: 1px solid #e8e8e8 !important;
      background-color: #ffffff !important;
      display: block !important;
      width: 100% !important;
      font-size: 14px !important;
      height:36.2px !important;
      font-weight: 400 !important;
      line-height: 1.45 !important;
      color: #6e6b7b !important;
      appearance: none !important;
      &::placeholder{
        color: #b9b9c3 !important;
        opacity: 1 !important;
      }
      &:focus{
        border:1px solid var(--primaryColor) !important;
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
      }
    }
    .e-checkbox-wrapper{
      .e-frame.e-check{
        background-color:var(--primaryColor);
        border-color:var(--primaryColor);
        color:#000000;
      }
      &:hover{
        .e-frame.e-check{
          background-color:var(--primaryColor);
          border-color:var(--primaryColor);
          color:#000000;
        }
      }
    }
    .e-checkbox-wrapper .e-checkbox:focus + .e-frame{
      background-color:var(--primaryColor);
      border-color:var(--primaryColor);
      box-shadow:unset !important;
    }
    .e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check{
      background-color:var(--primaryColor);
      border-color:var(--primaryColor);
      color:#000000;
      box-shadow:unset !important;
    }
    .e-btn.e-flat.e-primary:disabled{
      background-color:var(--primaryColor) !important;
      border-color:var(--primaryColor) !important;
      color:#000000 !important;
      padding:3px 14px 5px 14px;
      opacity:0.65;
    }
    .e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]){
      background-color:var(--primaryColor) !important;
      border-color:var(--primaryColor) !important;
      color:#000000 !important;
      padding:3px 14px 5px 14px;
    }
    .e-footer-content .e-btn.e-flat:not([DISABLED]){
      background:#ffffff !important;
      border-color: #d5d5d5;
      color:#6c6c6c !important;
      padding:3px 14px 5px 14px;
      &:hover{
        box-shadow:0 8px 10px -8px #82868b;
      }
    }
    .e-img-uploadwrap{
      .e-btn{
        background:#ffffff !important;
        border-color: #d5d5d5;
        color:#6c6c6c !important;
        padding:3px 14px 5px 14px;
        &:hover{
          box-shadow:0 8px 10px -8px #82868b;
        }
      }
    }
    .e-upload-files{
      margin-top:10px !important;
      .e-upload-file-list{
        .e-file-container{
          display:flex !important;
          align-items:center;
          flex-wrap:wrap;
          .e-file-name{
            padding-top:0px !important;
            max-width:calc(100% - 40px);
          }
          .e-file-type{
            padding-top:0px !important;
          }
          .e-file-size{
            width:100%;
          }
        }
        .e-file-remove-btn{
          margin: 0px !important;
          padding: 0px !important;
          width: 30px !important;
          height: 30px !important;
          border-radius: 50%;
          transform: translateY(-50%);
          &:hover{
            background-color:#f8f9fa;
          }
        }
      }
    }
    // after syncfusion comman issue put it's inside e-dialog before it was outside of e-dialog end
  }
}
// e-richtexteditor-end
// syncfusion 

.chart-custom-tooltip{
  padding: 12px 15px;
  background-color: #000000;
  border-radius: 10px;
  .title{
    font-size:16px;
    font-weight:500;
    color:#ffffff;
    margin-bottom:5px;
  }
  .cn__body{
    .custom-tooltip-label {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .taskId{
      display:inline-block;
      color:var(--primaryColor);
      font-weight:400;
      font-size:15px;
    }
    .value{
      color:#c6c6c6;
      font-weight:400;
      font-size:15px;
      display:inline-block;
      margin-left:6px;
    }
  }
}

.note-loyal{
  font-size:16px;
  margin-bottom:10px;
  .label{
    font-weight: 500;
    color: #000000;
    display: inline-block;
    margin-right: 7px;
  }
  .value{
    display: inline-block;
  }
}

.horizontal-tabbing{
  &.new-horizontal-tabbing{
    margin-right:0px;
    border-bottom:1px solid #dddddd;
    .nav-item{
      .nav-link{
        margin-right: 10px;
        width: auto;
        position: relative;
        padding: 12px 15px;
        border: none;
        border-radius: 0px;
        &::before{
          content: "";
          position: absolute;
          bottom: 0px;
          width: 0px;
          height: 2px;
          transition: 0.5s;
          background-color: var(--primaryColorDark);
          left: 0px;
        }
        &.active{
          color: var(--primaryColorDark);
          &::before{
            width:100%;
          }
        }
      }
    }
  }
}

input.searchInput {
  box-sizing: border-box;
  background-image: url('../images/icons/searchicon.png');
  background-position: 14px 12px;
  background-size:18px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 10px 20px 10px 45px;
  border: none;
  border: 1px solid #ddd;
  width: 100%;
  &:focus {
    outline: 2px solid #ddd;
    border-radius: 5px;
  }
}
// comman css end

// sidebar start
.header-navbar-shadow {
  background: unset !important;
}
.navigation-header{
  & > span{
    color: #757575;
    font-size: 16px;
    font-weight: 600 !important;
  }
}
.main-menu {
  border-right: 1px solid #eae8e1;
  overflow: unset;
  .shadow-bottom {
    background-image: linear-gradient(
      #f7f6f0 41%,
      rgba(247, 246, 240, 0.11) 95%,
      rgba(247, 246, 240, 0)
    );
  }
  .navbar-brand {
    margin-right: 0px;
    svg {
      width: 130px;
    }
  }
  .navbar-brand.not-collapse {
    width: 50px;
    margin: 0px auto;
    svg {
      width: 100%;
    }
  }
  .menu__collapse {
    width: 100%;
  }
  .main-menu-content {
    height: calc(100dvh - 84px) !important;
  }
  .modern-nav-toggle {
    // display:block !important;
    padding: 0px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    // background:linear-gradient(118deg, var(--primaryColor), #4f40e1);
    background: linear-gradient(118deg, #8dc247, #a3db59);
    position: absolute;
    top: 15px;
    right: -19px;
    border: 4px solid #f6f6f6;
    display: block !important;
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      width: 8px;
      height: 8px;
      left: 11px;
      transform: rotate(45deg);
      border-left: 2px solid #000000;
      border-bottom: 2px solid #000000;
    }
    svg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  ul.navigation-main {
    padding-bottom: 0px;
    padding-top: 10px;
  }
  ul.navigation-main > li:first-child {
    margin-top: 0px;
  }
  ul.navigation-main > li {
    padding: 0px 15px;
    margin-bottom: 4px;
    .menu-item,
    .menu-title {
      font-weight: 500;
      color: #000000;
    }
    .counter__badge {
      font-size: 14px;
      padding: 3px 7px;
      font-weight: 600;
      color: #000;
      background-color: var(--primaryColor);
      border-radius: 100px;
      min-width: 38px;
      text-align: center;
      margin-left: 12px;
      flex-shrink: 0;
    }
    svg {
      color: #000000 !important;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    &.active {
      a {
        box-shadow: unset !important;
        background: transparent !important;
        background-color: var(--primaryColor) !important;
        border-radius: 6px !important;
      }
    }
    &.has-sub.open.sidebar-group-active {
      & > a {
        background-color: var(--primaryColor) !important;
      }
    }
    &.has-sub.open.sidebar-group-active {
      .menu-content {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 25px;
          width: 1px;
          height: calc(100% - 45px);
          background-color: #000000;
          top: 50%;
          transform: translateY(-50%);
          display: none;
        }
      }
    }
  }
  &.menu-shadow {
    box-shadow: unset;
    background-color: #f7f6f0;
  }
  &.menu-light {
    .navigation {
      background-color: transparent;
    }
    .navigation > li > a {
      margin: 0px 0px;
      padding: 12px 15px 12px 15px;
      border-radius: 6px;
      &:hover {
        background-color: #eceade;
      }
    }
  }
  &.expanded {
    // background-color:#ffffff;
    .menu__collapse {
      width: 260px;
    }
  }
}
.menu-collapsed {
  .main-menu {
    .modern-nav-toggle {
      &::before {
        left: 9px;
        transform: rotate(-135deg);
      }
    }
  }
}
.main-menu .navigation li.open > a,
.main-menu .navigation li.menu-collapsed-open > a {
  background-color: var(--primaryColor) !important;
  &:hover {
    background-color: var(--primaryColor) !important;
  }
}
body
  .vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu:not(.expanded)
  .navigation
  li.active
  a {
  box-shadow: unset !important;
  background-image: unset !important;
  background-color: var(--primaryColor) !important;
}
.main-menu.menu-light .navigation li.nav-item,
.main-menu.menu-light .navigation li.nav-item.has-sub {
  margin: 0px 0px 4px 0px !important;
}
.main-menu.menu-light .navigation li.nav-item a:hover > *,
.main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover > * {
  transform: translateX(0px) !important;
}
.main-menu.menu-light .navigation > li.has-sub > ul li:not(.has-sub) {
  margin: 0px 0px 4px 0px !important;
}
.main-menu.menu-light .navigation > li.has-sub > ul li:not(.has-sub).active {
  box-shadow: unset !important;
  background-image: unset !important;
}
.main-menu.menu-light .navigation > li.has-sub > ul li:not(.has-sub).active a {
  background-color: var(--primaryColor) !important;
  box-shadow: unset !important;
  &:hover {
    background-color: var(--primaryColor) !important;
  }
}
.main-menu.menu-light .navigation > li.has-sub ul li > a {
  padding: 12px 15px 12px 15px;
  border-radius: 6px;
  svg {
    margin-left: 5px;
    margin-right: 21px;
    circle {
      fill: #f7f6f0;
    }
  }
  &:hover {
    background-color: #eceade;
  }
}
.main-menu.menu-light .navigation > li.has-sub .menu-content {
  margin-top: 5px;
}

.main-menu.menu-light .navigation > li.has-sub:last-child ul li > a,
.main-menu.menu-light
  .navigation
  > li.has-sub
  ul
  li
  > a[href='/admin/communication/email'] {
  svg {
    width: 20px;
    height: 20px;
    margin-left: 0px;
    margin-right: 17px;
  }
}
.main-menu.menu-light
  .navigation
  > li.has-sub
  > ul
  li.has-sub.open.sidebar-group-active
  > a {
  background-color: var(--primaryColor) !important;
  &:hover {
    background-color: var(--primaryColor) !important;
  }
}
.main-menu.menu-light .navigation > li.has-sub > ul li.has-sub > a {
  margin: 0px 0px;
}
.main-menu.menu-light .navigation > li.has-sub > ul li.has-sub li a {
  margin: 0px 0px;
  padding: 12px 15px 12px 25px;
}
.vertical-layout.vertical-menu-modern.menu-expanded
  .main-menu
  .navigation
  li.has-sub
  li
  a::after {
  right: 4px;
}
.main-menu.expanded .navigation li.has-sub > a {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    right: 10px;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transition: 0.5s;
  }
  &:after {
    display: none !important;
  }
}
.main-menu.expanded .navigation li.has-sub.open > a {
  &:before {
    transform: translateY(-50%) rotate(45deg);
    top: calc(50% - 2px);
  }
}

.main-menu.expanded .modern-nav-toggle {
  animation-name: modernnavtoggle;
  animation-duration: 0.5s;
  right: 165px;
}
@keyframes modernnavtoggle {
  0% {
    right: 165px;
  }
  100% {
    right: -13px;
  }
}
// sidebar end

.content.app-content {
  padding-top: 100px !important;
}

// top header start
.header-navbar .bookmark-wrapper img {
  border-radius: 4px;
  width: 48px;
  object-fit: cover;
  object-position: center;
}
.header-navbar {
  .current-task-of-header {
    background-color: #000000;
    border-radius: 100px;
    display: flex;
    position: relative;
    padding: 10px 80px 10px 15px;
    margin-right: 14px;
    .taskId,
    .taskName,
    .timer {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      position: relative;
      padding-right: 9px;
      margin-right: 9px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        width: 1px;
        height: calc(100% - 4px);
        background-color: #ffffff;
        opacity: 0.6;
      }
    }
    .taskId {
      max-width: 80px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .taskName {
      max-width: 114px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .timer {
      margin-right: 0px;
      padding-right: 0px;
      width: 76px;
      &:before {
        display: none;
      }
    }
    .action-btn-wrapper {
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      .action-btn {
        width: 30px;
        height: 30px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
        &.play-btn {
          svg {
            color: var(--primaryColor);
          }
        }
        &.stop-btn {
          svg {
            color: #ea5455;
          }
        }
        &.paush-btn {
          svg {
            color: orange;
          }
        }
        &:hover {
          &.play-btn {
            background-color: var(--primaryColor);
            svg {
              color: #000000;
            }
          }
          &.stop-btn {
            background-color: #ea5455;
            svg {
              color: #ffffff;
            }
          }
          &.paush-btn {
            background-color: orange;
            svg {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .task-timer-btn-wrapper {
    margin-right:12px;
    position: relative;
    .header-action-btn,
    .notification-btn{
      margin-right:0px;
    }
    .task-timer-dropdown {
      position: absolute;
      top: calc(100% - 10px);
      left: 50%;
      transform: translate(-50%);
      padding-top: 17px;
      z-index: 10;
      display: none;
      .inner-wrapper {
        box-shadow: 0 48px 100px 0 rgba(17, 12, 46, 0.15) !important;
        border-radius: 14px;
        min-width: 310px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: #000000;
          top: -4px;
        }
        .task-timer-header {
          padding: 8px 10px 8px 15px;
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
          background-color: #000;
          border: 1px solid #000;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .title {
            font-weight: 600;
            color: #fff;
            font-weight: 16px;
            width: calc(100% - 31px);
            white-space: pre;
            padding-right: 12px;
            margin-bottom: 0px;
          }
          .reset-btn-wrapper {
            width: 30px;
            height: 30px;
            transition: 0.5s;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: initial;
            padding: 6px;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
              color: #ffffff;
            }
            &:hover {
              background-color: #fff;
              svg {
                color: #000000;
              }
            }
          }
        }
        .task-timer-body {
          padding: 6px 0;
          background-color: #ffffff;
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
          .task-timer-row {
            display: flex;
            position: relative;
            align-items: center;
            padding: 10px 15px;
            padding-right: 76px;
            border-bottom: 1px solid #e3e3e3;
            &:last-child {
              border-bottom: none;
            }
            .taskId,
            .taskName {
              font-size: 16px;
              font-weight: 600;
              color: #000000;
              margin-right: 10px;
              display: inline-block;
            }
            .taskId {
              width: 55px;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .taskName {
              width: 100px;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .timer {
              font-size: 16px;
              font-weight: 600;
              color: #000000;
              width: 78px;
            }
            .action-btn-wrapper {
              display: flex;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 8px;
              .action-btn {
                width: 30px;
                height: 30px;
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                &:last-child {
                  margin-right: 0px;
                }
                svg {
                  width: 100%;
                  height: 100%;
                }
                &.play-btn {
                  svg {
                    color: var(--primaryColor);
                  }
                }
                &.stop-btn {
                  svg {
                    color: #ea5455;
                  }
                }
                &.paush-btn {
                  svg {
                    color: orange;
                  }
                }
                &:hover {
                  &.play-btn {
                    background-color: var(--primaryColor);
                    svg {
                      color: #000000;
                    }
                  }
                  &.stop-btn {
                    background-color: #ea5455;
                    svg {
                      color: #ffffff;
                    }
                  }
                  &.paush-btn {
                    background-color: orange;
                    svg {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
          .no-data-text {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            color: #000000;
            padding: 10px 10px;
          }
        }
      }
    }
    // &:hover {
    //   .task-timer-dropdown {
    //     display: block;
    //   }
    // }
  }
  .report-bug-btn-wrapper {
    margin-right: 12px;
  }
  .top-header-ctm-btns-wrapper{
    display:flex;
    align-items:center;
  }
  .header-action-btn,
  .notification-btn{
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // margin-right:15px;
    background-color: var(--primaryColor);
    border-radius: 50%;
    padding: 9px;
    cursor: pointer;
    margin-right: 12px;
    svg {
      position: relative;
      z-index: 2;
      color: #ffffff;
      width: 100%;
      height: 100%;
    }
  }
  .notification-btn{
    .notificationCount-badge{
      position: absolute;
      top: -7px;
      right: -5px;
      z-index: 3;
      background-color: #000;
      font-size: 11px;
      font-weight: 600;
      border-radius: 50%;
      border: 2px solid #cacaca;
      padding: 2px 3px;
      color: #fff;
      min-width:23px;
      display:flex;
      align-items:center;
      justify-content:center;
    }
  }
  .navbar-search {
    .mobile-title {
      display: none;
    }
    input {
      padding: 10px 20px 10px 38px;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      background-color: #f9f9f9;
      transition: 0.5s;
      box-shadow: unset !important;
      outline: none !important;
      background-image: url(../images/icons/search-gray-icon.svg);
      background-size: 18px;
      background-position: 12px 12px;
      &:focus {
        border: 1px solid var(--primaryColor);
        box-shadow: unset;
        border-radius: 8px;
      }
    }
  }
  .dropdown-user {
    .user-name {
      font-size: 16px;
      font-weight: 500;
      color: var(--primaryColor);
    }
    .user-status {
      font-size: 14px !important;
      color: grey;
    }
    .avatar {
      width: 40px;
      height: 40px;
      .profile-img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .dropdown-item {
      .badge {
        color: #000000 !important;
        padding: 5px 9px;
        font-size: 12px;
        min-width: 30px;
      }
    }
    .chang-log-count {
      min-width: unset;
      min-height: unset;
      color: #000;
      font-size: 11px;
      top: -5px !important;
      position: absolute;
      background-color: var(--primaryColor) !important;
      padding: 4px 5px;
      border-radius: 100px !important;
      border: 1px solid #ffffff !important;
      right: -5px !important;
    }
  }
}

.header-task-timer-dropdown {
  .popover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
    max-width: unset !important;
    border-radius: 14px;
    min-width: 310px;
  }
  .popover-arrow::after {
    border-bottom-color: #000000 !important;
  }
  .popover-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 10px 8px 15px;
    background-color: #000000;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border: 1px solid #000000;
    &:before {
      border-bottom: 0 solid #000000 !important;
    }
    .title {
      margin-bottom: 0px;
      font-weight: 600;
      color: #ffffff;
      font-weight: 16px;
      width: calc(100% - 31px);
      padding-right: 12px;
      white-space: pre;
    }
    .reset-btn-wrapper {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: transparent;
      transition: 0.5s;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
      &:hover {
        background-color: #ffffff;
        svg {
          color: #000000;
        }
      }
    }
  }
  .popover-body {
    padding: 6px 0px;
  }
  .task-timer-row {
    display: flex;
    position: relative;
    align-items: center;
    padding: 10px 15px;
    padding-right: 76px;
    border-bottom: 1px solid #e3e3e3;
    &:last-child {
      border-bottom: none;
    }
    .taskId,
    .taskName {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      margin-right: 10px;
      display: inline-block;
    }
    .taskId {
      width: 55px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .taskName {
      width: 100px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .timer {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      width: 78px;
    }
    .action-btn-wrapper {
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      .action-btn {
        width: 30px;
        height: 30px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
        &.play-btn {
          svg {
            color: var(--primaryColor);
          }
        }
        &.stop-btn {
          svg {
            color: #ea5455;
          }
        }
        &.paush-btn {
          svg {
            color: orange;
          }
        }
        &:hover {
          &.play-btn {
            background-color: var(--primaryColor);
            svg {
              color: #000000;
            }
          }
          &.stop-btn {
            background-color: #ea5455;
            svg {
              color: #ffffff;
            }
          }
          &.paush-btn {
            background-color: orange;
            svg {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.header-navbar {
  border-bottom: 1px solid #eae8e1;
  background-color: #f7f6f0 !important;
  .mobile-menu-toggle-btn {
    padding: 0px 0px;
  }
  .company-details {
    display: flex;
    // flex-wrap:wrap;
    align-items: center;
    padding-right: 12px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      display: block;
    }
    .right {
      padding-left: 8px;
      max-width: 150px;
    }
    .title {
      font-size: 15px;
      color: grey;
      margin-bottom: 0px;
      font-weight: 500;
      margin-bottom: 3px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .company-name {
      margin-bottom: 0px;
      font-size: 15px;
      color: #000000;
      margin-bottom: 0px;
      font-weight: 600;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  .user-details {
    border-left: 1px solid #e3e1da;
    padding-left: 12px;
    margin-left: 3px;
    .dropdown-user-link {
      padding: 0px 0px;
      display: flex;
      align-items: center;
      .img-wrapper {
        position: relative;
      }
      .avatar {
        width: 40px;
        height: 40px;
        background-color:var(--primaryColor);
        .avatar-content{
          width: 100%;
          height: 100%;
          color:#000000;
          font-size: 14px;
        }
        .profile-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
        .avatar-status-online {
          width: 11px;
          height: 11px;
          position: absolute;
          bottom: 0;
          border-radius: 50%;
          border: 1px solid #fff;
          right: 0px;
        }
      }
      .right {
        padding-left: 8px;
        display: inline-block;
        position: relative;
        padding-right: 20px;
        max-width: 150px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          right: 0px;
          width: 8px;
          height: 8px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
        }
        .user-name {
          font-size: 15px;
          color: grey;
          margin-bottom: 0px;
          font-weight: 500;
          margin-bottom: 1px;
          line-height: normal;
          width: 100%;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .user-status {
          margin-bottom: 0px;
          font-size: 15px;
          color: #000000;
          margin-bottom: 0px;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .dropdown-menu {
      max-height: 294px;
    }
    .dropdown-menu[data-popper-placement]:not([data-popper-placement^='top-']) {
      top: calc(100% + 14px) !important;
    }
  }
}
.header-navbar.floating-nav {
  margin: 0px;
  border-radius: 0px;
  width: calc(100% - 260px) !important;
}
.vertical-layout.vertical-menu-modern.menu-collapsed
  .header-navbar.floating-nav {
  width: calc(100% - 80px) !important;
}
.header-navbar.navbar-shadow {
  box-shadow: unset;
}
.navbar-light {
  background-color: transparent;
}
.navbar-floating .header-navbar-shadow {
  display: none;
}
// top header end

// dashboard-page
.comingSoon-wrapper {
  padding: 30px 0px;
  max-width: 100%;
  .header-text {
    width: 600px;
    max-width: 100%;
    margin: 0px auto;
    margin-bottom: 15px;
    .title {
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      color: #000000;
    }
    .text {
      font-size: 16px;
      color: grey;
      text-align: center;
    }
  }
  .img-wrapper {
    width: 500px;
    max-width: 100%;
    margin: 0px auto;
    img {
      width: 100%;
      display: block;
    }
  }
}
.dashboard-page {
  min-height: calc(100dvh - 137px);
  .card-title {
    padding: 15px;
  }
  .card-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .comingSoon-wrapper {
      padding: 15px 0px;
    }
  }
}
// dashboard-page-end

// Company Detail page start
// Company Detail css start
.company-detail-card {
  margin-bottom: 0px !important;
  overflow: hidden;
  .card-body {
    padding:0px 0px 0px 0px !important;
  }
  .submit-btn-fixed-wrapper {
    width: 100%;
    padding: 16px 15px;
    z-index: 2;
    background-color: #ffffff;
    box-shadow: 0px -8px 9px #f7f7f7;
    position: relative;
    z-index: 2;
    .btn {
      margin-top: 0px !important;
    }
    &.responsive-show {
      display: none;
    }
  }
  .contact-notes-tabPane{
    .notes-list-scroll-area{
      height: calc(100dvh - 250px);
      max-height:unset;
    }
  }
  .company-detail-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .company-info,
    .add-notes-sec {
      width: 50%;
      position: relative;
    }
    .company-info {
      &:before {
        content: '';
        position: absolute;
        top: -22px;
        right: 0px;
        height: calc(100% + 24px);
        width: 1px;
        background-color: #eaeaea;
      }
      .inner-scroll-wrapper {
        // height: calc(100dvh - 355px);
        height:calc(100dvh - 268px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 22px 20px 22px;
        &:hover {
          overflow-y: auto;
        }
      }
      .imgupload-dropzon {
        margin-bottom: 24px;
      }
    }
    .add-notes-sec {
      .inner-scroll-wrapper {
        padding: 0px 22px;
        // height: calc(100dvh - 285px);
        height:calc(100dvh - 235px);
        overflow-y: hidden;
        overflow-x: hidden;
        &:hover {
          overflow-y: auto;
        }
      }
      .no-data-wrapper {
        padding-bottom: 15px;
      }
      .note-listing-wrapper {
        margin: 0px -22px;
        padding: 0px 22px;
        // max-height: calc(100dvh - 602px);
        // overflow-y: hidden;
        // overflow-x: hidden;
        // &:hover {
        //   overflow-y: auto;
        // }
      }
      .rdw-editor-main {
        height: 135px;
        min-height: unset !important;
        .DraftEditor-root {
          overflow-y: auto;
          height: 100% !important;
        }
      }
      .header-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        .section-title-second-heading {
          width: calc(100% - 210px);
          padding-right: 12px;
        }
        .view-pinned-note-btn {
          color: var(--primaryColorDark) !important;
          font-size: 15px;
          padding: 8px 14px;
          border-radius: 8px;
          transition: 0.5s;
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
      .fixed-add-note {
        padding-top: 5px;
        .button-wrapper {
          padding: 15px 0px;
        }
        .e-richtexteditor{
          .e-rte-content{
            .e-content{
              min-height:250px;
            }
          }
        }
      }
    }
    .section-title-second-heading{
      position: relative;
      .mobile-toggle-header-btn{
        width:30px;
        height:30px;
        border-radius:50%;
        display:none;
        align-items:center;
        justify-content:center;
        padding:4px;
        transition:0.5s;
        position:absolute;
        top:-2px;
        right:0px;
        background-color:#ffffff;
        cursor: pointer;
        svg{
          width:100%;
          height:100%;
          color:#000000;
        }
        &:hover{
          background-color:#000000;
          svg{
            color:#ffffff;
          }
        }
      }
    }
  }
  &.add-new-user-card {
    & > .card-header {
      position: relative;
      padding-left: 60px;
      min-height: 68px;
      .back-arrow {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        font-size: 0px;
        position: absolute;
        top: 14px;
        left: 12px;
        transition: 0.5s;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          top: 13px;
          width: 12px;
          height: 12px;
          left: 15px;
          border-left: 3px solid #000;
          transform: rotate(45deg);
          border-bottom: 3px solid #000;
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
      .active-inactive-toggle {
        padding-right: 14px;
        .form-label {
          margin-bottom: 0px;
          color: #000000;
          margin-right: 7px;
        }
      }
    }
  }
}

.company-form-preview {
  & > .card {
    overflow: hidden;
    & > .card-body {
      padding-bottom: 7px;
    }
  }
  .submit-btn-wrapper {
    padding-top: 6px;
  }
  .custom-fileupload {
    background-color: #ffffff;
  }

  .e-richtexteditor{
    border:none !important;
    .e-rte-content{
      .e-content{
        min-height:unset !important;
        height:auto;
        padding:0px 0px !important;
      }
    }
  }
}
.company-form-preview {
  padding-top: 30px;
  // width: 500px;
  max-width: 100%;
  margin: 0px auto;
}
.dynamic-form-main-sec {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}
.dynamic-form-wrapper {
  padding: 0px 22px 0px 22px;
  position: relative;
  padding-top: var(--formElementSpacing);
  font-size: 16px;
  .form-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  .inner-wrapper {
    position: relative;
    z-index: 2;
  }
  .top-user-profile {
    // padding: 15px 15px;
    // background-color: whitesmoke;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--formElementSpacing);
    .profile-img {
      width: 60px;
      height: 60px;
      display: block;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }
    .right-details {
      width: 100%;
      // padding-left: 15px;
      text-align: center;
      margin-top: 15px;
      .title {
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 0px;
      }
      .text {
        margin-bottom: 0px;
        margin-top: 4px;
        font-size: 1.1em;
        line-height: normal;
        font-weight: 600;
      }
    }
    .right-details.logo__not {
      padding-left: 0px;
      .title,
      .text {
        text-align: center;
      }
    }
  }
  .DraftEditor-editorContainer {
    border-left: none !important;
  }
  .rdw-editor-main {
    border: none !important;
    overflow: visible !important;
  }
  .rdw-editor-wrapper {
    border: none !important;
  }
  .card-text {
    line-height: normal;
    margin-bottom: var(--formElementSpacing) !important;
    .rdw-editor-wrapper{
      .rdw-editor-main{
        min-height:0px !important;
      }
    }
  }
  .auth-login-form {
    margin-top: 0px !important;
  }
  .form-field-wrapper {
    margin-bottom: var(--formElementSpacing);
  }
  .form-label {
    font-size: 1em;
    font-weight: 600;
  }
  input.form-control {
    padding: 8px 14px 8px 14px;
    border-radius: var(--formElementRadius);
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    height: auto;
    font-size: 1em;
    color: #000000;
  }
  .react-tel-input {
    border-radius: var(--formElementRadius) !important;
    overflow: hidden;
  }
  input.form-control[type='tel'] {
    padding: 11px 14px 11px 48px;
    font-size: var(--formFontSize);
    font-family: var(--formFontFamily);
  }
  .custom-select__control {
    border-radius: var(--formElementRadius);
  }
  textarea.form-control {
    padding: 11px 14px 13px 14px !important;
    border-radius: var(--formElementRadius);
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    font-size: 1em;
    color: #000000;
    line-height: normal !important;
  }
  .custom-select__control {
    .custom-select__placeholder {
      font-size: 1em;
    }
    .custom-select__value-container {
      padding: 9px 0px 9px 0px;
    }
    &.custom-select__control--is-focused {
      box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
      border: 1px solid #e8e8e8;
    }
  }
  .custom-fileupload {
    background-color: white;
    border-radius: var(--formElementRadius);
    .outsite-CN {
      .title {
        font-size: 1em;
        text-align: center;
      }
      .text-secondary {
        text-align: center;
        line-height: normal;
        font-size: 1em;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .thankYou-default-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    .title {
      font-weight: 700;
      font-size: 1.8em;
      margin-bottom: 5px;
      line-height: normal;
    }
    .text {
      font-size: 1em;
      line-height: normal;
    }
  }
  .go-back-btn-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-bottom: 20px;
    svg {
      margin-right: 2px;
    }
    .back-arrow {
      width: 30px;
      height: 30px;
      font-size: 0px;
      position: relative;
      transition: 0.5s;
      border-radius: 50%;
      cursor: pointer;
      .arrow-shape {
        position: absolute;
        top: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #000;
        left: 13px;
        border-left: 2px solid #000;
        transform: rotate(45deg);
      }
    }
    a {
      display: inline-flex;
      align-items: center;
    }
  }
  .submit-btn-wrapper {
    padding-bottom: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // width:100% !important;
    margin-top: 0px !important;
    padding-top: var(--formElementSpacing);
    padding-bottom: var(--formElementSpacing);
    .btn {
      // width:100% !important;
      max-width: 100% !important;
      padding: 10px 22px 12px 22px !important;
      font-size: 1em;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-radius: var(--formElementRadius) !important;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      width: calc(100% + 44px);
      height: 1px;
      background-color: #eae8e1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .submit-wrapper-no-border {
    padding-bottom: var(--formElementSpacing);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .btn {
      // width:100% !important;
      max-width: 100% !important;
      padding: 10px 22px 12px 22px !important;
      font-size: 1em;
      margin-top: 5px;
      border-radius: var(--formElementRadius) !important;
    }
  }
  &.embed-form {
    .submit-btn-wrapper {
      padding-top: var(--formElementSpacing);
      padding-bottom: var(--formElementSpacing);
    }
  }
}
.form-design-wrapper-fixed {
  width: 500px; // width increased
  position: absolute;
  top: 20px;
  right: 0px;
  transform: translateX(calc(100% + 10px));
  transition: 0.3s;
  z-index: 9;
  .button-sticky {
    width: 40px;
    height: 40px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    border: none;
    padding: 8px;
    position: absolute;
    top: 0px;
    left: -50px;
    &:hover {
      background-color: #000000;
    }
    svg {
      width: 100%;
      height: 100%;
      color: white;
    }
  }
  .inner-wrapper {
    width: 100%;
    border: 1px solid #eaeaea;
    // overflow:hidden;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: #ffffff;
    box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
    .header-wrapper {
      width: 100%;
      padding: 10px 14px 8px 14px;
      display: flex;
      border-bottom: 1px solid #eaeaea;
      .title {
        font-size: 22px;
        font-weight: 700;
        color: #000000;
        padding-right: 15px;
        width: 100%;
        margin-bottom: 0px;
      }
      .close-btn {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        padding: 5px;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
    .body-wrapper {
      padding: 15px 15px;
    }
    .form-design-field-row {
      margin-left: -8px;
      margin-right: -8px;
      max-height: calc(100dvh - 354px);
      overflow-y: auto;
      .form-design-field-col {
        flex: unset;
        width: 50%;
        padding: 0px 8px;
        &.form-design-field-full {
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  &.open {
    transform: translateX(1px);
    .button-sticky {
      display: none;
    }
  }
}

.company-task-manager-row {
  margin: 0px -8px;
  .company-task-manager-col {
    margin-bottom: 15px;
    padding: 0px 8px;
  }
}
.company-task-manager-card {
  background-color: white;
  border-radius: 12px;
  .inner-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 12px 42px 12px 12px;
  }
  .avatar {
    width: 42px;
    height: 42px;
    overflow:hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }
  .right-details {
    width: calc(100% - 43px);
    padding-left: 12px;
    .title {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 700;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 2px;
    }
    .text {
      font-size: 14px;
      color: grey;
      margin-bottom: 0px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .form-check-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
  }
}

// Add email Template modal
.email-editor-modal-new {
  .create-template-btn {
    color: var(--primaryColorDark);
    font-size: 16px;
    display: inline-block;
    margin-bottom: 14px;
    transition: 0.5s;
    &:hover {
      text-decoration: underline;
      color: var(--primaryColorDark);
    }
  }
}
// Add email Template modal end

.add-checklist-modal {
  .add-more-btn {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    color: var(--primaryColor) !important;
    font-size: 16px;
    font-weight: 500;
    margin-top: 13px;
    background-color: transparent !important;
    border: none !important;
    box-shadow: unset !important;
    transition: 0.5s;
    &:hover {
      color: var(--primaryColorDark) !important;
    }
  }
  .folder-template-names-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    justify-content: space-between;
    .data-row {
      width: calc(50% - 7px);
      position: relative;
      padding: 13px 16px;
      border-radius: 12px;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.1;
        border-radius: 12px;
      }
      .label {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        display: block;
        margin-bottom: 3px;
      }
      .value {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: var(--primaryColorDark);
        display: block;
      }
    }
  }
  .view__checklist {
    .accordion-margin {
      width: 100%;
    }
    .accordion-item.checklist-box {
      .checklist-header {
        .left-wrapper {
          padding-right: 15px;
        }
        .title-field-wrapper {
          margin: 0px 0px;
          width: 100%;
          font-size: 17px;
          font-weight: 600;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #000000;
        }
      }
      .e-richtexteditor{
        .e-toolbar-wrapper{
          background-color:#ffffff !important;
        }
      }
    }
    .action-btn-wrapper {
      .action-btn {
        cursor: pointer;
      }
    }
    .contact-note {
      height: auto;
    }
  }
}
.accordion-item.checklist-box {
  margin-top: 0px !important;
  margin-bottom: 14px !important;
  background-color: #f7f7f7;
  border-radius: 14px !important;
  border: 1px solid #eee !important;
  box-shadow: unset !important;
  .checklist-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px 8px 15px;
    position: relative;
    .left-wrapper {
      display: inline-flex;
      align-items: center;
      width: calc(100% - 62px);
      flex-wrap: wrap;
    }
    .move-icon-wrapper {
      width: 30px;
      height: 30px;
      position: relative;
      left: -6px;
      top: -1px;
      svg {
        &:first-child {
          position: absolute;
          top: 50%;
          left: calc(50% - 4px);
          transform: translate(-50%, -50%);
          width: 20px;
        }
        &:last-child {
          position: absolute;
          top: 50%;
          left: calc(50% + 4px);
          transform: translate(-50%, -50%);
          width: 20px;
        }
      }
    }
    .form-check-input {
      width: 20px;
      height: 20px;
      margin-top: 0px;
      position: relative;
      top: -1px;
    }
    .title-field-wrapper {
      margin: 0px 14px;
      width: calc(100% - 79px);
      .input-field {
        width: 100%;
        padding: 7px 0px 9px 0px;
        border-radius: 10px;
        border: 1px solid transparent;
        background-color: transparent;
        box-shadow: unset;
        outline: none;
        font-size: 16px;
        font-weight: 600;
        transition: 0.5s;
        &::placeholder {
          color: #000000;
          opacity: 1;
        }
        &:focus {
          padding: 7px 14px 9px 14px;
          border: 1px solid #e8e8e8;
          background-color: #ffffff;
        }
      }
    }
    .action-btn-wrapper {
      display: inline-flex;
      align-items: center;
    }
    .action-btn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      padding: 4px;
      transition: 0.5s;
      svg {
        width: 100%;
        height: 100%;
      }
      &:hover {
        background-color: white;
      }
      &.toggle-btn {
        padding: 5px;
      }
      &.close-btn {
        position: absolute;
        top: -10px;
        right: -9px;
        background-color: #fff;
        border-radius: 50%;
        padding: 4px;
      }
      &.close-btn.cursor-not-allowed {
        pointer-events: none;
        opacity: 0.5;
        display: none;
      }
    }
    .badge-dot-wrapper {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .badge-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  .accordion-collapse.show {
    border-top: 1px solid #e8e8e8;
  }
  .accordion-body {
    padding: 15px 15px 15px 15px;
    .rdw-editor-wrapper {
      .rdw-editor-main {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: white;
      }
    }
    .markeyBy-name-time {
      display: inline-flex;
      align-items: center;
      margin-top: 14px;
      .title {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        margin-bottom: 5px;
        margin-right: 12px;
      }
      .markeyBy-name {
        margin-bottom: 5px;
        background-color: #ffffff;
        border-radius: 7px;
        padding: 5px 15px;
        font-size: 14px;
        color: #424242;
        font-weight: 600;
      }
      .markeyBy-time {
        color: grey;
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        margin-bottom: 5px;
        margin-left: 12px;
      }
    }
  }
  .e-richtexteditor{
    .e-toolbar-wrapper{
      background-color:#ffffff !important;
    }
  }
}
.accordion-item.checklist-box.active {
  background-color: #a3db591a;
  border: 1px solid var(--primaryColor);
}

.company-checklist-inner-scroll {
  max-height: calc(100dvh - 238px);
  overflow-y: auto;
  .accordion-margin {
    width: 100%;
  }
  .checklist-search{
    width:400px;
    max-width:100%;
    margin-bottom:15px;
    input{
      background-image: url(../images/icons/search-gray-icon.svg);
      background-position: 10px 10px;
      background-repeat: no-repeat;
      background-size: 20px;
      padding-left: 39px;
    }
  }
}
.checklist-folder-list-box {
  .checklist-header {
    padding: 0px 0px 0px 0px !important;
    &.checklist-new-ui {
      padding: 12px 15px !important;
      background-color: initial !important;
      display: flex !important;
      justify-content: space-between;
      width: 100%;
    }
    .accordion-button {
      padding: 12px 15px 12px 15px !important;
      background-color: transparent;
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      &:after {
        display: none;
      }
    }
    .title {
      font-size: 17px;
      color: #000000;
      font-weight: 600;
      max-width: calc(100% - 204px);
      // width: 100%;
      padding-right: 14px;
      align-items:center;
      .checklist-name{
        max-width:100%;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      .badge{
        flex-shrink:0;
        margin-right:0px !important;
        margin-left: 10px !important;
        padding: 6px 11px;
      }
    }
    .action-btn-wrapper {
      flex-shrink: 0;
      .action-btn {
        padding: 6px;
        margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .action-btn-wrapper {
      .action-btn {
        &.down-arrow-btn {
          padding: 3px;
          svg {
            position: relative;
            top: 1px;
          }
        }
        &.mobile-menu-btn{
          display:none;
        }
      }
    }
    .accordion-button:not(.collapsed) {
      .action-btn-wrapper {
        .action-btn {
          &.down-arrow-btn {
            svg {
              transform: rotate(-180deg);
              top: 0px;
            }
          }
        }
      }
    }
  }
  .accordion-body {
    .loader-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin: 20px 0px;
    }
  }
  .no-record-found-table {
    padding: 22px 0px;
    .img-wrapper {
      img {
        width: 250px;
      }
    }
  }
}

.company-checklist-row {
  margin:0px -8px;
  .custom-col {
    margin-bottom: 16px;
    padding:0px 8px;
  }
}
.company-form-card.checklist-company-form-card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: white;
  margin: 0px 0px 0px 0px;
  height: 100%;
  position: relative;
  .header-wrapper {
    padding: 10px 15px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    .form-card-title {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      width: calc(100% - 32px);
      padding-right: 10px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
    }
    .action-btn-wrapper {
      display: inline-flex;
      align-items: center;
      .action-btn {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        margin-right: 5px;
        border-radius: 50%;
        transition: 0.5s;
        padding:8px;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          background-color: #e3e3e3;
        }
        svg {
          color:#000000;
          width:100%;
          height:100%;
        }
        &.toggle-btn{
          display:none;
        }
      }
    }
  }
  .body-wrapper {
    padding: 16px 15px 7px 15px;
    display: block;
    .cn-wrapper {
      .title {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
      }
      .text {
        font-size: 16px;
        color: grey;
        line-height: 24px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
    }
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: 0px;
      padding: 0px 15px 20px 15px;
      .btn-primary {
        // width: calc(50% - 6px) !important;
        padding: 0px 0px;
        width: auto !important;
        background-color: transparent !important;
        border: none !important;
        color: var(--primaryColor) !important;
        box-shadow: unset !important;
        &:hover {
          color: var(--primaryColorDark) !important;
        }
      }
    }
    .checklist-listing-items {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -8px;
      .item {
        width: 50%;
        padding: 0px 8px;
        margin-bottom: 10px;
        .inner-wrapper {
          position: relative;
          padding: 8px;
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(118deg, #d0d0d0, rgba(0, 0, 0, 0));
            opacity: 0.2;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }
        .cn-wrapper {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          z-index: 2;
        }
        .form-check-input {
          margin: 0px;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .title {
          font-size: 14px;
          width: calc(100% - 30px);
          margin-bottom: 0px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #acacac;
        }
      }
      .item.active {
        .inner-wrapper {
          &:before {
            background: linear-gradient(118deg, #8dc247, rgba(0, 0, 0, 0));
          }
        }
        .title {
          color: var(--primaryColorDark);
        }
      }
    }
  }
}

.checklist-list-view-table-wrapper{
  width:100%;
  overflow-x:auto;
}
.checklist-list-view-table {
  border: 1px solid #ececec;
  border-radius: 12px;
  min-width:500px;
  .table-row {
    display: flex;
    flex-wrap: wrap;
    .table-cell {
      padding: 15px 15px;
      font-size: 14px;
      font-weight: 400;
      color: grey;
      display: flex;
      align-items: center;
      white-space:pre;
      overflow:hidden;
      text-overflow:ellipsis;
      &:nth-child(1) {
        width: 50px;
      }
      &:nth-child(2) {
        width: calc(100% - 322px);
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      &:nth-child(3) {
        width:270px;
      }
    }
  }
  .table-header {
    .table-row {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: var(--primaryColor);
      .table-cell {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
      }
    }
  }
  .table-body {
    .table-row {
      background-color: #ffffff;
      border-bottom: 1px solid #ececec;
      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: none;
      }
      .table-cell {
        padding: 12px 15px;
        &:first-child {
          position: relative;
          .drag-icon {
            &:first-child {
              position: absolute;
              top: 50%;
              left: calc(50% - 4px);
              transform: translate(-50%, -50%);
              width: 20px;
            }
            &:last-child {
              position: absolute;
              top: 50%;
              left: calc(50% + 4px);
              transform: translate(-50%, -50%);
              width: 20px;
            }
          }
        }
      }
      &.no-data-row {
        .table-cell {
          width: 100% !important;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          display: block;
          padding: 20px 15px;
        }
      }
    }
  }
  .action-btn-wrapper {
    display: inline-flex;
    align-items: center;
    .action-btn {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      margin-right: 5px;
      border-radius: 50%;
      transition: 0.5s;
      cursor: pointer;
      padding:7px;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background-color:#f0f0f0;
      }
      svg {
        color:#000000;
        width:100%;
        height:100%;
      }
    }
  }
}

.criteria-view-table {
  border: 1px solid #ececec;
  border-radius: 12px;
  .table-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px;
    .table-cell {
      padding: 15px 15px;
      font-size: 14px;
      font-weight: 400;
      color: grey;
      display: flex;
      align-items: center;
      &:first-child {
        width: 50px;
      }
    }
  }
  .table-header {
    .table-row {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: var(--primaryColor);
      .table-cell {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        width: calc(33.3333% - 50px);
        &:first-child {
          width: 50px;
        }
        &:last-child {
          width: 96px;
        }
      }
    }
  }
  .table-body {
    .table-row {
      background-color: #ffffff;
      border-bottom: 1px solid #ececec;
      padding: 0px 0px;
      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: none;
      }
      .table-cell {
        padding: 12px 15px;
        width: calc(33.3333% - 50px);
        &:first-child {
          position: relative;
          width: 50px;
          .drag-icon {
            &:first-child {
              position: absolute;
              top: 50%;
              left: calc(50% - 4px);
              transform: translate(-50%, -50%);
              width: 20px;
            }
            &:last-child {
              position: absolute;
              top: 50%;
              left: calc(50% + 4px);
              transform: translate(-50%, -50%);
              width: 20px;
            }
          }
        }
        &:last-child {
          width: 96px;
        }
      }
      &.no-data-row {
        .table-cell {
          width: 100% !important;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          display: block;
          padding: 20px 15px;
        }
      }
    }
  }
  .action-btn-wrapper {
    display: inline-flex;
    align-items: center;
    .action-btn {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      margin-right: 5px;
      border-radius: 50%;
      transition: 0.5s;
      cursor: pointer;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background-color: #e3e3e3;
      }
      svg {
        color: var(--primaryColorDark);
      }
    }
  }
}

.company-document-page {
  .mobile-heading{
    display:none;
  }
  .view-toggle-btn {
    .view-btn.active {
      background-color: var(--primaryColor);
      color: #000000;
    }
  }
}

.company-document-page {
  .top-header {
    .create-folder-link {
      background-color: #ffffff;
      color: #6c6c6c;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #82868b;
      padding: 0.64rem 1.5rem;
      border-radius: 0.358rem;
      margin-left: 14px;
      &:hover {
        box-shadow: 0 8px 25px -8px #82868b;
      }
    }
  }
  .company-document-inner-card {
    margin-bottom: 0px;
    height: 100%;
    .no-record-found-table {
      margin: 40px 0px;
      img {
        width: 250px;
      }
    }
    .card-body {
      .row {
        margin-top: 20px;
      }
    }
  }
  .company-files-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .company-document-listing-row {
    margin-bottom: 0px;
    width: calc(50% - 10px);
    .card-header{
      .toggle-btn{
        display:none;
      }
    }
    .company-checklist-inner-scroll {
      height: calc(100dvh - 283px);
      max-height: calc(100dvh - 283px);
      .company-form-card {
        background-color: #ffffff;
      }
      .loade-more-btn-wrapper {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        .btn {
          background-color: #ffffff;
        }
      }
      .accordion-body {
        & > .card {
          box-shadow: unset !important;
        }
      }
    }
    &:last-child {
      .card-body {
        height: calc(100dvh - 257px);
        max-height: calc(100dvh - 257px);
        overflow-y: auto;
      }
    }
    &.list-view {
      & > .card {
        & > .card-header {
          min-height: 69px;
        }
      }
      .company-checklist-inner-scroll {
        height: calc(100dvh - 310px);
        max-height: calc(100dvh - 310px);
      }
      &:last-child {
        .card-body {
          height: calc(100dvh - 310px);
          max-height: calc(100dvh - 310px);
        }
      }
    }
  }
  .document-col {
    .card-body {
      height: calc(100dvh - 293px);
      overflow-y: auto;
    }
    .load-more-apply {
      .card-body {
        height: calc(100dvh - 373px);
        overflow-y: auto;
      }
    }
  }
  .delete-document-col {
    .card-body {
      height: calc(100dvh - 293px);
      overflow-y: auto;
    }
    .load-more-apply {
      .card-body {
        height: calc(100dvh - 373px);
        overflow-y: auto;
      }
    }
  }
  .document-col,
  .delete-document-col {
    .ui-loader {
      height: 100%;
    }
  }
  .custom-col {
    width: 50%;
    margin-bottom: 20px;
  }
  .company-form-card {
    height: 100%;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 137px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: var(--primaryColorDark);
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .preview-btn {
        width: calc(50% - 6px);
      }
      .view-submissions-link {
        margin-left: 0px;
        width: calc(50% - 6px);
        display: block;
        height: 38px;
        text-align: center;
        border: 1px solid #dbdbdb;
        line-height: 32px;
        border-radius: 5px;
        color: #000000;
        transition: 0.5s;
        &:hover {
          box-shadow: 0 8px 25px -8px #82868b;
        }
      }
    }
  }
  .company-document-listing-row.list-view {
    .card {
      height: 100%;
    }
    .card-body {
      max-height: unset !important;
    }
  }
}

.company-checklist-page-wrapper {
  position: relative;
  .company-checklist-header {
    display: inline-block !important;
    position: absolute;
    top: 16px;
    right: 21px;
    z-index: 2;
    margin-bottom: 0px;
    &::before {
      display: none;
    }
  }
  .compnay-checklist-folder-wrapper {
    & > .card-header {
      padding-right: 114px;
    }
  }
}
// Company Detail css end

// contact page css start
.search-box-wrapper-contactList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 16px !important;
}
.contact-list-all-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .custom_select_wrapper {
    width: 220px;
  }
  .custom-select__menu {
    margin-top: 3px;
  }
}
.custom_select_wrapper > div,
.custom_select_wrapper > .select.custom-select__control {
  width: 100%;
}
.contact-list-taskFilter {
  display: inline-block;
  margin-left: 12px;
  .icon_wrapper {
    width: 43px;
    height: 41.2px;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    transition: 0.5s;
    &:hover {
      background-color: #f0f0f0;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .task__filter__dropdown {
    cursor: pointer;
  }
  .filter__icon_wrapper {
    // width: 49px;
    // height: 49px;
    width: 41.2px;
    height: 41.2px;
    border-radius: 7px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    &:after {
      display: none;
    }
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .task__filter__count {
    min-width: unset;
    min-height: unset;
    background-color: var(--primaryColor) !important;
    color: #000;
    font-size: 12px;
    padding: 5px 6px;
    border-radius: 100px !important;
    top: -5px !important;
    right: -7px !important;
  }
  &.active {
    .icon_wrapper {
      background-color: var(--primaryColor);
      border: 1px solid var(--primaryColor);
      svg {
        color: #ffffff;
      }
    }
  }
}
.contact-list-horizontal-tab {
  margin-left: 12px;
  .horizontal-button-tab {
    .view-btn {
      width: 150px;
      padding: 4px 15px;
      justify-content: center;
    }
  }
}
.contact-list-task-dropdown {
  padding-top: 7px;
  padding-bottom: 7px;
  min-width: 300px;
  .dropdown-item {
    width: 100%;
    padding: 15px 15px;
    border-bottom: 1px solid #ececec;
    background-color: transparent !important;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}

.contact-list-search-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0px 15px 0px;
  .search-box-wrapper {
    margin: 0px;
  }
}

.todo-application {
  .content-wrapper {
    border: none !important;
  }
}
.add-update-contact-tab-wrapper {
  position: relative;
  margin-bottom: 10px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    left: 0px;
    background-color: #d0d0d0;
  }
}
.horizontal-tabbing.add-update-contact-tab {
  margin-top: 0px;
  .nav-item {
    .nav-link {
      width: 190px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.contact__card__wrapper {
  background-color: transparent;
  box-shadow: unset;
  margin-bottom: 5px;
  border: none;
  & > .card-header {
    background-color: transparent !important;
  }
  .contact-personalInfo-wrapper {
    display: flex;
    flex-wrap: wrap;
    .vertical-tab-wrapper {
      width: 200px;
      border-right: 1px solid #e8e8e8;
      padding: 10px 0px;
      height: calc(100dvh - 232px);
      overflow-y: auto;
    }
    .right-tabContant-wrapper {
      width: calc(100% - 200px);
      padding: 20px;
    }
  }
  & > .card-header {
    padding: 0px;
    background-color: transparent;
    margin-bottom: 15px;
    border: none;
    padding-left: 50px;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .add-update-contact-tab-wrapper {
    margin-bottom: 0px;
    &::before {
      background-color: var(--primaryColor);
    }
    .horizontal-tabbing {
      .nav-item {
        .nav-link {
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
  }
  & > .card-body {
    background-color: #ffffff;
    // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border: 1px solid var(--primaryColor);
    border-top: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 0px;
  }
  .accordian-loyal-box {
    background-color: #ffffff;
    border-radius: 14px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    .accordian-loyal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      .inner-wrapper {
        padding: 14px 52px 14px 15px;
        width: 100%;
      }
      .title {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
      .down-arrow {
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 12px;
        right: 12px;
        transition: 0.5s;
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          transform: rotate(-45deg);
          position: absolute;
          left: 7px;
          top: 5px;
        }
      }
    }
    .accordian-loyal-body {
      padding: 15px 15px 0px 15px;
      display: none;
      .normal-text {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .go-to-website-sec {
        margin-top: 6px;
        a {
          &:hover {
            color: var(--primaryColorDark);
            text-decoration: underline;
          }
        }
      }
    }
  }
  .accordian-loyal-box.personal-info {
    .custom-field-wrapper {
      margin-top: 26px;
      .custom-field-header {
        margin-bottom: 18px;
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 3px;
        }
        .text {
          font-size: 16px;
          color: grey;
        }
      }
    }
    .custom-field-box {
      position: relative;
      padding-right: 82px;
      margin-bottom: 15px;
      width: 800px;
      max-width: 100%;
      .action-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        bottom: 5px;
        right: 0px;
        .action-btn {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: 0.5s;
          padding: 4px;
          &.edit-btn {
            padding: 7px;
            margin-right: 5px;
          }
          svg {
            width: 100%;
            height: 100%;
            color: var(--primaryColorDark) !important;
          }
          &:hover {
            background-color: #e3e3e3;
          }
        }
      }
    }
    .add-custom-filed-button {
      margin-top: 6px;
    }
    .email-with-subscribe-toggle-btn {
      width: calc(100% - 200px);
    }
    .subscribe-toggle-btn {
      width: 200px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
    }
  }
  .accordian-loyal-box.events {
    .accordion {
      width: 100%;
      .accordion-item {
        margin-top: 0px !important;
        margin-bottom: 14px !important;
        background-color: #f7f7f7;
        border-radius: 14px !important;
        border: 1px solid #eee !important;
        box-shadow: unset !important;
        .accordion-button {
          font-size: 17px;
          color: #000000;
          font-weight: 600;
          width: 100%;
          background-color: transparent;
          position: relative;
          .count {
            display: inline-block;
            margin-left: 5px;
            color: var(--primaryColor);
          }
          &::after {
            display: none;
          }
          .action-btn.down-arrow-btn {
            width: 30px;
            height: 30px;
            padding: 3px;
            position: absolute;
            top: 9px;
            right: 10px;
            svg {
              width: 100%;
              height: 100%;
              transform: rotate(-180deg);
              transition: 0.5s;
            }
          }
          &.collapsed {
            .action-btn.down-arrow-btn {
              svg {
                transform: rotate(0deg);
              }
            }
          }
        }
        .accordion-collapse.show {
          border-top: 1px solid #e8e8e8;
        }
      }
    }
    .rdt_Table_wrapper {
      .search-box-wrapper {
        margin-top: 13px;
      }
      .page-link {
        background-color: #ffffff;
      }
      .react-paginate {
        .page-item {
          background-color: #ffffff;
        }
      }
      .table__page__limit {
        .table__page__limit__control {
          background-color: #ffffff !important;
        }
      }
    }
  }
  .accordian-loyal-box.groups {
    .normal-text {
      font-size: 16px;
      margin-bottom: 15px;
    }
    .group-history-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--primaryColorDark);
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .group-history-box {
      margin-bottom: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      .change-arrow {
        display: inline-block;
        width: 22px;
        height: 2px;
        background-color: grey;
        position: relative;
        margin: 0px 15px;
        &::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          right: 0px;
          border-right: 2px solid grey;
          border-bottom: 2px solid grey;
        }
      }
      .group-change-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 13px;
        .group-name {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          &.change {
            color: #000000;
          }
        }
      }
      .author-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .author-name {
          margin-bottom: 5px;
          // background-color: #f0f0f0;
          background-color: white;
          color: #000000;
          font-weight: 500;
          font-size: 15px;
          border-radius: 7px;
          padding: 5px 15px;
          display: inline-block;
        }
        .date {
          color: grey;
          font-weight: 500;
          font-size: 15px;
          display: inline-block;
          margin-left: 15px;
          margin-bottom: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
    .no-record-found-table {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 30px 20px;
    }
  }
  .accordian-loyal-box.status {
    .normal-text {
      font-size: 16px;
      margin-bottom: 15px;
    }
    .status-history-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--primaryColorDark);
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .status-history-box {
      margin-bottom: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      .change-arrow {
        display: inline-block;
        width: 22px;
        height: 2px;
        background-color: grey;
        position: relative;
        margin: 0px 15px;
        &::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          right: 0px;
          border-right: 2px solid grey;
          border-bottom: 2px solid grey;
        }
      }
      .status-change-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 13px;
        .status-name {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          &.change {
            color: #000000;
          }
        }
      }
      .author-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .author-name {
          margin-bottom: 5px;
          // background-color: #f0f0f0;
          background-color: white;
          color: #000000;
          font-weight: 500;
          font-size: 15px;
          border-radius: 7px;
          padding: 5px 15px;
          display: inline-block;
        }
        .date {
          color: grey;
          font-weight: 500;
          font-size: 15px;
          display: inline-block;
          margin-left: 15px;
          margin-bottom: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
    .no-record-found-table {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 30px 20px;
    }
  }
  .accordian-loyal-box.category {
    .normal-text {
      font-size: 16px;
      margin-bottom: 15px;
    }
    .category-history-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--primaryColorDark);
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .category-history-box {
      margin-bottom: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      .change-arrow {
        display: inline-block;
        width: 22px;
        height: 2px;
        background-color: grey;
        position: relative;
        margin: 0px 15px;
        &::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          right: 0px;
          border-right: 2px solid grey;
          border-bottom: 2px solid grey;
        }
      }
      .category-change-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 13px;
        .category-name {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          &.change {
            color: #000000;
          }
        }
      }
      .author-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .author-name {
          margin-bottom: 5px;
          // background-color: #f0f0f0;
          background-color: white;
          color: #000000;
          font-weight: 500;
          font-size: 15px;
          border-radius: 7px;
          padding: 5px 15px;
          display: inline-block;
        }
        .date {
          color: grey;
          font-weight: 500;
          font-size: 15px;
          display: inline-block;
          margin-left: 15px;
          margin-bottom: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
    .no-record-found-table {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 30px 20px;
    }
  }
  .accordian-loyal-box.tags {
    .normal-text {
      font-size: 16px;
      margin-bottom: 15px;
    }
    .tags-history-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--primaryColorDark);
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .tags-history-box {
      margin-bottom:15px;
      padding:15px 15px 10px 15px;
      // border-bottom: 1px solid #eee;
      background-color:#ffffff;
      // border:1px dashed grey;
      border-radius:12px;
      .change-arrow {
        display: inline-block;
        width: 22px;
        height: 2px;
        background-color: grey;
        position: relative;
        margin: 0px 15px;
        &::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          right: 0px;
          border-right: 2px solid grey;
          border-bottom: 2px solid grey;
        }
      }
      .tags-change-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom:12px;
        .tags-name {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          width:100%;
          &.old-tag{
            margin-bottom:10px;
          }
          .title{
            font-size:16px;
            font-weight:600;
            color:#4b4b4b;
            margin-bottom:10px;
          }
          &.change {
            color: #000000;
          }
          .badge-tag {
            position: relative;
            padding: 7px 20px;
            font-size: 12px;
            color: var(--primaryColorDark);
            margin-right: 10px;
            display:inline-block;
            margin-bottom:8px;
            &::before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              border-radius: 100px;
              background-color: var(--primaryColor);
              opacity: 0.1;
            }
            &:first-child {
              margin-left: 0px;
            }
          }
        }
      }
      .author-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .author-name {
          margin-bottom: 5px;
          // background-color: #f0f0f0;
          background-color: #f2f2f2;
          color: #000000;
          font-weight: 500;
          font-size: 15px;
          border-radius: 7px;
          padding: 5px 15px;
          display: inline-block;
        }
        .date {
          color: grey;
          font-weight: 500;
          font-size: 15px;
          display: inline-block;
          margin-left: 15px;
          margin-bottom: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .no-record-found-table {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 30px 20px;
    }
  }
  .accordian-loyal-box.questions {
    .normal-text {
      margin-bottom: 13px;
    }
    .contact-que-box {
      position: relative;
      padding-right: 82px;
      margin-bottom: 15px;
      width: 800px;
      max-width: 100%;
      .form-label {
        width: 100%;
        display: block;
        margin-bottom: 7px;
        max-width: 100%;
      }
      .action-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        bottom: 5px;
        right: 0px;
        .action-btn {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: 0.5s;
          padding: 4px;
          &.edit-btn {
            padding: 7px;
            margin-right: 5px;
          }
          svg {
            width: 100%;
            height: 100%;
            color: var(--primaryColorDark) !important;
          }
          &:hover {
            background-color: #e3e3e3;
          }
        }
      }
    }
    .contact-add-question-btn {
      margin-top: 13px;
    }
    .no-record-found-table {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 30px 20px;
    }
  }
  .accordian-loyal-box.pipeline {
    .pipline-inner-box {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 14px;
      margin-bottom: 20px;
      .pipline-header {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        .title {
          font-size: 20px;
          color: #000000;
          font-weight: 700;
          margin-bottom: 0px;
          width: calc(100% - 32px);
          padding-right: 15px;
        }
      }
      .action-btn-wrapper {
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            background-color: #ffffff;
          }
        }
      }
      .no-record-found-table {
        padding-top: 30px;
        padding-bottom: 30px;
        .img-wrapper {
          img {
            width: 300px;
          }
        }
      }
    }
    .pipline-history-box-wrapper {
      margin-top: 15px;
      margin-bottom: 25px;
    }
    .pipline-history-box {
      margin-bottom: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid #e8e8e8;
      .change-arrow {
        display: inline-block;
        width: 22px;
        height: 2px;
        background-color: grey;
        position: relative;
        margin: 0px 15px;
        &::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          right: 0px;
          border-right: 2px solid grey;
          border-bottom: 2px solid grey;
        }
      }
      .pipline-change-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 13px;
        .pipline-name {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          &.change {
            color: #000000;
          }
        }
      }
      .author-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .author-name {
          margin-bottom: 5px;
          background-color:#f6f6f6;
          color: #000000;
          font-weight: 500;
          font-size: 15px;
          border-radius: 7px;
          padding: 5px 15px;
          display: inline-block;
        }
        .date {
          color: grey;
          font-weight: 500;
          font-size: 15px;
          display: inline-block;
          margin-left: 15px;
          margin-bottom: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
    .no-record-found-table {
      background-color: #f9f9f9;
      border-radius: 12px;
      padding: 30px 20px;
    }
    .pipline-history-box{
      .pipeline-history-title{
        font-size:20px;
        font-weight:600;
        color:#000000;
        margin-bottom:15px;
      }
      .pipelines-history-cell{
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child{
          border-bottom:none;
        }
        .old-pipeline,
        .new-pipeline{
          margin-bottom:13px;
          .title{
            font-size: 16px;
            font-weight: 600;
            color: #4b4b4b;
            margin-bottom: 10px;
          }
          .inner-wrapper{
            .badge-pipeline{
              padding: 7px 20px;
              margin-bottom: 8px;
              margin-right: 10px;
              position: relative;
              font-size: 12px;
              color: var(--primaryColorDark);
              display: inline-block;
              font-weight:600;
              &::before{
                content: "";
                position: absolute;
                top: 0px;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                left: 0px;
                border-radius: 100px;
                background-color: var(--primaryColor);
              }
            }
          }
          .author-date {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .author-name {
              margin-bottom: 5px;
              // background-color: #f0f0f0;
              background-color: white;
              color: #000000;
              font-weight: 500;
              font-size: 15px;
              border-radius: 7px;
              padding: 5px 15px;
              display: inline-block;
            }
            .date {
              color: grey;
              font-weight: 500;
              font-size: 15px;
              display: inline-block;
              margin-left: 15px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  .contact-personalInfo-wrapper {
    .right-tabContant-wrapper {
      .fixed-button-wrapper {
        border-top: 1px solid #e8e8e8;
        margin: 0px -20px;
      }
      .accordian-loyal-box {
        border: none;
        margin-bottom: 0px;
        .accordian-loyal-header {
          margin-bottom: 15px;
          .title {
            font-size: 22px;
          }
          .inner-wrapper {
            padding: 0px;
          }
          .down-arrow {
            display: none;
          }
        }
        .accordian-loyal-body {
          padding: 0px;
        }
        &.active {
          .accordian-loyal-header {
            border-bottom: none;
          }
        }
      }
    }
  }
  .contact-create-new-viewport-scroll {
    height: calc(100dvh - 332px);
    overflow-y: auto;
    .card-header {
      .card-title {
        margin: 10px 10px 10px 0; 
      }
      .button-wrapper {
        margin: 10px 0;
      }
    }
  }

  .contact__checklist__wrapper {
    position: relative;
    padding: 20px 20px 15px 20px;
    .list-grid-btn-wrapper {
      display: inline-flex;
      position: absolute;
      top: 21px;
      right: 20px;
      z-index: 4;
      .view-btn {
        &.active {
          background-color: var(--primaryColor) !important;
          color: #000000 !important;
        }
      }
    }
    .compnay-checklist-folder-wrapper {
      .card-header {
        padding: 0px 0px 20px 0px;
        padding-right: 95px;
        background-color: transparent;
        border-bottom: none;
        .card-title {
          font-size: 22px !important;
        }
        .create-folder-link {
          padding: 10px 14px;
          top: 0px;
        }
      }
      & > .card-body {
        padding: 0px 0px 0px 0px !important;
        height: calc(100dvh - 330px);
        overflow-y: auto;
      }
      .company-checklist-inner-scroll {
        max-height: unset;
      }
    }
  }
  .contact-email-tab {
    padding: 25px 25px 20px 25px;
    .contact-email-tab-header {
      margin-bottom: 18px;
      .btn-group {
        .view-btn {
          &.active {
            background-color: var(--primaryColor) !important;
            color: #000000 !important;
          }
        }
      }
    }
    .contact-email-card-row {
      height: calc(100dvh - 365px);
      overflow-y: auto;
      align-items: flex-start;
      .no-record-found-table {
        background-color: #fdfdfd;
        border-radius: 12px;
        padding: 30px 20px;
        border: 1px solid #eee;
      }
    }
    .contact-email-card-col {
      margin-bottom: 20px;
    }
    .company-form-card {
      border: 1px solid #eaeaea;
      border-radius: 10px;
      background-color: white;
      margin: 0px 0px 0px 0px;
      height: 100%;
      position: relative;
      .header-wrapper {
        padding: 10px 15px;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-card-title {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          width: calc(100% - 65px);
          padding-right: 10px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          .action-btn {
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            margin-right: 5px;
            border-radius: 50%;
            transition: 0.5s;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            &:hover {
              background-color: #e3e3e3;
            }
            svg {
              color: var(--primaryColor);
            }
          }
        }
      }
      .body-wrapper {
        padding: 23px 15px 12px 15px;
        display: block;
        .cn-wrapper {
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
          .text {
            font-size: 16px;
            color: grey;
            line-height: 24px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            margin-bottom: 0px;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: 0px;
          padding: 0px 15px 20px 15px;
          .btn-primary {
            // width: calc(50% - 6px) !important;
            padding: 0px 0px;
            width: auto !important;
            background-color: transparent !important;
            border: none !important;
            color: var(--primaryColor) !important;
            box-shadow: unset !important;
            &:hover {
              color: var(--primaryColorDark) !important;
            }
          }
        }
        .data-row {
          display: flex;
          flex-wrap: wrap;
          .label,
          .value {
            font-size: 16px;
            color: grey;
            font-weight: 400;
            margin-bottom: 5px;
          }
          .label {
            margin-right: 7px;
          }
          .value {
            color: #000000;
            font-weight: 700;
          }
        }
      }
    }
    .contact-email-list-view-wrapper {
      .card-header {
        display: none;
      }
      .card-body {
        padding: 0px 0px !important;
      }
    }
  }

  .contact__files__wrapper {
    padding: 20px;
    .company-document-inner-card {
      box-shadow: unset !important;
    }
    .company-document-listing-row {
      .company-checklist-inner-scroll {
        height: calc(100dvh - 422px);
        max-height: calc(100dvh - 422px);
      }
    }
    .company-document-listing-row:last-child {
      .card-body {
        height: calc(100dvh - 390px);
        max-height: calc(100dvh - 390px);
      }
    }
    .checklist-box {
      .no-record-found-table {
        margin: 0px 0px;
      }
    }
  }

  // .loyal-task-manager {
  //   margin-bottom: 0px;
  //   border: none;
  //   background-color: transparent;
  //   .sidebar-left {
  //     height: calc(100dvh - 232px);
  //     .scrollbar-container {
  //       height: calc(100% - 70px);
  //     }
  //   }
  //   .content-right {
  //     .task-manager-body {
  //       .infinite-scroll-component.todo-task-list {
  //         height: calc(100dvh - 392px) !important;
  //       }
  //     }
  //   }
  // }

  &.new-design {
    // old-tab-with-reduce-width
    .horizontal-tabbing {
      .nav-item {
        .nav-link {
          padding: 12px 15px;
        }
      }
      &.add-update-contact-tab {
        .nav-item {
          .nav-link {
            width: 150px;
          }
        }
      }
    }
    // old-tab-with-reduce-width

    // tab-design-1
    .add-update-contact-tab-wrapper {
      margin-bottom: 14px;
      &:before {
        background-color: #dddddd;
        bottom: -1px;
      }
      .horizontal-tabbing.add-update-contact-tab{
        // margin-right: 172px;
      }
    }
    .horizontal-tabbing {
      .nav-item {
        .nav-link {
          padding: 12px 15px;
          border: none;
          border-radius: 0px;
        }
      }
      &.add-update-contact-tab {
        .nav-item {
          .nav-link {
            width: auto;
            margin-right: 10px;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              bottom: 0px;
              left: 0px;
              width: 0px;
              height: 2px;
              transition: 0.5s;
              background-color: var(--primaryColorDark);
            }
            &.active {
              background-color: transparent;
              color: var(--primaryColorDark);
              &:before {
                width: 100%;
              }
              &:hover {
                background-color: white;
              }
            }
          }
        }
      }
    }
    & > .card-header {
      margin-bottom: 8px;
      .card-title{
        max-width:100%;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
    & > .card-body {
      border: none;
      background-color: transparent;
    }
    // tab-design-1-end

    .contact-personalInfo-wrapper {
      padding-top: 7px;
      .vertical-tab-wrapper {
        padding: 0px 0px;
        border-right: none;
        height:auto;
        .inner-scroll-wrapper{
          height:calc(100dvh - 258px);
          overflow-y:auto;
        }
        .bottom-sticky-btn{
          margin-top:10px;
          display:flex;
          align-items:center;
          justify-content:space-between;
        }
        .vertical-tab-item {
          border-bottom: none;
          border-radius: 6px;
          margin-bottom: 4px;
          &:last-child {
            margin-bottom: 0px;
          }
          &:hover {
            background-color: white;
          }
          &.active {
            &:hover {
              background-color: var(--primaryColor);
              color: #000000;
            }
          }
          &.archive-btn{
            background-color:var(--primaryColor);
            color:#000000;
            text-align:center;
            margin-bottom:0px !important;
            width:100%;
          }
          &.delete-contact{
            width:43.2px;
            height:43.2px;
            background-color:red;
            padding: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
              width:100%;
              height:100%;
              color:#ffffff;
            }
          }
        }
        .bottom-sticky-btn{
          &.unArchive{
            .archive-btn{
              width:calc(100% - 52px);
            }
          }
        }
      }
      .right-tabContant-wrapper {
        padding: 0px 0px 0px 20px;
        .accordian-loyal-box {
          background-color: transparent;
        }
        .formField-row {
          width: 1000px;
          max-width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          .formField-col {
            margin-bottom: 18px;
            &.uploadFile {
              width: 100%;
            }
            &.firstName,
            &.lastName,
            &.website,
            &.email,
            &.companyName,
            &.phoneNumber,
            &.address1,
            &.address2 {
              width: 50%;
            }
            &.city,
            &.state,
            &.country,
            &.zipCode {
              width: 25%;
            }
            &.email {
              display: flex;
              align-items: flex-end;
              .inner-field-wrapper {
                width: calc(100% - 176px);
                padding-right: 10px;
              }
              .subscribe-toggle-btn {
                width: 175px;
              }
            }
          }
        }
        .custom-field-wrapper {
          .add-custom-filed-button {
            color: var(--primaryColorDark);
            padding: 0px 0px;
            border: none !important;
            margin-top: 0px;
            &:focus {
              background-color: transparent !important;
            }
          }
        }
        .contact-create-new-viewport-scroll {
          height: auto;
          max-height:calc(100dvh - 260px);
        }
        .fixed-button-wrapper {
          border-top: none;
          justify-content: flex-start !important;
          margin: 17px 0px 0px 0px !important;
          .btn {
            border-top: none !important;
            width: auto !important;
          }
          .alert-btn,.archive-btn,.trash-btn{
            padding: 10px 13px;
            margin-left: 10px
          }
        }

        .contactCompanies__page{
          padding-bottom:10px;
          .addComBtn__wrapper{
            display:flex;
            justify-content:flex-end;
            margin-bottom:15px;
          }

          .companyListing__row{
            margin:0px -15px;
            display:flex;
            flex-wrap:wrap;
            .companyListing__col{
              padding:0px 15px;
              width:50%;
              margin-bottom:24px;
              .companyListing__colInner{
                height:100%;
                border-radius:10px;
                overflow:hidden;
                background-color:#ffffff;
                box-shadow:1px 1px 3px 0px hsla(0,0%,81%,.9),-1px -1px 2px 0px hsla(0,0%,100%,.9),1px -1px 2px 0px hsla(0,0%,81%,.2),-1px 1px 2px 0px hsla(0,0%,81%,.2);
                .companyHeader{
                  display:flex;
                  align-items:center;
                  padding:8px 13px;
                  box-shadow:1px 1px 3px 0px hsla(0,0%,81%,.9),-1px -1px 2px 0px hsla(0,0%,100%,.9),1px -1px 2px 0px hsla(0,0%,81%,.2),-1px 1px 2px 0px hsla(0,0%,81%,.2);
                  .headerDeatils__left{
                    width:calc(100% - 75px);
                    padding-right:15px;
                    .contactName{
                      font-size:18px;
                      font-weight:700;
                      white-space:pre;
                      overflow:hidden;
                      text-overflow:ellipsis;
                      color:#000000;
                      margin-bottom:1px;
                    }
                    .designation{
                      font-size:14px;
                      font-weight:400;
                      white-space:pre;
                      overflow:hidden;
                      text-overflow:ellipsis;
                      color:grey;
                      margin-bottom:0px;
                    }
                  }
                  .actionBtn__wrapper{
                    display:inline-flex;
                    align-items:center;
                    .editBtn,
                    .deleteBtn{
                      width:30px;
                      height:30px;
                      border-radius:4px;
                      display:flex;
                      align-items:center;
                      justify-content:center;
                      padding:6px;
                      cursor:pointer;
                      box-shadow:1px 1px 3px 0px hsla(0,0%,81%,.9),-1px -1px 2px 0px hsla(0,0%,100%,.9),1px -1px 2px 0px hsla(0,0%,81%,.2),-1px 1px 2px 0px hsla(0,0%,81%,.2);
                      svg{
                        width:100%;
                        height:100%;
                        color:#000000;
                        transition:0.4s;
                      }
                    }
                    .editBtn{
                      &:hover{
                        svg{
                          color:var(--primaryColorDark);
                        }
                      }
                    }
                    .deleteBtn{
                      margin-left:10px;
                      &:hover{
                        svg{
                          color:red;
                        }
                      }
                    }
                  }
                }
                .relatedContacts__tableWrapper{
                  width:100%;
                }
                .relatedContacts__table{
                  min-width:500px;
                  .tableRow{
                    display:flex;
                    margin:0px -8px;
                    .tableCol__name{
                      font-size:15px;
                      color:var(--primaryColorDark);
                      font-weight:600;
                      margin-bottom:0px;
                    }
                    .tableCol{
                      display:inline-flex;
                      align-items:center;
                      font-size:14px;
                      width:25%;
                      padding:7px 8px;
                      white-space:pre;
                      overflow:hidden;
                    }
                  }
                  .tableBody{
                    max-height: 172px;
                    overflow-y: auto;
                    overflow-x:hidden;
                  }
                }
                .companyBody{
                  padding:26px 20px 20px 20px;
                  .tableTitle{
                    font-size:18px;
                    font-weight:700;
                    color:#000000;
                    margin-bottom:14px;
                  }
                }
              }
            }
          }

          .noRecordWrapper{
            width:100%;
            display:flex;
            justify-content:center;
            background-color:#ffffff;
            border-radius:12px;
            padding:30px 30px;
            box-shadow:1px 1px 3px 0px rgba(207, 207, 207, 0.9), -1px -1px 2px 0px rgba(255, 255, 255, 0.9), 1px -1px 2px 0px rgba(207, 207, 207, 0.2), -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
          }
        }
      }
    }
    .accordian-loyal-box.personal-info {
      .custom-field-box {
        .action-btn-wrapper {
          .action-btn {
            &:hover {
              background-color: white;
            }
          }
        }
      }
    }
    .accordian-loyal-box.events {
      .accordion {
        .accordion-item {
          background-color: #ffffff;
          margin-bottom: 14px !important;
          border-radius: 10px !important;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
            rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
          .accordion-button {
            padding: 10px 14px;
            .action-btn {
              &:hover {
                background-color: #f4f4f4;
              }
            }
          }
        }
      }
    }
    .accordian-loyal-box.groups {
      .no-record-found-table {
        background-color: white;
      }
    }
    .accordian-loyal-box.questions {
      .contact-add-question-btn {
        padding: 0px 0px;
        border: none !important;
        margin-top: 0px;
        color: var(--primaryColorDark);
        &:focus {
          background-color: transparent !important;
        }
      }
      .action-btn-wrapper {
        .action-btn {
          &:hover {
            background-color: white;
          }
        }
      }
    }
    .accordian-loyal-box.pipeline {
      .pipline-inner-box {
        background-color: white;
        .pipline-header {
          .title {
            font-weight: 600;
          }
        }
        .action-btn-wrapper {
          .action-btn {
            &:hover {
              background-color: #f4f4f4;
            }
          }
        }
      }
    }

    .contact-activity-tabPane {
      padding: 13px 13px;
      height: calc(100dvh - 240px);
      overflow-y: auto;
    }
    .contact-notes-tabPane {
      .notes-list-scroll-area {
        max-height: calc(100dvh - 315px);
        overflow-y: auto;
        .whiteBG__wrapper{
          display:inline-flex;
          align-items:center;
          justify-content:center;
          background-color:#ffffff;
          border-radius:50%;
          box-shadow:1px 1px 3px 0px #cfcfcfe5,-1px -1px 2px 0px #ffffffe5,1px -1px 2px 0px #cfcfcf33,-1px 1px 2px 0px #cfcfcf33;
          padding:6px 6px;
        }
      }
      .inner-contant-wrapper {
        padding: 10px 12px 0px 12px;
      }
      .rdw-editor-wrapper {
        background-color: #ffffff;
      }
      .rdw-editor-main {
        border: none !important;
      }
      .available-notes-header {
        .print-action-wrapper {
          .add-note-btn {
            margin-left: 13px;
          }
        }
      }
      .contact-notes {
        .accordion-item {
          background-color: #ffffff;
          margin-bottom: 14px !important;
          border-radius: 10px !important;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
            rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
          .accordion-header {
            .accordion-button {
              padding: 10px 15px 5px 15px !important;
              .inner-wrapper {
                .heading-details {
                  padding-left: 0px;
                  .title {
                    margin-bottom: 5px;
                  }
                  .author-time {
                    .author-name,
                    .time {
                      margin-bottom: 5px;
                    }
                  }
                }
              }
              .action-btn-wrapper {
                .action-btn {
                  &:hover {
                    background-color: #f4f4f4;
                  }
                }
              }
            }
          }
        }
        .contant-wrapper {
          .footer-details {
            .author-name {
              background-color: #f4f4f4;
            }
            .action-btn-wrapper {
              .action-btn {
                background-color: #f4f4f4;
              }
            }
          }
        }
      }

      // need-to-comment
      .add-note-row,
      .submit-btn-wrapper {
        display: none !important;
      }
      .available-notes-wrapper {
        padding-top: 0px;
        margin-top: 0px !important;
        &:before {
          display: none;
        }
        .form-control {
          border: 1px solid #e8e8e8;
          border-radius: 8px;
          background-color: #ffffff;
          box-shadow: unset !important;
          background-image: url(../images/icons/search-gray-icon.svg);
          background-size: 18px;
          background-position: 11px 10px;
          padding: 4px 20px 5px 38px;
          background-repeat: no-repeat;
          height: 41.2px;
          font-size: 16px;
          color: #6e6b7b;
          &:focus {
            border: 1px solid var(--primaryColor);
            box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
          }
        }
        .form-control::placeholder {
          color: #b9b9c3;
          opacity: 1;
        }
      }
      // need-to-comment-end
    }
    .contact__checklist__wrapper {
      position: relative;
      padding: 5px 13px 0px 13px;
      .list-grid-btn-wrapper {
        display: inline-flex;
        position: absolute;
        top: 6px;
        z-index: 4;
        right: 14px;
        .view-btn {
          &.active {
            background-color: var(--primaryColor) !important;
            color: #000000 !important;
          }
        }
      }
      .card-header {
        padding: 0px 0px 0px 0px;
        padding-right: 94px;
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: transparent !important;
        border-bottom: none;
        .card-title {
          font-size: 22px !important;
          font-weight: 600 !important;
          margin-bottom: 0;
        }
        .create-folder-link {
          top: 0px;
          padding: 10px 20px 10px 20px;
        }
      }
      .company-checklist-inner-scroll {
        max-height: calc(100dvh - 308px);
      }
      .compnay-checklist-folder-wrapper {
        & > .card-body {
          padding: 20px 0px 0px 0px !important;
          height: auto;
        }
      }
      .accordion-item.checklist-box {
        background-color: #ffffff;
        margin-bottom: 14px !important;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
          rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
        .checklist-header {
          .title {
            padding-right: 0px;
            width: calc(100% - 220px);
            .checklist-name {
              max-width: 100%;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .badge {
              padding: 6px 12px;
              margin: 0px 0px 0px 12px !important;
              flex-shrink: 0;
            }
          }
          .action-btn-wrapper {
            padding-left: 15px;
            flex-shrink: 0;
          }
          .action-btn {
            &:hover {
              background-color: #f4f4f4;
            }
          }
        }
        .company-form-card.checklist-company-form-card {
          background-color: #f9f9f9;
          .body-wrapper {
            .checklist-listing-items {
              .item {
                .inner-wrapper {
                  &:before {
                    background: linear-gradient(
                      118deg,
                      #b7b7b7,
                      rgba(0, 0, 0, 0)
                    );
                    opacity: 0.3;
                  }
                }
                &.active {
                  .inner-wrapper {
                    &:before {
                      background: linear-gradient(
                        118deg,
                        var(--primaryColor),
                        rgba(0, 0, 0, 0)
                      );
                      opacity: 0.3;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .contact__files__wrapper {
      padding: 5px 0px 0px 0px;
      .top-header {
        margin-bottom: 18px !important;
      }
      .company-document-page {
        .company-document-listing-row {
          .company-checklist-inner-scroll {
            height: calc(100dvh - 390px);
            max-height: calc(100dvh - 390px);
          }
        }
        .company-document-listing-row {
          &:last-child {
            .card-body {
              height: calc(100dvh - 362px);
              max-height: calc(100dvh - 362px);
            }
          }
        }
      }
    }
    .contact-email-tab {
      padding: 8px 0px 0px 0px;
      .contact-email-card-row {
        height: calc(100dvh - 302px);
      }
    }
    .loyal-task-manager {
      margin-bottom: 0px;
      .sidebar-left {
        height:calc(100dvh - 239px);
      }
      .content-right {
        .task-manager-top-header {
          padding: 8px 14px 8px 0px !important;
        }
        .task-calenderView-wrapper{
          .full-calender-wrapper{
            height:calc(100dvh - 342px);
          }
        }
      }
      .task-manager-body {
        .infinite-scroll-component.todo-task-list {
          height:calc(100dvh - 435px) !important;
        }
      }
    }
    .comingSoon-wrapper {
      padding: 30px 15px;
      .header-text {
        width: 600px;
        max-width: 100%;
        margin: 0px auto;
        margin-bottom: 15px;
        .title {
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          color: #000000;
        }
        .text {
          font-size: 16px;
          color: grey;
          text-align: center;
        }
      }
      .img-wrapper {
        width: 400px;
        max-width: 100%;
        margin: 0px auto;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .contactDocumentPage{
    .documentHeader{
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      justify-content:space-between;
      margin-bottom:6px;
      .titleHeading{
        font-size:22px;
        font-weight:600;
        margin-bottom:10px;
      }
      .add-document-btn{
        margin-bottom:10px;
      }
    }
    .listingScrollWrapper{
      height: calc(100vh - 306px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: -15px;
      padding-right:15px;
    }
    .documentListingRow{
      display:flex;
      flex-wrap:wrap;
      margin:0px -10px;
      .documentListingCol{
        padding:0px 10px;
        width:33.3333%;
        margin-bottom:14px;
        .inner-wrapper{
          background-color:#ffffff;
          border-radius:8px;
          border:1px solid #e2e2e2;
          display:flex;
          align-items:center;
          justify-content:center;
          padding:9px 10px 9px 8px;
        }
        .cnWrapper{
          display:flex;
          align-items:center;
          width:calc(100% - 150px);
          padding-right:10px;
          img{
            display:block;
            width:42px;
            margin-right:12px;
          }
          .document-name{
            max-width:calc(100% - 54px);
            display:inline-block;
            width:auto;
            white-space:pre;
            overflow:hidden;
            text-overflow:ellipsis;
            font-size:18px;
            margin-bottom:0px;
          }
        }
        .actionBtnWrapper{
          display:inline-flex;
          flex-wrap:wrap;
          .actionBtn{
            width:30px;
            height:30px;
            display:flex;
            flex-wrap:wrap;
            align-items:center;
            justify-content:center;
            padding:6px;
            transition:0.5s;
            margin-right:4px;
            border-radius: 50%;
            svg{
              color:#000000;
              width:100%;
              height:100%;
            }
            &:hover{
              background-color:#f3f3f3;
            }
            &:last-child{
              margin-right:0px;
            }
            &.delete-btn{
              svg{
                color:red;
              }
            }
          }
        }
      }
    }
    &.excelsheetPage{
      .documentListingRow{
        .documentListingCol{
          .cnWrapper{
            width:calc(100% - 150px);
          }
        }
      }
    }
  }

  .tag-selector-select-box {
    .react-select {
      display: inline-block;
      width: auto;
      width: 500px;
      max-width: 100%;
    }
  }
  .tags-folder-box {
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    border-radius: 7px;
    margin-top: 16px;
    // display:none;
    .header-title {
      padding: 10px 10px 10px 14px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      .folder-name {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #000;
        width: 100%;
        padding-right: 10px;
      }
      .action-btn {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        border: none;
        background-color: transparent;
        border-radius: 6px;
        flex-shrink: 0;
        transform: rotate(0deg);
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
    .contant-tags-wrapper {
      padding: 15px 14px 10px 14px;
      display: none;
      .tag-badge {
        display: inline-flex;
        padding: 7px 9px 8px 14px;
        position: relative;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 6px;
        border-radius: 100px;
        border: 1px solid var(--primaryColor);
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--primaryColor);
          border-radius: 100px;
          opacity: 0.2;
        }
        .tag-name {
          font-size: 13px;
          font-weight: 500;
          color: #000000;
        }
        .cross-btn {
          width: 18px;
          height: 18px;
          display: inline-block;
          background-color: #fff;
          border-radius: 50%;
          z-index: 2;
          position: relative;
          margin-left: 7px;
          cursor: pointer;
          transition: 0.5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 1px;
            background-color: #000000;
            transition: 0.5s;
          }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          &:hover {
            background-color: #000000;
            &:before,
            &:after {
              background-color: #ffffff;
            }
          }
        }
      }
    }
    &.collapse-folder-box {
      .header-title {
        .action-btn {
          transform: rotate(-180deg);
        }
      }
      .contant-tags-wrapper {
        display: block;
      }
    }
  }
}

// funnel-sec
.funnel-wrapper{
  padding-bottom:25px;
  padding-top:10px;
}
.funnel-sec{
  display:flex;
  flex-wrap:wrap;
  margin-bottom:20px;
  &:last-child{
    margin-bottom:0px;
  }
  .funnel-title{
    font-size:18px;
    font-weight:600;
    color:#000000;
    position: relative;
    padding-right:16px;
    margin-right:16px;
    margin-bottom:0px;
    width:105px;
    &:before{
      content:":";
      position:absolute;
      top:0px;
      right:0px;
    }
  }
  .funnel-cn-wrapper{
    width:calc(100% - 121px);
    margin-top:-4px;
    .funnel-item{
      margin-bottom: 8px;
      // border: 1px solid #ddd;
      padding:4px 10px 7px 30px;
      display: inline-flex;
      border-radius: 6px;
      min-width: 150px;
      max-width: 150px;
      text-align: center;
      background-color: #fff;
      margin-right:10px;
      cursor: pointer;
      justify-content:center;
      position: relative;
      .funnel-name{
        font-size: 16px;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
        display:inline-block;
        text-align:center;
        max-width:100%;
      }
      &:before{
        content: "";
        position: absolute;
        top: -2px;
        left: -1px;
        width: 0px;
        height: 0px;
        border: 19px solid transparent;
        border-left:20px solid #f7f6f0;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0px;
        right:-36px;
        width: 0px;
        height: 0px;
        border: 17px solid transparent;
        border-left:20px solid #ffffff;
        z-index:2;
      }
      &.active{
        background-color:var(--primaryColor);
        border:1px solid var(--primaryColor);
        color:#000000;
        .funnel-name{
          color:#000000;
        }
        &:after{
          border: 17px solid transparent;
          border-left:20px solid var(--primaryColor);
        }
      }
    }
  }
}
// funnel-sec-end

.contact-notes {
  padding: 0px;
  width: 100%;
  .accordion-item {
    margin-top: 0px !important;
    margin-bottom: 14px !important;
    background-color: #f7f7f7;
    border-radius: 14px !important;
    border: 1px solid #eee !important;
    box-shadow: unset !important;
    .accordion-header {
      .accordion-button {
        padding: 15px 15px 8px 15px !important;
        background-color: transparent;
        justify-content: space-between;
        &:after {
          display: none;
        }
        .inner-wrapper {
          width: calc(100% - 138px);
          padding-right: 15px;
          display: flex;
          flex-wrap: wrap;
          .form-check-input {
            width: 20px;
            height: 20px;
            margin: 0px;
            margin-bottom: 7px;
          }
          .heading-details {
            width: 100%;
            padding-left: 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .title {
              font-size: 18px;
              color: #000000;
              font-weight: 600;
              margin-bottom: 0px;
              margin-right: 12px;
              position: relative;
              margin-bottom: 7px;
              // max-width: calc(100% - 364px);
              max-width: calc(100% - 590px);
              display: inline-block;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              .note-name {
                width: 100%;
                overflow: hidden;
                white-space: pre;
                text-overflow: ellipsis;
              }
              &:empty {
                display: none;
              }
              // &:before{
              //   content:"";
              //   position:absolute;
              //   top:50%;
              //   right:0px;
              //   transform:translateY(-50%);
              //   height:90%;
              //   width:1px;
              //   background-color:grey;
              // }
            }
            .author-time {
              font-size: 16px;
              width:500px;
              display: flex;
              align-items: center;
              .author-name {
                display: inline-block;
                margin-right: 12px;
                margin-bottom:4px;
                max-width: 100%;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .attachments-count{
                width:154px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-shrink:0;
                position: relative;
                top:-1px;
              }
              .time {
                display: inline-block;
                margin-bottom: 7px;
                flex-shrink: 0;
                margin-bottom:10px;
                margin-right:12px;
              }
            }
          }
        }
        .action-btn-wrapper {
          display: flex;
          flex-wrap: wrap;
          // width: 65px;
          justify-content: flex-end;
          margin-bottom: 5px;
          .action-btn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            padding: 4px;
            border-radius: 6px;
            margin-right: 5px;
            &:last-child {
              margin-right: 0px;
            }
            svg {
              width: 100%;
              height: 100%;
              transition: 0.5s;
            }
            &.print-btn {
              padding: 6px;
            }
            &.edit-btn,
            &.delete-btn {
              display: none;
            }
            &.down-arrow-btn {
              padding: 2px;
              svg {
                transform: rotate(-180deg);
              }
            }
            &:hover {
              background-color: #ffffff;
            }
          }
        }
        &.collapsed {
          .action-btn-wrapper {
            .action-btn {
              &.down-arrow-btn {
                svg {
                  transform: rotate(0deg);
                }
              }
            }
          }
        }
      }
      .accordion-button:not(.collapsed) {
        .inner-wrapper {
          .heading-details {
            .title {
              max-width: 100%;
              .note-name {
                white-space: normal;
              }
            }
            .author-time {
              width: auto;
              max-width: 100%;
              flex-wrap: wrap;
              .author-name {
                white-space: normal;
              }
            }
          }
        }
      }
    }
    .accordion-collapse {
      &.show {
        border-top: 1px solid #e8e8e8;
      }
    }
  }
  .accordion-body {
    padding-top: 20px;
  }
  .contant-wrapper {
    .normal-text {
      font-size: 16px;
      p {
        margin-bottom: 0px;
        line-height: 27px;
      }
    }
    .attachment-wrapper {
      margin-top: 20px;
      margin-bottom: -15px;
    }
    .footer-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;
      .author-name {
        margin-bottom: 5px;
        background-color: #ffffff;
        border-radius: 7px;
        padding: 5px 15px;
        color: #000000;
        font-weight: 500;
        font-size: 15px;
        display: inline-block;
      }
      .time {
        color: grey;
        font-weight: 500;
        font-size: 15px;
        display: inline-block;
        margin-bottom: 4px;
        margin-left: 15px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        margin-left: 30px;
        .action-btn {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          margin-right: 6px;
          padding: 4px;
          svg {
            width: 100%;
            color: var(--primaryColorDark) !important;
          }
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

.contact-notes-tabPane {
  .inner-contant-wrapper {
    padding: 30px 20px 20px 20px;
  }
  .notes-list-scroll-area {
    max-height: calc(100dvh - 350px);
    overflow-y: auto;
    .no-record-found-table {
      background-color: #fdfdfd;
      border-radius: 12px;
      padding: 30px 20px;
      border: 1px solid #eee;
    }
  }
  .submit-btn-wrapper {
    .cancel-btn {
      background-color: #ffffff !important;
      border-color: #82868b !important;
      color: #6c6c6c !important;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        box-shadow: 0 8px 25px -8px #82868b !important;
      }
    }
  }
  .available-notes-wrapper {
    position: relative;
    padding-top: 30px;
    margin-top: 30px !important;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      border-top: 1px solid #e8e8e8;
      width: calc(100% + 40px);
    }
    .heading {
      font-size: 22px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 0px;
    }
  }
  .available-notes-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .select-all {
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
      .text {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        margin-left: 10px;
        display: inline-block;
      }
      .form-check-input {
        width: 20px;
        height: 20px;
        margin-bottom: 0px;
      }
    }
    .print-action-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .print-btn {
        border: 1px solid #82868b;
        background-color: transparent;
        padding: 7px 20px;
        border-radius: 0.358rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        cursor: pointer;
        svg {
          width: 17px;
          margin-right: 4px;
        }
        &:hover {
          background-color: rgba(130, 134, 139, 0.04);
        }
        &.active {
          background-color: var(--primaryColor);
          color: #000000;
          border: 1px solid var(--primaryColor);
        }
      }
    }
  }
  .contact-notes.highlight-notes {
    .accordion-item {
      border: 1px solid var(--primaryColor) !important;
      background-color: transparent;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        border-radius: 14px;
        opacity: 0.1;
      }
      .accordion-header,
      .accordion-collapse {
        position: relative;
        z-index: 3;
        &.show {
          border-top: 1px solid var(--primaryColor);
        }
      }
    }
  }
}

.contact-activity-tabPane {
  padding: 22px 22px;
  .activity-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .icon-wrapper {
      width: 34px;
      height: 34px;
      border: 2px solid #ffffff;
      color: #000000;
      border-radius: 50%;
      padding: 7px;
      background-color: var(--primaryColor);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      position: relative;
      z-index: 3;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .details-wrapper {
      width: calc(100% - 48px);
      position: relative;
      .inner-wrapper {
        border: 1px solid #e6e6e6;
        border-radius: 7px;
        padding: 10px 15px;
        width: 100%;
        background-color: #ffffff;
        z-index: 3;
        position: relative;
      }
      .header {
        font-size: 16px;
        font-weight: 500;
        color: #979797;
        .label {
          display: inline-block;
          color: #000000;
          &.label-modual {
            &:hover {
              color: var(--primaryColorDark);
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .time {
          display: inline-block;
          position: relative;
          padding-left: 16px;
          margin-left: 12px;
          &:before {
            content: '';
            position: absolute;
            top: 9px;
            left: 0px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #979797;
          }
        }
      }
      .contant {
        font-size: 16px;
        color: grey;
        font-weight: 400;
        margin-top: 6px;
      }
      .card-reflection {
        border: 1px solid #e6e6e6;
        border-radius: 7px;
        height: 20px;
        width: 100%;
        position: absolute;
        left: 0px;
        width: 100%;
        bottom: -4px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: -30px;
        height: calc(100% + 20px);
        width: 1px;
        background-color: #e6e6e6;
      }
    }
    .time-mobile {
      display: none;
      align-items: center;
      margin-top: 9px;
      .time-icon-wrapper {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .value {
        font-size: 14px;
        font-weight: 500;
        color: #979797;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:last-child {
      .details-wrapper {
        &:before {
          display: none;
        }
      }
    }
  }
}

.add-custom-filed-modal {
  .modal-footer {
    .btn-primary {
      width: 148px;
      margin: 0px;
    }
  }
}

.contact-manage-group-view-modal {
  .card-header {
    display: none !important;
  }
}

.contact-manage-group-delete-modal {
  .rdt_Table_wrapper {
    .search-box-wrapper {
      margin-left: auto;
    }
  }
  .card-body {
    width: 100%;
    margin-top: -68px;
  }
  .delete-group-card {
    display: flex;
    flex-wrap: wrap;
    .card-header {
      height: auto;
      padding: 0px;
      display: flex;
      background-color: transparent;
      position: relative;
      border: none;
      padding: 10px 15px;
      width: 100%;
      order: -1;
      margin-bottom: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        border-radius: 10px;
      }
      .card-title {
        margin-bottom: 0px;
        position: relative;
        z-index: 2;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 22px;
        color: var(--primaryColorDark) !important;
        width: calc(100% - 128px);
        padding-right: 12px;
      }
      .btn-group {
        margin-right: 0px !important;
        background-color: #ffffff;
      }
    }
    .card-body {
      padding: 0px;
    }
  }
  .select-new-group-wrapper {
    position: relative;
    z-index: 4;
    width: 300px;
    top: -6px;
    .error-msg {
      display: none;
    }
  }
  .table__page__limit {
    .error {
      .table__page__limit__control {
        border: 1px solid red !important;
      }
    }
  }
}

.contact-status-page {
  .rdt_Table_Card {
    .custom_select_wrapper {
      width: 240px;
    }
  }
}

.send-email-modal {
  &.modal-dialog {
    max-width: 430px;
  }
  &.modal-dialog.send-email-full-modal-with-templateEditor {
    max-width: calc(100% - 30px) !important;
    width: 94% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .addCreate-new-btn {
    background-color: transparent;
    border: none;
    color: var(--primaryColor);
    transition: 0.5s;
    text-decoration: underline;
    font-size: 16px;
    &:hover {
      color: var(--primaryColorDark);
    }
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    .create-task {
      display: flex;
      align-items: center;
      padding-right: 18px;
      margin: 0px 0px;
      .label {
        font-weight: 500;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .right-footer {
      margin: 0px !important;
      display: flex;
      .btn-primary,
      .btn-danger {
        width: 120px !important;
      }
      .btn-primary {
        margin-left: 10px;
      }
    }
  }
}

.send-email-full-modal-with-templateEditor {
  .attachment-wrapper {
    .file__card {
      width: 33.3333%;
    }
  }
  .editor__wp {
    height: 100%;
  }
}

.add-contact-status-modal {
  .normal-text {
    font-size: 16px;
  }
}

.contact-status-view-modal {
  .no-record-found-table {
    padding: 30px 0px;
  }
  .card-body {
    padding: 0px;
    .rdt_Table_wrapper {
      .search-box-wrapper {
        margin-top: 0px;
      }
    }
  }
}

.contact-status-delete-modal {
  .rdt_Table_wrapper {
    .search-box-wrapper {
      margin-left: auto;
    }
  }
  .card-body {
    width: 100%;
    margin-top: -68px;
  }
  .delete-status-card {
    display: flex;
    flex-wrap: wrap;
    .card-header {
      height: auto;
      padding: 0px;
      display: flex;
      background-color: transparent;
      position: relative;
      border: none;
      padding: 10px 15px;
      width: 100%;
      order: -1;
      margin-bottom: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        border-radius: 10px;
      }
      .card-title {
        margin-bottom: 0px;
        position: relative;
        z-index: 2;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 22px;
        color: var(--primaryColorDark) !important;
        width: calc(100% - 128px);
        padding-right: 12px;
      }
      .btn-group {
        margin-right: 0px !important;
        background-color: #ffffff;
      }
    }
    .card-body {
      padding: 0px;
    }
  }
  .select-new-status-wrapper {
    position: relative;
    z-index: 4;
    width: 300px;
    top: -6px;
    .error-msg {
      display: none;
    }
  }
  .table__page__limit {
    .error {
      .table__page__limit__control {
        border: 1px solid red !important;
      }
    }
  }
}

.add-category-status-modal {
  .normal-text {
    font-size: 16px;
  }
}

.contact-category-page {
  .custom_select_wrapper {
    width: 240px;
  }
}

.contact-category-view-modal {
  .no-record-found-table {
    padding: 30px 0px;
  }
  .card-body {
    padding: 0px;
    .rdt_Table_wrapper {
      .search-box-wrapper {
        margin-top: 0px;
      }
    }
  }
}

.contact-category-delete-modal {
  .rdt_Table_wrapper {
    .search-box-wrapper {
      margin-left: auto;
    }
  }
  .card-body {
    width: 100%;
    margin-top: -68px;
  }
  .delete-category-card {
    display: flex;
    flex-wrap: wrap;
    .card-header {
      height: auto;
      padding: 0px;
      display: flex;
      background-color: transparent;
      position: relative;
      border: none;
      padding: 10px 15px;
      width: 100%;
      order: -1;
      margin-bottom: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        border-radius: 10px;
      }
      .card-title {
        margin-bottom: 0px;
        position: relative;
        z-index: 2;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 22px;
        color: var(--primaryColorDark) !important;
        width: calc(100% - 128px);
        padding-right: 12px;
      }
      .btn-group {
        margin-right: 0px !important;
        background-color: #ffffff;
      }
    }
    .card-body {
      padding: 0px;
    }
  }
  .select-new-category-wrapper {
    position: relative;
    z-index: 4;
    width: 300px;
    top: -6px;
    .error-msg {
      display: none;
    }
  }
  .table__page__limit {
    .error {
      .table__page__limit__control {
        border: 1px solid red !important;
      }
    }
  }
}

.add-contact-customField-modal {
  .normal-text {
    font-size: 16px;
  }
}

.contact-customField-page {
  .custom_select_wrapper {
    width: 240px;
  }
}

.contact-tags-page {
  .card-header.card-header-with-buttons{
    .list-grid-view-group{
      margin-right:0px;
      margin-left:10px;
      .view-btn.active{
        background-color: var(--primaryColor) !important;
        color:#000000 !important;
      }
    }
  }
  .rdt_Table_Card {
    margin-bottom: 5px;
    & > .card-header {
      .custom_select_wrapper {
        width: 240px;
      }
      .create-folder-link {
        font-size: 14px;
        color: var(--primaryColorDark);
        transition: 0.5s;
        display: inline-block;
        cursor: pointer;
        padding: 10px 14px;
        border-radius: 0.358rem;
        margin-right: 14px;
        &:hover {
          background-color: #eaeaea;
          color: var(--primaryColorDark);
        }
      }
    }
    .card-body {
      padding: 20px;
      .contact-tags-scroll-area {
        max-height: calc(100dvh - 251px);
        overflow-y: auto;
      }
    }
    .contact-tags-accordion {
      width: 100%;
      .accordion-item {
        margin-top: 0px !important;
        margin-bottom: 14px !important;
        background-color: #f7f7f7;
        border-radius: 14px !important;
        border: 1px solid #eee !important;
        box-shadow: unset !important;
        .accordion-header {
          .accordion-button {
            padding: 12px 15px 12px 15px !important;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              font-size: 18px;
              color: #000000;
              font-weight: 600;
              margin-bottom: 0px;
              width: calc(100% - 102px);
              padding-right:10px;
              white-space:pre;
              overflow:hidden;
              text-overflow:ellipsis;
            }
            .action-btn-wrapper {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
              .action-btn {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.5s;
                padding: 6px;
                border-radius: 6px;
                margin-right: 5px;
                &:last-child {
                  margin-right: 0px;
                }
                svg {
                  width: 100%;
                  height: 100%;
                  transition: 0.5s;
                }
                &.down-arrow-btn {
                  padding: 2px;
                  svg {
                    transform: rotate(-180deg);
                  }
                }
                &:hover {
                  background-color: #ffffff;
                }
              }
            }
            &.collapsed {
              .action-btn-wrapper {
                .action-btn {
                  &.down-arrow-btn {
                    svg {
                      transform: rotate(0deg);
                    }
                  }
                }
              }
            }
            &:after {
              display: none;
            }
          }
        }
        .accordion-collapse.show {
          border-top: 1px solid #e8e8e8;
        }
        .card-header {
          display: none;
        }
        .card-body {
          padding: 0px;
          .react-paginate .page-item {
            background-color: #ffffff;
            .page-link {
              background-color: #ffffff;
            }
          }
          .table__page__limit {
            .table__page__limit__control {
              background-color: #ffffff !important;
            }
          }
        }
        .no-record-found-table {
          padding: 30px 0px 20px 0px;
          .img-wrapper {
            img {
              width: 300px;
            }
          }
        }
      }
    }
    .cn-mgct-status-tags-cf-wrapper{
      background-color:transparent;
      border:none;
      box-shadow:unset !important;
      table{
        tbody{
          tr{
            background-color:#ffffff;
          }
        }
      }
    }
    .kanban-view-wrapper{
      padding:12px 0px;
      .card-title{
        display:none;
      }
      .react-trello-board{
        background-color:transparent !important;
        padding:0px 0px 10px 0px !important;
        .smooth-dnd-draggable-wrapper{
          margin-right:18px;
          max-width:314px;
          .react-trello-lane{
            margin:0px !important;
            background-color:#ffffff;
            border:1px solid #ddd;
            .main-card-shadow{
              box-shadow:unset !important;
              padding:14px 14px !important;
              .header{
                display:flex;
                align-items:center;
                background-color: white;
                padding: 10px 10px 10px 14px;
                border-radius: 6px;
                margin-bottom:14px;
                min-height:50px;
                box-shadow:2px 2px 10px #ddd;
                .title{
                  width:100%;
                  color: var(--primaryColorDark);
                  margin-bottom: 0px;
                  font-size: 16px;
                  font-weight: 700;
                  width:100%;
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  padding-right:10px;
                }
                .more-options-dropdown{
                  flex-shrink:0;
                  .btn-icon{
                    width:30px;
                    height:30px;
                    border-radius:50%;
                    padding:3px !important;
                    svg{
                      width:100%;
                      height:100%;
                      color:#000000;
                    }
                    &:hover{
                      background-color:#f0f0f0;
                    }
                  }
                }
              }
              .header + div{
                min-width:284px;
              }
              .addNew-contact-btn-wrapper{
                padding-top:10px;
                .btn.btn-primary{
                  width:100%;
                  background-color:transparent !important;
                  color:var(--primaryColorDark) !important;
                  border:none;
                  svg{
                    margin-right:5px;
                    color:var(--primaryColorDark);
                  }
                  &:hover{
                    background-color:var(--primaryColor) !important;
                    color:#000000 !important;
                    svg{
                      color:#000000;
                    }
                  }
                }
              }
            }
          } 
          .smooth-dnd-container{
            height:calc(100dvh - 356px);
            overflow-y:auto;
            .smooth-dnd-draggable-wrapper{
              margin-right:0px;
              width:100%;
              max-width:100%;
              background-color:#f0f0f0;
              border-radius:6px;
              margin-bottom:10px;
              .contact-box{
                display:flex;
                align-items:center;
                padding:10px 12px 10px 7px;
                cursor: pointer;
                .move-icon-wrapper{
                  width:30px;
                  height:30px;
                  position: relative;
                  flex-shrink:0;
                  .drag-icon{
                    width:22px;
                    height:22px;
                    position:absolute;
                    top:50%;
                    transform:translate(-50%,-50%);
                    &:first-child{
                      left:calc(50% - 5px);
                    }
                    &:last-child{
                      left:calc(50% + 4px);
                    }
                  }
                }
                .avatar{
                  background-color:var(--primaryColor) !important;
                  flex-shrink:0;
                  margin-left:3px;
                  .avatar-content{
                    color:#ffffff !important;
                  }
                  img{
                    font-size:0px !important;
                    object-fit:cover;
                  }
                }
                .name-company-details{
                  width:calc(100% - 65px);
                  padding-left:13px;
                  position: relative;
                  top:3px;
                  .contact-neme{
                    font-size:16px;
                    font-weight:600;
                    color:#000000;
                    white-space:pre;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    margin-bottom:0px;
                  }
                  .company-name{
                    font-size:14px;
                    font-weight:400;
                    color:#515151;
                    white-space:pre;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    margin-bottom:0px;
                    margin-top:4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .no-record-found-table {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.contact-tags-view-modal {
  .no-record-found-table {
    padding: 30px 0px;
  }
  .card-body {
    padding: 0px;
    .rdt_Table_wrapper {
      .search-box-wrapper {
        margin-top: 0px;
      }
    }
  }
}

.import-contact-modal {
  .modal-body {
    padding: 0px 0px;
  }
  .bs-stepper {
    box-shadow: unset;
    .bs-stepper-header {
      position: relative;
      justify-content: center;
      border-bottom: none;
      border-top: 1px solid #e8e8e8;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.1;
      }
      & > * {
        position: relative;
        z-index: 2;
      }
      .step {
        margin: 0px;
        .step-trigger {
          display: inline-flex;
          align-items: center;
          .bs-stepper-box {
            width: 40px;
            height: 40px;
            padding: 0px;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #dfdfdf;
            color: #8c8c8c;
          }
          .bs-stepper-label {
            margin: 0px;
            padding-left: 12px;
            .bs-stepper-title {
              font-size: 15px;
              font-weight: 600;
              color: #797979;
              margin-bottom: 3px;
            }
            .bs-stepper-subtitle {
              font-size: 14px;
              font-weight: 400;
              color: #828282;
            }
          }
        }
        &.active {
          .step-trigger {
            .bs-stepper-box {
              color: #000000;
            }
            .bs-stepper-label {
              .bs-stepper-title {
                color: #000000;
              }
            }
          }
        }
        &.crossed {
          .step-trigger {
            opacity: 1;
            .bs-stepper-box {
              background-color: var(--primaryColor);
              font-size: 0px;
              position: relative;
              &::before {
                content: '';
                position: absolute;
                top: calc(50% - 2px);
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 16px;
                height: 9px;
                border-left: 3px solid #000000;
                border-bottom: 3px solid #000000;
              }
            }
            .bs-stepper-label {
              .bs-stepper-title {
                color: #000000;
              }
            }
          }
        }
      }
      .line {
        padding: 0px;
        border-bottom: 2px dashed #cacaca;
        width: 60px;
        flex: unset;
        margin-left: 18px;
        margin-right: 18px;
        position: relative;
        svg {
          display: none;
        }
      }
      .step.crossed + .line {
        border-bottom: 2px dashed var(--primaryColor);
      }
    }
  }
  .bs-stepper-content {
    padding: 25px 25px !important;
  }
  .filter-contact {
    width: 250px;
    margin-left: auto;
    position: relative;
    z-index: 2;
  }
  .card {
    margin-top: -40px;
    box-shadow: unset;
    .card-body {
      padding: 0px 0px !important;
    }
    .search-box-wrapper {
      margin-top: 0px;
    }
  }
  .contactBox-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -10px;
    margin-bottom: 25px;
    .contactBox-col {
      width: 16.66%;
      padding: 0px 10px;
      .inner-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        padding: 25px;
        border-radius: 12px;
        background-color: #000000;
        overflow: hidden;
        &:before {
          content: '';
          height: 100%;
          width: 50px;
          position: absolute;
          bottom: -38px;
          transform: rotate(17deg);
          right: -28px;
          background-color: var(--primaryColor);
          opacity: 0.2;
        }
        &:after {
          content: '';
          content: '';
          height: calc(100% + 100px);
          width: 10px;
          position: absolute;
          bottom: -38px;
          opacity: 0.2;
          background-image: linear-gradient(
            var(--primaryColor) 70%,
            rgba(0, 0, 0, 0)
          );
          transform: rotate(18deg);
          right: 16px;
        }
        .title {
          font-size: 20px;
          font-weight: 300;
          color: #ffffff;
          position: relative;
          z-index: 2;
          width: 100%;
          margin-bottom: 25px;
        }
        .value {
          font-size: 50px;
          color: var(--primaryColor);
          font-weight: 700;
          position: relative;
          z-index: 2;
          width: 100%;
        }
      }
    }
  }
  .scanned-contacts-wrapper {
    background-color: #f4f4f4;
    border-radius: 12px;
    padding: 18px 18px 10px 18px;
    .title {
      font-size: 20px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
    }
  }
  .pipelines-sec {
    margin-top: 24px;
  }
  .pipline-title {
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 12px;
  }
  .pipline-row {
    .pipline-col {
      margin-bottom: 23px;
      .inner-wrapper {
        background-color: #f4f4f4;
        border-radius: 12px;
        padding: 23px 16px 16px 16px;
        position: relative;
        .field-wrapper {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        .close-btn {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: #cacaca;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 10px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            transition: 0.5s;
            color: #000000;
          }
          &:hover {
            background-color: #000000;
            svg {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    .previous-btn {
      margin: 0px;
      width: 130px;
    }
    .next-btn {
      margin: 0px;
      width: 130px;
      margin-left: 12px;
    }
  }
  .import-contact-upload-wrapper {
    width: 800px;
    max-width: 100%;
    margin: 0px auto;
    padding: 15px 0px;
    .fancy-ul {
      padding: 20px 0px;
    }
  }
  .import-loader-wrapper {
    width: 500px;
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 0px 40px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -60px;
    .img-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;
      .loading-img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 80px;
        background-color: #ffffff;
        z-index: 3;
      }
    }
    .progress {
      width: 80%;
      margin: 0px auto;
      margin-top: -50px;
      position: relative;
      z-index: 4;
    }
  }
  .import-loader-progress {
    width: 420px;
    max-width: 100%;
    margin: 0px auto;
    margin-top: -120px !important;
    position: relative;
    z-index: 4;
    margin-bottom: 40px !important;
  }

  .importContactMap-section{
    .import-loader-progress{
      display:flex;
      align-items:center;
      justify-content:center;
      padding-top:29px;
      padding-bottom:10px;
      .spinner-border{
        width:42px;
        height:42px;
      }
    }
    .fieldNCN-wrapper{
      display:flex;
      flex-wrap:wrap;
      justify-content:space-between;
      .field__name__replace__wrapper,
      .custom-field-wrapper{
        width:calc(50% - 20px);
        background-color: #f9f9f9;
        border-radius: 14px;
        padding:30px 30px;
        .section-title-wrapper{
          display:flex;
          .section-title{
            &:first-child{
              width:calc(40% + 20px);
            }
            &:last-child{
              width:calc(60% - 20px);
            }
          }
        }
        .scroll__wrapper {
          max-height:calc(100dvh - 400px);
          overflow-y:auto;
        }
        .section-title{
          font-weight:600;
          color:#000000;
          font-size:18px;
          margin-bottom:16px;
        }
        .field__name__replace__box{
          display:flex;
          align-items:center;
          background-color:white;
          border-radius:10px;
          border:1px dashed #b0b0b0;
          margin-bottom:15px;
          padding:16px 16px;
          .nameLabel{
            width:40%;
            position: relative;
            margin-right:20px;
            padding-right:20px;
            display:flex;
            align-items:center;
            .title{
              font-size:16px;
              color:grey;
              margin-bottom:0px;
              line-height:22px;
              display:inline-block;
              max-width:100%;
              white-space:pre;
              overflow:hidden;
              text-overflow:ellipsis;
            }
            &:after{
              content:":";
              display:inline-block;
              position:absolute;
              margin-left:10px;
              top:50%;
              transform:translateY(-50%);
              right:0px;
            }
          }
          .custom_select_wrapper{
            width:calc(60% - 20px);
          }
          &:last-child{
            margin-bottom:0px;
          }
        }
        .add__custom__field__btn{
          margin-top:15px;
          svg{
            position: relative;
            top:-1px;
          }
        }
      }
      .field__name__replace__wrapper{
        padding-right:19px;
        .scroll__wrapper {
          max-height:calc(100dvh - 330px);
          overflow-y:auto;
          padding-right:11px;
        }
        .ps__rail-y{
          opacity:1 !important;
        }
      }
      .custom-field-wrapper{
        padding-right:15px;
        .ps__rail-y{
          opacity:1 !important;
        }
        .scroll__wrapper{
          padding-right:15px;
        }
        .section-title-wrapper{
          display:flex;
          .section-title{
            &:first-child{
              width:calc(40% - 10px);
            }
            &:last-child{
              width:calc(60% + 10px);
            }
          }
        }
        .customFieldBox{
          display:flex;
          align-items:center;
        }
        .field__name__replace__box{
          width:100%;
          .custom_select_wrapper{
            width: calc(100% - 300px);
          }
        }
        .action-btn-wrapper{
          display: flex;
          margin-left: 13px;
          flex-shrink:0;
          margin-bottom:15px;
          .action-btn{
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            border-radius: 50%;
            padding: 7px;
            margin-right:2px;
            svg{
              width: 100%;
              height: 100%; 
              color: var(--primaryColorDark) !important;
            }
            &:last-child{
              margin-right:0px;
            }
            &:hover{
              background-color:#e6e6e6;
            }
          }
        }
      }
    }
    .import-loader-progress{
      margin-top:0px !important;
      margin-bottom:0px !important;
    }
  }
  .importCNTable-wrapper{
    margin-bottom:22px;
    .card{
      border:none;
    }
  }
}

.move-contacts-modal {
  .infoDetails {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    padding: 10px 15px;
    color: var(--primaryColorDark);
    margin-bottom: 15px;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.2;
      border-radius: 10px;
      border: 1px solid var(--primaryColor);
    }
    .text {
      margin-bottom: 0px;
    }
  }
  .down-arrow-btn {
    transform: rotate(90deg);
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0px auto;
  }
  .pipelines-sec {
    margin-top: 24px;
  }
  .pipline-title {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 12px;
  }
  .pipline-row {
    .pipline-col {
      margin-bottom: 23px;
      .inner-wrapper {
        background-color: #f4f4f4;
        border-radius: 12px;
        padding: 23px 16px 16px 16px;
        position: relative;
        .field-wrapper {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        .close-btn {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: #cacaca;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 10px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            transition: 0.5s;
            color: #000000;
          }
          &:hover {
            background-color: #000000;
            svg {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    .btn {
      margin: 0px;
      &:last-child {
        margin-left: 12px;
      }
      &.btn-primary {
        width: auto !important;
      }
    }
  }
}

.add-question-modal {
  .modal-footer {
    .btn-primary {
      margin: 0px;
      margin-left: 12px;
      min-width: 148px;
    }
  }
}

// manage-group-page
.cn-mgct-status-tags-cf-wrapper{
  .card-header{
    position: relative;
    padding-left:60px;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 14px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .dropdown-toggle{
      padding-left: 14px;
      padding-right: 20px;
      border-radius:6px !important;
    }
    .btn.btn-primary{
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}
// manage-group-page-end

// contact-category-page
.contact-category-page{
  .cn-mgct-status-tags-cf-wrapper{
    .card-header{
      .custom_select_wrapper{
        margin-right:12px;
      }
    }
  }
}
// contact-category-page-end

// contact-status-page
.contact-status-page{
  .cn-mgct-status-tags-cf-wrapper{
    .card-header{
      .custom_select_wrapper{
        width:240px;
        max-width:100%;
        margin-right:12px;
      }
    }
  }
}
// contact-status-page

// contact-status-page-new
.contact-status-page-new,
.contact-manage-group-page{
  .contact-status-page-header{
    padding:15px 15px;
    border-radius:10px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    background-color:#ffffff;
    margin-bottom:15px;
    .dropdown-wrapper{
      width:300px;
      .custom_select_wrapper{
        width:100%;
      }
    }
    .list-grid-view-group{
      margin-left:12px;
      .view-btn{
        &.active{
          background-color:var(--primaryColor) !important;
          svg{
            color:#000000;
          }
        }
      }
    }
  }
  .kanban-view-wrapper{
    .card-title{
      display:none;
    }
    .react-trello-board{
      background-color:transparent !important;
      padding:0px 0px 10px 0px !important;
      min-height:unset !important;
      .smooth-dnd-draggable-wrapper{
        margin-right:18px;
        max-width:314px;
        .react-trello-lane{
          margin:0px !important;
          background-color:#ffffff;
          border:1px solid #ddd;
          .spinner-wrapper{
            display:flex;
            align-items:center;
            justify-content:center;
            padding-top:15px;
          }
          .main-card-shadow{
            box-shadow:unset !important;
            padding:14px 14px !important;
            .header{
              display:flex;
              align-items:center;
              background-color: white;
              padding: 10px 10px 10px 14px;
              border-radius: 6px;
              margin-bottom:14px;
              min-height:50px;
              box-shadow:2px 2px 10px #ddd;
              .title{
                width:100%;
                color: var(--primaryColorDark);
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 700;
                width:100%;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
                padding-right:10px;
              }
              .more-options-dropdown{
                flex-shrink:0;
                .btn-icon{
                  width:30px;
                  height:30px;
                  border-radius:50%;
                  padding:3px !important;
                  svg{
                    width:100%;
                    height:100%;
                    color:#000000;
                  }
                  &:hover{
                    background-color:#f0f0f0;
                  }
                }
              }
            }
            .header + div{
              min-width:284px;
            }
            .addNew-contact-btn-wrapper{
              padding-top:10px;
              .btn.btn-primary{
                width:100%;
                background-color:transparent !important;
                color:var(--primaryColorDark) !important;
                border:none;
                svg{
                  margin-right:5px;
                  color:var(--primaryColorDark);
                }
                &:hover{
                  background-color:var(--primaryColor) !important;
                  color:#000000 !important;
                  svg{
                    color:#000000;
                  }
                }
              }
            }
          }
        } 
        .smooth-dnd-container{
          height:calc(100dvh - 369px);
          overflow-y:auto;
          .smooth-dnd-draggable-wrapper{
            margin-right:0px;
            width:100%;
            max-width:100%;
            background-color:#f0f0f0;
            border-radius:6px;
            margin-bottom:10px;
            .contact-box{
              display:flex;
              align-items:center;
              padding:10px 12px 10px 7px;
              cursor: pointer;
              .move-icon-wrapper{
                width:30px;
                height:30px;
                position: relative;
                flex-shrink:0;
                .drag-icon{
                  width:22px;
                  height:22px;
                  position:absolute;
                  top:50%;
                  transform:translate(-50%,-50%);
                  &:first-child{
                    left:calc(50% - 5px);
                  }
                  &:last-child{
                    left:calc(50% + 4px);
                  }
                }
              }
              .avatar{
                background-color:var(--primaryColor) !important;
                flex-shrink:0;
                margin-left:3px;
                .avatar-content{
                  color:#ffffff !important;
                }
                img{
                  font-size:0px !important;
                  object-fit:cover;
                }
              }
              .name-company-details{
                width:calc(100% - 65px);
                padding-left:13px;
                position: relative;
                top:3px;
                .contact-neme{
                  font-size:16px;
                  font-weight:600;
                  color:#000000;
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  margin-bottom:0px;
                }
                .company-name{
                  font-size:14px;
                  font-weight:400;
                  color:#515151;
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  margin-bottom:0px;
                  margin-top:4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// contact-status-page-new

// contact-manage-group-page
.contact-manage-group-page{
  position: relative;
  .contact-manage-group-header{
    display:inline-flex;
    .list-grid-view-group{
      margin-left:12px;
      .view-btn{
        &.active{
          background-color:var(--primaryColor) !important;
          svg{
            color:#000000;
          }
        }
      }
    }
  }
  .kanban-view-wrapper{
    .react-trello-board{
      .smooth-dnd-draggable-wrapper{
        .smooth-dnd-container{
          height:calc(100dvh - 338px);
        }
      }
    }
  }
  &.list-view-active{
    .contact-manage-group-header{
      position:absolute;
      top:15px;
      right:15px;
      z-index:3;
    }
    .cn-mgct-status-tags-cf-wrapper{
      .card-header{
        padding-right:252px;
      }
    }
  }
  &.kanban-view-active{
    .contact-manage-group-header{
      position:absolute;
      top:0px;
      right:0px;
      z-index:3;
    }
    .kanban-view-wrapper{
      .card-title{
        font-size:24px;
        font-weight:700;
        color:#000000;
        display:block;
        margin-bottom:18px;
        padding-top:6px;
      }
    }
  }
}
// contact-manage-group-page-end

// contact-customField-page
.contact-customField-page{
  .cn-mgct-status-tags-cf-wrapper{
    .card-header{
      .custom_select_wrapper{
        width: 200px;
        max-width:100%;
        margin-right:12px;
      }
    }
  }
}
// contact-customField-page-end

// contact page css end

// task manager start
html .navbar-floating.footer-static .app-content .content-area-wrapper {
//   height: auto;
}
.todo-application .content-area-wrapper {
  border: none !important;
}
.loyal-task-manager {
  margin-bottom: 7px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ececec;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  .sidebar-left {
    width: 260px;
    height: calc(100dvh - 142px);
    border-right: 1px solid #ececec;
    .collapse__menu__icon__wp {
      width: 32px;
      height: 32px;
      right: -17px;
      top: 17px;
      svg {
        display: none;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(50% + 2px);
        width: 10px;
        height: 10px;
        border-left: 2px solid #000000;
        border-bottom: 2px solid #000000;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &.responsive__btn {
        display: none;
      }
    }
    .scrollbar-container {
      height: calc(100% - 80px);
    }
    .add-task-btn-wrapper {
      padding: 15px 25px 15px 15px;
    }
    .add__task__btn {
      height: 38px;
      width: 100%;
      border-radius: 6px;
      border: none;
      display:none;
    }
    .page-tab {
      margin-top: 0px;
      .page-tab-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding: 8px 15px;
        cursor: pointer;
        .icon-wrapper {
          width: 26px;
          height: 26px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 3px;
          position: relative;
          z-index: 2;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
          }
        }
        .text-label {
          width: calc(100% - 27px);
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          padding-left: 10px;
          padding-top: 1px;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          .text {
            display: block;
            width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .badge {
            flex-shrink: 0;
            margin: 0px !important;
            margin-left: 10px !important;
            padding: 6px 10px 6px 10px;
          }
        }
        .text-label {
          width: calc(100% - 27px);
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          padding-left: 10px;
          padding-top: 1px;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          .text {
            display: block;
            width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .badge {
            flex-shrink: 0;
            margin: 0px !important;
            margin-left: 10px !important;
            padding: 6px 10px 6px 10px;
          }
        }
        &:hover {
          &:before {
            background-image: linear-gradient(
              to right,
              grey,
              rgba(255, 255, 255, 0)
            );
            opacity: 0.1;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 4px;
          height: 100%;
          background-color: transparent;
        }
        &.active {
          &:before {
            background-image: linear-gradient(
              to right,
              var(--primaryColor),
              rgba(255, 255, 255, 0)
            );
            opacity: 0.3;
          }
          &:hover {
            &:before {
              background-image: linear-gradient(
                to right,
                var(--primaryColor),
                rgba(255, 255, 255, 0)
              );
              opacity: 0.3;
            }
          }
          &:after {
            background-color: var(--primaryColor);
          }
          .icon-wrapper {
            svg {
              color: var(--primaryColorDark);
            }
          }
          .text-label {
            color: var(--primaryColorDark);
          }
        }
        &.archived {
          position: relative;
          padding-right: 48px;
          .setting {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            &:hover {
              svg {
                color: var(--primaryColorDark);
              }
            }
          }
        }
        &.snooze-tasks {
          .icon-wrapper {
            padding: 1px;
          }
          &.active {
            path {
              fill: var(--primaryColorDark);
            }
          }
        }
      }
    }
    .filter-wrapper {
      margin-top: 15px;
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      .filter-item {
        border-bottom: 1px solid #ececec;
        .header-item-wrapper {
          position: relative;
          .icon-wrapper {
            width: 26px;
            height: 26px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 3px;
            position: relative;
            z-index: 2;
            transition: 0.5s;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
            }
            &.plus-arrow {
              border-radius: 50%;
              background-color: #e3e3e3;
              cursor: pointer;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 15px;
              svg {
                color: #000000;
                transition: 0.5s;
              }
              &:hover {
                background-color: #000000;
                svg {
                  color: #ffffff;
                }
              }
            }
          }
        }
        .header-item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 10px 15px 10px 40px;
          cursor: pointer;
          .icon-wrapper {
            width: 26px;
            height: 26px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 3px;
            position: relative;
            z-index: 2;
            transition: 0.5s;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
            }
            &.plus-arrow {
              border-radius: 50%;
              background-color: #e3e3e3;
              cursor: pointer;
              svg {
                color: #000000;
                transition: 0.5s;
              }
              &:hover {
                background-color: #000000;
                svg {
                  color: #ffffff;
                }
              }
            }
            &.up-arrow,
            &.down-arrow {
              padding: 0px;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              padding: 2px;
              &:hover {
                background-color: #e3e3e3;
                cursor: pointer;
              }
            }
          }
          .text-label {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            width: calc(100% - 30px);
            padding: 0px 5px 0px 10px;
            display: flex;
            align-items: center;
            .text {
              display: block;
              width: 100%;
              padding-right: 10px;
            }
            .badge {
              padding: 6px 10px;
              margin: 0px 0px !important;
              flex-shrink: 0;
            }
          }
        }
        .filter-body-toggle-wrapper {
          .accordion-body {
            padding: 0px 0px 10px 0px;
          }
          .list-group-item {
            border: none;
            &.filter-item-inner {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding: 8px 15px;
              cursor: pointer;
              background-color: transparent;
              .drag-icon-wrapper {
                width: 30px;
                height: 30px;
                position: relative;
                left: -3px;
                cursor: grab;
                position: relative;
                z-index: 3;
                svg {
                  position: absolute;
                  top: 50%;
                  width: 20px;
                  transform: translate(-50%, -50%);
                }
                svg:first-child {
                  left: calc(50% - 4px);
                }
                svg:last-child {
                  left: calc(50% + 4px);
                }
              }
              .contantCN {
                width: calc(100% - 90px);
                display: flex;
                align-items: center;
                position: relative;
                z-index: 3;
                cursor: pointer;
                .bullet {
                  width: 14px;
                  height: 14px;
                  line-height: normal;
                  border-radius: 50%;
                }
                .text {
                  color: #000000;
                  width: calc(100% - 15px);
                  padding-left: 8px;
                  padding-right: 5px;
                  font-size: 14px;
                  font-weight: 500;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .action-btn-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;
                z-index: 3;
                .action-btn {
                  width: 30px;
                  height: 30px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  transition: 0.5s;
                  border-radius: 50%;
                  cursor: pointer;
                  padding: 7px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                  &:hover {
                    background-color: #e3e3e3;
                  }
                }
              }
              &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                // background-image:linear-gradient(to right, var(--primaryColor), rgba(255,255,255,0));
                opacity: 0.1;
              }
              &:after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 4px;
                height: 100%;
                background-color: var(--primaryColor);
                background-color: transparent;
              }
              &:hover {
                background-color: #ffffff;
              }
              &.active {
                background-color: transparent;
                .drag-icon-wrapper,
                .edit-btn {
                  svg {
                    color: #6e6b7b;
                  }
                }
                &:before {
                  // background-image: linear-gradient(
                  //   to right,
                  //   var(--primaryColor),
                  //   rgba(255, 255, 255, 0)
                  // );
                  background-color: var(--primaryColor);
                }
              }
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
        &.active {
          .header-item {
            background-color: #fbfbfb;
          }
          .filter-body-toggle-wrapper {
            background-color: #fbfbfb;
            .category-filter-item {
              background-color: transparent;
            }
          }
        }
      }
      .sidebarCollapse__CN{
        border-bottom: 1px solid #ececec;
        margin-bottom: 18px;
        padding-bottom: 2px;
        display:none;
        &:nth-child(2){
          padding-top:10px;
        }
        &:last-child{
          padding-bottom: 0px;
          margin-bottom:0px;
          border-bottom:none;
        }
        .drag-icon-wrapper,
        .action-btn-wrapper {
          display: none;
        }
        .filter-item-inner {
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: none;
          border-top: none;
          border: none;
          &.active {
            background-color: transparent;
            &:before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              opacity: 0.2;
              background-image: linear-gradient(
                to right,
                var(--primaryColor),
                rgba(255, 255, 255, 0)
              );
            }
          }
        }
        ul.filter-item-inner {
          height: auto;
          & > div {
            width: 100%;
          }
        }
        .contantCN {
          display: flex;
          align-items: center;
          justify-content: center;
          .text {
            display: none;
          }
        }
      }
    }
    .collapse-filter-letter {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: var(--primaryColor);
      color: #000000;
      font-size: 22px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 7px auto;
    }
  }
  .sidebar-left.taskSideBarCollapse .collapse__menu__icon__wp {
    transform: rotate(-180deg);
  }
  .sidebar-left.taskSideBarCollapse {
    .defaultAddTask__btn{
      &.sidebarCollapse__addTask__BtnShow{
        display:none !important;
      }
    }
    .add__task__btn {
      display:block;
    }
    .page-tab {
      .page-tab-item {
        justify-content: center;
        .text-label {
          display: none;
        }
        .icon-wrapper {
          margin-right: 4px;
        }
        &.archived {
          padding-right: 15px;
          .setting {
            display: none;
          }
        }
      }
    }
    // .filter-wrapper {
    //   border: none;
    //   .drag-icon-wrapper,
    //   .action-btn-wrapper {
    //     display: none;
    //   }
    //   .filter-item-inner {
    //     height: 46px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border-bottom: none;
    //     border-top: none;
    //     border: none;
    //     &.active {
    //       background-color: transparent;
    //       &:before {
    //         content: '';
    //         position: absolute;
    //         top: 0px;
    //         left: 0px;
    //         width: 100%;
    //         height: 100%;
    //         opacity: 0.2;
    //         background-image: linear-gradient(
    //           to right,
    //           var(--primaryColor),
    //           rgba(255, 255, 255, 0)
    //         );
    //       }
    //     }
    //   }
    //   ul.filter-item-inner {
    //     height: auto;
    //     & > div {
    //       width: 100%;
    //     }
    //   }
    //   .contantCN {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     .text {
    //       display: none;
    //     }
    //   }
    // }
    // .collapse-filter-letter {
    //   width: 36px;
    //   height: 36px;
    //   border-radius: 50%;
    //   background-color: var(--primaryColor);
    //   color: #000000;
    //   font-size: 22px;
    //   font-weight: 600;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin: 7px auto;
    // }
    .filter-wrapper{
      & > .filter-item.accordion{
        display:none;
      }
    }
    .sidebarCollapse__CN{
      display:block;
    }
  }
  .content-right {
    width: calc(100% - 260px) !important;
    border-left: none !important;
    .scrollbar-container {
      height: calc(100dvh - 326px) !important;
      .noRecordFound__wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 15px;
        .img-wrapper {
          img {
            width: 250px;
          }
        }
      }
    }
    .task-manager-top-header {
      display: flex;
      // flex-wrap:wrap;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      align-items: center;
      padding:6px 14px;
      padding-left: 0px;
      .task-manager-sidebar-toggle-btn {
        display: none;
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-content: center;
        margin-left: 15px;
        cursor: pointer;
        .line {
          display: block;
          width: 100%;
          height: 3px;
          border-radius: 15px;
          background-color: var(--primaryColor);
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0px;
          }
          &:nth-child(2) {
            width: 70%;
          }
        }
      }
      .task-manager-serach-box {
        .search__task__input {
          border: none;
          font-size: 18px;
          color: #000000 !important;
          padding-left: 44px;
          font-size:15px;
          &::placeholder {
            color: #a6a6a6 !important;
          }
          &:focus {
            box-shadow: unset;
          }
        }
        .form-element-icon-wrapper {
          svg {
            left: 15px;
          }
        }
      }
      .selects-custom-dropdown-wrapper {
        .selects-custom-dropdown {
          left: unset;
          right: 0px;
        }
        &.show {
          .filter__icon_wrapper {
            background-color: var(--primaryColor);
            border: 1px solid var(--primaryColor);
            color: #ffffff;
            svg {
              color: #ffffff !important;
            }
          }
        }
        .task__filter__count {
          background-color: var(--primaryColor) !important;
          padding: 1px 7px;
          border-radius: 100px !important;
          right: -7px !important;
          min-width: unset;
          min-height: unset;
          color: #000;
          font-size: 12px;
          top: -5px !important;
          position: absolute;
          border: 1px solid #ffffff !important;
          font-weight: 600;
        }
      }
      .reset-filters-btn {
        width: 39px;
        height: 39px;
        background-color: #ffffff;
        // border: 1px solid #82868b;
        border: none;
        border-radius: 7px;
        margin-left: 15px;
        transition: 0.5s;
        padding: 9px 9px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #f0f0f0;
          svg {
            color: #000000;
          }
        }
        &.active{
          background-color:var(--primaryColor);
          svg{
            fill: #000000 !important;
          }
          &:hover{
            background-color:var(--primaryColor);
            svg{
              fill: #000000 !important;
            }
          }
        }
      }
      .expand-collapse-task-btn {
        margin-top: 0px;
        border-radius: 7px;
        background-color: #ffffff;
        // border: 1px solid #82868b;
        border: none;
        margin-top: 0px;
        transition: 0.5s;
        width: 39px;
        height: 39px;
        padding: 9px;
        display: block;
        cursor: pointer;
        // margin-left:10px;
        .icon-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: #82868b;
          }
        }
        &:hover {
          background-color: #f0f0f0;
        }
        &.collapse-all {
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      .task-print-btn {
        background-color: #ffffff;
        border: none;
        border-radius: 7px;
        padding: 9px 9px;
        cursor: pointer;
        width: 39px;
        height: 39px;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-border {
          width: 22px;
          height: 22px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
      .task-export-btn {
        background-color: #ffffff;
        border: none;
        border-radius: 7px;
        padding: 9px 9px;
        cursor: pointer;
        width: 39px;
        height: 39px;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-border {
          width: 22px;
          height: 22px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
      .left {
        // width:calc(100% - 575px);
        width: 100%;
        padding-right: 12px;
      }
      .right {
        display: flex;
        // flex-wrap:wrap;
        align-items: center;
        flex-shrink: 0;
        .btn-group {
          margin-left: 10px;
        }
      }
      .selects-custom-dropdown-wrapper {
        // margin-left:10px;
        .icon_wrapper {
          transition: 0.5s;
          width: 39px;
          height: 39px;
          border-radius: 7px;
          background-color: #ffffff;
          // border: 1px solid #82868b;
          border: none;
          margin-top: 0px;
          padding: 9px;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
          &:hover {
            background-color: #f0f0f0;
          }
        }
        &.active {
          .icon_wrapper {
            background-color: var(--primaryColor);
            border: 1px solid var(--primaryColor);
            svg {
              color: #ffffff;
            }
          }
        }
      }
      .selects-custom-dropdown {
        .heading {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          padding: 12px 12px 4px 12px;
        }
      }
      .searchInSubtask-input-wrapper {
        border-radius: 50%;
        position: relative;
        .inner-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 3;
        }
        .form-check-input {
          width: 22px;
          height: 22px;
          border: 1px solid var(--primaryColor);
          background-color: #ffffff;
          cursor: pointer;
          &:checked {
            background-color: var(--primaryColor);
          }
        }
        .text {
          font-size: 15px;
          font-weight: 500;
          margin-left: 8px;
        }
      }
      .show__categories {
        margin-right: 17px;
        .text {
          position: relative;
          top: 1px;
        }
      }
      .new-task-btn {
        margin-left: 10px;
        padding-left: 18px;
        padding-right: 18px;
        background-color: transparent !important;
        box-shadow: unset !important;
        border: none !important;
        font-weight: 700;
        color: var(--primaryColorDark) !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        svg {
          margin-right: 3px;
        }
      }
    }
             .taskRowlistingHeightWrapper {
              //  height: calc(100dvh - 334px) !important;
               height: calc(100dvh - 384px) !important;
              .ReactVirtualized__Grid__innerScrollContainer{
                // min-height: calc(100dvh - 334px);
                min-height: calc(100dvh - 384px);
              }
               &.bottomLoaderActive {
                 padding-bottom: 48px;
               }
      
               .taskRowDataLoader {
                 position: absolute;
                 bottom: 0px;
                 left: 0px;
                 // right:0px;
                 width: calc(100% - 12px);
                 padding: 10px 10px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 background-color: #ffffff;
                 z-index: 3;
               }
             }
    .task-manager-body {
      .infinite-scroll-component.todo-task-list {
        // height: calc(100dvh - 334px) !important;
        height: calc(100dvh - 384px) !important;
      }
      .task-manager-main-accordion {
        overflow-x: auto;
        .task-manager-table-header,
        .infinite-scroll-component__outerdiv {
          min-width: 1200px;
        }
      }
      .noRecordFound__wrapper {
        padding: 35px 0px;
        .img-wrapper {
          img {
            width: 300px;
          }
        }
      }
      
      .mobile-row-wrapper{
        display:none;
      }
    }
    .task-manager-table-header {
      border: none;
      .task-manager-row {
        border-bottom: none;
        background-color: #eee;
        .task-manager-title {
          padding: 14px 39px 14px 0px;
          .sortHead__wrapper{
            display:inline-flex;
            align-items:center;
            width:auto !important;
            .title{
              display:inline-block;
              max-width:calc(100% - 21px);
            }
            svg{
              width:20px;
              height:20px;
              margin-left:1px;
              position: relative;
              top:1px;
              transform: rotate(180deg);
            }
            svg[stroke="#a3db59"]{
              transform: rotate(0deg);
            }
          }
        }
        &:hover {
          box-shadow: unset;
        }
      }
      .title {
        font-size: 14px;
        color: #000000;
        font-weight: 600;
      }
      .text-nowrap {
        margin-right: 0px !important;
        width: 18px !important;
        height: 18px;
        display: block;
        font-size: 0px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
          position: relative;
          top: 2px;
          margin-left: 1px;
          transform: rotate(180deg);
          // opacity:0;
          &.active {
            position: relative;
            top: 2px;
            transform: rotate(0deg);
            opacity: 1;
            color: #000000;
          }
        }
      }
      .task-number-cell,
      .task-name-cell,
      .contact-cell,
      .task-priority-cell,
      .task-status-cell,
      .task-date-cell,
      .task-assignee-cell,
      .task-pintask-cell,
      .task-checkbox-cell,
      .task-action-cell,
      .down-up-btn-cell {
        display: flex;
      }
      .right {
        & > div {
          &:hover {
            .text-nowrap {
              svg {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .add-task-form-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 10px 10px 10px;
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      background-color: #ffffff;
      position: relative;
      z-index: 4;
      .form-field-row {
        margin: 0px -6px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(100% - 64px);
        padding-right: 12px;
        .form-field-col {
          padding: 0px 6px;
          width: 20%;
          input,
          select {
            width: 100%;
          }
          .table__page__limit {
            .table__page__limit__control {
              background-color: #ffffff !important;
            }
          }
          .table__page__limit__menu {
            z-index: 4;
          }
        }
      }
      .action-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        .action-btn {
          width: 32px;
          height: 32px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 6px;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
          &:hover {
            background-color: #f2f2f2;
            border-radius: 50%;
          }
          &.save-btn {
            svg {
              color: var(--primaryColor);
            }
          }
        }
      }
      .assign__select__box {
        .custom-select__control.custom-select__control--is-focused {
          .custom-select__placeholder {
            display: none;
          }
        }
        .custom-select__value-container {
          display: flex;
        }
        .custom-select__value-container.custom-select__value-container--has-value {
          display: block;
          white-space: pre;
          overflow-x: auto !important;
          flex: auto;
          width: calc(100% - 50px);
          .custom-select__multi-value {
            display: inline-flex;
            white-space: pre;
          }
        }
      }
      .invalid-feedback {
        display: none;
      }
      .action-btn-mobile-wrapper{
        display:none;
      }
    }
    .showing-task-manager-footer {
      font-size: 15px;
      color: #000000;
      padding: 10px 10px;
      border-top: 1px solid #ececec;

      // position: fixed;
      background: #ffffff;
      // bottom: 93px;
      width : 100%;
      z-index : 2;
      position: relative;
    }
    .new-tab-details-design{
      .horizontal-new-tab-wrapper{
        margin-bottom:0px;
        padding-left: 25px;
        &:before{
          background-color:#ececec;
        }
        .horizontal-tabbing{
          margin-top:0px;
          border-radius:0px;
          .nav-item{
            .nav-link{
              cursor: pointer;
              &:hover{
                background-color:#f0f0f0;
              }
            }
          }
        }
      }
      .right__wrapper__OP{
        display:none;
        align-items: center;
        position: absolute;
        top: 14px;
        right: 0px;
        .searchInSubtask-input-wrapper{
          margin-right: 17px;
          .form-check-input{
            border: 1px solid var(--primaryColor);
            background-color: #ffffff;
            cursor: pointer;
            width: 18px;
            height: 18px;
            border-radius: 3px;
          }
          .text{
            margin-left: 8px;
            position: relative;
            top: 1px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    .task-calenderView-wrapper{
      .full-calender-wrapper{
        padding:15px 0px 0px 0px;
        position: relative;
        height:calc(100dvh - 244px);
        overflow-y:auto;
        .startDate-endDate-wrapper{
          display:inline-flex;
          align-items:center;
          position: absolute;
          right: 430px;
          top: 3px;
          .startDate-endDate-item{
            display: inline-flex;
            align-items: center;
            margin-right:5px;
            padding: 8px 13px 10px 13px;
            border-radius: 10px;
            transition:0.5s;
            .form-check-input{
              margin-right: 8px;
              cursor:pointer;
            }
            .form-label{
              margin-bottom:0px;
            }
            &:last-child{
              margin-right:0px;
            }
            &:hover{
              background-color: #f2f2f2;
            }
          }
        }
        .fc-header-toolbar{
          padding:0px 15px;
        }
        .fc{
          &.fc-media-screen{
            padding-bottom:0px;
          }
        }
        .fc-scrollgrid-section-header{
          .fc-col-header-cell{
            background-color:#f0f0f0;
            .fc-col-header-cell-cushion{
              color:#000000;
            }
            &:first-child{
              border-top-left-radius:0px;
            }
            &:last-child{
              border-top-right-radius:0px;
            }
          }
        }
        .fc-daygrid-body{
          &:before{
            background-color:transparent !important;
          }
        }
        .fc-scrollgrid-section-header{
          .fc-timegrid-axis{
            background-color:#f0f0f0;
            border-top-left-radius:0px;
          }
        }
        .fc-daygrid-event,
        .fc-timegrid-event{
          border:none !important;
          padding:0px 0px !important;
          margin-bottom:2px !important;
          &:before,
          &:after{
            display:none;
          }
        }
        .fc-timegrid-event-harness{
          .fc-event-task{
            height:100%;
          }
          &:after{
            display:none;
          }
        }
        .fc-event-task{
          background-color:#ffffff;
          padding:5px 8px 5px 18px;
          border-radius:8px;
          position: relative;
          background-color:#ffffff;
          display:flex;
          width:100%;
          .bg-wrapper,
          .border-wrapper{
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            border-radius:8px;
          }
          .bg-wrapper{
            opacity:0.2;
          }
          .border-wrapper{
            z-index:2;
          }
          .line-wrapper{
            position: absolute;
            top: 50%;
            width: 5px;
            height: calc(100% - 10px);
            transform: translateY(-50%);
            border-radius: 10px;
            left: 6px;
            z-index:3;
          }
          .text{
            position: relative;
            z-index:4;
            font-size: 12px;
            font-weight: 500;
            display:inline-block;
            width:100%;
            white-space:pre;
            overflow:hidden;
            text-overflow:ellipsis;
          }
        }
        .fc-popover.fc-more-popover{
          .fc-daygrid-event,
          .fc-timegrid-event{
            border:none !important;
            padding:0px 0px !important;
            margin-bottom:2px !important;
            &:before,
            &:after{
              display:none;
            }
          }
        }
        .fc{
          .fc-timeGridWeek-view,
          .fc-timeGridDay-view{
            .fc-scroller-harness-liquid{
              height:auto;
              .fc-scroller-liquid-absolute{
                position: relative;
              }
            }
            .fc-timegrid-more-link{
              bottom:unset !important;
              bottom: unset !important;
              width: 20px;
              height: 20px;
              font-size: 0px;
              &:before{
                content:"+";
                position:absolute;
                top:0px;
                font-size:20px;
                color:#000000;
                font-weight:500;
                height: 20px;
                line-height: 20px;
                left: 4px;
              }
              &:hover{
                background-color:#000000;
                &:before{
                  color:#ffffff;
                }
              }
            }
          }
        }
        .fc-view-harness{
          padding:0px 15px 10px 15px;
          height:auto !important;
          & > .fc-list{
            position:relative !important;
            right:unset !important;
            bottom:unset !important;
          }
        }
        .fc-list{
          .fc-list-table{
            display:block;
            width:100%;
            tr{
              .fc-list-event-time{
                display:flex;
                align-items:center;
              }
            }
            tr.fc-list-event{
              // border:1px solid #d5d5d5 !important;
              border:none !important;
              border-bottom: 1px solid #d5d5d5 !important;
              border-radius:0px !important;
              margin-bottom:0px !important;
              &:before{
                background-color:#b1b1b1 !important;
                opacity:0;
              }
              .fc-list-event-time{
                padding-left:0px !important;
              }
              .fc-list-event-graphic{
                display:none;
              }
              .fc-list-event-title{
                width:calc(100% - 160px);
                padding-right:0px;
              }
            }
            .fc-event-task .text{
              white-space:normal !important;
            }
          }
        }
        // .fc .fc-scroller-harness-liquid{
        //   height:calc(100dvh - 367px) !important;
        //   overflow-y:auto;
        // }
        // .fc .fc-dayGridMonth-view{
        //   .fc-scroller{
        //     height:850px;
        //   }
        // }
      }
    }
    .taskKanbanView {
      .taskKanbanView-scroll {
        width: 100%;
        overflow-x: auto;
      }

      .taskKanbanView-board {
        display: flex;
        white-space: pre;

        .taskKanbanView-board-col {
          min-width: 315px;
          width: 315px;
          .taskKanbanView-col-header {
            display:flex;
            align-items:center;
            box-shadow:1px 1px 3px 0px #cfcfcfe5,-1px -1px 2px 0px #ffffffe5,1px -1px 2px 0px #cfcfcf33,-1px 1px 2px 0px #cfcfcf33;
            padding: 6px 8px 6px 12px;
            border-radius: 7px;
            height:45px;
            width: calc(100% - 20px);
            margin:10px auto 4px auto;
            border-top:3px solid #000;
            margin-bottom:4px;
            .title {
              font-size: 15px;
              font-weight: 600;
              color: #000000;
              width: calc(100% - 23px);
              padding-right: 10px;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom:0px;
            }

            .more-options-dropdown {
              display: inline-block;

              .btn-icon {
                display: inline-block;
                width: 22px;
                height: 28px;
                border-radius: 5px;
                svg{
                  width:100%;
                  height:100%;
                  color:#000000;
                }
                &:hover{
                  background-color:#eaeaea;
                }
              }
            }
          }
          .infinite-scroll-component{
            height:calc(100vh - 329px) !important;
            padding:5px 10px 10px 10px;
          }
          .card-main{
            height:auto !important;
          }
          .contact-box{
            background-color:#f5f5f5;
            border-radius: 8px;
            padding:14px;
            margin-bottom: 10px;
            .topHeader{
              display:flex;
              align-items:flex-start;
              justify-content:space-between;
              .move-icon-wrapper{
                width: 30px;
                height: 30px;
                position: relative;
                border-radius:50%;
                .drag-icon{
                  position: absolute;
                  top: 50%;
                  width: 20px;
                  transform: translate(-50%, -50%);
                  &:first-child{
                    left: calc(50% - 4px);
                  }
                  &:last-child{
                    left:calc(50% + 4px);
                  }
                }
                &:hover{
                  background-color:#ffffff;
                }
              }
              .taskNumber{
                display:flex;
                flex-wrap:wrap;
                width:calc(100% - 31px);
                .labelName{
                  font-size:14px;
                  font-weight:500;
                  color:grey;
                  display:block;
                  text-align:right;
                  width:100%;
                }
                .value{
                  font-size:14px;
                  font-weight:500;
                  color:#000000;
                  display:block;
                  text-align:right;
                  width:100%;
                }
              }
            }
            .contactDetails{
              display:flex;
              align-items:center;
              .avatar{
                width:34px;
                height:34px;
                background-color:var(--primaryColor) !important;
                flex-shrink:0;
                .avatar-content{
                  width:100%;
                  height:100%;
                  font-size:14px;
                  color:#000000;
                }
                img{
                  width:100%;
                  height:100%;
                  object-fit:cover;
                  object-position:center;
                }
              }
              .name-company-details{
                width:100%;
                padding-left:12px;
                .contact-neme{
                  width:100%;
                  margin-bottom:0px;
                  font-size:16px;
                  color:#000000;
                  font-weight:600;
                }
              }
            }
          }
        }
      }
      .taskKanbanCard__box{
        background-color: #fdfdfd;
        border-radius: 14px;
        box-shadow: unset;
        margin-bottom: 16px;
        border: 1px solid #ddd;
        width:100%;
        position: relative;
        .inner-wrapper-tc{
          position: relative;
          z-index: 3;
          .titleWrapper{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 16px 16px;
            .top-header{
              margin-left: -7px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: calc(100% + 7px);
              .left-wrapper{
                display: inline-flex;
                align-items: center;
                .move-icon-cell{
                  padding-left: 0px;
                  margin-right: 6px;
                  border-radius: 50%;
                  width: 34px;
                  height: 34px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .move-icon-wrapper{
                    width: 30px;
                    height: 30px;
                    position: relative;
                    cursor: pointer;
                    .drag-icon{
                      position: absolute;
                      top: 50%;
                      width: 20px;
                      transform: translate(-50%, -50%);
                      &:first-child{
                        left: calc(50% - 4px);
                      }
                      &:last-child{
                        left: calc(50% + 4px);
                      }
                    }
                  }
                }
                .task-number{
                  font-size: 16px;
                  font-weight: 600;
                  color: #000000;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 100%;
                  display: inline-block;
                }
              }
              .right-action{
                display: flex;
                align-items: center;
                .task-checkbox-wrapper{
                  margin-right: 10px;
                  display: flex;
                  .switch-checkbox{
                    display: inline-flex;
                    position: relative;
                    input{
                      position: absolute;
                      top: 0px;
                      bottom: 0px;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                      z-index: 2;
                      left: 0px;
                      right: 0px;
                      cursor: pointer;
                    }
                    .switch-design{
                      width: 52px;
                      height: 26px;
                      background-color: #fff;
                      border-radius: 60px;
                      border: 1px solid #e4e4e4;
                      display: inline-block;
                      transition: 0.5s;
                      &:before{
                        content: "";
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        left: 3px;
                        border-radius: 50%;
                        background-color: #eaeaea;
                        top: 4px;
                        transition: 0.5s;
                      }
                    }
                    input:checked + .switch-design{
                      background-color:var(--primaryColor);
                      &::before{
                        left: 30px;
                        background-color:#ffffff;
                      }
                    }
                  }
                }
                .action-btn-wrapper{
                  display: flex;
                  align-items: center;
                  .action-btn {
                    width: 32px;
                    height: 32px;
                    margin-right: 3px;
                    &:last-child {
                      margin-right: 0px;
                    }
                    .inner-wrapper {
                      width: 100%;
                      height: 100%;
                      svg {
                        width: 100%;
                        height: 100%;
                        color: var(--bs-body-color);
                      }
                    }
                    .pitask-btn {
                      width: 100%;
                      height: 100%;
                      svg {
                        width: 100%;
                        height: 100%;
                        color: var(--bs-body-color) !important;
                      }
                    }
                    .task-action-wrapper {
                      width: 100%;
                      height: 100%;
                      .task-action-icon-wrapper {
                        width: 100%;
                        height: 100%;
                        svg {
                          width: 100%;
                          height: 100%;
                          color: var(--bs-body-color);
                        }
                      }
                    }
                    &.task-snooze-btn {
                      .inner-wrapper {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px;
                        cursor: pointer;
                        svg {
                          path {
                            fill: var(--bs-body-color);
                          }
                        }
                        &:hover {
                          background-color: #f2f2f2;
                          svg {
                            path {
                              fill: #000000;
                            }
                          }
                        }
                      }
                    }
                    &.task-update-btn {
                      .inner-wrapper {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px;
                        cursor: pointer;
                        &:hover {
                          background-color: #f2f2f2;
                          svg {
                            color: #000000;
                          }
                        }
                      }
                    }
                    &.task-pintask-btn {
                      margin-right:0px;
                      .pitask-btn {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px;
                        cursor: pointer;
                        svg {
                          path {
                            fill: transparent;
                            stroke-width: 2px;
                            stroke: var(--bs-body-color);
                          }
                        }
                        &:hover {
                          background-color: #f2f2f2;
                          svg {
                            path {
                              fill: transparent;
                              stroke-width: 2px;
                              stroke: #000000;
                            }
                          }
                        }
                        &.active {
                          svg {
                            path {
                              fill: var(--primaryColor);
                              stroke-width: 2px;
                              stroke: var(--primaryColor);
                            }
                          }
                        }
                      }
                    }
                    &.task-toggle-btn {
                      .task-action-icon-wrapper {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px;
                        cursor: pointer;
                        svg {
                          color: var(--bs-body-color) !important;
                        }
                        &:hover {
                          background-color: #f2f2f2;
                          svg {
                            color: #000000 !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .taskName-wrapper{
              margin-top: 10px;
              margin-bottom: 10px;
              width: 100%;
              .task-name{
                // font-size: 22px;
                font-weight: 600;
                color: #000000;
                display: block;
                line-height: 27px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
              }
            }
            .task-priority-status {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              width:100%;

              &>.task-priority,
              &>.task-status {
                display: none;
              }

              .mobile-task-priority-wrapper {
                margin-right: 6px;
                display: block;
              }

              .mobile-task-status-wrapper {
                display: block;
                margin-left: 6px;
              }

              .mobile-task-priority-wrapper,
              .mobile-task-status-wrapper {

                .task-priority,
                .task-status {
                  width: 100%;
                }

                max-width:calc(50% - 6px);

                .badgeLoyal-wrapper {
                  display: inline-flex;
                  align-items: center;
                  // padding: 4px 30px 6px 31px;
                  padding: 4px 10px 6px 28px;
                  border-radius: 8px;
                  position: relative;
                  width: 100%;
                  pointer-events: none;
                  

                  &::after {
                    display: none;
                  }

                  .dot {
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 6px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 10px;
                    z-index: 2;
                  }

                  .name {
                    display: inline-block;
                    font-size: 11px;
                    font-weight: 500;
                    position: relative;
                    top: 2px;
                    z-index: 2;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre;
                  }

                  .bg-wrapper {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    opacity: 0.1;
                    border-radius: 100px;
                  }

                  .border-wrapper {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    opacity: 0.1;
                    border-radius: 100px;
                  }

                  .down-arrow-btn {
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 6px;
                    cursor: pointer;
                    display: none;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  &.unassigned{
                    padding: 4px 30px 6px 10px;
                    &:before {
                      content: "";
                      position: absolute;
                      top: 7px;
                      right: 14px;
                      width: 8px;
                      height: 8px;
                      border-bottom: 1px solid #000000;
                      border-right: 1px solid #000000;
                      transform: rotate(45deg);
                  }
                  }
                }
              }
            }
            .contact-date-wrapper {
              display: flex;
              margin-bottom: 10px;
              width:100%;

              .contact-wrapper {
                width: 50%;
                padding-right: 8px;

                .label {
                  width: 100%;
                  font-size: 12px;
                  font-weight: 500;
                  color: #000000;
                  margin-bottom: 3px;
                  height: auto;
                }

                .contact-details {
                  width: auto;
                  height: auto;
                  display: flex;
                  align-items: center;
                }

                .contactImg {
                  width: 30px;
                  height: 30px;
                  margin-right: 8px;
                  flex-shrink: 0;

                  a {
                    display: block;
                    width: 100%;
                    height: 100%;

                    .avatar {
                      display: block;
                      width: 100%;
                      height: 100%;
                      background-color: var(--primaryColor) !important;

                      img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                      }

                      .avatar-content {
                        width: 100%;
                        height: 100%;
                        font-size: 11px;
                        color: #ffffff !important;
                      }
                    }
                  }
                }

                .contactName {
                  font-size: 11px;
                  font-weight: 500;
                  width: 100%;
                  display: inline-block;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .date-wrapper {
                width: 50%;
                padding-left: 8px;
                .value {
                  font-size: 12px;
                }
                .label {
                  width: 100%;
                  font-size: 12px;
                  font-weight: 500;
                  color: #000000;
                  margin-bottom: 8px;
                }

                .start-date,
                .end-date {
                  width: 63px;
                  display: inline-block;
                  pointer-events: none;
                  font-size: 15px;
                  color: var(--bs-body-color);
                  font-weight: 500;

                  .form-control {
                    padding: 0px 0px;
                    font-size: 11px;
                    color: var(--bs-body-color);
                    font-weight: 500;
                    height: auto;
                    border: none;
                    width: 72px;
                  }
                }

                .end-date {
                  position: relative;

                  &::before {
                    content: "";
                    position: absolute;
                    top: 7px;
                    left: -16px;
                    width: 6px;
                    height: 2px;
                    background-color: var(--bs-body-color);
                  }
                }
              }
            }
            .assignee-subtask-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-top: 1px solid #e8e8e8;
              padding-top: 18px;
              padding-bottom: 4px;
              width:100%;

              .assignee-wrapper {
                display: inline-block;

                .show > .dropdown-menu.task-assignee-dropdown {
                  display: none !important;
                }

                .task-assignee-wrapper {
                  .down__icon {
                    display: none;
                  }
                }
                .avatar-group{
                  display: inline-flex;
                  justify-content: flex-start;
                  .avatar{
                    width: 32px;
                    height: 32px;
                    transition: all 0.25s ease;
                    background-color: #c3c3c3;
                    border-radius: 50%;
                    cursor: pointer;
                    text-align: center;
                    white-space: nowrap;
                    position: relative;
                    color: #fff;
                    display: inline-flex;
                    font-size: 1rem;
                    vertical-align: middle;
                    font-weight: 600;
                    img{
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      object-position: center;
                      border-radius: 50%;
                      background: white;
                    }
                  }
                }
              }

              .subtask-wrapper {
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;

                .label {
                  width: 100%;
                  font-size: 14px;
                  font-weight: 500;
                  color: #000000;
                }

                .subtask-count {
                  padding: 5px 10px;
                  background-color: var(--primaryColor) !important;
                  color: #000000;
                  font-size: 14px;
                  margin-left: 7px;
                }

                .arrow__btn{
                  width: 26px;
                  height: 26px;
                  flex-shrink: 0;
                  background-color: #efefef;
                  border-radius: 50%;
                  padding: 2px;
                  margin-left:5px;
                  svg{
                    width:100%;
                    height:100%;
                    color:#000000;
                  }
                  &:hover{
                    background-color:#000000;
                    svg{
                      color:#ffffff;
                    }
                  }
                }
              }
            }
            .subTask-listing{
              width:100%;
              .subTask-row{
                display:flex;
                align-items:center;
                width:100%;
                border-top: 1px solid #e8e8e8;
                margin-top: 8px;
                padding-top: 8px;
                .subtaskNumber,
                .subtaskName{
                  display:inline-block;
                  font-size:15px;
                  font-weight:500;
                  width:auto;
                }
                .subtaskNumber{
                  width:62px;
                  margin-right:6px;
                  flex-shrink:0;
                }
                .subtaskName{
                  width:100%;
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
  .subtask-info-body {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    .accordion-body {
      padding: 0px 0px;
      .subtask-info-wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .subtask-info {
          width: 16.66%;
          // background-color: #000000;
          background-color: #eee;
          padding: 20px 14px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            width: 1px;
            height: calc(100% - 40px);
            // background-color: #ffffff;
            background-color: #939393;
            opacity: 0.5;
          }
          .label {
            font-size: 14px;
            // font-weight: 700;
            font-weight: 500;
            // color: white;
            color: black;
            display: block;
            width: 100%;
            margin-bottom: 2px;
          }
          .value {
            font-size: 14px;
            // font-weight: 500;
            font-weight: 700;
            color: var(--primaryColor);
            display: block;
            width: 100%;
          }
        }
        .edit__btn {
          width: 40px;
          padding: 10px 8px;
          position: absolute;
          top: 0px;
          right: 0px;
          height: 100%;
          background-color: var(--primaryColor);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            color: #000000;
          }
        }
      }
    }
  }
  .content-right {
    .task-manager-body {
      &.open-tasks {
        .task-manager-row {
          .task-manager-title {
            .left {
              width: calc(100% - 854px);
            }
          }
        }
      }
      &.archived {
        .task-manager-row {
          .task-manager-title {
            .left {
              // width: calc(100% - 806px);
              width: calc(100% - 905px);
              .move-icon-cell {
                width: 7px;
              }
              .task-name-cell {
                width: calc(100% - 108px);
              }
            }
          }
        }
      }
      &.trash {
        .task-manager-row {
          .task-manager-title {
            .left {
              width: calc(100% - 772px);
              .move-icon-cell {
                width: 7px;
              }
              .task-name-cell {
                width: calc(100% - 108px);
              }
            }
          }
        }
      }
      &.snoozed-tasks {
        .task-manager-row {
          .task-manager-title {
            .left {
              width: calc(100% - 852px);
            }
            .right {
              .down-up-btn-cell {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .mobile-task-priority-wrapper,
  .mobile-task-status-wrapper,
  .task-action-cell .edit-tsak-item{
    display:none;
  }

  
}
.subTask-tab {
  .task-manager-main-accordion {
    & > .todo-task-list {
      min-width: 1200px;
      min-height: unset !important;
    }
  }
}
.subtask-row-wrapper {
  min-height: 57px;
  .task-manager-row {
    &:first-child {
      border-top: 1px solid #ebe9f1;
    }
    .task-manager-title {
      padding-right: 9px !important;
    }
  }
}

.task-priority-dropdown {
  .dropdown-item {
    width: 100%;
  }
}

.task-manager-filters {
  min-width: 280px;
  min-height: 450px;
  .dropdown-item {
    // border-bottom:1px solid #ececec;
    padding: 8px 15px;
    background-color: transparent !important;
    &:last-child {
      border-bottom: none;
    }
  }
  .heading {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    padding: 10px 15px 5px 15px;
  }
  .custom-select__menu-list {
    max-height: 200px;
  }
}

.contact-select-box {
  .table__page__limit__option,
  .custom-select__option {
    border-bottom: 1px solid #f4f4f4 !important;
    &:last-child {
      border-bottom: none;
    }
    .avatar {
      background-color: var(--primaryColor) !important;
      .avatar-content {
        width: 30px;
        height: 30px;
        font-size: 12px;
        color: #000000 !important;
      }
    }
    .ms-1 {
      width: calc(100% - 30px);
      margin-left: 0px !important;
      padding-left: 10px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        max-width: 100%;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #000000 !important;
      }
    }
    &.custom-select__option--is-selected {
      position: relative;
      background-color: transparent !important;
      & > div {
        position: relative;
        z-index: 2;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.1;
      }
    }
  }
}
.priority__select__box,
.status__select__box,
.category__select__box,
.color__select__box {
  .custom-select__single-value {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .bullet {
      margin-right: 8px !important;
    }
    .custom___select__label {
      width: calc(100% - 19px);
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000000;
    }
  }
  .custom-select__option {
    display: flex;
    align-items: center;
    .bullet {
      margin-right: 8px !important;
      position: relative;
      z-index: 2;
    }
    .label {
      width: calc(100% - 19px);
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      z-index: 2;
      display: block;
    }
  }
  .custom-select__option.custom-select__option--is-selected {
    background-color: transparent !important;
    position: relative;
    .label {
      color: #000000 !important;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.1;
    }
  }
}

.loyal-task-manager {
  .content-right {
    .scrollbar-container.task-manager-category-scrollbar {
      height: calc(100dvh - 242px) !important;
      .scrollbar-container {
        height: auto !important;
      }
    }
    .task-manager-category-scrollbar {
      height: calc(100dvh - 245px) !important;
      .task-manager-body {
        .infinite-scroll-component.todo-task-list {
          height: auto !important;
          position: relative;
          .task-manager-row-wrapper:empty {
            min-height: 57px;
          }
          .noRecordFound__wrapper {
            padding-top: 0px;
          }
        }
      }
    }
  }
}

.task-manager-category-item {
  border-bottom: 1px solid #ebe9f1 !important;
  .scrollbar-container {
    height: auto !important;
  }
  .task-manager-cat-headerWrapper{
    position:sticky;
    top:0px;
    z-index:3;
    background-color:#ffffff;
    box-shadow:0px 1px 5px #eaeaea;
  }
  .task-manager-cat-data-header {
    display: flex;
    // flex-wrap:wrap;
    align-items: center;
    padding: 8px 15px;
    position: relative;
    .bg-span {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.05;
      display: none;
    }
    .bullet {
      margin-right: 8px !important;
      position: relative;
      z-index: 2;
      width:12px;
      height:12px;
      flex-shrink:0;
    }
    .category-name {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      width: 100%;
      max-width: 100%;
      padding-right: 15px;
      margin-bottom: 0px;
      position: relative;
      z-index: 2;
      white-space:pre;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .badge {
      padding: 6px 10px;
      flex-shrink: 0;
      margin-right: 6px;
    }
    .action-btn-wrapper {
      display: inline-block;
      flex-shrink: 0;
      position: relative;
      z-index: 2;
      .action-btn {
        width: 32px;
        height: 32px;
        // background-color:grey;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        cursor: pointer;
        svg {
          color: #000000;
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  .accordion-body {
    padding-left: 0px !important;
  }
  &.open {
    .task-manager-cat-data-header {
      .bg-span {
        display: block;
      }
    }
  }
  &.active {
    .task-manager-cat-data-header {
      background-color: var(--primaryColor);
    }
  }
}

.add-task-modal-new {
  &.modal-dialog {
    max-width: calc(100% - 30px);
  }
  .modal-header-wrapper {
    position: relative;
    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 0;
      opacity: 0.3;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      // background-image:linear-gradient(var(--primaryColor) 15%, #fff 100%);
      background-image: linear-gradient(
        var(--primaryColor) 15%,
        var(--primaryColor) 100%
      );
      opacity: 0.2;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.7) 100%
      );
      opacity: 1;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      display: none;
    }
  }
  .modal-header {
    padding: 20px 20px 80px 30px;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    z-index: 3;
    .btn-close {
      transform: translateY(0px);
      top: 12px;
      background-color: #ffffff;
      top: 26px;
      right: 11px !important;
      &:hover {
        background-color: #ffffff;
      }
    }
    .modal-title {
      width: 100%;
      position: relative;
      .inner-wrapper {
        display: flex;
      }
      .left {
        width: calc(100% - 350px);
        .taskDeatils-header {
          display: flex;
          flex-wrap: wrap;
        }
        .subtask-header {
          margin-top: 12px;
          .title {
            font-size: 19px;
            font-weight: 600;
          }
          .subtask-name {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .subtask-title-wrapper {
        width: 100%;
      }
      .infoNote {
        font-size: 16px;
        color: #4f4f4f;
        font-weight: 400;
        margin-top: 6px;
        .label,
        .value {
          display: inline-block;
        }
        .label {
          font-weight: 600;
          margin-right: 6px;
        }
        .subTask-name {
          color: var(--primaryColorDark);
          font-weight: 600;
        }
      }
      .update-task-wrapper {
        max-width: calc(100% - 72px);
        padding-right: 16px;
        .modal-title-text {
          font-size: 24px;
          font-weight: 700;
        }
        .task-number {
          font-size: 22px;
          font-weight: 700;
          color: var(--primaryColorDark);
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 6px;
          padding: 3px 12px;
          margin-left: 13px;
        }
      }
      .createdBy-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 15px;
        width: 100%;
        .title {
          width: 100%;
          font-size: 19px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .created-by,
        .time {
          svg {
            width: 22px;
            height: 22px;
            margin-right: 7px;
          }
          display: inline-flex;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
        }
        .created-by {
          margin-right: 26px;
        }
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .action-btn {
          width: 32px;
          height: 32px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #ffffff;
          padding: 8px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
          &:last-child {
            margin-left: 6px;
          }
          &:hover {
            background-color: #000000;
            svg {
              color: #ffffff;
            }
          }
        }
      }
      .contact-details {
        display: inline-flex;
        width: auto;
        align-items: flex-end;
        justify-content: flex-end;
        width: 350px;
        flex-shrink: 0;
        padding-bottom: 5px;
        padding-top: 35px;
        .inner-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          & > img {
            width: 48px;
            height: 48px;
            border-radius: 12px !important;
            object-fit: cover;
            object-position: center;
            flex-shrink: 0;
            margin-right: 13px !important;
          }
        }
        .nav-search-item-cn {
          max-width: calc(100% - 64px);
          .user-name {
            font-size: 20px;
            font-weight: 700;
            color: #000000;
            line-height: 24px;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .company-name {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .modal-body {
    padding: 0px;
    margin-top: -50px;
    position: relative;
    z-index: 4;
  }
  // .modal-body{
  //   min-height:calc(100dvh - 220px);
  // }
  .modal-footer {
    border-top: none;
    padding-top: 18px;
    padding-bottom: 18px;
    form {
      margin: 0px;
    }
    .submit-btn,
    .cancel-btn,
    .delete-btn {
      min-width: 148px;
    }
    .delete-btn{
      svg{
        display:none;
      }
    }
    .marked-btn {
      border: none;
      padding-left: 28px;
      padding-right: 15px;
      border: none !important;
      position: relative;
      background-color: transparent !important;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid grey;
        transition: 0.5s;
      }
      &:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 4px;
        width: 12px;
        height: 7px;
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: rotate(-45deg);
      }
      &:focus {
        &:before {
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);
        }
      }
    }
  }
  .horizontal-tab-parentTask-select-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 20px;
    .timerWrapper {
      display: flex;
      align-items: flex-end;
      position: relative;
      top: -8px;
      .viewBtn {
        width: 46px;
        height: 46px;
        border-radius: 7px;
        padding: 10px;
        background-color: var(--primaryColor);
        margin-right: 12px;
        svg {
          color: #000000;
          width: 100%;
          height: 100%;
        }
      }
    }
    .totalTime-spent-wrapper,
    .timer-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      position: relative;
      background-color: #ffffff;
      padding: 1px 14px;
      border-radius: 6px;
      height: 46px;
      width: 140px;
      border: 1px solid #e3e3e3;
      .time {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        width: 100%;
        line-height: 15px;
        position: relative;
        top: -1px;
      }
      .form-label {
        font-size: 13px;
        line-height: 14px;
        width: 100%;
        font-weight: 500;
        margin-bottom: 0px;
        position: relative;
        top: 5px;
      }
    }
    .timer-wrapper {
      margin-right: 10px;
    }
    .start-stop-time-wrapper {
      margin-left: 10px;
      display: flex;
      align-items: center;
      .start-time-btn,
      .stop-time-btn,
      .paush-time-btn {
        height: 46px;
      }
      .paush-time-btn {
        margin-right: 10px;
      }
    }
    &.timerWrapper-active {
      .horizontal-tab-wrapper {
        width: calc(100% - 210px);
        &:before {
          width: calc(100% + 260px);
          left: -30px;
          bottom: 1px;
        }
      }
      .parentTask-selectBox-wrapper {
        display: none;
      }
    }
    &.timerWrapper-active.timer-active {
      .horizontal-tab-wrapper {
        width: calc(100% - 200px);
        &:before {
          width: calc(100% + 250px);
        }
      }
    }
  }
  .horizontal-tab-wrapper {
    width: calc(100% - 315px);
    position: relative;
    .horizontal-tabbing {
      margin-top: 0px;
      padding-top: 1px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 1px;
      width: calc(100% + 365px);
      height: 1px;
      left: -30px;
      background-color: var(--primaryColor);
    }
    .nav-item {
      .nav-link {
        padding: 12px 15px;
        min-width: 150px;
        font-size: 16px;
        height: 49px;
        // &.active{
        //   background-color:var(--primaryColor);
        //   color:#ffffff;
        // }
        background-color: white;
        border-bottom: 1px solid var(--primaryColor);
        position: relative;
        bottom: 1px;
        .subtask-count {
          background-color: transparent !important;
          margin-left: 10px;
          position: relative;
          padding: 6px 10px;
          &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            // background-color:var(--primaryColor);
            background-color: #ddd;
            // opacity:0.2;
            border-radius: 100px;
          }
          .text {
            color: #6e6b7b;
            font-size: 14px;
            position: relative;
            z-index: 2;
          }
        }
        &.active {
          border-bottom: none;
          bottom: 0px;
          .subtask-count {
            background-color: transparent !important;
            &::before {
              content: '';
              background-color: var(--primaryColor);
              // opacity:0.2;
            }
            .text {
              color: #ffffff;
            }
          }
        }
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  .parentTask-selectBox-wrapper {
    width: 300px;
    position: relative;
    top: 1px;
    .select-parent-task {
      .custom-select__option {
        .inner-wrapper {
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        .task-name {
          width: calc(100% - 60px);
          margin-left: 0px !important;
          padding-left: 12px;
          span {
            display: block;
            width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 24px;
          }
        }
      }
    }
  }
  .task-modal-tabs-contant {
    // border:1px solid var(--primaryColor);
    border-bottom: 1px solid #e3e3e3;
    border-top: none;
    // border-bottom-left-radius:10px;
    // border-bottom-right-radius:10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .tab-content {
    padding: 0px;
    .tasks-tab {
      padding: 20px;
      .timeCol {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .start-stop-time-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .timer-wrapper {
            display: inline-flex;
            align-items: center;
            background-color: #f4f4f4;
            padding: 3px 14px;
            border-radius: 6px;
            height: 41.2px;
            margin-right: 11px;
            .label {
              margin-bottom: 0px;
              margin-right: 7px;
            }
            .time {
              font-size: 15px;
              font-weight: 500;
              color: #000000;
            }
          }
        }
        .start-time-btn {
          height: 41.2px;
          width: 41.2px;
          display: inline-flex;
          align-items: center;
          padding: 6px;
          font-size: 0px;
          border-radius: 8px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .paush-time-btn {
          height: 41.2px;
          width: 41.2px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          font-size: 0px;
          border-radius: 8px;
          background-color: orange !important;
          border: 1px solid orange !important;
          svg {
            width: 100%;
            height: 100%;
            color: #ffffff;
          }
        }
        .stop-time-btn {
          height: 41.2px;
          width: 41.2px;
          display: inline-flex;
          align-items: center;
          padding: 6px;
          font-size: 0px;
          border-radius: 8px;
          margin-left: 10px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .updates-tab {
      padding: 20px;
    }
  }
  .editor-scheduleTasks-row {
    position: relative;
    padding-top: 26px;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: #eaeaea;
      width: calc(100% - 30px);
    }
    .editor-col {
      .editor-class {
        min-height: 232px !important;
      }
    }
    .scheduleTasks-col {
      .inner-wrapper {
        height: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 7px;
      }
      .header {
        font-size: 18px;
        background-color: #f2f2f2;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding: 10px 15px;
        font-weight: 600;
        color: #000000;
      }
      .bodyCN {
        padding: 15px 15px;
        .schedule-repeat-label {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin-left: 10px;
          margin-top: 0px;
        }
      }
      .repeatedOn-col {
        .event-days {
          display: flex;
          border-radius: 7px;
          overflow: hidden;
          background-color: #f7f7f7;
          .event-days-btn {
            width: 14.28%;
            margin: 0px;
            background-color: transparent;
            border-radius: 0px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            color: grey;
            border: 1px solid #e8e8e8;
            &:first-child {
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
            }
            &:last-child {
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
            }
            &.active {
              border-top: 1px solid var(--primaryColor);
              border-bottom: 1px solid var(--primaryColor);
              background-color: var(--primaryColor);
              color: #000000;
            }
          }
        }
      }
    }
  }
  .checklist-attachments-row {
    position: relative;
    padding-top: 20px;
    margin-top: 25px !important;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: #eaeaea;
      width: calc(100% - 30px);
    }
    .checklist-col {
      .inner-wrapper {
        height: calc(100% - 34px);
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        // background-color:#f9f9f9;
      }
      .checklist-boxItem-wrapper {
        padding: 14px;
        .add-more-btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 10px;
          .add-more-btn {
            svg {
              margin-right: 5px;
            }
          }
        }
      }
      .form-label {
        margin-bottom: 10px;
      }
      .add-checklist-btn-wrapper {
        padding: 14px;
      }
      .add-checklist-btn {
        width: 100%;
        svg {
          margin-right: 5px;
        }
      }
      .checklist-filter-header {
        background-color: #f5f5f5;
        border-radius: 8px;
        padding: 13px 14px;
        display: flex;
        .filter-fields {
          width: calc(100% - 189px);
          padding-right: 28px;
        }
        .btn {
          padding: 12px 15px;
        }
      }
      .accordion-item.checklist-box {
        .checklist-header {
          .title-field-wrapper {
            width: calc(100% - 82px);
          }
        }
      }
    }
    .attachments-col {
      .form-label {
        margin-bottom: 10px;
      }
    }
  }
  .loyal-task-manager {
    border: none;
    margin-bottom: 0px;
    .subtask-add-btns-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #f7f7f7;
      padding: 13px 10px;
      .new-subtask-details-btn {
        margin-left: 10px;
      }
    }
    .content-right {
      width: 100% !important;
      .scrollbar-container {
        height: auto !important;
      }
      .task-manager-body {
        .task-manager-row {
          .task-manager-title {
            .left {
              width: calc(100% - 745px);
            }
          }
          .task-pintask-cell {
            display: none;
          }
        }
      }
    }
  }
  .updates-tab {
    .main-heading {
      font-size: 19px;
      font-weight: 700;
      color: #000;
      margin-bottom: 12px;
    }
    .task-modal-updates-row {
      .add-update-col {
        .editor-class {
          min-height: 184px !important;
        }
      }
      .status__select__box,
      .assigned-select-box {
        position: relative;
        z-index: 5;
      }
    }
    .note-listing-col {
      .note-box {
        background-color: #f7f7f7;
        padding: 15px 15px;
        border-radius: 15px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
        .note-footer {
          display: inline-flex;
          align-items: center;
          .author-time {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            .author-name {
              margin-bottom: 5px;
              background-color: #ffffff;
              border-radius: 7px;
              padding: 5px 15px;
            }
            .time {
              color: grey;
              font-weight: 500;
              font-size: 14px;
              display: inline-block;
              margin-bottom: 4px;
              margin-left: 15px;
            }
          }
          .action-btn-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            margin-left: 30px;
            .action-btn {
              width: 32px;
              height: 32px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: #ffffff;
              padding: 7px;
              transition: 0.5s;
              cursor: pointer;
              svg {
                width: 100%;
                height: 100%;
              }
              &:last-child {
                margin-left: 6px;
              }
              &:hover {
                background-color: #000000;
                svg {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  .loyal-task-manager {
    .content-right {
      .scrollbar-container {
        min-height: 600px;
      }
    }
  }
}
.add-task-modal-new.update-tab-active {
  .modal-content {
    width: 800px;
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
  }
  .editor-wrapper {
    position: relative;
  }
  .attachment-save-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    .custom-fileupload {
      border: none;
      background-color: transparent;
      display: block;
      width: 100%;
      .ui-loader {
        .overlay {
          background-color: transparent !important;
        }
      }
      .inner-wrapper {
        padding: 0px 0px;
        .dropzone {
          display: inline-block;
          .outsite-CN {
            display: none !important;
          }
        }
      }
      .ln-btn {
        padding: 7px 14px;
        border-radius: 4px;
        position: absolute;
        bottom: -38px;
        right: 155px;
        display: flex;
        align-items: center;
        .spinner-border {
          margin-right: 8px;
          width: 21px;
          height: 21px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          color: #000000;
          position: relative;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
      .attachment-wrapper {
        margin-top: 6px;
        justify-content: flex-start;
        .file__card {
          max-width: 25%;
          width: 200px;
          .inner-wrapper {
            padding-top: 90%;
            .overlay {
              background-color: rgba(0, 0, 0, 0.6) !important;
              transition: 0.3s;
            }
          }
        }
      }
      .remove-all-btn {
        position: absolute;
        bottom: -38px;
        left: 0px;
        margin-top: 0px;
      }
    }
    .btn-primary {
      margin-left: 10px;
      min-width: 140px;
    }
  }
  .note-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 12px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .profile-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      .avatar {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }
    .right-contant {
      width: calc(100% - 47px);
      padding-left: 10px;
      .note-header {
        display: flex;
        align-items: center;
        .name-time {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          width: 100%;
          padding-right: 12px;
          .name,
          .time {
            display: inline-block;
            margin-bottom: 4px;
          }
          .name {
            margin-right: 10px;
            color: #000000;
            font-weight: 600;
          }
          .time {
            font-weight: 500;
            position: relative;
            padding-left: 16px;
            &:before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #aeaeae;
            }
          }
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
          flex-shrink: 0;
          .action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #ffffff;
            padding: 7px;
            transition: 0.5s;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
            &:last-child {
              margin-left: 6px;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
        }
      }
      .attachment-wrapper {
        .file__card {
          width: 25%;
          margin-bottom: 7px;
          .inner-border-wrapper {
            background-color: #ffffff;
            border: 1px solid #ececec;
          }
          .inner-wrapper {
            padding-top: 0px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }
          .file__preview {
            position: static;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            img {
              border-radius: 6px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .file-name {
            font-size: 16px;
            font-weight: 400;
            color: grey;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
          .file__preview__details {
            display: flex;
            width: calc(100% - 69px);
            padding-right: 12px;
            align-items: center;
          }
          .overlay {
            position: static;
            opacity: 1;
            z-index: 2;
            background-color: transparent;
            display: inline-flex;
            flex-shrink: 0;
            width: auto;
            height: auto;
            .action-btn-wrapper {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .action-btn {
                width: 32px;
                height: 32px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #ffffff;
                padding: 7px;
                transition: 0.5s;
                cursor: pointer;
                margin-right: 0px;
                margin-left: 0px;
                svg {
                  width: 100%;
                  height: 100%;
                  color: #777 !important;
                }
                &:last-child {
                  margin-left: 4px;
                }
                &:hover {
                  background-color: #000000;
                  svg {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// new-task-modal
.add-task-modal-new {
  &.modal-dialog {
    width: 1000px;
    max-width: calc(100% - 30px);
    margin: 40px auto !important;
  }

  // add-task-modal
  &.only-add-task-modal {
    .marked-btn-mobile{
      display:none;
    }
    .mobile-header-wrapper{
      display:none;
    }
    .task-modal-loader-wrapper {
      min-height: calc(100dvh - 320px);
    }
    .modal-header-wrapper {
      &::before {
        display: none;
      }
      .bg-img {
        display: none;
      }
      .modal-header {
        padding: 15px 20px 20px 20px;
        .modal-title {
          .left {
            // width: auto;
            width:100%;
          }
        }
      }
      .btn-close {
        background-color: #f2f2f2;
        background-image: unset;
        top: 28px;
        width: 34px;
        height: 34px;
        padding: 0px;
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          height: 14px;
          width: 2px;
          background-color: #000000;
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &:hover {
          background-color: #000000;
          &:before,
          &:after {
            background-color: #ffffff;
          }
        }
      }
    }

    .taskName-date-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 20px;

      .taskName-field {
        width: calc(100% - 200px);
        padding-right: 20px;
      }

      .datepickerRange-field {
        width: 200px;
        // .start-date,
        // .end-date {
        //   width: calc(50% - 6px);
        // }

        .react-datepicker__aria-live {
          display: none;
        }
        .react-datepicker {
          .react-datepicker__day {
            &.react-datepicker__day--in-range {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% + 8px);
                height: 100%;
                background-color: var(--primaryColor);
                opacity: 0.2;
              }
              &:hover {
                background-color: transparent;
              }
            }
            &.react-datepicker__day--range-start {
              border-radius: 0px;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              background-color: var(--primaryColor);
              &:before {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                width: calc(100% + 4px);
                left: 0px;
                transform: translateX(0px);
              }
            }
            &.react-datepicker__day--range-end {
              border-radius: 0px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              background-color: var(--primaryColor);
              &:before {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                width: calc(100% + 4px);
                left: -4px;
                transform: translateX(0px);
              }
            }
          }
        }
      }
    }
    .horizontal-tab-parentTask-select-wrapper {
      padding: 0px 0px;
      display: inline-block;
      position: absolute;
      top: 4px;
      right: 53px;
      z-index: 4;
      .horizontal-tab-wrapper {
        display: none;
      }
      .parentTask-selectBox-wrapper {
        width: auto;
        top: 3;
        .custom-select__control {
          border: none;
          .custom-select__single-value {
            max-width: 220px !important;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .custom___select__label {
            font-size: 16px;
            color: #000000;
          }
          .custom-select__clear-indicator {
            margin-left: 6px;
            svg {
              color: #000000;
            }
          }
          .custom-select__dropdown-indicator {
            margin-right: 0px;
            color: #000000;
            margin-left: 6px;
          }
          .custom-select__clear-indicator ~ .custom-select__dropdown-indicator {
            margin-left: 0px;
          }
          .custom-select__input-container {
            max-width: 250px;
          }
        }
        .custom-select__menu {
          width: 300px;
          right: 0px;
        }
      }
    }
    .horizontal-tab-wrapper {
      .nav-item {
        .nav-link {
          .subtask-count {
            padding: 3px 9px 4px 9px;
            .text {
              font-size: 12px;
            }
          }
        }
      }
    }
    .modal-body {
      margin-top: 0px;
      position: static;
    }
    .tasks-tab {
      padding-top: 10px;
    }
    .timeCol:empty {
      display: none;
    }
    .task__top__details__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -8px;
      position: relative;
      padding-bottom: 5px;
      margin-bottom: 18px;
      &::before {
        content: '';
        position: absolute;
        left: 0px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: #eee;
        width: calc(100% + 24px);
      }
    }
    .task__top__details__col {
      width: 50%;
      padding: 0px 8px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      min-height: 42px;
      .inner-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        & > .label {
          display: flex;
          align-items: center;
          position: relative;
          font-weight: 600;
          font-size: 16px;
          width: 140px;
          .icon-wrapper {
            width: 22px;
            height: 22px;
            margin-right: 8px;
            position: relative;
            top: -2px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          &::before {
            content: ':';
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .value {
        width: calc(100% - 165px);
        padding-left: 15px;
        .add-span {
          font-size: 16px;
          font-weight: 400;
          transition: 0.5s;
          cursor: pointer;
          &:hover {
            color: var(--primaryColorDark);
            cursor: pointer;
          }
        }
        .contact-wrapper {
          display: inline-flex;
          align-items: center;
          position: relative;
          max-width: 100%;
          width: 100%;
          & > div {
            width: 100%;
          }
          .avatar {
            width: 30px;
            height: 30px;
            background-color: var(--primaryColor) !important;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            .avatar-content {
              width: 100%;
              height: 100%;
              color: #000000;
              font-size: 11px;
            }
          }
          .contact-name {
            width: calc(100% - 31px);
            font-size: 15px;
            color: #000000;
            font-weight: 500;
            padding-left: 8px;
            white-space: pre;
            overflow: hidden;
            // text-overflow:ellipsis;
          }
          .arrow-wrapper {
            width: 22px;
            height: 22px;
            margin-left: 4px;
            flex-shrink: 0;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .assignTo-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          & > div {
            width: 100%;
          }
          .avatar-group {
            .avatar {
              width: 30px;
              height: 30px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .arrow-wrapper {
            width: 22px;
            height: 22px;
            margin-left: 4px;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          .react-select {
            .avatar {
              &.pull-up {
                border: 3px solid white;
              }
              transform: translateY(0px) scale(1) !important;
              background-color: transparent !important;
              box-shadow: unset !important;
              &.bg-light-primary {
                background-color: var(--primaryColor) !important;
              }
              .avatar-content {
                font-size: 12px;
                padding-bottom: 3px;
                color: black;
              }
              img {
                width: 32px;
                height: 32px;
                object-fit: cover;
                object-position: center;
              }
            }
            .custom-select__single-value {
              &:first-child {
                margin-left: 0px;
              }
              margin-left: -10px;
            }
          }
        }
        .badgeLoyal-wrapper {
          display: inline-flex;
          align-items: center;
          padding: 4px 30px 6px 31px;
          border-radius: 8px;
          position: relative;
          width: 100%;
          .dot {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            z-index: 2;
          }
          .name {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            position: relative;
            z-index: 2;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
          }
          .bg-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .border-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .down-arrow-btn {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            cursor: pointer;
          }
        }
        .priority-label-wrapper {
          display: inline-flex;
          align-items: center;
          padding: 4px 30px 6px 31px;
          border-radius: 8px;
          position: relative;
          width: 100%;
          .dot {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            z-index: 2;
          }
          .name {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            position: relative;
            z-index: 2;
          }
          .bg-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .border-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .down-arrow-btn {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            cursor: pointer;
          }
        }
        .status-label-wrapper {
          display: inline-flex;
          align-items: center;
          padding: 4px 30px 6px 31px;
          border-radius: 8px;
          position: relative;
          width: 100%;
          .dot {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            z-index: 2;
          }
          .name {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            position: relative;
            z-index: 2;
          }
          .bg-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .border-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .down-arrow-btn {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            cursor: pointer;
          }
        }
        .category-label-wrapper {
          display: inline-flex;
          align-items: center;
          padding: 4px 30px 6px 31px;
          border-radius: 8px;
          position: relative;
          width: 100%;
          .dot {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            z-index: 2;
          }
          .name {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            position: relative;
            z-index: 2;
          }
          .bg-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .border-wrapper {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            border-radius: 8px;
          }
          .down-arrow-btn {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            cursor: pointer;
          }
        }
        .value-text {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
        }
        .form-control {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          padding: 6px 12px 6px 0px;
          border: none;
          transition: 0.5s;
          &:focus {
            padding: 6px 12px 6px 12px;
            border: 1px solid var(--primaryColor);
          }
        }
      }
      .custom-select__control {
        padding-left: 0px;
        min-height: unset !important;
        border: none !important;
        display: inline-flex;
        width: auto !important;
        max-width: 100%;
        flex-wrap: nowrap;
        .custom-select__value-container {
          // margin-right:6px;
          margin-right: 0px;
          // display:flex;
          flex: unset;
        }
        .react-select {
          width: 100%;
        }
        .custom-select__indicators {
          margin-left: 6px;
        }
      }
      .custom-select__menu {
        z-index: 4;
        .custom-select__option {
          &.custom-select__option--is-selected {
            background-color: var(--primaryColor) !important;
          }
        }
      }
      &.priority,
      &.status,
      &.category {
        .custom-select__menu {
          .custom-select__option.custom-select__option--is-selected {
            color: grey !important;
            &:hover {
              color: var(--primaryColorDark) !important;
              background-color: #f2f2f2 !important;
            }
          }
        }
      }
      .assignTo-wrapper {
        .custom-select__control {
          .custom-select__indicators {
            margin-left: 0px;
          }
          .custom-select__clear-indicator {
            display: none;
          }
          &:hover {
            .custom-select__clear-indicator {
              display: none;
            }
          }
        }
        .custom-select__value-container {
          display: flex;
          flex-wrap: nowrap;
        }
      }
      &.contact__company__profile {
        .created-user-wrapper {
          background-color: #f2f2f2;
          border-radius: 100px;
          border: 1px solid grey;
          padding: 6px 15px 6px 6px;
          display: inline-flex;
          align-items: center;
          border: 1px solid #e1e1e1;
          margin-bottom: 10px;
          cursor: pointer;
          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--primaryColor) !important;
            color: #000000 !important;
            .avatar-content {
              width: 100%;
              height: 100%;
              line-height: 28px;
              font-size: 15px;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
          }
          .name {
            color: var(--bs-body-color);
            font-weight: 600;
            font-size: 14px;
            padding-left: 6px;
            .userName {
              color: #000000;
            }
          }
        }
      }
      &.after-task {
        width: 100%;
        .inner-wrapper {
          align-items: flex-start;
          textarea.form-control {
            border: 1px solid #eaeaea;
            font-weight: normal;
            border-radius: 6px;
            padding: 7px 12px 10px 12px !important;
            &:focus {
              border: 1px solid var(--primaryColor);
            }
          }
        }
      }
    }
    .two__textarea {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -10px;
      .textarea-col {
        width: 50%;
        padding: 0px 10px;
      }
    }
    .checklist-wrapper {
      padding-top: 15px;
      .header-title {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        .downBtn-wrapper {
          margin-left: 8px;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .field-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -8px;
        padding-bottom: 14px;
        .field-col {
          padding: 0px 8px;
          width: 50%;
          &.field-col-btn {
            width: 220px;
            max-width: 100%;
            margin-top: 12px;
          }
          &.folder-col {
            .bullet {
              display: none;
            }
          }
        }
      }
      .accordion-item.checklist-box {
        border-radius: 9px !important;
        .checklist-header {
          .title-field-wrapper {
            width: calc(100% - 82px);
          }
        }
      }
      .add-more-btn {
        padding: 0px 0px;
        background-color: transparent !important;
        border: none !important;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
        &:hover {
          color: var(--primaryColorDark);
        }
      }
    }
    .checklist-scheduleTasks-wrapper {
      display: flex;
      flex-wrap: wrap;
      .checklist-wrapper {
        width: 50%;
        padding-right: 15px;
        padding-top: 15px;
        .header-title {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          .downBtn-wrapper {
            margin-left: 8px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .field-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0px -8px;
          padding-bottom: 14px;
          .field-col {
            padding: 0px 8px;
            width: 50%;
            &.field-col-btn {
              width: 220px;
              max-width: 100%;
              margin-top: 12px;
            }
            &.folder-col {
              .bullet {
                display: none;
              }
            }
          }
        }
        .accordion-item.checklist-box {
          border-radius: 9px !important;
          .checklist-header {
            .left-wrapper {
              width: calc(100% - 62px);
            }
            .title-field-wrapper {
              width: calc(100% - 70px);
              margin-right: 0px;
            }
          }
        }
        .add-more-btn {
          padding: 0px 0px;
          background-color: transparent !important;
          border: none !important;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
          svg {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          &:hover {
            color: var(--primaryColorDark);
          }
        }
      }
      .schedule-tasks-wrapper {
        width: 50%;
        padding-left: 15px;
        padding-top: 15px;
        .header-title {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          .downBtn-wrapper {
            margin-left: 8px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .field-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0px -8px;
          .field-col {
            width: 50%;
            padding: 0px 8px;
            margin-bottom: 12px;
            &.repeat-field-col {
              width: 100%;
            }
          }
          .flatpickr-wrapper{
            .flatpickr-calendar{
              right:0px;
            }
          }
        }
        .schedule-repeat-label {
          flex-shrink: 0;
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin-left: 10px;
          margin-top: 2px;
        }
        .event-days {
          display: flex;
          border-radius: 7px;
          overflow: hidden;
          // background-color:#f7f7f7;
          .event-days-btn {
            width: 14.28%;
            margin: 0px;
            background-color: transparent;
            border-radius: 0px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            color: grey;
            border: 1px solid #e8e8e8;
            border-right: none;
            &:hover {
              background-color: #f7f7f7;
            }
            &:first-child {
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
            }
            &:last-child {
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
              border-right: 1px solid #e8e8e8;
            }
            &.active {
              border-top: 1px solid var(--primaryColor);
              border-bottom: 1px solid var(--primaryColor);
              background-color: var(--primaryColor);
              color: #000000;
            }
          }
        }
      }
      .custom-select__menu-portal {
        z-index: 5;
      }
      .add-checklist-btn {
        svg {
          margin-right: 6px;
          position: relative;
          top: -1px;
        }
      }
    }
    .attachment-save-btn-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;
      .custom-fileupload {
        border: none;
        background-color: transparent;
        display: block;
        width: 100%;
        .ui-loader {
          .overlay {
            background-color: transparent !important;
          }
        }
        .inner-wrapper {
          padding: 0px 0px;
          .dropzone {
            display: inline-block;
            .outsite-CN {
              display: none !important;
            }
          }
        }
        .ln-btn {
          padding: 7px 14px;
          position: absolute;
          bottom: -75px;
          left: 0px;
          display: flex;
          align-items: center;
          right: unset;
          background-color: transparent;
          border-radius: 6px;
          .spinner-border {
            margin-right: 8px;
            width: 21px;
            height: 21px;
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            color: #000000;
            position: relative;
            top: -2px;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
          }
          &:hover {
            background-color: #f4f4f4;
          }
        }
        .attachment-wrapper {
          margin-top: 6px;
          justify-content: flex-start;
          .file__card {
            width: 20%;
            .overlay {
              background-color: rgba(0, 0, 0, 0.6) !important;
              transition: 0.3s;
            }
          }
        }
        .remove-all-btn {
          position: static;
          margin-top: 0px !important;
          justify-content: flex-start !important;
        }
      }
      .btn-primary {
        margin-left: 10px;
        min-width: 140px;
      }
    }
    .note-box {
      background-color: #f7f7f7;
      border-radius: 8px;
      padding: 10px 10px;
      margin-bottom: 12px;
      &:first-child {
        margin-top: 18px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
      .profile-img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        .avatar {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
          .avatar-content{
            width:100%;
            height:100%;
            font-size:14px;
          }
        }
      }
      .right-contant {
        width: calc(100% - 47px);
        padding-left: 10px;
        .note-header {
          display: flex;
          align-items: center;
          .name-time {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            width: 100%;
            padding-right: 12px;
            .name,
            .time {
              display: inline-block;
              margin-bottom: 4px;
            }
            .name {
              margin-right: 10px;
              color: #000000;
              font-weight: 600;
            }
            .time {
              font-weight: 500;
              position: relative;
              padding-left: 16px;
              &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #aeaeae;
              }
            }
          }
          .action-btn-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            flex-shrink: 0;
            .action-btn {
              width: 32px;
              height: 32px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: #ffffff;
              padding: 7px;
              transition: 0.5s;
              cursor: pointer;
              svg {
                width: 100%;
                height: 100%;
              }
              &:last-child {
                margin-left: 6px;
              }
              &:hover {
                background-color: #000000;
                svg {
                  color: #ffffff;
                }
              }
            }
          }
        }
        .attachment-wrapper {
          .file__card {
            width: 50%;
            margin-bottom: 7px;
            .inner-border-wrapper {
              background-color: #ffffff;
              border: 1px solid #ececec;
            }
            .inner-wrapper {
              padding-top: 0px;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
            }
            .file__preview {
              position: static;
              width: 40px;
              height: 40px;
              flex-shrink: 0;
              img {
                border-radius: 6px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
            .file-name {
              font-size: 16px;
              font-weight: 400;
              color: grey;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-left: 10px;
            }
            .file__preview__details {
              display: flex;
              width: calc(100% - 69px);
              padding-right: 12px;
              align-items: center;
            }
            .overlay {
              position: static;
              opacity: 1;
              z-index: 2;
              background-color: transparent;
              display: inline-flex;
              flex-shrink: 0;
              width: auto;
              height: auto;
              .action-btn-wrapper {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                .action-btn {
                  width: 32px;
                  height: 32px;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  background-color: #ffffff;
                  padding: 7px;
                  transition: 0.5s;
                  cursor: pointer;
                  margin-right: 0px;
                  margin-left: 0px;
                  svg {
                    width: 100%;
                    height: 100%;
                    color: #777 !important;
                  }
                  &:last-child {
                    margin-left: 4px;
                  }
                  &:hover {
                    background-color: #000000;
                    svg {
                      color: #ffffff !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .subTask-tab {
      .loyal-task-manager {
        margin-top: -6px;
        .subtask-add-btns-wrapper {
          justify-content: center;
        }
        .content-right {
          .task-manager-body {
            .task-manager-main-accordion {
              .task-manager-table-header {
                min-width: unset !important;
              }
            }
          }
          .scrollbar-container {
            min-height: unset !important;
            .ps__rail-x {
              height: 11px !important;
            }
            .ps__rail-x:hover > .ps__thumb-x,
            .ps__rail-x:focus > .ps__thumb-x {
              height: 6px !important;
            }
          }
        }
        .task-manager-main-accordion {
          min-width: 1200px;
        }
      }
      .task-manager-main-accordion {
        & > .todo-task-list {
          min-width: unset !important;
        }
      }
    }
    .tab-pane{
      form{
        & > .attachment-save-btn-wrapper{
          .custom-fileupload{
            .file__card{
              .inner-wrapper{
                padding-top:90% !important;
              }
            }
          }
        }
      }
    }
  }
  // add-task-modal-end

  // update-task-modal
  &.update-task-modal {
    .mobile-header-wrapper{
      display:none;
    }
    .modal-header {
      padding-bottom: 7px !important;
      .modal-title-text {
        font-weight: 600 !important;
        font-size: 22px !important;
      }
      .modal-title {
        .update-task-wrapper {
          .task-number {
            padding: 0px 0px;
            font-weight: 600 !important;
          }
        }
        .createdBy-text {
          .title {
            font-size: 17px;
          }
        }
        .createdBy-text .created-by,
        .createdBy-text .time {
          font-size: 15px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
        .createdBy-text .created-by {
          margin-right: 15px;
        }
        .left {
          width: 100%;
        }
        .subtask-header {
          width: 100%;
          .subtask-name {
            font-size: 26px;
            font-weight: 700;
          }
        }
      }
      .createdBy-text {
        .task__top__details__row {
          margin-bottom: 0px;
          padding-bottom: 0px;
          width:100%;
          &::before {
            display: none;
          }
        }
        .task__top__details__col {
          width: 50%;
          margin-bottom: 0px;
          .inner-wrapper {
            width: 100%;
          }
          .label {
            width: 164px;
          }
          .value {
            .created-time,
            .created-user-name {
              color: var(--bs-body-color);
              font-weight: 600;
              font-size: 16px;
            }
            .created-user-wrapper {
              background-color: #f2f2f2;
              border-radius: 100px;
              border: 1px solid grey;
              padding: 4px 15px 4px 4px;
              display: inline-flex;
              align-items: center;
              border: 1px solid #e1e1e1;
              max-width:100%;
              .avatar {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: var(--primaryColor) !important;
                color: #000000 !important;
                flex-shrink:0;
                .avatar-content {
                  width: 100%;
                  height: 100%;
                  line-height: 28px;
                  font-size: 11px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .name {
                color: var(--bs-body-color);
                font-weight: 600;
                font-size: 14px;
                padding-left: 6px;
                width:100%;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
              }
            }
          }
          .total-time-spent {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
          }
        }
      }
      .contact-details {
        display: none;
      }
      .timeCol {
        position: absolute;
        top: -4px;
        right: 34px;
        width: auto !important;
        margin-bottom: 0px !important;
        .start-stop-time-wrapper {
          background-color: black;
          border-radius: 100px;
          padding: 3px 4px 3px 4px;
          display: flex;
          align-items: center;
          .timer-wrapper {
            height: auto !important;
            padding: 0px 10px 0px 12px !important;
            background-color: transparent !important;
            display: flex;
            align-items: center;
            .time {
              color: white !important;
              letter-spacing: 3px;
              font-size: 15px !important;
              font-weight: 600 !important;
              width: 86px;
            }
            .form-label {
              display: none;
            }
          }
          .paush-time-btn,
          .stop-time-btn,
          .start-time-btn,
          .print-btn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            padding: 3px;
            background-color: transparent !important;
            border: 1px solid transparent !important;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              opacity: 0.3;
              border-radius: 50%;
            }
            svg {
              position: relative;
              z-index: 2;
              width: 100%;
              height: 100%;
            }
            &:hover {
              &::before {
                background-color: white;
                opacity: 1;
              }
            }
          }
          .start-time-btn,
          .print-btn {
            svg {
              color: var(--primaryColor);
            }
            &:hover {
              svg {
                color: var(--primaryColorDark);
              }
            }
          }
          .print-btn {
            padding: 4px;
          }
          .paush-time-btn {
            svg {
              color: orange;
            }
            // &::before{
            //   background-color:orange;
            // }
          }
          .stop-time-btn {
            margin-left: 0px;
            svg {
              color: red;
            }
            // &::before{
            //   background-color:red;
            // }
          }
          // &.start-task{
          //   padding:0px 0px;
          //   .start-time-btn{
          //     width:34px;
          //     height:34px;
          //   }
          // }
        }
      }
    }
    .modal-body {
      position: static;
    }
    .timerWrapper {
      display: none;
    }
    .horizontal-tab-parentTask-select-wrapper {
      position: static;
      width: 100%;
      .horizontal-tab-wrapper {
        display: block;
        width: 100% !important;
        padding: 0px 20px;
        &::before {
          width: 100% !important;
          background-color: #eee;
          left: 0px;
        }
        .nav-item {
          .nav-link {
            min-width: unset;
            border: none;
            bottom: 2px;
            margin-right: 0px;
            padding: 6px 15px;
            border-radius: 0px;
            height: 44px;
            &:hover {
              background-color: #f4f4f4;
            }
            &::after {
              display: none;
              background: unset !important;
              background-color: var(--primaryColorDark) !important;
              height: 2px;
              bottom: 0px;
            }
            &.active {
              color: var(--primaryColorDark);
              &::after {
                display: block;
              }
            }
          }
        }
      }
    }
    .tab-content {
      .updates-tab {
        padding: 10px 20px 20px 20px;
        .task__top__details__row {
          margin-bottom: 0px;
          .task__top__details__col {
            &:first-child {
              .label {
                width: 102px;
              }
              .value {
                width: calc(100% - 102px);
              }
            }
            &:nth-child(2) {
              .label {
                width: 150px;
              }
              .value {
                width: calc(100% - 150px);
              }
            }
          }
          &::before {
            display: none;
          }
        }
        .attachment-save-btn-wrapper {
          .custom-fileupload {
            .ln-btn {
              bottom: -55px;
            }
          }
          .save-btn {
            margin-top: 16px !important;
          }
        }
      }
    }
  }
  // update-task-modal-end

  // add-subtask-modal
  &.only-add-subtask {
    .modal-header-wrapper {
      .modal-header {
        .modal-title {
          .left {
            width: 100%;
            .infoNote {
              margin-top: 0px;
              display: inline-flex;
              align-items: center;
              padding: 8px 12px;
              position: relative;
              border-radius: 8px;
              width: 100%;
              border: 1px solid var(--primaryColor);
              margin-top: 14px;
              .icon-wrapper {
                width: 28px;
                height: 28px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: var(--primaryColor);
                position: relative;
                z-index: 3;
                padding: 5px;
                svg {
                  width: 100%;
                  height: 100%;
                  color: #ffffff;
                }
              }
              .value {
                color: #000000;
                position: relative;
                z-index: 3;
                margin-left: 10px;
              }
              &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: var(--primaryColor);
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }
  // add-subtask-modal-end

  &.subtaskTab-active.modal-dialog {
    width: 1210px;
  }
}
.dropdown-menu.task-status-dropdown,
.dropdown-menu.task-priority-dropdown, 
.dropdown-menu.task-action-dropdown
{  
  z-index: 9999;
}
// new-task-modal-end

// clone-task-modal
.clone-task-modal {
  &.modal-dialog {
    width: 540px;
    max-width: 100%;
  }
  .radio-btn-wrapper {
    .radio-btn-repeater {
      padding-left: 0px;
      margin-right: 30px !important;
      position: relative;
      &:last-child {
        margin-right: 0px !important;
      }
      input {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        left: 0px;
        cursor: pointer;
        margin-left: 0px;
      }
      .form-check-label {
        padding-right: 65px;
        position: relative;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        &:before {
          content: '';
          width: 54px;
          height: 28px;
          display: inline-block;
          transition: 0.5s;
          position: absolute;
          top: -2px;
          background-color: #fff;
          border-radius: 60px;
          // box-shadow: inset 0 0 4px #cecece;
          border: 1px solid #e4e4e4;
          right: 0px;
          transition: 0.5s;
          cursor: pointer;
        }
        &:after {
          content: '';
          position: absolute;
          top: 2px;
          width: 20px;
          height: 20px;
          transition: 0.5s;
          right: 29px;
          border-radius: 50%;
          background-color: #eaeaea;
          transition: 0.5s;
          cursor: pointer;
        }
      }
      input:checked + .form-check-label:before {
        background-color: var(--primaryColor);
        border: 1px solid transparent;
      }
      input:checked + .form-check-label:after {
        right: 6px;
        background-color: #ffffff;
        transform: unset;
      }
    }
  }
  .modal-footer {
    .cancel-btn,
    .submit-btn {
      margin: 0px !important;
    }
    .submit-btn {
      width: auto !important;
      margin-left: 12px !important;
    }
  }
}
// clone-task-modal-end

// task-report-page
.task-report-page{
  .card-header{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    position: relative;
    .right-wrapper{
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      .date-picker-error-wp{
        width:235px;
      }
      .contact-modal{
        width:250px;
        margin-left:10px;
        .react-select{
          width:100%;
          .avatar{
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            background-color: var(--primaryColor) !important;
            .avatar-content{
              width: 100%;
              height: 100%;
              color: #000000;
              font-size: 11px;
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .contact-name{
            width: calc(100% - 31px);
            font-size: 15px;
            color: #000000;
            font-weight: 500;
            white-space: pre;
            overflow: hidden;
            padding-left: 8px;
            text-overflow: ellipsis;
          }
        }
        .custom-select__menu{
          .custom-select__option.custom-select__option--is-selected{
            background-color:transparent !important;
            color:#000000 !important;
          }
          .custom-select__option.custom-select__option--is-selected.custom-select__option--is-focused{
            background-color:#f2f2f2 !important;
          }
        }
      }
      .submit-btn{
        padding-left:10px;
        padding-right:10px;
        width:132px !important;
        margin-left:10px;
      }
      .export-btn{
        padding-left:10px;
        padding-right:10px;
        margin-left:10px;
        padding-top: 10px;
        padding-bottom: 9px;
        svg{
          margin-right:6px;
          width: 18px;
          height: 18px;
        }
      }
    }
    .mobile-toggle-header-btn{
      width:30px;
      height:30px;
      border-radius:50%;
      display:none;
      align-items:center;
      justify-content:center;
      padding:4px;
      transition:0.5s;
      position:absolute;
      top:2px;
      right:15px;
      background-color:#ffffff;
      cursor: pointer;
      svg{
        width:100%;
        height:100%;
        color:#000000;
      }
      &:hover{
        background-color:#000000;
        svg{
          color:#ffffff;
        }
      }
    }
  }
  & > .card{
    & > .card-body{
      display:flex;
      flex-wrap:wrap;
      align-items:flex-start;
      justify-content:space-between;
      padding:20px 20px !important;
      .task-report-chart-wrapper,
      .task-report-table-wrapper{
        .heading{
          display: flex;
          align-items:center;
          margin-bottom:12px;
          .title{
            font-size:18px;
            font-weight:600;
            color:#000000;
            margin-bottom:0px;
            width:calc(100% - 31px);
            padding-right:15px;
          }
          .toggle-btn{
            width:30px;
            height:30px;
            border-radius:50%;
            align-items:center;
            justify-content:center;
            padding:4px;
            transition:0.5s;
            background-color:#ececec;
            cursor: pointer;
            svg{
              width:100%;
              height:100%;
              color:#000000;
            }
            &:hover{
              background-color:#000000;
              svg{
                color:#ffffff;
              }
            }
          }
        }
      }
      .task-report-chart-wrapper{
        width:100%;
        margin-bottom:30px;
        .chart-wrapper{
          background-color: #fbfbfb;
          border-radius: 10px;
          padding: 10px;
        }
      }
      .task-report-table-wrapper{
        width:100%;
        .row{
          margin:0px !important;
        }
        .card-body{
          padding:0px 0px;
        }
      }
    }
  }
  .rdt_Table_wrapper{
    width:100%;
  }
  .rdt_Table{
    .rdt_TableBody{
      .rdt_TableRow{
        .rdt_TableCell{
          font-size:15px;
          .task-number{
            cursor:pointer;
            &:hover{
              text-decoration:underline;
            }
          }
          &:first-child{
            color:var(--primaryColorDark);
          }
        }
        &:last-child{
          background-color:#f9f9f9;
          .rdt_TableCell{
            font-size:14px;
            font-weight:600;
            color:#000000;
          }
        }
      }
    }
  }
}
// task-report-page-end

.setStatus-modal {
  .modal-body{
    padding:20px 20px !important;
  }
  .normal-text {
    font-size: 16px;
    color: grey;
  }
  .modal-footer{
    form{
      width:148px;
      .btn-primary{
        width:100% !important;
      }
    }
  }
}

.add-status-priority-category-modal {
  .status-priority-category-color-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .field-name-wrapper {
      width: calc(100% - 86px);
      padding-right: 15px;
    }
    .color-wrapper {
      .inputType-color-round {
        width: 41.2px;
        height: 41.2px;
        padding: 4px;
        border-radius: 50%;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        .inner-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
        }
        input {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% + 20px);
          height: calc(100% + 20px);
          padding: 0px !important;
          cursor: pointer;
        }
      }
    }
  }
  .marked-checkbox {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    .form-check-input {
      width: 20px;
      height: 20px;
    }
    .label {
      display: flex;
      width: calc(100% - 21px);
      padding-left: 10px;
      font-size: 16px;
    }
  }
}

.task-status-priority-category-delete-modal {
  .rdt_Table_wrapper {
    .search-box-wrapper {
      margin-left: auto;
    }
  }
  .card-body {
    width: 100%;
    margin-top: -68px;
  }
  .delete-task-status-priority-category-card {
    display: flex;
    flex-wrap: wrap;
    .card-header {
      height: auto;
      padding: 0px;
      display: flex;
      background-color: transparent;
      position: relative;
      border: none;
      padding: 10px 15px;
      width: 100%;
      order: -1;
      margin-bottom: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        border-radius: 10px;
      }
      .card-title {
        margin-bottom: 0px;
        position: relative;
        z-index: 2;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 22px;
        color: var(--primaryColorDark) !important;
        width: calc(100% - 128px);
        padding-right: 12px;
      }
      .btn-group {
        margin-right: 0px !important;
        background-color: #ffffff;
      }
    }
    .card-body {
      padding: 0px;
    }
  }
  .select-new-task-status-priority-category-wrapper {
    position: relative;
    z-index: 4;
    width: 300px;
    top: -6px;
    .error-msg {
      display: none;
    }
  }
  .table__page__limit {
    .error {
      .table__page__limit__control {
        border: 1px solid red !important;
      }
    }
  }
}

.timer-history-modal {
  &.modal-dialog{
    width:1500px;
    max-width:calc(100% - 30px);
    padding-right:0px;
    margin:35px auto;
  }
  .modal-content{
    position: relative;
  }
  .modal-header{
    display:inline-flex;
    align-items:center;
    justify-content:center;
    width:auto;
    padding:0px 0px;
    width:34px;
    height:34px;
    background-color:transparent;
    position:absolute;
    right: 15px;
    top:23px;
    z-index:3;
    .modal-title{
      display:none;
    }
    .btn-close{
      position:static;
      transform:unset !important;
      &:hover{
        background-color:#f2f2f2;
      }
    }
  }
  .modal-footer{
    display:none;
  }

  .modal-body{
    padding:0px 0px;
  }
  .main-wrapper{
    display:flex;
    .left-table-wrapper{
      padding:20px 20px;
      width:100%;
      .top-header-wrapper{
        display:flex;
        align-items:center;
        padding-right:40px;
        .title{
          margin-bottom:10px;
          font-weight:600;
          color:#000000;
          font-size:18px;
          width:100%;
        }
        .buttons-wrapper{
          display:flex;
          align-items:center;
          flex-shrink:0;
          margin-bottom:10px;
          .add-new-btn{
            margin-left:10px;
          }
        }
      }
      .rdt_Table_Card{
        border:none;
        border-radius:0px;
        margin-bottom:0px;
        padding-top:10px;
        .card-header{
          display:none;
        }
        .card-body{
          padding:0px 0px;
        }
      }
    }
    .right-form-wrapper{
      width:400px;
      max-width:100%;
      padding:30px 20px 20px 20px;
      background-color:#f7f7f7;
      border-top-right-radius:14px;
      border-bottom-right-radius:14px;
      .form-field-wrapper{
        margin-bottom:16px;
      }
      input.form-control{
        background-color:#ffffff;
      }
      .btns-wrapper{
        display:flex;
        align-items:center;
        .btn{
          width:100px;
          &.cancel-btn{
            background-color: #ffffff !important;
            border-color: #82868b !important;
            color:#6c6c6c !important;
            margin-left:12px;
            &:hover{
              box-shadow:0 8px 25px -8px #82868b !important;
            }
          }
        }
      }
    }
  }
  &.form-enable{
    .modal-header{
      top:12px;
      .btn-close{
        &:hover{
          background-color:#ffffff !important;
        }
      }
    }
    .left-table-wrapper{
      width:calc(100% - 400px);
      .top-header-wrapper{
        padding-right:0px;
      }
    }
  }
}

.timer-note-modal {
  .modal-footer {
    .btn-primary {
      width: 148px !important;
    }
    .btn-danger {
      width: 148px;
    }
  }
}

.task-add-update-modal {
  .editor-wrapper {
    position: relative;
  }
  .attachment-save-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    .custom-fileupload {
      border: none;
      background-color: transparent;
      display: block;
      width: 100%;
      .ui-loader {
        .overlay {
          background-color: transparent !important;
        }
      }
      .inner-wrapper {
        padding: 0px 0px;
        .dropzone {
          display: inline-block;
          .outsite-CN {
            display: none !important;
          }
        }
      }
      .ln-btn {
        padding: 7px 14px;
        border-radius: 4px;
        position: absolute;
        bottom: -38px;
        right: 155px;
        display: flex;
        align-items: center;
        .spinner-border {
          margin-right: 8px;
          width: 21px;
          height: 21px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          color: #000000;
          position: relative;
          top: -2px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
      .attachment-wrapper {
        margin-top: 6px;
        justify-content: flex-start;
        .file__card {
          width: 25%;
          .overlay {
            background-color: rgba(0, 0, 0, 0.6) !important;
            transition: 0.3s;
          }
        }
      }
      .remove-all-btn {
        position: absolute;
        bottom: -38px;
        left: 0px;
        margin-top: 0px;
      }
    }
    .btn-primary {
      margin-left: 10px;
      min-width: 140px;
    }
  }
  .note-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 12px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .profile-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      .avatar {
        width: 100%;
        height: 100%;
        .avatar-content{
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }
    .right-contant {
      width: calc(100% - 47px);
      padding-left: 10px;
      .note-header {
        display: flex;
        align-items: center;
        .name-time {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          width: 100%;
          padding-right: 12px;
          .name,
          .time {
            display: inline-block;
            margin-bottom: 4px;
          }
          .name {
            margin-right: 10px;
            color: #000000;
            font-weight: 600;
          }
          .time {
            font-weight: 500;
            position: relative;
            padding-left: 16px;
            &:before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #aeaeae;
            }
          }
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
          flex-shrink: 0;
          .action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #ffffff;
            padding: 7px;
            transition: 0.5s;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
            &:last-child {
              margin-left: 6px;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
        }
      }
      .attachment-wrapper {
        .file__card {
          width: 50%;
          margin-bottom: 7px;
          .inner-border-wrapper {
            background-color: #ffffff;
            border: 1px solid #ececec;
          }
          .inner-wrapper {
            padding-top: 0px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }
          .file__preview {
            position: static;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            img {
              border-radius: 6px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .file-name {
            font-size: 16px;
            font-weight: 400;
            color: grey;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
          .file__preview__details {
            display: flex;
            width: calc(100% - 69px);
            padding-right: 12px;
            align-items: center;
          }
          .overlay {
            position: static;
            opacity: 1;
            z-index: 2;
            background-color: transparent;
            display: inline-flex;
            flex-shrink: 0;
            width: auto;
            height: auto;
            .action-btn-wrapper {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .action-btn {
                width: 32px;
                height: 32px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #ffffff;
                padding: 7px;
                transition: 0.5s;
                cursor: pointer;
                margin-right: 0px;
                margin-left: 0px;
                svg {
                  width: 100%;
                  height: 100%;
                  color: #777 !important;
                }
                &:last-child {
                  margin-left: 4px;
                }
                &:hover {
                  background-color: #000000;
                  svg {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

// change-parent-task-modal
.change-parent-task-modal{
  &.modal-dialog{
    width:500px;
    max-width:calc(100% - 30px);
  }
  .modal-body{
    padding:20px 20px;
  }
  .radio-btn-wrapper{
    flex-wrap: wrap;
    margin-bottom:14px;
    .radio-btn-repeater{
      margin-right: 20px !important;
      padding-left: 28px !important;
      margin-bottom: 10px !important;
      position: relative;
      .form-check-input{
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 4;
        opacity: 0;
        margin: 0px !important;
        cursor: pointer;
        left: 0px;
      }
      .form-check-label{
        font-size: 16px;
        margin-bottom: 0px !important;
        &:before{
          content: "";
          position: absolute;
          top: 1px;
          width: 20px;
          height: 20px;
          transition: 0.5s;
          border-radius: 50%;
          background-color: #ffffff;
          border: 1px solid #ddd;
          left: 0px;
        }
        &:after{
          content: "";
          position: absolute;
          top: 6px;
          width: 10px;
          height: 10px;
          opacity: 0;
          transition: 0.5s;
          border-radius: 50%;
          background-color: #ffffff;
          left: 5px;
        }
      }
      .form-check-input:checked + .form-check-label{
        &:before{
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);
        }
        &:after{
          opacity: 1;
        }
      }
    }
  }
  .normal-text{
    font-size:14px;
    font-weight:500;
  }
  .modal-footer{
    .btn-primary{
      width:148px !important;
      margin:0px !important;
      margin-left: 12px !important;
    }
  }
}
// change-parent-task-modal-end

// task-warning-modal
.task-warning-modal{
  max-width:500px;
  .normal-text{
    font-size:24px;
    font-weight:500;
    text-align:center;
    color:#000000;
    .taskID{
      color:var(--primaryColorDark);
    }
  }
  .checkbox-wrapper{
    text-align:center;
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    justify-content:center;
    font-size:16px;
    margin-top:20px;
    input{
      margin-right:8px;
    }
    .text{
      display:inline-block;
    }
  }
  .modal-content{
    padding: 15px 10px 22px 10px;
    border-radius: 30px !important;
  }
  .modal-footer{
    margin-top:0px;
    display:flex;
    justify-content:center;
    border-top:none;
    .btn{
      margin:0px !important;
      &.primaryBtn{
        background-color:var(--primaryColor) !important;
        border:1px solid var(--primaryColor) !important;
        color:#000000 !important;
        margin-right:12px !important;
      }
      &.secondnaryBtn{
        background-color:#ffffff !important;
        border:1px solid var(--primaryColor) !important;
        color:var(--primaryColor) !important;
        margin-right:12px !important;
      }
      &.dangerBtn{
        background-color:transparent !important;
        border:1px solid transparent !important;
        color:red !important;
      }
    }
  }
}
// task-warning-modal-end
// task manager end

// Mass email tool page start
.mass-email-tool-header {
  margin-bottom: 15px;
  .btn-group {
    .view-btn {
      &.active {
        background-color: var(--primaryColor);
        color: #000000;
      }
    }
  }
}
.mass-emails-card {
  margin-bottom: 5px;
  .mass-email-card-col {
    margin-bottom: 25px;
    padding: 0px 15px;
    // &:nth-last-child(1),
    // &:nth-last-child(2){
    //   margin-bottom:0px;
    // }
  }
  .card-body {
    padding-top: 20px !important;
  }
  .inner-scroll-area {
    height: calc(100dvh - 272px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    & > div {
      padding: 0px 0px;
    }
    &.load-more-active {
      height: calc(100dvh - 340px);
    }
  }
  .company-form-card {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: white;
    margin: 0px 0px 0px 0px;
    height: 100%;
    position: relative;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 68px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: var(--primaryColor);
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px 70px 15px;
      display: block;
      .nummber-of-contact {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .title {
          font-size: 16px;
          font-weight: 400;
          margin-right: 7px;
          margin-bottom: 3px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          position: relative;
          margin-bottom: 3px;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        padding: 0px 15px 15px 15px;
      }
    }
  }
  .loadMore-btn-wrapper {
    position: relative;
    padding: 15px 10px 14px 10px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 44px);
      height: 1px;
      background-color: #eae8e1;
    }
  }
}
.scheduled-mass-emails-card {
  margin-bottom: 5px;
  .scheduled-mass-email-card-col {
    margin-bottom: 25px;
    padding: 0px 15px;
    // &:nth-last-child(1),
    // &:nth-last-child(2){
    //   margin-bottom:0px;
    // }
  }
  .card-body {
    padding-top: 20px !important;
  }
  .inner-scroll-area {
    height: calc(100dvh - 272px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    & > div {
      padding: 0px 0px;
    }
    &.load-more-active {
      height: calc(100dvh - 340px);
    }
  }
  .company-form-card {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: white;
    margin: 0px 0px 0px 0px;
    height: 100%;
    position: relative;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 68px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: var(--primaryColorDark);
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px 16px 15px;
      display: block;
      .badge {
        border-radius: 8px !important;
        margin-bottom: 10px;
        min-width: 80px;
        text-align: center;
        position: relative;
        left: -15px;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
      .items-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0px;
        }
        .label {
          font-size: 16px;
          font-weight: 400;
          margin-right: 9px;
          margin-bottom: 3px;
        }
        .value {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          position: relative;
          margin-bottom: 3px;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        padding: 0px 15px 20px 15px;
      }
    }
  }
  .loadMore-btn-wrapper {
    position: relative;
    padding: 15px 10px 14px 10px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 44px);
      height: 1px;
      background-color: #eae8e1;
    }
  }
}
.mass-email-tool-row-list-view {
  .ui-loader,
  .rdt_Table_Card {
    height: 100%;
  }
  .rdt_Table_wrapper {
    .badge {
      border-radius: 7px !important;
      margin-bottom: 10px;
      min-width: 80px;
      text-align: center;
      position: relative;
      font-size: 12px;
      margin-bottom: 0px !important;
      padding: 6px 10px;
    }
  }
}

// send-mass-email-modal
.send-mass-email {
  .normal-text {
    font-size: 18px;
    color: #000000;
  }
  .top-header {
    & > .col-md-3 {
      width: 20%;
    }
  }
  .select-template-field {
    .form-field-wrapper {
      position: relative;
      padding-right: 50px;
      .preview-icon {
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        right: 0px;
        transition: 0.5s;
        z-index: 5;
        background-color: #ececec;
        cursor: pointer;
        &:hover {
          background-color: #000000;
          svg {
            color: #ffffff;
          }
        }
      }
    }
  }
  .info-text-wrapper {
    padding: 10px 17px;
    background-color: #f4f4f4;
    border-radius: 10px;
    color: #000;
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin-top: 15px;
    .inner-wrapper {
      position: relative;
      z-index: 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: var(--primaryColor);
      opacity: 0.1;
    }
    .text-primary {
      color: var(--primaryColorDark) !important;
      text-decoration: underline;
    }
  }
  .card {
    box-shadow: unset !important;
    margin-bottom: 0px !important;
    margin-top: 12px !important;
    .card-body {
      background-color: #f7f7f7;
      padding: 5px 20px 10px 20px !important;
      border-radius: 11px;
    }
    .page-link {
      background-color: #ffffff;
    }
    .table__page__limit {
      .table__page__limit__control {
        background-color: #ffffff !important;
      }
    }
  }
  .sender-email-option-row {
    display: flex;
    // flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    .display__one__line {
      width: 100% !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
    }
    .action-btn-wrapper {
      display: inline-flex;
      flex-shrink: 0;
      .action-btn {
        width: 30px;
        height: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.5s;
        padding: 6px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
  }
  .custom-select__option.custom-select__option--is-selected {
    .sender-email-option-row {
      .action-btn-wrapper {
        .action-btn {
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
  }
  .modal-footer {
    .auth-login-form {
      .btn-primary {
        width: 160px !important;
      }
    }
  }
  .more-filter-btn {
    padding: 8px 28px 8px 10px;
    border-radius: 7px;
    border: 1px solid transparent;
    background-color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    transition: 0.5s;
    &:before {
      content: '';
      position: absolute;
      top: 15px;
      right: 12px;
      width: 8px;
      height: 8px;
      border-left: 2px solid #000000;
      border-bottom: 2px solid #000000;
      transform: rotate(-45deg);
    }
    &:hover {
      background-color: #eeeeee;
    }
  }
}
// send-mass-email-modal-end

.clone-mass-email {
  .job-title-row {
    & > div {
      width: 350px;
      max-width: 100%;
    }
  }
  .modal-footer {
    .auth-login-form {
      .btn-primary {
        width: 160px !important;
      }
    }
  }
}
// Mass email tool page end

// create__mass__email__page
.create__mass__email__form {
  .info-normal-text {
    font-size: 18px;
    color: #000000;
    margin-top: 0px;
  }
  .title-template-row-wrapper {
    width: 991px;
    max-width: 100%;
  }
  .sender-email-option-row {
    display: flex;
    // flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    .display__one__line {
      width: 100% !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      padding-right: 6px;
    }
    .action-btn-wrapper {
      display: inline-flex;
      flex-shrink: 0;
      .action-btn {
        width: 30px;
        height: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.5s;
        padding: 6px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
  }
  .card {
    margin-bottom: 10px;
    min-height: calc(100dvh - 168px);
  }
  .card-header {
    justify-content: space-between;
    padding-left: 60px;
    .left {
      display: flex;
      align-items: center;
    }
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 14px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .header-back-btn {
      margin-left: 0px;
    }
    .right {
      display: flex;
      align-items: center;
      .btns-wrapper {
        display: flex;
        align-items: center;
        .btn {
          margin-right: 15px;
          min-width: 165px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .select-template-field {
    .form-field-wrapper {
      position: relative;
      padding-right: 50px;
      .preview-icon {
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        right: 0px;
        transition: 0.5s;
        z-index: 5;
        border: 1px solid #e8e8e8;
        padding-left: 1px;
        cursor: pointer;
        &:hover {
          background-color: #ececec;
        }
      }
    }
  }
  .custom-card-body {
    padding-top: 20px !important;
    padding-bottom: 28px !important;
  }
  .contact-filters-wrapper {
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    .contact-filter-heading {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      .search-box-wrapper {
        width: 350px;
        max-width: 100%;
      }
      .more-filter-btn {
        padding: 8px 28px 8px 10px;
        border-radius: 7px;
        border: 1px solid transparent;
        background-color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        transition: 0.5s;
        &:before {
          content: '';
          position: absolute;
          top: 15px;
          right: 12px;
          width: 8px;
          height: 8px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          transform: rotate(-45deg);
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
    }
    .sec-title {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
      width: 100%;
    }
    .filter-fields-wrapper {
      border-top: 1px solid #e1e1e1;
      padding: 20px 20px 4px 20px;
      .field-col {
        margin-bottom: 18px !important;
      }
      .custom_select_wrapper {
        .cs_form_label {
          background-color: transparent;
          background-image: linear-gradient(#f9f9f9 46%, white 47%);
        }
      }
    }
  }
  .create-mass-email-contact-header {
    display: flex;
    // flex-wrap:wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .left {
      flex-shrink: 0;
      .heading {
        font-size: 20px;
        font-weight: 700;
        color: #000000;
        width: 100%;
        margin-bottom: 4px;
      }
      .list-counter {
        font-size: 14px;
        font-weight: 400;
        color: grey;
        .label {
          margin-right: 3px;
        }
        .value {
          color: #000000;
          font-weight: 500;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 14px;
      .selected-contact-info {
        padding: 7px 12px;
        background-color: #f4f4f4;
        border-radius: 10px;
        color: #000;
        position: relative;
        .inner-wrapper {
          position: relative;
          z-index: 2;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: var(--primaryColor);
          opacity: 0.1;
        }
        .text-primary {
          color: var(--primaryColorDark) !important;
          text-decoration: underline;
        }
      }
      .form-check-input {
        flex-shrink: 0;
      }
    }
  }
  .create-mass-email-contact-list {
    .contact-col {
      margin-bottom: 15px;
      .event-contact-card {
        margin: 0px 0px;
        background-color: #f9f9f9;
        border-radius: 12px;
        border: 1px solid #eaeaea;
        .inner-card {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          padding: 12px 42px 12px 12px;
          .avatar {
            width: 42px;
            height: 42px;
            overflow: hidden;
            .avatar-content{
              width:100%;
              height:100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }
          }
          .right-details {
            width: calc(100% - 43px);
            padding-left: 12px;
            .title {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              font-weight: 700;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 2px;
            }
            .text {
              font-size: 14px;
              color: grey;
              margin-bottom: 0px;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .form-check-input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
          }
        }
      }
    }
  }
}
// create__mass__email__page__end

// scheduled-mass-email-view-modal
.scheduled-mass-email-view-modal {
  .details-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px -8px;
    margin-bottom: 5px;
    width: calc(100% + 16px);
    .details-box {
      width: 16.66%;
      padding: 0px 8px;
      margin-bottom: 15px;
      // border-right:1px solid #e8e8e8;
      .inner-wrapper {
        background-color: #000000;
        border-radius: 10px;
        height: 100%;
        padding: 17px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        position: relative;
        overflow: hidden;
        &:before {
          content: '';
          position: absolute;
          bottom: -80px;
          right: -28px;
          width: 100px;
          height: 100px;
          background-color: var(--primaryColor);
          border-radius: 50%;
          opacity: 0.6;
        }
      }
      &:last-child {
        border-right: none;
      }
      .label {
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        width: 100%;
      }
      .value {
        width: 100%;
      }
      .text {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 500;
        color: var(--primaryColor);
      }
      .template-value-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .preview-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 7px;
          transition: 0.5s;
          margin-left: 6px;
          svg {
            width: 100%;
            height: 100%;
            color: var(--primaryColor);
          }
          &:hover {
            background-color: #ffffff;
            svg {
              color: var(--primaryColorDark) !important;
            }
          }
        }
      }
      .badge {
        font-size: 12px;
        padding: 7px 15px !important;
      }
    }
  }
  .accordion-margin.contact-accordion {
    width: 100%;
    .accordion-item {
      box-shadow: unset !important;
      border: 1px solid #e8e8e8 !important;
      margin-top: 0px;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      .btn-group.toggle-view-btn-wrapper {
        margin-right: 0px;
        position: absolute;
        top: 6px;
        right: 48px;
        z-index: 4;
        .view-btn {
          padding: 7px 7px;
          &.active {
            background-color: var(--primaryColor);
            svg {
              color: #ffffff;
            }
          }
        }
      }
      .accordion-button {
        padding: 8px 8px 8px 20px;
        display: flex;
        justify-content: space-between;
        &:after {
          display: none;
        }
        .title {
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0px;
          width: calc(100% - 100px);
          padding-right: 12px;
          .value {
            color: var(--primaryColor);
            margin-left: 5px;
          }
        }
        .down-arrow-btn {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          transition: 0.5s;
          padding: 3px;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
            transform: rotate(-180deg);
            transition: 0.5s;
          }
          &:hover {
            background-color: #ececec;
          }
        }
        &.collapsed {
          .down-arrow-btn {
            svg {
              transform: rotate(0deg);
            }
          }
        }
      }
      .accordion-collapse.collapse {
        border-top: 1px solid #e8e8e8;
      }
      .accordion-body {
        padding-top: 20px;
      }
      .event-contact-list-wrapper {
        margin: 0px -8px;
        .react-dataTable{
          width:100%;
          overflow-x:auto;
          .rdt_Table{
            min-width:700px;
          }
        }
        .event-contact-col {
          margin: 0px !important;
          margin-bottom: 15px !important;
          padding: 0px 8px !important;
          width: 25%;
          flex: unset;
          .event-contact-card {
            background-color: #f7f7f7;
            border-radius: 12px;
            margin: 0px !important;
            .inner-card {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              position: relative;
              padding: 12px 12px 12px 12px;
            }
            .avatar {
              width: 42px;
              height: 42px;
              .avatar-content{
                width:100%;
                height:100%;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
                overflow: hidden;
              }
            }
            .right-details {
              width: calc(100% - 43px);
              padding-left: 12px;
              .title {
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                font-weight: 700;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 2px;
              }
              .text {
                font-size: 14px;
                color: grey;
                margin-bottom: 0px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .form-check-input-wrapper {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 14px;
              width: 18px;
              height: 18px;
              input {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .rdt_Table_wrapper {
      .search-box-wrapper {
        margin-top: 0px;
      }
    }
  }
}
// scheduled-mass-email-view-modal-end

// sendgrid-statistics-page
.sendgrid-statistics-wrapper {
  .card {
    margin-bottom: 10px;
  }
  .card-header {
    justify-content: space-between;
    padding-left: 60px;
    .left {
      display: flex;
      align-items: center;
    }
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 14px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .header-back-btn {
      margin-left: 0px;
    }
    .right {
      display: flex;
      align-items: center;
      .btns-wrapper {
        display: flex;
        align-items: center;
        .btn {
          margin-right: 15px;
          min-width: 165px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .card-body {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -15px;
    .card-box {
      width: 20%;
      padding: 0px 15px;
      margin-bottom: 24px;
      .inner-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 17px 17px;
        border-radius: 12px;
        background-color: #fbfbfb;
        border: 1px solid #e8e8e8;
        .avatar {
          width: 70px;
          height: 70px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 20px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .details-wrapper {
          width: calc(100% - 71px);
          padding-left: 15px;
          .title {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 0px;
          }
          .value {
            font-size: 24px;
            font-weight: 700;
            color: #000000;
          }
        }
      }
    }
  }
  .chart-row {
    .inner-wrapper {
      background-color: #fbfbfb;
      border: 1px solid #e8e8e8;
      border-radius: 12px;
      height: 100%;
      .chart-title {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 700;
        color: #000000;
        padding: 12px 20px;
        border-bottom: 1px solid #e8e8e8;
      }
      .cart-wrapper {
        padding: 15px 20px;
      }
    }
  }
}
.menu-expanded {
  .sendgrid-statistics-wrapper {
    .card-wrapper {
      .card-box {
        width: 25%;
      }
    }
  }
}
// sendgrid-statistics-page-end

// note css start
.notes-card-box {
  padding: 20px 0px 14px 0px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  min-height: 60px;
  &:last-child {
    border-bottom: none;
  }
  .text {
    padding-right:40px;
    & > * {
      margin-bottom: 0px !important;
    }
  }
  .author-date-name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    .author-name,
    .date {
      display: inline-block;
      margin-bottom: 6px;
      font-size: 14px;
    }
    .author-name {
      color: black;
      font-size: 14px;
      font-weight: 500;
      margin-right: 15px;
      padding: 7px 14px;
      background-color: #eee;
      border-radius: 8px;
    }
  }
  .action-btn-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    top: 14px;
    right:0px;
    .action-btn {
      width: 28px;
      height: 28px;
      margin-right: 5px;
      border-radius: 50%;
      transition: 0.5s;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f3f3;
      svg {
        color: var(--primaryColorDark) !important;
      }
      &:hover {
        background-color: #e4e4e4;
      }
    }
    .edit-btn {
      padding: 7px;
    }
    .dropdown{
      & > .nav-link{
        width:32px;
        height:32px;
        padding:5px 5px;
        transition:0.5s;
        border-radius:50%;
        svg{
          width:100%;
          height:100%;
          color:#000000;
        }
        &:hover{
          background-color:#eaeaea;
        }
      }
    }
  }
  .dropdown-menu{
    .dropdown-item{
      svg{
        width:20px;
        height:20px;
        margin-right:8px;
        color:#000000;
      }
      &:nth-child(2){
        svg{
          width:22px;
          height:2px;
          margin-right:6px;
        }
      }
      &:nth-child(2){
        svg{
          width:17px;
          height:17px;
          margin-right:11px;
        }
      }
      &:hover{
        color:var(--primaryColorDark);
        svg{
          color:var(--primaryColorDark);
        }
      }
    }
  }
}
// note css end

// pinned-notes-modal
.pinned-notes-modal {
  .no-data-wrapper {
    margin: 10px 0px;
  }
  .notes-card-box {
    padding: 12px 0px 12px 0px;
    .text {
      padding-right: 40px;
    }
  }
  #unpin-note {
    path {
      fill: var(--primaryColorDark);
    }
  }
}
// pinned-notes-modal end

// .company-template-tab-card {
//   margin-bottom: 15px;
// }
.company-email-inner-scroll {
  max-height: calc(100dvh - 327px);
  overflow-y: auto;
}
.company-email-temp-card-row {
  .company-email-temp-card-col {
    margin-bottom: 15px;
  }
  .company-form-card {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: white;
    margin: 10px 0px 0px 0px;
    height: 100%;
    position: relative;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 32px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: var(--primaryColor);
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px 45px 15px;
      display: block;
      .cn-wrapper {
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
        .text {
          font-size: 16px;
          color: grey;
          line-height: 24px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        padding: 0px 15px 20px 15px;
        .btn-primary {
          // width: calc(50% - 6px) !important;
          padding: 0px 0px;
          width: auto !important;
          background-color: transparent !important;
          border: none !important;
          color: var(--primaryColor) !important;
          box-shadow: unset !important;
          &:hover {
            color: var(--primaryColorDark) !important;
          }
        }
      }
    }
  }
}

.sms-template-card {
  .card-header {
    padding-right: 115px;
  }
  & > .card {
    box-shadow: unset !important;
    border: none;
    margin-bottom: 0px;
  }
  & > .card-body {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }
  .grid-card-view-toggle-btns {
    position: absolute;
    top: 14px;
    right: 20px;
    z-index: 3;
    .view-btn {
      &.active {
        background-color: var(--primaryColor);
        svg {
          color: #ffffff;
        }
      }
    }
  }
  .loadMore-btn-wrapper {
    position: relative;
    padding: 15px 10px 8px 10px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 44px);
      height: 1px;
      background-color: #eae8e1;
    }
  }
}
.company-sms-inner-scroll {
  height: calc(100dvh - 240px);
  overflow-y: auto;
  &.load-more-active {
    height: calc(100dvh - 300px);
  }
}
.company-sms-temp-card-row {
  .company-sms-temp-card-col {
    margin-bottom: 15px;
  }
  .company-form-card {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: white;
    // margin: 10px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    position: relative;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 32px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: #000000;
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px 58px 15px;
      display: block;
      position: relative;
      .cn-wrapper {
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
        .text {
          font-size: 16px;
          color: grey;
          line-height: 24px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0px;
        padding: 0px 15px 20px 15px;
        .btn-primary {
          width: auto !important;
          color: var(--primaryColor) !important;
          padding: 0px 0px;
          background-color: transparent !important;
          border: none !important;
          box-shadow: unset !important;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          .icon-wrapper {
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            padding: 3px;
            border-radius: 50%;
            background-color: var(--primaryColor);
            svg {
              left: -1px;
              color: #000000;
              position: relative;
              top: 1px;
              transition: 0.5s;
            }
          }
          span {
            color: var(--primaryColorDark);
          }
          &:hover {
            color: var(--primaryColorDark) !important;
            .icon-wrapper {
              background-color: #000000;
              svg {
                color: var(--primaryColor);
              }
            }
          }
        }
      }
    }
  }
}

.create-mass-sms-card {
  .create-mass-sms-header {
    padding-left: 57px;
    display: flex;
    flex-wrap: nowrap;
    .card-title {
      width: 100%;
    }
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 13px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .action-btn-header {
      flex-shrink: 0;
    }
  }
  .create-mass-sms-body {
    padding-top: 20px !important;
    padding-bottom: 28px !important;
  }
  .title-template-row-wrapper {
    width: 991px;
    max-width: 100%;
  }
  .contact-filters-wrapper {
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    .contact-filter-heading {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      .search-box-wrapper {
        width: 350px;
        max-width: 100%;
      }
      .more-filter-btn {
        padding: 8px 28px 8px 10px;
        border-radius: 7px;
        border: 1px solid transparent;
        background-color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        transition: 0.5s;
        &:before {
          content: '';
          position: absolute;
          top: 15px;
          right: 12px;
          width: 8px;
          height: 8px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          transform: rotate(-45deg);
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
    }
    .sec-title {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
      width: 100%;
    }
    .filter-fields-wrapper {
      border-top: 1px solid #e1e1e1;
      padding: 20px 20px 4px 20px;
      .field-col {
        margin-bottom: 18px !important;
      }
      .custom_select_wrapper {
        .cs_form_label {
          background-color: transparent;
          background-image: linear-gradient(#f9f9f9 46%, white 47%);
        }
      }
    }
  }
  .create-mass-email-contact-header {
    display: flex;
    // flex-wrap:wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .left {
      flex-shrink: 0;
      .heading {
        font-size: 20px;
        font-weight: 700;
        color: #000000;
        width: 100%;
        margin-bottom: 4px;
      }
      .list-counter {
        font-size: 14px;
        font-weight: 400;
        color: grey;
        .label {
          margin-right: 3px;
        }
        .value {
          color: #000000;
          font-weight: 500;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 14px;
      .selected-contact-info {
        padding: 7px 12px;
        background-color: #f4f4f4;
        border-radius: 10px;
        color: #000;
        position: relative;
        .inner-wrapper {
          position: relative;
          z-index: 2;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: var(--primaryColor);
          opacity: 0.1;
        }
        .text-primary {
          color: var(--primaryColorDark) !important;
          text-decoration: underline;
        }
      }
      .form-check-input {
        flex-shrink: 0;
      }
    }
  }
  .create-mass-email-contact-list {
    .contact-col {
      margin-bottom: 15px;
      .event-contact-card {
        margin: 0px 0px;
        background-color: #f9f9f9;
        border-radius: 12px;
        border: 1px solid #eaeaea;
        .inner-card {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          padding: 12px 42px 12px 12px;
          .avatar {
            width: 42px;
            height: 42px;
            overflow: hidden;
            .avatar-content{
              width:100%;
              height:100%;
              text-transform:uppercase;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }
          }
          .right-details {
            width: calc(100% - 43px);
            padding-left: 12px;
            .title {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              font-weight: 700;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 2px;
            }
            .text {
              font-size: 14px;
              color: grey;
              margin-bottom: 0px;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .form-check-input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
          }
        }
      }
    }
  }
}

.send-SMS-modal {
  .repeated-number {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .react-tel-input {
      width: calc(100% - 42px);
      padding-right: 12px;
    }
    .remove-minus-btn {
      width: 41px;
      height: 41px;
      padding: 0px;
      background-color: #ffffff !important;
      border-color: #e8e8e8 !important;
      color: #e8e8e8 !important;
      border-radius: 10px;
      transition: 0.5s;
      svg {
        stroke: #82868b;
        color: #82868b;
      }
    }
  }
  .add-more-btn {
    background-color: transparent !important;
    color: var(--primaryColor) !important;
    padding: 0px;
    border: none !important;
    transition: 0.5s;
    font-size: 16px;
    box-shadow: unset !important;
    &:hover {
      color: var(--primaryColorDark) !important;
    }
  }
  .btn-primary {
    width: 148px;
  }
}
.send-mass-sms-modal {
  .normal-text {
    font-size: 18px;
    color: #000000;
  }
  .filter-row {
    width: 1200px;
    max-width: calc(100% + 25px);
    margin-top: 10px;
  }
  .create-mass-email-contact-header {
    display: flex;
    // flex-wrap:wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 18px;
    .left {
      flex-shrink: 0;
      .heading {
        font-size: 20px;
        font-weight: 700;
        color: #000000;
        width: 100%;
        margin-bottom: 4px;
      }
      .list-counter {
        font-size: 14px;
        font-weight: 400;
        color: grey;
        .label {
          margin-right: 3px;
        }
        .value {
          color: #000000;
          font-weight: 500;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 14px;
      .selected-contact-info {
        padding: 7px 12px;
        background-color: #f4f4f4;
        border-radius: 10px;
        color: #000;
        position: relative;
        .inner-wrapper {
          position: relative;
          z-index: 2;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: var(--primaryColor);
          opacity: 0.1;
        }
        .text-primary {
          color: var(--primaryColorDark) !important;
          text-decoration: underline;
        }
      }
      .form-check-input {
        flex-shrink: 0;
      }
    }
  }
  .create-mass-sms-contact-list {
    .contact-col {
      margin-bottom: 15px;
      .event-contact-card {
        margin: 0px 0px;
        background-color: #f9f9f9;
        border-radius: 12px;
        border: 1px solid #eaeaea;
        .inner-card {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          padding: 12px 42px 12px 12px;
          .avatar {
            width: 42px;
            height: 42px;
            overflow: hidden;
            .avatar-content{
              width:100%;
              height:100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }
          }
          .right-details {
            width: calc(100% - 43px);
            padding-left: 12px;
            .title {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              font-weight: 700;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 2px;
            }
            .text {
              font-size: 14px;
              color: grey;
              margin-bottom: 0px;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .form-check-input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
          }
        }
      }
    }
  }
}
.send-SMS-modal .repeated-number .remove-minus-btn:hover {
  background-color: #e8e8e8 !important;
}
.mass-sms-blast-page {
  .mass-sms-blast-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .btn-group {
      .view-btn {
        &.active {
          background-color: var(--primaryColor);
          svg {
            color: #000000;
          }
        }
      }
    }
  }
  .mass-email-row {
    .no-record-found-table {
      padding-top: 30px;
      padding-bottom: 30px;
      .img-wrapper {
        img {
          width: 250px;
          max-width: 100%;
        }
      }
    }

  }
  .mass-sms-col,
  .schedule-mass-sms-col {
    .card-body {
      padding: 20px 20px 0px 20px !important;
    }
    .inner-scroll-area {
      height: calc(100dvh - 272px);
      overflow-y: auto;
      &.load-more-active {
        height: calc(100dvh - 339px);
      }
    }
    .loadMore-btn-wrapper {
      position: relative;
      padding: 15px 10px 15px 10px;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 44px);
        height: 1px;
        background-color: #eae8e1;
      }
    }
    .rdt_Table_wrapper {
      margin-bottom: 20px;
      .search-box-wrapper {
        margin-top: 0px;
      }
    }
  }
  .mass-sms-card-col {
    margin-bottom: 20px;
    .company-form-card {
      border: 1px solid #eaeaea;
      border-radius: 10px;
      background-color: white;
      margin: 0px 0px 0px 0px;
      height: 100%;
      position: relative;
      .header-wrapper {
        padding: 10px 15px;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        .form-card-title {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          width: 100%;
          padding-right: 10px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          flex-shrink: 0;
          .action-btn {
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            margin-right: 5px;
            border-radius: 50%;
            transition: 0.5s;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            &:hover {
              background-color: #e3e3e3;
            }
            svg {
              color: var(--primaryColorDark);
            }
          }
        }
      }
      .body-wrapper {
        padding: 16px 15px 70px 15px;
        display: block;
        .nummber-of-contact {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .title {
            font-size: 16px;
            font-weight: 400;
            margin-right: 7px;
            margin-bottom: 3px;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            position: relative;
            margin-bottom: 3px;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: 0px;
          padding: 0px 15px 15px 15px;
          .btn {
            svg {
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
  .scheduled-mass-sms-card-col {
    margin-bottom: 20px;
    .company-form-card {
      border: 1px solid #eaeaea;
      border-radius: 10px;
      background-color: white;
      margin: 0px 0px 0px 0px;
      height: 100%;
      position: relative;
      .header-wrapper {
        padding: 10px 15px;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-card-title {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          width: calc(100% - 68px);
          padding-right: 10px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          .action-btn {
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            margin-right: 5px;
            border-radius: 50%;
            transition: 0.5s;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            &:hover {
              background-color: #e3e3e3;
            }
            svg {
              color: var(--primaryColorDark);
            }
          }
        }
      }
      .body-wrapper {
        padding: 16px 15px 16px 15px;
        display: block;
        .badge {
          border-radius: 8px !important;
          margin-bottom: 10px;
          min-width: 80px;
          text-align: center;
          position: relative;
          left: -15px;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
        .items-row {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0px;
          }
          .label {
            font-size: 16px;
            font-weight: 400;
            margin-right: 9px;
            margin-bottom: 3px;
          }
          .value {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            position: relative;
            margin-bottom: 3px;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: 0px;
          padding: 0px 15px 20px 15px;
        }
      }
    }
  }
  .schedule-mass-sms-col{
    .mass-sms-card-col{
      .items-row-wrapper{
        padding-top:16px;
        .items-row{
          display:flex;
          flex-wrap:wrap;
          margin-bottom:6px;
          .label,
          .value{
            font-size:15px;
            display:inline-block;
            margin-bottom:3px;
          }
          .label{
            margin-right:8px;
          }
          .value{
            color:#000000;
          }
        }
      }
      .company-form-card{
        .body-wrapper{
          padding:16px 15px 15px 15px;
        }
      }
    }
  }
}

.delete-folder-modal {
  .rdt_Table_wrapper {
    .search-box-wrapper {
      margin-left: auto;
    }
  }
  .card-body {
    width: 100%;
    margin-top: -68px;
  }
  .delete-folder-card {
    display: flex;
    flex-wrap: wrap;
    .card-header {
      height: auto;
      padding: 0px;
      display: flex;
      background-color: transparent;
      position: relative;
      border: none;
      padding: 10px 15px;
      width: 100%;
      order: -1;
      margin-bottom: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        border-radius: 10px;
      }
      .card-title {
        margin-bottom: 0px;
        position: relative;
        z-index: 2;
        font-size: 16px !important;
        font-weight: 500 !important;
        color: var(--primaryColorDark) !important;
      }
    }
    .card-body {
      padding: 0px;
    }
  }
  .select-new-folder-wrapper {
    position: relative;
    z-index: 4;
    width: 300px;
    top: -6px;
  }
  .table__page__limit {
    .error {
      .table__page__limit__control {
        border: 1px solid red !important;
      }
    }
  }
}
// Company Detail page end

// Users List page start
.rdt_Table_Card {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .button-wrapper {
    display: inline-flex;
    &:empty {
      display: none;
    }
    .dropdown-toggle {
      padding: 10px 27px 11px 14px;
      position: relative;
      &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        left: unset;
      }
      &:hover {
        background-color: #ffffff;
        box-shadow: 0 8px 25px -8px #82868b;
      }
    }
    .add-btn {
      padding: 10px 14px 11px 14px;
    }
    .btn-group {
      margin-right: 10px;
    }
  }
  .search-box-wrapper {
    margin-bottom: 13px;
    margin-top: 20px;
    width: 300px;
    max-width: 100%;
    input {
      margin: 0px !important;
      // padding: 10px 14px 12px 40px;
      padding: 7px 14px 9px 40px;
    }
  }
}
// Users List page end

// add user page css start
.switch-checkbox {
  display: inline-flex;
  position: relative;
  input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
  .switch-design {
    width: 54px;
    height: 28px;
    display: inline-block;
    background-color: #fff;
    border-radius: 60px;
    // box-shadow:inset 0 0 4px #cecece;
    border: 1px solid #e4e4e4;
    transition: 0.5s;
    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #eaeaea;
      transition: 0.5s;
    }
  }
  input:checked {
    background-color: transparent !important;
    border: none !important;
    background-image: unset !important;
  }
  input:checked + .switch-design {
    background-color: var(--primaryColor);
    box-shadow: unset;
    border: 1px solid transparent;
    &:before {
      background-color: #ffffff;
      left: 29px;
    }
  }
  input.form-check-input[disabled],
  input[disabled] {
    border: none !important;
    opacity: 0;
  }
  input.form-check-input[disabled] + .switch-design,
  input[disabled] + .switch-design {
    opacity: 0.5;
  }
}
.permission-wrapper {
  padding-top: 10px;
  // padding-bottom: 10px;
  & > .header-wrapper {
    margin-bottom: 14px;
    .heading {
      font-size: 20px;
      font-weight: 600;
      color: var(--primaryColorDark);
      margin-bottom: 3px;
    }
    .text {
      font-size: 14px;
    }
  }
  .accordian-loyal-box {
    background-color: #f7f7f7;
    border-radius: 14px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    .accordian-loyal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      .inner-wrapper {
        padding: 14px 112px 14px 15px;
        width: 100%;
      }
      .title {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
      .down-arrow {
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 12px;
        right: 12px;
        transition: 0.5s;
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          transform: rotate(-45deg);
          position: absolute;
          left: 7px;
          top: 5px;
        }
      }
      .switch-checkbox {
        position: absolute;
        top: 11px;
        right: 45px;
        z-index: 2;
      }
    }
    .accordian-loyal-body {
      padding: 15px 15px 0px 15px;
      display: none;
      .permission-row-wrapper {
        margin: 0px -15px;
        display: flex;
        flex-wrap: wrap;
        .permission-row {
          padding: 0px 15px;
          width: 50%;
          margin-bottom: 15px;
          .inner-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
          }
          .permission-title {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            max-width: calc(100% - 60px);
            padding-right: 10px;
            margin-top: 2px;
          }
          &.inventory-row{
            width:100%;
            margin-top:10px;
            .heading{
              font-size:17px;
              font-weight:600;
              color:#000000;
              width:100%;
              margin-bottom:14px;
            }
            .radio-btn-wrapper{
              flex-wrap:wrap;
              .radio-btn-repeater{
                margin-right: 20px !important;
                padding-left: 28px !important;
                margin-bottom: 10px !important;
                position: relative;
                .form-check-input{
                  position: absolute;
                  top: 0px;
                  width: 100%;
                  height: 100%;
                  z-index: 4;
                  opacity: 0;
                  margin: 0px !important;
                  cursor: pointer;
                  left: 0px;
                }
                .form-check-label{
                  font-size: 16px;
                  margin-bottom: 0px !important;
                  &:before{
                    content: "";
                    position: absolute;
                    top: 1px;
                    width: 20px;
                    height: 20px;
                    transition: 0.5s;
                    border-radius: 50%;
                    background-color: #ffffff;
                    border: 1px solid #ddd;
                    left: 0px;
                  }
                  &:after{
                    content: "";
                    position: absolute;
                    top: 6px;
                    width: 10px;
                    height: 10px;
                    opacity: 0;
                    transition: 0.5s;
                    border-radius: 50%;
                    background-color: #ffffff;
                    left: 5px;
                  }
                }
                .form-check-input:checked + .form-check-label{
                  &:before{
                    background-color: var(--primaryColor);
                    border: 1px solid var(--primaryColor);
                  }
                  &:after{
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.accordian-loyal-box.active {
  .accordian-loyal-header {
    border-bottom: 1px solid #e8e8e8;
    .down-arrow {
      transform: rotate(-180deg);
    }
  }
  .accordian-loyal-body {
    display: block !important;
  }
}

// user-details-page-new
.user-details-page-new {
  .contact-activity-tabPane {
    padding: 13px 13px;
    height: calc(100dvh - 200px);
    overflow-y: auto;
  }

  .contact-notes-tabPane {
    .notes-list-scroll-area {
      max-height: calc(100dvh - 270px);
      overflow-y: auto;
    }
    .inner-contant-wrapper {
      padding: 10px 12px 0px 12px;
    }
    .rdw-editor-wrapper {
      background-color: #ffffff;
    }
    .rdw-editor-main {
      border: none !important;
    }
    .available-notes-header {
      .print-action-wrapper {
        .add-note-btn {
          margin-left: 13px;
        }
      }
    }
    .contact-notes {
      .accordion-item {
        background-color: #ffffff;
        margin-bottom: 14px !important;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
          rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
        .accordion-header {
          .accordion-button {
            padding: 10px 15px 5px 15px !important;
            .inner-wrapper {
              .heading-details {
                padding-left: 0px;
                .title {
                  margin-bottom: 5px;
                }
                .author-time {
                  .author-name,
                  .time {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .action-btn-wrapper {
              .action-btn {
                &:hover {
                  background-color: #f4f4f4;
                }
              }
            }
          }
        }
      }
      .contant-wrapper {
        .footer-details {
          .author-name {
            background-color: #f4f4f4;
          }
          .action-btn-wrapper {
            .action-btn {
              background-color: #f4f4f4;
            }
          }
        }
      }
    }

    // need-to-comment
    .add-note-row,
    .submit-btn-wrapper {
      display: none !important;
    }
    .available-notes-wrapper {
      padding-top: 0px;
      margin-top: 0px !important;
      &:before {
        display: none;
      }
      .form-control {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: unset !important;
        background-image: url(../images/icons/search-gray-icon.svg);
        background-size: 18px;
        background-position: 11px 10px;
        padding: 4px 20px 5px 38px;
        background-repeat: no-repeat;
        height: 41.2px;
        font-size: 16px;
        color: #6e6b7b;
        &:focus {
          border: 1px solid var(--primaryColor);
          box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        }
      }
      .form-control::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
    }
    // need-to-comment-end
  }

  .contact__checklist__wrapper {
    position: relative;
    padding: 5px 13px 0px 13px;
    .list-grid-btn-wrapper {
      display: inline-flex;
      position: absolute;
      top: 6px;
      z-index: 4;
      right: 14px;
      .view-btn {
        &.active {
          background-color: var(--primaryColor) !important;
          color: #000000 !important;
        }
      }
    }
    .card-header {
      padding: 0px 0px 0px 0px;
      padding-right: 94px;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: transparent;
      border-bottom: none;
      .card-title {
        font-size: 22px !important;
        font-weight: 600 !important;
        margin-bottom: 0;
      }
      .create-folder-link {
        top: 0px;
        padding: 10px 20px 10px 20px;
      }
    }
    .company-checklist-inner-scroll {
      max-height: calc(100dvh - 265px);
    }
    .compnay-checklist-folder-wrapper {
      & > .card-body {
        padding: 20px 0px 0px 0px !important;
      }
    }
    .accordion-item.checklist-box {
      background-color: #ffffff;
      margin-bottom: 14px !important;
      border-radius: 10px !important;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
        rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
      .checklist-header {
        .title {
          padding-right: 0px;
          width: calc(100% - 220px);
          .checklist-name {
            max-width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .badge {
            padding: 6px 12px;
            margin: 0px 0px 0px 12px !important;
            flex-shrink: 0;
          }
        }
        .action-btn-wrapper {
          padding-left: 15px;
          flex-shrink: 0;
        }
        .action-btn {
          &:hover {
            background-color: #f4f4f4;
          }
        }
      }
      .company-form-card.checklist-company-form-card {
        background-color: #f9f9f9;
        .body-wrapper {
          .checklist-listing-items {
            .item {
              .inner-wrapper {
                &:before {
                  background: linear-gradient(
                    118deg,
                    #b7b7b7,
                    rgba(0, 0, 0, 0)
                  );
                  opacity: 0.3;
                }
              }
              &.active {
                .inner-wrapper {
                  &:before {
                    background: linear-gradient(
                      118deg,
                      var(--primaryColor),
                      rgba(0, 0, 0, 0)
                    );
                    opacity: 0.3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact__files__wrapper {
    padding-top: 5px;
    .top-header {
      margin-bottom: 18px !important;
    }
    .company-document-page {
      .company-document-listing-row {
        .company-checklist-inner-scroll {
          height: calc(100dvh - 346px);
          max-height: calc(100dvh - 346px);
        }
      }
      .company-document-listing-row {
        &:last-child {
          .card-body {
            height: calc(100dvh - 318px);
            max-height: calc(100dvh - 318px);
          }
        }
      }
    }
  }

  .company-detail-card{
    .company-detail-row{
      .company-info{
        .inner-scroll-wrapper{
          height:calc(100dvh - 355px);
        }
      }
      .add-notes-sec{
        .inner-scroll-wrapper{
          height:calc(100dvh - 286px);
        }
        .fixed-add-note{
          .button-wrapper{
            padding:15px 0px;
          }
        }
      }
    }
  }
  .contact-activity-tabPane{
    padding:0px 0px !important;
  }
}
// user-details-page-new-end

.role-chekcbox-wrapper {
  & > .form-label {
    display: none;
  }
  .form-check {
    padding: 0px;
    margin-right: 0px !important;
    position: relative;
    &:nth-child(1) {
      margin-right: 18px !important;
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
      margin: 0px !important;
    }
    .form-check-label {
      position: relative;
      padding-right: 65px;
      font-size: 18px;
      font-weight: 600;
      color: #000;
      &:before {
        content: '';
        width: 54px;
        height: 28px;
        display: inline-block;
        background-color: #fff;
        border-radius: 60px;
        box-shadow: inset 0 0 4px #cecece;
        transition: 0.5s;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 4px;
        right: 29px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eaeaea;
        transition: 0.5s;
      }
    }
    input:checked + .form-check-label {
      &:before {
        background-color: var(--primaryColor);
      }
      &:after {
        right: 6px;
        background-color: #ffffff;
      }
    }
  }
}
// add user page css end

// company modual css start
// company-form-page css start
.company-form-page {
  // padding-bottom: 20px;
  .top-header {
    margin-bottom: 15px !important;
  }
  .view-toggle-btn {
    .view-btn {
      &.active {
        background-color: var(--primaryColor);
        color: #000000;
      }
    }
  }
  .ui-loader {
    height: 100%;
  }
  .company-form-inner-card {
    height: 100%;
    .no-data-wrapper {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .card-header{
      .searchInput{
        width:175px;
      }
    }
    .card-body {
      max-height: calc(100dvh - 325px);
      overflow-y: auto;
    }
  }
  .list-view-card-wrapper {
    height: 100%;
    .no-record-found-table {
      .img-wrapper {
        img {
          width: 260px;
        }
      }
    }
    .rdt_Table_wrapper {
      height: 100%;
      .ui-loader {
        height: calc(100% - 75px);
      }
      .react-dataTable {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        & > div {
          width: 100%;
        }
      }
    }
  }
  .list-view-card-wrapper {
    .rdt_Table_Card {
      height: 100%;
      .button-wrapper {
        .btn-group {
          margin-right: 0px;
        }
      }
    }
  }

  .company-form-card-row {
    margin: 0px -8px;
    margin-top: 20px;
    margin-bottom: -10px;
    .company-form-card-col {
      margin-bottom: 15px;
      padding: 0px 8px;
    }
  }
  .company-form-card {
    height: 100%;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        // width: calc(100% - 172px);
        width: 100%;
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: var(--primaryColorDark);
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .preview-btn {
        width: calc(50% - 6px);
      }
      .view-submissions-link {
        margin-left: 0px;
        width: calc(50% - 6px);
        display: block;
        height: 38px;
        text-align: center;
        border: 1px solid #dbdbdb;
        line-height: 32px;
        border-radius: 5px;
        color: #000000;
        transition: 0.5s;
        &:hover {
          box-shadow: 0 8px 25px -8px #82868b;
        }
      }
    }
  }

  // .delete-form-col {
  //   .company-form-card {
  //     background-color: #f9f9f9;
  //     .header-wrapper {
  //       .form-card-title {
  //         width: calc(100% - 67px);
  //       }
  //     }
  //     .view-submissions-link {
  //       background-color: #ffffff;
  //     }
  //   }
  // }
  .marketing-form-col {
    .company-form-inner-card {
      .card-body {
        max-height: unset;
        height: calc(100dvh - 251px);
      }
    }
    &.load-more-active {
      .company-form-inner-card {
        .card-body {
          max-height: unset;
          height: calc(100dvh - 319px);
        }
      }
    }
    .no-record-found-table {
      padding-top: 35px;
      padding-bottom: 30px;
      .img-wrapper {
        img {
          width: 300px;
        }
      }
    }
  }
  .loadMore-btn-wrapper {
    position: relative;
    padding: 15px 10px 15px 10px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 1px;
      background-color: #eae8e1;
    }
  }

  .form-action-toggle-dropdown{
    .form-action-toggle-btn{
      display:inline-block;
      width:30px;
      height:30px;
      border-radius:50%;
      padding:5px;
      svg{
        width:100%;
        height:100%;
        color:#747474;
      }
      &:hover{
        background-color:#e4e4e4;
        svg{
          color:#000000;
        }
      }
    }
    .dropdown-menu{
      max-height:unset;
      .dropdown-item{
        svg{
          width: 18px;
          height: 18px;
          margin-right:10px;
        }
      }
    }
  }
  .form-list-table-col{
    .rdt_Table_wrapper{
      .rdt_TableHeadRow{
        .rdt_TableCol{
          min-width:unset;
          max-width:unset;
          flex-grow: unset;
          flex-shrink: unset;
          flex-basis: unset;
          &:nth-child(1){
            width:calc(100% - 255px);
          }
          &:nth-child(2){
            width:165px;
          }
          &:last-child{
            width:90px;
          }
        }
      }
      .rdt_TableBody{
        min-height: 450px;
        .rdt_TableRow{
          .rdt_TableCell{
            min-width:unset;
            max-width:unset;
            flex-grow: unset;
            flex-shrink: unset;
            flex-basis: unset;
            &:nth-child(1){
              width:calc(100% - 255px);
            }
            &:nth-child(2){
              width:165px;
            }
            &:last-child{
              width:90px;
            }
          }
        }
      }
    }
  }
}

.menu-expanded {
  .company-form-page {
    .company-form-card-row {
      .company-form-card-col {
        width: 100%;
      }
    }
  }
}

.form-preview-page {
  .card {
    margin-bottom: 5px !important;
  }
  .add-company-form-wrapper {
    .inner-scroll-wrapper {
      height: calc(100dvh - 305px);
    }
  }
}
// company-form-page css end

// report problem page start
.support-ticket-page-header-row {
  padding: 0px 2px;
}

.report-problem-row {
  padding-top: 20px;

  .report-problem-col {
    margin-bottom: 20px;
  }

  .company-form-card {
    // height:100%;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: white;
    margin: 0px 0px 0px 0px;
    height: 100%;

    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;

      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 32px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }

      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;

        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            background-color: #e3e3e3;
          }

          svg {
            color: var(--primaryColor);
          }
        }
      }
    }

    .body-wrapper {
      padding: 16px 15px;
      display: block;
      position: relative;
      .new-badge {
        position: absolute;
        top: 17px;
        background-color: var(--primaryColor);
        color: #000;
        padding: 5px 16px;
        font-weight: 600;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        right: 0px;
      }

      .support-ticket-row {
        display: flex;
        // flex-wrap: wrap;
        margin-bottom: 7px;

        .date-label {
          font-size: 16px;
          font-weight: 500;
          color: grey;
          position: relative;
          width: 90px;
          margin-right: 12px;
          flex-shrink: 0;
          margin-bottom: 4px;

          &:before {
            content: ':';
            position: absolute;
            top: 0px;
            right: 0px;
          }
        }

        .data-value {
          font-size: 16px;
          font-weight: 500;
          color: black;
          max-width: 100%;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 4px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
        // &.message{
        //   flex-wrap:wrap;
        //   .data-value{
        //     white-space:normal;
        //   }
        // }
      }
    }
    .action-btn {
      &.message-btn {
        position: relative;
        .count {
          position: absolute;
          font-size: 0px;
          padding: 0px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: var(--primaryColor);
          border: 2px solid #fff;
          right: 0px;
          top: 1px;
        }
      }
    }
  }
}

.support__tickets__card {
  & > .card-body {
    overflow-y: auto;
    height: calc(100dvh - 266px);

    &.load-more-active {
      height: calc(100dvh - 337px);
    }
  }

  .load-more-btn-wrapper {
    margin-bottom: 0px !important;
    border-top: 1px solid #eae8e1;
    padding: 16px 0px;
  }

  .badge-selectBox {
    margin-bottom: 12px;
    cursor: pointer;

    .badgeLoyal-wrapper {
      display: inline-flex;
      align-items: center;
      padding: 4px 30px 6px 31px;
      border-radius: 8px;
      position: relative;
      width: 100%;

      .dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        z-index: 2;
      }

      .name {
        display: inline-block;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        z-index: 2;
      }

      .bg-wrapper {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        border-radius: 8px;
      }

      .border-wrapper {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        border-radius: 8px;
      }

      .down-arrow-btn {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        cursor: pointer;
      }
    }

    .custom-select__control {
      padding-left: 0px;
      min-height: unset !important;
      border: none !important;
      display: inline-flex;
      width: auto !important;
      max-width: 100%;
      flex-wrap: nowrap;

      .custom-select__value-container {
        // margin-right:6px;
        margin-right: 0px;
        // display:flex;
        flex: unset;
      }

      .react-select {
        width: 100%;
      }

      .custom-select__indicators {
        margin-left: 6px;
      }
    }

    .custom-select__menu {
      z-index: 4;
      width: 200px;

      .custom-select__option {
        &.custom-select__option--is-selected {
          background-color: transparent !important;
        }
      }
    }

    .custom-select__menu {
      .custom-select__option.custom-select__option--is-selected {
        color: grey !important;

        &:hover {
          color: var(--primaryColorDark) !important;
          background-color: #f2f2f2 !important;
        }
      }
    }
  }

  .status-badge {
    margin-bottom: 12px;
  }
}

.support-ticket-page {
  .support-ticket-page-header-row {
    margin-bottom: 15px;

    .btn-group {
      .view-btn.active {
        background-color: var(--primaryColor) !important;
        color: #000000 !important;
      }
    }
  }

  .support__tickets__card {
    .search-box-wrapper {
      width: 300px;
      max-width: 100%;

      svg {
        path {
          fill: rgb(172, 172, 172);
        }
      }

      input {
        margin: 0px !important;
        // padding: 10px 14px 12px 40px;
        padding: 7px 14px 9px 40px;
        border-radius: 7px;
      }
    }
  }
}

.feature-request-preview-modal,
.support-ticket-preview-modal{
  &.modal-dialog{
    width:550px;
    max-width:100%;
  }
  .modal-body{
    padding:20px 0px;
  }
  .scroll-wrapper{
    padding:0px 20px;
    max-height: calc(100dvh - 150px);
    overflow-y: auto;
  }
  .modal-header{
    padding:0px 0px;
    width:34px;
    height:34px;
    background-color:transparent;
    position:absolute;
    top:8px;
    right:8px;
    z-index:3;
    .modal-title{
      display:none;
    }
    .btn-close{
      position:static;
      transform:unset !important;
    }
  }
  .badgeLoyal-wrapper{
    display:inline-flex;
    align-items:center;
    padding:4px 14px 6px 31px;
    border-radius:8px;
    position: relative;
    max-width:100%;
    .dot{
      width:14px;
      height:14px;
      border-radius:50%;
      display:inline-block;
      margin-right:6px;
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      left:10px;
      z-index:2;
    }
    .name{
      display:inline-block;
      font-size:15px;
      font-weight:500;
      position: relative;
      z-index:2;
      max-width:100%;
      text-overflow:ellipsis;
      overflow:hidden;
      white-space:pre;
    }
    .bg-wrapper{
      content:"";
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      opacity: 0.10;
      border-radius:8px;
    }
    .border-wrapper{
      content:"";
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      opacity: 0.10;
      border-radius:8px;
    }
    .down-arrow-btn{
      width:20px;
      height:20px;
      flex-shrink:0;
      display:flex;
      align-items:center;
      justify-content:center;
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      right:6px;
      cursor: pointer;
    }
  }
  .user-name{
    display:flex;
    align-items:center;
    padding-left:20px;
    padding-right:32px;
    margin-bottom:20px;
    .icon-wrapper{
      width:26px;
      height:26px;
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      justify-content:center;
      margin-right:10px;
      flex-shrink:0;
      svg{
        width:100%;
        height:100%;
        color:#000000;
        stroke-width: 3px;
      }
    }
    .data-value{
      font-weight:600;
      font-size:20px;
      color:#000000;
      white-space:pre;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
  .data-row-new{
    width:100%;
    padding:0px 10px;
    margin-bottom:25px;
    .label-wrapper{
      width:100%;
      display:flex;
      align-items:center;
      margin-bottom:10px;
      .icon-wrapper{
        width:23px;
        height:23px;
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        justify-content:center;
        margin-right:8px;
        flex-shrink:0;
        svg{
          width:100%;
          height:100%;
        }
      }
      .label-text{
        font-weight:500;
        font-size:17px;
        display:inline-block;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
        max-width:100%;
      }
    }
    .value-wrapper{
      font-weight:500;
      font-size:17px;
      color:#000000;
      word-break:break-all;
      .email-value,
      .phone-value{
        max-width:100%;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
        display:inline-block;
      }
    }
    &.message{
      width:100%;
      padding:0px 0px;
      margin-top:-6px;
      .value-wrapper{
        font-size:16px;
        color:var(--bs-body-color);
        font-weight:400;
      }
    }
    &.attachment{
      width:100%;
      padding:0px 0px;
      margin-top:-6px;
      margin-bottom:0px;
      .file__drop__zone_wp{
        display:flex;
        flex-wrap:wrap;
        margin:0px -7px;
        .file-col{
          padding:0px 7px;
          width:33.3333%;
          margin-bottom:12px;
        }
        .file__card{
          width:100%;
          .file__preview__wp{
            width: 100%;
            padding-top:65%;
            .file__preview{
              position:absolute;
              top:0px;
              left:0px;
              width:100%;
              height:100%;
              img{
                width:100%;
                height:100%;
                object-fit:cover;
                object-position:center;
              }
            }
          }
          .overlay{
            top:0px;
            left:0px;
            .icon-wrapper{
              width:32px;
              height:33px;
              border-radius:50%;
              transition:0.5s;
              padding:6px;
              display:flex;
              align-items:center;
              justify-content:center;
              svg{
                width:100%;
                height:100%;
              }
              &:hover{
                background-color:rgba(255,255,255,0.2);
              }
            }
          }
        }
      }
    }
  }
  .double-row-data{
    display:flex;
    flex-wrap:wrap;
    margin:0px -10px;
    .data-row-new{
      width:50%;
      padding:0px 10px;
    }
  }
}

.add-support-ticket-modal {
  &.modal-dialog {
    width: 600px;
    max-width: calc(100% - 30px);
  }
}

.support-ticket-comment-modal {
  &.modal-dialog {
    width: 600px;
    max-width: calc(100% - 30px);
  }

  .modal-body {
    max-height: calc(100dvh - 212px);
    overflow-y: auto;
    padding-bottom: 10px;
  }

  .comment-box {
    margin-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 16px;
    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: none;
    }
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      .img-wrapper {
        width: 32px;
        height: 32px;
        .avatar {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          .avatar-content {
            width: 100%;
            height: 100%;
            background-color: var(--primaryColor);
            font-size: 12px;
            text-transform: uppercase;
            color: #000000;
          }
        }
      }
      .right-wrapper {
        width: calc(100% - 33px);
        padding-left: 10px;
        .top-header {
          display: flex;
          flex-wrap: wrap;
          .user-name {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
          }
          .time {
            font-size: 14px;
            font-weight: 400;
            position: relative;
            padding-left: 13px;
            margin-left: 13px;
            color: #717171;
            &:before {
              content: '';
              position: absolute;
              top: 7px;
              left: -1px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #a6a6a6;
            }
          }
        }
        .description {
          font-size: 14px;
          line-height: 22px;
          color: grey;
          margin-top: 4px;
        }
      }
    }
  }

  .comment-send-wrapper {
    padding: 14px;

    .inner-wrapper {
      position: relative;
    }

    .form-control {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
        rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
      font-size: 16px;
      height: auto;
      border-radius: 100px;
      padding: 11px 52px 11px 20px;
    }

    .btn {
      width: 36px;
      height: 36px;
      padding: 7px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .invalid-feedback {
      display: none;
    }
  }
}

// report problem page end

// feature requests page start
.feature-requests-row {
  padding-top: 20px;
  .feature-requests-col {
    margin-bottom: 20px;
  }
  .company-form-card {
    // height:100%;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: white;
    margin: 0px 0px 0px 0px;
    .header-wrapper {
      padding: 10px 15px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      .form-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        width: calc(100% - 32px);
        padding-right: 10px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .action-btn-wrapper {
        display: inline-flex;
        align-items: center;
        .action-btn {
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          margin-right: 5px;
          border-radius: 50%;
          transition: 0.5s;
          cursor: pointer;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            color: var(--primaryColor);
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px;
      display: block;
      .item {
        .date-label {
          width: 70px;
          margin-bottom: 13px;
        }
        .value {
          width: calc(100% - 70px);
          margin-left: 0px;
        }
      }
    }
  }
}
// feature requests page end

.form-submissions-page {
  .rdt_Table_Card {
    .button-wrapper {
      .btn-group {
        margin-right: 0px;
      }
    }
  }
}

.company-form-preview-modal {
  &.modal-dialog {
    width: 700px;
    max-width: 100%;
  }
  .company-form-preview-wrapper {
    .top-user-profile {
      display: flex;
      align-items: center;
      padding: 15px 15px;
      // background-color: whitesmoke;
      border-radius: 12px;
      margin-bottom: 15px;
      .profile-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
      .right-details {
        width: 100%;
      }
      .title {
        font-size: 20px;
        color: #000000;
        font-weight: 700;
        margin-bottom: 4px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
      }
    }
    .btn-primary[type='submit'] {
      height: 40px;
      border-radius: 0.358rem;
    }
    .rdw-editor-wrapper {
      border: none;
      border-radius: 0px;
      font-size: 16px;
    }
    .custom-fileupload {
      background-color: #ffffff;
      .outsite-CN {
        text-align: center;
      }
    }
  }
  .modal-footer {
    .btn-primary {
      height: 40px;
      border-radius: 0.358rem;
      margin: 0px 0px;
      min-width: 148px;
    }
  }

  .e-richtexteditor{
    border:none !important;
    .e-rte-content{
      .e-content{
        min-height:unset !important;
        height:auto;
        padding:0px 0px !important;
      }
    }
  }
}

.form-preview-page {
  .card-header {
    padding-left: 60px;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 14px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  .ui-loader {
    & > .card {
      & > .card-body {
        overflow: hidden;
        position: relative;
        // min-height: 700px;
        min-height: calc(100dvh - 210px);
      }
    }
  }
  .card {
    margin-bottom: 15px;
  }
  .right {
    display: flex;
    align-items: center;
    .label {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      margin-right: 10px;
    }
  }
}
.form-design-field-row {
  align-items: flex-end;
  .form-design-field-col {
    width: 33%;
    margin-bottom: 16px;
  }
  .color-picker-with-code {
    display: flex;
    align-items: center;
    input {
      // border-top-right-radius: 0px;
      // border-bottom-right-radius: 0px;
      width: 65px;
      flex-shrink: 0;
      padding: 4px 4px;
      overflow: hidden;
      border-right: none;
      border-radius: 3px;
    }
    // .code-text {
    //   height: 41.2px;
    //   width: 100%;
    //   padding: 8px 14px 8px 14px;
    //   border-top-right-radius: 7px;
    //   border-bottom-right-radius: 7px;
    //   border: 1px solid #e8e8e8;
    //   background-color: #ffffff;
    //   font-size: 14px;
    // }
  }
  .range-slider-wrapper {
    display: flex;
    .form-range {
      width: 100%;
    }
    .value {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      flex-shrink: 0;
      padding-left: 20px;
      position: relative;
      top: -1px;
    }
  }
}

.add-company-form-wrapper {
  .inner-scroll-wrapper {
    height: calc(100dvh - 350px);
    overflow-y: auto;
  }
  .accordian-loyal-box {
    background-color: #ffffff;
    border-radius: 14px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    .accordian-loyal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      .inner-wrapper {
        padding: 14px 112px 14px 15px;
        width: 100%;
      }
      .title {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
      .down-arrow {
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 12px;
        right: 12px;
        transition: 0.5s;
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          transform: rotate(-45deg);
          position: absolute;
          left: 7px;
          top: 5px;
        }
      }
      .switch-checkbox {
        position: absolute;
        top: 11px;
        right: 45px;
        z-index: 2;
      }
    }
    .accordian-loyal-body {
      padding: 15px 15px 0px 15px;
      display: none;
      .permission-row-wrapper {
        margin: 0px -15px;
        display: flex;
        flex-wrap: wrap;
        .permission-row {
          padding: 0px 15px;
          width: 50%;
          margin-bottom: 15px;
          .inner-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
          }
          .permission-title {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .permission-row-wrapper {
    margin: 0px -15px;
    display: flex;
    flex-wrap: wrap;
    .permission-row {
      padding: 0px 15px;
      width: 50%;
      margin-bottom: 15px;
      .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start !important;
        justify-content: space-between;
        background-color: #f7f7f7;
        border-radius: 10px;
        padding: 12px 14px;
      }
      .permission-title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        max-width: calc(100% - 60px);
        padding-right: 10px;
      }
    }
  }
  .permission-row-wrapper.from-details {
    .permission-row {
      width: 25% !important;
    }
  }
  .preview-form-fields {
    .field-repeater-row-wrapper {
      width: 1200px;
      max-width: 100%;
      & > div {
        & > div {
          margin-bottom: 18px;
        }
      }
    }
    .field-repeater-row {
      display: flex;
      align-items: flex-start;
      position: relative;
      padding-right: 80px;
      .title-wrapper {
        width: 400px;
        margin-top: 8px;
        .form-label {
          display: flex;
          width: 100%;
          margin-bottom: 0px;
          align-items: center;
          .move-btn {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            flex-shrink: 0;
          }
          .text {
            width: auto;
            max-width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }
          .required-field-sign {
            margin-left: 4px;
            display: inline-block;
            flex-shrink: 0;
          }
        }
      }
      .form-field-wrapper {
        width: calc(100% - 400px);
        padding-left: 15px;
      }
      .action-btn-wrapper {
        position: absolute;
        top: 5px;
        right: 0px;
        display: inline-flex;
        align-items: center;
        .action-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          transition: 0.5s;
          svg {
            width: 18px;
            height: 18px;
            color: var(--primaryColorDark) !important;
          }
          &:hover {
            background-color: #e3e3e3;
          }
        }
        .action-btn.delete-btn {
          margin-left: 3px;
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .submit-btn-wrapper {
      margin-top: 25px;
    }
  }
  .form-design-fields {
    .accordian-loyal-body {
      margin-bottom: 5px !important;
    }
  }
  .after-form-submitted {
    .form-field-wrapper {
      width: 1200px;
      max-width: 100%;
    }
    .form-field-group {
      margin-top: 22px;
      .form-label {
        margin-bottom: 8px;
      }
    }
  }
  .form-assignments {
    .accordian-loyal-body {
      margin-bottom: 0px !important;
    }
  }
  .accordian-loyal-box.emails {
    .header-text {
      margin-bottom: 22px;
      p {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 25px;
      }
      .title {
        line-height: 23px;
        span {
          color: var(--primaryColor);
        }
      }
    }
    .ellipsis-form-label {
      width: 100%;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
    }
    .toggle-form-inner-wrapper {
      height: 100%;
    }
    .toggle-form-inner-wrapper.active {
      padding: 20px 20px;
      background-color: #fbfbfb;
      border-radius: 10px;
    }
    .checkbox-switch-toggle-btn-wrapper {
      margin-bottom: 15px;
    }
    .form-field-group {
      margin-bottom: 20px;
    }
    .btn-outline-primary {
      width: auto !important;
      padding: 12px 20px;
      margin-top: 10px !important;
    }
    .company-form-card {
      height: 100%;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      background-color: white;
      margin: 10px 0px 15px 0px;
      .header-wrapper {
        padding: 10px 15px;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        .form-card-title {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          width: calc(100% - 32px);
          padding-right: 10px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          .action-btn {
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            margin-right: 5px;
            border-radius: 50%;
            transition: 0.5s;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            &:hover {
              background-color: #e3e3e3;
            }
            svg {
              color: var(--primaryColor);
            }
          }
        }
      }
      .body-wrapper {
        padding: 16px 15px;
        display: block;
        .cn-wrapper {
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
          .text {
            font-size: 16px;
            color: grey;
            line-height: 24px;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .btn-primary {
            width: calc(50% - 6px) !important;
          }
        }
      }
    }
  }
}

.add-update-field-modal {
  max-width: 500px;
  .form-field-group {
    margin-bottom: 20px;
    .form-field-group {
      margin-top: 20px;
    }
  }
  .required-field-wrapper {
    display: flex;
    align-items: center;
    input {
      margin-right: 8px;
      margin-top: 0px;
    }
    .form-label {
      margin-bottom: 0px;
    }
  }
  .modal-footer {
    form {
      margin: 0px;
      .btn-primary {
        width: 148px !important;
      }
    }
  }
}
// company modual css end

// report-problem-modal
.report-problem-form {
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 15px;
    width: 500px;
    max-width: 100%;
    line-height: 24px;
  }
  .report-problem-form-info-text {
    .text {
      font-size: 16px;
      margin-bottom: 8px;
      font-weight: 500;
      color: var(--primaryColorDark);
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .progress {
    margin-top: 10px !important;
  }
  .attachment-save-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    .custom-fileupload {
      border: none;
      background-color: transparent;
      display: block;
      width: 100%;
      .ui-loader {
        .overlay {
          background-color: transparent !important;
        }
      }
      .inner-wrapper {
        padding: 0px 0px;
        .dropzone {
          display: inline-block;
          .outsite-CN {
            display: none !important;
          }
        }
      }
      .ln-btn {
        padding: 7px 14px;
        border-radius: 4px;
        position: absolute;
        bottom: -38px;
        display: flex;
        align-items: center;
        .spinner-border {
          margin-right: 8px;
          width: 21px;
          height: 21px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          color: #000000;
          position: relative;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
      .attachment-wrapper {
        margin-top: 6px;
        justify-content: flex-start;
        .file__card {
          max-width: 25%;
          width: 200px;
          .inner-wrapper {
            padding-top: 90%;
            .overlay {
              background-color: rgba(0, 0, 0, 0.6) !important;
              transition: 0.3s;
            }
          }
        }
      }
      .remove-all-btn {
        margin-top: 0px !important;
        position: static;
        display: inline-flex !important;
        margin-bottom: 20px;
      }
    }
    .btn-primary {
      margin-left: 10px;
      min-width: 140px;
    }
  }
  .note-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 12px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .profile-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      .avatar {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }
    .right-contant {
      width: calc(100% - 47px);
      padding-left: 10px;
      .note-header {
        display: flex;
        align-items: center;
        .name-time {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          width: 100%;
          padding-right: 12px;
          .name,
          .time {
            display: inline-block;
            margin-bottom: 4px;
          }
          .name {
            margin-right: 10px;
            color: #000000;
            font-weight: 600;
          }
          .time {
            font-weight: 500;
            position: relative;
            padding-left: 16px;
            &:before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #aeaeae;
            }
          }
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
          flex-shrink: 0;
          .action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #ffffff;
            padding: 7px;
            transition: 0.5s;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
            &:last-child {
              margin-left: 6px;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
        }
      }
      .attachment-wrapper {
        .file__card {
          width: 25%;
          margin-bottom: 7px;
          .inner-border-wrapper {
            background-color: #ffffff;
            border: 1px solid #ececec;
          }
          .inner-wrapper {
            padding-top: 0px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }
          .file__preview {
            position: static;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            img {
              border-radius: 6px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .file-name {
            font-size: 16px;
            font-weight: 400;
            color: grey;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
          .file__preview__details {
            display: flex;
            width: calc(100% - 69px);
            padding-right: 12px;
            align-items: center;
          }
          .overlay {
            position: static;
            opacity: 1;
            z-index: 2;
            background-color: transparent;
            display: inline-flex;
            flex-shrink: 0;
            width: auto;
            height: auto;
            .action-btn-wrapper {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .action-btn {
                width: 32px;
                height: 32px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #ffffff;
                padding: 7px;
                transition: 0.5s;
                cursor: pointer;
                margin-right: 0px;
                margin-left: 0px;
                svg {
                  width: 100%;
                  height: 100%;
                  color: #777 !important;
                }
                &:last-child {
                  margin-left: 4px;
                }
                &:hover {
                  background-color: #000000;
                  svg {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.report-problem-card {
  .card-body {
    padding: 20px 20px !important;
    .report-problem-form {
      & > .inner-wrapper {
        width: 900px;
        max-width: 100%;
      }
      .submit-btn-wrapper {
        margin-bottom: 10px;
        justify-content: flex-start !important;
        button {
          width: 180px;
        }
      }
    }
  }
}

.report-problem-modal {
  .report-problem-form {
    .attachment-save-btn-wrapper {
      .custom-fileupload {
        .attachment-wrapper {
          margin-top: -6px;
        }
        .remove-all-btn {
          margin-top: 0px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}
// report-problem-modal end

// fancy form
.fancy-form {
  // padding:50px 15px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  .inner-wrapper {
    padding: 20px 20px;
    width: 900px;
    max-width: 100%;
    // background-color:#ffffff;
    border-radius: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 20px;
    }
    .form-cn-wrapper {
      position: relative;
      z-index: 3;
    }
  }
  .profile-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .profile-img {
      width: 100px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .right {
      width: calc(100% - 100px);
      padding-left: 15px;
      .company-name {
        font-size: 26px;
        font-weight: 700;
        color: #000000;
      }
      .form-name {
        font-size: 20px;
        font-weight: 700;
        color: grey;
      }
    }
  }
  .top-user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    // background-color: whitesmoke;
    border-radius: 12px;
    margin-bottom: 15px;
    .profile-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: block;
      flex-shrink: 0;
      &:empty {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
    .right-details {
      width: 100%;
      padding-left: 15px;
      &:empty {
        display: none;
      }
    }
    .title {
      font-size: 22px;
      color: var(--primaryColor);
      font-weight: 700;
      margin-bottom: 0px;
    }
    .text {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0px;
      margin-top: 4px;
    }
    .right-details.logo__not {
      padding-left: 0px;
      .title,
      .text {
        text-align: center;
      }
    }
  }
  .description-text {
    margin-top: 17px;
    .rdw-editor-wrapper {
      border: none;
      font-size: 16px;
      .rdw-editor-main {
        border: none !important;
      }
    }
  }
  .submit-btn-wrapper {
    padding-bottom: 10px;
    .btn {
      // background-color:#000000 !important;
      // color:yellow !important;
      // border:1px solid #000000 !important;
      width: 170px !important;
      // &:hover{
      //   background-color: yellow !important;
      //   color:#000000 !important;
      // }
    }
  }
  .form-label {
    color: #000000;
  }
}
// fancy form end

// Users CSS Starts
.task-title label {
  width: 30%;
}

.task-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 700px;
}
.task-title label {
    width: 30%;
    margin-bottom: 0;
}

.task-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 700px;
}

.task-notification {display: flex;flex-wrap: wrap;width: 100%;justify-content: space-between; padding-left: 20px; margin: 15px 0 0; }

.auth-login-form { position: relative;}
.task-notification-wrap {
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; margin-top: 10px
}
.task-notification label.form-label {
    width: 30%;margin-bottom: 0;
}
.task-inner > label {
  display: inline-block;
  margin: 12px 0 0;
  padding-left: 20px;
}

.task-notification input#email {
  position: relative;
  left: -140px;
}

.task-notification input#platform {
  position: relative;
  left: -120px;
}
// Users CSS Ends

@media (max-width: 1600px) {
  .company-form-listing-row > .form-col,
  .company-form-listing-row > .delete-form-col {
    width: 100% !important;
  }
  .company-form-listing-row > .form-col {
    margin-bottom: 30px;
  }
  .company-form-page .company-form-inner-card .card-body {
    height: auto;
    max-height: unset;
  }
}

// pipeline page start
.pipeline-page-wrapper {
  .tabbing-header-wrapper {
    margin-bottom: 0px;
    &:before {
      width: 100%;
      background-color: var(--primaryColor);
      display:none;
    }
    .horizontal-tabbing {
      margin-top: 0px;
      .nav-item {
        .nav-link {
          min-width: 200px;
          font-size: 16px;
        }
      }
    }
  }
  .pipeline-card {
    box-shadow: unset;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid var(--primaryColor);
    border-top: none;
  }
  .pipeline-kanbanView-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 5px;
    .left-sidebar {
      width: 210px;
      // min-height:calc(100dvh - 212px);
      border-right: 1px solid #d0d0d0;
      .group-wrapper {
        height: 100%;
        .group-header {
          padding: 10px 12px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .plus-btn {
            width: 26px;
            height: 26px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            padding: 3px;
            transition: 0.5s;
            border-radius: 50%;
            background-color: #e3e3e3;
            cursor: pointer;
            display: none;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
              transition: 0.5s;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
          .group-heading {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            // width: calc(100% - 57px);
            width: 100%;
            // padding: 0px 10px 0px 10px;
            margin-bottom: 0px;
            position: relative;
            .close-btn {
              align-items: center;
              justify-content: center;
              width: 28px;
              height: 28px;
              border-radius: 50%;
              padding: 5px;
              transition: 0.5s;
              cursor: pointer;
              position: absolute;
              top: calc(50% + 2px);
              transform: translateY(-50%);
              right: 0px;
              display: none;
              svg {
                width: 100%;
                height: 100%;
              }
              &:hover {
                background-color: #e8e8e8;
              }
            }
          }
          .down-arrow-btn {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 2px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            transition: 0.5s;
            display: none;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
            }
            &:hover {
              background-color: #e3e3e3;
              cursor: pointer;
            }
          }
        }
        .add-group-wrapper {
          padding: 13px 12px 13px 12px;
          .btn {
            width: 100%;
            svg {
              margin-right: 7px;
              position: relative;
              top: -2px;
            }
          }
        }
        .group-list-body {
          height: calc(100dvh - 300px);
          overflow-y: auto;
        }
        .group-item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          // padding:7px 12px;
          position: relative;
          cursor: pointer;
          border-radius: 10px;
          // margin-bottom: 5px;
          .group-item-header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 7px 6px 7px 12px;
            width: 100%;
            border-radius: 10px;
          }
          .group-title {
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            width: calc(100% - 91px);
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0px;
            position: relative;
            z-index: 2;
          }
          .action-btn-wrapper {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 2;
            .action-btn {
              width: 30px;
              height: 30px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              transition: 0.5s;
              border-radius: 50%;
              cursor: pointer;
              padding: 7px;
              svg {
                width: 100%;
                height: 100%;
              }
              &:hover {
                background-color: #e3e3e3;
              }
              &.edit-btn {
                svg {
                  color: #000000;
                }
              }
              &.toggle-btn {
                padding: 3px;
                svg {
                  color: #000000;
                }
              }
            }
          }
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            width: 100%;
            height: 100%;
            // opacity: 0.4;
            left: 0px;
            display: none;
          }
          &:hover {
            .group-item-header {
              background-color: #ececec;
            }
            &:before {
              background-image: linear-gradient(
                to right,
                #d9d9d9,
                rgba(255, 255, 255, 0)
              );
            }
          }
          &.active {
            border-top: 1px solid var(--primaryColor);
            border-bottom: 1px solid var(--primaryColor);
            &:before {
              background-image: linear-gradient(
                to right,
                var(--primaryColor),
                var(--primaryColor)
              );
            }
            &:hover {
              &:before {
                background-image: linear-gradient(
                  to right,
                  var(--primaryColor),
                  var(--primaryColor)
                );
              }
            }
          }
          .group-pipeline-wrapper {
            width: 100%;
            position: relative;
            background-image: linear-gradient(to right, #e1e1e1, white);
            z-index: 2;
            .group-header {
              padding: 15px 12px 6px 12px;
              .group-heading {
                font-size: 16px;
              }
              &.category-pipeline,
              &.status-pipeline{
                padding:12px 12px 12px 12px;
                position: relative;
                &:before{
                  content:"";
                  position:absolute;
                  top:0px;
                  left:0px;
                  width:0px;
                  height:100%;
                  background-color:var(--primaryColor);
                  opacity:0.3;
                  transition:0.4s;
                }
                &.active{
                  &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    width: 4px;
                    height: calc(100% - 4px);
                    left: 0px;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    transform: translateY(-50%);
                    background-color: var(--primaryColor);
                  }
                  &:before{
                    width:100%;
                    opacity:0.3;
                  }
                }
                .group-heading{
                  font-size:14px;
                  font-weight:400;
                  &:hover{
                    color:var(--primaryColorDark);
                    text-decoration:underline;
                  }
                }
              }
              &.category-pipeline{
                margin-top:10px;
                margin-bottom:4px;
              }
              &.custom-pipeline{
                margin-bottom:6px;
              }
            }
            .group-item {
              border-top: none;
              border-bottom: none;
              &::before{
                content:"";
                  position:absolute;
                  top:0px;
                  left:0px;
                  width:0px;
                  height:100%;
                  background-color:var(--primaryColor);
                  opacity:0.3;
                  transition:0.4s;
                  display:block;
              }
              &:hover {
                &:before {
                  background-image: linear-gradient(
                    to right,
                    #d9d9d9,
                    rgba(255, 255, 255, 0)
                  );
                }
              }
              &.active {
                &:before {
                  background-image: linear-gradient(
                    to right,
                    var(--primaryColor),
                    rgba(0, 0, 0, 0)
                  );
                  opacity: 0.4;
                }
                &:hover {
                  &:before {
                    background-image: linear-gradient(
                      to right,
                      var(--primaryColor),
                      rgba(0, 0, 0, 0)
                    );
                  }
                }
              }
              &.active{
                &::before{
                  width:100%;
                }
              }
            }
            .add-btn-wrapper {
              padding: 7px 12px 14px 12px;
              .btn {
                width: 100%;
                background-color: white !important;
                border: none !important;
                color: var(--primaryColorDark);
                svg {
                  margin-right: 5px;
                  position: relative;
                  top: -2px;
                  color: var(--primaryColorDark);
                }
                .text {
                  color: var(--primaryColorDark);
                }
                &:hover {
                  background-color: var(--primaryColor) !important;
                  svg {
                    color: #000000;
                  }
                  .text {
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }
    .rightCN {
      width: calc(100% - 210px);
      .pipline-stage-kanban-scroll {
        height: calc(100dvh - 250px);
        padding: 20px 12px 0px 12px;
      }
      .pipline-stage-kanban-view-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px 0px 20px;
        .title {
          font-size: 22px;
          font-weight: 600;
          color: #000000;
          display: inline-flex;
          align-items: center;
          .task-manager-sidebar-toggle-btn {
            display: none;
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            flex-wrap: wrap;
            align-content: center;
            margin-right: 15px;
            cursor: pointer;

            background-color: #e8e8e8;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            margin-right: 10px;
            position: relative;
            .line {
              display: block;
              width: 100%;
              height: 3px;
              border-radius: 15px;
              background-color: var(--primaryColor);
              margin-bottom: 6px;
              display: none;
              &:last-child {
                margin-bottom: 0px;
              }
              &:nth-child(2) {
                width: 70%;
              }
            }
            &::before {
              content: '';
              position: absolute;
              top: 12px;
              left: 9px;
              width: 10px;
              height: 10px;
              border-left: 2px solid #000000;
              border-bottom: 2px solid #000000;
              transform: rotate(-135deg);
            }
          }
        }
        .btn-group {
          margin-left: 12px;
        }
        .view-btn.active {
          background-color: var(--primaryColor);
          color: #000000 !important;
        }
      }
      .stage-grid-view-wrapper{
        padding:0px 7px;
        .search-box-wrapper{
          width:300px;
          max-width:100%;
          margin-bottom:15px;
        }
      }
      .pipeline-stages-table{
        border: 1px solid #ececec;
        border-radius: 12px;
        .table-row {
          display: flex;
          flex-wrap: wrap;
          .table-cell {
            padding: 15px 15px;
            font-size: 14px;
            font-weight: 400;
            color: grey;
            display: flex;
            align-items: center;
            white-space:pre;
            overflow:hidden;
            text-overflow:ellipsis;
            &:nth-child(1) {
              width: 50px;
            }
            &:nth-child(2),
            &:nth-child(3){
              width:calc(50% - 90px);
            }
          }
        }
        .table-header {
          .table-row {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: var(--primaryColor);
            .table-cell {
              font-size: 14px;
              font-weight: 600;
              color: #000000;
            }
          }
        }
        .table-body {
          .table-row {
            background-color: #ffffff;
            border-bottom: 1px solid #ececec;
            &:last-child {
              border-bottom-left-radius: 12px;
              border-bottom-right-radius: 12px;
              border-bottom: none;
            }
            .table-cell {
              padding: 12px 15px;
              &:first-child {
                position: relative;
                .drag-icon {
                  &:first-child {
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 4px);
                    transform: translate(-50%, -50%);
                    width: 20px;
                  }
                  &:last-child {
                    position: absolute;
                    top: 50%;
                    left: calc(50% + 4px);
                    transform: translate(-50%, -50%);
                    width: 20px;
                  }
                }
              }
            }
            &.no-data-row {
              .table-cell {
                width: 100% !important;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                display: block;
                padding: 20px 15px;
              }
            }
          }
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          .action-btn {
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            margin-right: 5px;
            border-radius: 50%;
            transition: 0.5s;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            &:hover {
              background-color:#f4f4f4;
            }
            svg {
              color:#000000 !important;
              &.cursor-not-allowed{
                opacity:0.6;
              }
            }
            &.delete-btn{
              svg{
                color:red !important;
              }
            }
          }
        }
      }
      // .react-trello-board {
      //   padding: 0px 0px !important;
      //   & > div {
      //     width: 100%;
      //   }
      //   .smooth-dnd-container {
      //     display: flex;
      //     white-space: pre;
      //     .smooth-dnd-draggable-wrapper {
      //       height: auto;
      //       padding: 0px 8px;
      //       margin-bottom: 10px;
      //       .react-trello-lane {
      //         margin: 0px !important;
      //         height: 100%;
      //         width: 100%;
      //         position: relative;
      //         box-shadow: unset !important;
      //         padding-bottom: 42px;
      //         border: 1px solid #e6e6e6 !important;
      //         background-color:#fbfbfb;
      //       }
      //       .main-card-shadow {
      //         box-shadow: unset !important;
      //         position: static;
      //         padding: 0px !important;
      //         .header {
      //           display: flex;
      //           flex-wrap: wrap;
      //           align-items: center;
      //           padding: 9px 12px;
      //           width: calc(100% - 2px);
      //           margin: 0px auto;
      //           margin-top: 1px;
      //           border-top-left-radius: 10px;
      //           border-top-right-radius: 10px;
      //           position: relative;
      //           z-index: 2;
      //           cursor: grab !important;
      //           &:before {
      //             content: '';
      //             position: absolute;
      //             top: 0px;
      //             left: 0px;
      //             width: 100%;
      //             height: 100%;
      //             background-color: var(--primaryColor);
      //             border-top-left-radius: 10px;
      //             border-top-right-radius: 10px;
      //             opacity: 0.2;
      //           }
      //           .title {
      //             width: calc(100% - 31px);
      //             padding-right: 10px;
      //             font-size: 16px;
      //             font-weight: 500;
      //             color: #000000;
      //             margin-bottom: 0px;
      //             white-space: pre;
      //             overflow: hidden;
      //             text-overflow: ellipsis;
      //             position: relative;
      //             z-index: 3;
      //             cursor: grab !important;
      //           }
      //           .more-options-dropdown {
      //             cursor: pointer !important;
      //             .btn-icon {
      //               width: 30px;
      //               height: 30px;
      //               padding: 4px !important;
      //               svg {
      //                 width: 100%;
      //                 height: 100%;
      //                 color: #000000;
      //               }
      //               &:hover {
      //                 background-color: #ffffff;
      //               }
      //             }
      //           }
      //         }
      //         .header + div {
      //           margin: 0px !important;
      //           padding: 14px 12px;
      //           min-width: 100% !important;
      //         }
      //         .smooth-dnd-container {
      //           flex-wrap: wrap;
      //         }
      //         .smooth-dnd-draggable-wrapper {
      //           padding: 0px 0px;
      //           width: 100%;
      //           .contact-box {
      //             background-color: #ffffff;
      //             padding: 12px 14px 12px 30px;
      //             border-radius: 8px;
      //             border: 1px solid #e6e6e6;
      //             position: relative;
      //             margin-bottom: 10px;
      //             .title {
      //               margin-bottom: 0px;
      //               white-space: pre;
      //               overflow: hidden;
      //               text-overflow: ellipsis;
      //               font-size: 14px;
      //               font-weight: 600;
      //               color: #000000;
      //               cursor: pointer;
      //             }
      //             .move-icon-wrapper {
      //               width: 22px;
      //               height: 26px;
      //               position: absolute;
      //               top: 50%;
      //               transform: translateY(-50%);
      //               left: 5px;
      //               cursor: grab !important;
      //               .drag-icon {
      //                 position: absolute;
      //                 top: 50%;
      //                 width: 17px;
      //                 transform: translate(-50%, -50%);
      //                 &:first-child {
      //                   left: calc(50% - 4px);
      //                 }
      //                 &:last-child {
      //                   left: calc(50% + 3px);
      //                 }
      //               }
      //             }
      //             &:last-child {
      //               margin-bottom: 0px;
      //             }
      //           }
      //         }
      //         .addNew-contact-btn-wrapper {
      //           position: absolute;
      //           bottom: 0px;
      //           left: 0px;
      //           width: 100%;
      //           .btn {
      //             width: 100%;
      //             border-bottom-left-radius: 10px;
      //             border-bottom-right-radius: 10px;
      //             svg {
      //               margin-right: 6px;
      //               position: relative;
      //               top: -2px;
      //             }
      //           }
      //         }
      //       }
      //       &:last-child {
      //         margin-bottom: 0px;
      //         & > .card {
      //           border: none;
      //         }
      //       }
      //     }
      //   }
      // }
      // .react-trello-board
      //   > div
      //   > .smooth-dnd-container
      //   > .smooth-dnd-draggable-wrapper {
      //   min-width: 20%;
      //   max-width: 20%;
      // }
      .stage-grid-view-wrapper {
        .card-header {
          display: none;
        }
        .rdt_Table_wrapper {
          .search-box-wrapper {
            margin-top: 0px;
          }
        }
      }
      .no-record-found-table {
        padding: 40px 15px;
      }
      .contact-status-page-new{
        
      }
    }
  }
  .pipeline-list-view-wrapper {
    // border: 1px solid var(--primaryColor);
    // border-top: none;
    background-color: #ffffff;
    border-radius:8px;
    border: 1px solid #eae8e1;
    .rdt_Table_Card {
      box-shadow: unset;
      border: none;
      .card-header {
        background-color: transparent;
        .select-group {
          .custom_select_wrapper {
            width: 250px;
          }
        }
      }
    }
  }
}

.pipeline-page-wrapper.pipeline-page-wrapper-new{
  .horizontal-new-tab-wrapper {
    position: relative;
    margin-bottom: 15px;
    width:100%;
    z-index:4;
    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      left: 0px;
      background-color: #dddddd;
    }
    .horizontal-tabbing {
      .nav-item {
        .nav-link {
          padding: 12px 15px;
          border: none;
          background-color: transparent;
          font-size: 16px;
          font-weight: 500;
          border-radius: 0px;
          min-width:150px;
          svg {
            width: 21px;
            height: 21px;
            margin-right: 8px;
          }
          &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 0px;
            height: 2px;
            transition: 0.5s;
            left: 0px;
            background-color: var(--primaryColorDark);
          }
          &:hover {
            background-color: #ffffff;
          }
          &.active {
            color: var(--primaryColorDark);
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .react-trello-board{
    background-color:transparent !important;
    padding:0px 0px 10px 0px !important;
    min-height:unset !important;
    .smooth-dnd-draggable-wrapper{
      margin-right:18px;
      max-width:333px;
      .react-trello-lane{
        margin:0px !important;
        background-color:#ffffff;
        border:1px solid #ddd;
        box-shadow:unset !important;
        .spinner-wrapper{
          display:flex;
          align-items:center;
          justify-content:center;
          padding-top:15px;
        }
        .main-card-shadow{
          box-shadow:unset !important;
          padding:14px 14px !important;
          .header{
            display:flex;
            align-items:center;
            background-color: white;
            padding: 10px 10px 10px 14px;
            border-radius: 6px;
            margin-bottom:14px;
            min-height:50px;
            box-shadow:2px 2px 10px #ddd;
            .title{
              width:100%;
              color: var(--primaryColorDark);
              margin-bottom: 0px;
              font-size: 16px;
              font-weight: 700;
              width:100%;
              white-space:pre;
              overflow:hidden;
              text-overflow:ellipsis;
              padding-right:10px;
            }
            .more-options-dropdown{
              flex-shrink:0;
              .btn-icon{
                width:30px;
                height:30px;
                border-radius:50%;
                padding:3px !important;
                svg{
                  width:100%;
                  height:100%;
                  color:#000000;
                }
                &:hover{
                  background-color:#f0f0f0;
                }
              }
            }
          }
          .header + div{
            min-width:284px;
          }
          .addNew-contact-btn-wrapper{
            padding-top:10px;
            .btn.btn-primary{
              width:100%;
              background-color:transparent !important;
              color:var(--primaryColorDark) !important;
              border:none;
              svg{
                margin-right:5px;
                color:var(--primaryColorDark);
              }
              &:hover{
                background-color:var(--primaryColor) !important;
                color:#000000 !important;
                svg{
                  color:#000000;
                }
              }
            }
          }
        }
      } 
      .smooth-dnd-container{
        height:calc(100dvh - 338px);
        overflow-y:auto;
        .smooth-dnd-draggable-wrapper{
          margin-right:0px;
          width:100%;
          max-width:100%;
          background-color:#f0f0f0;
          border-radius:6px;
          margin-bottom:10px;
          .contact-box{
            display:flex;
            align-items:center;
            padding:10px 12px 10px 7px;
            cursor: pointer;
            .move-icon-wrapper{
              width:30px;
              height:30px;
              position: relative;
              flex-shrink:0;
              .drag-icon{
                width:22px;
                height:22px;
                position:absolute;
                top:50%;
                transform:translate(-50%,-50%);
                &:first-child{
                  left:calc(50% - 5px);
                }
                &:last-child{
                  left:calc(50% + 4px);
                }
              }
            }
            .avatar{
              background-color:var(--primaryColor) !important;
              flex-shrink:0;
              margin-left:3px;
              .avatar-content{
                color:#ffffff !important;
              }
              img{
                font-size:0px !important;
                object-fit:cover;
              }
            }
            .name-company-details{
              width:calc(100% - 32px);
              padding-left:5px;
              .contact-neme{
                font-size:16px;
                font-weight:600;
                color:#000000;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
                margin-bottom:0px;
              }
              .company-name{
                font-size:14px;
                font-weight:400;
                color:#515151;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
                margin-bottom:0px;
                margin-top:4px;
              }
            }
          }
        }
      }
    }
  }
  .pipeline-kanbanView-wrapper{
    .left-sidebar{
      background-color:#ffffff;
      border-radius: 12px;
      padding:0px 0px;
      width:280px;
      .group-wrapper{
        padding:0px 0px;
        .group-header{
          padding:16px 16px;
        }
        // .add-group-wrapper{
        //   padding:16px 16px;
        // }
        .group-list-body{
          height: calc(100dvh - 210px);
          margin-bottom: 16px;
          padding:16px 16px;
        }
        .group-item{
          .group-title{
            width:calc(100% - 32px);
          }
          .action-btn-wrapper{
            .action-btn{
              &:hover{
                background-color:#ffffff;
              }
            }
          }
        }
      }
    }
  }
  &.kanban-view-active{
    .tabbing-header-wrapper{
      &::before{
        display:none;
      }
    }
    .horizontal-new-tab-wrapper{
      width:calc(100% - 300px);
      margin-left:auto;
    }
    & > .tab-content{
      margin-top:-62px;
    }
    .pipeline-kanbanView-wrapper{
      .rightCN{
        width:calc(100% - 280px);
        // padding-top:48px;
        .pipline-stage-kanban-view-header{
          padding:0px 0px 0px 20px;
        }
        .pipline-stage-kanban-scroll{
          height:calc(100dvh - 180px);
          padding:15px 0px 0px 20px;
          .cate-status-pipeline-wrapper{
            width:100%;
            overflow-x:auto;
            .component-loader{
              width:70px;
              height:70px;
              margin:0px auto;
              min-height:unset;
              background-color:#ffffff;
              border-radius:50%;
              box-shadow:rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
              margin-bottom:10px;
              .page__round__loader{
                height: 54px;
                width: 54px;
              }
            }
          }
          .temp-board{
            display:inline-flex;
            white-space:pre;
            .temp-board-column{
              margin-right: 18px;
              background-color: #ffffff;
              border: 1px solid #ddd;
              width:315px;
              padding:14px;
              border-radius: 8px;
              .header{
                padding: 10px 10px 10px 14px;
                box-shadow: 2px 2px 10px #ddd;
                background-color: white;
                border-radius: 6px;
                margin-bottom: 14px;
                display: flex;
                align-items: center;
                min-height: 50px;
                .title{
                  color: var(--primaryColorDark);
                  font-size: 16px;
                  font-weight: 700;
                  width: 100%;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0px;
                }
                .more-options-dropdown{
                  flex-shrink: 0;
                  .btn-icon{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    padding: 3px !important;
                    svg{
                      width: 100%;
                      height: 100%;
                      color: #000000;
                    }
                    &:hover{
                      background-color: #f0f0f0;
                    }
                  }
                }
              }
              .addNew-contact-btn-wrapper{
                padding-top: 10px;
                .btn.btn-primary{
                  background-color: transparent !important;
                  border: none;
                  width: 100%;
                  color: var(--primaryColorDark) !important;
                  svg{
                    margin-right: 5px;
                    color: var(--primaryColorDark);
                  }
                  &:hover{
                    background-color: var(--primaryColor) !important;
                    color: #000000 !important;
                    svg{
                      color: #000000 !important;
                    }
                  }
                }
              }
            }
            .infinite-scroll-component{
              height:calc(100dvh - 340px) !important;
              margin-left: -14px;
              margin-right: -14px;
              padding: 0px 14px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items:flex-start;
              align-content:flex-start;
              & > div{
                width:100% !important;
              }
              & > .spinner-border{
                width:30px !important;
                height:30px !important;
                margin:12px auto 0px auto !important;
              }
            }
            .card-main{
              border:none;
              height:auto !important;
              margin-bottom: 10px;
            }
            .contact-box{
              display:flex;
              align-items:center;
              padding:10px 12px 10px 7px;
              background-color: #f0f0f0;
              border-radius: 6px;
              cursor: pointer;
              width:285px;
              .move-icon-wrapper{
                width:30px;
                height:30px;
                position: relative;
                flex-shrink:0;
                .drag-icon{
                  width:22px;
                  height:22px;
                  position:absolute;
                  top:50%;
                  transform:translate(-50%,-50%);
                  &:first-child{
                    left:calc(50% - 5px);
                  }
                  &:last-child{
                    left:calc(50% + 4px);
                  }
                }
              }
              .avatar{
                background-color:var(--primaryColor) !important;
                flex-shrink:0;
                margin-left:3px;
                .avatar-content{
                  color:#ffffff !important;
                }
                img{
                  font-size:0px !important;
                  object-fit:cover;
                }
              }
              .name-company-details{
                width:calc(100% - 65px);
                padding-left:13px;
                position: relative;
                top:3px;
                .contact-neme{
                  font-size:16px;
                  font-weight:600;
                  color:#000000;
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  margin-bottom:0px;
                }
                .company-name{
                  font-size:14px;
                  font-weight:400;
                  color:#515151;
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  margin-bottom:0px;
                  margin-top:4px;
                }
              }
            }
          }
          .scrollbar-container.group-list-body{
            .cate-status-pipeline-wrapper{
              height:calc(100dvh - 200px);
              overflow-y: auto;
            }
            .rdt_Table_wrapper{
              .search-box-wrapper{
                margin-top:0px;
              }
            }
          }
        }
        .stage-grid-view-wrapper{
          padding:0px 0px;
          .pipeline-stages-table{
            .table-body{
              // max-height:calc(100dvh - 375px);
              overflow-y: auto;
            }
          }
        }
      }
    }
    .pipeline-card{
      border:none;
      background-color:transparent;
    }
  }
}

.pipeline-page-wrapper {
  .tabbing-header-wrapper {
    margin-bottom: 0px;
    &:before {
      width: 100%;
      background-color: var(--primaryColor);
      display:none;
    }
    .horizontal-tabbing {
      margin-top: 0px;
      .nav-item {
        .nav-link {
          min-width: 200px;
          font-size: 16px;
        }
      }
    }
  }
  .pipeline-card {
    box-shadow: unset;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid var(--primaryColor);
    border-top: none;
  }
  .pipeline-kanbanView-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // margin-bottom:5px;
    margin-bottom: 0px;
    .left-sidebar {
      width: 260px;
      // min-height:calc(100dvh - 212px);
      border-right: none;
      .group-wrapper {
        height: 100%;
        padding: 10px 10px;
        .group-header {
          padding: 0px 0px 10px 0px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .plus-btn {
            width: 26px;
            height: 26px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            padding: 3px;
            transition: 0.5s;
            border-radius: 50%;
            background-color: #e3e3e3;
            cursor: pointer;
            display: none;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
              transition: 0.5s;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
          .group-heading {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            // width: calc(100% - 57px);
            width: 100%;
            // padding: 0px 10px 0px 10px;
            margin-bottom: 0px;
          }
          .down-arrow-btn {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 2px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            transition: 0.5s;
            display: none;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
            }
            &:hover {
              background-color: #e3e3e3;
              cursor: pointer;
            }
          }
        }
        .add-group-wrapper {
          padding: 13px 12px 13px 12px;
          .btn {
            width: 100%;
            svg {
              margin-right: 7px;
              position: relative;
              top: -2px;
            }
          }
        }
        .group-list-body {
          height: calc(100dvh - 300px);
          overflow-y: auto;
        }
        .group-item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          // padding:7px 12px;
          position: relative;
          cursor: pointer;
          .group-item-header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 7px 6px 7px 12px;
            width: 100%;
          }
          .group-title {
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            width: calc(100% - 91px);
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0px;
            position: relative;
            z-index: 2;
          }
          .action-btn-wrapper {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 2;
            .action-btn {
              width: 30px;
              height: 30px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              transition: 0.5s;
              border-radius: 50%;
              cursor: pointer;
              padding: 7px;
              svg {
                width: 100%;
                height: 100%;
              }
              &:hover {
                background-color: #e3e3e3;
              }
              &.edit-btn {
                svg {
                  color: #000000;
                }
              }
              &.toggle-btn {
                padding: 3px;
                svg {
                  color: #000000;
                }
              }
            }
          }
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            width: 100%;
            height: 100%;
            // opacity: 0.4;
            left: 0px;
          }
          &:hover {
            &:before {
              background-image: linear-gradient(
                to right,
                #d9d9d9,
                rgba(255, 255, 255, 0)
              );
            }
          }
          &.active {
            background-color: #f9f9f9;
            border-radius: 10px;
            // border-top:1px solid var(--primaryColor);
            // border-bottom:1px solid var(--primaryColor);
            border-top: none;
            border-bottom: none;
            &:before {
              // background-image: linear-gradient(to right, var(--primaryColor), var(--primaryColor));
              background-color: transparent;
              background-image: unset;
            }
            &:hover {
              &:before {
                background-image: unset;
              }
            }
            .group-item-header {
              border-top: none;
              background-color: #ececec;
              border-radius: 10px;
            }
          }
          .group-pipeline-wrapper {
            width: 100%;
            position: relative;
            background-image: unset;
            z-index: 2;
            .group-header {
              padding: 15px 12px 6px 12px;
              .group-heading {
                font-size: 16px;
              }
            }
            .group-item {
              border-top: none;
              border-bottom: none;
              position: relative;
              &:hover {
                &:before {
                  background-image: unset;
                }
              }
              &.active {
                &:before {
                  background-image: unset;
                  opacity: 0.4;
                }
                &:after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 0px;
                  width: 4px;
                  transform: translateY(-50%);
                  height: calc(100% - 4px);
                  background-color: var(--primaryColor);
                  border-top-right-radius: 6px;
                  border-bottom-right-radius: 6px;
                }
                &:hover {
                  &:before {
                    background-image: unset;
                  }
                }
              }
              .group-item-header {
                background-color: transparent;
                .group-title {
                  width: calc(100% - 61px);
                }
              }
            }
            .add-btn-wrapper {
              padding: 7px 12px 14px 12px;
              .btn {
                width: 100%;
                // background-color:white !important;
                background-color: transparent !important;
                border: none !important;
                color: var(--primaryColorDark);
                svg {
                  margin-right: 5px;
                  position: relative;
                  top: -2px;
                  color: var(--primaryColorDark);
                }
                .text {
                  color: var(--primaryColorDark);
                }
                &:hover {
                  background-color: var(--primaryColor) !important;
                  svg {
                    color: #000000;
                  }
                  .text {
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }
    .rightCN {
      width: calc(100% - 260px);
      .pipline-stage-kanban-scroll {
        height: calc(100dvh - 290px);
        padding: 15px 12px 0px 12px;
      }
      .pipline-stage-kanban-view-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px 0px 20px;
        .title {
          font-size: 22px;
          font-weight: 600;
          color: #000000;
        }
        .btn-group {
          margin-left: 12px;
        }
        .view-btn.active {
          background-color: var(--primaryColor);
          color: #000000 !important;
        }
      }
      // .react-trello-board {
      //   padding: 0px 0px !important;
      //   min-height: 300px !important;
      //   & > div {
      //     width: 100%;
      //   }
      //   .smooth-dnd-container {
      //     display: flex;
      //     white-space: pre;
      //     .smooth-dnd-draggable-wrapper {
      //       height: auto;
      //       padding: 0px 8px;
      //       margin-bottom: 10px;
      //       .react-trello-lane {
      //         margin: 0px !important;
      //         height: 100%;
      //         width: 100%;
      //         position: relative;
      //         box-shadow: unset !important;
      //         padding-bottom: 50px;
      //         background-color:#fbfbfb;
      //         border: 1px solid #e6e6e6 !important;
      //       }
      //       .main-card-shadow {
      //         box-shadow: unset !important;
      //         position: static;
      //         padding: 0px !important;
      //         .header {
      //           display: flex;
      //           flex-wrap: wrap;
      //           align-items: center;
      //           padding: 9px 14px;
      //           width: calc(100% - 2px);
      //           margin: 0px auto;
      //           margin-top: 1px;
      //           border-top-left-radius: 6px;
      //           border-top-right-radius: 6px;
      //           position: relative;
      //           z-index: 2;
      //           cursor: grab !important;
      //           &:before {
      //             content: '';
      //             position: absolute;
      //             top: 0px;
      //             left: 0px;
      //             width: 100%;
      //             height: 100%;
      //             border-top-left-radius: 6px;
      //             border-top-right-radius: 6px;
      //             opacity: 0.04;
      //           }
      //           .title {
      //             width: calc(100% - 31px);
      //             padding-right: 10px;
      //             font-size: 16px;
      //             font-weight: 500;
      //             color: #000000;
      //             margin-bottom: 0px;
      //             white-space: pre;
      //             overflow: hidden;
      //             text-overflow: ellipsis;
      //             position: relative;
      //             z-index: 3;
      //             cursor: grab !important;
      //           }
      //           .more-options-dropdown {
      //             cursor: pointer !important;
      //             .btn-icon {
      //               width: 30px;
      //               height: 30px;
      //               padding: 4px !important;
      //               svg {
      //                 width: 100%;
      //                 height: 100%;
      //                 color: #000000;
      //               }
      //               &:hover {
      //                 background-color: #ffffff;
      //               }
      //             }
      //           }
      //         }
      //         .header + div {
      //           margin: 0px !important;
      //           padding: 7px 12px 14px 12px;
      //           min-width: 100% !important;
      //         }
      //         .smooth-dnd-container {
      //           flex-wrap: wrap;
      //         }
      //         .smooth-dnd-draggable-wrapper {
      //           padding: 0px 0px;
      //           width: 100%;
      //           .contact-box {
      //             background-color: #ffffff;
      //             padding: 12px 14px 12px 30px;
      //             border-radius: 8px;
      //             border: 1px solid #e6e6e6;
      //             position: relative;
      //             margin-bottom: 10px;
      //             .title {
      //               margin-bottom: 0px;
      //               white-space: pre;
      //               overflow: hidden;
      //               text-overflow: ellipsis;
      //               font-size: 14px;
      //               font-weight: 600;
      //               color: #000000;
      //               cursor: pointer;
      //             }
      //             .move-icon-wrapper {
      //               width: 22px;
      //               height: 26px;
      //               position: absolute;
      //               top: 50%;
      //               transform: translateY(-50%);
      //               left: 5px;
      //               cursor: grab !important;
      //               .drag-icon {
      //                 position: absolute;
      //                 top: 50%;
      //                 width: 17px;
      //                 transform: translate(-50%, -50%);
      //                 &:first-child {
      //                   left: calc(50% - 4px);
      //                 }
      //                 &:last-child {
      //                   left: calc(50% + 3px);
      //                 }
      //               }
      //             }
      //             &:last-child {
      //               margin-bottom: 0px;
      //             }
      //           }
      //         }
      //         .addNew-contact-btn-wrapper {
      //           position: absolute;
      //           bottom: 5px;
      //           left: 50%;
      //           transform: translateX(-50%);
      //           width: calc(100% - 28px);
      //           border-top: 1px solid #e6e6e6;
      //           padding-top: 5px;
      //           .btn {
      //             width: 100%;
      //             border-bottom-left-radius: 6px;
      //             border-bottom-right-radius: 6px;
      //             background-color: transparent !important;
      //             border: none;
      //             svg {
      //               margin-right: 6px;
      //               position: relative;
      //               top: -2px;
      //             }
      //             &:hover {
      //               box-shadow: unset;
      //               color: var(--primaryColorDark) !important;
      //             }
      //           }
      //         }
      //       }
      //       &:last-child {
      //         margin-bottom: 0px;
      //       }
      //       &.smooth-dnd-ghost{
      //         .react-trello-lane{
      //           box-shadow:0px 0px 20px #c8c8c8 !important;
      //         }
      //       }
      //     }
      //   }
      // }
      // .react-trello-board
      //   > div
      //   > .smooth-dnd-container
      //   > .smooth-dnd-draggable-wrapper {
      //   min-width: 270px;
      //   max-width: 270px;
      // }
      .stage-grid-view-wrapper {
        .card-header {
          display: none;
        }
        .rdt_Table_wrapper {
          .search-box-wrapper {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .pipeline-list-view-wrapper {
    // border: 1px solid var(--primaryColor);
    // border-top: none;
    background-color: #ffffff;
    border-radius:8px;
    border: 1px solid #eae8e1;
    .rdt_Table_Card {
      box-shadow: unset;
      .card-header {
        background-color: transparent;
        .select-group {
          .custom_select_wrapper {
            width: 250px;
          }
        }
      }
    }
  }
}

.pipline-stage-view{
  &.modal-dialog{
    width:1200px;
    max-width:calc(100% - 30px);
    margin:30px auto;
  }
  .card-body{
    padding:0px 0px;
  }
  .rdt_Table_wrapper{
    .search-box-wrapper{
      margin-top:0px;
    }
  }
}

.add-new-contact-contact-pipline-modal {
  .search-box-wrapper{
    margin-top:5px !important;
    .form-element-icon-wrapper{
      width:100%;
    }
  }
  .contact-box {
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding-right: 30px;
      margin-bottom: 12px;
      border-bottom: 1px solid #ececec;
      padding-bottom: 12px;
      .form-check-input {
        width: 20px;
        height: 20px;
        position: absolute;
        top: calc(50% - 6px);
        transform: translateY(-50%);
        right: 0px;
        cursor: pointer;
      }
      .img-wrapper {
        width: 44px;
        height: 44px;
        .avatar{
          width:100%;
          height:100%;
          .avatar-content{
            width:100%;
            height:100%;
            text-transform:uppercase;
            font-size:14px !important;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
      .details {
        width: calc(100% - 45px);
        padding-left: 12px;
        .title {
          font-size: 16px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          color: #000000;
          margin-bottom: 0px;
        }
        .email {
          font-size: 16px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          color: #666;
        }
      }
    }
    &:last-child {
      .inner-wrapper {
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }
  .no-record-found-table {
    padding: 20px 0px;
    .img-wrapper {
      img {
        width: 250px;
        max-width: 100%;
      }
    }
  }
  .load-more-wrapper{
    width:100%;
    display:flex;
    justify-content:center;
    margin-top:7px;
  }
  .load-more-btn{
    width:150px !important;
  }
  .modal-footer {
    form {
      margin: 0px;
    }
    .btn-primary {
      width: 148px !important;
    }
  }
}

.add-pipline-stage-modal {
  .modal-footer {
    .btn-primary {
      min-width: 148px;
    }
  }
}

.moving-contact-pipline-modal {
  .from-to-wrapper {
    display: flex;
    flex-wrap: wrap;
    // align-items:center;
    justify-content: space-between;
    position: relative;
    .box-wrapper {
      width: calc(50% - 20px);
      position: relative;
      padding: 6px 12px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      .label {
        position: relative;
        z-index: 3;
        font-size: 16px;
        font-weight: 600;
        color: var(--primaryColorDark);
        display: block;
        width: 100%;
      }
      .value {
        position: relative;
        z-index: 3;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        display: block;
        width: 100%;
        opacity: 0.8;
        line-height: 18px;
      }
      .white-cover {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;
        border-radius: 8px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-image: linear-gradient(to right, var(--primaryColor), white);
        opacity: 0.2;
        // border:1px solid var(--primaryColor);
        z-index: 2;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 8px;
        background-image: linear-gradient(
          to right,
          var(--primaryColor),
          rgba(0, 0, 0, 0)
        );
        z-index: 0;
      }
      &:last-child {
        .label,
        .value {
          text-align: right;
        }
        &:before {
          background-image: linear-gradient(
            to right,
            white,
            var(--primaryColor)
          );
        }
        &:after {
          background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0),
            var(--primaryColor)
          );
        }
      }
    }
    .arrow {
      width: 25px;
      height: 25px;
      color: #000000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }
  }
  textarea.form-control {
    height: 200px;
  }
  .modal-footer {
    .btn-primary {
      min-width: 148px;
    }
  }
}

.pipline-contact-info-modal {
  &.modal-dialog {
    max-width: calc(100% - 30px);
    width: 600px;
  }
  .contact-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -8px;
  }
  .contact-info-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    border-bottom: none;
    position: relative;
    width: 50%;
    margin-bottom: 16px;
    padding: 0px 8px;
    .label {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      width: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }
    .value {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #535353;
      word-break: break-all;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      line-height: 20px;
    }
  }
}

.add-update-pipline-modal {
  .normal-text {
    font-size: 15px;
    margin-bottom: 7px;
  }
}
.add-update-groupPipeline {
  .normal-text {
    font-size: 15px;
    margin-bottom: 7px;
  }
}

.view-pipline-single-page {
  .card-header {
    padding-left: 60px;
    position: relative;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 14px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .card-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .value {
      padding: 9px 10px;
      width: calc(100% - 150px);
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #535353;
      word-break: break-all;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
    }
    &:nth-child(even) {
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.1;
      }
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      margin-bottom: 0px;
      border-bottom: 1px solid #ddd;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.add-update-pipline-modal {
  .normal-text {
    font-size: 15px;
    margin-bottom: 7px;
  }
}
.add-update-groupPipeline {
  .normal-text {
    font-size: 15px;
    margin-bottom: 7px;
  }
}

.view-pipline-single-page {
  .card-header {
    .card-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .arrowLeft-btn {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        margin-right: 6px;
      }
    }
    .button-wrapper {
      .btn-group {
        margin-right: 10px;
      }
    }
  }
  .table-responsive {
    border-radius: 12px;
    border: 1px solid #ececec;
    overflow: hidden !important;
  }
  .pipeline-stages-table {
    border-radius: 12px;
    thead {
      th {
        padding: 14px 12px;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        background-color: var(--primaryColor) !important;
        box-shadow: unset !important;
        text-transform: capitalize;
        &:first-child {
          border-top-left-radius: 12px;
          width: 50px;
        }
        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px 12px;
          box-shadow: unset;
          border-bottom: 1px solid #ececec;
          background-color: #ffffff;
          &:first-child {
            border-left: 1px solid #ececec;
            width: 50px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: calc(100% - 100px);
          }
          &:last-child {
            border-right: 1px solid #ececec;
            width: 100px;
          }
        }
        &:first-child {
          td {
            border-top: 1px solid #ececec;
          }
        }
      }
      .move-icon-wrapper {
        position: relative;
        width: 32px;
        height: 32px;
        .drag-icon {
          position: absolute;
          top: 50%;
          width: 20px;
          &:first-child {
            left: calc(50% - 4px);
            transform: translate(-50%, -50%);
          }
          &:last-child {
            left: calc(50% + 4px);
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .action-btn-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      .action-btn {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        border-radius: 50%;
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
  }
}
// pipeline page end

// Events page design start
.full-calender-wrapper {
  padding-top: 16px;
  .fc-header-toolbar {
    margin-bottom: 14px !important;
    .fc-prev-button,
    .fc-next-button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px;
      background-color: var(--primaryColor) !important;
      transition: 0.5s;
      .fc-icon {
        &::before {
          color: #000000;
        }
      }
      &:hover,
      &:focus {
        background-color: #000000 !important;
        .fc-icon {
          &::before {
            color: var(--primaryColor);
          }
        }
      }
    }
    .fc-prev-button {
      padding-right: 2px;
    }
    .fc-next-button {
      margin-left: 7px;
    }
    .fc-toolbar-title {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin-left: 10px;
    }
    .fc-toolbar-chunk {
      .fc-button-group {
        display: inline-flex;
        align-items: center;
        background-color: #f2f2f2;
        padding: 6px;
        border-radius: 12px;
        box-shadow: inset 1px 1px 4px #d5d5d5;
        .fc-button {
          width: 90px;
          height: 36px;
          margin-left: 0px;
          border: none;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 500;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #000000 !important;
          margin-right: 10px;
          &:hover {
            background-color: transparent !important;
          }
          &.fc-button-active {
            background-color: var(--primaryColor) !important;
            color: #000000 !important;
            &:hover {
              background-color: var(--primaryColor) !important;
              color: #000000;
            }
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .fc-scrollgrid {
    border: none !important;
  }
  .fc-scrollgrid-section-header {
    & > th {
      border-right: none !important;
    }
    .fc-col-header-cell {
      border: none !important;
      background-color: #000000;
      &:first-child {
        border-top-left-radius: 12px;
        overflow: hidden;
      }
      &:last-child {
        border-top-right-radius: 12px;
        overflow: hidden;
      }
      .fc-col-header-cell-cushion {
        display: block;
        color: #ffffff;
        padding: 10px 10px;
        font-size: 18px;
        font-weight: 600;
      }
      &.fc-day-today {
        background-color: var(--primaryColor) !important;
        .fc-col-header-cell-cushion {
          color: #000000 !important;
        }
      }
    }
  }
  .fc-daygrid-body {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.1;
    }
    .fc-daygrid-day-number {
      font-size: 18px;
      color: #000000;
      font-weight: 600;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      padding: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .fc-daygrid-day-top {
      opacity: 1;
      display: inline-flex !important;
      font-size: 18px;
      color: #000000;
      font-weight: 600;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      padding: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-decoration: none;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--primaryColor);
        opacity: 0;
        transition: 0.3s;
      }
      &:hover {
        text-decoration: none;
        &:before {
          opacity: 0.3;
        }
      }
    }
    .fc-day-today {
      background-color: transparent !important;
      .fc-daygrid-day-top {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .fc-daygrid-day-events {
    margin-top: 5px !important;
    .fc-daygrid-event-harness {
      margin-bottom: 4px !important;
      .fc-daygrid-event {
        background-color: #ffffff !important;
        padding: 5px 8px 5px 18px;
        font-size: 14px;
        color: #000000 !important;
        border-radius: 8px !important;
        position: relative;
        margin-top: 0px !important;
        .fc-event-time {
          font-size: 14px !important;
        }
        .fc-event-title-container {
          font-size: 14px !important;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 6px;
          transform: translateY(-50%);
          width: 5px;
          height: calc(100% - 10px);
          // border-top-left-radius:4px;
          // border-bottom-left-radius:4px;
          border-radius: 10px;
          background-color: var(--primaryColor);
        }
        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border: 1px solid var(--primaryColor);
          border-radius: 8px;
          opacity: 0.5;
        }
      }
    }
  }
  .fc-daygrid-day-bottom {
    .fc-daygrid-more-link {
      font-size: 14px;
      font-weight: 600;
      color: var(--primaryColorDark);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .fc
    .fc-toolbar
    .fc-button-group
    .fc-button-primary:not(.fc-prev-button):not(
      .fc-next-button
    ).fc-button-active {
    background-color: var(--primaryColor) !important;
    font-weight: 600;
  }
  .fc-timegrid-slot-label-cushion {
    font-size: 13px;
    color: #000000;
    font-weight: 400;
  }
  .fc-timegrid-axis-cushion {
    font-size: 13px;
    color: #000000 !important;
    font-weight: 400;
  }
  .fc-timegrid-event-harness {
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      border-radius: 8px;
      opacity: 0.1;
    }
  }
  .fc-timegrid-event {
    background-color: transparent !important;
    padding: 5px 8px 5px 18px;
    font-size: 14px;
    color: #000000 !important;
    border-radius: 8px !important;
    position: relative;
    margin-top: 0px !important;
    box-shadow: unset !important;
    border: none !important;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      width: 5px;
      height: calc(100% - 10px);
      // border-top-left-radius:4px;
      // border-bottom-left-radius:4px;
      border-radius: 10px;
      background-color: var(--primaryColor);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--primaryColor);
      border-radius: 8px;
      opacity: 0.5;
    }
  }
  .fc-scrollgrid-section-header .fc-timegrid-axis {
    background-color: #000000;
    border-top-left-radius: 12px;
  }
}
.fc-timeGridDay-view .fc-scrollgrid-section-header .fc-timegrid-axis {
  background-color: var(--primaryColor);
}
.fc-timeGridWeek-view .fc-scroller-harness.fc-scroller-harness-liquid {
  margin-top: -2px;
}
.fc .fc-timeGridDay-view .fc-timegrid-cols .fc-day-today {
  background-color: #ffffff !important;
}

.fc-popover.fc-more-popover {
  border-radius: 8px;
  overflow: hidden !important;
  width: 300px;
  .fc-popover-header {
    background-color: #000000;
    padding: 6px 10px;
    .fc-popover-title {
      color: #ffffff;
      font-weight: 600;
    }
    .fc-popover-close {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      transition: 0.5s;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
      opacity: 1;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        transition: 0.5s;
        font-size: 18px;
      }
      &:hover {
        background-color: rgba(255, 255, 255);
        &:before {
          color: #000000;
        }
      }
    }
  }
  .fc-popover-body {
    padding: 12px 12px !important;
  }
  .fc-daygrid-event-harness {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      border-radius: 8px;
      opacity: 0.1;
    }
  }
  .fc-daygrid-event {
    background-color: transparent !important;
    padding: 5px 8px 5px 18px;
    font-size: 14px;
    color: #000000 !important;
    border-radius: 8px !important;
    position: relative;
    margin-top: 0px !important;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      width: 5px;
      height: calc(100% - 10px);
      // border-top-left-radius:4px;
      // border-bottom-left-radius:4px;
      border-radius: 10px;
      background-color: var(--primaryColor);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--primaryColor);
      border-radius: 8px;
      opacity: 0.5;
    }
  }
}

.fc-list {
  border: none !important;
  .fc-list-table {
    thead,
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    tr {
      display: flex;
      background-color: transparent !important;
      th {
        display: flex;
        width: 100%;
        border: none;
        .fc-list-day-cushion {
          display: flex;
          justify-content: space-between;
          width: 100%;
          background-color: transparent;
          padding: 0px 0px;
          a {
            display: inline-block;
            float: none !important;
            font-size: 17px;
            font-weight: 700;
            color: #000000;
          }
          &:after {
            display: none;
          }
        }
      }
      .fc-list-event-time,
      .fc-list-event-graphic,
      .fc-list-event-title {
        padding: 10px 10px;
        background-color: transparent;
        border: none;
      }
      .fc-list-event-time {
        font-size: 14px;
        color: #000000;
        font-weight: 500;
        width: 160px;
        padding-left: 17px;
      }
      .fc-list-event-graphic {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .fc-list-event-dot {
          border: none;
          width: 10px;
          height: 10px;
          background-color: var(--primaryColor);
          border-radius: 50%;
        }
      }
      .fc-list-event-title {
        width: calc(100% - 190px);
        display: flex;
        align-items: center;
        a {
          display: inline-block;
          font-size: 14px;
          color: #000000;
          font-weight: 400;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.fc-list-event {
        border: 1px solid var(--primaryColor) !important;
        margin-bottom: 8px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          background-color: var(--primaryColor);
          opacity: 0.1;
        }
        &:hover {
          td {
            background-color: transparent !important;
          }
        }
        &.fc-event-today {
          .fc-list-event-graphic {
            .fc-list-event-dot {
              background-color: #000000;
            }
          }
          &:before {
            opacity: 1;
          }
        }
      }
      &.fc-list-day {
        margin-bottom: 8px;
        margin-top: 20px;
        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }
}
// Events page design end

// add-event-modal
.add-update-event-modal {
  .modal-header {
    .inner-wrapper {
      display: flex;
      align-items: center;
    }
    .title {
      display: inline-block;
      margin-right: 10px;
    }
    .action-btn-wrapper {
      display: inline-flex;
      .action-btn {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 2px;
        svg {
          width: 100%;
          height: 100%;
        }
        &.link-btn {
          padding: 9px;
        }
        &:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }
  .preview-RSVP-wrapper {
    margin-bottom: 15px;
    .inner-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        padding-right: 12px;
      }
    }
  }
  .update-rsvp-form-new {
    width: 500px;
    max-width: 100%;
    margin: 0px auto;
    .logo-name-radio-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .inner-item {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        .label {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          padding-right: 12px;
          display: inline-block;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
    .form-card {
      border-radius: 12px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      .top-header {
        padding: 20px 20px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        // background-color:#f7f7f7;
        border-bottom: 1px solid #dfdfdf;
        .brand-logo {
          img {
            max-height: 70px;
            object-fit: contain;
            object-position: center;
          }
        }
        .company-name {
          margin-bottom: 0px;
          margin-top: 13px;
          width: 100%;
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          color: #000000;
        }
      }
      .form-body {
        padding: 20px 20px;
        .event-name {
          font-size: 20px;
          font-weight: 700;
          color: var(--primaryColorDark);
        }
        .event-details-title {
          font-size: 17px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 8px;
        }
        .event-dec {
          font-size: 14px;
          font-weight: 400;
          color: grey;
          margin-bottom: 14px;
        }
        .event-start-end-date {
          display: flex;
          flex-wrap: wrap;
          // align-items:center;
          justify-content: space-between;
          position: relative;
          margin-bottom: 14px;
          .box-wrapper {
            width: calc(50% - 10px);
            position: relative;
            padding: 10px 14px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            .label {
              position: relative;
              z-index: 3;
              font-size: 15px;
              font-weight: 600;
              color: var(--primaryColorDark);
              display: block;
              width: 100%;
              margin-bottom: 2px;
            }
            .value {
              position: relative;
              z-index: 3;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              display: block;
              width: 100%;
              opacity: 0.8;
              line-height: 18px;
            }
            .white-cover {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background-color: white;
              z-index: 1;
              border-radius: 8px;
            }
            &:before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              background-image: linear-gradient(
                to right,
                var(--primaryColor),
                white
              );
              opacity: 0.2;
              // border:1px solid var(--primaryColor);
              z-index: 2;
            }
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: calc(100% + 2px);
              height: calc(100% + 2px);
              border-radius: 8px;
              background-image: linear-gradient(
                to right,
                var(--primaryColor),
                rgba(0, 0, 0, 0)
              );
              z-index: 0;
            }
            &:last-child {
              .label,
              .value {
                text-align: right;
              }
              &:before {
                background-image: linear-gradient(
                  to right,
                  white,
                  var(--primaryColor)
                );
              }
              &:after {
                background-image: linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0),
                  var(--primaryColor)
                );
              }
            }
          }
        }
        .radio-btn-main-wrapper {
          margin-bottom: 14px;
          .radio-btn-wrapper {
            .radio-btn-repeater {
              padding-left: 0px;
            }
            .form-check-label {
              font-size: 15px;
              color: #000000;
              font-weight: 500;
              padding-left: 8px;
              position: relative;
              &:before {
                content: '';
                position: absolute;
                top: 6px;
                left: -13px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #ffffff;
                z-index: 2;
              }
            }
            input {
              margin-left: 0px !important;
            }
            input:checked {
              .form-check-label {
                position: relative;
              }
            }
          }
        }
      }
      .form-footer {
        padding: 20px 20px 14px 20px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: #f7f7f7;
        .footer-text {
          font-size: 14px;
          font-weight: 400;
          margin-top: 13px;
          color: #000000;
          a {
            color: var(--primaryColorDark);
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .event-details-wrapper {
    background-color: #fbfbfb;
    border-radius: 12px;
    padding: 20px 20px 10px 20px;
    margin-bottom: 16px;
    .title {
      font-size: 18px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .flatpickr-input {
      background-color: #ffffff;
    }
    .right {
      textarea {
        height: calc(100% - 29px);
      }
    }
  }
  .schedule-events-wrapper {
    background-color: #fbfbfb;
    border-radius: 12px;
    padding: 20px 20px 10px 20px;
    margin-bottom: 16px;
    .title {
      font-size: 18px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .flatpickr-input {
      background-color: #ffffff;
    }
    .schedule-repeat-label {
      margin-top: 0px;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      position: relative;
      top: 38px;
    }
    .event-days {
      display: flex;
      border-radius: 7px;
      overflow: hidden;
      background-color: #ffffff;
      .event-days-btn {
        width: 14.28%;
        margin: 0px;
        background-color: transparent;
        border-radius: 0px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: grey;
        border: 1px solid #e8e8e8;
        &:first-child {
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }
        &:last-child {
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
        }
        &.active {
          border-top: 1px solid var(--primaryColor);
          border-bottom: 1px solid var(--primaryColor);
          background-color: var(--primaryColor);
          color: #000000;
        }
      }
    }
  }
  .contact-filters-wrapper {
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    .contact-filter-heading {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      .search-box-wrapper {
        width: 350px;
        max-width: 100%;
      }
      .more-filter-btn {
        padding: 8px 28px 8px 10px;
        border-radius: 7px;
        border: 1px solid transparent;
        background-color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        transition: 0.5s;
        &:before {
          content: '';
          position: absolute;
          top: 15px;
          right: 12px;
          width: 8px;
          height: 8px;
          border-left: 2px solid #000000;
          border-bottom: 2px solid #000000;
          transform: rotate(-45deg);
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
    }
    .sec-title {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
      width: 100%;
    }
    .filter-fields-wrapper {
      border-top: 1px solid #e1e1e1;
      padding: 20px 20px 4px 20px;
      .field-col {
        margin-bottom: 18px !important;
      }
      .custom_select_wrapper {
        .cs_form_label {
          background-color: transparent;
          background-image: linear-gradient(#f9f9f9 46%, white 47%);
        }
      }
    }
  }
  .invite-contacts-wrapper {
    background-color: #fbfbfb;
    border-radius: 12px;
    padding: 20px 20px 20px 20px;
    margin-bottom: 16px;
    .main-title {
      font-size: 18px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .custom_select_wrapper {
      .cs_form_label {
        background-color: transparent;
        background-image: linear-gradient(to bottom, #fbfbfb 43%, white 44%);
      }
    }
    .accordion-margin.contact-accordion {
      width: 100%;
      .accordion-item {
        box-shadow: unset !important;
        border: 1px solid #e8e8e8 !important;
        margin-top: 0px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        .btn-group.toggle-view-btn-wrapper {
          margin-right: 0px;
          position: absolute;
          top: 6px;
          right: 48px;
          z-index: 4;
          .view-btn {
            padding: 7px 7px;
            &.active {
              background-color: var(--primaryColor);
              svg {
                color: #ffffff;
              }
            }
          }
        }
        .accordion-button {
          padding: 8px 8px 8px 20px;
          display: flex;
          justify-content: space-between;
          &:after {
            display: none;
          }
          .title {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
            width: calc(100% - 100px);
            padding-right: 12px;
            .value {
              color: var(--primaryColor);
              margin-left: 5px;
            }
          }
          .down-arrow-btn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            transition: 0.5s;
            padding: 3px;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
              transform: rotate(-180deg);
              transition: 0.5s;
            }
            &:hover {
              background-color: #ececec;
            }
          }
          &.collapsed {
            .down-arrow-btn {
              svg {
                transform: rotate(0deg);
              }
            }
          }
        }
        .accordion-collapse.collapse {
          border-top: 1px solid #e8e8e8;
        }
        .accordion-body {
          padding-top: 20px;
        }
        .event-contact-list-wrapper {
          margin: 0px -8px;
          .event-contact-col {
            margin: 0px !important;
            margin-bottom: 15px !important;
            padding: 0px 8px !important;
            width: 25%;
            flex: unset;
            .event-contact-card {
              background-color: #f7f7f7;
              border-radius: 12px;
              margin: 0px !important;
              .inner-card {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;
                padding: 12px 42px 12px 12px;
              }
              .avatar {
                width: 42px;
                height: 42px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 50%;
                  overflow: hidden;
                }
              }
              .right-details {
                width: calc(100% - 43px);
                padding-left: 12px;
                .title {
                  font-size: 16px;
                  line-height: 24px;
                  color: #000000;
                  font-weight: 700;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 2px;
                }
                .text {
                  font-size: 14px;
                  color: grey;
                  margin-bottom: 0px;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .form-check-input-wrapper {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 14px;
                width: 18px;
                height: 18px;
                input {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .rdt_Table_wrapper {
        .search-box-wrapper {
          margin-top: 0px;
        }
      }
    }
  }
  .invite-contacts-wrapper.rsvp-submissions-wrapper {
    .accordion-margin.contact-accordion {
      .accordion-item {
        .accordion-button {
          .title {
            width: calc(100% - 32px);
          }
        }
      }
    }
  }
  .modal-footer {
    .add-event-btn,
    .calcel-btn {
      width: 148px !important;
      margin-left: 14px;
    }
    .auth-login-form {
      margin-left: 0px;
    }
    .delete-all-btn,
    .delete-btn,
    .cancel-btn,
    .update-btn {
      margin: 0px 0px;
    }
    .delete-btn,
    .cancel-btn,
    .update-btn {
      width: 148px !important;
      margin-left: 14px;
    }
    .delete-all-btn {
      width: auto !important;
    }
  }
}
// add-event-modal-end

// email template page
.email-template-card {
  margin-bottom: 5px;
  .inner-card-wrapper {
    position: relative;
    .grid-card-view-toggle-btns {
      position: absolute;
      top: 14px;
      right: 20px;
      z-index: 3;
      .view-btn {
        &.active {
          background-color: var(--primaryColor);
          svg {
            color: #ffffff;
          }
        }
      }
    }
  }
  .card-header {
    padding-right: 115px;
  }
  .company-email-inner-scroll {
    height: calc(100dvh - 233px);
    max-height: calc(100dvh - 233px);
    padding-bottom: 20px;
    &.load-more-active {
      height: calc(100dvh - 295px);
      max-height: calc(100dvh - 295px);
      padding-bottom: 20px;
    }
  }
  .card-body {
    padding-bottom: 10px !important;
  }
  .company-form-card {
    .btn-wrapper {
      .btn {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        span {
          color: var(--primaryColorDark);
        }
        .icon-wrapper {
          width: 26px;
          height: 26px;
          padding: 3px;
          border-radius: 50%;
          background-color: var(--primaryColor);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          svg {
            color: #000000;
            position: relative;
            left: -1px;
            top: 1px;
            transition: 0.5s;
          }
        }
        &:hover {
          .icon-wrapper {
            background-color: #000000;
            svg {
              color: var(--primaryColor);
            }
          }
        }
      }
    }
    .body-wrapper {
      padding: 16px 15px 50px 15px;
    }
  }
  .email-template-list-view {
    .card {
      box-shadow: unset !important;
      margin-bottom: 0px !important;
    }
  }
  .loadMore-btn-wrapper {
    position: relative;
    padding: 15px 10px 8px 10px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 44px);
      height: 1px;
      background-color: #eae8e1;
    }
  }
}
// email template page end

// update-profile-page
.update-profile-card {
  margin-bottom: 10px;
}
.update-profile-form {
  width: 900px;
  max-width: 100%;
}
// update-profile-page-end

// setting-profile-page
.setting-profile-page {
  .setting-profile-tab-wrapper {
    position: relative;
    margin-bottom: 15px;
    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      left: 0px;
      background-color: #dddddd;
    }
    .horizontal-tabbing {
      .nav-item {
        .nav-link {
          padding: 12px 15px;
          border: none;
          background-color: transparent;
          font-size: 16px;
          font-weight: 500;
          border-radius: 0px;
          &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 0px;
            height: 2px;
            transition: 0.5s;
            left: 0px;
            background-color: var(--primaryColorDark);
          }
          &:hover {
            background-color: #ffffff;
          }
          &.active {
            color: var(--primaryColorDark);
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .personal-info-tab {
    background-color: transparent;
    border: none;
    padding: 6px 13px 20px 13px;
    .card-header {
      background-color: transparent !important;
      border: none;
      padding: 0px 0px 14px 0px;
    }
    .card-body {
      padding: 0px 0px;
    }
    .update-profile-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .field-wrapper {
        width: calc(50% - 20px);
        &.upload-profile,
        &.biography {
          width: 100%;
        }
      }
    }
  }

  .contact-activity-tabPane {
    padding: 13px 13px;
    height: calc(100dvh - 200px);
    overflow-y: auto;
  }

  .contact-notes-tabPane {
    .notes-list-scroll-area {
      max-height: calc(100dvh - 270px);
      overflow-y: auto;
    }
    .inner-contant-wrapper {
      padding: 10px 12px 0px 12px;
    }
    .rdw-editor-wrapper {
      background-color: #ffffff;
    }
    .rdw-editor-main {
      border: none !important;
    }
    .available-notes-header {
      .print-action-wrapper {
        .add-note-btn {
          margin-left: 13px;
        }
      }
    }
    .contact-notes {
      .accordion-item {
        background-color: #ffffff;
        margin-bottom: 14px !important;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
          rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
        .accordion-header {
          .accordion-button {
            padding: 10px 15px 5px 15px !important;
            .inner-wrapper {
              .heading-details {
                padding-left: 0px;
                .title {
                  margin-bottom: 5px;
                }
                .author-time {
                  .author-name,
                  .time {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .action-btn-wrapper {
              .action-btn {
                &:hover {
                  background-color: #f4f4f4;
                }
              }
            }
          }
        }
      }
      .contant-wrapper {
        .footer-details {
          .author-name {
            background-color: #f4f4f4;
          }
          .action-btn-wrapper {
            .action-btn {
              background-color: #f4f4f4;
            }
          }
        }
      }
    }

    // need-to-comment
    .add-note-row,
    .submit-btn-wrapper {
      display: none !important;
    }
    .available-notes-wrapper {
      padding-top: 0px;
      margin-top: 0px !important;
      &:before {
        display: none;
      }
      .form-control {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: unset !important;
        background-image: url(../images/icons/search-gray-icon.svg);
        background-size: 18px;
        background-position: 11px 10px;
        padding: 4px 20px 5px 38px;
        background-repeat: no-repeat;
        height: 41.2px;
        font-size: 16px;
        color: #6e6b7b;
        &:focus {
          border: 1px solid var(--primaryColor);
          box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        }
      }
      .form-control::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
    }
    // need-to-comment-end
  }

  .contact__checklist__wrapper {
    position: relative;
    padding: 5px 13px 0px 13px;
    .list-grid-btn-wrapper {
      display: inline-flex;
      position: absolute;
      top: 6px;
      z-index: 4;
      right: 14px;
      .view-btn {
        &.active {
          background-color: var(--primaryColor) !important;
          color: #000000 !important;
        }
      }
    }
    .card-header {
      padding: 0px 0px 0px 0px;
      padding-right: 94px;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: transparent;
      border-bottom: none;
      .card-title {
        font-size: 22px !important;
        font-weight: 600 !important;
        margin-bottom: 0;
      }
      .create-folder-link {
        top: 0px;
        padding: 10px 20px 10px 20px;
      }
    }
    .company-checklist-inner-scroll {
      max-height: calc(100dvh - 265px);
    }
    .compnay-checklist-folder-wrapper {
      & > .card-body {
        padding: 20px 0px 0px 0px !important;
      }
    }
    .accordion-item.checklist-box {
      background-color: #ffffff;
      margin-bottom: 14px !important;
      border-radius: 10px !important;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
        rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
      .checklist-header {
        .title {
          padding-right: 0px;
          width: calc(100% - 220px);
          .checklist-name {
            max-width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .badge {
            padding: 6px 12px;
            margin: 0px 0px 0px 12px !important;
            flex-shrink: 0;
          }
        }
        .action-btn-wrapper {
          padding-left: 15px;
          flex-shrink: 0;
        }
        .action-btn {
          &:hover {
            background-color: #f4f4f4;
          }
        }
      }
      .company-form-card.checklist-company-form-card {
        background-color: #f9f9f9;
        .body-wrapper {
          .checklist-listing-items {
            .item {
              .inner-wrapper {
                &:before {
                  background: linear-gradient(
                    118deg,
                    #b7b7b7,
                    rgba(0, 0, 0, 0)
                  );
                  opacity: 0.3;
                }
              }
              &.active {
                .inner-wrapper {
                  &:before {
                    background: linear-gradient(
                      118deg,
                      var(--primaryColor),
                      rgba(0, 0, 0, 0)
                    );
                    opacity: 0.3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact__files__wrapper {
    padding-top: 5px;
    .top-header {
      margin-bottom: 18px !important;
    }
    .company-document-page {
      .company-document-listing-row {
        .company-checklist-inner-scroll {
          height: calc(100dvh - 346px);
          max-height: calc(100dvh - 346px);
        }
      }
      .company-document-listing-row {
        &:last-child {
          .card-body {
            height: calc(100dvh - 318px);
            max-height: calc(100dvh - 318px);
          }
        }
      }
    }
  }

  .loyal-task-manager{
    .sidebar-left{
      height:calc(100dvh - 200px);
    }
    .content-right{
      .task-manager-body{
        .infinite-scroll-component.todo-task-list{
          height:calc(100dvh - 372px) !important;
        }
      }
      .task-manager-category-scrollbar{
        height:calc(100dvh - 304px) !important;
      }
      .task-calenderView-wrapper{
        .full-calender-wrapper{
          height:calc(100dvh - 300px);
        }
      }
    }
  }
}

.add-update-note-modal {
  &.modal-dialog {
    width: 700px;
    max-width: calc(100% - 30px);
    margin: 0px auto;
  }
  .modal-title{
    display:flex;
    justify-content:space-between;
    width:100%;
    .leftWrapper{
      display:flex;
      align-items:center;
      .saving__tag{
        margin-left:10px;
        display:flex;
        align-items:center;
        .saving__tag__icon{
          display:inline-flex;
          align-items:center;
        }
        .text{
          font-size:16px;
          margin-left:5px;
          position: relative;
          top:1px;
        }
      }
    }
  }
  .timerWrapper{
    margin-top:14px;
    .timerText{
      font-size:16px;
      font-weight:700;
      color:#000000;
      position: relative;
      top:1px;
    }
    .timerStartStopBtn {
      padding:8px 12px;
      margin-right:10px;
      &.stopBtn{
        background-color:red !important;
        border:1px solid red !important;
        color:#ffffff !important;
        &:hover{
          background-color:#dd0b0b !important;
          border:1px solid #dd0b0b !important;
        }
      }
    }
  }
  .modal-body {
    padding-bottom: 0px;
  }
  .modal-footer {
    border-top: none;
  }
  .header__title {
    .form-control {
      width: 100%;
    }
    .btn {
      flex-shrink: 0;
      height: 41.2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 14px;
      width: 87px;
      margin-left: 10px;
      svg {
        margin-right: 3px;
      }
    }
  }
  .attachment-save-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    .custom-fileupload {
      border: none;
      background-color: transparent;
      display: block;
      width: 100%;
      .ui-loader {
        .overlay {
          background-color: transparent !important;
        }
      }
      .inner-wrapper {
        padding: 0px 0px;
        .dropzone {
          display: inline-block;
          .outsite-CN {
            display: none !important;
          }
        }
      }
      .ln-btn {
        padding: 7px 14px;
        border-radius: 4px;
        position: absolute;
        bottom: -56px;
        left: 0px;
        display: flex;
        align-items: center;
        .spinner-border {
          margin-right: 8px;
          width: 21px;
          height: 21px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          color: #000000;
          position: relative;
          top: -2px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
      .attachment-wrapper {
        margin-top: 6px;
        justify-content: flex-start;
        .file__card {
          width: 25%;
          .inner-wrapper {
            .overlay {
              background-color: rgba(0, 0, 0, 0.7) !important;
            }
          }
        }
      }
      .remove-all-btn {
        // position: absolute;
        // bottom: -38px;
        // left: 0px;
        justify-content: flex-start !important;
        margin-top: 0px !important;
      }
    }
    .btn-primary {
      margin-left: 10px;
      min-width: 140px;
    }
  }
  .note-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 12px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .profile-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      .avatar {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }
    .right-contant {
      width: calc(100% - 47px);
      padding-left: 10px;
      .note-header {
        display: flex;
        align-items: center;
        .name-time {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          width: 100%;
          padding-right: 12px;
          .name,
          .time {
            display: inline-block;
            margin-bottom: 4px;
          }
          .name {
            margin-right: 10px;
            color: #000000;
            font-weight: 600;
          }
          .time {
            font-weight: 500;
            position: relative;
            padding-left: 16px;
            &:before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #aeaeae;
            }
          }
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
          flex-shrink: 0;
          .action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #ffffff;
            padding: 7px;
            transition: 0.5s;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
            &:last-child {
              margin-left: 6px;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
        }
      }
      .attachment-wrapper {
        .file__card {
          width: 50%;
          margin-bottom: 7px;
          .inner-border-wrapper {
            background-color: #ffffff;
            border: 1px solid #ececec;
          }
          .inner-wrapper {
            padding-top: 0px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }
          .file__preview {
            position: static;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            img {
              border-radius: 6px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .file-name {
            font-size: 16px;
            font-weight: 400;
            color: grey;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
          .file__preview__details {
            display: flex;
            width: calc(100% - 69px);
            padding-right: 12px;
            align-items: center;
          }
          .overlay {
            position: static;
            opacity: 1;
            z-index: 2;
            background-color: transparent;
            display: inline-flex;
            flex-shrink: 0;
            width: auto;
            height: auto;
            .action-btn-wrapper {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .action-btn {
                width: 32px;
                height: 32px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #ffffff;
                padding: 7px;
                transition: 0.5s;
                cursor: pointer;
                margin-right: 0px;
                margin-left: 0px;
                svg {
                  width: 100%;
                  height: 100%;
                  color: #777 !important;
                }
                &:last-child {
                  margin-left: 4px;
                }
                &:hover {
                  background-color: #000000;
                  svg {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .attachment-SpeechToText-wrapper{
    position: relative;
    .speech-to-text-btn{
      padding:10px 15px 7px 15px !important;
      position: absolute;
      right: 0px;
      bottom:17px;
      min-height: 38px;
      min-width: 90px;
      svg{
        margin-right:5px;
        position: relative;
        top:-1px;
      }
    }
    // TEMP DESIGN [MG]
    .cancel-btn{
      min-height: 38px;
      min-width: 90px;
      padding:10px 15px 7px 15px !important;
      position: absolute;
      right: 0;
      bottom:17px;
    
    }
  }
}
// setting-profile-page-end

// integration page
.integration-card {
  margin-bottom: 10px;
  .card-body {
    padding-bottom: 0px;
    padding-top: 22px !important;
  }
}
.integration-box {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  .field-wrapper {
    width: 1000px;
    max-width: 100%;
  }
  .heading {
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 13px;
  }
}
// integration page end

// feature-request-page
.feature-request-card {
  margin-bottom: 10px;
}
.feature-request-form {
  width: 900px;
  max-width: 100%;
  .normal-text {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }
}
// feature-request-page-end

// change-log-page
.change-log-page {
  & > .card {
    border: none;
    margin-bottom: 20px;
    .card-header {
      background-color: transparent !important;
    }
  }
  .page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-size: 26px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 0px;
    }
  }
  .change-logs-inner-iBox {
    margin-bottom: 22px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 22px;
    .haader-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;
      .icon-wrapper {
        width: 40px;
        height: 40px;
        position: relative;
        border-radius: 10px;
        padding: 7px;
        svg {
          width: 100%;
          height: 100%;
          color: var(--primaryColorDark);
        }
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--primaryColor);
          border-radius: 10px;
          opacity: 0.2;
        }
      }
      .heading {
        font-size: 20px;
        margin-bottom: 0px;
        color: #000000;
        width: calc(100% - 40px);
        padding-left: 14px;
      }
    }
    .rdw-editor-wrapper {
      font-size: 16px;
      color: #000000;
      border: none;
      .rdw-editor-main {
        border: none !important;
      }
      ul {
        margin: 0px;
        padding: 0px 0px;
        li {
          margin: 0px 0px;
          position: relative;
          font-size: 16px;
          color: #000000;
          padding-left: 32px;
          margin-bottom: 6px;
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: var(--primaryColor);
            opacity: 0.2;
          }
          &:after {
            content: '';
            position: absolute;
            top: 7px;
            left: 6px;
            width: 10px;
            height: 6px;
            border-left: 2px solid var(--primaryColorDark);
            border-bottom: 2px solid var(--primaryColorDark);
            transform: rotate(-45deg);
          }
        }
      }
    }
    .change-log-list-wrapper {
      padding-left: 55px;
      margin-left: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: none;
    }
    .rdw-editor-wrapper{
      .rdw-editor-main{
        min-height:unset !important;
      }
    }
  }
  & > .card {
    background-color: transparent;
    box-shadow: unset;
    .card-header {
      background-color: transparent;
      padding: 0px 0px;
      border-bottom: none;
      margin-bottom: 12px !important;
      .card-title {
        font-size: 20px !important;
      }
    }
    & > .card-body {
      padding: 20px 20px !important;
      border-radius: 10px;
      background-color: white;
      border: 1px solid #ececec;
    }
  }

  &.superAdmin{
    overflow:hidden;
    // height:calc(100dvh - 142px);
    // overflow-y:auto;
    // padding-top:70px;
    .card{
      .card-header{
        .card-title{
          width:100%;
        }
        .action-btn-wrapper{
          flex-shrink:0;
          margin-left:12px;
          .action-btn{
            width:26px;
            height:26px;
            border-radius:50%;
            transition:0.5s;
            padding:5px;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-left:5px;
            svg{
              margin:0px !important;
              width:100%;
              height:100%;
            }
            &:hover{
              background-color:#ffffff;
            }
            &:first-child{
              margin-left:0px;
            }
          }
        }
      }
    }
    // .page-header{
    //   padding:0px 15px 15px 15px;
    //   box-shadow: 0px 4px 8px #d2d2d2;
    //   margin-top:0px;
    //   position:absolute;
    //   top:0px;
    //   left:0px;
    //   width: 100%;
    //   z-index:2;
    //   background-color:#f7f6f0;
    // }
    .change-log-body{
      padding:4px 15px 15px 15px;
    }
    &::before{
      bottom: 76px;
    }
  }
  .e-richtexteditor{
    border:none !important;
    .e-rte-content{
      .e-content{
        min-height:unset !important;
        padding:0px 0px !important;
      }
    }
  }
}

.log-details-form{
  & > .card-body{
    padding-top:16px !important;
  }
  .form-wrapper{
    width:700px;
    max-width:100%;
    input.form-control{
      background-color:#ffffff !important;
    }
    .rdw-editor-wrapper{
      .rdw-editor-main{
        background-color:#ffffff;
      }
    }
  }
}
// change-log-page-end

// new-ui css end
// feature-request-preview modal end

.task-sidebar-accordian {
  .task-sidebar-accordian-header {
    position: relative;
    padding-right: 47px !important;
    .down-arrow,
    .up-arrow {
      position: absolute;
      top: -2px;
      right: 16px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .accordion-body {
    padding: 0px;
  }
}
.todo-task-list.accordion {
  .todo__list_left.main-accordian-wrapper {
    position: relative;
    margin: 0px !important;
    padding: 10px 15px 10px 30px;
    .up-arrow,
    .down-arrow {
      position: absolute;
      top: 8px;
      left: 10px;
      cursor: pointer;
    }
  }
  .accordion-body {
    padding-left: 0px;
  }
}

.todo-task-list.category {
  height: calc(100dvh - 232px);
  .accordion-item {
    border-bottom: 1px solid #ebe9f1;
  }
  .todo__list_left {
    position: relative;
    padding-left: 22px;
    padding-top: 8px;
    padding-bottom: 8px;
    .up-arrow {
      position: absolute;
      top: 7px;
      left: 0px;
      cursor: pointer;
    }
  }
}

.search-in-subtask {
  padding-right: 20px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  .form-label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
  }
  input {
    margin-left: 8px;
    position: relative;
    top: 0px;
    border: 1px solid grey !important;
    &:checked {
      border: 1px solid var(--primaryColor) !important;
      background-color: var(--primaryColor) !important;
      background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3e%3cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3e%3c/svg%3e") !important;
    }
  }
}

.avatar img {
  border-radius: 50%;
  object-fit: contain;
  background: white;
}

.hide-toolbar-options {
  display: none;
}

.site-inner-loader-wrapper {
  position: relative;
  .site-loader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.2;
    }
    .inner-wrapper {
      position: relative;
      z-index: 2;
    }
  }
}

// email-modual-design-start
.email-main-round-loader-wrapper {
  height: calc(100dvh - 155px);
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #eae8e1;
  .spinner-border {
    width: 45px;
    height: 45px;
  }
  .mail-syncing-loader-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      text-align: center;
      display: block;
      width: 100%;
      margin-bottom: 15px;
      padding-left: 13px;
    }
  }
  .dot-flashing {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    background-color: var(--primaryColor);
    color: var(--primaryColor);
    animation: dot-flashing-2 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin-left: 17px;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -23px;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    background-color: var(--primaryColor);
    color: var(--primaryColor);
    animation: dot-flashing-2 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 23px;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    background-color: var(--primaryColor);
    color: var(--primaryColor);
    animation: dot-flashing-2 1s infinite alternate;
    animation-delay: 1s;
  }
  @keyframes dot-flashing-2 {
    0% {
      background-color: var(--primaryColor);
    }
    50%,
    100% {
      background-color: rgba(163, 219, 89, 0.4);
    }
  }
}

.email-application {
  .content-area-wrapper {
    border: none !important;
    border-radius: 0px !important;
  }
}
.email-modual {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid #eae8e1;
  border-radius: 10px;
  overflow: hidden;
  .sidebar-left {
    float: none;
    width: 260px;
    height: calc(100dvh - 140px);
    .collapse__btn {
      z-index: 3;
      position: absolute;
      width: 32px;
      height: 32px;
      top: 17px;
      background-color: var(--primaryColor);
      cursor: pointer;
      border-radius: 50%;
      right: -17px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #000000;
        left: calc(50% + 2px);
        border-left: 2px solid #000000;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    .close-btn {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 18px;
      right: -16px;
      background-color: var(--primaryColor);
      border-radius: 50%;
      display: none;
      &:before {
        content: '';
        position: absolute;
        top: 11px;
        width: 8px;
        height: 8px;
        left: 10px;
        transform: rotate(-135deg);
        border-left: 2px solid #000000;
        border-bottom: 2px solid #000000;
      }
    }
    .compose-btn-wrapper {
      padding: 15px 25px 15px 15px;
      .compose-btn {
        svg {
          margin-right: 5px;
          position: relative;
          top: -1px;
        }
      }
    }
    .sidebar-menu-list {
      max-height: calc(100% - 71px);
      height: auto;
      overflow-y: auto;
      .list-group {
        border-radius: 0px;
        .list-group-item {
          border: none !important;
          padding: 8px 18px 10px 18px;
          margin-bottom: 4px;
          position: relative;
          display: flex;
          align-items: center;
          .icon__wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            flex-shrink: 0;
            margin-right: 8px;
            display: none;
            svg {
              width: 100%;
              height: 100%;
              fill: #000000;
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
          svg {
            color: #000000;
            margin-right: 4px;
            position: relative;
            z-index: 2;
          }
          span {
            color: #000000;
            font-weight: 600;
            position: relative;
            z-index: 2;
          }
          .menu-name {
            width: 100%;
            max-width: 100%;
            white-space: pre;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .badge {
            margin: 0px !important;
            margin-left: 10px !important;
            padding:0px 0px 0px 0px;
            font-size: 14px;
            background-color:transparent !important;
          }
          &:hover {
            background-color: #f8f8f8;
          }
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            height: 100%;
            width: 4px;
            background-color: var(--primaryColor);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            opacity: 0;
            transition: 0.3;
          }
          &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            background-color: var(--primaryColor);
            opacity: 0;
            transition: 0.2;
          }
          &.active {
            background-color: transparent;
            &:before {
              opacity: 1;
            }
            &:after {
              opacity: 0.3;
            }
          }
        }
      }
      .ps__rail-y {
        z-index: 3;
      }
    }
  }
  .content-right {
    float: none;
    width: calc(100% - 260px);
    height: calc(100dvh - 140px);
    position: relative;
    border-left: 1px solid #ebe9f1 !important;
    .top-header-wrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eae8e1;
      padding-right: 13px;
    }
    .top-header-wrapper + input{
      display:none;
    }
    .reset-btn-wrapper {
      display: inline-block;
      z-index: 3;
      .refresh-btn {
        background-color: #000000 !important;
        border: 1px solid #000000 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          path {
            fill: #ffffff;
          }
        }
        &.loader-active {
          padding-right: 22px;
        }
      }
      .dot-flashing {
        position: relative;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: #ffffff;
        color: #ffffff;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
        margin-left: 17px;
      }
      .dot-flashing::before,
      .dot-flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }
      .dot-flashing::before {
        left: -10px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: #ffffff;
        color: #ffffff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }
      .dot-flashing::after {
        left: 10px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: #ffffff;
        color: #ffffff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }
      @keyframes dot-flashing {
        0% {
          background-color: #ffffff;
        }
        50%,
        100% {
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
    }
    .compose-btn {
      width: auto !important;
      margin-left: 10px;
      display: none !important;
    }
    .pagination-count {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }
    .next-prev-btn-wrapper {
      display: inline-flex;
      align-items: center;
      margin: 0px 14px;
      .prev,
      .next {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        transition: 0.5s;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
      .prev {
        margin-right: 5px;
      }
    }
    .top-search-header {
      padding: 8px 14px 8px 28px;
      width: 100%;
      display: flex;
      align-items: center;
      .task-manager-sidebar-toggle-btn {
        display: none;
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-content: center;
        margin-right: 15px;
        cursor: pointer;
        flex-shrink: 0;
        .line {
          display: block;
          width: 100%;
          height: 3px;
          border-radius: 15px;
          background-color: var(--primaryColor);
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0px;
          }
          &:nth-child(2) {
            width: 70%;
          }
        }
      }
      .email-top-search-box {
        width: 100%;
        .search__mail__input {
          border: none;
          font-size: 16px;
          color: #000000 !important;
          padding-left: 28px;
          &::placeholder {
            color: #a6a6a6 !important;
          }
          &:focus {
            box-shadow: unset;
          }
        }
        .form-element-icon-wrapper {
          svg {
            left: 0px;
          }
        }
      }
    }
    .right-wrapper {
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
    }
    .select-all-wrapper {
      padding: 7px 13px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #eae8e1;
      display: flex;
      justify-content: space-between;
      .select-all {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .form-check-input {
          position: relative;
          top: -1px;
          border: 1px solid #dfdfdf;
          box-shadow: inset 0 0 3px #d9d9d9;
          cursor: pointer;
          margin-top: 0px;
          width: 16px;
          height: 16px;
        }
        .form-label {
          margin-bottom: 0px;
          font-size: 15px;
          margin-left: 10px;
          color: #000000;
          font-weight: 500;
        }
      }
      .action-right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .action-icon {
          width: 35px;
          height: 35px;
          display: block;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          &:hover {
            background-color: #e3e3e3;
          }
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
          }
          &.folder-move-icon{
            padding:6px;
          }
          &.filter-icon{
            padding:9px;
          }
          &.split-view-icon{
            padding:9px;
            margin-left:5px;
            svg{
              path{
                fill:#000000 !important;
              }
            }
          }
        }
        .list-inline {
          display: inline-flex;
          align-items: center;
        }
        .list-inline-item {
          margin-right:5px;
          &:last-child {
            margin-right: 0px;
          }
        }
        .action-toggle-btn {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 5px;
          transition: 0.5s;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
          }
          &:hover {
            background-color: #e1e1e1;
          }
        }
        .split-view-btn {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 6px;
          transition: 0.5s;
          border: none;
          background-color: transparent;
          margin-left: 7px;
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: #000000 !important;
            }
          }
          &:hover {
            background-color: #e1e1e1;
          }
        }
      }
    }
    .email-list-wrapper {
      height: calc(100% - 103px);
      &.no-data {
        height: calc(100% - 97px);
      }
      .spinner-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 48px;
        .spinner-border {
          width: 40px;
          height: 40px;
        }
      }
    }
    .email-items {
      padding-left: 0px;
      transition: 0.4s;
      margin-bottom: 0px;
      .mail-item {
        border-bottom: 1px solid #ebe9f1;
        position: relative;
        .inner-wrapper {
          display: flex;
          align-items: center;
          padding: 4px 6px 4px 5px;
          position: relative;
          z-index: 2;
          cursor: pointer;
        }
        .action-wrapper {
          display: flex;
          align-items: center;
          .checkbox-wrapper {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            padding: 8px;
            position: relative;
            cursor: pointer;
            .form-check-input {
              border: 1px solid #dfdfdf;
              box-shadow: inset 0 0 3px #d9d9d9;
              transition: 0.5s;
              width: 100%;
              height: 100%;
              cursor: pointer;
              margin-top: 0px;
            }
            &:hover {
              background-color: #f7f7f7;
              .form-check-input {
                border: 1px solid #000000;
              }
            }
          }
          .email-favorite {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            padding: 7px;
            transition: 0.5s;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
              color: #c6c6c6;
              transition: 0.5s;
              &.favorite {
                fill: $warning;
                stroke: $warning;
                // fill:var(--primaryColor);
                // stroke:var(--primaryColor);
              }
            }
            &:hover {
              background-color: #ffffff;
              svg {
                color: #000000;
              }
            }
          }
        }
        .details-wrapper {
          width: calc(100% - 65px);
          display: flex;
          align-items: center;
          padding-left: 10px;
          .title-wrapper {
            display: inline-block;
            width: 250px;
            flex-shrink: 0;
            white-space: pre;
            overflow: hidden;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            .name {
              font-size: 14px;
              font-weight: 500;
              color: #595959;
              margin-bottom: 0px;
              margin-right: 5px;
              &:empty {
                display: none;
              }
            }
            .subject {
              font-size: 14px;
              font-weight: 400;
              color: grey;
            }
          }
          .description-wrapper {
            width: calc(100% - 332px);
            padding-left: 12px;
            display: inline-flex;
            .subject {
              font-size: 14px;
              font-weight: 500;
              color: #595959;
              margin-right: 7px;
              white-space: pre;
              overflow: hidden;
              max-width: 100%;
              flex-shrink: 0;
              text-overflow: ellipsis;
            }
            .text {
              white-space: pre;
              overflow: hidden;
              max-width: 100%;
              // width:100%;
              // white-space:pre;
              display: inline-block;
              // overflow:hidden;
              // text-overflow:ellipsis;
              margin-bottom: 0px;
              text-overflow: ellipsis;
            }
          }
          .date-wrapper {
            font-size: 14px;
            font-weight: 500;
            color: #595959;
            white-space: pre;
            padding-left: 12px;
            width: 80px;
            text-align: left;
            flex-shrink: 0;
          }
        }
        &:hover {
          box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
            0 1px 2px 0 rgba(60, 64, 67, 0.3),
            0 1px 3px 1px rgba(60, 64, 67, 0.15);
          position: relative;
          z-index: 2;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--primaryColor);
          opacity: 0.1;
        }
        &.mail-read {
          .details-wrapper {
            .title-wrapper {
              .name {
                color: #000000;
                font-weight: 600;
              }
            }
            .description-wrapper {
              .subject {
                color: #000000;
                font-weight: 600;
              }
            }
            .date-wrapper {
              color: #000000;
            }
          }
          .email-favorite {
            &:hover {
              background-color: #f7f7f7;
            }
          }
          &::before {
            opacity: 0;
          }
        }
        &.active{
          box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
            0 1px 2px 0 rgba(60, 64, 67, 0.3),
            0 1px 3px 1px rgba(60, 64, 67, 0.15);
            &:after{
              content:"";
              position:absolute;
              top:0px;
              left:0px;
              width:3px;
              height:100%;
              z-index:4;
              background-color:var(--primaryColor);
            }
        }
      }
    }
    .no-record-wrapper {
      min-height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 35px 0px;
    }
  }
  &.sidebar-collapse {
    .sidebar-left {
      width: 80px;
      .collapse__btn {
        transform: rotate(180deg);
      }
      .compose-btn-wrapper {
        .compose-btn {
          height: 40px;
          font-size: 0px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          padding: 6px;
          svg {
            margin-right: 0px !important;
            width: 100%;
            height: 100%;
          }
        }
      }
      .sidebar-menu-list {
        .list-group {
          .list-group-item {
            justify-content: center;
            padding: 7px 18px 9px 18px;
            .icon__wrapper {
              display: none;
            }
            .badge {
              display: none;
            }
            .icon__wrapper {
              margin-right: 0px;
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
    .content-right {
      width: calc(100% - 80px) !important;
    }
  }
  &.right-of-index {
    .content-body {
      display: flex;
      flex-wrap: wrap;
      .top-header-wrapper,
      .select-all-wrapper {
        width: 100%;
      }
      .email-list-wrapper {
        width: 400px;
        height: calc(100% - 103px);
        .no-record-found-table {
          padding: 0px 20px;
          .img-wrapper {
            img {
              width: 250px;
              max-width: 100%;
            }
          }
        }
        .email-items {
          .mail-item {
            .inner-wrapper {
              padding: 8px 10px 10px 10px;
              align-items: flex-start;
              .action-wrapper {
                width: 16px;
                position: static;
                .checkbox-wrapper {
                  padding: 0px 0px;
                  width: 16px;
                  height: 16px;
                  position: relative;
                  top: 2px;
                }
                .email-favorite-wrapper {
                  position: absolute;
                  bottom: 10px;
                  right: 10px;
                  .email-favorite {
                    width: 16px;
                    height: 16px;
                    padding: 0px;
                    svg {
                      color: #919191;
                    }
                    &:hover {
                      background-color: transparent;
                      svg {
                        color: #000000;
                      }
                    }
                  }
                }
              }
              .details-wrapper {
                width: calc(100% - 16px);
                padding-left: 10px;
                flex-wrap: wrap;
                .title-wrapper {
                  order: 1;
                  width: calc(100% - 55px);
                  padding-right: 5px;
                  .name {
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 0px;
                    width: 100%;
                  }
                }
                .description-wrapper {
                  order: 3;
                  width: 100%;
                  padding-left: 0px;
                  margin-top: 4px;
                  .subject {
                    width: calc(100% - 24px);
                    margin-right: 0px !important;
                    font-size: 13px;
                    font-weight: normal;
                    color: #595959;
                  }
                }
                .date-wrapper {
                  text-align: right;
                  width: 55px;
                  padding-left: 0px;
                  order: 2;
                }
              }
            }
          }
        }
      }
      .email-details-page {
        width: calc(100% - 400px);
        border-left: 1px solid #eae8e1;
        border-radius: 0px;
        .email-subject {
          padding: 0px 15px;
        }
        .email-detail-box {
          padding: 15px 15px;
          &:first-child {
            padding-top: 0px;
          }
          .email-detail-head {
            .left {
              .avatar {
                width: 38px;
                height: 38px;
                .avatar-content {
                  font-size: 14px;
                }
              }
              .mail-items {
                padding-left: 12px;
              }
              .name-mail {
                margin-bottom: 0px;
                .name,
                .mail {
                  font-size: 14px;
                }
              }
              .to-me-wrapper {
                .text {
                  font-size: 14px;
                }
              }
            }
            .right {
              .action-icon,
              .action-toggle-btn {
                width: 32px;
                height: 32px;
                border-radius: 50%;
              }
              .action-icon {
                padding: 8px;
              }
              .action-toggle-btn {
                padding: 7px;
              }
            }
          }
        }
        .mail-message-wrapper {
          padding: 10px 0px 0px 50px;
        }
        .email-details-page-scroll-area {
          margin-bottom: 0px;
          height: calc(100dvh - 362px);
        }
        .reply-forward-btn-wrapper {
          padding: 20px 20px 20px 65px;
          margin-bottom: 10px;
        }
        &.no-data {
          .email-details-page-scroll-area {
            height: calc(100dvh - 244px);
            padding: 20px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .no-record-found-table {
              .img-wrapper {
                img {
                  width: 300px;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .reply-mail-form {
      position: relative;
      left: -50px;
      width: calc(100% + 50px);
    }
    .mail-task-preview {
      position: relative;
      left: -50px;
      width: calc(100% + 50px);
      .fr-sticky-dummy:empty {
        display: none;
      }
      .task__top__details__col {
        width: 50%;
        &.after-task {
          padding: 0px 0px;
          width: 100%;
          .inner-wrapper {
            align-items: flex-start;
            textarea.form-control {
              border: 1px solid #eaeaea;
              border-radius: 6px;
              padding: 7px 12px 10px 12px !important;
              font-weight: normal;
              &:focus {
                border: 1px solid var(--primaryColor);
              }
            }
          }
        }
      }
      .description-textarea {
        margin-bottom: 10px;
        .fr-box {
          border: 1px solid #ebe9f1;
          overflow: hidden;
          &.fr-basic {
            .fr-wrapper {
              border: none;
            }
            .fr-element {
              font-size: 15px;
              padding: 16px 16px;
              min-height: 220px;
            }
          }
          .fr-toolbar {
            border: none;
            border-radius: 0px;
            border-top: 1px solid #ebe9f1;
            border-bottom: 1px solid #ebe9f1;
            padding: 7px 16px 7px 16px;
            background-color: #f7f7f7;
          }
          .fr-newline {
            display: none;
          }
          .fr-btn-grp {
            margin: 0px 0px;
            display: flex;
            align-items: center;
            .fr-command.fr-btn {
              margin: 0px 5px 0px 0px;
              width: 32px;
              height: 32px;
              padding: 5px;
              .fr-svg {
                margin: 0px !important;
                // width: 100%;
                // height: 100%;
              }
              .fr-sr-only {
                display: none;
              }
              &:hover {
                background-color: #ffffff;
              }
              &:last-child {
                margin: 0px 0px 0px 0px;
              }
            }
            .fr-command.fr-btn[aria-pressed='true'] {
              .fr-svg {
                path {
                  fill: var(--primaryColorDark);
                }
              }
            }
          }

          .fr-view table td,
          .fr-view table th {
            border: unset !important;
            background: unset !important;
          }
          .fr-view blockquote {
            color: #000000 !important;
          }
          .fr-second-toolbar {
            display: none;
          }
        }
      }
      .attachment-save-btn-wrapper {
        .custom-fileupload {
          .attachment-wrapper {
            .file__card {
              width: 200px;
              max-width: 25%;
            }
          }
        }
      }
    }
  }
}

// email-setting-page
.email-setting-page {
  width: 100%;
  .loader-wrapper {
    width: 100%;
    height: calc(100dvh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border {
      width: 60px;
      height: 60px;
    }
  }
  .radio-btn-wrapper {
    .radio-btn-repeater {
      margin: 0px !important;
      padding: 0px;
      position: relative;
      margin-right: 20px !important;
      background-color: #f2f2f2;
      border-radius: 10px;
      padding: 14px 56px 14px 50px;
      position: relative;
      input {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 3;
        cursor: pointer;
        opacity: 0;
        margin: 0px !important;
      }
      .form-check-label {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 17px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: #d5d5d5 0px 0px 1px 2px inset;
          transition: 0.5s;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 23px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: white;
          opacity: 0;
          transition: 0.5s;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 24px;
        height: 24px;
        background-size: 100%;
        background-position: center;
      }
      &:first-child {
        &:before {
          background-image: url('../images/emailFullViewIcon.png');
        }
      }
      &:last-child {
        &:before {
          background-image: url('../images/emailSplitViewIcon.png');
        }
      }
    }
    .radio-btn-repeater input:checked + .form-check-label {
      &:before {
        background-color: var(--primaryColor);
        box-shadow: unset;
      }
      &:after {
        opacity: 1;
      }
    }
  }
  .custom-select__multi-value {
    position: relative;
    background-color: transparent;
    border-radius: 100px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      border-radius: 100px;
      opacity: 0.2;
    }
    .custom-select__multi-value__label {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      padding: 5px 32px 8px 13px;
    }
    .custom-select__multi-value__remove {
      width: 20px;
      height: 20px;
      border-radius: 50% !important;
      padding: 2px !important;
      transition: 0.4s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 9px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: 100%;
      }
      &:hover {
        background-color: #ffffff !important;
      }
    }
  }
  .add-emails-row {
    margin: 0px -15px;
    width: 1000px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    .add-emails-col {
      width: 50%;
      padding: 0px 15px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 7px;
      }
    }
  }
  .save-btn-wrapper {
    margin-top: 20px;
    .save-btn {
      width: 150px;
    }
  }
  .contant-wrapper {
    width:991px;
    max-width: 100%;
    .sec-heading {
      font-size: 18px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .sec-wrapper {
      margin-bottom: 20px;
      padding: 18px;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      background-color: white;
      &:nth-child(3) {
        margin-bottom: 0px;
      }
    }
  }
}
// email-setting-page-end

// compose-email-modal-start
.integration-box.email-connection {
  .connect-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 15px;
    .details {
      width: calc(100% - 193px);
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-wrapper {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
      }
      .details-text {
        width: calc(100% - 82px);
        padding-left: 20px;
        .title {
          font-size: 18px;
          font-weight: 700;
          color: #000000;
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          color: grey;
          margin-bottom: 0px;
        }
      }
    }
  }
  .connected-account-wrapper {
    background-color: #fff;
    border-radius: 15px;
    margin-top: 15px;
    .ca-header {
      padding: 20px 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid #ebe9f1;
      background-color: #ececec;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .icon-wrapper {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--primaryColor);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
      }
      .right-text {
        width: calc(100% - 36px);
        padding-left: 15px;
        .title {
          font-size: 18px;
          font-weight: 700;
          color: #000000;
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          color: grey;
          margin-left: 10px;
        }
      }
    }
    .connected-item {
      display: flex;
      // flex-wrap:wrap;
      align-items: center;
      padding: 12px 16px 14px 16px;
      border-bottom: 1px solid #ebe9f1;
      &:last-child {
        border-bottom: none;
      }
      .imap-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 90px;
        flex-shrink: 0;
        .icon-wrapper {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
          }
        }
        .title {
          font-size: 18px;
          color: #595757;
          margin-bottom: 0px;
          padding-left: 8px;
          font-weight: 600;
        }
      }
      .user-wrapper {
        display: flex;
        // flex-wrap:wrap;
        align-items: center;
        // width:calc(100% - 210px);
        width: 100%;
        padding: 0px 15px;
        .profile-wrapper {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            border: 2px solid #f7f7f7;
          }
          .name-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: var(--primaryColor);
            color: #000000;
            font-size: 14px;
            font-weight: 700;
          }
        }
        .email {
          font-size: 16px;
          margin-bottom: 0px;
          color: #595757;
          padding-left: 8px;
          width: calc(100% - 40px);
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .action-btn-wrapper {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .action-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          padding: 7px;
          margin-right: 6px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
          }
          &:last-child {
            margin-right: 0px;
          }
          &.close-btn {
            padding: 5px;
          }
          &.refresh-btn {
            svg {
              path {
                fill: #000000 !important;
              }
            }
          }
          &:hover {
            background-color: #eeeeee;
          }
        }
      }
    }
  }
}

.modal.modal-sticky.compose-mail-modal .modal-dialog {
  margin: 0px !important;
}
.compose-mail-modal {
  width: 650px;
  right: 30px !important;
  bottom: 0px !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  .modal-dialog {
    width: 100%;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .modal-header {
    background-color: transparent;
    position: relative;
    padding: 10px 15px;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.3;
    }
  }
  .modal-title {
    position: relative;
    z-index: 2;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #000000 !important;
  }
  .action-btn-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    .action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 6px;
      margin-right: 6px;
      transition: 0.5s;
      svg {
        width: 100%;
        height: 100%;
        color: #000000;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.maximize-btn {
        padding: 8px;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .compose-form {
    .compose-mail-fields {
      position: relative;
      padding: 0px 10px 0px 33px;
      border-bottom: 1px solid #ebe9f1;
      .form-label {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        margin-bottom: 0px;
        z-index: 2;
      }
      .cc-bcc-btns-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        .cc-btn,
        .bcc-btn {
          font-size: 16px;
          font-weight: 600;
          color: grey;
          transition: 0.5s;
          &:hover {
            color: var(--primaryColorDark);
            text-decoration: underline;
          }
        }
        .cc-btn {
          margin-right: 9px;
        }
      }
      .custom-select__control {
        border: none !important;
        .custom-select__indicators {
          display: none !important;
        }
      }
      .custom-select__control.custom-select__control--is-focused {
        box-shadow: unset !important;
      }
      .custom-select__menu {
        margin-top: 0px;
        .compose-mail-row {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .name {
            font-size: 15px;
            font-weight: 500;
            color: #000000;
            width: calc(100% - 25px);
            padding-left: 12px;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }
        }
      }
      .cc-bcc-close-btn-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .close-btn {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transition: 0.5s;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
            color: grey;
          }
          &:hover {
            background-color: #f7f7f7;
            svg {
              color: #000000;
            }
          }
        }
      }
      &.compose-mail-fields-to {
        padding: 0px 86px 0px 33px;
        z-index: 5;
      }
      &.compose-mail-fields-cc {
        padding: 0px 48px 0px 35px;
        z-index: 4;
        .cc-bcc-close-btn-wrapper {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 8px;
        }
      }
      &.compose-mail-fields-bcc {
        padding: 0px 48px 0px 44px;
        z-index: 3;
        .cc-bcc-close-btn-wrapper {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 8px;
        }
      }
      &.compose-mail-fields-subject {
        padding: 0px 15px 0px 78px;
        .form-control {
          border: none !important;
          font-size: 15px;
          &:focus {
            box-shadow: unset;
          }
        }
      }
      &.error {
        .invalid-feedback {
          display: none;
        }
      }
    }
    .message-body-wrapper {
      position: relative;
      max-height: calc(100dvh - 280px);
      overflow-y: auto;
      // padding-bottom: 56px;
      &.error {
        padding-bottom: 87px;
      }
      .rdw-editor-wrapper {
        border: none;
        border-radius: 0px;
        .rdw-editor-main {
          border: none !important;
          max-height: 340px !important;
        }
        .rdw-editor-main {
          min-height: 340px !important;
          font-size: 15px;
          padding: 10px 13px 10px 13px;
        }
        .rdw-editor-toolbar {
          order: 2;
          border-top: 1px solid #ebe9f1 !important;
          border-radius: 0px !important;
          padding: 7px 10px 3px 10px !important;
          background-color: #f7f7f7;
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          width: 100%;
          .rdw-option-wrapper {
            background-color: #f7f7f7;
            &:hover {
              background-color: #ffffff;
            }
          }
        }
      }
      .invalid-feedback {
        position: absolute;
        bottom: 48px;
        font-size: 16px;
        padding: 10px 15px;
        margin-top: 0px;
      }
    }
    .attachment-wrapper {
      margin: 0px 0px 10px 0px;
      .attachment-row {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 15px;
        .attachment-icon {
          width: 28px;
          height: 28px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
          .skeletonBox {
            width: 100%;
            height: 100%;
          }
        }
        .attachment-name {
          font-size: 16px;
          color: #000000;
          width: 100%;
          font-weight: 500;
          padding-left: 10px;
          padding-right: 10px;
          width: calc(100% - 157px);
          white-space:pre;
          overflow:hidden;
          text-overflow:ellipsis;
        }
        .attachment-size {
          font-size: 16px;
          color: #000000;
          width: 100%;
          font-weight: 500;
          width: 95px;
          flex-shrink: 0;
        }
        .attachment-close {
          width: 30px;
          height: 30px;
          margin-left: 10px;
          border-radius: 50%;
          padding: 5px;
          transition: 0.5s;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
  .compose-footer-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 14px 15px 14px 12px !important;
    background-color: #ffffff;
    border-top:1px solid #dee2e6;
    .action-btn {
      width: 32px;
      width: 32px;
      padding: 6px;
      border-radius: 5px;
      margin-right: 4px;
      cursor: pointer !important;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background-color: #eeeeee;
      }
      &.upload-btn {
        position: relative;
        input {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    .send__btn__group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .dropup .dropdown-toggle::after {
      filter: grayscale(1) brightness(0);
    }
    .send__btn__group{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .create-task{
        display: flex;
        align-items: center;
        padding-right: 18px;
        .label{
          font-weight: 500;
          display: inline-block;
          margin-right: 10px;
        }
      }
      .save-as-btn{
        border-radius: 7px !important;
      }
    }
  }
}
body{
  .compose-mail-modal{
    .e-richtexteditor{
      border-top:none !important;
      border-radius:0px !important;
      border-bottom:none !important;
    }
  }
}
// compose-email-modal-end

// error-modal
.compose-error-modal {
  .error-text {
    font-size: 18px;
    color: #000000;
  }
}
// error-modal-end

// email-details-page
.email-details-page {
  background-color: #ffffff;
  border-radius: 10px;
  .loader-wrapper {
    height: calc(100dvh - 214px);
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border {
      width: 45px;
      height: 45px;
    }
  }
  .email-details-page-scroll-area {
    height: calc(100dvh - 257px);
    margin-bottom: 8px;
    overflow-y: auto;
    .ps__rail-x {
      display: none !important;
    }
  }
  .email-detail-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px 12px 15px;
    .email-header-left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .back-arrow {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        transition: 0.5s;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
      .social-icons {
        padding-left: 15px;
        margin-left: 15px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
          height: calc(100% - 10px);
          width: 1px;
          background-color: #ebe9f1;
        }
        .social-icons-list {
          padding-left: 0px;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          .list-inline-item {
            margin-right: 7px;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child {
              margin-right: 0px;
            }
          }
          .action-icon {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px;
            transition: 0.5s;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
              color: #000000;
              &.favorite {
                fill: $warning;
                stroke: $warning;
              }
            }
            &:hover {
              background-color: #eeeeee;
            }
            &.move-to{
              padding:7px;
            }
          }
        }
      }
    }
    .email-header-right {
      display: inline-flex;
      align-items: center;
      .prev,
      .next {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        transition: 0.5s;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
      .prev {
        margin-right: 5px;
      }
    }
  }
  .email-subject {
    margin-bottom: 0px;
    padding: 0px 24px;
    font-size: 21px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 24px;
    margin-top: 7px;
  }
  .email-detail-box {
    padding: 20px 20px;
    border-bottom: 1px solid #ebe9f1;
    .email-detail-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(100% - 37px);
        padding-right: 20px;
        .avatar {
          width: 50px;
          height: 50px;
          background-color: var(--primaryColor);
          display: flex;
          align-items: center;
          justify-content: center;
          .avatar-content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 50px;
            font-size: 16px;
            font-weight: 700;
            color: #000000;
          }
        }
        .mail-items {
          padding-left: 15px;
        }
        .name-mail {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 4px;
          .name {
            font-size: 16px;
            font-weight: 700;
            color: #000000;
            margin-bottom: 0px;
            padding-right: 7px;
          }
          .mail {
            font-size: 16px;
            font-weight: 500;
            color: grey;
            margin-bottom: 0px;
          }
        }
        .to-me-wrapper {
          display: flex;
          align-items: center;
          .text {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            color: grey;
            margin-bottom: 0px;
          }
          .email-info-dropup {
            display: inline-flex;
            margin-left: 5px;
            .dropdown-toggle {
              width: 18px;
              height: 18px;
              position: relative;
              border-radius: 3px;
              transition: 0.5s;
              display: inline-block;
              &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: 4px;
                width: 0px;
                height: 0px;
                border: 5px solid transparent;
                border-top: 5px solid #000000;
              }
              &:after {
                display: none;
              }
              &:hover {
                background-color: #eeeeee;
              }
            }
            .dropdown-menu {
              background-color: #fff;
              border: 1px solid #eae8e1;
              border-radius: 10px;
              padding: 10px 16px;
              table {
                margin-bottom: 0px;
              }
              td {
                font-size: 14px;
                padding: 4px 6px 4px 0px;
                color: grey !important;
                &:first-child {
                  padding-right: 12px;
                }
                &:last-child {
                  color: #000000 !important;
                  padding: 4px 0px 4px 0px;
                }
              }
            }
          }
        }
      }
      .right {
        .action-icon {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
            &.favorite {
              fill: $warning;
              stroke: $warning;
            }
          }
          &:hover {
            background-color: #eeeeee;
          }
        }
        display: inline-flex;
        .action-toggle-btn {
          width: 36px;
          height: 36px;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px;
          transition: 0.5s;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
          }
          &:hover {
            background-color: #eeeeee;
          }
        }
      }
    }
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .mail-message-wrapper {
    padding: 15px 0px 0px 65px;
  }
  .mail-attachments-wrapper {
    display: flex;
    flex-wrap: wrap;
    .mail-attachments-header {
      width: 100%;
    }
    .mail-attachment-item {
      width: 200px;
      margin-right: 15px;
      margin-bottom: 15px;
      .inner-wrapper {
        width: 100%;
        a,
        .img-wrapper {
          width: 100%;
          padding-top: 70%;
          position: relative;
          display: block;
          border: 1px solid #dbdbdb;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          overflow: hidden;
          img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          .overllay-action-btn {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            transition: 0.5s;
            .action-btn {
              width: 36px;
              height: 36px;
              border: none;
              padding: 7px !important;
              background-color: transparent;
              transition: 0.5s;
              border-radius: 50%;
              svg {
                width: 100%;
                height: 100%;
                color: #ffffff;
                transition: 0.5s;
              }
              &:last-child {
                margin-left: 6px;
              }
              &:hover {
                background-color: rgba(255, 255, 255, 0.2);
              }
            }
          }
          &:hover {
            .overllay-action-btn {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
        .attachment-name {
          font-size: 16px;
          color: black;
          padding: 6px 10px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 1px solid #dbdbdb;
          border-top: none;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          width: 100%;
          display: block;
        }
      }
    }
  }
  .reply-forward-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 20px 20px 85px;
    margin-bottom: 60px;
    .reply-btn,
    .forward-btn {
      display: inline-block;
      padding: 8px 24px;
      border-radius: 7px;
      border: 1px solid #bdbdbd;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      transition: 0.5s;
      svg {
        color: #000000;
        width: 16px;
        height: 16px;
        margin-right: 7px;
        position: relative;
        top: -2px;
        transition: 0.5s;
      }
      &:hover {
        background-color: #000000;
        color: #ffffff;
        border: 1px solid #000000;
        svg {
          color: #ffffff;
        }
      }
    }
    .reply-btn {
      margin-right: 15px;
    }
  }
}

.reply-mail-form {
  border: 1px solid #ebe9f1;
  border-radius: 8px;
  // padding: 10px 16px 16px 16px;
  padding: 10px 16px 0px 16px;
  margin-top: 20px;
  border-bottom: none;
  .mobile-header,
  .mobile-footer{
    display:none;
  }
  .fr-sticky-dummy {
    height: 0px !important;
  }
  .compose-mail-fields {
    position: relative;
    padding: 0px 10px 0px 33px;
    border-bottom: 1px solid #ebe9f1;
    .form-label {
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      margin-bottom: 0px;
      z-index: 2;
    }
    .cc-bcc-btns-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4px;
      .cc-btn,
      .bcc-btn {
        font-size: 16px;
        font-weight: 600;
        color: grey;
        transition: 0.5s;
        &:hover {
          color: var(--primaryColorDark);
          text-decoration: underline;
        }
      }
      .cc-btn {
        margin-right: 9px;
      }
    }
    .custom-select__control {
      border: none !important;
      .custom-select__indicators {
        display: none !important;
      }
    }
    .custom-select__control.custom-select__control--is-focused {
      box-shadow: unset !important;
    }
    .custom-select__menu {
      margin-top: 0px;
      .compose-mail-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .avatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .name {
          font-size: 15px;
          font-weight: 500;
          color: #000000;
          width: calc(100% - 25px);
          padding-left: 12px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
    .cc-bcc-close-btn-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .close-btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: 0.5s;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
          color: grey;
        }
        &:hover {
          background-color: #f7f7f7;
          svg {
            color: #000000;
          }
        }
      }
    }
    &.compose-mail-fields-to {
      padding: 0px 75px 0px 24px;
      z-index: 5;
    }
    &.compose-mail-fields-cc {
      padding: 0px 42px 0px 35px;
      z-index: 4;
      .cc-bcc-close-btn-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
      }
    }
    &.compose-mail-fields-bcc {
      padding: 0px 42px 0px 44px;
      z-index: 3;
      .cc-bcc-close-btn-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
      }
    }
    &.compose-mail-fields-subject {
      padding: 0px 15px 0px 78px;
      .form-control {
        border: none !important;
        font-size: 15px;
        &:focus {
          box-shadow: unset;
        }
      }
    }
    &.error {
      .invalid-feedback {
        display: none;
      }
    }
  }
  .message-body-wrapper {
    position: relative;
    // padding-bottom: 56px;
    margin-left: -16px;
    margin-right: -16px;
    margin-top:15px;
    &.error {
      padding-bottom: 87px;
    }
    .rdw-editor-wrapper {
      border: none;
      border-radius: 0px;
      .rdw-editor-main {
        border: none !important;
        max-height: 340px !important;
      }
      .rdw-editor-main {
        min-height: 240px !important;
        font-size: 15px;
        padding: 10px 15px 10px 15px;
      }
      .rdw-editor-toolbar {
        order: 2;
        border-top: 1px solid #ebe9f1 !important;
        border-radius: 0px !important;
        padding: 7px 10px 3px 10px !important;
        background-color: #f7f7f7;
        .rdw-option-wrapper {
          background-color: #f7f7f7;
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
    .invalid-feedback {
      position: absolute;
      bottom: 48px;
      font-size: 16px;
      padding: 10px 15px;
      margin-top: 0px;
    }
  }
  .attachment-wrapper {
    margin: 0px 0px;
    padding: 16px 16px 16px 16px;
    &:empty {
      padding: 0px 16px 0px 16px;
    }
    .attachment-row {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 0px;
      .attachment-icon {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
        .skeletonBox {
          width: 100%;
          height: 100%;
        }
      }
      .attachment-name {
        font-size: 16px;
        color: #000000;
        width: 100%;
        font-weight: 500;
        padding-left: 10px;
        padding-right: 10px;
      }
      .attachment-size {
        font-size: 16px;
        color: #000000;
        width: 100%;
        font-weight: 500;
        width: 95px;
        flex-shrink: 0;
      }
      .attachment-close {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        border-radius: 50%;
        padding: 5px;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  .action-btn-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    .action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 6px;
      margin-right: 6px;
      transition: 0.5s;
      svg {
        width: 100%;
        height: 100%;
        color: #000000;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.maximize-btn {
        padding: 8px;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .compose-footer-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    // padding: 14px 0px 0px 0px !important;
    // background-color: #ffffff;
    padding: 12px 16px 12px 16px !important;
    margin: 0px -16px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebe9f1;
    .action-btn {
      width: 32px;
      width: 32px;
      padding: 6px;
      border-radius: 5px;
      margin-right: 4px;
      cursor: pointer !important;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background-color: #eeeeee;
      }
      &.upload-btn {
        position: relative;
        input {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    .send__btn__group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .create-task {
        display: flex;
        align-items: center;
        padding-right: 18px;
        .label {
          font-weight: 500;
          display: inline-block;
          margin-right: 10px;
        }
      }
      .save-as-btn {
        border-radius: 7px !important;
      }
    }
    .dropup .dropdown-toggle::after {
      filter: grayscale(1) brightness(0);
    }
  }
  .fr-box {
    &.fr-basic {
      .fr-wrapper {
        border: none;
      }
      .fr-element {
        font-size: 15px;
        padding: 16px 16px;
        min-height: 220px;
      }
    }
    .fr-toolbar {
      border: none;
      border-radius: 0px;
      border-top: 1px solid #ebe9f1;
      border-bottom: 1px solid #ebe9f1;
      padding: 7px 16px 7px 16px;
      background-color: #f7f7f7;
    }
    .fr-newline {
      display: none;
    }
    .fr-btn-grp {
      margin: 0px 0px;
      display: flex;
      align-items: center;
      .fr-command.fr-btn {
        margin: 0px 5px 0px 0px;
        width: 32px;
        height: 32px;
        padding: 5px;
        .fr-svg {
          margin: 0px !important;
          // width: 100%;
          // height: 100%;
        }
        .fr-sr-only {
          display: none;
        }
        &:hover {
          background-color: #ffffff;
        }
        &:last-child {
          margin: 0px 0px 0px 0px;
        }
      }
      .fr-command.fr-btn[aria-pressed='true'] {
        .fr-svg {
          path {
            fill: var(--primaryColorDark);
          }
        }
      }
    }

    .fr-view table td,
    .fr-view table th {
      border: unset !important;
      background: unset !important;
    }
    .fr-view blockquote {
      color: #000000 !important;
    }
    .fr-second-toolbar {
      display: none;
    }
  }
  .fr-wrapper {
    & > div[style] {
      display: none !important;
    }
  }
}
body{
  .reply-mail-form{
    .e-richtexteditor{
      border-radius:0px !important;
    }
  }
}

// mail-task-preview
.mail-task-preview {
  position: relative;
  border: 1px solid #ebe9f1;
  margin-top: -10px;
  padding: 24px 16px 19px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  // background-color: #f7f7f7;
  // &:before{
  //   content:"";
  //   position:absolute;
  //   top:0px;
  //   left:0px;
  //   width:100%;
  //   height:100%;
  //   background-color:var(--primaryColor);
  //   opacity:0.1;
  // }
  // .inner-wrapper{
  //   position:relative;
  //   z-index:2;
  // }
  .mobile-top-header{
    display:none;
    align-items:center;
    margin-bottom:12px;
    box-shadow: 0px 2px 12px #bdbdbd;
    padding:7px 10px 7px 8px;
    margin-bottom:14px;
    .back-btn{
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      border-radius: 50%;
      padding: 4px;
      border:none;
      background-color:transparent;
      margin-right:13px;
      svg{
        color: #000000 !important;
        width: 100%;
        height: 100%;
      }
      &:hover{
        background-color:#eeeeee;
      }
    }
    .title{
      font-size:20px;
      font-weight:700;
      color:#000000;
      margin-bottom:0px;
    }
  }
  .taskName-date-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    .taskName-field {
      width: calc(100% - 230px);
      padding-right: 20px;
    }
    .datepickerRange-field {
      width: 230px;
      .react-datepicker__aria-live {
        display: none;
      }
      .react-datepicker {
        .react-datepicker__day {
          &.react-datepicker__day--in-range {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              top: 0px;
              left: 50%;
              transform: translateX(-50%);
              width: calc(100% + 8px);
              height: 100%;
              background-color: var(--primaryColor);
              opacity: 0.2;
            }
            &:hover {
              background-color: transparent;
            }
          }
          &.react-datepicker__day--range-start {
            border-radius: 0px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: var(--primaryColor);
            &:before {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              width: calc(100% + 4px);
              left: 0px;
              transform: translateX(0px);
            }
          }
          &.react-datepicker__day--range-end {
            border-radius: 0px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: var(--primaryColor);
            &:before {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              width: calc(100% + 4px);
              left: -4px;
              transform: translateX(0px);
            }
          }
        }
      }
    }
  }
  .task__top__details__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -8px;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 0px;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: #eee;
      width: calc(100% + 24px);
      display: none;
    }
  }
  .task__top__details__col {
    width: 33.3333%;
    padding: 0px 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    min-height: 42px;
    .inner-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      & > .label {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 600;
        font-size: 16px;
        width: 140px;
        .icon-wrapper {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          position: relative;
          top: -2px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        &::before {
          content: ':';
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .value {
      width: calc(100% - 140px);
      padding-left: 15px;
      .add-span {
        font-size: 16px;
        font-weight: 400;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
          color: var(--primaryColorDark);
          cursor: pointer;
        }
      }
      .contact-wrapper {
        display: inline-flex;
        align-items: center;
        position: relative;
        max-width: 100%;
        width: 100%;
        & > div {
          width: 100%;
        }
        .avatar {
          width: 30px;
          height: 30px;
          background-color: var(--primaryColor) !important;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          .avatar-content {
            width: 100%;
            height: 100%;
            color: #000000;
            font-size: 11px;
          }
        }
        .contact-name {
          width: calc(100% - 31px);
          font-size: 15px;
          color: #000000;
          font-weight: 500;
          padding-left: 8px;
          white-space: pre;
          overflow: hidden;
          // text-overflow:ellipsis;
        }
        .arrow-wrapper {
          width: 22px;
          height: 22px;
          margin-left: 4px;
          flex-shrink: 0;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .assignTo-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        & > div {
          width:auto;
          max-width:100%;
        }
        .avatar-group {
          .avatar {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .arrow-wrapper {
          width: 22px;
          height: 22px;
          margin-left: 4px;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .react-select {
          .avatar {
            &.pull-up {
              border: 3px solid white;
            }
            transform: translateY(0px) scale(1) !important;
            background-color: transparent !important;
            box-shadow: unset !important;
            &.bg-light-primary {
              background-color: var(--primaryColor) !important;
            }
            .avatar-content {
              font-size: 12px;
              padding-bottom: 3px;
              color: black;
            }
            img {
              width: 32px;
              height: 32px;
              object-fit: cover;
              object-position: center;
            }
          }
          .custom-select__single-value {
            &:first-child {
              margin-left: 0px;
            }
            margin-left: -10px;
          }
        }
      }
      .badgeLoyal-wrapper {
        display: inline-flex;
        align-items: center;
        padding: 4px 30px 6px 31px;
        border-radius: 8px;
        position: relative;
        width: 100%;
        .dot {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          z-index: 2;
        }
        .name {
          display: inline-block;
          font-size: 15px;
          font-weight: 500;
          position: relative;
          z-index: 2;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre;
        }
        .bg-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .border-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .down-arrow-btn {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          cursor: pointer;
        }
      }
      .priority-label-wrapper {
        display: inline-flex;
        align-items: center;
        padding: 4px 30px 6px 31px;
        border-radius: 8px;
        position: relative;
        width: 100%;
        .dot {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          z-index: 2;
        }
        .name {
          display: inline-block;
          font-size: 15px;
          font-weight: 500;
          position: relative;
          z-index: 2;
        }
        .bg-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .border-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .down-arrow-btn {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          cursor: pointer;
        }
      }
      .status-label-wrapper {
        display: inline-flex;
        align-items: center;
        padding: 4px 30px 6px 31px;
        border-radius: 8px;
        position: relative;
        width: 100%;
        .dot {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          z-index: 2;
        }
        .name {
          display: inline-block;
          font-size: 15px;
          font-weight: 500;
          position: relative;
          z-index: 2;
        }
        .bg-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .border-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .down-arrow-btn {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          cursor: pointer;
        }
      }
      .category-label-wrapper {
        display: inline-flex;
        align-items: center;
        padding: 4px 30px 6px 31px;
        border-radius: 8px;
        position: relative;
        width: 100%;
        .dot {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          z-index: 2;
        }
        .name {
          display: inline-block;
          font-size: 15px;
          font-weight: 500;
          position: relative;
          z-index: 2;
        }
        .bg-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .border-wrapper {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          border-radius: 8px;
        }
        .down-arrow-btn {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          cursor: pointer;
        }
      }
      .value-text {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      .form-control {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        padding: 6px 12px 6px 0px;
        border: none;
        transition: 0.5s;
        &:focus {
          padding: 6px 12px 6px 12px;
          border: 1px solid var(--primaryColor);
        }
      }
    }
    .custom-select__control {
      padding-left: 0px;
      min-height: unset !important;
      border: none !important;
      display: inline-flex;
      width: auto !important;
      max-width: 100%;
      flex-wrap: nowrap;
      .custom-select__value-container {
        // margin-right:6px;
        margin-right: 0px;
        // display:flex;
        flex: unset;
      }
      .react-select {
        width: 100%;
      }
      .custom-select__indicators {
        margin-left: 6px;
      }
    }
    .custom-select__menu {
      z-index: 4;
      .custom-select__option {
        &.custom-select__option--is-selected {
          background-color: transparent !important;
        }
      }
    }
    &.priority,
    &.status,
    &.category {
      .custom-select__menu {
        .custom-select__option.custom-select__option--is-selected {
          color: grey !important;
          &:hover {
            color: var(--primaryColorDark) !important;
            background-color: #f2f2f2 !important;
          }
        }
      }
    }
    .assignTo-wrapper {
      .custom-select__control {
        .custom-select__indicators {
          margin-left: 0px;
        }
        .custom-select__clear-indicator {
          display: none;
        }
        &:hover {
          .custom-select__clear-indicator {
            display: none;
          }
        }
      }
      .custom-select__value-container {
        display: flex;
        flex-wrap: nowrap;
      }
    }
    &.contact__company__profile {
      .created-user-wrapper {
        background-color: #f2f2f2;
        border-radius: 100px;
        border: 1px solid grey;
        padding: 6px 15px 6px 6px;
        display: inline-flex;
        align-items: center;
        border: 1px solid #e1e1e1;
        margin-bottom: 10px;
        cursor: pointer;
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--primaryColor) !important;
          color: #000000 !important;
          .avatar-content {
            width: 100%;
            height: 100%;
            line-height: 28px;
            font-size: 15px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }
        .name {
          color: var(--bs-body-color);
          font-weight: 600;
          font-size: 14px;
          padding-left: 6px;
          .userName {
            color: #000000;
          }
        }
      }
    }
    &.after-task{
      width:100%;
      padding-left:0px;
      padding-right:0px;
      textarea{
        font-weight:400 !important;
        border:1px solid #eaeaea !important;
        &:focus{
          border:1px solid var(--primaryColor) !important;
        }
      }
    }

    .react-select{
      width:auto;
      max-width:100%;
      display:inline-block;
    }
  }
  .custom-select__menu{
    width:300px;
    right:0px;
  }
  .attachment-save-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    z-index: 0;
    .custom-fileupload {
      border: none;
      background-color: transparent;
      display: block;
      width: 100%;
      .ui-loader {
        .overlay {
          background-color: transparent !important;
        }
      }
      .inner-wrapper {
        padding: 0px 0px;
        .dropzone {
          display: inline-block;
          .outsite-CN {
            display: none !important;
          }
        }
      }
      .ln-btn {
        padding: 7px 14px;
        position: absolute;
        bottom: -24px;
        left: 0px;
        display: flex;
        align-items: center;
        right: unset;
        // background-color: transparent;
        background-color: #f4f4f4;
        border-radius: 6px;
        .spinner-border {
          margin-right: 8px;
          width: 21px;
          height: 21px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          color: #000000;
          position: relative;
          top: -2px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        &:hover {
          // background-color:#f4f4f4;
          background-color: #000000;
          svg {
            color: #ffffff;
          }
          .text {
            color: #ffffff;
          }
        }
      }
      .attachment-wrapper {
        margin-top: -10px;
        justify-content: flex-start;
        padding-bottom: 15px;
        .file__card {
          width: 200px;
          max-width: 20%;
          .overlay {
            background-color: rgba(0, 0, 0, 0.6) !important;
            transition: 0.3s;
          }
        }
      }
      .remove-all-btn {
        margin-top: 0px !important;
        display: inline-flex !important;
        position: absolute;
        left: 202px;
        bottom: -24px;
      }
    }
    .btn-primary {
      margin-left: 10px;
      min-width: 140px;
    }
  }
}
// mail-task-preview-end

// email-details-page-end
// email-modual-design-end

// body{
//   position: relative;
//   #root{
//     position: relative;
//     z-index:2;
//   }
//   &:before{
//     content:"";
//     position:absolute;
//     top:100px;
//     left:100px;
//     background-color:var(--primaryColor);
//     width:600px;
//     height:600px;
//     border-radius:50%;
//     // z-index: 1;
//     filter: blur(200px);
//     opacity: 0.2;
//   }
//   &:after{
//     content:"";
//     position:absolute;
//     bottom:100px;
//     right:100px;
//     background-color:var(--primaryColor);
//     width:600px;
//     height:600px;
//     border-radius:50%;
//     // z-index: 1;
//     filter: blur(200px);
//     opacity: 0.2;
//     display:block;
//   }
// }
// super-admin-design-start
// inventory-design-start

.user-instruction {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  padding: 15px 20px;
  margin-bottom: 20px;
  span {
    margin-bottom: 10px;
    font-weight: 600;
  }
}
.orange-dot {
  height: 15px;
  width: 15px;
  background-color: #fc6600;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  -webkit-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}
.online-order-status {
  background: #777;
  padding: 8px;
  border-radius: 20px;
  color: white;
  &.processing {
    background: #5b841b;
  }
  &.on-hold {
     background: #f0a91f;
  }
  &.ready-for-ship {
     background: #91902c;
  }
  &.waiting-for-pick {
     background: #b737ac;
  }
  &.completed {
    background: #31729e;
  }
   &.ready-for-pickup {
    background: #c32929;
  }
}
.online-order-status-button {
  text-align: center;
}
@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.product-list-header {
  .product-date-box {
    display: flex;
    position: relative;
    // svg {
    //   position: absolute;
    //   top: 6px;
    //   right: 7px;
    //   width: 18px;
    //   cursor: pointer;
    // }
  }
  .contact-select-box {
    .custom-select__single-value,
    .custom-select__option {
      .avatar {
        margin-right: 5px !important;
      }
    }
    .custom-select__single-value {
      .avatar-content {
        background-color: var(--primaryColor) !important;
        color: black !important;
      }
    }
  }
}
.card-header.product-list-header {
  .right {
    display: flex;
    .btn-outline-secondary {
      border: 1px solid #e8e8e8 !important;
      background-color: #fff !important;
    }
    .product-date-box {
      position: relative;
      .flatpickr-wrapper {
        width: 100%;
        input {
          width: 100%;
        }
      }
      & > svg {
        cursor: pointer;
        width: 18px;
        height: 18px;
        top: 10px;
        right: 8px;
        position: absolute;
      }
    }
    .contact-select-box {
      width: 250px;
      margin-right: 10px;
      margin-left: 10px;
    }
    .scan-product-btn {
      padding-left: 12px;
      padding-right: 12px;
    }
    .btn-group {
      margin-right: 10px;
    }
    .add-product-btn {
      margin-left: 10px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.add-inventory-product-modal {
  max-width: 780px;
  .add-items-row {
    display: flex;
    margin: 0px -15px;
    .add-item-box {
      width: 50%;
      padding: 0px 15px;
      .inner-wrapper {
        position: relative;
        padding: 14px 12px;
        border: 1px dashed var(--primaryColor);
        border-radius: 12px;
        cursor: pointer;
        .icon-wrapper {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          background-color: var(--primaryColor);
          color: #ffffff;
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          margin: 0px auto;
          margin-bottom: 7px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          display: block;
          width: 100%;
          color: var(--primaryColorDark);
          text-align: center;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--primaryColor);
          opacity: 0.1;
          border-radius: 12px;
        }
        &:hover {
          &:before {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.inventory-product-categories-table-wrapper,
.inventory-warehouse-locations-table-wrapper {
  .rdt_Table_Card {
    box-shadow: unset !important;
  }
}

.inventory-scan-product-modal {
  // .barcod-wrapper{
  //   position: relative;
  //   padding:25px 15px;
  //   &::before{
  //     content:"";
  //     position:absolute;
  //     top:0px;
  //     left:0px;
  //     width:100%;
  //     height:100%;
  //     background-color:var(--primaryColor);
  //     opacity:0.1;
  //     border-radius:12px;
  //   }
  // }
  // .icon-wrapper{
  //   height: 100px;
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: center;
  //   justify-content: center;
  //   position: absolute;
  //   top:24px;
  //   left: 0px;
  //   right: 0px;
  //   width: 100%;
  //   z-index: 2;
  //   .inner-wrapper{
  //     width: 70px;
  //     height: 70px;
  //     border-radius: 50%;
  //     padding: 17px;
  //     position: relative;
  //     margin: 0px auto;
  //     background-color: #fff;
  //     &::before{
  //       content:"";
  //       position:absolute;
  //       top:0px;
  //       left:0px;
  //       width:100%;
  //       height:100%;
  //       border-radius:50%;
  //       background-color:#fff;
  //       opacity:0.1;
  //     }
  //   }
  // }
  #html5qr-code-full-region {
    padding: 25px 15px !important;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.1;
      border-radius: 12px;
    }
  }
  #html5qr-code-full-region__scan_region {
    position: relative;
    &::before {
      content: '';
      width: 70px;
      height: 70px;
      border-radius: 50%;
      padding: 17px;
      margin: 0px auto;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      content: '';
      width: 70px;
      height: 70px;
      border-radius: 50%;
      padding: 17px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0px auto;
      background-image: url(../images/icons/barcode-scanner.svg);
      background-size: 52%;
      background-repeat: no-repeat;
      background-position: center;
    }
    img {
      display: none !important;
    }
    video {
      position: relative;
      z-index: 2;
    }
    #qr-shaded-region {
      z-index: 3;
    }
    canvas#qr-canvas-visible {
      &:first-child {
        background-color: #fff;
        padding: 10px 10px;
        border-radius: 10px;
        position: relative;
        z-index: 3;
      }
    }
  }
  #html5qr-code-full-region__dashboard {
    position: relative;
    z-index: 2;
  }
  #html5qr-code-full-region__dashboard_section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  button.html5-qrcode-element {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
    border-radius: 6px;
    padding: 7px 12px;
    font-size: 16px;
    transition: 0.5s;
    margin: 14px 4px 0px 4px;
    min-width: 180px;
    &:hover {
      box-shadow: 0 8px 25px -8px #a3db59;
    }
  }
  #html5qr-code-full-region {
    border: none !important;
    & > div:first-child {
      display: none !important;
    }
  }
  #html5qr-code-full-region__dashboard_section {
    #html5-qrcode-anchor-scan-type-change {
      font-size: 16px;
      border: 1px solid transparent;
      border-radius: 6px;
      padding: 7px 12px;
      font-size: 16px;
      transition: 0.5s;
      text-decoration: none !important;
      display: inline-block;
      margin: 14px 4px 0px 4px;
      &:hover {
        background-color: #ffffff;
        border: 1px solid transparent;
      }
    }
  }
  #html5qr-code-full-region__scan_region {
    z-index: 4;
  }
  #html5qr-code-full-region__dashboard_section {
    & > div {
      width: 100% !important;
      & > div:nth-child(2) {
        width: 100% !important;
        max-width: 100% !important;
        border: 2px dashed var(--primaryColor) !important;
        border-radius: 10px;
        & > div {
          margin-top: 12px;
          margin-bottom: 14px;
        }
      }
    }
  }
}

.inventory_product_details {
  .card-header {
    margin-bottom: 0px;
    background-color: #fff;
    border-bottom: none;
    padding-left: 65px;
    padding-bottom: 25px;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 11px;
      left: 15px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .action-btn-wrapper {
      display: flex;
      align-items: center;
      .action-btn {
        width: 34px;
        height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        border-radius: 50%;
        cursor: pointer;
        padding: 8px;
        margin-right: 6px;
        svg {
          width: 100%;
          height: 100%;
        }
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
  }
  .card-body {
    padding: 20px 20px !important;
  }
  .add-update-contact-tab-wrapper {
    padding: 0px 22px;
  }
  .inventory-product-details {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .product-images {
      width: 35%;
      .carousel {
        .slide {
          img {
            height: 640px !important;
            object-fit: contain;
            background-color: whitesmoke;
            border-radius: 12px;
            overflow: hidden;
          }
        }
        .thumbs-wrapper {
          margin: 0px;
          .thumbs {
            padding-left: 0px;
            margin-bottom: 0px;
            margin-top: 10px;
            overflow-x: auto;
            .thumb {
              height: 100px;
              height: 100px;
              background-color: whitesmoke;
              border-radius: 10px;
              overflow: hidden;
              margin-bottom: 10px;
              padding: 0px;
              opacity: 0.5;
              border: 2px solid transparent;
              transition: 0.5s;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
              &:hover {
                opacity: 0.8;
              }
              &.selected {
                opacity: 1;
                border: 2px solid var(--primaryColorDark);
              }
            }
          }
        }
        .control-dots {
          .dot {
            background-color: gray !important;
            box-shadow: unset !important;
            border-radius: 10px;
            &.selected {
              background-color: var(--primaryColor) !important;
              width: 20px;
            }
          }
        }
        .control-arrow {
          display: none !important;
        }
        .carousel-status {
          right: 8px;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
          font-size: 16px;
          top: 2px;
          font-weight: 700;
        }
      }
    }
    .product-content-right {
      width: 65%;
      padding-left: 25px;
      .product-title {
        font-size: 30px;
        font-weight: 600;
        color: #000;
        margin-bottom: 14px;
      }
      .product-barcode {
        display: inline-block;
        border-radius: 10px;
        padding: 10px 10px;
        position: relative;
        border: 1px dashed var(--primaryColor);
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 4px;
        }
        svg {
          position: relative;
          z-index: 2;
          & > rect {
            fill: transparent !important;
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--primaryColor);
          opacity: 0.1;
        }
      }
      .product-price {
        margin-bottom: 14px;
        .form-label {
          display: block;
          color: #000;
          font-size: 18px;
          margin-bottom: 0px;
        }
        .sell-price {
          font-size: 26px;
          font-weight: 600;
          color: var(--primaryColor);
          display: inline-block;
        }
        .orignal-price {
          font-size: 18px;
          display: inline-block;
          position: relative;
          top: -2px;
          text-decoration: line-through;
          margin-left: 10px;
        }
      }
      .product-description,
      .product-location {
        margin-bottom: 20px;
        .title {
          display: block;
          color: #000;
          font-size: 18px;
          margin-bottom: 6px;
          font-weight: 600;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          li {
            position: relative;
            padding-left: 32px;
            font-size: 16px;
            color: var(--bs-body-color);
            margin-bottom: 12px;
            font-weight: 400;
            &:last-child {
              margin-bottom: 0px;
            }
            &:before {
              content: '';
              position: absolute;
              top: 7px;
              width: 12px;
              height: 8px;
              left: 7px;
              border-left: 2px solid var(--primaryColorDark);
              border-bottom: 2px solid var(--primaryColorDark);
              transform: rotate(-45deg);
            }
            &:after {
              content: '';
              position: absolute;
              top: -1px;
              left: 0px;
              width: 24px;
              height: 24px;
              font-size: 12px;
              line-height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--primaryColor);
              color: var(--primaryColorDark);
              border-radius: 50%;
              font-weight: 600;
              opacity: 0.2;
            }
          }
        }
      }
      .ectra-details {
        // margin: 0px -14px;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        background-color: #f4f4f4;
        border-radius: 12px;
        li {
          width: 25%;
          // padding:0px 14px;
          border-right: 1px solid #e1e1e1;
          &:last-child {
            border-right: 0px;
          }
          .inner-wrapper {
            height: 100%;
            padding: 12px 15px;
          }
          .label {
            display: block;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
          .value {
            display: block;
            font-size: 18px;
            font-weight: 600;
            color: var(--primaryColor);
          }
        }
      }
      .criteria-details {
        // margin: 0px -14px;
        padding: 0px;
        display: flex;
        list-style: none;
        background-color: #f4f4f4;
        border-radius: 12px;
        li {
          border-right: 1px solid #e1e1e1;
          &:last-child {
            border-right: 0px;
          }
          .inner-wrapper {
            height: 100%;
            padding: 12px 15px;
          }
          .label {
            display: block;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
          .value {
            display: block;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.inventory-product-add-uodate-card {
  .card-header {
    position: relative;
    padding-left: 60px;
  }
  .back-arrow {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    font-size: 0px;
    position: absolute;
    top: 9px;
    left: 12px;
    transition: 0.5s;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      top: 13px;
      width: 12px;
      height: 12px;
      left: 15px;
      border-left: 3px solid #000;
      transform: rotate(45deg);
      border-bottom: 3px solid #000;
    }
    &:hover {
      background-color: #f0f0f0;
    }
  }
  & > .card-body {
    padding: 20px 20px !important;
  }
  .step-title {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 12px;
  }
  .step1-row {
    position: relative;
    align-items: flex-start;
    padding-bottom: 10px;
    margin-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      right: 0px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #eaeaea;
    }
  }
  .barcode-details {
    display: inline-block;
    border-radius: 10px;
    padding: 10px 10px;
    position: relative;
    border: 1px dashed var(--primaryColor);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 0px;
    margin-right: 10px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 4px;
    }
    svg {
      position: relative;
      z-index: 2;
      & > rect {
        fill: transparent !important;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.1;
    }
  }
  .product-dimention-input-group {
    & > input,
    & > div {
      padding: 0px 0px !important;
      margin-left: 0px !important;
    }
    & > input {
      padding: 0px 8px !important;
      height: 43px;
    }
    .custom-select__control {
      border-radius: 0px 10px 10px 0px !important;
      border-top: none;
      border-right: none;
      border-bottom: none;
    }
  }
  .syncToWooCommerce-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 18px;
  }
  .sygn-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 10px 19px;
    img {
      width: 170px;
      margin-left: 10px;
      display: block;
      position: relative;
      top: 3px;
    }
  }
  .product-img-upload,
  .attachments-col {
    .form-label:not(.upload-btn) {
      width: 100% !important;
      margin-bottom: 8px;
    }
  }
  .product-img-upload {
    margin-bottom: 15px;
  }

  .add-product-details-card {
    box-shadow: unset !important;
    border: none !important;
    background-color: #f9f9f9;
    border: 1px solid #eaeaea !important;
    margin-bottom: 20px;
    .card-header {
      background-color: transparent !important;
      padding: 15px 20px;
      .title {
        margin-bottom: 0px;
        font-size: 20px;
        font-weight: 600;
        color: #000000;
      }
    }
    .card-body {
      padding: 20px 20px 10px 20px;
    }
    .mt-1.row:empty {
      display: none;
    }
    // .radio-btn-wrapper {
    //   .radio-btn-repeater {
    //     margin-right: 25px !important;
    //     position: relative;
    //     padding-left: 0px;
    //     input {
    //       position: absolute;
    //       top: 0px;
    //       width: 100%;
    //       height: 100%;
    //       z-index: 2;
    //       opacity: 0;
    //       left: 0px;
    //       margin-left: 0px;
    //       margin-top: 0px;
    //       cursor: pointer;
    //     }
    //     .form-check-label {
    //       position: relative;
    //       padding-left: 65px;
    //       padding-top: 6px;
    //       padding-bottom: 6px;
    //       &:before {
    //         content: '';
    //         width: 54px;
    //         height: 28px;
    //         display: inline-block;
    //         transition: 0.5s !important;
    //         position: absolute;
    //         top: 2px;
    //         transition: 0.5s;
    //         background-color: #fff;
    //         border-radius: 60px;
    //         border: 1px solid #e4e4e4;
    //         cursor: pointer;
    //         left: 0px;
    //       }
    //       &:after {
    //         content: '';
    //         position: absolute;
    //         top: 6px;
    //         width: 20px;
    //         height: 20px;
    //         transition: 0.5s !important;
    //         border-radius: 50%;
    //         background-color: #eaeaea;
    //         cursor: pointer;
    //         left: 4px;
    //       }
    //     }
    //     &:last-child {
    //       margin-right: 0px !important;
    //     }
    //   }
    //   .radio-btn-repeater input:checked + .form-check-label {
    //     &:before {
    //       background-color: var(--primaryColor);
    //       border: 1px solid var(--primaryColor);
    //     }
    //     &:after {
    //       left: 29px;
    //       background-color: #ffffff;
    //     }
    //   }
    // }
    .discount-type-col {
      padding-right: 10px;
    }
    .discount-type {
      .toggle-btns-wrapper {
        width: 330px !important;
      }
      .discount-type-field {
        width: calc(100% - 330px) !important;
        position: relative;
        .sign {
          position: absolute;
          bottom: 1px;
          right: 15px;
          background-color: #ececec;
          width: 40px;
          height: 39px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 400;
          color: #000;
          padding-bottom: 3px;
        }
      }
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    textarea {
      height: 156px;
    }
    .imgupload-dropzon {
      background-color: #ffffff;
      border: 2px dashed #d5d5d5;
      .right-details {
        .title {
          color: #000;
        }
        .text {
          width: 300px;
          max-width: 100%;
        }
      }
    }
    .dimentions-weight-row {
      .weight-col {
        width: 50%;
      }
      .input-group-text {
        background-color: #ececec;
        font-size: 16px;
        font-weight: 400;
        color: #000;
      }
    }

    .inventory-add-attachment-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;
      padding-bottom: 10px;
      padding-top: 5px;
      .custom-fileupload {
        border: none;
        background-color: transparent;
        display: block;
        width: 100%;
        .ui-loader {
          .overlay {
            background-color: transparent !important;
          }
        }
        .inner-wrapper {
          padding: 0px 0px;
          .dropzone {
            display: inline-block;
            .outsite-CN {
              display: none !important;
            }
          }
        }
        .ln-btn {
          padding: 7px 14px;
          border-radius: 4px;
          // position: absolute;
          // bottom: -38px;
          // right: 155px;
          display: flex;
          align-items: center;
          // background-color:var(--primaryColor);
          .spinner-border {
            margin-right: 8px;
            width: 21px;
            height: 21px;
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            color: #000000;
            position: relative;
            top: -2px;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
          }
          &:hover {
            background-color: #ffffff;
          }
        }
        .attachment-wrapper {
          margin-top: 15px;
          justify-content: flex-start;
          .file__card {
            width: 20%;
            .inner-wrapper {
              padding-top: 90%;
            }
          }
        }
        .remove-all-btn {
          margin-top: 0px !important;
          justify-content: flex-start !important;
        }
      }
      .btn-primary {
        margin-left: 10px;
        min-width: 140px;
      }
    }
    .note-box {
      background-color: #f7f7f7;
      border-radius: 8px;
      padding: 10px 10px;
      margin-bottom: 12px;
      &:first-child {
        margin-top: 18px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
      .profile-img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        .avatar {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
        }
      }
      .right-contant {
        width: calc(100% - 47px);
        padding-left: 10px;
        .note-header {
          display: flex;
          align-items: center;
          .name-time {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            width: 100%;
            padding-right: 12px;
            .name,
            .time {
              display: inline-block;
              margin-bottom: 4px;
            }
            .name {
              margin-right: 10px;
              color: #000000;
              font-weight: 600;
            }
            .time {
              font-weight: 500;
              position: relative;
              padding-left: 16px;
              &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #aeaeae;
              }
            }
          }
          .action-btn-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            flex-shrink: 0;
            .action-btn {
              width: 32px;
              height: 32px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: #ffffff;
              padding: 7px;
              transition: 0.5s;
              cursor: pointer;
              svg {
                width: 100%;
                height: 100%;
              }
              &:last-child {
                margin-left: 6px;
              }
              &:hover {
                background-color: #000000;
                svg {
                  color: #ffffff;
                }
              }
            }
          }
        }
        .attachment-wrapper {
          .file__card {
            width: 50%;
            margin-bottom: 7px;
            .inner-border-wrapper {
              background-color: #ffffff;
              border: 1px solid #ececec;
            }
            .inner-wrapper {
              padding-top: 0px;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
            }
            .file__preview {
              position: static;
              width: 40px;
              height: 40px;
              flex-shrink: 0;
              img {
                border-radius: 6px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
            .file-name {
              font-size: 16px;
              font-weight: 400;
              color: grey;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-left: 10px;
            }
            .file__preview__details {
              display: flex;
              width: calc(100% - 69px);
              padding-right: 12px;
              align-items: center;
            }
            .overlay {
              position: static;
              opacity: 1;
              z-index: 2;
              background-color: transparent;
              display: inline-flex;
              flex-shrink: 0;
              width: auto;
              height: auto;
              .action-btn-wrapper {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                .action-btn {
                  width: 32px;
                  height: 32px;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  background-color: #ffffff;
                  padding: 7px;
                  transition: 0.5s;
                  cursor: pointer;
                  margin-right: 0px;
                  margin-left: 0px;
                  svg {
                    width: 100%;
                    height: 100%;
                    color: #777 !important;
                  }
                  &:last-child {
                    margin-left: 4px;
                  }
                  &:hover {
                    background-color: #000000;
                    svg {
                      color: #ffffff !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .question-box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      &:empty {
        margin-top: 0px;
      }
      .question-col {
        width: 25%;
        margin-bottom: 15px;
        flex: unset;
        .inner-wrapper {
          height: auto;
          background-color: #ffffff;
          border: 1px solid #e8e8e8;
          border-radius: 8px;
          .header-wrapper {
            display: flex;
            align-items: center;
            padding: 9px 15px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 1px solid #e8e8e8;
            border-bottom: none;
            .title {
              font-size: 18px;
              font-weight: 600;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              padding-right: 12px;
              margin-bottom: 0px;
              color: #000000;
            }
            .custom-toggle-btn {
              flex-shrink: 0;
              position: relative;
              display: inline-flex;
              input {
                position: absolute;
                top: 0px;
                width: 100%;
                height: 100%;
                z-index: 2;
                opacity: 0;
                margin-top: 0px;
                cursor: pointer;
              }
              input.form-check-input[disabled] + .label {
                opacity: 0.5;
              }
              .label {
                position: relative;
                width: 54px;
                height: 28px;
                display: inline-block;
                &:before {
                  content: '';
                  width: 54px;
                  height: 28px;
                  display: inline-block;
                  transition: 0.5s !important;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  transition: 0.5s;
                  background-color: #fff;
                  border-radius: 60px;
                  border: 1px solid #e4e4e4;
                  cursor: pointer;
                }
                &:after {
                  content: '';
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  width: 20px;
                  height: 20px;
                  transition: 0.5s !important;
                  border-radius: 50%;
                  background-color: #eaeaea;
                  cursor: pointer;
                }
              }
              input:checked + .label {
                &:before {
                  background-color: var(--primaryColor);
                  border: 1px solid var(--primaryColor);
                }
                &:after {
                  left: 29px;
                  background-color: #ffffff;
                }
              }
            }
          }
          .body-wrapper {
            padding: 15px 15px;
            display: none;
            .field-wrapper {
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
        &.active {
          .inner-wrapper {
            height: 100%;
            .header-wrapper {
              border-bottom: 1px solid #e8e8e8;
            }
            .body-wrapper {
              display: block;
            }
          }
        }
      }
    }

    .quetion-rr-row {
      display: flex;
      flex-wrap: wrap;
      .quetion-rr-col {
        width: 100%;
      }
    }
  }
}

.inventory-order-add-update-card {
  .back-arrow {
    width: 25px;
    height: 25px;
    position: relative;
    margin-right: 6px;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      border-left: 2px solid #000000;
      border-bottom: 2px solid #000000;
      width: 14px;
      height: 14px;
    }
  }
  & > .card-body {
    padding: 0px !important;
  }
}
.offline-order-details {
  .back-arrow {
    width: 25px;
    height: 25px;
    position: relative;
    margin-right: 6px;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      border-left: 2px solid #000000;
      border-bottom: 2px solid #000000;
      width: 14px;
      height: 14px;
    }
  }
}

.order-details {
  .product-list {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    max-height: 296px;
    overflow-y: auto;
    padding: 0;
    li {
      padding: 12px 12px 12px;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}
.order-items {
  .product-qty {
    display: flex;
    .qt-inc {
      width: 35px;
    }
    .qt-input {
      width: 85px;
    }
  }
}

.select-per-sheet {
  .value {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
  }
  .inner-wrapper {
    background-color: #f7f7f7;
    height: 100%;
    padding: 14px 15px;
    border-radius: 12px;
    .form-label {
      display: block;
      width: 100%;
    }
  }
  .select-barcode-group {
    .btn {
      height: 41px;
      background-color: white;
      &.active {
        background-color: var(--primaryColor);
        color: white;
      }
    }
  }
}
.print-barcode-btn {
  width: 8.45in;
  margin: 15px auto 15px auto;
}
.list-barcode {
  margin: 0px auto !important;
  padding: 0px !important;
  border: none !important;
  height: auto;
  width: 8.45in;
  margin-bottom: 10px;
  .product-barcode {
    height: auto !important;
    margin: 0px !important;
    padding: 24px 0px 15px 0px !important;
    border: 1px dashed #3e3e3e;
    width: 50% !important;
    svg {
      width: 100%;
    }
  }
  .header {
    padding: 0px 30px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    display: flex;
    justify-content: space-between;
  }
}

.history-wrap {
  border-radius: 12px;
  .event {
    background-color: #ffffff;
    margin-bottom: 14px;
    padding-left: 54px;
    position: relative;
    &:last-child {
      margin-bottom: 0px;
    }
    .inner-wrapper {
      padding: 18px 18px;
      border-radius: 10px;
      background-color: #f7f6f0;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      left: 0px;
      .avatar-content {
        width: auto !important;
        height: auto !important;
        text-transform: uppercase;
        font-size: 15px !important;
        font-weight: 900 !important;
      }
    }
    .user-details {
      display: inline-block;
      padding-right: 10px;
      max-width: calc(100% - 26px);
    }
    .user-name {
      font-size: 17px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px !important;
    }
    .info-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 3px;
      background-color: var(--primaryColor);
      cursor: pointer;
      border-radius: 50%;
      margin-bottom: 2px;
      position: relative;
      top: 2px;
      svg {
        color: #ffffff;
        width: 100%;
        height: 100%;
      }
    }
    .details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      .title {
        margin-bottom: 0px !important;
        font-size: 16px;
        color: #959595;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 2px;
        width: 100%;
      }
    }
    .date {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .text {
        font-size: 16px;
        color: grey;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 19px;
      width: 2px;
      background-color: var(--primaryColor);
      height: calc(100% + 15px);
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
  }
}

.product-history-modal {
  &.modal-dialog {
    width: 1050px !important;
    max-width: calc(100% - 30px) !important;
  }
  .history-details-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .history-details-box {
    width: 33.3333%;
    margin-bottom: 13px;
    .inner-wrapper {
      height: 100%;
      // padding: 15px 15px;
    }
    .item-name {
      font-size: 18px;
      font-weight: 700;
      color: #000;
    }
    .contant-body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .item-details-row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
      .label {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        margin-right: 8px;
        display: inline-block;
        margin-bottom: 3px;
        display: none;
      }
      .value {
        font-size: 16px;
        color: grey;
        font-weight: 600;
        display: inline-block;
      }
      &.initial-row {
        padding-right: 12px;
        max-width: calc(50% - 20px);
      }
      &.updated-row {
        position: relative;
        padding-left: 34px;
        margin-bottom: 5px;
        max-width: calc(50% + 20px);
        &:before {
          content: '';
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 1px;
          border-top: 1px solid #000000;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          top: calc(50% - 4px);
          transform: translateY(-50%);
          left: 10px;
          transform: rotate(-135deg);
          width: 8px;
          height: 8px;
          border-left: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }
        .value {
          color: var(--primaryColorDark);
        }
      }
      .img-main-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 4px !important;
        margin: 0px -6px;
        .img-wrapper {
          width: 110px;
          // max-width:25%;
          padding: 0px 6px;
          margin-bottom: 10px;
          .inner-wrapper {
            width: 100%;
            position: relative;
            padding-top: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            border: 1px solid #ececec;
            img {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100% !important;
              height: 100% !important;
              display: block;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
    &.title-details-box,
    &.location-details-box {
      width: 100%;
    }
    &.location-details-box {
      order: 14;
    }
    &.description-details-box {
      order: 15;
      width: 100%;
      .item-details-row {
        .value {
          font-weight: 400;
        }
      }
    }
    &.img-details-box {
      order: 20;
      width: 100%;
      .initial-row {
        margin-bottom: 10px;
      }
    }
  }
}

.inventory-settings {
  background-color: transparent;
  border: none;
  & > .card-header {
    background-color: transparent;
    padding: 0px;
    padding-left: 50px;
    margin-bottom: 25px;
    position: relative;
    background-color: transparent !important;
    border-bottom: none !important;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: -5px;
      left: 0px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .horizontal-tabbing.inventory-settings-tab {
    &:before {
      content: '';
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 1px;
      left: 0px;
      background-color: var(--primaryColor);
    }
    .nav-item {
      .nav-link {
        width: 230px;
        font-size: 16px;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  & > .card-body {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #ffffff;
    border: 1px solid var(--primaryColor);
    border-top: none;
    padding: 0px;
  }
  .inventory-product-categories-table-wrapper {
    .rdt_Table_Card {
      margin-bottom: 0px;
      border: none;
      background-color: transparent;
      box-shadow: unset !important;
      .card-header {
        border-bottom: none;
      }
      .search-box-wrapper {
        margin-top: 0px;
      }
      & > .card-body {
        padding: 20px 20px;
        padding-top: 0px;
      }
    }
  }
  .inventory-product-criteria-tab {
    .rdt_Table_Card {
      margin-bottom: 0px;
      border: none;
      background-color: transparent;
      box-shadow: unset !important;
      .card-header {
        border-bottom: none;
      }
      .search-box-wrapper {
        margin-top: 0px;
      }
      & > .card-body {
        padding: 20px 20px;
        padding-top: 0px;
      }
    }
  }
  .inventory-product-wooCommerce-tab {
    padding: 20px 20px;
    .auth-login-form {
      width: 800px;
      max-width: 100%;
    }
    .field-wrapper {
      margin-bottom: 14px;
    }
    .woocommerce-integration-steps {
      width: 1000px;
      max-width: 100%;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        width: 500px;
        max-width: 100%;
        line-height: 24px;
        margin-bottom: 15px;
      }
      .fancy-ul {
        .item {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        img {
          display: block;
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    .woocommerce-integration {
      width: 1000px;
      max-width: 100%;
      margin-top: 20px;
    }
  }
}

// inventory-orders
.inventory-orders {
  background-color: transparent;
  border: none;
  & > .card-header {
    background-color: transparent;
    padding: 0px;
    padding-left: 50px;
    margin-bottom: 25px;
    position: relative;
    background-color: transparent !important;
    border-bottom: none !important;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: -5px;
      left: 0px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .horizontal-tabbing.inventory-orders-tab {
    &:before {
      content: '';
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 1px;
      left: 0px;
      background-color: var(--primaryColor);
    }
    .nav-item {
      .nav-link {
        width: 230px;
        font-size: 16px;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  & > .card-body {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #ffffff;
    border: 1px solid var(--primaryColor);
    border-top: none;
    padding: 0px;
  }
  .offline-order-tab {
    .card {
      box-shadow: unset !important;
      border: none !important;
      border-radius: 0px !important;
      margin-bottom: 0px !important;
      background-color: transparent !important;
    }
  }
}
// inventory-orders-end

.print-barcode-modal.modal-dialog {
  width: 900px;
  max-width: calc(100% - 30px);
  .radio-btn-wrapper {
    .radio-btn-repeater {
      padding-left: 0px;
      position: relative;
      input {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0 !important;
        margin-top: 0px;
        cursor: pointer;
        left: 0px;
        margin-left: 0px;
      }
      .form-check-label {
        padding-left: 65px;
        padding-top: 6px;
        padding-bottom: 6px;
        position: relative;
        &:before {
          content: '';
          width: 54px;
          height: 28px;
          display: inline-block;
          transition: 0.5s !important;
          position: absolute;
          top: 2px;
          transition: 0.5s;
          background-color: #fff;
          border-radius: 60px;
          border: 1px solid #e4e4e4;
          cursor: pointer;
          left: 0px;
        }
        &:after {
          content: '';
          position: absolute;
          top: 6px;
          width: 20px;
          height: 20px;
          transition: 0.5s !important;
          border-radius: 50%;
          background-color: #eaeaea;
          cursor: pointer;
          left: 4px;
        }
      }
      input:checked + .form-check-label {
        &:before {
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);
        }
        &:after {
          left: 28px;
          background-color: #ffffff;
        }
      }
    }
  }
  .print-barcode-btn {
    margin: 10px 0px 16px 0px;
  }
  .list-barcode-table {
    td {
      padding: 6px 10px 10px 10px;
      border: 1px dashed black;
    }
    .title {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      padding: 10px 15px 5px 15px;
      margin-bottom: 0px;
    }
    svg {
      width: 100%;
    }
  }
}
@media print {
  .print-barcode-modal.modal-dialog {
    .list-barcode-table {
      td {
        border: 1px solid black;
      }
    }
  }
}

// inventory-design-end
// super-admin-design-end

// connect-IMAP-modal
.connect-IMAP-modal {
  &.modal-dialog{
    width:770px;
    max-width:calc(100% - 30px);
    margin:30px auto;
    padding-right:0px;
  }
  .modal-content {
    & > .modal-body {
      padding: 0px 0px;
    }
  }
  .form-error {
    margin-top: 10px;
  }
  .bs-stepper {
    box-shadow: unset;
    .bs-stepper-header {
      position: relative;
      justify-content: center;
      border-bottom: none;
      border-top: 1px solid #e8e8e8;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.1;
      }
      & > * {
        position: relative;
        z-index: 2;
      }
      .step {
        margin: 0px;
        .step-trigger {
          display: inline-flex;
          align-items: center;
          .bs-stepper-box {
            width: 40px;
            height: 40px;
            padding: 0px;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #dfdfdf;
            color: #8c8c8c;
          }
          .bs-stepper-label {
            margin: 0px;
            padding-left: 12px;
            .bs-stepper-title {
              font-size: 15px;
              font-weight: 600;
              color: #797979;
              margin-bottom: 3px;
            }
            .bs-stepper-subtitle {
              font-size: 14px;
              font-weight: 400;
              color: #828282;
            }
          }
        }
        &.active {
          .step-trigger {
            .bs-stepper-box {
              color: #000000;
            }
            .bs-stepper-label {
              .bs-stepper-title {
                color: #000000;
              }
            }
          }
        }
        &.crossed {
          .step-trigger {
            opacity: 1;
            .bs-stepper-box {
              background-color: var(--primaryColor);
              font-size: 0px;
              position: relative;
              &::before {
                content: '';
                position: absolute;
                top: calc(50% - 2px);
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 16px;
                height: 9px;
                border-left: 3px solid #000000;
                border-bottom: 3px solid #000000;
              }
            }
            .bs-stepper-label {
              .bs-stepper-title {
                color: #000000;
              }
            }
          }
        }
      }
      .line {
        padding: 0px;
        border-bottom: 2px dashed #cacaca;
        width: 60px;
        flex: unset;
        margin-left: 8px;
        margin-right: 18px;
        position: relative;
        svg {
          display: none;
        }
      }
      .step.crossed + .line {
        border-bottom: 2px dashed var(--primaryColor);
      }
    }
  }
  .bs-stepper-content {
    padding: 25px 25px !important;
  }
  .title {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  .footer__btns {
    display: flex;
    justify-content: flex-end;
    padding-top: 22px;
    position: relative;
    margin-top: 22px;
    .btn {
      width: 120px !important;
    }
    .btn[type='submit'] {
      margin-left: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 50px);
      height: 1px;
      background-color: #eae8e1;
    }
  }
  .imap__platform__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px;
    button {
      background-color: transparent;
      border: 1px solid #dedede;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 54px;
      padding: 3px 2px;
      border-radius: 10px;
      margin-right: 10px;
      min-width: 129px;
      transition: 0.5s;
      margin-bottom:10px;
      .icon__wrapper {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 8px;
        display: block;
        margin-right: 6px;
        background-color: #ececec;
        transition: 0.5s;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 16px;
        color: grey;
        font-weight: 600;
      }
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background-color: #ececec;
        .icon__wrapper {
          background-color: #ffffff;
        }
      }
      &.active {
        border: 1px solid var(--primaryColor);
        background-color: var(--primaryColor);
        .icon__wrapper {
          background-color: #ffffff;
        }
        .text {
          color: black;
        }
      }
    }
    .form-error{
      width:100%;
    }
  }
  .smtp__imap__success {
    position: relative;
    color: var(--primaryColorDark);
    font-size: 16px;
    line-height: 22px;
    padding: 10px 10px 10px 50px;
    margin-bottom: 15px;
    svg {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.2;
      border-radius: 10px;
    }
  }
  .with-delete-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .form-label {
      width: 100%;
      display: block;
    }
    .react-select {
      width: calc(100% - 43px);
    }
    .delete-btn-wrapper {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 7px;
      margin-left: 7px;
      svg {
        color: red;
        width: 100%;
        height: 100%;
      }
      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
  .loader__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
  }
  .update__IMAP__wrapper {
    padding: 25px 25px 25px 25px;
    .note {
      position: relative;
      color: var(--primaryColorDark);
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      padding: 10px 15px 10px 15px;
      margin-bottom: 15px;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        border-radius: 10px;
      }
    }
  }
  .update__IMAP__wrapper + .footer__btns{
    padding-bottom:22px;
    padding-left:22px;
    padding-right:22px;
    margin-top:0px;
    &::before{
      width:100%;
    }
  }
}
// connect-IMAP-modal-end

// mobile-bottom-menu
.mobile-bottom-menu-wrapper {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  // background-color:#f7f6f0;
  background-color: transparent;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px 10px 10px 10px;
  background-image: linear-gradient(
    rgba(247, 246, 240, 0),
    rgba(247, 246, 240, 0.8)
  );
  z-index: 9;
  display: none;
  .mobile-bottom-menu {
    width: 100%;
    background-color: #000000;
    border-radius: 12px;
    .inner-wrapper {
      display: flex;
      .menu-item {
        width: 20%;
        transition: 0.5s;
        .inner-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 3px;
          transition: 0.7s;
          .icon-wrapper {
            width: 44px;
            height: 44px;
            cursor: pointer;
            padding: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 3px solid #000000;
            transition: 0.7s;
            border-radius: 50%;
            background-color: #000000;
            svg {
              width: 100%;
              height: 100%;
              color: white;
              transition: 0.7s;
            }
          }
        }
        &:hover {
          .inner-wrapper {
            .icon-wrapper {
              svg {
                color: var(--primaryColor);
              }
            }
          }
        }
        &.active {
          position: relative;
          top: -20px;
          .inner-wrapper {
            .icon-wrapper {
              background-color: var(--primaryColor);
              border-radius: 50%;
              svg {
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}
// mobile-bottom-menu-end

// new tab & details design
.new-tab-details-design {
  .horizontal-new-tab-wrapper {
    position: relative;
    margin-bottom: 15px;
    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      left: 0px;
      background-color: #dddddd;
    }
    .horizontal-tabbing {
      .nav-item {
        .nav-link {
          padding: 12px 15px;
          border: none;
          background-color: transparent;
          font-size: 16px;
          font-weight: 500;
          border-radius: 0px;
          cursor: pointer;
          svg {
            width: 21px;
            height: 21px;
            margin-right: 8px;
          }
          &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 0px;
            height: 2px;
            transition: 0.5s;
            left: 0px;
            background-color: var(--primaryColorDark);
          }
          &:hover {
            background-color: #ffffff;
          }
          &.active {
            color: var(--primaryColorDark);
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .personal-info-tab {
    background-color: transparent;
    border: none;
    padding: 6px 13px 20px 13px;
    .card-header {
      background-color: transparent !important;
      border: none;
      padding: 0px 0px 14px 0px;
    }
    .card-body {
      padding: 0px 0px;
    }
    .update-profile-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .field-wrapper {
        width: calc(50% - 20px);
        &.upload-profile,
        &.biography {
          width: 100%;
        }
      }
    }
  }

  .contact-activity-tabPane {
    padding: 13px 13px;
    height: calc(100dvh - 200px);
    overflow-y: auto;
  }

  .contact-notes-tabPane {
    .notes-list-scroll-area {
      max-height: calc(100dvh - 270px);
      overflow-y: auto;
    }
    .inner-contant-wrapper {
      padding: 10px 12px 0px 12px;
    }
    .rdw-editor-wrapper {
      background-color: #ffffff;
    }
    .rdw-editor-main {
      border: none !important;
    }
    .available-notes-header {
      .print-action-wrapper {
        .add-note-btn {
          margin-left: 13px;
        }
      }
    }
    .contact-notes {
      .accordion-item {
        background-color: #ffffff;
        margin-bottom: 14px !important;
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
          rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
        .accordion-header {
          .accordion-button {
            padding: 10px 15px 5px 15px !important;
            .inner-wrapper {
              .heading-details {
                padding-left: 0px;
                .title {
                  margin-bottom: 5px;
                }
                .author-time {
                  .author-name,
                  .time {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .action-btn-wrapper {
              .action-btn {
                &:hover {
                  background-color: #f4f4f4;
                }
              }
            }
          }
        }
      }
      .contant-wrapper {
        .footer-details {
          .author-name {
            background-color: #f4f4f4;
          }
          .action-btn-wrapper {
            .action-btn {
              background-color: #f4f4f4;
            }
          }
        }
      }
    }

    // need-to-comment
    .add-note-row,
    .submit-btn-wrapper {
      display: none !important;
    }
    .available-notes-wrapper {
      padding-top: 0px;
      margin-top: 0px !important;
      &:before {
        display: none;
      }
      .form-control {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: unset !important;
        background-image: url(../images/icons/search-gray-icon.svg);
        background-size: 18px;
        background-position: 11px 10px;
        padding: 4px 20px 5px 38px;
        background-repeat: no-repeat;
        height: 41.2px;
        font-size: 16px;
        color: #6e6b7b;
        &:focus {
          border: 1px solid var(--primaryColor);
          box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        }
      }
      .form-control::placeholder {
        color: #b9b9c3;
        opacity: 1;
      }
    }
    // need-to-comment-end
  }

  .contact__checklist__wrapper {
    position: relative;
    padding: 5px 13px 0px 13px;
    .list-grid-btn-wrapper {
      display: inline-flex;
      position: absolute;
      top: 6px;
      z-index: 4;
      right: 14px;
      .view-btn {
        &.active {
          background-color: var(--primaryColor) !important;
          color: #000000 !important;
        }
      }
    }
    .card-header {
      padding: 0px 0px 0px 0px;
      padding-right: 94px;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: transparent;
      border-bottom: none;
      .card-title {
        font-size: 22px !important;
        font-weight: 600 !important;
        margin-bottom: 0;
      }
      .create-folder-link {
        top: 0px;
        padding: 10px 20px 10px 20px;
      }
    }
    .company-checklist-inner-scroll {
      max-height: calc(100dvh - 265px);
    }
    .compnay-checklist-folder-wrapper {
      & > .card-body {
        padding: 20px 0px 0px 0px !important;
      }
    }
    .accordion-item.checklist-box {
      background-color: #ffffff;
      margin-bottom: 14px !important;
      border-radius: 10px !important;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px,
        rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
      .checklist-header {
        .title {
          padding-right: 0px;
          width: calc(100% - 220px);
          .checklist-name {
            max-width: 100%;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .badge {
            padding: 6px 12px;
            margin: 0px 0px 0px 12px !important;
            flex-shrink: 0;
          }
        }
        .action-btn-wrapper {
          padding-left: 15px;
          flex-shrink: 0;
        }
        .action-btn {
          &:hover {
            background-color: #f4f4f4;
          }
        }
      }
      .company-form-card.checklist-company-form-card {
        background-color: #f9f9f9;
        .body-wrapper {
          .checklist-listing-items {
            .item {
              .inner-wrapper {
                &:before {
                  background: linear-gradient(
                    118deg,
                    #b7b7b7,
                    rgba(0, 0, 0, 0)
                  );
                  opacity: 0.3;
                }
              }
              &.active {
                .inner-wrapper {
                  &:before {
                    background: linear-gradient(
                      118deg,
                      var(--primaryColor),
                      rgba(0, 0, 0, 0)
                    );
                    opacity: 0.3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact__files__wrapper {
    padding-top: 5px;
    .top-header {
      margin-bottom: 18px !important;
    }
    .company-document-page {
      .company-document-listing-row {
        .company-checklist-inner-scroll {
          height: calc(100dvh - 346px);
          max-height: calc(100dvh - 346px);
        }
      }
      .company-document-listing-row {
        &:last-child {
          .card-body {
            height: calc(100dvh - 318px);
            max-height: calc(100dvh - 318px);
          }
        }
      }
    }
  }
}

.add-update-note-modal {
  &.modal-dialog {
    width: 700px;
    max-width: calc(100% - 30px);
    margin: 0px auto;
  }
  .header__title{
    .btn{
      height:41.2px;
      width:41.2px;
      font-size:0px;
      padding:4px 4px;
      svg{
        margin-right:0px;
      }
    }
  }
  .modal-body{
    padding-bottom:0px;
  }
  .modal-footer{
    border-top:1px solid #dddddd;
    display:flex;
  }
  .attachment-save-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    padding-bottom:70px;
    .custom-fileupload {
      border: none;
      background-color: transparent;
      display: block;
      width: 100%;
      .ui-loader {
        .overlay {
          background-color: transparent !important;
        }
      }
      .inner-wrapper {
        padding: 0px 0px;
        .dropzone {
          display: inline-block;
          .outsite-CN {
            display: none !important;
          }
        }
      }
      .ln-btn {
        padding: 7px 14px;
        border-radius: 4px;
        position: absolute;
        bottom:-52px;
        left:0px;
        display: flex;
        align-items: center;
        .spinner-border {
          margin-right: 8px;
          width: 21px;
          height: 21px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          color: #000000;
          position: relative;
          top:0px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
      .attachment-wrapper {
        margin-top: 6px;
        justify-content: flex-start;
        .file__card {
          width: 25%;
          .inner-wrapper {
            .overlay {
              background-color: rgba(0, 0, 0, 0.7) !important;
            }
          }
        }
      }
      .remove-all-btn {
        // position: absolute;
        // bottom: -38px;
        // left: 0px;
        justify-content: flex-start !important;
        margin-top: 0px !important;
      }
    }
    .btn-primary {
      margin-left: 10px;
      min-width: 140px;
    }
  }
  .note-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 12px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .profile-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      .avatar {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }
    .right-contant {
      width: calc(100% - 47px);
      padding-left: 10px;
      .note-header {
        display: flex;
        align-items: center;
        .name-time {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          width: 100%;
          padding-right: 12px;
          .name,
          .time {
            display: inline-block;
            margin-bottom: 4px;
          }
          .name {
            margin-right: 10px;
            color: #000000;
            font-weight: 600;
          }
          .time {
            font-weight: 500;
            position: relative;
            padding-left: 16px;
            &:before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #aeaeae;
            }
          }
        }
        .action-btn-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
          flex-shrink: 0;
          .action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #ffffff;
            padding: 7px;
            transition: 0.5s;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
            &:last-child {
              margin-left: 6px;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff;
              }
            }
          }
        }
      }
      .attachment-wrapper {
        .file__card {
          width: 50%;
          margin-bottom: 7px;
          .inner-border-wrapper {
            background-color: #ffffff;
            border: 1px solid #ececec;
          }
          .inner-wrapper {
            padding-top: 0px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }
          .file__preview {
            position: static;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            img {
              border-radius: 6px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .file-name {
            font-size: 16px;
            font-weight: 400;
            color: grey;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
          .file__preview__details {
            display: flex;
            width: calc(100% - 69px);
            padding-right: 12px;
            align-items: center;
          }
          .overlay {
            position: static;
            opacity: 1;
            z-index: 2;
            background-color: transparent;
            display: inline-flex;
            flex-shrink: 0;
            width: auto;
            height: auto;
            .action-btn-wrapper {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .action-btn {
                width: 32px;
                height: 32px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #ffffff;
                padding: 7px;
                transition: 0.5s;
                cursor: pointer;
                margin-right: 0px;
                margin-left: 0px;
                svg {
                  width: 100%;
                  height: 100%;
                  color: #777 !important;
                }
                &:last-child {
                  margin-left: 4px;
                }
                &:hover {
                  background-color: #000000;
                  svg {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// new tab & details design end

// user guide
.learn-more-button{
  position:fixed;
  top:80px;
  right:0px;
  z-index:5;
  transform:translateX(calc(100% - 35px));
  transition:0.4s;
  .btn{
    padding:0px;
    display:flex;
    align-items:center;
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
    border-top-left-radius:100px;
    border-bottom-left-radius:100px;
    .icon-wrapper{
      width:35px;
      height:37px;
      display:flex;
      align-items:center;
      justify-content:center;
      font-size:16px;
      .inner__wrapper{
        width:26px;
        height:26px;
        border-radius:50%;
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:#000000;
        color:var(--primaryColor);
      }
    }
    .text{
      height:34px;
      padding:6px 10px;
      display:inline-flex;
      align-items:center;
      padding:3px 12px 3px 5px;
    }
  }
  &:hover{
    transform:translateX(0px);
    .icon-wrapper{
      font-size:16px;
    }
  }
    &.newWindow__page {
      top: 130px;
  
      .inner__wrapper {
        padding: 5px;
  
        svg {
          width: 100%;
          height: 100%;
        }
      }
  
      .text {
        display: none !important;
      }
  
      &:hover {
        transform: translateX(calc(100% - 35px));
      }
    }
}
.user-guide-page{
  .rdt_Table_Card{
    .user-list-first-name{
      height: 21px;
      overflow: hidden;
      display: block;
    }
  }
}
.user-guide-modal {
  &.modal-dialog {
    width: 1000px;
    max-width: calc(100% - 30px);
    margin: 30px auto;
  }
}

// user-guide-preview-modal
.user-guide-preview-modal{
  &.modal-dialog{
    width:1300px;
    max-width:calc(100% - 30px);
    margin:30px auto;
  }
  .text-wrapper{
    text-align:center;
    margin-bottom:20px;
  }
  .img-wrapper{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    img{
      display:inline-block;
      width:auto;
      max-width:100%;
      margin-bottom:15px;
      object-fit:contain;
      object-position:center;
      border-radius:10px;
    }
  }
  .video-wrapper{
    .video-box{
      width:100%;
      padding-top:50%;
      position: relative;
      margin-bottom:15px;
      border-radius:10px;
      overflow:hidden;
      &:last-child{
        margin-bottom:0px;
      }
      video{
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        object-fit:contain;
        object-position:center;
      }
      .cover-wrapper{
        height:100%;
        width:100%;
        position:absolute;
        top:0px;
        left:0px;
        background-color:rgba(0,0,0,0.6);
        .overllay-wrapper{
          height:100%;
          width:100%;
          position:absolute;
          top:0px;
          left:0px;
          background-color:rgba(0,0,0,0.6);
          z-index:2;
        }
        img{
          width:100%;
          height:100%;
          object-fit:cover;
          object-position:center;
        }
        .video-play-button {
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          box-sizing: content-box;
          display: block;
          width: 24px;
          height: 35px;
          /* background: #fa183d; */
          border-radius: 50%;
          padding: 0px 0px 0px 0px;
          border: none;
          z-index: 3;
        }
        .video-play-button span {
          display: block;
          position: relative;
          z-index: 3;
          width: 0;
          height: 0;
          border-left: 22px solid #fff;
          border-top: 14px solid transparent;
          border-bottom: 14px solid transparent;
          left: 4px;
          top: 1px;
        }
        .video-play-button:before {
          content: '';
          position: absolute;
          z-index: 0;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          display: block;
          width: 60px;
          height: 60px;
          background: var(--primaryColor);
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite;
        }
        .video-play-button:after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          display: block;
          width: 60px;
          height: 60px;
          background: var(--primaryColor);
          border-radius: 50%;
          transition: all 200ms;
        }
        .video-play-button:hover:after {
          background: var(--primaryColor);
        }
      }
    }
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
// user-guide-preview-modal-end
// user guide end

// direct__mail__card
.direct__mail__card {
  .card-body {
    padding: 12px 20px 20px 20px !important;
    height: calc(100dvh - 207px);
    overflow-y: auto;
    &.load-more-active {
      height: calc(100dvh - 275px);
    }
  }
  .loadMore-btn-wrapper {
    padding: 15px 10px 15px 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      width: 100%;
      height: 1px;
      background-color: #eae8e1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .company-email-inner-scroll {
    height: auto;
    max-height: unset;
  }
  .company-email-temp-card-row {
    .company-form-card {
      .body-wrapper {
        padding: 16px 15px 12px 15px;
        .cn-wrapper {
          .title {
            font-size: 16px;
          }
        }
      }
    }
  }
}
// direct__mail__card__end

// directmail-add-template-modal
.directmail-add-template-modal {
  &.modal-dialog {
    // width:635px;
    width: 765px;
    max-width: calc(100% - 30px);
  }
  .modal-header{
    .action-btn{
      width:33px;
      height:33px;
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      justify-content:center;
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      right:50px;
      padding:7px;
      cursor:pointer;
      border-radius:50%;
      svg{
        width:100%;
        height:100%;
        cursor:pointer;
        color:#000000;
      }
      &:hover{
        background-color:#000000;
        svg{
          color:#ffffff;
        }
      }
    }
  }
  .fr-box {
    .fr-wrapper {
      // min-height:842px;
      min-height: calc(100dvh - 535px);
    }
  }
}
// directmail-add-template-modal-end

// directmail-preview-modal
.directmail-preview-modal {
  &.modal-dialog{
    width:842px;
    max-width:100%;
  }
  .modal-header {
    .modal-title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
  .page-wrapper{
    width: 100%;
    position: relative;
    padding-top: 131%;
    margin-bottom:20px;
    .page-wrapper-height{
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 46px;
      border: 1px solid #ddd;
      background-color: #ffffff;
      border-radius: 10px;
      left: 0px;
      .page-wrapper-height-inner{
        width: 100%;
        height: 100%;
        overflow: hidden;
        display:flex;
        flex-wrap:wrap;
      }
    }
    &:last-child{
      margin-bottom:0px;
    }
    &.postcard{
      width: 100%;
      padding-top: 63%;
      position: relative;
      .view-label{
        font-size:2vw;
        font-weight:700;
        color:#000000;
        position:absolute;
        top:15px;
        right:20px;
        opacity:0.05;
      }
      .logo-wrapper{
        max-width:300px;
        max-height:350px;
        img{
          max-width:300px;
          max-height:350px;
          object-fit:contain;
          object-position:left;
          overflow:hidden;
        }
      }
    }
  }
}
// directmail-preview-modal-end

// directmail-template-card
.email-template-card.directmail-template-card {
  .inner-card-wrapper {
    .grid-card-view-toggle-btns {
      .view-btn.active {
        svg {
          color: #000000;
        }
      }
    }
  }
  .directmail__listing__wrapper {
    & > .card-body {
      padding: 12px 20px 20px 20px !important;
      height: calc(100dvh - 207px);
      overflow-y: auto;
      &.load-more-active {
        height: calc(100dvh - 275px);
      }
    }
    .loadMore-btn-wrapper {
      padding: 15px 10px 15px 10px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        width: 100%;
        height: 1px;
        background-color: #eae8e1;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .company-form-card{
      .body-wrapper{
        padding-bottom:50px;
      }
    }
  }
  .company-email-inner-scroll {
    padding-bottom: 0px;
    height: auto;
    max-height: unset !important;
  }
  .company-form-card {
    .body-wrapper {
      padding-bottom: 10px;
      .data-row {
        .label {
          font-size: 17px;
          font-weight: 500;
        }
        .value {
          font-size: 22px;
          color: var(--primaryColorDark);
          margin-top: 4px;
          font-weight: 500;
        }
      }
    }
  }
}
// directmail-template-card-end

// create-directemail-page
.create-directemail-loader-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 150px);
  .spinner-border {
    width: 50px;
    height: 50px;
  }
}
// create-directemail-page-end

// view-direct-mail-modal
.view-direct-mail-modal{
  &.modal-dialog{
    width:835px;
    max-width:calc(100% - 30px);
    margin:30px auto;
    &.contacts-tab-active{
      width:1300px;
    }
  }
  .modal-header{
    .modal-title{
      width:100%;
      display:flex;
      align-items:center;
      .title-text{
        width:100%;
        display:block;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
    .icon-wrapper{
      width:28px;
      height:28px;
      border-radius:50%;
      padding:6px;
      display:flex;
      align-items:center;
      justify-content:center;
      cursor: pointer;
      transition: 0.5s;
      position: relative;
      right: -5px;
      svg {
        width: 100%;
        height: 100%;
        color: #000000;
        transition: 0.5s;
      }
      &:hover {
        background-color: #000000;
        svg {
          color: #ffffff;
        }
      }
    }
  }
  .top__header {
    position: relative;
    padding-bottom:15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .print__btn{
      width:170px !important;
      svg{
        width:18px;
        height:18px;
      }
    }
  }
  .contact__wrapper {
    .card {
      border: none;
      .card-body {
        padding: 0px 0px;
        .rdt_Table_wrapper {
          .search-box-wrapper {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .print__envelop__loader {
    min-height: calc(100dvh - 250px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border {
      width: 40px;
      height: 40px;
    }
  }
  .envelop__box {
    border: 1px solid #ececec;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
    min-height: 942px;
    overflow: hidden;
    // min-height:1042px;
    .top-img {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;
      opacity: 0.5;
    }
    .bottom-img {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      opacity: 0.5;
    }
    .inner__wrapper {
      position: relative;
      z-index: 3;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
// view-direct-mail-modal-end

// add-edit-direct-mail
.addEdit-direct-mail-popup{
  display:inline-block;
  position: fixed;
  right: 0px;
  top: 85px;
  transform:translateX(calc(100% + 12px));
  transition:0.5s;
  z-index:3;
  .inner-wrapper{
    background:#ffffff;
    border-top-left-radius:12px;
    border-bottom-left-radius:12px;
    overflow:hidden;
    box-shadow:rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
    width: 740px;
    max-width:100%;
  }
  .button-sticky{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    background-color: #000000;
    border: none;
    padding: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    left: -50px;
    svg{
      width: 100%;
      height: 100%;
      color: white;
    }
  }
  .top-header{
    display: flex;
    align-items:center;
    width:100%;
    padding: 10px 14px 10px 14px;
    border-bottom: 1px solid #eaeaea;
    .title{
      font-size: 22px;
      font-weight: 700;
      color: #000000;
      width: 100%;
      margin-bottom: 0px;
      padding-right: 15px;
    }
    .close-btn{
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      border: none;
      background-color: transparent;
      border-radius: 50%;
      padding: 5px;
      svg{
        width:100%;
        height:100%;
        color:#000000;
      }
      &:hover{
        background-color:#e3e3e3;
      }
    }
  }
  .scroll-wrapper{
    padding:15px 15px 18px 15px;
    max-height: calc(100dvh - 230px);
    overflow-y: auto;
    .page-break-btn{
      padding:7px 15px 9px 15px;
      background-color:#eeeeee;
      border:none;
      border-radius:5px;
      transition:0.5s;
      margin-top:15px;
      &:hover{
        background-color:#000000;
        color:#ffffff;
      }
    }
  }
  .radio-btn-wrapper{
    flex-wrap: wrap;
    margin-bottom:7px;
    .radio-btn-repeater{
      margin-right: 20px !important;
      padding-left: 28px !important;
      margin-bottom: 10px !important;
      position: relative;
      .form-check-input{
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 4;
        opacity: 0;
        margin: 0px !important;
        cursor: pointer;
        left: 0px;
      }
      .form-check-label{
        font-size: 16px;
        margin-bottom: 0px !important;
        &:before{
          content: "";
          position: absolute;
          top: 1px;
          width: 20px;
          height: 20px;
          transition: 0.5s;
          border-radius: 50%;
          background-color: #ffffff;
          border: 1px solid #ddd;
          left: 0px;
        }
        &:after{
          content: "";
          position: absolute;
          top: 6px;
          width: 10px;
          height: 10px;
          opacity: 0;
          transition: 0.5s;
          border-radius: 50%;
          background-color: #ffffff;
          left: 5px;
        }
      }
      .form-check-input:checked + .form-check-label{
        &:before{
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);
        }
        &:after{
          opacity: 1;
        }
      }
    }
  }
  .note-loyal{
    display:flex;
    flex-wrap:wrap;
    font-size:16px;
    margin-bottom:12px;
    .label{
      color:#000000;
      margin-right:5px;
    }
  }
  .upload-file-wrapper{
    margin-bottom:14px;
    &:last-child{
      margin-bottom:0px;
    }
  }
  .fileUpload-custom{
    background-color:#ffffff;
    border:1px dashed grey;
    border-radius:7px;
    padding:10px 10px;
    margin-bottom:10px;
    position: relative;
    display:flex;
    align-items:center;
    input{
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      cursor: pointer;
      opacity:0;
    }
    .btn-wrapper{
      display:inline-block;
      position: relative;
      margin-right:8px;
      flex-shrink:0;
    }
    .uploadFile-btn{
      background-color:var(--primaryColor);
      color:#000000;
      border-radius:6px;
      padding:7px 14px;
      border:none;
      font-size:14px;
      font-weight:500;
    }
    .file-name{
      display:inline-block;
      width:100%;
      max-width:100%;
      white-space:pre;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .spinner-border{
      width:16px;
      height:16px;
      flex-shrink:0;
      margin-left:8px;
    }
    .btn-close{
      display:inline-block;
      flex-shrink:0;
      width:22px;
      height:22px;
      transition:0.5s;
      margin-left:8px;
      border-radius:50%;
      &:hover{
        background-color:#eee;
      }
    }
  }
  .submit-btn-wrapper{
    padding: 10px 14px 10px 14px;
    border-top: 1px solid #eaeaea;
  }
  &.open{
    transform:translateX(0px);
    .button-sticky{
      display:none;
    }
  }
  .letter-header-textEditor,
  .letter-footer-textEditor{
    .e-richtexteditor{
      .e-rte-content{
        .e-content{
          min-height:200px !important;
        }
      }
    }
  }
}
// add-edit-direct-mail-end

// direct-mail-preview-wrapper
.direct-mail-preview-page{
  width:100%;
  background-color:#ffffff;
  border-radius: 10px;
  padding:20px;
  .page-title{
    font-size:24px;
    font-weight:600;
    color:#000000;
    margin-bottom:15px;
    position:relative;
    padding-left:45px;
    padding-right:45px;
    width:815px;
    max-width:100%;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: -4px;
      left: 0px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .print-btn{
      width:30px;
      height:30px;
      border-radius:50%;
      background-color:var(--primaryColor);
      padding:7px;
      cursor:pointer;
      display:flex;
      align-items:center;
      justify-content:center;
      position:absolute;
      top:0px;
      right:7px;
      svg{
        color:#000000;
        width:100%;
        height:100%;
      }
    }
  }
}
.direct-mail-preview-scroll{
  width:100%;
  overflow-x:auto;
}
.direct-mail-preview-wrapper{
  display:inline-block;
  // width:815px;
  // width:8.3in;
  // width:850px;
  width:802.8px;
  .scroll-wrapper{
    height: calc(100dvh - 228px);
    overflow-y: auto;
    padding-right:15px;
    width:100%;
    overflow-x:hidden;
  }
  .page-wrapper{
    width:100%;
    // width:800px;
    // height:1047px;
    margin-bottom:20px;
    position: relative;
    // padding-top:141%;
    // padding-top: 137.8%;
    // padding-top:132.8%;
    padding-top:130%;
    .page-wrapper-height{
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      padding:46px;
      border:1px solid #ddd;
      background-color:#ffffff;
      border-radius:10px;
      overflow:hidden;
      .page-wrapper-height-inner{
        width:100%;
        height:100%;
        overflow:hidden;
        display:flex;
        flex-wrap:wrap;
        align-content:space-between;
        position: relative;
        .direct-mail-letter-header,
        .direct-mail-letter-footer{
          max-height:35%;
          overflow:hidden;
          width:100%;
          background-color:#ffffff;
          z-index:3;
          p{
            margin-bottom:0px !important;
          }
        }
        .direct-mail-letter-header{
          // padding-bottom:32px;
          position:absolute;
          top:0px;
          left:0px;
          width:100%;
          .dm-letter-bottom{
            position: absolute;
            bottom: 0px;
            left:0px;
            z-index: 2;
            background-color: #fafafa;
            font-size: 14px;
            padding: 3px 10px;
            width: 100%;
            border-top: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            display:none;
          }
        }
        .direct-mail-letter-footer{
          // padding-top:32px;
          position:absolute;
          bottom:0px;
          left:0px;
          width:100%;
          .dm-letter-bottom{
            position: absolute;
            top: 0px;
            left:0px;
            z-index: 2;
            background-color: #fafafa;
            font-size: 14px;
            padding: 3px 10px;
            width: 100%;
            border-top: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            display:none;
          }
        }
        .direct-mail-letter-contant{
          height:100%;
          display:flex;
          // align-items:center;
          .inner-wrapper{
            width:100%;
            padding:10px 0px;
            max-height:100%;
            overflow:hidden;
          }
        }
      }
    }
    &:last-child{
      margin-bottom:0px;
    }
    &.postcard{
      width:100%;
      position: relative;
      padding-top: 63%;
      .view-label{
        font-size:2vw;
        font-weight:700;
        color:#000000;
        position:absolute;
        top:15px;
        right:20px;
        opacity:0.05;
      }
      .logo-wrapper{
        max-width:300px;
        max-height:350px;
        img{
          max-width:300px;
          max-height:350px;
          object-fit:contain;
          object-position:left;
          overflow:hidden;
        }
      }
    }
  }
}
// direct-mail-preview-wrapper-end

// direct-mail-print
// .direct-mail-print{
//   width:100%;
//   height:950px;
//   position: relative;
//   .direct-mail-letter-contant{
//     width:100%;
//     height:100%;
//     display:flex;
//     align-items:center;
//     .inner-wrapper{
//       width:100%;
//       max-height:calc(100% - 10px);
//       overflow:hidden;
//     }
//   }
//   .direct-mail-letter-header{
//     position:absolute;
//     top:0px;
//     left:0px;
//     width:100%;
//     max-height:35%;
//     overflow:hidden;
//     border-bottom:1px solid grey;
//   }
//   .direct-mail-letter-footer{
//     position:absolute;
//     bottom:0px;
//     left:0px;
//     width:100%;
//     max-height:35%;
//     overflow:hidden;
//     border-top:1px solid grey;
//   }
// }
// direct-mail-print-end

// feature-requests
.feature-requests-header{
  margin-bottom:15px;
  .add-btn{
    svg{
      position:relative;
      top:-1px;
    }
    .btn-text{
      display:inline-block;
      margin-left:7px;
    }
  }
  .view-toggle-btn{
    .view-btn{
      &.active{
        background-color:var(--primaryColor);
        svg{
          color:#000000;
        }
      }
    }
  }
}
.feature-requests-card{
  .search-box-wrapper{
    svg{
      fill:#b9b9b9;
    }
  }
  .loadMore-btn-wrapper{
    padding:15px 10px 15px 10px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0px;
      width: 100%;
      height: 1px;
      background-color: #eae8e1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.support__tickets__card{
  &.feature-requests-card{
    & > .card-body{
      height:calc(100dvh - 266px);
      &.load-more-active{
        height:calc(100dvh - 336px);
      }
    }
  }
}
.feature-requests-page{
  .mobile-page-title{
    display:none;
  }
  .rdt_Table_wrapper{
    .message-btn{
      position: relative;
      .count{
        position: absolute;
        font-size: 0px;
        width: 14px;
        height: 14px;
        top: 1px;
        padding: 0px;
        border-radius: 50%;
        background-color: var(--primaryColor);
        border: 2px solid #fff;
        right:0px;
      }
    }
  }
}

// add-feature-request-modal
.add-feature-request-modal{
  .title{
    font-size:16px;
    color:grey;
  }
  textarea{
    height:150px;
  }
  .submit-btn-wrapper{
    display:flex;
    justify-content:flex-end;
    padding-top:10px;
    .btn{
      width:150px !important;
    }
  }
}
// add-feature-request-modal-end
// feature-requests-end

// envelope-listing-table
.envelope-listing-table{
  border-radius:10px;
  border: 1px solid #ececec;
  border-radius: 12px;
  padding:0px 0px !important;
  .table-header{
    .table-row{
      border-top-left-radius:10px;
      border-top-right-radius:10px;
      background-color: var(--primaryColor);
      .table-cell{
        font-size: 14px;
        font-weight: 600;
        color: #000000;
      }
    }
  }
  .table-body{
    .table-row{
      border-bottom: 1px solid #ececec !important;
      &:last-child{
        border-bottom-left-radius:10px;
        border-bottom-right-radius:10px;
        border-bottom:none !important;
      }
    }
  }
  .table-row{
    display:flex;
    flex-wrap:wrap;
    .table-cell{
      padding:15px 15px !important;
      &:nth-child(1){
        width:calc(100% - 180px);
        display:flex;
        align-items:center;
        .template-name{
          white-space:pre;
          overflow:hidden;
          text-overflow:ellipsis;
          display:inline-block;
          width:100%;
        }
      }
      &:last-child{
        width:180px;
      }
    }
  }
  .action-btn-wrapper{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    .action-btn{
      width:28px;
      height:28px;
      padding:7px;
      display:flex;
      align-items:center;
      justify-content:center;
      margin-right:2px;
      cursor: pointer;
      border-radius:50%;
      svg{
        width:100%;
        height:100%;
      }
      &:last-child{
        margin-right:0px;
      }
      &:hover{
        background-color:#f0f0f0;
      }
    }
  }
  .no-record-found-table{
    padding:36px 15px;
  }
}
// envelope-listing-table-end

// add-update-envelope-modal
.envelope-preview-box{
  width:100%;
  height:100%;
  .inner-width-wrapper{
    border-radius:5px;
    overflow:hidden;
    // padding-top:16px;
    // padding-bottom:16px;
    border:1px solid #e1e1e1;
    position: relative;
    // min-height:500px;
    // height:500px;
    margin:0px 0px;
    .margin-wrapper{
      height:100%;
      width:100%;
      overflow:hidden;
      // background-color:red;
      .inner-relative-wrapper{
        position: relative;
      }
      .e-richtexteditor{
        border:none !important;
        border-radius:0px !important;
        height:100% !important;
        .e-rte-content{
          height:100% !important;
          .e-content{
            padding:0px 0px !important;
            min-height:unset !important;
            height:100% !important;
            overflow:hidden !important;
          }
        }
        .e-rte-table{
          td{
            border:1px solid #f4f4f4 !important;
            padding:0px 0px !important;
          }
        }
      }
    }
  }
  .contant-wrapper{
    position:absolute;
    z-index:4;
    display:flex;
    align-items:flex-start;
    width:100%;
    .logo-wrapper{
      display:inline-block;
      flex-shrink:0;
      img{
        width:100% !important;
      }
    }
    .returnAddress-wrapper,
    .mailingAddress-wrapper{
      font-size:16px;
      line-height:24px;
      color:#000000;
      font-weight:400;
      // max-width:calc(50% - 102px);
    }

    &.top-left-position{
      justify-content:flex-start;
      // position:absolute;
      top:0px;
      left:0px;
    }
    &.top-right-position{
      justify-content:flex-end;
      // position:absolute;
      top:0px;
      right:0px;
    }
    &.bottom-left-position{
      justify-content:flex-start;
      // position:absolute;
      bottom:0px;
      left:0px;
    }
    &.bottom-right-position{
      justify-content:flex-end;
      // position:absolute;
      bottom:0px;
      right:0px;
    }
    &.center-position{
      justify-content:center;
      // position:absolute;
      top:50%;
      transform:translateY(-50%);
      left:0px;
      right:0px;
    }
    p,
    h1,
    h2,h3,h4,h5,h6,ul,ol{
      margin-top:0px;
    }
  }
}

.envelope-print-wrapper{
  .inner-relative-wrapper{
    position: relative;
  }
  .e-richtexteditor{
    border:none !important;
    border-radius:0px !important;
    height:100% !important;
    .e-rte-content{
      height:100% !important;
      .e-content{
        padding:0px 0px !important;
        min-height:unset !important;
        height:100% !important;
        overflow:hidden !important;
      }
    }
    .e-rte-table{
      td{
        border:none !important;
        padding:0px 0px !important;
      }
    }
  }
  .contant-wrapper{
    position:absolute;
    z-index:4;
    display:flex;
    align-items:flex-start;
    width:100%;
    .logo-wrapper{
      display:inline-block;
      flex-shrink:0;
      img{
        width:100% !important;
      }
    }
    .returnAddress-wrapper,
    .mailingAddress-wrapper{
      font-size:16px;
      line-height:24px;
      color:#000000;
      font-weight:400;
      // max-width:calc(50% - 102px);
    }

    &.top-left-position{
      justify-content:flex-start;
      // position:absolute;
      top:0px;
      left:0px;
    }
    &.top-right-position{
      justify-content:flex-end;
      // position:absolute;
      top:0px;
      right:0px;
    }
    &.bottom-left-position{
      justify-content:flex-start;
      // position:absolute;
      bottom:0px;
      left:0px;
    }
    &.bottom-right-position{
      justify-content:flex-end;
      // position:absolute;
      bottom:0px;
      right:0px;
    }
    &.center-position{
      justify-content:center;
      // position:absolute;
      top:50%;
      transform:translateY(-50%);
      left:0px;
      right:0px;
    }
    p,
    h1,
    h2,h3,h4,h5,h6,ul,ol{
      margin-top:0px;
    }
  }
}
.add-update-envelope-modal{
  &.modal-dialog{
    width:1400px;
    max-width:100%;
  }
  .modal-content{
    position: relative;
  }
  .modal-header{
    display:inline-flex;
    align-items:center;
    justify-content:center;
    width:auto;
    padding:0px 0px;
    width:34px;
    height:34px;
    background-color:transparent;
    position:absolute;
    right: 15px;
    top: 19px;
    z-index:3;
    .modal-title{
      display:none;
    }
    .btn-close{
      position:static;
      transform:unset !important;
      &:hover{
        background-color:#ffffff;
      }
    }
  }
  .modal-body{
    padding:0px 0px;
  }
  .main-wrapper{
    display:flex;
    flex-wrap:wrap;
    .envelope-preview-wrapper{
      width:60%;
      padding:25px;
      .inner-sec-wrapper{
        // min-height:calc(100dvh - 118px);
        width:100%;
      }
    }
    .form-option-wrapper{
      width:40%;
      padding:20px 20px;
      background-color: #f2f2f2;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
      .inner-sec-wrapper{
        position: relative;
      }
      .top-header-title{
        padding-right:35px;
        display:flex;
        align-items:center;
        margin-bottom:12px;
        .action-btn{
          width:32px;
          height:32px;
          display:flex;
          align-items:center;
          justify-content:center;
          transition:0.5s;
          border-radius:50%;
          cursor: pointer;
          padding:8px;
          flex-shrink:0;
          svg{
            width:100%;
            height:100%;
          }
          &:hover{
            background-color:#ffffff;
          }
        }
      }
      .title{
        font-size:20px;
        font-weight:700;
        color:#000000;
        width:100%;
        margin-bottom:0px;
        padding-right: 128px;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      .scroll-wrapper{
        height: calc(100dvh - 200px);
        overflow-y: auto;
      }
      .opation-box{
        margin-bottom: 16px;
        padding-bottom:8px;
        border-bottom: 1px solid #dfdfdf;
        &:first-child{
          padding-bottom:16px;
        }
        &:last-child{
          margin-bottom: 0px;
          padding-bottom: 0px;
          border-bottom:none;
        }
        .fileUpload-custom{
          background-color:#ffffff;
          border:1px dashed grey;
          border-radius:7px;
          padding:10px 10px;
          margin-bottom:10px;
          position: relative;
          display:flex;
          align-items:center;
          input{
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            cursor: pointer;
            opacity:0;
          }
          .btn-wrapper{
            display:inline-block;
            position: relative;
            margin-right:8px;
            flex-shrink:0;
          }
          .uploadFile-btn{
            background-color:var(--primaryColor);
            color:#000000;
            border-radius:6px;
            padding:7px 14px;
            border:none;
            font-size:14px;
            font-weight:500;
          }
          .file-name{
            display:inline-block;
            width:auto;
            max-width:100%;
            white-space:pre;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          .spinner-border{
            width:16px;
            height:16px;
            flex-shrink:0;
            margin-left:8px;
          }
          .btn-close{
            display:inline-block;
            flex-shrink:0;
            width:22px;
            height:22px;
            transition:0.5s;
            margin-left:8px;
            border-radius:50%;
            &:hover{
              background-color:#eee;
            }
          }
        }
        textarea{
          min-height:140px;
          margin-bottom:10px;
        }
        .cn-position-wrapper{
          .form-label{
            margin-bottom:10px;
          }
          .radio-btn-wrapper{
            flex-wrap:wrap;
            .radio-btn-repeater{
              margin-right:15px !important;
              padding-left:28px !important;
              margin-bottom:10px !important;
              position: relative;
              input{
                position:absolute;
                top:0px;
                left:0px;
                width:100%;
                height:100%;
                z-index:4;
                opacity:0;
                margin:0px !important;
                cursor: pointer;
              }
              .form-check-label{
                font-size:16px;
                margin-bottom:0px !important;
                &:before{
                  content:"";
                  position:absolute;
                  top:1px;
                  left: 0px;
                  width: 20px;
                  height: 20px;
                  border-radius:50%;
                  background-color:#ffffff;
                  border:1px solid #ddd;
                  transition:0.5s;
                }
                &:after{
                  content: "";
                  position: absolute;
                  top: 6px;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: #ffffff;
                  left:5px;
                  opacity:0;
                  transition:0.5s;
                }
              }
              input:checked + .form-check-label{
                &::before{
                  background-color:var(--primaryColor);
                  border:1px solid var(--primaryColor);
                }
                &:after{
                  opacity:1;
                }
              }
            }
          }
        }
        .logo-wrapper{
          display:flex;
          flex-wrap:wrap;
          align-items:flex-start;
          .upload-file-wrapper{
            width:400px;
          }
          .cn-position-wrapper{
            width:calc(100% - 400px);
            padding-left:20px;
            .radio-btn-wrapper{
              margin-top:11px;
            }
          }
          .range-col{
            width:400px;
            padding:5px 0px 10px 0px;
            .range-slider-wrapper{
              display:flex;
              flex-wrap:wrap;
              align-items:center;
              .form-range{
                width:calc(100% - 114px);
              }
              .form-range::-moz-range-track{
                background-color:#ffffff;
              }
              .form-range::-webkit-slider-runnable-track{
                background-color:#ffffff;
              }
              .value{
                font-size:16px;
                color:#000000;
                font-weight:600;
                text-align:right;
                width:65px;
                display:inline-block;
                padding-left:12px;
                &:first-child{
                  padding-left:0px;
                  text-align:left;
                  padding-right:12px;
                  width:47px;
                }
              }
            }
          }
        }
        &.address-wrapper{
          display:flex;
          flex-wrap:wrap;
          .return-address-wrapper,
          .Mailing-address-wrapper{
            width:50%;
            .cn-position-wrapper{
              margin-top:12px;
            }
          }
          .return-address-wrapper{
            padding-right:12px;
          }
          .Mailing-address-wrapper{
            padding-left:12px;
          }
          .rdw-editor-main{
            background-color:white;
            border-bottom-left-radius:10px;
            border-bottom-right-radius:10px;
            min-height:225px !important;
          }
        }
        .range-row{
          display:flex;
          flex-wrap:wrap;
          margin:0px -10px;
          .range-col{
            width:33.3333%;
            padding:0px 10px;
            .range-slider-wrapper{
              display:flex;
              flex-wrap:wrap;
              align-items:center;
              .form-range{
                width:calc(100% - 114px);
              }
              .form-range::-moz-range-track{
                background-color:#ffffff;
              }
              .form-range::-webkit-slider-runnable-track{
                background-color:#ffffff;
              }
              .value{
                font-size:16px;
                color:#000000;
                font-weight:600;
                text-align:right;
                width:65px;
                display:inline-block;
                padding-left:12px;
                &:first-child{
                  padding-left:0px;
                  text-align:left;
                  padding-right:12px;
                  width:47px;
                }
              }
            }
          }
        }
      }
      .submit-btn-wrapper{
        display: inline-flex;
        position: absolute;
        top: -3px;
        right: 80px !important;
        .btn{
          width:100px !important;
        }
      }
    }
  }

  // new-design
  &.modal-dialog{
    width:1200px;
    max-width:calc(100% - 30px);
    padding-right:0px;
    margin:30px auto;
  }
  .main-wrapper{
    .form-option-wrapper,
    .envelope-preview-wrapper{
      width:100%;
    }
    .form-option-wrapper{
      order:-1;
      border-top-left-radius:14px;
      border-bottom-right-radius:0px;
      .scroll-wrapper{
        height:auto;
      }
    }
  }
  // new-design
}
// add-update-envelope-modal-end

// envelope-page-start
.envelope-preview-wrapper{
  padding:36px 0px;
  // display:none;
  overflow-x:auto;
}
.invelope-preview-new{
  display:flex;
  flex-wrap:wrap;
  width:1300px;
  // max-width:100%;
  margin:0px auto;
  .inner-sec-wrapper{
    width: 1177px;
    max-width:100%;
    display:flex;
    align-items:flex-end;
    justify-content:flex-start;
    .envelope-preview-box{
      width:100%;
      height:auto;
    }
  }
  .vertical-scale-wrapper{
    width:60px;
    border-left:1px solid #000000;
    display: flex;
    flex-wrap: wrap;
    padding-right: 15px;
    .inch-scale-box{
      display:block;
      position: relative;
      border-bottom:1px solid #000000;
      height:98px;
      width:24px;
      &::before,
      &::after{
        content:"";
        position:absolute;
        top:-12px;
        right:-18px;
        font-weight:600;
        font-size:16px;
        color:#000000;
      }
      &::after{
        opacity:0;
      }
      .small-scale-line,
      .half-scale-line{
        display:block;
        height:1px;
        background-color:#000000;
        width:10px;
        margin-top:8.8px;
      }
      .half-scale-line{
        width:18px;
      }
      &:first-child{
        border-top:1px solid #000000;
        &:before{
          content:"6";
        }
      }
      &:nth-child(2){
        &:before{
          content:"5";
        }
      }
      &:nth-child(3){
        &:before{
          content:"4";
        }
      }
      &:nth-child(4){
        &:before{
          content:"3";
        }
      }
      &:nth-child(5){
        &:before{
          content:"2";
        }
      }
      &:nth-child(6){
        &:before{
          content:"1";
        }
        &:after{
          content:"0";
          opacity:1;
          top:unset;
          bottom:-12px;
        }
      }
    }
  }
  .horizontal-scale-wrapper{
    width: 1177px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    margin-left: 60px;
    border-bottom:1px solid #000000;
    .inch-scale-box{
      display:block;
      position: relative;
      border-right:1px solid #000000;
      width:98px;
      height:24px;
      display:flex;
      flex-wrap:wrap;
      align-items:flex-end;
      &::before,
      &::after{
        content:"";
        position:absolute;
        top:-22px;
        left:-10px;
        font-weight:600;
        font-size:16px;
        color:#000000;
        width:20px;
        text-align:center;
      }
      &::after{
        opacity:0;
      }
      .small-scale-line,
      .half-scale-line{
        display:block;
        width:1px;
        height:10px;
        background-color:#000000;
        margin-left:8.8px;
      }
      .half-scale-line{
        height:18px;
      }
      &:first-child{
        border-left:1px solid #000000;
        &:before{
          content:"0";
        }
      }
      &:nth-child(2){
        &:before{
          content:"1";
        }
      }
      &:nth-child(3){
        &:before{
          content:"2";
        }
      }
      &:nth-child(4){
        &:before{
          content:"3";
        }
      }
      &:nth-child(5){
        &:before{
          content:"4";
        }
      }
      &:nth-child(6){
        &:before{
          content:"5";
        }
      }
      &:nth-child(7){
        &:before{
          content:"6";
        }
      }
      &:nth-child(8){
        &:before{
          content:"7";
        }
      }
      &:nth-child(9){
        &:before{
          content:"8";
        }
      }
      &:nth-child(10){
        &:before{
          content:"9";
        }
      }
      &:nth-child(11){
        &:before{
          content:"10";
        }
      }
      &:nth-child(12){
        &:before{
          content:"11";
        }
        &:after{
          content:"12";
          opacity:1;
          left:unset;
          right:-10px;
        }
      }
    }
  }
}
.envelope-page{
  background-color:#ffffff;
  border-radius: 8px;
  border: 1px solid #eae8e1;
  background-color: #fff;
  .top-header-envelope-add{
    display:flex;
    align-items:flex-start;
    margin-bottom:15px;
    .left{
      width:calc(100% - 234px);
      padding-right:15px;
    }
    .right{
      display:inline-flex;
      align-items:center;
      position: relative;
      top: 30px;
    }
    .info{
      display:inline-block;
      width:24px;
      height:24px;
      background-color:#000000;
      border-radius:50%;
      position: relative;
      margin-left: 10px;
      cursor: pointer;
      .dot{
        position:absolute;
        top: 5px;
        left: 10px;
        width: 4px;
        height: 4px;
        background-color:var(--primaryColor);
        border-radius:50%;
      }
      .line{
        height: 8px;
        width: 2px;
        position: absolute;
        top: 11px;
        border-radius: 10px;
        background-color: var(--primaryColor);
        left: 11px;
      }
    }
  }
  .envelope-page-header{
    border-bottom: 1px solid #eaeaea;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    padding:12px 15px 12px 0px;
    .left{
      position: relative;
      padding-left: 60px;
      .back-arrow{
        width: 38px;
        height: 38px;
        font-size: 0px;
        position: absolute;
        top:50%;
        transform:translateY(-50%);
        transition: 0.5s;
        border-radius: 50%;
        cursor: pointer;
        left: 12px;
        &:before{
          content: "";
          position: absolute;
          top: 13px;
          width: 12px;
          height: 12px;
          border-bottom: 3px solid #000;
          left: 15px;
          border-left: 3px solid #000;
          transform: rotate(45deg);
        }
        &:hover{
          background-color:#f0f0f0;
        }
      }
      .title{
        margin-bottom:0px;
        font-size: 24px;
        font-weight: 600;
        color: #000000;
      }
    }
    .right{
      display:flex;
      align-items:center;
      .preview-btn{
        display:inline-flex;
        align-items:center;
        display:none;
        .label{
          font-size:16px;
          font-weight:600;
          color:grey;
          margin-right:8px;
          display:inline-block;
        }
      }
      .action-btn{
        width:38px;
        height:38px;
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:#e3e3e3;
        transition:0.5s;
        border-radius:50%;
        padding:9px;
        margin-left:13px;
        cursor:pointer;
        svg{
          width:100%;
          height:100%;
          color:#000000;
        }
        &:hover{
          background-color:#000000;
          svg{
            color:#ffffff;
          }
        }
      }
      .update-save-btn{
        margin-left:13px;
      }
    }
  }
  .envelope-body{
    padding:20px 20px;
    height: calc(100dvh - 206px);
    overflow-y: auto;
    position: relative;
    overflow-x:hidden;
  }
  .upload-file-wrapper{
    .form-label{
      margin-bottom:10px;
      width:100%;
    }
    .fileUpload-custom{
      background-color:#ffffff;
      border:1px dashed grey;
      border-radius:7px;
      padding:10px 10px;
      margin-bottom:10px;
      position: relative;
      display:flex;
      align-items:center;
      input{
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        cursor: pointer;
        opacity:0;
      }
      .btn-wrapper{
        display:inline-block;
        position: relative;
        margin-right:8px;
        flex-shrink:0;
      }
      .uploadFile-btn{
        background-color:var(--primaryColor);
        color:#000000;
        border-radius:6px;
        padding:7px 14px;
        border:none;
        font-size:14px;
        font-weight:500;
      }
      .file-name{
        display:inline-block;
        width:auto;
        max-width:100%;
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      .spinner-border{
        width:16px;
        height:16px;
        flex-shrink:0;
        margin-left:8px;
      }
      .btn-close{
        display:inline-block;
        flex-shrink:0;
        width:22px;
        height:22px;
        transition:0.5s;
        margin-left:8px;
        border-radius:50%;
        &:hover{
          background-color:#eee;
        }
      }
    }
  }
  .range-slider-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .form-range{
      width: calc(100% - 114px);
    }
    .value{
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      display: inline-block;
      text-align: right;
      padding-left: 12px;
      width: 65px;
      &:first-child{
        padding-left: 0px;
        text-align: left;
        padding-right: 12px;
        width: 47px;
      }
    }
  }
  .logo-width-rangeSlider{
    .form-label{
      margin-bottom:10px;
    }
  }
  .radio-btn-wrapper{
    flex-wrap:wrap;
    .radio-btn-repeater{
      margin-right:20px !important;
      padding-left: 28px !important;
      margin-bottom: 10px !important;
      position: relative;
      .form-check-input{
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 4;
        opacity: 0;
        margin: 0px !important;
        cursor: pointer;
        left: 0px;
      }
      .form-check-label{
        margin-bottom: 0px !important;
        font-size: 16px;
        &:before{
          content: "";
          position: absolute;
          top: 1px;
          width: 20px;
          height: 20px;
          transition: 0.5s;
          border-radius: 50%;
          background-color: #ffffff;
          border: 1px solid #ddd;
          left: 0px;
        }
        &:after{
          content: "";
          position: absolute;
          top: 6px;
          width: 10px;
          height: 10px;
          opacity: 0;
          transition: 0.5s;
          border-radius: 50%;
          background-color: #ffffff;
          left: 5px;
        }
      }
      .form-check-input:checked + .form-check-label:before{
        background-color: var(--primaryColor);
        border: 1px solid var(--primaryColor);
      }
      .form-check-input:checked + .form-check-label:after{
        opacity: 1;
      }
    }
  }
  .accordian-loyal-box{
    background-color: #ffffff;
    border-radius: 14px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    .accordian-loyal-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border-bottom: 1px solid transparent;
      // border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
      .inner-wrapper{
        padding: 14px 112px 14px 15px;
        width:100%;
        .title{
          font-size: 18px;
          font-weight: 600;
          color: #000;
          margin-bottom: 0px;
        }
        .down-arrow{
          width: 24px;
          height: 24px;
          position: absolute;
          top: 12px;
          transition: 0.5s;
          right: 12px;
          &:before{
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            top: 5px;
            border-bottom: 2px solid #000000;
            border-left: 2px solid #000000;
            transform: rotate(-45deg);
            left: 7px;
          }
        }
      }
    }
    .accordian-loyal-body{
      display:none !important;
      .accordian-loyal-body-inner{
        padding:15px 15px 10px 15px;
      }
    }
    &.active{
      .accordian-loyal-header{
        border-bottom: 1px solid #e8e8e8;
      }
      .accordian-loyal-body{
        display:block !important;
      }
    }
    &.logo-setting{
      .logo-wrapper{
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start;
        .upload-file-wrapper,
        .logo-width-rangeSlider{
          width:calc(50% - 330px);
          padding-right:30px;
        }
      }
      .logo-width-rangeSlider{
        .form-label{
          margin-bottom:27px;
        }
      }
      .cn-position-wrapper{
        .form-label{
          margin-bottom:27px;
        }
      }
    }
    &.return-address,
    &.mailing-address{
      .cn-position-wrapper{
        margin-top:20px;
        margin-bottom:10px;
        .form-label{
          margin-bottom:10px;
        }
      }
    }
    &.envelope-design-setting{
      .range-row{
        display:flex;
        flex-wrap:wrap;
        margin:0px -15px;
        .range-col{
          width:33.3333%;
          padding:0px 15px;
          margin-bottom:10px;
          .inner-wrapper{
            background-color: #f7f7f7;
            border-radius: 8px;
            padding: 15px 15px 20px 15px;
          }
          .form-label{
            margin-bottom:10px;
          }
          .form-range::-moz-range-track{
            background-color: #ffffff;
          }
          &.disable-sec{
            opacity:0.5;
            pointer-events:none;
          }
        }
      }
      .fixed-width-note{
        font-size:16px;
        margin-top:10px;
        .label{
          font-weight:500;
          color:#000000;
          display:inline-block;
          margin-right:7px;
        }
        .value{
          display:inline-block;
        }
      }
    }
    &.field-has-error{
      border:1px solid red;
    }
  }
  .envelope-setting-form-wrapper{
    .button-sticky,
    .preview-enable-header{
      display:none;
    }
  }
  &.preview-enable{
    .envelope-setting-form-wrapper{
      position:absolute;
      width:500px;
      top:20px;
      right:0px;
      z-index:9;
      transform:translateX(calc(100% + 11px));
      transition:0.5s;
      .button-sticky{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        left: -50px;
        background-color: #000000;
        border: none;
        padding: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        svg{
          width: 100%;
          height: 100%;
          color: white;
        }
      }
      .envelope-setting-box-cn{
        border: 1px solid #eaeaea;
        background-color: #ffffff;
        box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        .preview-enable-header{
          display:flex;
          padding: 10px 14px 8px 14px;
          border-bottom: 1px solid #eaeaea;
          .title{
            margin-bottom:0px;
            font-size: 22px;
            font-weight: 700;
            color: #000000;
            width: 100%;
            padding-right: 15px;
          }
          .close-btn{
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            border: none;
            background-color: transparent;
            border-radius: 50%;
            padding: 5px;
            svg{
              width: 100%;
              height: 100%;
            }
            &:hover{
              background-color:#e3e3e3;
            }
          }
        }
        .preview-enable-body{
          padding:20px 0px;
          .scroll-wrapper{
            max-height: calc(100dvh - 354px);
            overflow-y: auto;
            padding:0px 20px;
          }
        }
        .accordian-loyal-box{
          &.envelope-design-setting{
            .range-row{
              .range-col{
                width:100%;
                margin-bottom:18px;
                .inner-wrapper{
                  background-color:transparent;
                  padding:0px;
                  .form-range::-moz-range-track{
                    background-color:#f5f5f5;
                  }
                }
                &:last-child{
                  margin-bottom:10px;
                }
              }
            }
          }
          &.logo-setting{
            .cn-position-wrapper{
              .form-label{
                margin-bottom:14px;
              }
            }
            .logo-wrapper{
              .upload-file-wrapper,
              .logo-width-rangeSlider{
                width:100%;
                padding-right:0px;
              }
              .upload-file-wrapper{
                margin-bottom:15px;
              }
              .logo-width-rangeSlider{
                margin-bottom:25px;
                .form-label{
                  margin-bottom:10px;
                }
              }
            }
          }
        }
      }
      &.active{
        transform:translateX(0px);
        .button-sticky{
          display:none;
        }
      }
    }
    .envelope-preview-wrapper{
      display:block;
    }
  }
}


.envelope-print-wrapper{
  display:none;
}

@media print {
  .envelope-print-wrapper
  {
    display: block;
  }
  }
// envelope-page-end

// envelope-preview-modal
.envelope-preview-modal{
  &.modal-dialog{
    width:1220px;
    max-width:calc(100% - 30px);
    padding-right:0px;
    margin:30px auto;
  }
  .envelope-preview-wrapper{
    display:block;
    padding:0px 0px;
    .vertical-scale-wrapper,
    .horizontal-scale-wrapper{
      display:none;
    }
    .invelope-preview-new{
      width:100%;
      .inner-sec-wrapper{
        width:100%;
        .envelope-preview-box{
          display:flex;
          justify-content:center;
        }
      }
    }
  }
}
// envelope-preview-modal-end

// envelope-print
.envelope-print{
  display:none;
}
@media print {
  // @page {}
  body {
    margin:0mm 0mm 0mm 0mm !important;
    padding:0mm 0mm 0mm 0mm !important;
  }
  .envelope-print{
    display:block;
    .invelope-preview-new{
      width:100%;
      .vertical-scale-wrapper,
      .horizontal-scale-wrapper{
        display:none;
      }
      .inner-sec-wrapper{
        width:100%;
        align-items:flex-start;
      }
    }
    .envelope-preview-box{
      min-height:650px;
      height:650px;
    }
    .inner-width-wrapper{
      padding-top:0px !important;
    }
    .inner-width-wrapper{
      padding:0px;
    }
  } 
}
// envelope-print-end

// company-documents
.company-files-wrapper{
  .checklist-folder-list-box{
    .checklist-header{
      .title{
        max-width:calc(100% - 136px);
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
  }
}
// company-documents-end

// single-note-page
.single-note-page{
  height:100dvh;
  padding:0px 15px;
  .single-note-inner-page{
    width:800px;
    max-width:100%;
    border-radius:14px;
    margin:36px auto;
    background-color:#ffffff;
    // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px, rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
    padding:20px 20px;
    box-shadow:rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    min-height:80px;
    .loader{
      margin-top:17px;
    }
    .company-details{
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 15px;
      display:flex;
      align-items:center;
      &::before{
        content:"";
        position:absolute;
        bottom:0px;
        left:50%;
        transform:translateX(-50%);
        width:100%;
        height:1px;
        background-color:#e5e5e5;
        width:100%;
      }
      .avatar{
        width:42px;
        height:42px;
        img{
          width:100%;
          height:100%;
          object-fit:cover;
          object-position:center;
        }
        .avatar-content{
          width:100%;
          height:100%;
          background-color:var(--primaryColor) !important;
          color:#000000 !important;
          display:flex;
          align-items:center;
          justify-content:center;
          font-size: 14px;
        }
      }
      .form-label{
        font-size:22px;
        font-weight:600;
        width:calc(100% - 42px);
        padding-left:12px;
        margin-bottom:0px;
      }
    }
    .note-title{
      font-size:20px;
      font-weight:600;
      color:#000000;
      margin-bottom:12px;
    }
    .note-author-time{
      display:flex;
      align-items:center;
      margin-bottom: 10px;
      .avatar{
        width:42px;
        height:42px;
        img{
          width:100%;
          height:100%;
          object-fit:cover;
          object-position:center;
        }
        .avatar-content{
          width:100%;
          height:100%;
          background-color:var(--primaryColor) !important;
          color:#000000 !important;
          display:flex;
          align-items:center;
          justify-content:center;
          font-size: 14px;
        }
      }
      .right-details{
        width:calc(100% - 43px);
        padding-left:14px;
        .author-name{
          display:block;
          width:100%;
          font-size:16px;
          font-weight:600;
          color:#000000;
          margin-bottom: 2px;
        }
        .note-titme{
          font-size:14px;
          color:grey;
        }
      }
    }
    .attachment-wrapper{
      margin-top: 22px;
    }
  }


}
// single-note-page-end

// single-note-page
.single-note-page{
  height:100dvh;
  padding:0px 15px;
  .single-note-inner-page{
    width:800px;
    max-width:100%;
    border-radius:14px;
    margin:36px auto;
    background-color:#ffffff;
    // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px, rgba(0, 0, 0, 0.14) 0px 1px 2px !important;
    padding:20px 20px;
    box-shadow:rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    min-height:80px;
    .loader{
      margin-top:17px;
    }
    .company-details{
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 15px;
      display:flex;
      align-items:center;
      &::before{
        content:"";
        position:absolute;
        bottom:0px;
        left:50%;
        transform:translateX(-50%);
        width:100%;
        height:1px;
        background-color:#e5e5e5;
        width:100%;
      }
      .avatar{
        width:42px;
        height:42px;
        img{
          width:100%;
          height:100%;
          object-fit:cover;
          object-position:center;
        }
        .avatar-content{
          width:100%;
          height:100%;
          background-color:var(--primaryColor) !important;
          color:#000000 !important;
          display:flex;
          align-items:center;
          justify-content:center;
          font-size: 14px;
        }
      }
      .form-label{
        font-size:22px;
        font-weight:600;
        width:calc(100% - 42px);
        padding-left:12px;
        margin-bottom:0px;
      }
    }
    .note-title{
      font-size:20px;
      font-weight:600;
      color:#000000;
      margin-bottom:12px;
    }
    .note-author-time{
      display:flex;
      align-items:center;
      margin-bottom: 10px;
      .avatar{
        width:42px;
        height:42px;
        img{
          width:100%;
          height:100%;
          object-fit:cover;
          object-position:center;
        }
        .avatar-content{
          width:100%;
          height:100%;
          background-color:var(--primaryColor) !important;
          color:#000000 !important;
          display:flex;
          align-items:center;
          justify-content:center;
          font-size: 14px;
        }
      }
      .right-details{
        width:calc(100% - 43px);
        padding-left:14px;
        .author-name{
          display:block;
          width:100%;
          font-size:16px;
          font-weight:600;
          color:#000000;
          margin-bottom: 2px;
        }
        .note-titme{
          font-size:14px;
          color:grey;
        }
      }
    }
    .attachment-wrapper{
      margin-top: 22px;
    }
  }
  .private-note{
    padding-top:25px;
    .normal-text{
      font-size:18px;
      text-align:center;
      margin-bottom:24px;
      a{
        color:var(--primaryColorDark);
        &:hover{
          color:var(--primaryColorDark);
          text-decoration:underline;
        }
      }
    }
    .private-note-img{
      img{
        display:block;
        width:400px;
        max-width:100%;
        margin:0px auto;
      }
    }
  }
  .powered-by{
    padding-bottom:35px;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner-wrapper{
      display: inline-block;
      background-color: #f3f3f3;
      padding: 25px 36px;
      border-radius: 20px;
      margin-top:40px;
    }
    .powered-by-text{
      font-size:20px;
      color:#000000;
      font-weight:600;
      text-align:center;
    }
    .logo-wrapper{
      display:flex;
      align-items:center;
      justify-content:center;
      .logo-link{
        display:block;
        width:200px;
        svg{
          width:100%;
        }
      }
    }
  }
  .no-record-found-table{
    width:300px;
    max-width:100%;
    margin:30px auto;
  }
}
// single-note-page-end

// Shared note page 
.shared-note-page { 
  height: initial;
  .single-note-inner-page {
    .note-author-time {
      .avatar 
      {
        display: flex; 
        overflow: hidden !important;
        img {
          width: 42px;
          height: 42px;
          border-radius: 100%;
        }
      }
    } 
  }
  .attachment-wrapper {
    .file__card {
      .inner-border-wrapper {
        border: 1px solid #cacaca;
        box-sizing: border-box !important;
      }
      .inner-wrapper, .file__preview {
        height: 138px;
        width: 138px;
        padding: 0 !important;
        img {
          height: 138px;
          width: 138px;
          border-radius: 10px !important;
        }
      }
    }
  }
}

.single-note-page .single-note-inner-page .note-author-time .right-details {
  width: calc(100% - 32px) !important;
  padding: 0 !important;
  justify-content: space-between !important;
  display: flex !important;
  flex-direction: column !important;
  margin-left: 20px !important;
}

.NoteToPrint {
  .attachment-wrapper {
    .file__card {
      .file__preview {
        img {
          height: 125px !important;
          width: 125px !important;
          border-radius: 20px !important;
        }
      }
    }
  }
}

@media print {
  .shared-note-page { 
    height: initial;
    .single-note-inner-page {
      .note-author-time {
        .avatar 
        {
          display: flex !important;
          overflow: hidden !important;
          img {
            width: 42px !important;
            height: 42px !important;
            border-radius: 100% !important;
          }
        }
      } 
    }
    .attachment-wrapper {
      .file__card {
        .inner-border-wrapper {
          border: 1px solid #cacaca;
          box-sizing: border-box !important;
        }
        .inner-wrapper, .file__preview {
          height: 138px !important;
          width: 138px !important;
          padding: 0 !important;
          img {
            height: 138px !important;
            width: 138px !important;
            border-radius: 20px !important;
          }
        }
      }
    }
  }
  .single-note-page .single-note-inner-page .note-author-time .right-details {
    width: calc(100% - 32px) !important;
    padding: 0 !important;
    justify-content: space-between !important;
    display: flex !important;
    flex-direction: column !important;
    margin-left: 20px !important;
  }
  .NoteToPrint {
    .attachment-wrapper {
      .file__card {
        .file__preview {
          img {
            height: 125px !important;
            width: 125px !important;
            border-radius: 10px !important; 
          }
        }
      }
    }
  }
}


.page-size-300 {
  .no-record-found-table{
    width:300px;
    max-width:100%;
    margin:30px auto;
  }
}
// mass-email-template-card
.mass-email-template-card{
  & > .card-header{
    padding-right:15px;
    .right{
      display:inline-flex;
      align-items:center;
      .create-folder-link{
        background-color: #ffffff;
        border: 1px solid #82868b;
        padding: 9px 12px;
        border-radius: 5px;
        cursor: pointer;
        color: #6c6c6c;
        font-size: 14px;
        font-weight: 500;
        &:hover{
          box-shadow: 0 8px 25px -8px #82868b;
        }
      }
      .btn-primary{
        margin-left:10px !important;
      }
      .grid-card-view-toggle-btns{
        margin-left:10px;
      }
      .view-btn{
        &.active{
          background-color:var(--primaryColor);
          svg{
            color:#000000;
          }
        }
      }
    }
  }
  & > .card-body{
    padding:16px 16px !important;
  }
  .mass-email-template-accordian{
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-bottom:14px;
    &:last-child{
      margin-bottom:0px;
    }
    .mass-email-template-accordian-item{
      background-color:transparent;
      .accordion-button{
        background-color:transparent;
        display:flex;
        align-items:center;
        &::after{
          display:none !important;
        }
        .name-count-wrapper{
          display:flex;
          align-items:center;
          width:100%;
        }
        .template-name{
          font-size:18px;
          max-width:100%;
          color: #000;
          font-weight: 600;
        }
        .badge{
          margin:0px 10px !important;
          padding: 5px 11px;
          flex-shrink:0px;
        }
        .down-arrow{
          width:28px;
          height:28px;
          flex-shrink:0px;
          padding:3px;
          display:flex;
          align-items:center;
          justify-content:center;
          border-radius:50%;
          transition:0.3s;
          svg{
            color:#000000;
            width:100%;
            height:100%;
          }
          &:hover{
            background-color:#e6e6e6;
          }
        }
      }
      .accordion-collapse{
        &.show{
          border-top:1px solid #e2e2e2;
        }
      }
    }
    .company-email-temp-card-row{
      margin:0px -8px;
    }
    .company-email-temp-card-col{
      padding:0px 8px;
    }
    .company-email-inner-scroll,
    .company-email-inner-scroll.load-more-active{
      height:auto;
      max-height:unset;
      padding-bottom:0px !important;
    }
    .loadMore-btn-wrapper{
      padding:10px 0px 10px 0px !important;
      &::before{
        display:none !important;
      }
    }
    .email-template-list-view{
      .card{
        border:none;
        background-color:transparent;
      }
      .card-body{
        padding:0px 0px !important;
        height:auto !important;
      }
      .search-box-wrapper{
        margin-top:8px;
      }
    }
  }
}
// mass-email-template-card-end

// direct-mail-template-card
.direct-mail-template-card{
  & > .card-header{
    .create-folder-link{
      background-color:#ffffff;
      color:#6c6c6c;
      font-size: 14px;
      font-weight: 500;
      border:1px solid #82868b;
      padding:9px 12px;
      border-radius:5px;
      cursor:pointer;
      &:hover{
        box-shadow:0 8px 25px -8px #82868b;
      }
    }
    .grid-card-view-toggle-btns{
      margin-left:10px;
      .view-btn.active{
        background-color:var(--primaryColor) !important;
        svg{
          color:#000000 !important;
        }
      }
    }
  }
  & > .card-body{
    padding:16px 16px !important;
  }
  .direct-mail-accordian{
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-bottom:14px;
    &:last-child{
      margin-bottom:0px;
    }
    .direct-mail-accordian-item{
      background-color:transparent;
      .loader-wrapper{
        display:flex;
        align-items:center;
        justify-content:center;
        padding:10px 15px;
        width:100%;
      }
      .accordion-button{
        background-color:transparent;
        display:flex;
        align-items:center;
        &::after{
          display:none !important;
        }
        .name-count-wrapper{
          display:flex;
          align-items:center;
          width:100%;
        }
        .template-name{
          font-size:18px;
          max-width:100%;
          color: #000;
          font-weight: 600;
        }
        .badge{
          margin:0px 10px !important;
          padding: 5px 11px;
          flex-shrink:0px;
        }
        .down-arrow{
          width:28px;
          height:28px;
          flex-shrink:0px;
          padding:3px;
          display:flex;
          align-items:center;
          justify-content:center;
          border-radius:50%;
          transition:0.3s;
          svg{
            color:#000000;
            width:100%;
            height:100%;
          }
          &:hover{
            background-color:#e6e6e6;
          }
        }
      }
      .accordion-collapse{
        &.show{
          border-top:1px solid #e2e2e2;
        }
      }
      .email-template-list-view{
        .card{
          background-color:transparent !important;
          box-shadow:unset !important;
          border:none !important;
          .card-body{
            padding:0px 0px !important;
            height:auto;
            .search-box-wrapper{
              margin-top:15px;
            }
          }
        }
      }
    }
    .company-email-temp-card-row{
      margin:0px -8px;
    }
    .company-email-temp-card-col{
      padding:0px 8px;
    }
  }
  .loadMore-btn-wrapper{
    &::before{
      display:none !important;
    }
  }
}
// direct-mail-template-card-end
// add-update-event-modal
.add-update-event-modal{
  &.modal-dialog{
    width:1300px;
    max-width:calc(100% - 30px);
  }
  .event-contact-card{
    .avatar{
      img{
        width:100%;
        height:100%;
        object-fit:cover;
        object-position:center;
      }
      .avatar-content{
        width:100%;
        height:100%;
        text-transform:uppercase !important;
      }
    }
  }
  .invite-contacts-wrapper{
    .accordion-margin.contact-accordion{
      .accordion-item{
        .event-contact-list-wrapper{
          .event-contact-col{
            .event-contact-card{
              .right-details{
                .title{
                  font-size: 15px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
// add-update-event-modal-end

// super-admin-css-start
.compay__list__superAdmin{
  .react-dataTable{
    & > .react-dataTable{
      width:100%;
      overflow-x:auto;
      & > div:first-child{
        min-width:800px;
        width:100%;
      }
    }
  }
  .search-box-wrapper{
    .form-element-icon-wrapper{
      width:300px;
    }
    .new-tab-details-design{
      order:-1;
      width:calc(100% - 312px);
      .horizontal-new-tab-wrapper{
        margin-bottom:0px;
        .horizontal-tabbing{
          .nav-item{
            .nav-link{
              &:hover{
                background-color:#f8f8f8;
              }
            }
          }
        }
      }
    }
  }
}

.add-update-company-superAdmin{
  overflow:hidden;
  .top_header{
    padding-left:56px;
    padding-top:0px;
    padding-bottom:0px;
    align-items:center;
    overflow:hidden;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 14px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .card-title{
      align-items:center;
    }
    .right{
      display:inline-flex;
      align-items:center;
    }
    .new-tab-details-design{
      .horizontal-new-tab-wrapper{
        margin-bottom:0px;
        .horizontal-tabbing{
          .nav-item{
            .nav-link{
              padding:24px 15px;
            }
          }
        }
        &::before{
          display:none;
        }
      }
    }
    .login__btn{
      width:30px;
      height:30px;
      display:flex;
      align-items:center;
      justify-content:center;
      background-color:var(--primaryColor);
      border-radius:50%;
      padding:6px;
      margin-left:12px;
      svg{
        width:100%;
        height:100%;
        margin:0px;
        color:#000000;
      }
    }
  }
  & > .card-body{
    padding:22px 0px 0px 0px !important;
  }
  .company-detail-card{
    .company-detail-row{
      .company-info{
        .status_column{
          .inner-ci-field{
            position: relative;
            top: 37px;
            .form-label{
              margin-bottom:0px;
            }
            .form-check-input{
              position: relative;
              top: 2px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .sender-emails{
    padding:0px 15px 15px 15px;
    .card-header{
      padding:0px 0px;
      border-bottom:none;
    }
    .card-body{
      padding:0px 0px 0px 0px !important;
    }
    .search-box-wrapper{
      margin-top:0px;
    }
    .rdt_Table_wrapper{
      margin-top:-40px;
    }
  }
}
// super-admin-css-end

.direct-mail-print-ll{
  // width:796.8px;
  // height:1123.2px;
  // padding:46px 46px;
  // display:none;
  width:0px;
  height:0px;
  overflow: hidden;
  justify-content:center;
  .direct-mail-print{
    width:704.8px;
    height:1031.2px;
    position: relative;
    .direct-mail-letter-header{
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
    }
    .direct-mail-letter-footer{
      position:absolute;
      bottom:0px;
      left:0px;
      width:100%;
    }
    .direct-mail-letter-contant{
      height:100%;
      .inner-wrapper{
        height:100%;
        overflow:hidden;
      }
    }
  }
}

.direct-mail-preview-wrapper{
  .direct-mail-print-ll{ 
    width:796.8px;
    height:1123.2px;
    border: 1px solid #ededed;
    border-radius: 10px;
    display:flex;
    padding:46px 46px;
    .direct-mail-print{
      padding-top: 13px;
      .direct-mail-letter-contant{
        .inner-wrapper{
          height:100%;
        }
      }
    }
  }
}

@media print {
  .direct-mail-print-ll{
    width:796.8px;
    height:1123.2px;
    padding:0px 0px;
    display:flex;
    align-items: center;
  }
}

.addSpreadSheet__modal{
  .e-spreadsheet{
    .e-sheet-panel{
      height:calc(100vh - 360px) !important;
    }
  }
  .header__title{
    width:400px;
    flex-wrap:wrap;
  }
}

.form-submissions-page{
  .user-list-page{
    .card-header{
      padding-left: 60px;
      .back-arrow {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        font-size: 0px;
        position: absolute;
        top: 12px;
        left: 12px;
        transition: 0.5s;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          top: 13px;
          width: 12px;
          height: 12px;
          left: 15px;
          border-left: 3px solid #000;
          transform: rotate(45deg);
          border-bottom: 3px solid #000;
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.contactAddCompanyModal{
  .modal-dialog{
    max-width:500px;
  }
  .custom-select__single-value{
    .avatar{
      margin-right:6px;
      .avatar{
        margin-right:0px;
      }
    }
    .contact-name{
      width: calc(100% - 44px);
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// contactCompany__card 
.contactCompany__card{
  .card-header{
    padding-left: 58px;
    .back-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 12px;
      left: 12px;
      transition: 0.5s;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 13px;
        width: 12px;
        height: 12px;
        left: 15px;
        border-left: 3px solid #000;
        transform: rotate(45deg);
        border-bottom: 3px solid #000;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  .card-body{
    padding:0px 0px;
    display:flex;
    .leftForm{
      border-right:1px solid #ededed;
    }
    .leftForm,
    .rightContactList{
      width:50%;
      padding:20px 20px;
      height:calc(100vh - 202px);
      overflow-y:auto;
    }
    .leftForm{
      &.onlyLeftForm{
        width:100%;
        form{
          width:700px;
          max-width:100%;
        }
      }
    }
    .leftForm.onlyLeftForm + .rightContactList{
      display:none;
    }
    .rightContactList{
      .top-search-header{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding-bottom:15px;
        .search-box{
          width:300px;
          max-width:100%;
          padding-right:14px;
        }
        .addBtn{
          flex-shrink:0;
          &.opacity-50{
            pointer-events:none;
          }
        }
      }

      .contact-row{
        display:flex;
        flex-wrap:wrap;
        margin:0px -8px;
        .contact-col{
          width:50%;
          padding:0px 8px;
          .innerWrapper{
            background-color: #fafafa;
            border-radius: 12px;
            margin-bottom: 14px;
            padding:20px 52px 20px 15px;
            display:flex;
            align-items:center;
            position: relative;
            .avatar{
              width:40px;
              height:40px;
              background-color:var(--primaryColor);
              color:#000000;
              flex-shrink:0;
              .avatar-content{
                width:100%;
                height:100%;
                font-size: 14px;
              }
              img{
                width:100%;
                height:100%;
                object-fit:cover;
                object-position:center;
              }
            }
            .right-details{
              width:calc(100% - 40px);
              padding-left:14px;
              .title{
                font-size:18px;
                font-weight:700;
                color:#000000;
                margin-bottom: 3px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              p.text {
                font-size: 16px;
                margin-bottom: 0px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .action-wrapper {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: #ffffff;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding:6px;
              position:absolute;
              top:50%;
              transform:translateY(-50%);
              right:12px;
              cursor:pointer;
              svg{
                width:100%;
                height:100%;
                color:red;
              }
              &:hover{
                background-color:red;
                svg{
                  color:#ffffff !important;
                }
              }
            }
          }
        }
        .no-data-found{
          padding:10px 8px;
          font-size:20px;
          font-weight:700;
          width:100%;
          color:#000000;
          text-align:center;
        }
      }
    }
  }
}
// contactCompany__card 

// marketing add form new design
.form-preview-page.newDesign{
  .ui-loader{
    & > .card{
      & > .card-body{
        padding-left:0px;
        padding-right:0px;
        min-height:unset;
        padding-bottom:0px;
      }
    }
  }
  .add-company-form-wrapper{
    .contact-personalInfo-wrapper{
      display: flex;
      align-items: flex-start;
      .vertical-tab-wrapper{
        width: 250px;
        border-right: 1px solid #e8e8e8;
        .inner-scroll-wrapper{
          height: calc(100dvh - 206px);
          overflow-y: auto;
        }
        .vertical-tab-item{
          padding:12px 13px;
        }
      }
      .right-tabContant-wrapper{
        width: calc(100% - 250px);
        & > .innerWrapper{
          height:calc(100dvh - 275px);
          padding: 20px;
          overflow-y: auto;
        }
        & > .submit-btn-wrapper{
          padding: 14px 20px;
          border-top: 1px solid #e8e8e8;
          display:flex;
          justify-content:flex-start !important;
          button{
            width: 140px !important;
          }
        }
        .mail-task-preview{
          border: none;
          padding: 0px 0px;
          margin-top: 16px;
        }
        .accordian-loyal-box.emails{
          border:none !important;
          & > .accordian-loyal-body{
            display:block !important;
            padding:0px 0px;
          }
        }
      }
    }
  }
}
// marketing add form new design end

// responsive-start
@media only screen and (max-width: 1600px) {
  // company-add-new-user
  .company-task-manager-row {
    .company-task-manager-col {
      width: 50%;
    }
  }
  // company-add-new-user-end

  // company-document
  .company-document-page .custom-col {
    width: 100%;
  }
  // company-document-end

  // Mass-email-blast
  .mass-emails-card {
    .mass-email-card-col {
      width: 100%;
    }
  }
  .scheduled-mass-emails-card {
    .scheduled-mass-email-card-col {
      width: 100%;
    }
  }
  // Mass-email-blast-end

  // send-mass-email-modal
  .send-mass-email {
    .top-header {
      margin: 0px -8px;
      & > .col-md-3 {
        padding: 0px 8px;
      }
    }
  }
  // send-mass-email-modal-end

  // scheduled-mass-email-view-modal
  .scheduled-mass-email-view-modal {
    .details-wrapper {
      .details-box {
        width: 33.3333%;
      }
    }
  }
  // scheduled-mass-email-view-modal-end

  // sendgrid-statistics-page
  .sendgrid-statistics-wrapper {
    .card-wrapper {
      .card-box {
        width: 33.3333%;
      }
    }
  }
  .menu-expanded {
    .sendgrid-statistics-wrapper {
      .card-wrapper {
        .card-box {
          width: 33.3333%;
        }
      }
    }
  }
  // sendgrid-statistics-page-end

  // contact-user-details-page
  .contact__card__wrapper {
    .accordian-loyal-box {
      .accordian-loyal-body {
        .upload-file-col {
          width: 660px;
          max-width: 100%;
        }
      }
    }
  }
  // contact-user-details-page-end

  // contact-checklist
  .contact__card__wrapper {
    .contact__checklist__wrapper {
      .compnay-checklist-folder-wrapper {
        .company-checklist-row {
          .custom-col {
            width: 50%;
          }
        }
      }
    }
  }
  // contact-checklist-end

  // Mass-sms-blast
  .mass-sms-blast-page {
    .mass-sms-card-col {
      width: 100%;
    }
    .scheduled-mass-sms-card-col {
      width: 100%;
    }
  }
  // Mass-sms-blast-end

  // marketing-add-forms
  .add-company-form-wrapper {
    .preview-form-fields {
      .field-repeater-row {
        .title-wrapper {
          width: 25%;
        }
        .form-field-wrapper {
          width: 75%;
        }
      }
    }
  }
  // marketing-add-forms-end

  // task-manager
  .loyal-task-manager {
    .content-right {
      .task-manager-top-header {
        .searchInSubtask-input-wrapper {
          display:none;
          .text {
            display: none;
          }
        }
      }
      .new-tab-details-design{
        .right__wrapper__OP{
          display:inline-flex;
        }
      }
      .task-calenderView-wrapper{
        .full-calender-wrapper{
          .fc-event-task{
            padding:3px 8px 3px 18px;
          }
          .startDate-endDate-wrapper{
            right: 194px;
            top: 139px;
            position: fixed;
          }
          .fc-scrollgrid-section-header{
            .fc-col-header-cell{
              .fc-col-header-cell-cushion{
                font-size:16px;
              }
            }
          }
          .fc-daygrid-body{
            .fc-daygrid-day-top{
              width: 24px;
              height: 24px;
            }
            .fc-daygrid-day-number{
              font-size:14px;
            }
          }
          .fc-daygrid-day-bottom{
            .fc-daygrid-more-link{
              font-size:12px;
            }
          }
          .fc-scrollgrid-section-header{
            .fc-col-header-cell{
              .fc-col-header-cell-cushion{
                font-size:14px;
              }
            }
          }
        }
      }
    }
  }
  // task-manager-end

  // contact-task-manager
  .contact__card__wrapper{
    &.new-design{
      .loyal-task-manager{
        .content-right{
          .new-tab-details-design{
            .right__wrapper__OP{
              top: 113px;
            }
          }
          .task-calenderView-wrapper{
            .full-calender-wrapper{
              .startDate-endDate-wrapper{
                top: 240px;
              }
            }
          }
        }
      }
    }
  }
  // contact-task-manager-end

  // Form Design Fields
  .form-design-field-row {
    .form-design-field-col {
      width: 33.3333%;
    }
  }
  // Form Design Fields end

  // inventory-add-new-product
  .inventory-product-add-uodate-card {
    .add-product-details-card {
      .question-box {
        .question-col {
          width: 33.3333%;
        }
      }
    }
  }
  // inventory-add-new-product-end

  // inventory-product-details
  .inventory_product_details {
    .inventory-product-details {
      .product-images {
        .carousel {
          .slide {
            img {
              height: 450px !important;
            }
          }
        }
      }
      .product-content-right {
        .ectra-details {
          li {
            width: 50%;
            border-bottom: 1px solid #e1e1e1;
            &:nth-last-child(2),
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  // inventory-product-details-end

  // contact-inner-page-design
  .setting-profile-page {
    .contact__checklist__wrapper {
      padding: 5px 0px 0px 0px;
      .list-grid-btn-wrapper {
        right: 0px;
        top: 5px;
      }
      .company-checklist-row {
        .custom-col {
          width: 50%;
        }
      }
    }
  }
  // contact-inner-page-design-end

  // mail-task-preview
  .mail-task-preview {
    .task__top__details__col {
      width: 50%;
    }
  }
  // mail-task-preview-end

  // new-tab-details-design
  .new-tab-details-design {
    .contact__checklist__wrapper {
      .company-checklist-row {
        .custom-col {
          width: 50%;
        }
      }
    }
  }
  // new-tab-details-design-end

  // support-tickets
  .report-problem-row {
    .report-problem-col {
      width: 50% !important;
    }
  }
  // support-tickets-end

  // direct__mail__card
  .direct__mail__card {
    .company-email-temp-card-row {
      .company-email-temp-card-col {
        width: 50%;
      }
    }
  }
  // direct__mail__card__end

  // email-modual
  .email-application{
    .content-area-wrapper{
      .email-modual{
        .sidebar-left{
          width:200px;
        }
        .content-right{
          width:calc(100% - 200px);
        }
        &.sidebar-collapse{
          .sidebar-left{
            width:80px;
          }
          .content-right{
            width:calc(100% - 80px);
          }
        }
        &.right-of-index{
          .content-body{
            .email-list-wrapper{
              width:300px;
            }
            .email-details-page{
              width: calc(100% - 300px);
            }
          }
        }
      }
    }
  }
  // email-modual-end
}
@media only screen and (max-width: 1439px) {
  .contactCompany__card{
    .card-body{
      .rightContactList{
        .contact-row{
          .contact-col{
            width:100%;
          }
        }
      }
    }
  }

  // top-main-header
  .header-navbar {
    .navbar-search {
      width: 204px;
    }
    .company-details {
      .right {
        max-width: 130px;
      }
    }
    .user-details {
      .dropdown-user-link {
        .right {
          max-width: 130px;
        }
      }
    }
    .task-timer-btn-wrapper,
    .report-bug-btn-wrapper {
      margin-right: 10px;
    }
    .current-task-of-header {
      padding: 10px 74px 10px 15px;
      margin-right: 10px !important;
      .taskId {
        position: absolute;
        top: 2px;
        left: 14px;
        font-size: 12px;
        border-right: none;
        padding-right: 0px;
        padding-right: 0px;
        margin-right: 0px;
        max-width: 67px;
        &:before {
          display: none;
        }
      }
      .taskName {
        font-size: 12px;
        padding-right: 0px;
        margin-right: 0px;
        position: relative;
        padding-top: 2px;
        top: 5px;
        min-width: 60px;
        max-width: 67px;
        &::before {
          display: none;
        }
      }
      .timer {
        font-size: 12px;
        position: relative;
        margin-left: 7px;
        padding-left: 7px;
        width: 64px;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
          width: 1px;
          height: calc(100% + 4px);
          background-color: #ffffff;
          opacity: 0.6;
          display: block;
        }
      }
    }
  }
  // top-main-header-end

  // comapny-user-details-page
  .user-details-page-new{
    .company-task-manager-row{
      .company-task-manager-col{
        width:100%;
      }
    }
  }
  // comapny-user-details-page-end

  // company-checklist
  .company-checklist-page-wrapper {
    .checklist-box {
      .company-checklist-row {
        .custom-col {
          width: 50%;
        }
      }
    }
  }
  // company-checklist-end

  // send-mass-email-modal
  .send-mass-email {
    .top-header {
      & > .col-md-3 {
        width: 33.3333%;
      }
    }
  }
  // send-mass-email-modal-end

  // clone-mass-email
  .clone-mass-email {
    .cloneMass-mail-topHeader-fields-wrapper {
      margin: 0px -8px;
      & > div {
        padding: 0px 8px;
      }
    }
  }
  // clone-mass-email-end

  // marketing-add-forms
  .add-company-form-wrapper {
    .preview-form-fields {
      .field-repeater-row {
        .title-wrapper {
          width: 30%;
        }
        .form-field-wrapper {
          width: 70%;
        }
      }
    }
    .accordian-loyal-box.emails {
      .toggle-form-inner-wrapper {
        .col-lg-6 {
          width: 100%;
        }
      }
    }
  }
  // marketing-add-forms-end

  // task-manager
  .loyal-task-manager {
    position: relative;
    .sidebar-left {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: #ffffff;
      z-index: 5;
      transform: translateX(-110%);
      &.show {
        transform: translateX(0%);
      }
      .collapse__menu__icon__wp {
        display: none;
        &.responsive__btn {
          display: block;
        }
      }
    }
    .content-right {
      width: 100% !important;
      transition: 0.3s;
      .new-tab-details-design{
        .Phorizontal-new-tab-wrapper{
          padding-left:0px;
        }
      }
      .task-manager-top-header {
        .task-manager-sidebar-toggle-btn {
          display: flex;
        }
      }
      .task-manager-top-header {
        .left {
          padding-left: 0px;
        }
      }
    }
    .sidebar-left.show + .content-right {
      padding-left: 260px;
      .new-tab-details-design{
        .horizontal-new-tab-wrapper{
          padding-left:25px;
        }
      }
      .task-manager-top-header {
        padding-left: 10px;
        .task-manager-sidebar-toggle-btn{
          margin-left:0px;
          display:none;
        }
        .task-manager-serach-box{
          .form-element-icon-wrapper{
            svg{
              left: 3px;
            }
          }
          .search__task__input{
            padding-left: 32px;
          }
        }
      }
    }
    .task-calenderView-wrapper{
      .full-calender-wrapper{
        .fc-header-toolbar{
          .fc-toolbar-chunk{
            .fc-button-group{
              .fc-button{
                margin-right: 5px;
                padding:6px 9px;
                width:80px;
              }
            }
          }
        }
        .fc-scrollgrid-section-header{
          .fc-col-header-cell{
            .fc-col-header-cell-cushion{
              font-size:13px !important;
            }
          }
        }
      }
    }
    .sidebar-left{
      .collapse__menu__icon__wp{
        transform: rotate(0deg);
        display:none !important;
      }
      .collapse__menu__icon__wp.before1439{
        display:block !important;
      }
    }
    .sidebar-left.taskSideBarCollapse{
      width:260px;
      .collapse__menu__icon__wp{
        transform: rotate(0deg);
        display:none !important;
      }
      .collapse__menu__icon__wp.before1439{
        display:block !important;
      }
      .add__task__btn,
      .add__task__btn.sidebarCollapse__addTask__BtnShow{
        display:none;
      }
      .defaultAddTask__btn,
      .defaultAddTask__btn.sidebarCollapse__addTask__BtnShow{
        display:block !important;
      }
      .page-tab{
        .page-tab-item{
          .icon-wrapper{
            margin-right:0px;
          }
          .text-label{
            display:flex;
          }
          &.archived{
            .setting{
              display:flex;
            }
          }
        }
      }
      .filter-wrapper{
        & > .filter-item.accordion{
          display:block;
        }
      }
      .sidebarCollapse__CN{
        display:none;
      }
    }
    .content-right.taskSideBarCollapse{
      width:100% !important;
    }
    .sidebar-left,
    .sidebar-left.taskSideBarCollapse{
      transition: 0.3s !important;
    }
    .sidebar-left,
    .sidebar-left.taskSideBarCollapse,
    .sidebar-left:not(.taskSideBarCollapse){
      transform: translateX(-120%);
    }
    .sidebar-left.show,
    .sidebar-left.show.taskSideBarCollapse,
    .sidebar-left.show:not(.taskSideBarCollapse){
      transform: translateX(0%);
    }
  }
  // task-manager-end

  // contact-task-manager
  .contact__card__wrapper{
    &.new-design{
      .loyal-task-manager{
        .content-right{
          .new-tab-details-design{
            .right__wrapper__OP{
              top:12px;
            }
          }
        }
      }
    }
  }
  // contact-task-manager-end

  // email-page
  .email-modual {
    .sidebar-left {
      width: 210px;
    }
    .content-right {
      width: calc(100% - 210px) !important;
      .email-items {
        .mail-item {
          .details-wrapper {
            .title-wrapper {
              width: 170px;
            }
            .description-wrapper {
              width: calc(100% - 252px);
            }
          }
        }
      }
    }
  }
  // email-page-end

  // product-list
  .card-header.product-list-header {
    .card-title {
      width: 100%;
      margin-bottom: 13px;
    }
    .right {
      width: 100%;
      .scan-product-btn {
        width: 160px;
        flex-shrink: 0;
      }
      .add-product-btn {
        width: 160px;
        flex-shrink: 0;
      }
      .btn-group {
        flex-shrink: 0;
        .dropdown-toggle {
          width: 124px;
          padding-left: 12px;
          padding-right: 12px;
        }
      }
      .product-date-box {
        margin-left: 0px !important;
        width: 216px;
        flex-shrink: 0;
      }
      .contact-select-box {
        width: 50%;
      }
    }
  }
  // product-list-end

  // inventory-add-new-product
  .inventory-product-add-uodate-card {
    .add-product-details-card {
      .quetion-rr-row {
        .quetion-rr-col {
          width: 100%;
        }
      }
    }
  }
  // inventory-add-new-product-end

  // contact-inner-page-design
  .contact__card__wrapper.new-design {
    .contact-personalInfo-wrapper {
      .right-tabContant-wrapper {
        .formField-row {
          .formField-col.website,
          .formField-col.email {
            width: 100%;
          }
        }
      }
    }
    .contact-email-tab {
      .contact-email-card-col {
        width: 50%;
      }
    }
  }
  // contact-inner-page-design-end

  // email-modual
  .email-application{
    .content-area-wrapper{
      .email-modual{
        .sidebar-left{
          width:80px;
          .compose-btn-wrapper{
            .compose-btn{
              height: 40px;
              font-size: 0px;
              display: flex !important;
              align-items: center;
              justify-content: center;
              padding: 6px;
              svg{
                width: 100%;
                height: 100%;
                margin-right: 0px !important;
              }
            }
          }
          .sidebar-menu-list{
            .list-group{
              .list-group-item{
                padding: 7px 18px 9px 18px;
                .icon__wrapper,
                .badge{
                  display:none;
                }
              }
            }
          }
        }
        .content-right{
          width:calc(100% - 80px) !important;
        }
        &.right-of-index{
          .mail-task-preview{
            .task__top__details__col{
              width:100%;
            }
          }
        }
      }
    }
  }
  // email-modual-end

  // task-report-page
  .task-report-page{
    .card-header{
      .right-wrapper{
        .date-picker-error-wp{
          width: 210px;
        }
        .contact-modal{
          width:235px;
        }
      }
    }
    & > .card{
      & > .card-body{
        .task-report-chart-wrapper{
          width:100%;
          margin-bottom:20px;
        }
        .task-report-table-wrapper{
          width:100%;
        }
      }
    }
  }
  // task-report-page-end

  .contact__card__wrapper.new-design{
    .contact-personalInfo-wrapper{
      .right-tabContant-wrapper{
        .contactCompanies__page{
          .companyListing__row{
            .companyListing__col{
              width:100%;
              .companyListing__colInner{
                .relatedContacts__tableWrapper{
                  overflow-x:auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  // company-add-new-user
  .company-task-manager-row {
    .company-task-manager-col {
      width: 100%;
    }
  }
  .permission-wrapper {
    .accordian-loyal-box {
      .accordian-loyal-body {
        .permission-row-wrapper {
          .permission-row {
            width: 100%;
          }
        }
      }
    }
  }
  // company-add-new-user-end

  // contact-list-page
  .contact-list-all-filter-wrapper {
    .custom_select_wrapper {
      width: 200px;
    }
  }
  .contact-list-horizontal-tab {
    .horizontal-button-tab {
      .view-btn {
        width: auto;
      }
    }
  }
  // contact-list-page-end

  // email-templates
  .company-email-temp-card-row {
    .company-email-temp-card-col {
      width: 50%;
    }
  }
  // email-templates-end

  // create__mass__email__page
  .create__mass__email__form {
    .contact-filters-wrapper {
      .filter-fields-wrapper {
        .field-col {
          width: 33.3333%;
        }
      }
    }
  }
  // create__mass__email__page__end

  // scheduled-mass-email-view-modal
  .scheduled-mass-email-view-modal {
    .accordion-margin.contact-accordion {
      .accordion-item {
        .event-contact-list-wrapper {
          .event-contact-col {
            width: 33.3333%;
          }
        }
      }
    }
  }
  // scheduled-mass-email-view-modal-end

  // sendgrid-statistics-page
  .sendgrid-statistics-wrapper {
    .card-wrapper {
      .card-box {
        width: 50%;
      }
    }
    .chart-row {
      .chart-col {
        width: 100%;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  .menu-expanded {
    .sendgrid-statistics-wrapper {
      .card-wrapper {
        .card-box {
          width: 50%;
        }
      }
    }
  }
  // sendgrid-statistics-page-end

  // contact-note
  .contact__card__wrapper {
    .add-note-row {
      .add-note-text-col,
      .add-note-attachment-col {
        width: 100%;
      }
      .add-note-attachment-col {
        margin-top: 17px;
      }
    }
  }
  // contact-note-end

  // SMS Templates page
  .company-sms-temp-card-col {
    width: 50%;
  }
  // SMS Templates page end

  // create-mass-sms
  .create-mass-sms-card {
    .contact-filters-wrapper {
      .filter-fields-wrapper {
        .field-col {
          width: 33.3333%;
        }
      }
    }
  }
  // create-mass-sms-end

  // marketing-add-forms
  .add-company-form-wrapper {
    .permission-row-wrapper.from-details {
      .permission-row {
        width: 50% !important;
      }
    }
  }
  // marketing-add-forms-end

  //task-manager add-update-task
  .add-task-modal-new {
    &.update-tab-active {
      .note-box {
        .right-contant {
          .attachment-wrapper {
            .file__card {
              width: 50%;
            }
          }
        }
      }
    }
  }
  //task-manager add-update-task-end

  // inventory-add-new-product
  .inventory-product-add-uodate-card {
    .add-product-details-card {
      .inventory-add-attachment-wrapper {
        .custom-fileupload {
          .attachment-wrapper {
            .file__card {
              width: 25%;
            }
          }
        }
      }
    }
  }
  // inventory-add-new-product-end
}

@media only screen and (max-width: 1340px) {
  // task-manager
  .loyal-task-manager{
    .sidebar-left.show + .content-right{
      .new-tab-details-design{
        .right__wrapper__OP{
          .searchInSubtask-input-wrapper{
            .text{
              width: 122px;
              display: inline-block;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  // task-manager-end
}

@media only screen and (max-width: 1201px) {
  // sidebar
  .header-navbar {
    right: 0px !important;
    left: unset !important;
  }
  // sidebar-end
}
@media only screen and (max-width: 1200px) {
  // company-form-page css
  .menu-expanded {
    .company-form-page {
      .company-form-card-row {
        .company-form-card-col {
          width: 100%;
        }
      }
    }
  }
  // company-form-page css end
}
@media only screen and (max-width: 1199px) {
  // top-main-header
  .header-navbar.floating-nav ~ .footer {
    margin-left: 0px;
  }
  .header-navbar {
    &.floating-nav {
      width: 100% !important;
    }
    .mobile-menu-toggle-btn {
      padding: 0px 0px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background-color: #eae8e1;
      position: relative;
      transition: 0.5s;
      top: 3px;
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        // background-color:#000000;
        border: 1px solid #000000;
        position: absolute;
        transition: 0.5s;
        &:nth-child(1) {
          top: 9px;
          left: 9px;
        }
        &:nth-child(2) {
          top: 9px;
          left: 23px;
        }
        &:nth-child(3) {
          top: 24px;
          left: 9px;
        }
        &:nth-child(4) {
          top: 24px;
          left: 23px;
        }
      }
      &:hover {
        background-color: #000000;
        .dot {
          border: 1px solid white;
        }
      }
    }
    .company-details {
      margin-left: 12px;
    }
    .current-task-of-header {
      display: none;
    }
  }
  // top-main-header-end

  // delete-folder-modal
  .delete-folder-modal {
    &.modal-dialog {
      width: calc(100% - 30px);
      max-width: calc(100% - 30px) !important;
    }
  }
  // delete-folder-modal-end

  // contact-list-page
  .contact-list-all-filter-wrapper {
    .custom_select_wrapper {
      width: 210px;
    }
  }
  .contact-list-horizontal-tab {
    .horizontal-button-tab {
      .view-btn {
        min-width: 110px;
      }
    }
  }
  // contact-list-page-end

  // email-templates
  .email-editor-modal-new {
    &.modal-dialog.editor-open {
      max-width: calc(100% - 30px) !important;
      width: calc(100% - 30px) !important;
    }
    iframe {
      min-width: unset !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  // email-templates-end

  // send-mass-email-modal
  .send-mass-email {
    &.modal-dialog {
      width: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
    .card {
      .card-body {
        padding: 0px 15px 10px 15px !important;
      }
    }
  }
  // send-mass-email-modal-end

  // clone-mass-email
  .clone-mass-email {
    &.modal-dialog {
      width: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
  }
  // clone-mass-email-end

  // clone-mass-email
  .clone-mass-email {
    .cloneMass-mail-topHeader-fields-wrapper {
      margin: 0px -8px;
      & > div {
        width: 50%;
        margin-bottom: 14px;
      }
    }
  }
  // clone-mass-email-end

  // scheduled-mass-email-view-modal
  .scheduled-mass-email-view-modal {
    &.modal-dialog {
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
    }
  }
  // scheduled-mass-email-view-modal-end

  // sendgrid-statistics-page
  .sendgrid-statistics-wrapper {
    .card-wrapper {
      margin: 0px -8px;
      margin-bottom: 10px;
      .card-box {
        width: 33.3333%;
        padding: 0px 8px;
        margin-bottom: 15px;
      }
    }
  }
  // sendgrid-statistics-page-end

  // company-form-page css
  .company-form-page {
    .company-form-card-row {
      .company-form-card-col {
        width: 100%;
      }
    }
  }
  // company-form-page css end

  // contact-manage-group
  .contact-manage-group-view-modal,
  .contact-manage-group-delete-modal {
    &.modal-dialog {
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
    }
  }
  // contact-manage-group-end

  // contact-status
  .contact-status-view-modal,
  .contact-status-delete-modal {
    &.modal-dialog {
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
    }
  }
  // contact-status-end

  // contact-status
  .contact-category-view-modal,
  .contact-category-delete-modal {
    &.modal-dialog {
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
    }
  }
  // contact-status-end

  // contact-tags
  .contact-tags-view-modal {
    &.modal-dialog {
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
    }
  }
  // contact-tags-end

  // send-mass-sms-modal
  .send-mass-sms-modal {
    &.modal-dialog {
      max-width: calc(100% - 30px) !important;
    }
  }
  // send-mass-sms-modal-end

  // pipeline
  .pipeline-page-wrapper {
    .pipeline-kanbanView-wrapper {
      position: relative;
      overflow: hidden;
      .left-sidebar {
        width: 270px;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        background-color: #ffffff;
        z-index: 4;
        border-bottom-left-radius:0px !important;
        border-top-left-radius:0px !important;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
        box-shadow: 0px 0px 15px #cecece;
        transition: 0.5s;
        transform: translateX(-150%);
        .group-wrapper {
          padding-bottom: 16px;
          .group-list-body {
            height: calc(100dvh - 56px) !important;
            padding:0px 0px 0px 0px !important;
            margin-bottom:0px !important;
          }
          & > .group-header{
            padding-bottom:16px !important;
          }
          .group-header {
            margin-bottom:0px !important;
            .group-heading {
              .close-btn {
                display: inline-flex;
              }
            }
          }
          .group-item.active{
            .group-item-header{
              border-radius:0px;
            }
          }
        }
      }
      .rightCN {
        width: 100%;
        .pipline-stage-kanban-scroll {
          height: calc(100dvh - 238px);
        }
        .pipline-stage-kanban-view-header {
          .title {
            .task-manager-sidebar-toggle-btn {
              display: inline-flex;
            }
          }
        }
      }
    }
  }
  .open-sidebar {
    z-index: 12;
    .mobile-overllay-pieline{
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(7px);
      position:fixed;
      top:0px;
      left:0px;
      width:100vw;
      height:100vh;
      z-index:2;
    }
    .pipeline-page-wrapper{
      .pipeline-kanbanView-wrapper{
        .left-sidebar{
          transform: translateX(0%);
        }
      }
    }
  }
  // pipeline-end

  // inventory-add-new-product
  .inventory-product-add-uodate-card {
    .add-product-details-card {
      .inventory-add-attachment-wrapper {
        .custom-fileupload {
          .attachment-wrapper {
            .file__card {
              width: 20%;
            }
          }
        }
      }
    }
  }
  // inventory-add-new-product-end

  // contact-inner-page-design
  .setting-profile-page {
    .contact__checklist__wrapper {
      padding: 5px 0px 0px 0px;
      .list-grid-btn-wrapper {
        right: 0px;
        top: 5px;
      }
    }
    .contact-notes-tabPane {
      .inner-contant-wrapper {
        padding: 10px 0px 0px 0px;
      }
    }
    .contact-activity-tabPane {
      padding: 13px 0px;
    }
    .personal-info-tab {
      padding: 6px 0px 20px 0px;
    }
  }

  .contact__card__wrapper.new-design {
    .contact-activity-tabPane {
      padding: 13px 0px;
    }
    .contact-notes-tabPane {
      .inner-contant-wrapper {
        padding: 10px 0px 0px 0px;
      }
    }
  }
  // contact-inner-page-design-end

  // add-update-event-modal
  .add-update-event-modal{
    .schedule-events-wrapper{
      .col-md-3{
        width:50%;
      }
    }
    .accordion-margin.contact-accordion{
      .accordion-item{
        .event-contact-list-wrapper{
          .event-contact-col{
            width:33.3333% !important;
          }
        }
      }
    }
  }
  // add-update-event-modal-end

  // custom-pipeline
  .pipeline-page-wrapper.pipeline-page-wrapper-new.kanban-view-active{
    .pipeline-kanbanView-wrapper{
      .rightCN{
        width:100%;
        .pipline-stage-kanban-view-header{
          padding-left:0px;
          .title{
            display:none;
          }
          .cp-toggle-btn{
            transition: 0.5s;
            width: 41.2px;
            height: 41.2px;
            position: relative;
            cursor: pointer;
            border-radius: 6px;
            background-color: #ffffff;
            display:block !important;
            &::before{
              content: "";
              position: absolute;
              bottom: 8px;
              height: 9px;
              width: 9px;
              border-radius: 50%;
              border: 1px solid #000000;
              right: 8px;
            }
            .line{
              background-color: transparent;
              border-radius: 50%;
              border: 1px solid #000000;
              height: 9px;
              width: 9px;
              position: absolute;
              display:block;
              &:first-child{
                left: 8px;
                top: 8px;
              }
              &:nth-child(2){
                top: 8px;
                right: 8px;
              }
              &:nth-child(3){
                bottom: 8px;
                left: 8px;
              }
            }
          }
          &.main-view{
            padding-bottom:15px;
            .right{
              width:100%;
              display:flex;
              justify-content:space-between;
            }
          }
        }
        .pipline-stage-kanban-view-header.main-view + .pipline-stage-kanban-scroll{
          padding-top:0px;
          height:calc(100dvh - 206px) !important;
          .search-box-wrapper{
            width:100%;
          }
        }
        .pipline-stage-kanban-scroll{
          padding-left:0px;
        }
      }
    }
    .react-trello-board{
      .smooth-dnd-draggable-wrapper{
        margin-right:10px;
        .smooth-dnd-container{
          height: calc(100dvh - 352px);
        }
      }
    }
  }
  // custom-pipeline-end
}

.sidebar-refresh-btn {
  width: 35px;
  margin-right: 30px;
}

 // Public checklist CSS Starts
 .accordion-item.checklist-box .checklist-header .action-btn-wrapper {
  // width: 65px;
  // justify-content: flex-end;
 }
 .public-checklist-module {
  max-width: 1000px;
  margin: 0 auto;
  position: initial !important;
  
  .folder-template-names-wrapper { 
    text-align: center;
    margin-bottom: 15px;
    label {
      font-size: 20px;
      line-height: 28px;
      display: inline-block;
    }
  }
  .left-wrapper { 
    width: calc(100% - 65px) !important;
  }
  .title-field-wrapper {
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100% !important;
    font-weight: 700;
    &.checklist-show-full-title {
      font-weight: 700;
    }
  }
  .accordion-item.checklist-box .accordion-body {
    padding: 0;
  }
  .accordion-item.checklist-box .accordion-collapse {
    &.show {
      border-top: none;
    }
  }
  .action-btn-wrapper {
    width: 65px;
    justify-content: flex-end;
   }
  
  .checklist-box.accordion-item img{
    outline:none !important;
  }
}
.public-checklist-module .spinner-border {
  position: absolute;
  top: 49%;
  left: 49%;
}

// Public checklist CSS Ends

@media only screen and (max-width: 992px) {
  //task-manager add-update-task
  .add-task-modal-new {
    .tasks-tab {
      .task-row-2 {
        & > .col-12 {
          width: 50% !important;
        }
      }
      .editor-scheduleTasks-row {
        .est-col {
          width: 100% !important;
          &.editor-col {
            margin-bottom: 20px;
          }
        }
      }
      .checklist-attachments-row {
        .ca-col {
          width: 100% !important;
          &.checklist-col {
            margin-bottom: 15px;
          }
          &.attachments-col {
            .custom-fileupload {
              .attachment-wrapper {
                .file__card {
                  max-width: 25%;
                }
              }
            }
          }
        }
      }
    }
  }
  //task-manager add-update-task-end
}

@media only screen and (max-width: 992px) {
  .connect-IMAP-modal{
    .bs-stepper{
      .bs-stepper-header{
        flex-direction:row;
        align-items:center;
        .line{
          display:flex;
          width:48px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .contactCompany__card{
    .card-body{
      max-height:calc(100vh - 202px);
      overflow-y:auto;
      flex-wrap:wrap;
      .leftForm,
      .rightContactList{
        width:100%;
        height:auto;
        overflow-y:hidden;
      }
      .leftForm{
        border-right:none;
      }
    }
  }

  // top-header
  .header-navbar{
    .top-header-ctm-btns-wrapper{
      display:none;
    }
  }
  // top-header-end

  // report-problem-modal
  .report-problem-modal {
    &.modal-dialog {
      max-width: 735px;
    }
  }
  // report-problem-modal-end

  // company-profile-start
  .company-detail-card {
    .company-detail-row {
      .company-info,
      .add-notes-sec {
        width: 100%;
      }
      .company-info {
        .inner-scroll-wrapper {
          height: auto;
        }
        .submit-btn-fixed-wrapper {
          display: none;
        }
      }
      .add-notes-sec {
        .inner-scroll-wrapper {
          height: auto;
        }
      }
    }
    & > .card-body {
      padding-top: 0px !important;
      position: relative;
    }
    .company-detail-row {
      // height: calc(100dvh - 268px);
      height: calc(100dvh - 205px);
      overflow-y: auto;
      position: relative;
      padding-top: 20px;
      padding-bottom: 80px;
    }
    .submit-btn-fixed-wrapper {
      &.responsive-show {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        border-top: 1px solid #eae8e1;
      }
    }
  }
  // company-profile-end

  // company-add-new-user
  .permission-wrapper {
    .accordian-loyal-box {
      .accordian-loyal-body {
        .permission-row-wrapper {
          .permission-row {
            width: 50%;
          }
        }
      }
    }
  }
  .company-task-manager-row {
    .company-task-manager-col {
      width: 50%;
    }
  }
  .company-detail-row {
    .add-notes-sec {
      border-top: 1px solid #eaeaea;
      padding-top: 20px;
    }
  }
  // company-add-new-user-end

  // company-user-details-page
  .user-details-page-new{
    .company-task-manager-row{
      .company-task-manager-col{
        width:50%;
      }
    }
    .company-detail-card{
      .company-detail-row{
        height:calc(100dvh - 267px);
        overflow-y:auto;
        .company-info{
          .inner-scroll-wrapper{
            height:auto;
          }
        }
        .add-notes-sec{
          .inner-scroll-wrapper{
            height:auto;
          }
        }
      }
    }
  }
  // company-user-details-page-end

  // company-checklist
  .company-checklist-page-wrapper {
    .compnay-checklist-folder-wrapper {
      & > .card-header {
        .create-folder-link {
          padding-left: 13px;
          padding-right: 13px;
        }
        .btn-primary {
          padding-left: 13px;
          padding-right: 13px;
        }
      }
    }
  }
  .company-form-card.checklist-company-form-card {
    .body-wrapper {
      .checklist-listing-items {
        .item {
          width: 100%;
        }
      }
    }
  }
  .add-checklist-modal {
    &.modal-dialog {
      max-width: 735px;
    }
  }
  .delete-folder-modal {
    &.modal-dialog {
      max-width: 735px;
    }
  }
  // company-checklist-end

  // company-document
  .company-document-page {
    .company-files-wrapper {
      .company-document-listing-row {
        width: 100%;
        .company-checklist-inner-scroll {
          height: auto;
        }
        &:first-child {
          margin-bottom: 17px;
        }
      }
    }
    .company-document-listing-row:last-child {
      .card-body {
        height: auto;
      }
    }
    .company-document-listing-row.list-view:last-child {
      .card-body {
        height: auto;
      }
    }
  }
  // company-document-end

  // contact-list-page
  .search-box-wrapper-contactList {
    flex-wrap: wrap;
    .form-element-icon-wrapper {
      width: 100%;
    }
    .right-filter-wrapper {
      width: 100%;
      margin-top: 15px;
      .contact-list-all-filter-wrapper {
        width: 100%;
      }
    }
  }
  .contact-list-horizontal-tab {
    width: calc(100% - 278px);
    .horizontal-button-tab {
      width: 100%;
      .view-btn {
        width: 33.3333%;
      }
    }
  }
  // contact-list-page-end

  // email-templates
  .company-email-temp-card-row {
    margin-left: -10px;
    margin-right: -10px;
    .company-email-temp-card-col {
      width: 50%;
      padding: 0px 10px;
    }
  }
  // email-templates-end

  // Mass-email-blast
  .mass-email-tool-row.mass-email-tool-row-list-view {
    .mass-email-col,
    .scheduled-mass-email-col {
      width: 100%;
    }
    .mass-email-col {
      margin-bottom: 20px;
    }
  }
  .mass-emails-card {
    .inner-scroll-area {
      margin: 0px -10px;
      height: auto;
      max-height: calc(100dvh - 272px);
      &.load-more-active {
        height: auto;
        max-height: calc(100dvh - 340px);
      }
      .mass-email-card-col {
        width: 50%;
        padding: 0px 10px;
      }
    }
  }
  .scheduled-mass-emails-card {
    .inner-scroll-area {
      margin: 0px -10px;
      height: auto;
      max-height: calc(100dvh - 272px);
      &.load-more-active {
        height: auto;
        max-height: calc(100dvh - 340px);
      }
      .scheduled-mass-email-card-col {
        width: 50%;
        padding: 0px 10px;
      }
    }
  }
  // Mass-email-blast-end

  // create__mass__email__page
  .create__mass__email__form {
    .contact-filters-wrapper {
      .filter-fields-wrapper {
        padding: 20px 20px 4px 20px;
        .row {
          margin: 0px -8px;
        }
        .field-col {
          padding: 0px 8px;
        }
      }
    }
  }
  .create__mass__email__form {
    .create-mass-email-contact-list {
      margin: 0px -9px;
      .contact-col {
        width: 50%;
        padding: 0px 9px;
      }
    }
  }
  // create__mass__email__page__end

  // send-mass-email-modal
  .send-mass-email {
    .top-header {
      & > .col-md-3 {
        width: 50%;
      }
    }
  }
  // send-mass-email-modal-end

  // scheduled-mass-email-view-modal
  .scheduled-mass-email-view-modal {
    .details-wrapper {
      .details-box {
        width: 50%;
      }
    }
    .accordion-margin.contact-accordion {
      .accordion-item {
        .event-contact-list-wrapper {
          .event-contact-col {
            width: 50%;
          }
        }
      }
    }
  }
  // scheduled-mass-email-view-modal-end

  // sendgrid-statistics-page
  .sendgrid-statistics-wrapper {
    .card-wrapper {
      .card-box {
        width: 50%;
      }
    }
  }
  // sendgrid-statistics-page-end

  // company-form-page css
  .company-form-page {
    .custom-col {
      width: 100%;
      &:first-child {
        margin-bottom: 25px;
      }
    }
    .marketing-form-col {
      width: 100%;
      &:first-child {
        margin-bottom: 25px;
      }
      .company-form-inner-card {
        .card-body {
          height: auto;
          max-height: calc(100dvh - 319px);
        }
      }
      &.load-more-active {
        .company-form-inner-card {
          .card-body {
            height: auto;
            max-height: calc(100dvh - 319px);
          }
        }
      }
    }
    .company-form-card-row {
      .company-form-card-col {
        width: 50%;
      }
    }
  }
  // company-form-page css end

  // contact-category-page
  .contact-category-page{
    .cn-mgct-status-tags-cf-wrapper{
      .card-header{
        .custom_select_wrapper{
          width:200px;
          max-width:100%;
        }
      }
    }
  }
  // contact-category-page-end

  // contact-tags
  .contact-tags-page {
    .card-header-with-buttons {
      .create-folder-link {
        margin-right: 10px !important;
      }
      .button-wrapper {
        margin-left: 10px !important;
      }
      .custom_select_wrapper {
        width: 175px !important;
      }
    }
  }
  // contact-tags-end

  // contact-custom-fields
  .contact-customField-page {
    .card-header-with-buttons {
      .button-wrapper {
        margin-left: 10px !important;
      }
    }
    .cn-mgct-status-tags-cf-wrapper{
      .card-header{
        .custom_select_wrapper{
          width:140px;
        }
      }
    }
  }
  // contact-custom-fields-end

  // SMS Templates page
  .company-sms-temp-card-row {
    margin: 0px -8px;
  }
  .company-sms-temp-card-col {
    padding: 0px 8px;
  }
  // SMS Templates page end

  // create-mass-sms
  .create-mass-sms-card {
    .contact-filters-wrapper {
      .filter-fields-wrapper {
        padding: 20px 20px 4px 20px;
        .row {
          margin: 0px -8px;
        }
        .field-col {
          padding: 0px 8px;
        }
      }
    }
  }
  .create-mass-sms-card {
    .create-mass-email-contact-list {
      margin: 0px -9px;
      .contact-col {
        width: 50%;
        padding: 0px 9px;
      }
    }
  }
  // create-mass-sms-end

  // send-mass-sms-modal
  .send-mass-sms-modal {
    .create-mass-sms-contact-list {
      margin: 0px -8px;
      .contact-col {
        padding: 0px 8px;
      }
    }
  }
  // send-mass-sms-modal-end

  // Mass-sms-blast
  .mass-sms-blast-page {
    .mass-sms-card-col,
    .scheduled-mass-sms-card-col {
      width: 50%;
    }
    .mass-sms-col,
    .schedule-mass-sms-col {
      width: 100%;
      .inner-scroll-area {
        height: auto;
        max-height: calc(100dvh - 272px);
      }
      .inner-scroll-area.load-more-active {
        height: auto;
        max-height: calc(100dvh - 339px);
      }
    }
    .mass-sms-col {
      margin-bottom: 20px;
    }
  }
  // Mass-sms-blast-end

  // marketing-add-forms
  .add-company-form-wrapper {
    .form-assignments {
      .form-filed-wrapper {
        width: 100% !important;
      }
    }
    .autoresponder-notification-row {
      & > .col-sm-6 {
        width: 100%;
        &:first-child {
          margin-bottom: 14px;
        }
      }
    }
  }
  // marketing-add-forms-end

  // task-add-update-modal
  .task-add-update-modal.modal-dialog {
    max-width: calc(100% - 30px) !important;
  }
  // task-add-update-modal-end

  //task-manager add-update-task
  .add-task-modal-new {
    .modal-header {
      padding: 20px 20px 66px 30px;
      .modal-title {
        .inner-wrapper {
          // display: block;
          display:flex;
        }
        .left {
          width: 100%;
        }
        .contact-details {
          width: 100%;
          justify-content: flex-start;
          padding-bottom: 0px;
          margin-top: 18px;
          padding-top: 0px;
        }
      }
    }
  }
  //task-manager add-update-task-end

  // email-page
  .email-modual {
    position: relative;
    .sidebar-left {
      position: absolute !important;
      top: 0px;
      left: 0px;
      height: 100%;
      z-index: 4;
      background-color: #ffffff;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
      box-shadow: 0px 0px 15px #cecece;
      transform: translateX(-120%);
      transition: 0.5s;
      .compose-btn-wrapper {
        padding-right: 26px;
      }
      .close-btn {
        display: block;
        transform: scaleX(-1);
        cursor: pointer;
      }
    }
    .content-right {
      width: 100% !important;
      .compose-btn {
        display: block !important;
      }
      .top-search-header {
        .task-manager-sidebar-toggle-btn {
          display: inline-flex !important;
        }
      }
    }
    &.open-sidebar {
      .sidebar-left {
        transform: translateX(0px);
      }
    }
  }
  .modal.modal-sticky.compose-mail-modal .modal-dialog {
    max-width: 100%;
  }
  // email-page-end

  // product-list
  .card-header.product-list-header {
    .right {
      .scan-product-btn {
        width: 41.2px;
        height: 41.2px;
        span {
          display: none;
        }
      }
      .add-product-btn {
        width: 41.2px;
        height: 41.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: none;
        }
      }
      .btn-group {
        flex-shrink: 0;
        .dropdown-toggle {
          width: 124px;
          padding-left: 12px;
          padding-right: 12px;
        }
      }
      .product-date-box {
        margin-left: 0px !important;
        width: 216px;
        flex-shrink: 0;
      }
      .contact-select-box {
        width: 50%;
      }
    }
  }
  // product-list-end

  // inventory-add-new-product
  .inventory-product-add-uodate-card {
    .add-product-details-card {
      .main-all-fields-wrapper {
        .col-md-4 {
          width: 50% !important;
          &:first-child {
            width: 100% !important;
          }
        }
      }
      .discount-type-col {
        width: 100% !important;
        padding-right: 0px;
      }
      .discount-type {
        .toggle-btns-wrapper,
        .discount-type-field {
          width: 50% !important;
        }
      }
    }
  }
  // inventory-add-new-product-end

  // inventory-add-new-product
  .inventory-product-add-uodate-card {
    .add-product-details-card {
      .dimentions-weight-row {
        .dimentions-col,
        .weight-col {
          width: 100% !important;
        }
        .weight-col {
          margin-bottom: 14px;
        }
      }
      .description-img-wrapper {
        & > .col-md-6,
        & > .attachments-col {
          width: 100% !important;
        }
        & > .attachments-col {
          margin-bottom: 10px;
        }
      }
      .inventory-add-attachment-wrapper {
        .custom-fileupload {
          .attachment-wrapper {
            .file__card {
              width: 25%;
            }
          }
        }
      }
      .question-box {
        .question-col {
          width: 50%;
        }
      }
    }
  }
  // inventory-add-new-product-end

  // inventory-product-details-page
  .inventory_product_details {
    .inventory-product-details {
      .product-images {
        width: 100%;
      }
      .product-content-right {
        width: 100%;
        padding-left: 0px;
        padding-top: 10px;
      }
    }
  }
  // inventory-product-details-page-end

  // task-manager
  .loyal-task-manager {
    .sidebar-left.show + .content-right {
      overflow-x:hidden;
      .new-tab-details-design{
        width:800px;
        position: relative;
      }
      .task-manager-top-header,
      .task-manager-body,
      .task-calenderView-wrapper{
        width: 800px;
      }
      .task-calenderView-wrapper{
        .full-calender-wrapper{
          .startDate-endDate-wrapper{
            display:none;
          }
        }
      }
    }
  }
  // task-manager-end
  // contact-inner-page-design
  .contact__card__wrapper.new-design {
    .contact-personalInfo-wrapper {
      .right-tabContant-wrapper {
        .formField-row {
          .formField-col.city,
          .formField-col.state,
          .formField-col.country,
          .formField-col.zipCode {
            width: 50%;
          }
        }
      }
    }
  }
  // contact-inner-page-design-end

  // mail-task-preview
  .mail-task-preview {
    .task__top__details__col {
      width: 100%;
    }
  }
  // mail-task-preview-end

  // task-modal
  .add-task-modal-new.only-add-task-modal {
    .task__top__details__col {
      .custom-select__menu {
        width: 300px;
        right: 0px;
      }
      .react-select {
        width: auto;
        max-width: 100%;
      }
      .value {
        .assignTo-wrapper {
          & > div {
            width: auto;
            max-width: 100%;
          }
        }
      }
    }
    .tab-pane {
      form{
        & > .attachment-save-btn-wrapper{
          .custom-fileupload{
            .file__card{
              width:25%;
            }
          }
        }
      }
    } 
  }
  // task-modal-end

  // new-tab-details-design
  .new-tab-details-design{
    .contact__files__wrapper{
      .company-document-page{
        .company-document-listing-row{
          .company-checklist-inner-scroll{
            height:auto !important;
          }
        }
      }
    }
  }
  // new-tab-details-design-end

  // direct__mail__card
  .direct__mail__card {
    .company-email-temp-card-row {
      .company-email-temp-card-col {
        width: 100%;
      }
    }
  }
  // direct__mail__card__end

  // email-modual
  .email-application{
    .content-area-wrapper{
      .email-modual{
        .sidebar-left{
          width:200px;
          .compose-btn-wrapper{
            .compose-btn{
              font-size:14px;
              svg{
                width:16px;
                height:16px;
                margin-right:5px !important;
                top:0px;
              }
            }
          }
          .sidebar-menu-list{
            .list-group{
              .list-group-item{
                .badge{
                  display:inline-flex;
                }
              }
            } 
          }
        }
        .content-right{
          width:100% !important;
          .top-search-header{
            padding:8px 14px 8px 15px;
          }
          .select-all-wrapper{
            padding:7px 13px 7px 10px;
            .action-right{
              .action-icon{
                &.split-view-icon{
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }

  .reply-mail-form{
    width: calc(100% + 65px);
    position: relative;
    left: -65px;
  }
  .mail-task-preview{
    width: calc(100% + 65px);
    position: relative;
    left: -65px;
    .custom-select__menu{
      right:unset;
      left:0px;
    }
  }
  // email-modual-end

  // task-report-page
  .task-report-page{
    .card-header{
      .card-title{
        margin-bottom:10px;
      }
      .right-wrapper{
        .contact-modal{
          width:230px;
        }
      }
    }
  }
  // task-report-page-end

  // contact profile page
  .contact__card__wrapper{
    &.new-design{
      .mobile-contactInfo-sidebar-toggle-btn{
        margin-left:0px;
        transition:0.5s;
        width: 38px;
        height: 38px;
        padding:8px 7px;
        border-radius:6px;
        position:absolute;
        top:-1px;
        right:12px;
        background-color:#ffffff;
        cursor: pointer;
        display:none;
        .line{
          background-color:transparent;
          height: 8px;
          width: 8px;
          border-radius:50%;
          border:1px solid #000000;
          position:absolute;
          &:first-child{
            top:8px;
            left:8px;
          }
          &:nth-child(2){
            top:8px;
            right:8px;
            width:8px;
          }
          &:nth-child(3){
            bottom:8px;
            left:8px;
          }
        }
        &::before{
          content:"";
          position:absolute;
          bottom:8px;
          right:8px;
          height:8px;
          width:8px;
          border-radius:50%;
          border:1px solid #000000;
        }
        &:hover{
          background-color:#000000;
          .line{
            border:1px solid #ffffff;
          }
          &::before{
            border:1px solid #ffffff;
          }
        }
      }
      &.contactInfo-tab-active{
        .mobile-contactInfo-sidebar-toggle-btn{
          display:block;
        }
        & > .card-header{
          & > .card-title{
            max-width:calc(100% - 45px);
          }
        }
      }
      .contact-personalInfo-wrapper{
        .vertical-tab-wrapper{
          position: fixed;
          top: 0px;
          left: 0px;
          width: 240px;
          height: 100dvh;
          background-color:#f7f6f0;
          z-index:9;
          padding:12px 12px;
          overflow: visible;
          transition:0.5s;
          transform:translateX(-120%);
          .mobile-close-btn{
            position:absolute;
            top:5px;
            right:-36px;
            width:30px;
            height:30px;
            border-radius:50%;
            background-color:#f7f6f0;
            transition:0.5s;
            cursor: pointer;
            &:before,
            &:after{
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 14px;
              height: 2px;
              background-color: #000;
              transform: translate(-50%,-50%) rotate(45deg);
              transition:0.5s;
            }
            &:after{
              transform: translate(-50%,-50%) rotate(-45deg);
            }
            &:hover{
              background-color:#000000;
              &::before,
              &::after{
                background-color:#ffffff;
              }
            }
          }
          .inner-scroll-wrapper{
            height:calc(100dvh - 80px);
          }
        }
        .right-tabContant-wrapper{
          width:100%;
          padding-left:0px;
        }
      }
      .add-update-contact-tab-wrapper{
        margin-left: -15px;
        margin-right: -15px;
        padding:0px 15px;
      }
    }
  }
  .app-content{
    &.contact-profile-sidebar-open{
      z-index: 12;
      .contact__card__wrapper{
        &.new-design{
          .contact-personalInfo-wrapper{
            .vertical-tab-wrapper{
              transform:translateX(0px);
            }
            .vertical-tab-mobile-overllay{
              position: fixed;
              top: 0px;
              left:0px;
              width:100vw;
              height:100dvh;
              z-index:7;
              background-color: rgba(0, 0, 0, 0.2);
              backdrop-filter: blur(7px);
            }
          }
        }
      }
    }
  }
  // contact profile page

  // move-contacts-modal
  .move-contacts-modal{
    &.modal-dialog{
      max-width:800px;
    }
  }
  // move-contacts-modal-end

  // send-mass-sms-modal
  .send-mass-sms-modal{
    .create-mass-sms-contact-list{
      .contact-col{
        width:50%;
      }
    }
  }
  // send-mass-sms-modal-end

  // add-envelope-page
  .envelope-preview-wrapper{
    padding:15px 0px 10px 0px;
  }
  .envelope-page{
    .accordian-loyal-box.envelope-design-setting{
      .range-row{
        .range-col{
          width:100%;
        }
      }
    }
  }
  // add-envelope-page-end

  // envelope-listing-page
  .envelope-card{
    .company-email-temp-card-row{
      margin:0px 0px;
    }
  }
  // envelope-listing-page-end

  // add-update-event-modal
  .add-update-event-modal{
    .event-details-wrapper{
      .left,
      .right{
        width:100% !important;
      }
      .right{
        .textarea{
          height:100px !important;
        }
      }
    }
  }
  // add-update-event-modal-end

  // super-admin-css-start
  .add-update-company-superAdmin{
    & > .card-body{
      padding:0px 0px 0px 0px !important;
    }
    .sender-emails{
      padding:16px 15px 15px 15px;
    }
  }
  // super-admin-css-end

  .contact__card__wrapper{
    .contactDocumentPage{
      .documentListingRow{
        .documentListingCol{
          width:50%;
        }
      }
    }
  }

  .auth-login-form {
    .header__title {
      width: 240px;
    }
    .attachment-btns {
      width: calc(100% - 260px);
      justify-content: flex-end !important;
    }    
  }


}

@media only screen and (max-width: 767px) {
  .contactCompany__card{
    max-height:calc(100vh - 184px);
    .card-header{
      background-color:transparent !important;
      border-bottom: none;
      .back-arrow{
        &:hover{
          background-color:#ffffff;
        }
      }
    }
    .card-body{
      .leftForm,
      .rightContactList{
        padding:15px;
      }
      .leftForm{
        padding-top:0px;
      }
      .rightContactList{
        .contact-row{
          .contact-col{
            .innerWrapper{
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }

  // comman-css
  html body{
    height:auto !important;
    // padding-bottom:90px;
    // padding-bottom:65px;
    padding-bottom:0;
    overflow-x:hidden;
    // padding-bottom:0px;
  }
  body .content.app-content {
    overflow: visible !important;
    min-height: unset !important;
    height: auto !important;
    // padding:50px 15px 0px 15px !important;
    padding: 0px 0px 0px 0px !important;
    padding-top: 65px !important;
    // background-color:#ffffff;
    background-color: transparent;
    // overflow:hidden !important;
    // border-top-left-radius: 20px;
    // border-top-right-radius: 20px;
    height: 100% !important;
  }
  html body { height: 100% !important;}
  .scroll-top{
    right: 15px;
    bottom: 75px;
  }

  .card{
    background-color:transparent;
    border:none;
  }

  .table__page__limit{
    .table__page__limit__control{
      background-color:#ffffff !important;
    }
  }

  .form-label {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .imgupload-dropzon{
    background-color:white;
    align-items:flex-start;
    .left-preview{
      width:52px;
      height:52px;
      .fileIcon-wrapper{
        padding:10px 10px;
      }
    }
    .right-details {
      width: calc(100% - 52px);
      .title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 4px;
      }
      .text {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .image-upload-wrapper {
        .upload-btn,
        .reset-btn {
          font-size: 12px;
          padding: 8px 13px 9px 13px;
        }
        .upload-btn {
          margin-right: 8px !important;
        }
      }
    }
  }

  .modal {
    height: 100dvh;
    transition: unset !important;
    .modal-dialog {
      &.modal-dialog-mobile {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        padding: 0px !important;
        height: 100dvh;
        align-items: flex-end !important;
        transition: unset !important;
        .modal-content {
          box-shadow: unset;
          border-radius: 0px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          animation-name: modalDialogMobile;
          animation-duration: 0.2s;
          animation-timing-function: linear;
          .modal-header{
            border-top-left-radius:20px;
            border-top-right-radius:20px;
            box-shadow: 0px 1px 9px #bbb;
            z-index:3;
          }
        }
        .modal-body{
          max-height:calc(100dvh - 175px);
          overflow-y:auto;
        }
      }
    }
  }
  @keyframes modalDialogMobile {
    0% {
      transform: translateY(50%);
      opacity: 1;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .attachment-wrapper {
    margin: 0px 0px;
    .file__card {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0px 0px;
      margin-bottom: 10px;
      .inner-border-wrapper {
        background-color: #f9f9f9 !important;
        border: 1px solid #ececec;
        padding: 5px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        .close-btn {
          display: none;
        }
        .inner-wrapper {
          padding: 0px !important;
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          .file__preview {
            img {
              border-radius: 6px;
            }
          }
          .overlay {
            display: none;
          }
        }
        .image__edit__wp {
          padding: 0px 6px 0px 10px;
          width: calc(100% - 40px);
          .file-name {
            width: 100%;
            padding-right: 0px;
          }
          .file__edit__field {
            width: 100%;
          }
          .edit-btn,
          .save-btn {
            display: none;
          }
        }
        .action-btn-wrapper {
          display: none;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          .action-btn {
            width: 28px;
            height: 28px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            border-radius: 50%;
            background-color: transparent;
            padding: 6px;
            cursor: pointer;
            margin-right: 0px;
            margin-left: 0px;
            svg {
              width: 100%;
              height: 100%;
              color: #777 !important;
            }
            &:hover {
              background-color: #000000;
              svg {
                color: #ffffff !important;
              }
            }
          }
        }
      }
      &:hover {
        .inner-border-wrapper {
          .image__edit__wp {
            width: calc(100% - 152px);
          }
          .action-btn-wrapper {
            display: flex;
          }
        }
      }
    }
  }

  .contact__card__wrapper{
    .contactDocumentPage{
      padding:15px;
      height: calc(100vh - 235px);
      overflow-y: auto;
      .listingScrollWrapper{
        margin-right:0px;
        padding-right:0px;
        height:auto;
      }
    }
  }

  .new-tab-details-design {
    .horizontal-new-tab-wrapper {
      .horizontal-tabbing {
        .nav-item {
          .nav-link {
            font-weight: 700;
          }
          &:last-child {
            margin-right: 22px;
          }
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        width: 30px;
        background-image: linear-gradient(
          to right,
          rgba(247, 246, 240, 0),
          rgb(247, 246, 240)
        );
        z-index: 3;
      }
    }
    .contact-activity-tabPane{
      padding:4px 10px 0px 10px;
      height:calc(100dvh - 188px);
      &:before{
        content: "";
        position: fixed;
        bottom:64px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height:50px;
        z-index:4;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
  }

  .radio-switch-toggle-btn-wrapper{
    .radio-btn-repeater{
      margin-right:15px !important;
      .form-check-label{
        padding-right: 56px;
        &:before{
          width: 46px;
          height: 24px;
        }
        &:after{
          top: 2px;
          width: 16px;
          height: 16px;
          right: 26px;
        }
      }
      input:checked + .form-check-label{
        &:after{
          right:5px;
        }
      }
      &:last-child{
        margin-right:0px !important;
      }
    }
  }

  .switch-checkbox{
    .switch-design{
      width: 44px;
      height: 24px;
      &::before{
        width: 18px;
        height: 18px;
        top: 3px;
        left:3px;
      }
    }
    input:checked + .switch-design{
      &::before{
        left: 23px;
      }
    }
  }
  // comman-css-end

  // footer
  .footer {
    display: none !important;
  }
  // footer-end

  // sidebar
  .main-menu.menu-fixed {
    position: fixed;
    top: 0px;
  }
  // sidebar-end

  // header
  .header-navbar {
    min-height: unset !important;
    border-bottom: none;
    top: 0px;
    .navbar-container {
      width: 100%;
    }
    .mobile-menu-toggle-btn {
      width: 34px;
      height: 34px;
      border-radius: 8px;
      top: 0px;
      flex-shrink: 0;
      .dot{
        background-image:unset !important;
      }
      .dot:nth-child(1) {
        top: 6px;
        left: 7px;
      }
      .dot:nth-child(2) {
        top: 6px;
        left: 20px;
      }
      .dot:nth-child(3) {
        top: 20px;
        left: 7px;
      }
      .dot:nth-child(4) {
        top: 20px;
        left: 20px;
      }
    }
    .navbar-nav {
      width: 100%;
      padding-left: 12px;
      & > div {
        flex-shrink: 0;
      }
    }
    .company-details {
      display: none;
    }
    .navbar-container {
      // padding: 7px 10px !important;
      padding: 12px 10px !important;
      min-height: unset !important;
    }
    .user-details {
      border-left: none;
      padding-left: 0px;
      margin-left: 0px;
      .dropdown-user-link {
        .avatar {
          width: 34px;
          height: 34px;
        }
        .right {
          padding-left: 16px;
          padding-right: 0px;
          .user-name,
          .user-status {
            display: none;
          }
        }
      }
      .dropdown-menu[data-popper-placement]:not(
          [data-popper-placement^='top-']
        ) {
        top: unset !important;
        bottom: 0px !important;
      }
    }
    .report-bug-btn {
      width: 34px;
      height: 34px;
      padding: 8px;
    }
    .task-timer-btn-wrapper {
      margin-right: 6px;
      .task-timer-btn {
        width: 34px;
        height: 34px;
      }
    }
    .report-bug-btn-wrapper {
      margin-right: 6px;
    }
    .navbar-search {
      order: -1;
      width: calc(100% - 102px);
      input {
        padding: 4px 14px 4px 32px;
        height: 36px;
        background-size: 14px;
        background-position: 9px 10px;
        font-size: 14px;
      }
      &.active {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background-color: #fff;
        height: 100dvh;
        z-index: 4;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;
        .mobile-title {
          display: block;
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 0px;
          padding: 10px 12px 0px 12px;
        }
        input {
          width: calc(100% - 30px);
          margin-top: 13px;
          margin-bottom: 13px;
          margin-right: 15px;
          margin-left: auto;
        }
        .nav-search-content {
          position: static;
          width: 100%;
          box-shadow: unset;
          max-height: calc(100dvh - 93px);
          .nav-search-item {
            padding: 10px 12px;
            img {
              width: 32px !important;
              height: 32px !important;
              border-radius: 50% !important;
            }
            &:first-child {
              border-top: 1px solid #ebe9f1 !important;
            }
          }
        }
      }
      .back-arrow {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 6px;
        align-items: center;
        justify-content: center;
        display: none;
        margin-left: 10px;
        background-color: #f9f9f9;
        svg {
          width: 100%;
          height: 100%;
          color: #000000;
        }
        &:hover {
          background-color: #000000;
          svg {
            color: white;
          }
        }
      }
    }
    .dropdown-user {
      .dropdown-menu {
        position: fixed !important;
        bottom: 0px !important;
        left: 0px;
        width: 100vw;
        border-top-left-radius: 17px;
        border-top-right-radius: 17px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        z-index: 16;
        transform: translate(0px, 110%) !important;
        inset: unset !important;
        top: unset !important;
        transition: 0.6s !important;
        border: none !important;
        box-shadow: unset !important;
        max-height: calc(100dvh - 5px);
        padding-top: 30px;
        .dropdown-item {
          font-size: 16px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 14px;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          width: 50px;
          background-color: #a8a8a8;
          border-radius: 20px;
        }
      }
      &.show {
        &:before {
          content: '';
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100vw;
          height: 100dvh;
          background-color: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(7px);
          z-index: 15;
        }
        .dropdown-menu {
          transform: translate(0px, 0%) !important;
        }
      }
    }
    .task-timer-btn-wrapper {
      display: none;
      &.active {
        display: block;
        &:before {
          content: '';
          position: fixed;
          top: 0px;
          width: 100vw;
          height: 100dvh;
          -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
          z-index: 15;
          background-color: rgba(0, 0, 0, 0.2);
          left: 0px;
        }
        .task-timer-dropdown {
          display: block;
          position: fixed;
          bottom: 0px;
          left: 0px;
          transform: translate(0px, 0px);
          top: unset;
          width: 100%;
          padding-top: 0px;
          z-index: 15;
          .inner-wrapper {
            box-shadow: unset;
            border-radius: 0px;
            .task-timer-header {
              position: relative;
              padding: 20px 10px 8px 15px;
              &::before {
                content: '';
                position: absolute;
                top: 10px;
                height: 4px;
                width: 50px;
                background-color: #ffffff;
                border-radius: 20px;
                left: 50%;
                transform: translateX(-50%);
              }
              .title {
                font-size: 16px;
              }
            }
            .task-timer-body {
              border-bottom-left-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
              max-height: calc(100dvh - 100px);
              overflow-y: auto;
              .taskId,
              .taskName,
              .timer {
                font-size: 14px !important;
              }
              .task-timer-row {
                .action-btn-wrapper {
                  .action-btn {
                    width: 24px;
                    height: 24px;
                    padding: 2px;
                    &.play-btn,
                    &.stop-btn {
                      margin-right: 2px;
                    }
                  }
                }
              }
            }
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  // new-version
  .header-navbar {
    padding-left: 0px;
    .mobile-menu-toggle-btn {
      background-color: white;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
      position: relative;
      top: 0px;
      width: 38px;
      height: 38px;
      .dot {
        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 23px;
          height: 2px;
          border: none;
          position: absolute;
          top: 14px;
          right: 6px;
          left: unset;
          border-radius: 0px;
          background-color: #000000;
        }
        &:nth-child(4) {
          width: 15px;
          top: 21px;
        }
      }
      &:hover {
        .dot {
          &:nth-child(3),
          &:nth-child(4) {
            background-color: white;
          }
        }
      }
    }
    .navbar-nav {
      order: -1;
      padding-left: 0px;
      padding-right: 3px;
      width: calc(100% - 38px);
      .report-bug-btn-wrapper {
        margin-right: 9px;
      }
      .header-action-btn,
      .notification-btn{
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        width: 38px;
        height: 38px;
        margin-right:10px;
        svg {
          color: #000000;
        }
        &:hover {
          background-color: #000000;
          svg {
            color: white;
          }
        }
      }
      .navbar-search {
        order: 1;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        transition: 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
        .search-icon {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 9px;
          svg {
            width: 100%;
            height: 100%;
            color: #000000;
            transition: 0.4s;
          }
        }
        input {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 3;
          opacity: 0;
          cursor: pointer;
          transition: 0s;
        }
        &:hover {
          background-color: #000000;
          .search-icon {
            svg {
              color: #ffffff;
            }
          }
        }
        &.active {
          width: 100%;
          height: 100dvh;
          display: flex;
          justify-content: center;
          border-radius: 0px;
          .search-icon {
            display: none;
          }
          input {
            position: static;
            width: calc(100% - 73px);
            height: 36px;
            opacity: 1;
          }
          &:hover {
            background-color: #ffffff;
          }
          .back-arrow {
            display: flex;
            cursor: pointer;
          }
        }
      }
      .dropdown-user {
        order: -1;
        // width: calc(100% - 86px);
        width: calc(100% - 94px);
        padding-right: 12px;
        .avatar {
          width: 38px;
          height: 38px;
        }
        .right {
          padding-left: 10px;
          width: calc(100% - 40px);
          max-width: calc(100% - 40px);
          &:before {
            display: none;
          }
          .user-name,
          .user-status {
            display: block;
          }
          .user-name {
            margin-bottom: 1px;
            font-size: 16px;
          }
          .user-status {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  // new-version-end
  // header-end

  // mobile-bottom-menu
  .mobile-bottom-menu-wrapper {
    display: block;
  }
  // mobile-bottom-menu-end

  // dashboard-page
  .dashboard-page {
    min-height: calc(100dvh - 170px);
  }
  // dashboard-page-end

  // contact-activity-tabPane
  .contact-activity-tabPane {
    .activity-item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 40px;
      }
      .details-wrapper {
        .header {
          .time {
            display: none;
          }
        }
        .contant {
          margin-top: 2px;
        }
      }
      .time-mobile {
        display: flex;
      }
    }
  }
  // contact-activity-tabPane-end

  // contact-notes-tabPane
  .new-tab-details-design {
    .contact-notes-tabPane {
      .inner-contant-wrapper {
        padding: 4px 0px 0px 0px;
      }
      .available-notes-header {
        flex-wrap: nowrap;
        margin-bottom: 10px;
        padding: 0px 15px;
        flex-wrap: wrap;
        .print-action-wrapper {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          margin-left: 12px;
          .print-btn {
            font-size: 0px;
            padding: 4px 4px;
            width: 42px;
            height: 41.2px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            svg {
              margin-right: 0px;
            }
          }
        }
        .dropdown-toggle {
          font-size: 0px;
          padding: 6px 12px 6px 10px;
          height: 41.2px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          .align-middle {
            display: none;
          }
          &:after {
            left: 5px;
          }
        }
        .add-note-btn {
          position: fixed;
          margin: 0px !important;
          bottom: 88px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 4;
          border-radius: 100px;
          width: 165px;
        }
      }
      .contact-notes {
        .accordion-item {
          box-shadow: unset !important;
          background-color: transparent;
          border-radius: 0px !important;
          margin-bottom: 0px !important;
          border: none !important;
          border-bottom: 1px solid #d9d9d9 !important;
          .accordion-header {
            .accordion-button {
              position: relative;
              .inner-wrapper {
                width: 100%;
                padding-right:60px;
                .heading-details {
                  .title {
                    width: calc(100% - 70px);
                    max-width: 100%;
                    margin-right: 0px;
                    font-size: 16px;
                  }
                  .author-time {
                    width: 100%;
                    flex-wrap:wrap;
                    .author-name,
                    .time {
                      font-size: 14px;
                    }
                    .author-name {
                      margin-right: 8px;
                      max-width: calc(100% - 170px);
                      white-space: pre;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
              .action-btn-wrapper {
                position: absolute;
                top: 7px;
                right: 10px;
                .action-btn {
                  width: 26px;
                  height: 26px;
                  border-radius: 4px;
                  margin-right: 2px;
                  display:none !important;
                  &.edit-btn,
                  &.delete-btn {
                    padding: 6px;
                    display: none;
                  }
                  &:last-child {
                    margin-right: 0px;
                  }
                  &:hover {
                    background-color: #ffffff;
                  }
                  &.mobile-toggle-btn,
                  &.down-arrow-btn{
                    display:flex !important;
                  }
                }
              }
              &:not(.collapsed){
                .inner-wrapper{
                  .heading-details{
                    .title{
                      width:auto !important;
                      max-width:100% !important;
                    }
                  }
                }
              }
            }
          }
          .accordion-body {
            padding: 0px 15px 15px 15px;
          }
          .contant-wrapper {
            .normal-text {
              font-size: 14px;
              line-height: 20px;
              p {
                line-height: 24px;
              }
            }
          }
          .attachment-wrapper {
            margin: 0px -5px;
            margin-top: 16px;
            .file__card {
              width: 25% !important;
              max-width: 25% !important;
              padding: 0px 5px;
              .inner-border-wrapper {
                .inner-wrapper {
                  padding-top: 100% !important;
                  width: 100%;
                  height: auto;
                  .overlay {
                    display: flex;
                    .action-btn-wrapper {
                      .action-btn {
                        width: 30px;
                        height: 30px;
                        &:hover {
                          background-color: rgba(255, 255, 255, 0.2);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .footer-details {
            display: none;
          }
          .accordion-collapse.show {
            border-top: none;
          }
          &:hover {
            .accordion-header {
              .accordion-button {
                .inner-wrapper {
                  .heading-details {
                    .title {
                      width: calc(100% - 70px);
                    }
                  }
                }
                .action-btn-wrapper {
                  .action-btn {
                    &.edit-btn,
                    &.delete-btn {
                      display: flex;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .notes-list-scroll-area {
        position: relative;
        &:before {
          content: '';
          position: fixed;
          bottom: 88px;
          width: 100%;
          height: 40px;
          z-index: 3;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
        & > div:last-child {
          margin-bottom: 40px;
          .accordion-item {
            border-bottom: none !important;
          }
        }
      }
    }
  }
  // contact-notes-tabPane-end

  // add-update-note-modal
  .add-update-note-modal{
    .modal-footer{
      border-top:1px solid #dddddd;
      display:flex;
      justify-content:space-between;
      .cancel-btn{
        margin:0px !important;
        width:calc(50% - 6px) !important;
      }
      .add-note-btn{
        margin:0px !important;
        width:calc(50% - 6px) !important;
      }
    }
  }
  // add-update-note-modal-end

  // contact__checklist__wrapper
  .new-tab-details-design{
    .contact__checklist__wrapper{
      padding-top:8px;
      .company-checklist-inner-scroll{
        max-height: calc(100dvh - 302px);
        &:before{
          content: "";
          position: fixed;
          bottom: 64px;
          width: 100%;
          height: 50px;
          z-index: 2;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
        .accordion-margin:last-child{
          margin-bottom:25px;
        }
        .checklist-search{
          width:100%;
          padding:10px 15px 0px 15px;
          margin-bottom:5px;
        }
      }
      .list-grid-btn-wrapper{
        right:15px;
        top:0px;
      }
      .card-header{
        padding: 0px 15px 15px 15px;
        box-shadow: 0px 4px 6px #dbdbdb;
        .card-title{
          font-size:20px !important;
        }
        .btns-wrapper{
          width:100%;
          margin-top:20px !important;
          display:flex;
          align-items:center;
          justify-content:space-between;
          .create-folder-link,
          .add-btn-wrapper{
            width:calc(50% - 6px);
          }
          .create-folder-link{
            background-color:#ffffff;
            display:flex;
            justify-content:center;
            outline:1px solid var(--primaryColor);
            height:39.88px;
            font-weight:500;
            padding:11px 6px;
            &:hover{
              background-color:var(--primaryColor);
              color:#000000;
            }
          }
          .add-btn-wrapper{
            .btn{
              margin-left:0px !important;
              width:100% !important;
              padding:11px 6px;
            }
          }
        }
      }
      .compnay-checklist-folder-wrapper > .card-body{
        padding:7px 0px 0px 0px !important;
      }
      .accordion-item.checklist-box{
        margin-bottom:0px !important;
        box-shadow:unset !important; 
        border-radius:0px !important;
        background-color:transparent;
        border:none !important;
        border-bottom:1px solid #d9d9d9 !important;
        .checklist-header{
          .accordion-button{
            padding:11px 10px 11px 15px !important;
          }
          .title{
            font-size:15px;
            width:calc(100% - 37px);
            max-width:calc(100% - 37px);
            .badge{
              font-size: 11px;
              padding: 2px 9px !important;
              height: 20px;
              line-height: 20px;
              min-width: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0px 0px 0px 8px !important;
              position: relative;
              top: 1px;
            }
          }
          .action-btn-wrapper {
            padding-left: 10px;
            .action-btn {
              padding: 5px;
              width: 26px;
              height: 26px;
              margin-right: 2px;
              display: none;
              &:hover {
                background-color: #ffffff;
              }
              &.down-arrow-btn {
                padding: 3px;
                margin-right: 0px;
                display: flex;
                svg {
                  color: #000000;
                }
              }
              &.mobile-menu-btn{
                display:flex;
                svg{
                  color:#000000;
                }
              }
            }
          }
        }
        .company-form-card.checklist-company-form-card{
          background-color:#ffffff;
          .header-wrapper{
            padding:10px 8px 10px 15px;
            .form-card-title{
              font-size:15px;
            }
            .action-btn-wrapper{
              .action-btn{
                width:26px;
                height:26px;
                margin-right:2px;
                display:none;
                align-items:center;
                justify-content:center;
                padding:6px;
                svg{
                  width:100%;
                  height:100%;
                }
                &:last-child{
                  margin-right:0px;
                }
                &.toggle-btn{
                  display:flex;
                }
              }
            }
          }
          .body-wrapper{
            .checklist-listing-items{
              .item{
                .form-check-input{
                  width:17px;
                  height:17px;
                  position: relative;
                  top:2px;
                }
              }
            }
          }
        }
      }
      .company-checklist-row{
        .custom-col{
          width:100%;
        }
      }
    }
  }

  .checklist-list-view-table{
    border-radius:6px;
    .table-header{
      .table-row{
        border-top-left-radius:6px;
        border-top-right-radius:6px;
      }
    }
    .table-body{
      .table-row{
        &:last-child{
          border-bottom-left-radius:6px;
          border-bottom-right-radius:6px;
        }
      }
    }
    .table-row{
      .table-cell{
        padding:12px 10px !important;
        &:nth-child(1){
          width:38px;
        }
        &:nth-child(2){
          width:calc(100% - 196px);
        }
        &:last-child{
          width:158px;
        }
      }
    }
    .action-btn-wrapper{
      .action-btn{
        width:26px;
        height:26px;
        padding:6px;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-right:2px;
        svg{
          width:100%;
          height:100%;
        }
        &:last-child{
          margin-right:0px;
        }
      }
    }
  }
  // contact__checklist__wrapper__end

  // report-problem-modal
  .report-problem-modal{
    .modal-body{
      padding:0px 0px 15px 0px;
      max-height:unset !important;
      .report-problem-form{
        .scroll-wrapper{
          max-height: calc(100dvh - 215px);
          overflow-y:auto;
          padding:20px 15px 0px 15px;
        }
        .attachment-save-btn-wrapper {
          .custom-fileupload {
            .ln-btn {
              // width:calc(50% - 6px);
              // padding:7px 9px;
              position: fixed;
              right: unset;
              left: 15px;
              bottom: 15px;
            }
            .remove-all-btn {
              position: static;
              margin-bottom: 18px;
              margin-top: 7px !important;
            }
          }
        }
      }
    }
    .submit-btn-wrapper {
      padding: 0px 15px;
      border-top: 1px solid #e8e8e8;
      padding-top: 15px;
      .btn {
        width: 140px;
      }
    }
  }
  // report-problem-modal-end

  // setting-profile-page
  .setting-profile-page {
    .personal-info-tab {
      padding: 0px 15px 40px 15px;
      height: calc(100dvh - 216px);
      overflow-y: auto;
      &:before {
        content: '';
        position: fixed;
        bottom: 92px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 50px;
        background-image: linear-gradient(
          rgba(247, 246, 240, 0),
          rgba(247, 246, 240, 1)
        );
        z-index: 2;
      }
      .card-header {
        .card-title {
          font-size: 20px !important;
          align-items: center;
        }
      }
      .update-profile-form {
        .field-wrapper {
          width: 100%;
        }
        .submit-btn-wrapper {
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          bottom: 92px;
          z-index: 3;
          margin-top: 0px !important;
          .btn {
            border-radius: 100px;
          }
        }
      }
    }
    .contact-activity-tabPane {
      .activity-item {
        .icon-wrapper {
          border: 2px solid #f7f6f0;
        }
      }
    }
  }
  // setting-profile-page-end

  // create-update-folder-name
  .create-update-folder-name{
    .modal-footer{
      form{
        margin:0px 0px !important;
        width:100%;
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // create-update-folder-name-end

  // reset-password-modal
  .reset-password-modal{
    .modal-footer{
      display:flex;
      align-items:center;
      justify-content:space-between;
      .btn-danger{
        width:calc(50% - 6px);
      }
      .auth-login-form{
        margin-left:0px !important;
        width:calc(50% - 6px);
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // reset-password-modal-end

  // user-guide-preview-modal
  .user-guide-preview-modal{
    .img-wrapper{
      img{
        border-radius:8px;
      }
    }
    .video-wrapper{
      .video-box{
        .cover-wrapper{
          .video-play-button{
            &:before{
              width: 42px;
              height: 42px;
            }
            &:after{
              width: 42px;
              height: 42px;
            }
            span{
              border-top:8px solid transparent;
              border-bottom:8px solid transparent;
              border-left: 14px solid #fff;
              left:7px;
            }
          }
        }
      }
    }
  }
  // user-guide-preview-modal

  // add-checklist-modal
  .add-checklist-modal{
    .modal-footer{
      display:flex;
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
    .accordion-item.checklist-box{
      border-radius:7px !important;
      margin-bottom:10px !important;
      .checklist-header{
        padding:4px 10px 4px 10px;
        .form-check-input{
          width: 17px;
          height: 17px;
        }
        .title-field-wrapper{
          margin:0px 0px 0px 12px;
          width:calc(100% - 60px);
          .input-field{
            font-size:14px;
            padding:6px 0px 8px 0px;
            border-radius: 7px;
            margin-top:0px !important;
            &:focus{
              padding:6px 10px 8px 10px;
            }
          }
        }
        .action-btn{
          &.close-btn{
            width: 25px;
            height: 25px;
            padding: 3px;
          }
        }
      }
      .accordion-body{
        padding:6px 6px;
      }
    }
    .add-more-btn{
      margin-top:6px !important;
    }
  }
  // add-checklist-modal-end

  // delete-folder-modal
  .contact-manage-group-delete-modal,
  .delete-folder-modal{
    &.modal-dialog{
      max-width:100% !important;
    }
    .modal-footer{
      display:flex;
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
    .delete-folder-card{
      .card-header{
        .card-title{
          font-size:14px !important;
        }
      }
      .rdt_Table_wrapper{
        .search-box-wrapper{
          width:100% !important;
          margin-top:12px;
        }
      }
    }
    .select-new-folder-wrapper{
      width:100%;
    }
    .card-body{
      margin-top:0px !important;
    }
  }
  // delete-folder-modal-end

  // contact-manage-group-delete-modal
  .contact-manage-group-delete-modal{
    .select-new-group-wrapper{
      width:100%;
    }
    .rdt_Table_wrapper{
      .search-box-wrapper{
        width:100%;
        margin-top: 10px;
      }
    }
  }
  // contact-manage-group-delete-modal-end

  // view-checklist-modal
  .add-checklist-modal.view-checklist-modal{
    .folder-template-names-wrapper{
      .data-row{
        width:100%;
        margin-bottom:12px;
        &:last-child{
          margin-bottom:0px;
        }
      }
    }
    .view__checklist{
      .accordion-item.checklist-box{
        .checklist-header{
          .title-field-wrapper{
            font-size:15px;
          }
        }
      }
    }
  }
  // view-checklist-modal-end

  // company-document-page
  .new-tab-details-design{
    .company-document-page{
      position: relative;
      &::before{
        content: "";
        position: fixed;
        bottom: 70px;
        width: 100%;
        height: 50px;
        z-index: 2;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
      & > .ui-loader{
        padding:15px 0px;
        // max-height:calc(100dvh - 275px);
        max-height:calc(100dvh - 310px);
        overflow-y:auto;
      }
      .top-header{
        margin-bottom:0px !important;
        padding:0px 15px 15px 15px !important;
        box-shadow: 0px 4px 6px #dbdbdb;
        position: relative;
        z-index: 7;
      }
      .add-new-btn{
        &:first-child{
          position: fixed;
          bottom: 88px;
          z-index:6;
          width: 165px;
          margin: 0px !important;
          left: 50%;
          transform: translateX(-50%);
          .btn{
            border-radius: 100px;
            width:100%;
          }
        }
        .create-folder-link{
          margin-left:0px;
        }
      }
      .company-document-listing-row{
        margin-bottom:5px !important;
        .card-header{
          border-top-left-radius:0px;
          border-top-right-radius:0px;
          padding:10px 10px 10px 15px;
          .card-title{
            font-size:18px !important;
          }
          .toggle-btn{
            display:flex;
            align-items:center;
            justify-content:center;
            width:26px;
            height:26px;
            border-radius:5px;
            padding:2px;
            border:none;
            background-color:transparent;
            svg{
              width:100%;
              height:100%;
            }
            &:hover{
              background-color:#f4f4f4;
            }
          }
        }
        .compnay-checklist-folder-wrapper,
        .hide-scrollbar.card-body{
          display:none;
        }
        .compnay-checklist-folder-wrapper{
          .card-body{
            padding:10px 10px;
          }
          .accordion-item.checklist-box{
            border-radius: 7px !important;
            margin-bottom: 10px !important;
            background-color:#ffffff;
            .checklist-header{
              .title{
                font-size:15px;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
              }
              .accordion-button{
                padding:8px 10px 8px 15px !important;
              }
              .action-btn-wrapper{
                .action-btn{
                  width: 26px;
                  height: 26px;
                  padding:5px;
                  margin-right: 2px;
                  display:flex;
                  align-items:center;
                  justify-content:center;
                  &.down-arrow-btn{
                    padding:2px 2px;
                  }
                  svg{
                    width:100%;
                    height:100%;
                  }
                  &:last-child{
                    margin-right:0px;
                  }
                  &:hover{
                    background-color:#f4f4f4;
                  }
                }
              }
            }
          }
          .accordion-margin{
            &:last-child{
              .accordion-item.checklist-box{
                margin-bottom:0px !important;
              }
            }
          }
        }
        .hide-scrollbar.card-body{
          height:auto !important;
          max-height:unset !important;
        }
        &.active{
          .card-header{
            .toggle-btn{
              transform:rotate(-180deg);
            }
          }
          .compnay-checklist-folder-wrapper,
          .hide-scrollbar.card-body{
            display:block;
          }
        }
      }
      .company-form-card{
        .header-wrapper{
          padding:10px 10px 10px 15px;
          .form-card-title{
            width:calc(100% - 116px);
          }
          .action-btn-wrapper{
            .action-btn{
              width:26px;
              height:26px;
              display:flex;
              align-items:center;
              justify-content:center;
              margin-right:3px;
              padding:5px;
              &:last-child{
                margin-right:0px;
              }
              svg{
                width:100%;
                height:100%;
              }
            }
          }
        }
      }
    }
    &.setting-profile-page{
      .loyal-task-manager{
        .content-right{
          .task-manager-body{
            .infinite-scroll-component.todo-task-list{
              height:calc(100dvh - 298px) !important;
              &::before{
                bottom: 78px;
              }
            }
          }
        }
      }
    }
  }
  // company-document-page-end

  // edit-document-modal
  .edit-document-modal{
    .modal-footer{
      display:flex;
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
    .radio-switch-toggle-btn-wrapper{
      .radio-btn-wrapper{
        flex-wrap:wrap;
        .radio-btn-repeater{
          margin-bottom:10px;
          &:last-child{
            margin-bottom:0px;
          }
        }
      }
    }
  }
  // edit-document-modal-end

  // Integration
  .integration-card{
    margin-bottom:0px;
    height:calc(100dvh - 130px);
    overflow-y:auto;
    padding-bottom:30px;
    &:before{
      content: "";
      position: fixed;
      bottom:64px;
      width: 100%;
      height:50px;
      z-index: 4;
      background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      left: 50%;
      transform: translateX(-50%);
    }
    .card-header{
      border-radius:0px;
      background-color:transparent !important;
      border-bottom:none !important;
      padding:5px 15px 0px 15px;
      .card-title{
        font-size:22px !important;
      }
    }
    .card-body{
      padding:14px 15px 0px 15px !important;
    }
    .integration-box{
      background-color:#ffffff;
      border-radius:7px;
      padding:16px 16px;
      .heading{
        font-size:18px;
        font-weight:600;
      }
      .form-label{
        line-height:19px;
        margin-bottom:9px;
        color:#979797;
        font-weight:400;
      }
      &.email-connection{
        .ca-header{
          padding:0px 0px;
          background-color:transparent;
          border-bottom:none;
          margin-bottom:12px;
          .right-text{
            padding-left:10px;
            .title{
              width:100%;
              display:block;
              font-weight:500;
              font-size:16px;
            }
            .text{
              width:100%;
              display:block;
              font-weight:400;
              font-size:14px;
              margin-left:0px;
            }
          }
        }
        .connected-account-wrapper{
          .connected-item{
            padding:12px 12px;
            flex-wrap:wrap;
            position: relative;
            background-color:#f7f7f7;
            margin-bottom:10px;
            border-bottom:none;
            border-radius:7px;
            &:last-child{
              margin-bottom:0px;
            }
            .imap-wrapper{
              margin-bottom:5px;
              width:100%;
              padding-right: 68px;
              .icon-wrapper{
                width:24px;
                height:24px;
              }
              .title{
                font-size:16px;
                width:calc(100% - 25px);
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
              }
            }
            .user-wrapper{
              width:100%;
              padding-left:0px;
              padding-right:0px;
              .avatar{
                .avatar-content{
                  width:26px;
                  height:26px;
                  font-size:11px;
                }
              }
              .email{
                font-size:14px;
                padding-left:6px;
                width:calc(100% - 27px);
              }
            }
            .action-btn-wrapper{
              position:absolute;
              top:11px;
              right:10px;
              .action-btn{
                width:27px;
                height:27px;
                margin-right:3px;
                padding:6px;
                &.close-btn{
                  padding:4px;
                }
                &:last-child{
                  margin-right:0px;
                }
                &:hover{
                  background-color:#ffffff;
                }
              }
            }
          }
        }
        .connect-wrapper{
          padding:15px 0px 0px 0px;
          .details{
            width:100%;
            padding-right:0px;
          }
          .connect-IMAP-btn{
            margin-top:20px;
            margin-bottom:10px;
          }
        }
      }
    }
  }
  // Integration-end

  // notifications-card
  .notifications-card,
  .faq-card{
    .card-header{
      display:none;
    }
    .card-body{
      padding:0px 15px;
    }
  }
  // notifications-card-end

  // feature-requests-page
  .feature-requests-page{
    padding-top:5px;
    .mobile-page-title{
      display:block;
      font-size:22px;
      font-weight:600;
      color:#000000;
      padding:0px 15px 7px 15px;
    }
    .feature-requests-header{
      padding:0px 15px;
    }
    .feature-requests-card{
      .card-header{
        border-radius:0px;
        padding:0px 15px 13px 15px;
        background-color:transparent !important;
        box-shadow: 0px 4px 6px #dbdbdb;
        .card-title{
          display:none;
        }
        .search-box-wrapper{
          width:100%;
        }
      }
      .report-problem-row{
        .report-problem-col{
          width:100% !important;
        }
      }
    }
    .table-view{
      .card-header{
        display:none;
      }
      .card-body{
        padding:0px 15px;
        .search-box-wrapper{
          margin-top:0px;
          width:100%;
        }
        .rdt_Table_wrapper{
          .ui-loader{
            & > .react-dataTable{
              max-height:calc(100dvh - 290px);
              overflow-y:auto;
              position: relative;
              padding-bottom:30px;
              &::before{
                content: "";
                position: fixed;
                bottom: 64px;
                width: 100%;
                height: 50px;
                z-index: 4;
                background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
          .react-paginate .page-link{
            background-color:#ffffff;
          }
        }
      }
    }
  }
  .support__tickets__card{
    &.feature-requests-card{
      & > .card-body{
        padding:0px 15px 25px 15px !important ;
        height:calc(100dvh - 290px);
        &:before{
          content: "";
          position: fixed;
          bottom: 64px;
          width: 100%;
          height: 50px;
          z-index: 4;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0 80%);
          left: 50%;
          transform: translateX(-50%);
        }
        &.load-more-active{
          height:calc(100dvh - 308px);
          padding-bottom:40px !important;
          &:before{
            bottom:86px;
          }
        }
      }
      .loadMore-btn-wrapper{
        position:fixed;
        display:inline-block;
        border-top:none;
        left: 50%;
        transform: translateX(-50%);
        bottom: 88px;
        z-index: 4;
        padding:0px 0px !important;
        &::before{
          display:none;
        }
        .btn{
          background-color:var(--primaryColor);
          color:#000000 !important;
          border:1px solid var(--primaryColor);
          border-radius:100px;
        }
      }
    }
  }

  .modal .modal-dialog.modal-dialog-mobile.support-ticket-comment-modal .modal-body{
    max-height:calc(100dvh - 220px);
  }
  // feature-requests-page-end

  // add-feature-request-modal
  .add-feature-request-modal{
    .modal-body{
      padding:0px 0px;
      max-height:unset !important;
      height:auto;
    }
    .mobile-scroll{
      padding:20px 15px;
      max-height:calc(100dvh - 150px);
      overflow-y:auto;
    }
    .submit-btn-wrapper{
      padding:12px 15px;
      border-top:1px solid #e8e8e8;
      .btn{
        width:100% !important;
      }
    }
  }
  // add-feature-request-modal-end

  // feature-request-preview-modal, support-ticket-preview-modal
  .feature-request-preview-modal,
  .support-ticket-preview-modal{
    .modal-body{
      max-height:unset !important;
    }
    .modal-header{box-shadow:unset !important;}
    .data-row-new{
      margin-bottom:18px;
      &.attachment{
        margin-bottom:0px;
      }
    }
  }
  // feature-request-preview-modal, support-ticket-preview-modal end

  // change-log-page
  .change-log-page{
    padding:6px 15px 30px 15px;
    &::before{
      content: "";
      position: fixed;
      bottom: 64px;
      width: 100%;
      height: 50px;
      z-index: 4;
      background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      left: 50%;
      transform: translateX(-50%);
    }
    .page-header{
      margin-bottom:15px;
      .title{
        font-size:22px;
        font-weight:600;
      }
    }
    & > .card{
      .card-header{
        .card-title{
          font-size:18px !important;
        }
      }
      & > .card-body{
        padding:13px 13px !important;
      }
    }
    .change-logs-inner-iBox{
      padding-bottom:10px;
      margin-bottom:15px;
      .haader-wrapper{
        .icon-wrapper{
          width:30px;
          height:30px;
          border-radius:5px;
          display:flex;
          flex-wrap:wrap;
          align-items:center;
          justify-content:center;
          &::before{
            border-radius:5px;
          }
        }
        .heading{
          padding-left:10px;
          width:calc(100% - 31px);
          font-size:18px;
        }
      }
      .change-log-list-wrapper{
        padding-left:0px;
      }
    }

    &.superAdmin{
      padding-top: 70px;
      height: calc(100dvh - 142px);
      overflow-y: auto;
      .page-header{
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 2;
        left: 0px;
        padding: 0px 15px 15px 15px;
        box-shadow: 0px 4px 8px #d2d2d2;
        margin-top: 0px;
        background-color: #f7f6f0;
      }
    }
  }

  .log-details-form{
    overflow:hidden;
    .card-header{
      padding:0px 15px 15px 15px;
      background-color:transparent !important;
      box-shadow:0px 4px 8px #d2d2d2;
      position: relative;
      z-index:4;
      .card-title{
        font-size:22px !important;
      }
    }
    & > .card-body{
      padding-left:15px;
      padding-right:15px;
      height: calc(100dvh - 193px);
      overflow-y: auto;
      padding-bottom:50px;
      &::before{
        content: "";
        position: fixed;
        bottom:78px;
        width: 100%;
        height: 50px;
        z-index: 2;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .submit-btn-wrapper{
      display: inline-flex !important;
      position: fixed;
      bottom: 86px;
      z-index: 9;
      left:50%;
      transform:translateX(-50%);
      .submit-btn{
        margin-top:0px !important;
        border-radius:100px !important;
      }
    }
    .e-richtexteditor{
      .e-toolbar-wrapper{
        .e-toolbar{
          background-color:#ffffff !important;
        }
      }
    }
  }
  // change-log-page-end

  // connect-IMAP-modal
  .connect-IMAP-modal{
    .modal-body{
      max-height:unset !important;
    }
    .bs-stepper-content{
      padding:0px 0px !important;
    }
    .bs-stepper{
      .bs-stepper-header{
        .line{
          display:none;
        }
        .step{
          width:100%;
          margin-bottom:10px;
          .step-trigger{
            width: 100%;
            justify-content: flex-start;
          }
          &:last-child{
            margin-bottom:0px;
          }
        }
      }
    }
    .imap__platform__wrapper{
      padding:25px 15px 15px 15px;
      max-height: calc(100dvh - 400px);
      overflow-y: auto;
    }
    .add-connection-form-wrapper{
      padding:25px 0px 15px 0px;
      .inner-wrapper{
        padding:0px 15px;
        max-height: calc(100dvh - 440px);
        overflow-y: auto;
      }
    }
    .map-mailbox-folder-wrapper{
      padding:25px 0px 15px 0px;
      .inner-wrapper{
        padding:0px 15px;
        max-height: calc(100dvh - 440px);
        overflow-y: auto;
      }
    }
    .footer__btns{
      padding:20px 15px;
      margin-top:0px;
      display:flex;
      justify-content:flex-end;
      &:before{
        width:100%;
      }
      button.btn{
        width:calc(50% - 8px) !important;
        margin:0px !important;
        &:first-child{
          margin-right:8px !important;
        }
        &:last-child{
          margin-left:8px !important;
        }
      }
    }
    .update__IMAP__wrapper{
      .add-connection-form-wrapper{
        .inner-wrapper{
          padding-left:0px;
          padding-right:0px;
        }
      }
    }
  }
  .connect-IMAP-modal{
    &.update-IMAP-modal{
      .add-connection-form-wrapper{
        .inner-wrapper{
          max-height:calc(100dvh - 350px);
        }
      }
    }
  }
  // connect-IMAP-modal-end

  // email-setting-page
  .email-setting-page{
    padding:10px 15px 40px 15px;
    height: calc(100dvh - 163px);
    overflow-y: auto;
    position: relative;
    &:before{
      content: "";
      position: fixed;
      bottom: 92px;
      width: 100%;
      height: 50px;
      z-index: 2;
      background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      left: 50%;
      transform: translateX(-50%);
    }
    .contant-wrapper{
      .sec-heading{
        margin-bottom:15px;
      }
    }
    .radio-btn-wrapper{
      flex-wrap:wrap;
      .radio-btn-repeater{
        width:255px;
        margin-bottom:12px !important;
        &:last-child{
          margin-bottom:0px !important;
        }
      }
    }
    .save-btn-wrapper{
      margin-top:0px;
      display:inline-block;
      position: fixed;
      bottom: 92px;
      z-index:4;
      left: 50%;
      transform: translateX(-50%);
      .btn{
        border-radius:100px;
      }
    }
  }
  // email-setting-page-end

  // email-modual
  .email-main-round-loader-wrapper{
    height:calc(100dvh - 170px);
    background-color:transparent;
    border:none;
  }
  .email-application{
    .content-area-wrapper{
      .email-modual{
        border-radius:0px;
        border:none;
        background-color:transparent;
        .sidebar-left{
          width:100%;
          transform:translateX(0px);
          border-top-left-radius:14px;
          border-bottom-right-radius:0px;
          height:100dvh;
          position:fixed !important;
          bottom:0px;
          top:unset;
          left:0px;
          right:0px;
          z-index:120;
          background-color:transparent !important;
          display:none;
          transition:0s;
          &:before{
            content: "";
            position: fixed;
            top: 0px;
            width: 100vw;
            height: 100dvh;
            -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
            background-color: rgba(0, 0, 0, 0.2);
            left: 0px;
            z-index:2;
          }
          & > .inner-wrapper{
            background-color: #ffffff;
            box-shadow: 0px 0px 15px #cecece;
            position:fixed !important;
            bottom:0px;
            left:0px;
            right:0px;
            padding-top:40px;
            border-top-left-radius:14px;
            border-top-right-radius:14px;
            z-index:3;
            transform:translateY(110%);
            transition:0.5s;
            &:before{
              content: "";
              position: absolute;
              top: 14px;
              height: 4px;
              width: 50px;
              background-color: #a8a8a8;
              border-radius: 20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .collapse__btn{
            display:none;
          }
          .close-btn{
            display:none;
          }
          .compose-btn-wrapper{
            padding:15px 15px 15px 15px;
          }
          .sidebar-menu-list{
            padding-bottom:10px;
          }
          .compose-btn-wrapper{
            display:none;
          }
        }
        .content-right{
          .top-header-wrapper{
            padding-top:2px;
            padding-bottom:8px;
          }
          .top-search-header{
            .task-manager-sidebar-toggle-btn{
              margin-right:0px;
              width:26px;
              .line{
                background-color:#000000;
                height:2px;
                margin-bottom: 5px;
              }
              .line:nth-child(2){
                width:80%;
              }
              .line:nth-child(3){
                width:60%;
              }
            }
            .email-top-search-box{
              display:none;
            }
          }
          .next-prev-btn-wrapper .prev,
          .next-prev-btn-wrapper .next{
            width:32px;
            height:32px;
            padding:5px;
            &:hover{
              background-color:#ffffff;
            }
          }
          .reset-btn-wrapper{
            .refresh-btn{
              font-size:0px;
              padding:10px 11px;
              svg{
                margin-right:0px;
              }
              .dot-flashing{
                margin-right:8px;
              }
            }
          }
          .compose-btn{
            position: fixed;
            bottom: 92px;
            z-index:5;
            left: 50%;
            transform: translateX(-50%);
            margin:0px;
            border-radius:100px;
            svg{
              margin-right:5px;
            }
          }
          .email-items{
            .mail-item{
              border-bottom:1px solid #dfdfdf;
              .inner-wrapper{
                padding:8px 10px 10px 10px;
                align-items: flex-start;
                .action-wrapper{
                  width: 16px;
                  position: static;
                  .checkbox-wrapper{
                    padding: 0px 0px;
                    width: 16px;
                    height: 16px;
                    position: relative;
                    top: 2px;
                  }
                  .email-favorite-wrapper{
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    .email-favorite{
                      width: 16px;
                      height: 16px;
                      padding: 0px;
                      svg{
                        color:#919191;
                      }
                      &:hover{
                        svg{
                          color:#000000;
                        }
                      }
                    }
                  }
                }
                .details-wrapper{
                  width: calc(100% - 16px);
                  flex-wrap: wrap;
                  padding-left: 10px;
                  .title-wrapper{
                    padding-right: 5px;
                    order: 1;
                    width: calc(100% - 55px);
                    .name{
                      margin-right: 0px;
                      white-space: pre;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                  .description-wrapper{
                    padding-left: 0px;
                    margin-top: 4px;
                    order: 3;
                    width: 100%;
                    .subject{
                      margin-right: 0px !important;
                      width: calc(100% - 24px);
                      font-size: 13px;
                      font-weight: normal;
                      color: #595959;
                    }
                  }
                  .date-wrapper{
                    text-align: right;
                    padding-left: 0px;
                    width: 55px;
                    order: 2;
                  }
                }
              }
            }
          }
          .email-list-wrapper{
            height:calc(100dvh - 257px);
            padding-bottom:60px;
            &:before{
              content: "";
              position: fixed;
              bottom: 86px;
              width: 100%;
              height: 50px;
              z-index: 4;
              background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        &.right-of-index{
          .content-body{
            .email-list-wrapper{
              width:100%;
            }
            .email-details-page{
              display:none;
            }
          }
        }
      }
    }
  }

  .email-menu-open{
    .header-navbar{
      z-index: -1;
    }
    .app-content{
      z-index:10;
    }
    .email-application{
      .content-area-wrapper{
        .email-modual{
          .sidebar-left{
            display:block;
            & > .inner-wrapper{
              transform:translateY(0px);
            }
          }
        }
      }
    }
  }
  // email-modual-end

  // email-details-page
  .email-details-page{
    background-color:transparent;
    overflow:hidden;
    .email-detail-header{
      padding:12px 10px 12px 8px;
      box-shadow: 0px 4px 8px #d2d2d2;
      .email-header-left{
        .back-arrow{
          &:hover{
            background-color:#ffffff;
          }
        }
        .social-icons{
          .social-icons-list{
            .action-icon{
              &:hover{
                background-color:#ffffff;
              }
            }
          }
          &:before{
            background-color:#d9d9d9;
          }
        }
      }
      .email-header-right{
        .prev,
        .next{
          svg{
            color:#000000 !important;
          }
          &:hover{
            background-color:#ffffff;
          }
        }
      }
    }
    .email-subject{
      padding:0px 14px;
      margin-top: 0px;
      margin-bottom:16px;
    }
    .email-detail-box{
      padding:15px 14px 20px 14px;
      .email-detail-head{
        .left{
          padding-right:10px;
          align-items:flex-start;
          width: calc(100% - 67px);
          .avatar{
            width:34px;
            height:34px;
            margin-top: 5px;
            .avatar-content{
              font-size:13px;
            }
          }
          .mail-items{
            width:calc(100% - 35px);
            padding-left:10px;
            .name-mail{
              margin-bottom:0px;
              .name{
                margin-bottom:3px;
                font-weight:600;
              }
              .mail{
                max-width:100%;
                display:inline-flex;
                align-items:center;
                margin-bottom:3px;
                .innerCN{
                  display:inline-block;
                  width:calc(100% - 30px);
                  white-space:pre;
                  overflow:hidden;
                  text-overflow:ellipsis;
                }
              }
            }
            .to-me-wrapper{
              max-width:100%;
              .text{
                max-width: calc(100% - 20px);
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .email-info-dropup{
                .dropdown-toggle{
                  &:hover{
                    background-color:#ffffff;
                  }
                }
              }
            }
          }
        }
        .right{
          .favorite{
            &:hover{
              background-color:#ffffff;
            }
          }
          .action-toggle-btn{
            width:26px;
            padding-left:3px;
            padding-right:3px;
            &:hover{
              background-color:#ffffff;
            }
          }
        }
      }
    }
    .mail-message-wrapper{
      padding:15px 0px 0px 0px;
    }
    .reply-forward-btn-wrapper{
      padding:20px 20px 20px 20px;
      margin-bottom:0px;
      .reply-btn,
      .forward-btn{
        font-size:14px;
        padding:8px 14px;
        svg{
          width:14px;
          height:14px;
        }
      }
      .reply-btn{
        margin-right:8px;
      }
    }
    .mobile-scroll-wrapper{
      overflow-x:hidden;
      height: calc(100dvh - 208px);
      margin-bottom: 0px !important;
      position: relative;
      padding-top:15px;
      padding-bottom:20px;
      &::before{
        content: "";
        position: fixed;
        bottom: 82px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
    .email-details-page-scroll-area{
      height:auto;
      overflow:hidden;
    }
  }
  // email-details-page-end

  // compose-mail-modal
  .compose-mail-modal-overlay{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100dvh;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
    z-index:9;
    display:none;
  }
  .compose-mail-modal{
    right:0px !important;
    width:100%;
    transform:translateY(110%);
    transition:0.5s !important;
    .modal-dialog{
      padding-right:0px !important;
    }
    .modal-header{
      background-color:#f8f8f8;
      box-shadow: 0px 1px 9px #bbb;
      background-color: #f8f8f8;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      z-index:9;
      &:before{
        display:none;
      }
    }
    .action-btn-wrapper{
      .action-btn{
        &:hover{
          background-color:#e3e3e3;
        }
      }
    }
    .compose-form{
      .message-body-wrapper{
        max-height:calc(100dvh - 270px);
        overflow-y:auto;
        .rdw-editor-wrapper{
          .rdw-editor-toolbar{
            position: fixed;
            bottom: 66px;
            left: 0px;
            right: 0px;
            width: 100%;
            z-index: 9;
          }
          .rdw-editor-main{
            min-height:unset !important;
            height:auto !important;
            max-height:unset !important;
          }
        }
      }
    }
  }
  body.compose-mail-modal-open{
    .app-content{
      z-index:11;
    }
    .header-navbar{
      z-index:-1;
    }
    .compose-mail-modal-overlay{
      display:block;
    }
    .compose-mail-modal{
      transform:translateY(0px);
    }
  }
  // compose-mail-modal-end
  .email-details-page{
    border-radius:0px;
  }

  // reply-mail-box
  body.open-replyBox{
    overflow:hidden !important;
  }
  .open-replyBox{
    z-index:99;
  }
  .open-replyBox{
    .email-details-page-scroll-area{
      overflow-y:hidden;
    }
    .reply-mail-form{
      position:fixed;
      top:0px;
      left:0px;
      background-color:#ffffff;
      z-index:9;
      width:100%;
      margin-top:0px;
      border:none;
      border-radius:0px;
      padding:0px 0px;
      min-height:100dvh;
      .mobile-header{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:10px 15px 10px 10px;
        background-color: white;
        box-shadow: 0px 2px 12px #bdbdbd;
        z-index: 10;
        position: relative;
        .mobile-header-left,
        .mobile-header-right{
          display:flex;
          align-items:center;
        }
        .mobile-header-left{
          .action-btn{
            width:36px;
            height:36px;
            display:flex;
            align-items:center;
            justify-content:center;
            border-radius:50%;
            padding:4px;
            transition:0.5s;
            svg{
              color:#000000 !important;
              width:100%;
              height:100%;
            }
            &:hover{
              background-color:#eeeeee;
            }
          }
        }
        .create-task{
          display:inline-flex;
          align-items:center;
          margin-right:10px;
          .label{
            font-size:14px;
            font-weight:500;
            margin-right:7px;
          }
          .form-check-input{
            margin-top:0px !important;
          }
        }
        .upload-btn{
          width:36px;
          height:36px;
          border-radius:8px;
          padding:7px 7px !important;
          display:flex;
          align-items:center;
          justify-content:center;
          position: relative;
          transition:0.5s;
          margin-right:6px;
          input{
            position: absolute;
            top: 0px;
            left:0px;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
          }
          svg{
            width:100%;
            height:100%;
            color:#000000;
          }
          &:hover{
            background-color:#eeeeee;
          }
        }
        .save-as-btn{
          width:100px !important;
          height:40px;
          border-radius:8px;
          padding:0px 0px !important;
          display:flex;
          align-items:center;
          justify-content:center;
          padding:7px !important;
          font-size:15px;
          color:#000000;
          & > span{
            display:flex;
            align-items:center;
            justify-content:center;
            width:100%;
            height:100%;
            svg{
              width:20px;
              height:20px;
              margin-right:8px;
            }
          }
        }
        .custom_select_wrapper{
          width: 110px;
          margin-left:12px;
          .custom-select__control{
            width: 106px;
            padding-left:9px;
            min-height:40px !important;
            padding-bottom:2px !important;
          }
        }
      }
      .mobile-footer{
        display:flex;
        padding:10px 15px;
        border-top:1px solid #ebe9f1;
        position:fixed;
        bottom:0px;
        left:0px;
        right:0px;
        z-index:10;
        background-color:#ffffff;
        justify-content:space-between;
        align-items:center;
        .upload-btn{
          padding: 6px 14px;
          background-color: #e8e8e8;
          border-radius: 5px;
          font-size:16px;
          color:#000000;
          transition:0.5s;
          position: relative;
          svg{
            color:#000000;
            width:20px;
            height:20px;
            margin-right:5px;
            transition:0.5s;
          }
          input{
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            z-index:2;
            opacity:0;
            cursor: pointer;
          }
          &:hover{
            background-color:#000000;
            color:#ffffff;
            svg{
              color:#ffffff;
            }
          }
        }
        .create-task{
          display:flex;
          align-items:center;
          .label{
            font-size:15px;
            color:#000000;
            margin-right:7px;
          }
          .view-task-btn{
            width:35px;
            height:35px;
            background-color:#e8e8e8;
            border-radius:5px;
            transition:0.5s;
            margin-left:10px;
            padding:8px;
            border-radius:50%;
            display:flex;
            align-items:center;
            justify-content:center;
            cursor: pointer;
            svg{
              width:100%;
              height:100%;
              color:#000000;
              transition:0.5s;
            }
            &:hover{
              background-color:#000000;
              svg{
                color:#ffffff;
              }
            }
          }
        }
      }
      .mobile-body-wrapper{
        height:calc(100dvh - 58px);
        overflow-y:auto;
        padding-top:9px;
        padding-bottom:57px;
      }
      .compose-footer-wrapper{
        display:none !important;
      }
      .compose-mail-fields{
        .form-label{
          left:10px;
        }
        .cc-bcc-btns-wrapper{
          right:10px;
          transform:translateY(0px);
          top:10px;
        }
        .cc-bcc-close-btn-wrapper{
          right:8px;
          transform:translateY(0px);
          top:5px;
        }
        &.compose-mail-fields-to{
          padding:0px 75px 0px 29px;
          .form-label{
            transform:translateY(0px);
            top:7px;
          }
          .custom-select__multi-value{
            max-width:calc(100% - 10px);
            .custom-select__multi-value__label{
              max-width:calc(100% - 20px);
              padding-right:4px !important;
            }
          }
        }
        &.compose-mail-fields-cc{
          padding:0px 42px 0px 30px;
          .form-label{
            transform:translateY(0px);
            top:8px;
          }
          .custom-select__multi-value{
            max-width:calc(100% - 10px);
            .custom-select__multi-value__label{
              max-width:calc(100% - 20px);
              padding-right:4px !important;
            }
          }
        }
        &.compose-mail-fields-bcc{
          padding:0px 42px 0px 39px;
          border-bottom:none;
          .form-label{
            transform:translateY(0px);
            top:8px;
          }
          .custom-select__multi-value{
            max-width:calc(100% - 10px);
            .custom-select__multi-value__label{
              max-width:calc(100% - 20px);
              padding-right:4px !important;
            }
          }
        }
      }
      .message-body-wrapper{
        margin-left:0px;
        margin-right:0px;
        margin-top:0px;
        .rdw-editor-wrapper{
          .rdw-editor-main{
            min-height:250px !important;
            max-height:unset !important;
          }
        }
      }
      .attachment-wrapper{
        .attachment-row{
          .attachment-name{
            white-space:pre;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          .attachment-size{
            display:none;
          }
        }
      }
    }
  }
  // reply-mail-box-end

  // mail-task-preview
  .open-taskPreview{
    z-index:99;
    .mail-task-preview{
      position:fixed;
      top:0px;
      left:0px;
      height:100dvh;
      width:100%;
      background-color:#ffffff;
      padding:0px 0px;
      margin-top:0px;
      border-radius:0px;
      z-index:10;
      .mobile-top-header{
        display:flex;
      }
      & > .inner-wrapper{
        height:calc(100dvh - 67px);
        overflow-y:auto;
        overflow-x: hidden;
        padding:10px 15px 25px 15px;
      }
      .custom-select__menu{
        left:-114px;
      }
      .taskName-date-wrapper{
        padding-bottom:12px;
        .taskName-field{
          width:100%;
          padding-right:0px;
          margin-bottom:15px;
        }
        .datepickerRange-field{
          width:100%;
        }
      }
      .task__top__details__col{
        .inner-wrapper{
          & > .label{
            font-size:14px;
            width:105px;
            .icon-wrapper{
              width: 18px;
              height: 18px;
              top:-4px;
              margin-right:6px;
            }
          }
          .value{
            width:calc(100% - 106px);
            padding-left:12px;
          }
        }
        &.after-task{
          .inner-wrapper{
            flex-wrap:wrap;
            & > .label{
              width:100%;
              margin-bottom:8px;
              &:before{
                right:unset;
                left:104px;
              }
            }
            .value{
              width:100%;
              padding-left:0px;
            }
          }
        }
      }
      .attachment-wrapper{
        margin-top:0px !important;
        padding-bottom:10px !important;
        .file__card{
          .inner-border-wrapper{
            .image__edit__wp{
              width:calc(100% - 153px);
            }
            .action-btn-wrapper{
              display:flex;
            }
          }
        }
      }
      .attachment-save-btn-wrapper{
        margin-bottom:-12px;
        .custom-fileupload{
          .ln-btn{
            position: fixed;
            bottom: unset;
            top: 8px;
            right: 10px;
            left: unset;
            font-size: 0px !important;
            padding:7px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            background-color: transparent;
            svg{
              top:0px;
              margin-right:0px;
              width:100%;
              height:100%;
            }
            .text{
              display:none;
            }
            &:hover{
              background-color:#f4f4f4;
              svg{
                color:#000000;
              }
            }
          }
          .remove-all-btn{
            position:static;
          }
        }
      }
    }
  }
  // mail-task-preview-end

  // task-manager
  .loyal-task-manager{
    border-radius:0px;
    border:none;
    background-color:transparent;
    .sidebar-left{      
      position: fixed;
      bottom: 0px;
      height: 100dvh;
      top:unset;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      transition:0.1s;
      .collapse__menu__icon__wp{
        &.responsive__btn{
          background-color: #000;
          &:before{
            border-left: 2px solid var(--primaryColor);
            border-bottom: 2px solid var(--primaryColor);
          }
        }
      }
    }
    .sidebar-left.show + .content-right{
      padding-left:0px;
    }
    .content-right{
      &:before{
        content:"";
        position:fixed;
        top:0px;
        left:0px;
        right:0px;
        width:100vw;
        height:100dvh;
        backdrop-filter: blur(7px);
        background-color: rgba(0, 0, 0, 0.2);
        z-index:10;
        display:none;
      }
      .task-manager-category-scrollbar{
        height:calc(100dvh - 252px) !important;
        padding-bottom:40px;
        .task-manager-body{
          .infinite-scroll-component.todo-task-list{
            padding-bottom:18px;
          }
        }
      }
      .new-tab-details-design{
        .right__wrapper__OP{
          display:none;
        }
      }
      .new-tab-details-design{
        display:none;
      }
      .task-manager-top-header{
        flex-wrap:wrap;
        padding-right:0px;
        position: relative;
        border-bottom:none;
        box-shadow: 0px 4px 8px #d2d2d2;
        z-index:9;
        .task-manager-sidebar-toggle-btn{
          margin-left:0px;
          transition:0.5s;
          width:41.2px;
          height:41.2px;
          padding:8px 7px;
          border-radius:6px;
          position:absolute;
          top:5px;
          right:14px;
          background-color:#ffffff;
          .line{
            background-color:transparent;
            height:9px;
            width:9px;
            border-radius:50%;
            border:1px solid #000000;
            position:absolute;
            &:first-child{
              top:8px;
              left:8px;
            }
            &:nth-child(2){
              top:8px;
              right:8px;
              width:9px;
            }
            &:nth-child(3){
              bottom:8px;
              left:8px;
            }
          }
          &::before{
            content:"";
            position:absolute;
            bottom:8px;
            right:8px;
            height:9px;
            width:9px;
            border-radius:50%;
            border:1px solid #000000;
          }
          &:hover{
            background-color:#000000;
            .line{
              border:1px solid #ffffff;
            }
            &::before{
              border:1px solid #ffffff;
            }
          }
        }
        .left{
          padding-right:0px;
          padding-left:14px;
          width:calc(100% - 66px);
          // .task-manager-serach-box{
          //   .search__task__input{
          //     background-color:transparent;
          //   }
          // }
        }
        .right{
          width:100%;
          position: relative;
          padding-left:10px;
          margin-top:6px;
          .new-task-btn{
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            right:14px;
            margin-left:0px;
            &:hover{
              .text{
                text-decoration:underline;
              }
            }
          }
          .reset-filters-btn,
          .expand-collapse-task-btn,
          .selects-custom-dropdown-wrapper .icon_wrapper,
          .task-export-btn,
          .task-print-btn{
            background-color:transparent;
            padding:9px 9px;
            border-radius:50%;
            &:hover{
              background-color:#ffffff;
            }
          }
          .expand-collapse-task-btn{
            display:none;
          }
          .selects-custom-dropdown-wrapper{
            .selects-custom-dropdown{
              left:0px;
              right:unset;
              .inner-wrapper{
                border-radius: 10px;
              }
            }
          }
        }
        .reset-filters-btn{
          margin-left:0px;
        }
      }
      .task-manager-body{
        .task-manager-main-accordion{
          .task-manager-table-header{
            display:none;
          }
        }
        .task-manager-row-wrapper{
          padding:0px 15px;
          & > .task-manager-row{
            display:none;
          }
        }
        .infinite-scroll-component.todo-task-list{
          height:calc(100dvh - 252px) !important;
          padding-bottom: 40px;
          &:before{
            content: "";
            position: fixed;
            bottom: 88px;
            width: 100%;
            height: 40px;
            z-index: 9;
            background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .task-manager-main-accordion{
          .infinite-scroll-component__outerdiv{
            min-width:unset;
            width:100%;
          }
        }
        .add-task-form-row{
          padding:17px 15px;
          .action-btn-wrapper{
            display:none;
          }
          .form-field-row{
            width:100%;
            padding-right:0px;
            margin:0px 0px;
            padding-bottom:10px;
            .form-field-col{
              width:100%;
              padding:0px 0px;
              margin-bottom:12px;
            }
          }
          .action-btn-mobile-wrapper{
            display:flex;
            align-items:center;
            .cancel-btn{
              margin-left:12px;
            }
          }
        }
      }
      .showing-task-manager-footer{
        display:none;
      }
      .task-manager-category-wrapper{
        background-color:#ffffff;
        padding-top:13px;
        .task-manager-category-item{
          .task-manager-cat-headerWrapper{
            z-index:9 !important;
          }
          .accordion-body{
            background-color:#f7f6f0;
          }
        }
      }
    }
  }
  .taskManager-menu-open{
    height:100dvh;
    overflow:hidden !important;
    // .wrapper{
    //   width: calc(100vw + 260px);
    //   padding-left: 260px;
    // }
    .header-navbar{
      z-index:0 !important;
      // transform: translateX(260px);
    }
    .app-content{
      z-index:10 !important;
    }
    // .mobile-bottom-menu-wrapper{
    //   transform: translateX(265px);
    //   left: 0px;
    // }
    .sidebar-left{
      z-index:12 !important;
      transform:translateX(0px);
    }
    .content-right{
      pointer-events:none;
      padding-left:0px;
      &::before{
        display:block;
      }
    }
  }

  // snooze-task-modal
  .snooze-task-modal{
    .modal-body{
      min-height:340px;
    }
    .modal-footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-danger,
      .auth-login-form{
        width:calc(50% - 6px);
        margin-left:0px;
      }
      .auth-login-form{
        .btn-primary{
          width:100% !important;
        }
      }
    }
  }
  // snooze-task-modal-end

  // task-add-update-modal
  .task-add-update-modal{
    &.modal-dialog{
      width:100% !important;
      max-width:100% !important;
    }
    .modal-body{
      max-height:unset !important;
      padding-bottom:80px;
      padding-left:0px;
      padding-right:0px;
      padding-top:0px !important;
      .mobile-scroll-wrapper{
        padding:20px 20px 0px 20px;
        max-height:calc(100dvh - 200px);
        overflow-y:auto;
      }
      &::before{
        content:"";
        position:fixed;
        bottom:68px;
        left:0px;
        width:100%;
        border-top:1px solid rgba(34, 41, 47, 0.05);
      }
    }
    .attachment-save-btn-wrapper{
      .custom-fileupload{
        .ln-btn{
          position:fixed;
          bottom:15px;
          left:15px;
          right:unset;
        }
        .remove-all-btn{
          position:static;
          justify-content:flex-start !important;
          margin-top: 8px !important;
          margin-bottom: 10px;
        }
      }
      & > .btn-primary{
        position:fixed;
        bottom:15px;
        right:15px;
        width:140px !important;
        margin-left:0px;
      }
    }
    .attachment-wrapper{
      .file__card{
        .inner-border-wrapper{
          .image__edit__wp{
            width:calc(100% - 152px);
          }
          .action-btn-wrapper{
            display:flex;
          }
        }
      }
    }
    .note-listing-col{
      width:100%;
    }
    .note-box{
      .profile-img{
        width: 38px;
        height: 38px;
      }
      .right-contant{
        width:100%;
        padding-left:0px;
        margin-top:10px;
        .note-header{
          margin-top:-50px;
          padding-left: 48px;
          .name-time{
            width: calc(100% - 70px);
            .name{
              margin-right:0px;
              white-space:pre;
              overflow:hidden;
              text-overflow:ellipsis;
              width:100%;
            }
            .time{
              white-space:pre;
              overflow:hidden;
              text-overflow:ellipsis;
              width:100%;
              padding-left:0px;
              font-size:14px;
              &::before{
                display:none;
              }
            }
          }
        }
        .contant-area{
          margin-top:6px;
        }
        .attachment-wrapper{
          .file__card{
            .inner-border-wrapper{
              background-color:#ffffff !important;
              width:100%;
              .inner-wrapper{
                width:100%;
              }
            }
            .file__preview__details{
              width:calc(100% - 60px);
            }
            .overlay{
              .action-btn-wrapper{
                .action-btn{
                  width:28px;
                  height:28px;
                  padding:6px;
                }
              }
            }
          }
        }
      }
    }
  }
  // task-add-update-modal-end

  // change-parent-task-modal
.change-parent-task-modal{
  .modal-body{
    min-height:200px;
  }
  .modal-footer{
    justify-content:space-between;
    .btn-primary,
    .btn-danger{
      width:calc(50% - 6px) !important;
    }
    .btn-primary{
      margin-left:0px !important;
    }
  }
}
// change-parent-task-modal-end

  // setStatus-modal
  .setStatus-modal{
    .modal-body{
      min-height:200px;
    }
    .modal-footer{
      justify-content:space-between;
      .btn-danger,
      form{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
      .btn-primary{
        width:100% !important;
        margin-left:0px !important;
      }
    }
  }
  // setStatus-modal-end

  // .add-task-modal-new.only-add-task-modal
  .add-task-modal-new{
    &.only-add-task-modal{
      margin:0px !important;
      padding-right:0px !important;
      width: 100%;
      max-width: 100%;
      height: 100dvh;
      align-items: flex-end !important;
      transition: unset !important;
      .modal-content{
        border-bottom-left-radius:0px;
        border-bottom-right-radius:0px;
      }
      .modal-body{
        max-height: calc(100dvh - 175px);
        overflow-y: auto;
      }
      .modal-header-wrapper{
        z-index:6;
        .modal-header{
          padding:15px 12px 15px 12px;
          box-shadow: 0px 1px 9px #bbb;
          background-color:#f8f8f8;
          .subtask-header{
            display:none !important;
          }
          .modal-title{
            .left{
              padding-right: 120px;
            }
          }
        }
        .btn-close{
          background-color:#ffffff;
        }
      }
      .horizontal-tab-parentTask-select-wrapper{
        top:7px;
        .parentTask-selectBox-wrapper{
          .custom-select__control{
            background-color:transparent;
            .custom-select__placeholder{
              color:#737373;
            }
            .custom-select__single-value{
              max-width: 145px !important;
            }
          }
        }
      }
      .tasks-tab{
        padding-top:20px;
        padding-left:12px;
        padding-right:12px;
        padding-bottom:0px;
      }
      .taskName-date-wrapper{
        .taskName-field{
          width:100%;
          padding-right:0px;
          margin-bottom:15px;
        }
        .datepickerRange-field{
          width:100%;
        }
      }
      .task__top__details__row{
        padding-bottom:11px;
      }
      .task__top__details__col{
        width:100%;
        .custom-select__menu{
          left:-114px;
        }
        .inner-wrapper{
          & > .label{
            font-size:14px;
            width:105px;
            .icon-wrapper{
              width: 18px;
              height: 18px;
              top:-4px;
              margin-right:6px;
            }
          }
          .value{
            width:calc(100% - 106px);
            padding-left:12px;
          }
        }
        &.after-task{
          .inner-wrapper{
            flex-wrap:wrap;
            & > .label{
              width:100%;
              margin-bottom:8px;
              &:before{
                right:unset;
                left:104px;
              }
            }
            .value{
              width:100%;
              padding-left:0px;
            }
          }
        }
      }
      .checklist-scheduleTasks-wrapper{
        .checklist-wrapper,
        .schedule-tasks-wrapper{
          width:100%;
        }
        .checklist-wrapper{
          padding-top:22px;
          padding-right:0px;
          .field-row{
            .field-col{
              width:100%;
              &.folder-col{
                margin-bottom:12px;
              }
            }
          }
          .checklist-boxItem-wrapper{
            margin-top:10px;
          }
        }
        .schedule-tasks-wrapper{
          padding-left:0px;
          padding-top:22px;
        }
      }
      .accordion-item{
        &.checklist-box{
          .checklist-header{
            padding:6px 8px 6px 10px;
            .move-icon-wrapper{
              width:28px;
              height:28px;
              border-radius:50%;
              svg{
                &:first-child,
                &:last-child{
                  width:18px;
                }
              }
              &:hover{
                background-color:#ffffff;
              }
            }
            .form-check-input{
              width:18px !important;
              height:18px;
            }
            .title-field-wrapper{
              margin-left: 10px;
              width:calc(100% - 57px) !important;
              .input-field{
                font-size: 14px;
                font-weight: 500;
              }
            }
            .left-wrapper{
              width:calc(100% - 54px);
            }
            .badge-dot-wrapper{
              width:12px;
              height:12px;
              .badge-dot{
                width:8px;
                height:8px;
              }
            }
            .action-btn{
              width:28px;
              height:28px;
              padding:3px;
              &.close-btn{
                width:24px;
                height:24px;
              }
            }
          }
        }
      }
      .task-modal-tabs-contant{
        border-bottom:none;
      }
      .attachment-save-btn-wrapper{
        .custom-fileupload{
          .ln-btn{
            width:38px;
            height:38px;
            padding:6px;
            background-color:#ffffff;
            display:flex;
            align-items:center;
            justify-content:center;
            bottom: 15px;
            left: 14px;
            position:fixed;
            border:1px solid #82868b;
            .spinner-border{
              margin-right:0px;
            }
            svg{
              margin-right:0px;
              width:100%;
              height:100%;
              color:#000000;
              position:static;
            }
            .text{
              font-size:0px;
            }
            &:hover{
              background-color:#000000;
              border:1px solid #000000;
              svg{
                color:#ffffff;
              }
              .spinner-border{
                border:0.25em solid #ffffff;
                border-right-color:transparent;
              }
            }
          }
          .remove-all-btn{
            margin-top:8px !important;
            margin-bottom: 20px;
          }
        }
      }
      .attachment-wrapper{
        margin-top: -6px !important;
        .file__card{
          .inner-border-wrapper{
            .action-btn-wrapper{
              display:flex;
            }
            .image__edit__wp{
              width:calc(100% - 152px);
            }
          }
        }
      }
      .modal-footer{
        border-bottom-left-radius:0px;
        border-bottom-right-radius:0px;
        border-top:1px solid #e1e1e1;
        padding:15px 15px 15px 63px;
        form{
          margin-left:0px !important;
          display:flex;
          align-items:center;
          justify-content:space-between;
          width:100%;
          .cancel-btn,
          .submit-btn{
            width:calc(50% - 6px) !important;
            margin:0px !important;
            min-width:unset !important;
          }
        }
      }
      .note-listing-col{
        width:100%;
      }
      .note-box{
        .profile-img{
          width: 38px;
          height: 38px;
        }
        .right-contant{
          width:100%;
          padding-left:0px;
          margin-top:10px;
          .note-header{
            margin-top:-50px;
            padding-left: 48px;
            .name-time{
              width: calc(100% - 70px);
              .name{
                margin-right:0px;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
                width:100%;
              }
              .time{
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
                width:100%;
                padding-left:0px;
                font-size:14px;
                &::before{
                  display:none;
                }
              }
            }
          }
          .contant-area{
            margin-top:6px;
          }
          .attachment-wrapper{
            .file__card{
              .inner-border-wrapper{
                background-color:#ffffff !important;
                width:100%;
                .inner-wrapper{
                  width:100%;
                }
              }
              .file__preview__details{
                width:calc(100% - 60px);
              }
              .overlay{
                .action-btn-wrapper{
                  .action-btn{
                    width:28px;
                    height:28px;
                    padding:6px;
                  }
                }
              }
            }
          }
        }
      }
      .tab-pane{
        form{
          & > .attachment-save-btn-wrapper{
            .custom-fileupload{
              .file__card{
                .inner-wrapper{
                  padding-top:0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  // .add-task-modal-new.only-add-task-modal-end

  // only-add-task-modal update-task-modal
  .add-task-modal-new.update-task-modal{
    .modal-header-wrapper{
      .modal-header{
        padding-bottom: 15px !important;
        .modal-title{
          .createdBy-text{
            display:none;
          }
          .modal-title-text{
            display:none !important;
          }
          .update-task-wrapper{
            .task-number{
              margin-left:0px;
              background-color:transparent;
              color:#000000;
            }
          }
        }
        .timeCol{
          top:-1px;
          right:40px;
        }
      }
      .btn-close{
        top: 31px;
      }
    }
    .marked-btn-mobile{
      display:block;
      padding-left: 28px;
      padding-right: 15px;
      color: #82868b !important;
      border: none !important;
      background-color: transparent !important;
      position: relative;
      box-shadow:unset !important;
      margin-left: 12px;
      margin-top: 14px;
      font-size: 16px;
      &:before{
        content: "";
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        transition: 0.5s;
        transform: translateY(-50%);
        border-radius: 5px;
        border: 1px solid grey;
        left: 0px;
      }
      &:after{
        content: "";
        position: absolute;
        top: 13px;
        width: 12px;
        height: 7px;
        left: 4px;
        border-left: 2px solid #ffffff;
        transform: rotate(-45deg);
        border-bottom: 2px solid #ffffff;
      }
      &.checked{
        &:before{
          background-color:var(--primaryColor);
        }
        &:after{
          opacity:1;
        }
      }
    }
    .mobile-header-wrapper{
      display:block;
      padding-bottom: 7px !important;
      .update-task-wrapper {
        .task-number {
          padding: 0px 0px;
          font-weight: 600 !important;
        }
      }
      .createdBy-text {
        .title {
          font-size: 17px;
        }
      }
      .createdBy-text .created-by,
      .createdBy-text .time {
        font-size: 15px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
      .createdBy-text .created-by {
        margin-right: 15px;
      }
      .left {
        width: 100%;
      }
      .subtask-header {
        width: 100%;
        .subtask-name {
          font-size: 26px;
          font-weight: 700;
        }
      }
      .createdBy-text {
        .task__top__details__row {
          margin-bottom: 0px;
          padding-bottom: 0px;
          margin:0px 0px;
          padding:0px 12px;
          &::before {
            display: none;
          }
        }
        .task__top__details__col {
          width:100%;
          margin-bottom: 0px;
          padding:0px 0px;
          .inner-wrapper {
            width: 100%;
          }
          .label {
            width: 135px;
          }
          .value {
            width:calc(100% - 135px);
            .created-time,
            .created-user-name {
              color: var(--bs-body-color);
              font-weight: 600;
              font-size: 16px;
            }
            .created-user-wrapper {
              background-color: #f2f2f2;
              border-radius: 100px;
              border: 1px solid grey;
              padding: 4px 15px 4px 4px;
              display: inline-flex;
              align-items: center;
              border: 1px solid #e1e1e1;
              max-width:100%;
              .avatar {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: var(--primaryColor) !important;
                color: #000000 !important;
                flex-shrink:0;
                .avatar-content {
                  width: 100%;
                  height: 100%;
                  line-height: 28px;
                  font-size: 11px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .name {
                color: var(--bs-body-color);
                font-weight: 600;
                font-size: 14px;
                padding-left: 6px;
                width:100%;
                white-space:pre;
                overflow:hidden;
                text-overflow:ellipsis;
              }
            }
          }
          .total-time-spent {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
          }
          &.contact__company__profile{
            order:-1;
            .created-user-wrapper{
              border-radius: 0px;
              padding: 0px;
              background-color: transparent;
              border: none;
              margin-bottom: 12px;
              margin-top: 10px;
            }
          }
        }
      }
      .contact-details {
        display: none;
      }
      .timeCol {
        position: absolute;
        top: -4px;
        right: 34px;
        width: auto !important;
        margin-bottom: 0px !important;
        .start-stop-time-wrapper {
          background-color: black;
          border-radius: 100px;
          padding: 3px 4px 3px 4px;
          display: flex;
          align-items: center;
          .timer-wrapper {
            height: auto !important;
            padding: 0px 10px 0px 12px !important;
            background-color: transparent !important;
            display: flex;
            align-items: center;
            .time {
              color: white !important;
              letter-spacing: 3px;
              font-size: 15px !important;
              font-weight: 600 !important;
              width: 86px;
            }
            .form-label {
              display: none;
            }
          }
          .paush-time-btn,
          .stop-time-btn,
          .start-time-btn,
          .print-btn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            padding: 3px;
            background-color: transparent !important;
            border: 1px solid transparent !important;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              opacity: 0.3;
              border-radius: 50%;
            }
            svg {
              position: relative;
              z-index: 2;
              width: 100%;
              height: 100%;
            }
            &:hover {
              &::before {
                background-color: white;
                opacity: 1;
              }
            }
          }
          .start-time-btn,
          .print-btn {
            svg {
              color: var(--primaryColor);
            }
            &:hover {
              svg {
                color: var(--primaryColorDark);
              }
            }
          }
          .print-btn {
            padding: 4px;
          }
          .paush-time-btn {
            svg {
              color: orange;
            }
            // &::before{
            //   background-color:orange;
            // }
          }
          .stop-time-btn {
            margin-left: 0px;
            svg {
              color: red;
            }
            // &::before{
            //   background-color:red;
            // }
          }
          // &.start-task{
          //   padding:0px 0px;
          //   .start-time-btn{
          //     width:34px;
          //     height:34px;
          //   }
          // }
        }
      }
    }
    .horizontal-tab-parentTask-select-wrapper{
      .horizontal-tab-wrapper{
        padding:0px 13px;
      }
    }
    .modal-footer{
      padding:15px 15px 15px 112px;
      .marked-btn{
        display:none;
      }
      .delete-btn{
        width:38px;
        height:38px;
        min-width:unset !important;
        font-size:0px;
        padding:6px !important;
        margin-right:0px !important;
        position: fixed;
        bottom:15px;
        left:62px;
        svg{
          display:block;
          width:100%;
          height:100%;
        }
        &:hover{
          background-color:#ea5455;
          svg{
            color:#ffffff !important;
          }
        }
      }
    }
    &.update-tab-active{
      .modal-header{
        .modal-title{
          .subtask-header{
            display:none;
          }
        }
      }
      .tab-content{
        .updates-tab{
          padding:10px 14px 14px 14px;
          .task__top__details__row{
            .task__top__details__col,
            .task__top__details__col:nth-child(2){
              .label{
                width:108px;
              }
              .value{
                width:calc(100% - 108px);
              }
            }
          }
        }
      }
      .modal-footer{
        padding:15px 15px 15px 65px;
      }
      .attachment-save-btn-wrapper{
        .custom-fileupload{
          .ln-btn{
            bottom:15px !important;
          }
        }
        .save-btn{
          margin:0px !important;
          width:calc(50vw - 47px) !important;
          position:fixed;
          right:15px;
          bottom:15px;
        }
      }
      .attachment-wrapper{
        margin-top:4px !important;
      }
    }
    &.subtaskTab-active{
      .subTask-tab{
        .task-manager-main-accordion{
          & > .todo-task-list{
            display:none;
          }
        }
        .loyal-task-manager{
          .content-right{
            .task-manager-body{
              .task-manager-row-wrapper{
                padding:0px 13px;
                width:100%;
                & > .task-manager-row{
                  display:block;
                }
              }
              .mobile-row-wrapper{
                padding-bottom:20px;
              }
              .down-up-btn{
                width:34px;
                height:34px;
                border-radius:50%;
                background-color:#f2f2f2;
                svg{
                  color:#000000 !important;
                }
                &:hover{
                  background-color:#000000;
                  svg{
                    color:#ffffff !important;
                  }
                }
              }
            }
          }
          .task-manager-main-accordion{
            min-width:unset;
          }
          .subtask-add-btns-wrapper{
            padding:0px 0px;
            background-color:transparent;
            .new-subtask-btn{
              padding-left:10px;
              padding-right:12px;
              padding-top: 10px;
              padding-bottom: 10px;
              position:fixed;
              bottom:15px;
              left:15px;
              width:calc(50% - 21px) !important;
            }
            .new-subtask-details-btn{
              padding-left:10px;
              padding-right:12px;
              position:fixed;
              bottom:15px;
              right:15px;
              padding-top: 10px;
              padding-bottom: 10px;
              width:calc(50% - 21px) !important;
            }
          }
          .subtask-info-body{
            border-top:1px solid #e8e8e8;
            padding-top:18px;
            margin-top:16px;
            .accordion-body{
              .subtask-info-wrapper{
                .subtask-info{
                  width:100%;
                  background-color:transparent;
                  padding:0px 0px;
                  margin-bottom:12px;
                  .value{
                    color:grey;
                    font-weight:400;
                  }
                  &:last-child{
                    margin-bottom:0px;
                  }
                }
                .edit__btn{
                  width:34px;
                  height:34px;
                  border-radius:50%;
                  background-color:#f2f2f2;
                  padding:8px;
                  right: 41px;
                  top: -73px;
                  svg{
                    color:#000000 !important;
                    width:100%;
                    height:100%;
                  }
                  &:hover{
                    background-color:#000000;
                    svg{
                      color:#ffffff !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal-footer{
        padding:15px 15px 15px 15px;
        height:69px;
        form{
          .cancel-btn{
            width:106px !important;
            display:none;
          }
        }
      }
    }
  }
  // only-add-task-modal update-task-modal end

  // add-status-priority-category-modal
  .add-status-priority-category-modal{
    .modal-footer{
      justify-content:space-between;
      .btn-danger{
        width:calc(50% - 6px) !important;
      }
      form{
        margin:0px;
        width:calc(50% - 6px) !important;
        .btn-primary{
          width:100% !important;
        }
      }
    }
  }
  // add-status-priority-category-modal-end
  // task-manager-end

  // task-report-page
  .task-report-page{
    overflow:hidden;
    margin-top:4px;
    &:before{
      content: "";
      position: fixed;
      bottom: 92px;
      width: 100%;
      height: 50px;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
    }
    & > .card{
      .card-header{
        background-color:transparent !important;
        padding:0px 15px 15px 15px;
        box-shadow:0px 4px 8px #d2d2d2;
        position:relative;
        z-index:3;
        .card-title{
          margin-bottom:0px;
        }
        .right-wrapper{
          display:none;
          flex-wrap:wrap;
          justify-content:space-between;
          margin-top:12px;
          .date-picker-error-wp{
            width:100%;
            margin-bottom:10px;
            input.form-control{
              background-color:#ffffff;
            }
          }
          .contact-modal{
            margin-bottom:10px;
            width:100%;
            margin-left:0px;
          }
          .submit-btn,
          .export-btn{
            width:calc(50% - 6px) !important;
            margin-left:0px;
          }
        }
        .mobile-toggle-header-btn{
          display:flex;
        }
        &.filter-active{
          .right-wrapper{
            display:flex;
          }
        }
      }
      & > .card-body{
        padding:20px 16px 35px 16px !important;
        height:calc(100dvh - 210px);
        overflow-y: auto;
      }
      .card-header.filter-active + .card-body{
        height: calc(100dvh - 362px) !important;
      } 
    }
    .task-report-chart-wrapper,
    .task-report-table-wrapper{
      .heading{
        .toggle-btn{
          background-color:#ffffff !important;
          &:hover{
            background-color:#000000 !important;
          }
        }
      }
    }
  }
  // task-report-page-end

  // company-profile-page
  .company-detail-card{
    // height:calc(100dvh - 154px);
    overflow-y:auto;
    padding:0px 0px 52px 0px;
    & > .card-body{
      padding:0px 15px !important;
    }
    &::before{
      content: "";
      position: fixed;
      bottom:88px;
      width: 100%;
      height: 50px;
      z-index: 2;
      background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      left: 50%;
      transform: translateX(-50%);
    }
    .card-header{
      padding: 0px 0px;
      background-color: transparent !important;
      border-bottom: none;
      margin-bottom:15px;
      display:none;
      .card-title{
        font-size:22px;
      }
    }
    .company-detail-row{
      padding-top:0px;
      height:auto;
      padding-bottom:0px !important;
      .company-info{
        &::before{
          display:none;
        }
        .inner-scroll-wrapper{
          padding:0px 0px;
        }
        .auth-login-form{
          display:none;
        }
        &.form-active{
          .auth-login-form{
            display:block;
          }
        }
      }
      .section-title-second-heading{
        padding-right:40px;
        margin-top:5px;
        .mobile-toggle-header-btn{
          display:flex;
        }
      }
    }
    .add-notes-sec{
      padding-top: 15px;
      margin-top: 13px;
      .inner-scroll-wrapper{
        padding:0px 0px !important;
      }
      .header-action{
        .view-pinned-note-btn{
          &:hover{
            background-color:#ffffff !important;
          }
        }
      }
      .rdw-editor-main{
        background-color:#ffffff;
      }
      .note-listing-wrapper{
        margin:0px 0px !important;
        padding:0px 0px !important;
      }
    }
    .submit-btn-fixed-wrapper{
      position:fixed !important;
      bottom:88px !important;
      border-top:none !important;
      left:50% !important;
      transform:translateX(-50%);
      background-color:transparent;
      padding:0px 0px;
      box-shadow:unset !important;
      z-index:5;
      .btn-primary{
        border-radius:50px !important;
      }
    }
  }
  // company-profile-page-end

  // comapnt-note-sec
  .notes-card-box{
    .action-btn-wrapper{
      .dropdown{
        & > .nav-link{
          &:hover{
            background-color:#ffffff;
          }
        }
      }
    }
  }
  // comapnt-note-sec-end

  // company-user-list-page
  .user-list-page{
    overflow:hidden;
    .card-header{
      background-color:transparent !important;
      border-top-left-radius: 0px;
      border-top-right-radius:0px;
      padding:0px 15px 15px 15px;
      box-shadow:0px 4px 8px #d2d2d2;
      position: relative;
      z-index:2;
      .card-title{
        width:100%;
        margin-bottom:13px;
      }
      .button-wrapper{
        width:100%;
        display:flex;
        justify-content:space-between;
        .btn-group{
          margin-right:0px !important;
          width:calc(50% - 6px);
          .btn-group{
            width:100%;
            .dropdown-toggle{
              width:100%;
            }
          }
        }
        .btn-primary{
          width:calc(50% - 6px) !important;
          justify-content:center !important;
        }
        #add-user {
          width: calc(50% - 6px);
          .btn-primary {
            width:100% !important; 
          }
        }
      }
    }
    .rdt_Table_wrapper{
      .search-box-wrapper{
        width:100%;
      }
    }
    & > .card-body{
      padding:0px 15px 46px 15px;
      height:calc(100dvh - 245px);
      overflow-y: auto;
      &:before{
        content: "";
        position: fixed;
        bottom:80px;
        width: 100%;
        height:46px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
    .page-item{
      margin-right:0.3571rem;
      &.next-item{
        margin-left:0px !important;
      }
    }
    .page-link{
      background-color:#ffffff;
    }
    .page-item.disabled .page-link{
      background-color:#ffffff;
    }
    .table__page__limit{
      .table__page__limit__control{
        background-color:#ffffff !important; 
      }
      & > .me-1{
        margin-right:0px !important;
      }
    }
  }
  // company-user-list-page-end

  // company-user-details-page
  .user-details-page-new{
    .user-details-page-new{
      height:calc(100dvh - 213px);
      overflow-y:auto;
      padding:0px 15px 50px 15px;
      &::before{
        content: "";
        position: fixed;
        bottom: 90px;
        width: 100%;
        height: 52px;
        z-index:4;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
    .company-detail-card{
      height:auto;
      padding:0px 0px 0px 0px;
      & > .card-body{
        padding:0px 0px !important;
      }
      .company-detail-row{
        height:auto;
        .company-info{
          .inner-scroll-wrapper{
            overflow-y:hidden;
            padding-bottom:6px;
          }
          .auth-login-form{
            display:block;
          }
        }
        .add-notes-sec{
          margin-top:20px;
        }
      }
    }
    .permission-wrapper{
      .accordian-loyal-box{
        background-color:#ffffff;
        .accordian-loyal-header{
          .inner-wrapper{
            padding:14px 95px 14px 15px;
          }
          .switch-checkbox{
            top: 12px;
            right:42px;
          }
        }
      }
    }
    .company-task-manager-card{
      background-color: #f4f4f4;
    }
    .contact-activity-tabPane{
      height:auto !important;
    }
    .contact-notes-tabPane{
      margin-left:-15px;
      margin-right:-15px;
      .available-notes-header{
        position: fixed;
        top: 120px;
        left: 0px;
        width: 100%;
        background-color: #f7f6f0;
        padding-bottom: 11px;
        margin-bottom: 0px;
        z-index:4;
        box-shadow: 0px 4px 6px #dbdbdb;
      }
      .inner-contant-wrapper{
        padding-top:0px;
      }
      .notes-list-scroll-area{
        height:auto;
        max-height:unset;
        &::before{
          display:none;
        }
        & > div:last-child{
          margin-bottom:10px;
        }
      }
    }
    .contact__checklist__wrapper{
      padding:110px 0px 0px 0px;
      margin-left:-15px;
      margin-right:-15px;
      .card-header{
        position: fixed;
        top: 122px;
        left: 0px;
        width: 100%;
        z-index: 3;
        background-color: #f7f6f0;
        border-radius: 0px;
        .card-title{
          position: relative;
          top: 8px;
        }
        .btns-wrapper{
          margin-top:28px !important;
        }
      }
      .company-checklist-inner-scroll{
        max-height:unset;
        height:auto;
        .accordion-margin:last-child{
          margin-bottom:0px;
        }
      }
      .list-grid-btn-wrapper{
        position: fixed;
        right: 15px;
        top: 122px;
      }
    }
    .contact__files__wrapper{
      margin-left:-15px;
      margin-right:-15px;
      .company-document-page{
        padding-top:0px;
        .top-header{
          margin-bottom: 0px !important;
          padding: 0px 15px 15px 15px !important;
          box-shadow: 0px 4px 6px #dbdbdb;
          position: fixed;
          top: 123px;
          left: 0px;
          width: 100% !important;
          background-color: #f7f6f0;
          z-index:4;
        }
        & > .ui-loader{
          max-height:unset;
          height:auto;
          padding-top: 70px;
        }
      }
    }
  }
  // company-user-details-page-end

  // company-checklist-page
  .company-checklist-page-wrapper{
    .company-checklist-header{
      right: 15px;
      top: 4px;
      z-index:5;
    }
    .compnay-checklist-folder-wrapper{
      overflow:hidden;
      & > .card-header{
        background-color:transparent !important;
        padding:0px 15px 15px 15px !important;
        box-shadow:0px 4px 8px #d2d2d2;
        padding-bottom:16px;
        position: relative;
        z-index:4;
        .card-title{
          font-size:16px;
          font-size: 20px !important;
          margin-top: 12px !important;
        }
        .btns-wrapper{
          width:100% !important;
          margin-top: 20px !important;
          justify-content: space-between;
          .create-folder-link{
            width:calc(50% - 6px);
            border:1px solid var(--primaryColor);
            text-align:center;
            padding:9px 10px !important;
            top:0px;
            &:hover{
              background-color:#ffffff !important;
            }
          }
          .add-btn-wrapper{
            width:calc(50% - 6px);
            .btn-primary{
              width:100% !important;
              margin-left:0px !important;
            }
          }
        }
      }
      & > .card-body{
        padding:0px 0px 0px 0px !important;
        .company-checklist-inner-scroll{
          padding:20px 15px 40px 15px !important;
          max-height:calc(100dvh - 260px);
          position: relative;
          &::before{
            content: "";
            position: fixed;
            bottom:80px;
            width: 100%;
            height: 50px;
            z-index:4;
            left: 50%;
            transform: translateX(-50%);
            background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          }
        }
      }
      .accordion-item.checklist-box{
        background-color:#ffffff;
        border-radius:12px !important;
        margin-bottom:8px !important;
        .company-checklist-row{
          .custom-col{
            width:100% !important;
          }
        }
      }
      .checklist-folder-list-box{
        .checklist-header{
          .accordion-button{
            padding:10px 8px 10px 13px !important;
          }
          .title{
            max-width:calc(100% - 60px);
            padding-right: 8px;
            .badge{
              padding: 5px 9px;
              font-size: 12px;
              margin-left:8px !important;
            }
          }
          .action-btn-wrapper{
            .action-btn{
              display:none;
              border-radius:50%;
              &.down-arrow-btn{
                display:flex;
              }
              &.mobile-menu-btn{
                display:flex;
                margin-right:0px;
              }
              &:hover{
                background-color:#f2f2f2;
              }
            }
          }
        }
      }
    }
    .company-form-card.checklist-company-form-card{
      .header-wrapper{
        padding:10px 8px 10px 15px;
        .action-btn-wrapper{
          .action-btn{
            display:none;
            &.toggle-btn{
              display:flex;
              padding: 5px;
            }
          }
        }
      }
    }
  }
  // company-checklist-page-end

  // company-document-page
  .company-document-page{
    overflow:hidden;
    & > .ui-loader{
      height:calc(100dvh - 283px);
      overflow-y:auto;
      padding-bottom:15px;
      &:before{
        content: "";
        position: fixed;
        bottom: 84px;
        width: 100%;
        height: 50px;
        z-index: 4;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .top-header{
      margin-bottom:0px;
      flex-wrap:wrap;
      padding:4px 15px 15px 15px;
      box-shadow:0px 4px 8px #d2d2d2;
      background-color: #f7f6f0;
      .mobile-heading{
        display:block;
        font-weight:600;
        color:#000000;
        font-size:26px;
        margin-bottom:0px;
      }
      .add-new-btns{
        order:3;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        .add-new-btn{
          width:calc(50% - 6px) !important;
          margin-top:13px;
          .btn-primary{
            width:100% !important;
          }
          .create-folder-link{
            margin-left:0px;
            width:100%;
            padding:0.64rem 9px;
            text-align:center;
            justify-content:center;
          }
        }
      }
    }
    .company-document-listing-row{
      margin-bottom:0px !important;
      .card-header{
        .toggle-btn{
          display:flex;
          width: 30px;
          height: 30px;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          border-radius: 50%;
          padding: 4px;
          background-color:#f9f9f9;
          cursor: pointer;
          border:none;
          svg{
            width: 100%;
            height: 100%;
            color: #000000;
          }
          &:hover{
            background-color:#000000;
            svg{
              color:#ffffff;
            }
          }
        }
      }
      .card-header{
        border-top-left-radius:0px;
        border-top-right-radius:0px;
        padding:12px 15px;
        .card-title{
          font-size:20px !important;
          font-weight:600;
        }
      }
      .compnay-checklist-folder-wrapper{
        & > .card-body{
          padding:15px 15px !important;
        }
      }
      .checklist-folder-list-box{
        .checklist-header{
          .accordion-button{
            padding:8px 10px 8px 14px !important;
          }
          .action-btn-wrapper{
            .action-btn{
              display:none;
              border-radius:50%;
              svg{
                color:#000000 !important;
              }
              &.toggle-btn,
              &.down-arrow-btn{
                display:flex !important;
              }
              &:hover{
                background-color:#f5f5f5;
              }
            }
          }
        }
        .checklist-header{
          .title{
            max-width:calc(100% - 67px);
          }
        }
      }
      .accordion-item.checklist-box{
        background-color:#ffffff;
        margin-bottom:8px !important;
        border-radius:10px !important;
      }
      .company-checklist-inner-scroll{
        max-height:unset !important;
        height:auto !important;
      }
      &:last-child{
        .card-body{
          max-height:unset !important;
          height:auto !important;
          padding:15px 15px !important;
          & > .row{
            margin-top:0px !important;
          }
        }
        .custom-col{
          margin-bottom:14px;
        }
        .company-form-card{
          background-color:#ffffff;
        }
      }
      &.list-view{
        .accordion-body{
          .card-header{
            display:none;
          }
          .card-body{
            padding:0px 0px;
            .search-box-wrapper{
              margin-top:0px;
            }
          }
        }
        &:last-child{
          .search-box-wrapper{
            margin-top:0px !important;
          }
        }
      }
    }
  }
  // company-document-page-end

  // contact-profile-page
  .contact__card__wrapper{
    &.new-design{
      & > .card-header{
        padding-left: 53px;
        padding-right: 15px;
        padding-top: 5px;
        margin-bottom:0px;
        padding-bottom:4px;
        background-color: #f6f5ef !important;
        z-index:5;
        .back-arrow{
          left: 8px;
          top:-1px;
        }
        .card-title{
          font-size: 22px !important;
        }
      }
      .add-update-contact-tab-wrapper{
        margin-bottom:0px;
        box-shadow:0px 4px 8px #d2d2d2;
        z-index:4;
        position: relative;
        .horizontal-tabbing{
          .nav-item{
            .nav-link{
              &:hover{
                background-color:transparent !important;
                color:#000000;
              }
              &.active{
                color:var(--primaryColorDark);
                &:hover{
                  color:var(--primaryColorDark);
                }
              }
            }
          }
        }
      }
      .contact-personalInfo-wrapper{
        padding:0px 0px;
        .right-tabContant-wrapper{
          .contact-create-new-viewport-scroll{
            max-height:calc(100dvh - 236px);
            padding:15px 15px 40px 15px;
            position: relative;
            &::before{
              content: "";
              position: fixed;
              bottom: 88px;
              width: 100%;
              height: 50px;
              z-index:4;
              background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .fixed-button-wrapper{
            margin-top: 0px !important;
            position: fixed;
            bottom: 88px;
            left: 50%;
            transform: translateX(-50%);
            z-index:6;
            .btn-primary{
              border-radius:100px !important;
            }
          }
          .formField-row{
            margin-left:0px !important;
            margin-right:0px !important;
            & > *{
              padding-left:0px;
              padding-right:0px;
            }
            .formField-col{
              width:100% !important;
              flex-wrap:wrap !important;
            }
            .formField-col.email{
              .inner-field-wrapper{
                width:100%;
              }
              .subscribe-toggle-btn{
                padding-bottom:0px;
                margin-top:12px;
              }
            }
          }
          .custom-field-box{
            .form-label{
              margin-bottom:5px;
            }
          }
        }
      }
      .funnel-sec{
        .funnel-title{
          width:100%;
          padding-right:0px;
          margin-right:0px;
          margin-bottom:15px;
          &::before{
            display:none;
          }
        }
        .funnel-cn-wrapper{
          width:100%;
          .funnel-item{
            min-width:unset;
            padding:3px 10px 4px 22px;
            .funnel-name{
              font-size:14px;
            }
            &::before{
              border: 16px solid transparent;
              border-left: 12px solid #f7f6f0;
            }
            &:after{
              right: -25px;
              border: 15px solid transparent;
              border-left:12px solid #ffffff;
              top: -1px;
            }
            &.active{
              &:after{
                border-left:12px solid var(--primaryColor);
              }
            }
          }
        }
      }
      .contact-activity-tabPane{
        padding:20px 15px 10px 15px;
        height:calc(100dvh - 220px);
        &:before{
          content: "";
          position: fixed;
          bottom: 64px;
          width: 100%;
          height: 50px;
          z-index: 4;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .contact-notes-tabPane {
        .inner-contant-wrapper {
          padding: 4px 0px 0px 0px;
        }
        .available-notes-header {
          // flex-wrap: nowrap;
          margin-bottom: 10px;
          padding: 0px 15px;
          margin-top: 12px;
          .print-action-wrapper {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-left: 12px;
            .print-btn {
              font-size: 0px;
              padding: 4px 4px;
              width: 42px;
              height: 41.2px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
              svg {
                margin-right: 0px;
              }
            }
          }
          .dropdown-toggle {
            font-size: 0px;
            padding: 6px 12px 6px 10px;
            height: 41.2px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            .align-middle {
              display: none;
            }
            &:after {
              left: 5px;
            }
          }
          .add-note-btn {
            position: fixed;
            margin: 0px !important;
            bottom: 88px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 4;
            border-radius: 100px;
            width: 165px;
          }
        }
        .contact-notes {
          .accordion-item {
            box-shadow: unset !important;
            background-color: transparent;
            border-radius: 0px !important;
            margin-bottom: 0px !important;
            border: none !important;
            border-bottom: 1px solid #d9d9d9 !important;
            .accordion-header {
              .accordion-button {
                position: relative;
                .inner-wrapper {
                  width: 100%;
                  padding-right:55px;
                  .heading-details {
                    .title {
                      width: calc(100% - 70px);
                      max-width: 100%;
                      margin-right: 0px;
                      font-size: 16px;
                    }
                    .author-time {
                      width: 100%;
                      .author-name,
                      .time,
                      .attachments-count{
                        font-size: 14px;
                      }
                      .author-name {
                        margin-right: 8px;
                        max-width: calc(100% - 170px);
                        white-space: pre;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
                .action-btn-wrapper {
                  position: absolute;
                  top: 7px;
                  right: 10px;
                  .action-btn {
                    width: 26px;
                    height: 26px;
                    border-radius: 4px;
                    margin-right: 2px;
                    display:none !important;
                    &.edit-btn,
                    &.delete-btn {
                      padding: 6px;
                      display: none;
                    }
                    &:last-child {
                      margin-right: 0px;
                    }
                    &:hover {
                      background-color: #ffffff;
                    }
                    &.mobile-toggle-btn,
                    &.down-arrow-btn{
                      display:flex !important;
                    }
                  }
                }
                &:not(.collapsed){
                  .inner-wrapper{
                    .heading-details{
                      .title{
                        width:auto !important;
                        max-width:100% !important;
                      }
                    }
                  }
                }
              }
            }
            .accordion-body {
              padding: 0px 15px 15px 15px;
            }
            .contant-wrapper {
              .normal-text {
                font-size: 14px;
                line-height: 20px;
                p {
                  line-height: 24px;
                }
              }
            }
            .attachment-wrapper {
              margin: 0px -5px;
              margin-top: 16px;
              .file__card {
                width: 25% !important;
                max-width: 25% !important;
                padding: 0px 5px;
                .inner-border-wrapper {
                  .inner-wrapper {
                    padding-top: 100% !important;
                    width: 100%;
                    height: auto;
                    .overlay {
                      display: flex;
                      .action-btn-wrapper {
                        .action-btn {
                          width: 30px;
                          height: 30px;
                          &:hover {
                            background-color: rgba(255, 255, 255, 0.2);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .footer-details {
              display: none;
            }
            .accordion-collapse.show {
              border-top: none;
            }
            &:hover {
              .accordion-header {
                .accordion-button {
                  .inner-wrapper {
                    .heading-details {
                      .title {
                        width: calc(100% - 70px);
                      }
                    }
                  }
                  .action-btn-wrapper {
                    .action-btn {
                      &.edit-btn,
                      &.delete-btn {
                        display: flex;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .notes-list-scroll-area {
          position: relative;
          max-height:calc(100dvh - 289px);
          padding-bottom: 22px;
          &:before {
            content: '';
            position: fixed;
            bottom: 75px;
            width: 100%;
            height: 65px;
            z-index: 3;
            background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
            left: 50%;
            transform: translateX(-50%);
          }
          & > div:last-child {
            margin-bottom: 40px;
            .accordion-item {
              border-bottom: none !important;
            }
          }
        }
      }
      .contact__checklist__wrapper{
        padding:22px 0px 40px 0px;
        height:calc(100dvh - 224px);
        overflow-y:auto;
        position: relative;
        &::before{
          content: "";
          position: fixed;
          bottom: 64px;
          width: 100%;
          height: 50px;
          z-index: 4;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
        .company-checklist-inner-scroll{
          max-height:unset;
          height:auto;
          &:before{
            content: "";
            position: fixed;
            bottom: 64px;
            width: 100%;
            height: 50px;
            z-index: 2;
            background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
            left: 50%;
            transform: translateX(-50%);
          }
          .accordion-margin:last-child{
            margin-bottom:25px;
          }
          .checklist-search{
            margin-bottom:5px;
            width:100%;
            padding:10px 15px 0px 15px;
          }
        }
        .list-grid-btn-wrapper{
          right:15px;
          top:13px;
        }
        .card-header{
          padding:0px 15px 0px 15px;
          box-shadow:unset;
          .card-title{
            font-size:20px !important;
          }
          .btns-wrapper{
            width:100%;
            margin-top:20px !important;
            display:flex;
            align-items:center;
            justify-content:space-between;            
            .create-folder-link,
            .add-btn-wrapper{
              width:calc(50% - 6px);
            }
            .create-folder-link{
              background-color:#ffffff;
              display:flex;
              justify-content:center;
              outline:1px solid var(--primaryColor);
              height:39.88px;
              font-weight:500;
              padding:11px 6px;
              &:hover{
                background-color:var(--primaryColor);
                color:#000000;
              }
            }
            .add-btn-wrapper{
              .btn{
                margin-left:0px !important;
                width:100% !important;
                padding:11px 6px;
              }
            }
          }
        }
        .compnay-checklist-folder-wrapper > .card-body{
          padding:7px 0px 0px 0px !important;
        }
        .accordion-item.checklist-box{
          margin-bottom:0px !important;
          box-shadow:unset !important; 
          border-radius:0px !important;
          background-color:transparent;
          border:none !important;
          border-bottom:1px solid #d9d9d9 !important;
          .checklist-header{
            .accordion-button{
              padding:11px 10px 11px 15px !important;
            }
            .title{
              font-size:15px;
              width:calc(100% - 37px);
              max-width:calc(100% - 37px);
              .badge{
                font-size: 11px;
                padding: 2px 9px !important;
                height: 20px;
                line-height: 20px;
                min-width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px 0px 0px 8px !important;
                position: relative;
                top: 1px;
              }
            }
            .action-btn-wrapper {
              padding-left: 10px;
              .action-btn {
                padding: 5px;
                width: 26px;
                height: 26px;
                margin-right: 2px;
                display: none;
                &:hover {
                  background-color: #ffffff;
                }
                &.down-arrow-btn {
                  padding: 3px;
                  margin-right: 0px;
                  display: flex;
                  svg {
                    color: #000000;
                  }
                }
                &.mobile-menu-btn{
                  display:flex;
                  svg{
                    color:#000000;
                  }
                }
              }
            }
          }
          .company-form-card.checklist-company-form-card{
            background-color:#ffffff;
            .header-wrapper{
              padding:10px 8px 10px 15px;
              .form-card-title{
                font-size:15px;
              }
              .action-btn-wrapper{
                .action-btn{
                  width:26px;
                  height:26px;
                  margin-right:2px;
                  display:none;
                  align-items:center;
                  justify-content:center;
                  padding:6px;
                  svg{
                    width:100%;
                    height:100%;
                  }
                  &:last-child{
                    margin-right:0px;
                  }
                  &.toggle-btn{
                    display:flex;
                  }
                }
              }
            }
            .body-wrapper{
              .checklist-listing-items{
                .item{
                  .form-check-input{
                    width:17px;
                    height:17px;
                    position: relative;
                    top:2px;
                  }
                }
              }
            }
          }
        }
        .company-checklist-row{
          .custom-col{
            width:100%;
          }
        }
      }
      .contact__files__wrapper{
        height: calc(100dvh - 223px);
        overflow-y: auto;
        &:before{
          content: "";
          position: fixed;
          bottom: 73px;
          width: 100%;
          height: 45px;
          z-index: 4;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        }
        .top-header{
          box-shadow:unset;
          margin-bottom:0px !important;
          padding:12px 15px 15px 15px;
        }
        .company-document-page{
          & > .ui-loader{
            height:auto;
            &::before{
              display:none;
            }
          }
        }
      }
      .contact-email-tab{
        height:calc(100dvh - 225px);
        overflow-y:auto;
        padding:22px 15px 30px 15px;
        &:before{
          content: "";
          position: fixed;
          bottom: 72px;
          width: 100%;
          height:45px;
          z-index: 2;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
        .contact-email-card-row{
          height:auto;
          .contact-email-card-col{
            width:100%;
            margin-bottom:12px;
          }
        }
      }
      .loyal-task-manager{
        height: calc(100dvh - 225px);
        overflow-y:auto;
        &::before{
          content: "";
          position: fixed;
          bottom: 64px;
          width: 100%;
          height: 50px;
          z-index: 4;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        }
        .sidebar-left{
          height:100dvh;
        }
        .content-right{
          .task-manager-top-header{
            box-shadow:unset;
            padding:20px 16px 0px 0px !important;
            .left{
              width: calc(100% - 53px);
            }
            .right{
              .new-task-btn{
                right:0px;
              }
            }
            .task-manager-sidebar-toggle-btn{
              top: 20px;
              right: 16px;
            }
          }
          .task-manager-category-scrollbar{
            height:auto !important;
          }
          .task-manager-category-wrapper{
            padding-top:0px;
            margin-top:10px;
          }
        }
        .task-manager-body{
          .add-task-form-row{
            margin-top:10px;
          }
          .infinite-scroll-component.todo-task-list{
            height:auto !important;
            &::before{
              display:none;
            }
          }
        }
      }
    }
  }
  // contact-profile-page-end

  // contact-list-page
  .contact__list__page{
    .card-header{
      padding:0px 15px;
      border-radius:0px;
      background-color:transparent !important;
      border-bottom:none;
      .card-title{
        font-size:22px !important;
        margin-bottom:12px;
      }
      .buttons-wrapper{
        width:100%;
        & > div{
          width:100%;
          display:flex;
          align-items:center;
          .btn-group{
            width:calc(50% - 6px);
            .dropdown-toggle{
              width:100%;
            }
          }
          .btn-primary{
            margin-left:0px;
            width:calc(100% - 6px) !important;
          }
        }
      }
    }
    & > .card-body{
      padding:0px 15px;
    }
    .search-box-wrapper-contactList{
      margin-top:10px !important;
      margin-bottom:15px !important;
      .right-filter-wrapper{
        margin-top: 9px;
      }
      .contact-list-taskFilter{
        .icon_wrapper{
          background-color: #fff !important;
        }
      }
      .contact-list-all-filter-wrapper{
        .selects-custom-dropdown-wrapper{
          .selects-custom-dropdown{
            left:unset;
            right:0px;
          }
        }
        .custom_select_wrapper{
          width:calc(100% - 56px);
        }
        .contact-list-horizontal-tab{
          width:100%;
          margin-left:0px;
          margin-top:10px;
          .horizontal-button-tab{
            .view-btn{
              &:last-child{
                width:42% !important;
              }
            }
          }
        }
      }
    }
  }
  // contact-list-page-end

  // contact-manage-group-page
  .contact-manage-group-page{
    &.kanban-view-active{
      .kanban-view-wrapper{
        padding:0px 15px;
        .react-trello-board{
          .smooth-dnd-draggable-wrapper{
            .smooth-dnd-container{
              height:calc(100dvh - 350px);
            }
          }
          .smooth-dnd-draggable-wrapper{
            margin-right:10px;
          }
        }
      }
      .contact-manage-group-header{
        right:12px;
      }
    }
    &.list-view-active{
      padding-bottom:25px;
      .card-header{
        padding:0px 15px 0px 60px !important;
        background-color:transparent !important;
        border-bottom:none !important;
        .right-wrapper{
          .btn-group{
            margin-right:0px !important;
          }
        }
        .back-arrow{
          top: 1px;
          &:hover{
            background-color:#ffffff !important;
          }
        }
      }
      .contact-manage-group-header{
        top: 51px;
        width: calc(100% - 30px);
        .right-wrapper{
          width:100%;
          .btn{
            width:calc(100% - 94px) !important;
          }
        }
      }
      .card-body{
        padding:45px 15px 0px 15px;
        .rdt_Table_wrapper{
          .search-box-wrapper{
            width:100%;
          }
        }
        .table-responsive{
          overflow-x:auto;
          .table{
            min-width:700px;
            background-color: #fff;
          }
        }
      }
    }
  }
  // contact-manage-group-page-end

  // contact-status-page-new
  .contact-status-page-new{
    padding:0px 15px;
    .kanban-view-wrapper{
      .react-trello-board{
        .smooth-dnd-draggable-wrapper{
          margin-right:10px;
          .smooth-dnd-container{
            height: calc(100dvh - 400px);
          }
        }
      }
    }
    .contact-status-page-header{
      padding:8px 0px 0px 0px;
      margin-top:-4px;
      background-color:transparent;
      .dropdown-wrapper{
        width:100%;
      }
      .custom_select_wrapper{
        .cs_form_label{
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      .right-wrapper{
        width:100%;
        margin-top:10px;
        .btn{
          width:calc(100% - 94px);
        }
      }
    }
    &.list-view{
      padding-top:48px;
      padding-bottom:25px;
      .card-header{
        padding:0px 0px 0px 52px;
        background-color:transparent !important;
        border-bottom:none;
        position: fixed;
        top: 64px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 30px);
        .back-arrow{
          top: 2px;
          left:0px;
          &:hover{
            background-color:#ffffff;
          }
        }
        .right-wrapper{
          .btn-group{
            margin-right:0px !important;
          }
        }
      }
      .card-body{
        padding:0px 0px;
        .rdt_Table_wrapper{
          .search-box-wrapper{
            width:100%;
            margin-top:0px;
          }
        }
        .table-responsive{
          overflow-x:auto;
          .table{
            min-width:700px;
            background-color:#ffffff;
          }
        }
      }
    }
  }
  // contact-status-page-new-end

  // contact-tags-page
  .contact-tags-page{
    .card-header{
      padding:0px 15px;
      background-color:transparent !important;
      position: relative;
      border-bottom:none !important;
      .create-folder-link{
        position:absolute;
        top:0px;
        right:5px;
        background-color:#ffffff;
        color:#000000 !important;
        &:hover{
          background-color:var(--primaryColor) !important;
        }
      }
      & > div{
        flex-wrap:wrap;
      }
      .custom_select_wrapper{
        width:100% !important;
        margin-top:24px;
      }
      .button-wrapper{
        margin-left:0px !important;
        justify-content:space-between;
        width:100%;
        margin-top:10px;
        .btn-group{
          margin-right:0px !important;
          width:calc(50% - 6px);
        }
        .add-btn{
          width:calc(50% - 6px) !important;
        }
      }
    }
    .rdt_Table_Card{
      .card-body{
        padding:20px 15px;
        .contact-tags-scroll-area{
          max-height:unset !important;
        }
      }
      .contact-tags-accordion{
        .accordion-item{
          background-color:#ffffff;
          margin-bottom:10px !important;
          .accordion-header{
            .accordion-button{
              .action-btn-wrapper{
                .action-btn{
                  &:hover{
                    background-color:#f4f4f4;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // contact-tags-page-end

  // contact-customField-page
  .contact-customField-page{
    .card-header{
      padding:8px 15px 0px 15px;
      background-color:transparent !important;
      border-bottom:none;
      .card-title{
        font-size:20px !important;
        .back-arrow{
          display:none;
        }
      }
      .right-wrapper{
        width:100%;
        padding-top:23px;
        .btn-group{
          width:100%;
          margin-right:0px !important;
          & > div:nth-child(1){
            width:calc(100% - 122px);
            padding-right:10px;
            .custom_select_wrapper{
              width:100% !important;
            }
          }
          .btn-group{
            display:inline-block;
            width:auto;
          }
          .dropdown-toggle{
            width: 125px;
            flex: unset;
          }
        }
        .button-wrapper{
          width:100%;
          & > .btn-primary{
            position: absolute;
            top: 0px;
            right: 12px;
          }
        }
      }
    }
    .card-body{
      padding:0px 15px;
      .rdt_Table_wrapper{
        .search-box-wrapper{
          margin-top: 12px;
          width:100%;
        }
      }
      .table-responsive{
        overflow-x:auto;
        .table{
          min-width:700px;
          background-color:#ffffff;
        }
      }
    }
  }
  // contact-customField-page-end

  // add-contact-customField-modal
  .add-contact-customField-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        &:nth-child(1){
          width:calc(55% - 6px) !important;  
        }
        &:nth-child(2){
          width:calc(45% - 6px) !important;  
        }
        margin:0px !important;
      }
    }
  }
  // add-contact-customField-modal-end

  // add-update-group-modal
  .add-update-group-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
  }
  // add-update-group-modal-end

  // contact-manage-group-view-modal
  .contact-manage-group-view-modal,
  .contact-status-view-modal,
  .contact-category-view-modal{
    .rdt_Table_Card{
      .search-box-wrapper{
        margin-top:0px !important;
        width:100%;
      }
      .card-body{
        padding:0px 0px !important;
      }
    }
    .modal-footer{
      justify-content:flex-end;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
  }
  // contact-manage-group-view-modal-end

  // add-contact-category-modal
  .add-contact-category-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
  }
  // add-contact-category-modal-end

  // add-new-contact-contact-pipline-modal
  .add-new-contact-contact-pipline-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
  }
  // add-new-contact-contact-pipline-modal-end

  // add-contact-status-modal
  .add-contact-status-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
  }
  // add-contact-status-modal-end

  // add-contact-tag-modal
  .add-contact-tag-modal{
    .modal-footer{
      justify-content:space-between;
      form{
        margin:0px !important;
        width:100% !important;
      }
      .btn{
        width:100% !important;
        margin:0px !important;
      }
    }
  }
  // add-contact-tag-modal-end

  // import-unsubContact-modal
  .import-unsubContact-modal{
    .modal-footer{
      .btn{
        margin:0px 0px !important;
      }
      .btn-text{
        display:inline-block !important;
      }
    }
  }
  // import-unsubContact-modal-end

  // company-form-page
  .company-form-page{
    overflow:hidden;
    .top-header{
      padding:0px 15px;
      box-shadow: 0px 4px 8px #d2d2d2;
      padding-bottom:15px;
      margin-bottom:0px !important;
      position: relative;
      z-index:6;
    }
    .company-form-listing-row{
      margin:0px !important;
      padding-top:20px;
      padding-bottom:30px;
      height:calc(100dvh - 200px);
      overflow-y:auto;
      position: relative;
      overflow-x: hidden;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
      .card{
        & > .card-body{
          padding:0px 0px !important;
          .rdt_Table_wrapper{
            .search-box-wrapper{
              width:100%;
            }
            .rdt_Table_footer{
              flex-wrap:wrap;
              .react-paginate{
                justify-content:center !important;
                width:100% !important;
                margin-bottom:12px !important;
                .page-link{
                  background-color:#ffffff;
                }
                .page-item{
                  margin-right:5px;
                }
              }
              & > div{
                width:100%;
                display:flex;
                justify-content:space-between;
              }
              .table__page__limit{
                & > div{
                  margin-right:0px !important;
                }
              }
            }
          }
        }
      }
    }
    .company-form-inner-card{
      & > .card-body{
        padding:0px 0px !important;
        max-height:unset !important;
        overflow:hidden !important;
      }
    }
    .card-header{
      padding:0px 0px !important;
      background-color:transparent !important;
      border-bottom:none !important;
      .card-title{
        font-size:22px !important;
      }
      .mobile-toggle-header-btn{
        width:30px;
        height:30px;
        border-radius:50%;
        display:flex !important;
        align-items:center;
        justify-content:center;
        padding:4px;
        transition:0.5s;
        position:absolute;
        top:-2px;
        right:0px;
        background-color:#ffffff;
        cursor: pointer;
        svg{
          width:100%;
          height:100%;
          color:#000000;
        }
        &:hover{
          background-color:#000000;
          svg{
            color:#ffffff;
          }
        }
      }
    }
    .company-form-card-row{
      .company-form-card-col{
        width:100% !important;
        .company-form-card{
          background-color:#ffffff !important;
        }
      }
    }
    .loadMore-btn-wrapper{
      padding:0px 0px;
      &:before{
        display:none !important;
      }
    }

    .form-list-table-col{
      .rdt_Table_wrapper{
        .rdt_TableHeadRow,
        .rdt_TableBody{
          min-width:550px;
        }
      }
    }
  }
  // company-form-page-end

  // company-form-preview-page
  .form-preview-page{
    & > .ui-loader{
      & > .card{
        overflow:hidden;
        & > .card-header{
          padding:0px 15px 15px 60px;
          background-color:transparent !important;
          border-bottom:none;
          box-shadow: 0px 4px 8px #d2d2d2;
          z-index:3;
          .card-title{
            font-size:22px !important;
          }
          .back-arrow{
            top: -3px;
            &:hover{
              background-color:#ffffff;
            }
          }
        }
        & > .card-body{
          padding:0px 0px !important;
        }
      }
    }
    .add-company-form-wrapper{
        padding:18px 15px 50px 15px !important;
        height: calc(100dvh - 198px);
        overflow-y:auto;
        overflow-x:hidden;
        position: relative;
        &:before{
          content: "";
          position: fixed;
          bottom: 84px;
          width: 100%;
          height: 50px;
          z-index: 4;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        }
      & > .auth-login-form{
        & > .submit-btn-wrapper{
          position:fixed;
          bottom:0px;
          bottom:84px !important;
          width:100%;
          z-index:6;
          left: 50%;
          transform: translateX(-50%);
          .btn{
            width:140px !important;
            margin:0px !important;
            border-radius:100px !important;
          }
        }
      }
      .inner-scroll-wrapper{
        height:auto;
        position:relative;
        // padding:15px 15px 40px 15px;
        &:before{
          content: "";
          position: fixed;
          bottom: 84px;
          width: 100%;
          height: 50px;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        }
      }
      .auth-login-form{
        margin-top:0px !important;
      }
      .permission-row-wrapper.from-details{
        .permission-row{
          width:100% !important;
        }
      }
      .preview-form-fields{
        .field-repeater-row{
          flex-wrap:wrap;
          padding-right:0px;
          .title-wrapper{
            width:100%;
            margin-top:0px;
            margin-bottom: 8px;
            padding-right: 72px;
          }
          .form-field-wrapper{
            width:100%;
            padding-left:0px;
          }
          .action-btn-wrapper{
            top: -3px;
            .action-btn{
              width: 28px;
              height: 28px;
              svg{
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
      .form-design-field-row{
        .form-design-field-col{
          width:100%;
          .range-slider-wrapper{
            margin-top:10px;
          }
        }
      }
      .accordian-loyal-box.after-form-submitted{
        .customRadio-btn-wrapper{
          .radio-btn-repeater{
            margin-right: 15px !important;
            &:last-child{
              margin-right:0px !important;
            }
          }
        }
      }
      .accordian-loyal-box.emails{
        .toggle-form-inner-wrapper{
          &.active{
            padding:15px 15px;
          }
        }
      }
      & > .company-form-preview{
        padding-top:5px;
        .submit-btn-wrapper{
          .btn{
            width:120px !important;
          }
        }
        .form-design-wrapper-fixed{
          position:static;
          width:auto;
          transform:translateX(0px) translateY(110%);
          position:fixed;
          bottom:0px;
          top:unset !important;
          left:0px;
          width:100%;
          height:auto;
          display:flex;
          align-items:flex-end;
          .inner-wrapper{
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-right-radius:12px;
            .body-wrapper{
              padding:0px 0px;
              .form-design-field-row{
                margin:0px 0px !important;
                max-height:calc(100dvh - 100px);
                padding:15px 10px 22px 10px;
              }
            }
          }
          .button-sticky{
            display:none;
          }
        }
        .form-design-wrapper-mobile-overllay + .button-sticky{
          width: 40px;
          height: 40px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          background-color: #000000;
          border: none;
          padding: 8px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          position:fixed;
          top:140px;
          right:0px;
          svg{
            width: 100%;
            height: 100%;
            color: white;
          }
        }
      }
      .company-form-preview ~ .submit-btn-wrapper{
        position:fixed;
        bottom:0px;
        bottom:84px !important;
        width:100%;
        z-index:6;
        left: 50%;
        transform: translateX(-50%);
        .btn{
          width:140px !important;
          margin:0px !important;
          border-radius:100px !important;
        }
      }
    }
  }
  // company-form-preview-page-end

  // form-design-setting-modal-open
  .app-content{
    &.form-design-setting-modal-open{
      z-index: 15;
      .form-design-wrapper-mobile-overllay{
        position:fixed;
        top:0px;
        left:0px;
        width:100vw;
        height:100dvh;
        background-color:rgba(0,0,0,0.2);
        backdrop-filter:blur(7px);
      }
      .form-preview-page{
        .add-company-form-wrapper{
          & > .company-form-preview{
            .form-design-wrapper-fixed{
              transform: translateX(0px) translateY(0px);
            }
          }
        }
      }
    }
  }
  // form-design-setting-modal-open

  // add-update-field-modal
  .add-update-field-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
      form{
        width:calc(50% - 6px) !important;
        .btn.btn-primary{
          width:100% !important;
          margin:0px !important;
        }
      }
    }
  }
  // add-update-field-modal-end

  // sms-template-card
  .sms-template-card{
    overflow:hidden;
    .grid-card-view-toggle-btns{
      right: 12px;
      top: 0px;
      z-index:6;
    }
    .sms__templates__header{
      padding:0px 12px 15px 15px;
      background-color:transparent !important;
      border-bottom:none;
      box-shadow: 0px 4px 8px #d2d2d2;
      z-index:4;
      .card-title{
        font-size:22px !important;
        width:100%;
        position: relative;
        top: 5px;
      }
      .btn{
        width:100% !important;
        margin-left:0px !important;
        margin-top: 24px !important;
      }
    }
    & > .card-body{
      height: calc(100dvh - 252px);
      padding:20px 15px 30px 15px !important;
      overflow-y: auto;
      overflow-x:hidden;
      &:before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
      .loadMore-btn-wrapper{
        padding:0px 0px !important;
        &::before{
          display:none !important;
        }
      }
    }
    .company-sms-inner-scroll.load-more-active{
      height:auto !important;
      .company-sms-temp-card-col{
        width:100% !important;
      }
    }
    & > .card{
      .card-header{
        padding:0px 12px 15px 15px;
        background-color:transparent !important;
        border-bottom:none;
        box-shadow: 0px 4px 8px #d2d2d2;
        z-index:4;
        .card-title{
          font-size:22px !important;
          width:100%;
          position: relative;
          top: 5px;
        }
        .button-wrapper{
          width:100%;
          display:flex;
          justify-content:space-between;
          margin-top: 24px;
          .btn-group{
            width:calc(50% - 6px) !important;
            margin:0px !important;
            .btn-group{
              width:100% !important;
              & > .dropdown-toggle{
                width:100%;
              }
            }
          }
          .btn{
            width:calc(50% - 6px) !important;
            margin:0px !important;
            display:flex;
            justify-content:center;
          }
        }
      }
      & > .card-body{
        padding:0px 15px 20px 15px !important;
        height: calc(100dvh - 253px);
        overflow-y: auto;
        position: relative;
        &:before{
          content: "";
          position: fixed;
          bottom: 80px;
          width: 100%;
          height: 50px;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        }
        .rdt_Table_wrapper{
          .search-box-wrapper{
            width:100%;
          }
        }
      }
    }
  }
  // sms-template-card-END

  // email-editor-modal-new
  .email-editor-modal-new{
    .modal-footer{
      .btn{
        margin:0px !important;
        width:calc(50% - 6px) !important;
      }
    }
  }
  // email-editor-modal-new-end

  // mass-sms-blast-page
  .mass-sms-blast-page{
    overflow:hidden;
    .mass-sms-blast-header{
      margin-bottom:0px;
      padding:0px 15px 15px 15px;
      box-shadow: 0px 4px 8px #d2d2d2;
    }
    .mass-email-row{
      height: calc(100dvh - 202px);
      overflow-y: auto;
      position: relative;
      padding:20px 15px 35px 15px;
      margin:0px !important;
      display:flex;
      flex-wrap:wrap;
      align-content:flex-start;
      &:before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
      .mass-sms-col,
      .schedule-mass-sms-col{
        padding:0px 0px !important;
        .inner-scroll-area{
          max-height:unset !important;
        }
        .rdt_Table_Card{
          .card-header{
            .button-wrapper{
              .btn-group{
                margin-right:0px !important;
              }
            }
            .card-title{
              max-width: calc(100% - 135px);
            }
          }
        }
      }
      .schedule-mass-sms-col{
        .rdt_Table_Card{
          position: relative;
          padding-top: 10px;
          &::before{
            content:"";
            position:absolute;
            top: -15px;
            left:-15px;
            width:calc(100% + 30px);
            height:1px;
            background-color:#e4e4e4;
          }
        }
      }
      .card-header{
        padding:0px 0px;
        background-color:transparent !important;
        border-bottom:none !important;
        margin-bottom:20px;
        .card-title{
          font-size:20px !important;
        }
        .mobile-toggle-header-btn{
          width:30px;
          height:30px;
          border-radius:50%;
          display:flex !important;
          align-items:center;
          justify-content:center;
          padding:4px;
          transition:0.5s;
          position:absolute;
          top:-2px;
          right:0px;
          background-color:#ffffff;
          cursor: pointer;
          svg{
            width:100%;
            height:100%;
            color:#000000;
          }
          &:hover{
            background-color:#000000;
            svg{
              color:#ffffff;
            }
          }
        }
      }
      .card-body{
        padding:0px 0px !important;
      }
      .mass-sms-card-col{
        width:100% !important;
      }
    }
  }
  // mass-sms-blast-page-end

  // create-mass-sms-card
  .create-mass-sms-card{
    overflow:hidden;
    .create-mass-sms-header{
      padding:0px 15px 15px 15px;
      box-shadow: 0px 4px 8px #d2d2d2;
      flex-wrap:wrap;
      background-color:transparent !important;
      position: relative;
      z-index:5;
      .back-arrow{
        top: -1px;
        &:hover{
          background-color:#ffffff;
        }
      }
      .card-title{
        padding-left:40px;
      }
      .action-btn-header{
        display:flex;
        justify-content:space-between;
        margin-top:14px;
        width:100%;
        .btn{
          width:calc(50% - 6px) !important;
        }
        .btn.update-mass-sms-btn{
          width:100% !important;
          margin-left:0px !important;
        }
      }
    }
    .create-mass-sms-body{
      padding:20px 15px 20px 15px !important;
      height: calc(100dvh - 250px);
      overflow-y: auto;
      position: relative;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
      .contact-filters-wrapper{
        background-color:transparent;
        margin-top:20px;
        .contact-filter-heading{
          padding:0px 0px;
          .search-box-wrapper{
            width:calc(100% - 98px);
          }
          .more-filter-btn{
            span{
              display:none;
            }
          }
        }
        .filter-fields-wrapper{
          padding:15px 0px 0px 0px;
          border-top:none;
          position: relative;
          margin-top: 15px;
          &::before{
            content:"";
            position:absolute;
            top:0px;
            left:-15px;
            width:calc(100% + 30px);
            height:1px;
            background-color:#e4e4e4;
          }
          .field-col{
            width:100%;
            margin-bottom:12px !important;
          }
        }
      }
    }
    .create-mass-email-contact-list{
      .contact-col{
        width:100%;
        margin-bottom:10px;
        .event-contact-card{
          background-color:#ffffff;
        }
      }
    }
  }
  // create-mass-sms-card-end

  // send-mass-sms-modal
  .send-mass-sms-modal{
    &.modal-dialog{
      max-width:100% !important;
    }
    .filter-row{
      .col-md-4{
        width:100% !important;
        margin-bottom:15px;
        &:last-child{
          margin-bottom:0px;
        }
      }
    }
    .create-mass-sms-contact-list{
      .contact-col{
        width:100%;
      }
    }
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
      }
      .auth-login-form{
        margin-left:0px !important;
        width:calc(50% - 6px) !important;
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // send-mass-sms-modal-end

  // email-template-card
  .email-template-card{
    overflow:hidden;
    .card-header{
      padding:0px 12px 15px 15px;
      background-color:transparent !important;
      box-shadow: 0px 4px 8px #d2d2d2;
      border-bottom:none;
      position: relative;
      z-index:4;
      .card-title{
        width:100%;
        font-size:22px !important;
        top: 5px;
        position: relative;
      }
      .btn-primary{
        margin-left:0px !important;
        width:100% !important;
        margin-top:23px;
      }
    }
    .inner-card-wrapper{
      .grid-card-view-toggle-btns{
        top:0px !important;
        right:12px !important;
        z-index:5;
      }
    }
    .card-body{
      padding: 20px 15px 35px 15px !important;
      height: calc(100dvh - 251px);
      overflow-y: auto;
      overflow-x: hidden;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index:4;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
    .company-email-temp-card-row{
      .company-email-temp-card-col{
        width:100% !important;
      }
      .company-form-card{
        margin-top:0px;
        .body-wrapper{
          padding:16px 15px 60px 15px;
        }
      }
    }
    .loadMore-btn-wrapper{
      padding:0px 0px;
      &::before{
        display:none;
      }
    }
    .company-email-inner-scroll,
    .company-email-inner-scroll.load-more-active{
      height:auto !important;
      max-height:unset !important;
    }
    .company-email-inner-scroll.load-more-active{
      padding-bottom:10px;
    }

    .email-template-list-view{
      .card-header{
        .button-wrapper{
          width:100%;
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin-top:23px;
          .btn-group{
            margin-right:0px !important;
            width:calc(50% - 6px) !important;
            .btn-group{
              width:100% !important;
              .dropdown-toggle{
                width:100% !important;
              }
            }
          }
          .btn-primary{
            width:calc(50% - 6px) !important;
            margin-top:0px;
            justify-content:center;
          }
        }
      }
      .rdt_Table_wrapper{
        .search-box-wrapper{
          width:100%;
          margin-top:0px;
        }
      }
    }
  }
  // email-template-card-end

  // send-test-email-modal
  .send-test-email-modal{
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
      }
      .auth-login-form{
        margin-left:0px !important;
        width:calc(50% - 6px) !important;
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // send-test-email-modal-end

  // mass-email-blast-page
  .mass-email-blast-page{
    overflow:hidden;
    .mass-email-tool-header{
      margin-bottom: 0px;
      padding: 0px 15px 15px 15px;
      box-shadow: 0px 4px 8px #d2d2d2;
      position: relative;
      z-index:4;
    }
    .mass-email-tool-row-list-view{
      height: calc(100dvh - 202px);
      overflow-y: auto;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 20px 15px 35px 15px;
      margin: 0px !important;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
      .mass-email-col{
        padding:0px 0px;
        .card-header{
          padding: 0px 0px;
          background-color: transparent !important;
          border-bottom: none !important;
          margin-bottom: 20px;
          .card-title{
            font-size: 20px !important;
          }
          .mobile-toggle-header-btn{
            border-radius: 50%;
            padding: 4px;
            background-color: #ffffff;
            cursor: pointer;
            right: 0px;
            width: 30px;
            height: 30px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            position: absolute;
            top: -2px;
            &:hover{
              background-color: #000000;
              svg{
                color: #ffffff;
              }
            }
          }
        }
        .card-body{
          padding: 0px 0px !important;
        }
        .rdt_Table_Card{
          .card-header{
            .button-wrapper{
              margin:0px !important;
              .btn-group{
                margin:0px !important;
              }
            }
          }
          .rdt_Table_wrapper{
            .search-box-wrapper{
              width:100%;
              margin-top:0px;
            }
          }
        }
      }
    }
    .mass-emails-card{
      .inner-scroll-area,
      .inner-scroll-area.load-more-active{
        height:auto;
        max-height:unset;
      }
      .inner-scroll-area{
        .mass-email-card-col{
          width:100% !important;
          margin-bottom:12px;
        }
      }
      .loadMore-btn-wrapper{
        padding:10px 0px 0px 0px;
        &::before{
          display:none;
        }
      }
    }
    .scheduled-mass-email-col{
      padding:10px 0px 0px 0px;
      .scheduled-mass-emails-card{
        .card-header{
          padding: 0px 0px;
          background-color: transparent !important;
          border-bottom: none !important;
          margin-bottom: 20px;
          .card-title{
            font-size: 20px !important;
          }
          .mobile-toggle-header-btn{
            border-radius: 50%;
            padding: 4px;
            background-color: #ffffff;
            cursor: pointer;
            right: 0px;
            width: 30px;
            height: 30px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            position: absolute;
            top: -2px;
            &:hover{
              background-color: #000000;
              svg{
                color: #ffffff;
              }
            }
          }
        }
        .card-body{
          padding: 0px 0px !important;
        }
        .inner-scroll-area,
        .inner-scroll-area.load-more-active{
          height:auto;
          max-height:unset;
          .scheduled-mass-email-card-col{
            width:100%;
            margin-bottom:12px;
          }
        }
        .loadMore-btn-wrapper{
          padding:10px 0px 0px 0px;
          &::before{
            display:none;
          }
        }
      }
      .rdt_Table_Card{
        .card-header{
          padding:0px 0px;
          display:flex;
          align-items:center;
          background-color:transparent !important;
          border-bottom:none !important;
          .card-title{
            font-size:20px !important;
            width:calc(100% - 120px);
            // white-space:pre;
            // overflow:hidden;
            // text-overflow:ellipsis;
            padding-right:10px;
          }
          .button-wrapper{
            margin:0px !important;
            .btn-group{
              margin:0px !important;
            }
          }
        }
        .card-body{
          padding:0px 0px !important;
        }
        .rdt_Table_wrapper{
          .search-box-wrapper{
            width:100%;
          }
        }
      }
    }
  }
  // mass-email-blast-page-end

  // create__mass__email__page
  .create__mass__email__page{
    overflow:hidden;
    .card-header{
      padding: 0px 15px 15px 15px;
      box-shadow: 0px 4px 8px #d2d2d2;
      background-color: transparent !important;
      position: relative;
      z-index:4;
      .left{
        padding-left:44px;
        .back-arrow{
          top:-1px;
          &:hover{
            background-color:#ffffff;
          }
        }
      }
      .right{
        width: 100%;
        .btns-wrapper{
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 14px;
          .btn{
            width: calc(50% - 6px) !important;
            &.update-mass-email-btn{
              width:100% !important;
            }
          }
        }
      }
    }
    .custom-card-body{
      padding: 20px 15px 20px 15px !important;
      height: calc(100dvh - 250px);
      overflow-y: auto;
      position: relative;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
    .create__mass__email__form{
      .select-template-field{
        .form-field-wrapper{
          .preview-icon{
            background-color:#ffffff;
            &:hover{
              background-color:#ffffff;
            }
          }
        }
      }
      .title-field{
        margin-bottom:15px;
      }
      .contact-filters-wrapper{
        background-color:transparent;
        margin-top:20px;
        margin-bottom:5px;
        .contact-filter-heading{
          padding:0px 0px;
          .search-box-wrapper{
            width:calc(100% - 98px);
          }
          .more-filter-btn{
            span{
              display:none;
            }
          }
        }
        .filter-fields-wrapper{
          padding:15px 0px 0px 0px;
          border-top:none;
          position: relative;
          margin-top: 15px;
          &::before{
            content:"";
            position:absolute;
            top:0px;
            left:-15px;
            width:calc(100% + 30px);
            height:1px;
            background-color:#e4e4e4;
          }
          .field-col{
            width:100%;
            margin-bottom:12px !important;
          }
        }
      }
      .create-mass-email-contact-list{
        .contact-col{
          width:100%;
          margin-bottom:8px;
          .event-contact-card{
            background-color:#ffffff;
          }
        }
      }
      .create-mass-email-contact-header{
        flex-wrap:wrap;
        .right{ 
          width:100%;
          order:-1;
          padding-left:0px;
          position: relative;
          padding-bottom:15px;
          .selected-contact-info{
            margin-top:10px;
          }
          .form-check-input{
            position:absolute;
            right:0px;
            bottom: -36px;
          }
        }
      }
    }
  }
  // create__mass__email__page__end

  // send-mass-email-modal
  .send-mass-email-modal{
    .top-header > .col-md-3{
      width:100% !important;
    }
    .select-template-field{
      margin-top: 1rem !important;
    }
    .rdt_Table_footer{
      flex-wrap:wrap !important;
      .react-paginate{
        width:100%;
        justify-content:center !important;
        margin-bottom:10px !important;
      }
      .react-paginate + div{
        width:100%;
        justify-content:center !important;
        margin-bottom:12px !important;
      }
    }
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
      }
      .auth-login-form{
        margin-left:0px !important;
        width:calc(50% - 6px) !important;
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // send-mass-email-modal-end

  // clone-mass-email
  .clone-mass-email{
    .job-title-row > div{
      width:100%;
    }
    .cloneMass-mail-topHeader-fields-wrapper > div{
      width:100%;
    }
    .create__mass__email__form{
      .contact-filters-wrapper{
        background-color:transparent;
        margin-top:20px;
        .contact-filter-heading{
          padding:0px 0px;
          .search-box-wrapper{
            width:calc(100% - 98px);
          }
          .more-filter-btn{
            span{
              display:none;
            }
            &:hover{
              background-color:#eeeeee;
            }
          }
        }
        .filter-fields-wrapper{
          padding:15px 0px 0px 0px;
          border-top:none;
          position: relative;
          margin-top: 15px;
          &::before{
            content:"";
            position:absolute;
            top:0px;
            left:-15px;
            width:calc(100% + 30px);
            height:1px;
            background-color:#e4e4e4;
          }
          .field-col{
            width:100%;
            margin-bottom:12px !important;
          }
        }
      }
      .create-mass-email-contact-header{
        flex-wrap:wrap;
        .right{ 
          width:100%;
          order:-1;
          padding-left:0px;
          position: relative;
          padding-bottom:15px;
          .selected-contact-info{
            margin-top:10px;
          }
          .form-check-input{
            position:absolute;
            right:0px;
            bottom: -36px;
          }
        }
      }
      .create-mass-email-contact-list{
        .contact-col{
          width:100%;
        }
      }
    }
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
      }
      .auth-login-form{
        margin-left:0px !important;
        width:calc(50% - 6px) !important;
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // clone-mass-email-end

  // scheduled-mass-email-view-modal
  .scheduled-mass-email-view-modal{
    .details-wrapper{
      .details-box{
        width:100%;
      }
    }
    .accordion-margin.contact-accordion{
      .accordion-item{
        .event-contact-list-wrapper{
          .event-contact-col{
            width:100%;
          }
        }
      }
    }
    .modal-footer{
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
      .auth-login-form{
        margin-left:0px !important;
        width:calc(50% - 6px) !important;
        .btn{
          width:100% !important;
          margin:0px !important;
        }
      }
    }
  }
  // scheduled-mass-email-view-modal-end

  // direct__mail__card
  .direct__mail__card{
    overflow:hidden;
    .card-header{
      background-color:transparent !important;
      padding:0px 15px 15px 15px !important;
      box-shadow: 0px 4px 8px #d2d2d2;
      position: relative;
      z-index:4;
      .card-title{
        font-size:18px !important;
        width:calc(100% - 132px);
        white-space:pre;
        overflow:hidden;
        text-overflow:ellipsis;
        padding-right:6px;
      }
      .btn-primary{
        margin:0px !important;
      }
    }
    .card-body{
      padding:12px 15px 30px 15px !important;
      position: relative;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  // direct__mail__card__end

  // direct-mail-preview-page
  .direct-mail-preview-page{
    padding:0px 0px 10px 0px;
    background-color:transparent;
    overflow:hidden;
    .page-title{
      font-size:22px;
      padding:0px 15px 15px 55px;
      box-shadow: 0px 4px 8px #d2d2d2;
      .back-arrow{
        left:12px;
        top:-6px;
        &:hover{
          background-color:#ffffff;
        }
      }
    }
    .page-wrapper{
      margin-bottom:10px;
      &:last-child{
        margin-bottom:0px;
      }
      .page-wrapper-height{
        padding:15px;
        border-radius:6px;
        .view-label{
          font-size:4vw;
        }
      }
    }
    .scroll-wrapper{
      padding-right:0px;
      height:calc(100dvh - 207px);
      position: relative;
      padding:5px 15px 30px 15px !important;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
  }

  .direct-mail-preview-wrapper{
    .direct-mail-print-ll{
      background-color:#ffffff;
    }
  }
  // direct-mail-preview-page-end

  // .addEdit-direct-mail-popup
  .addEdit-direct-mail-popup-overllay{
    position:fixed;
    top:0px;
    left:0px;
    width:100dvh;
    height:100dvh;
    background-color:rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7px);
    z-index: 4;
  }
  .direct-mail-setting-btn-sticky-mobile{
    display:flex !important;
    background-color: #000000;
    border: none;
    padding: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    right:0px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position:fixed;
    top:100px;
    svg{
      width: 100%;
      height: 100%;
      color: white;
    }
  }
  .addEdit-direct-mail-popup{
    top:unset;
    bottom:0px;
    transform:translateX(0px) translateY(110%) !important;
    width:100%;
    z-index:5;
    .button-sticky{
      display:none;
    }
    .inner-wrapper{
      border-top-right-radius:12px;
      border-bottom-left-radius:0px;
    }
    .scroll-wrapper{
      max-height: calc(100dvh - 170px);
    }
  }
  .app-content.addEdit-direct-mail-popup-open{
    z-index: 15;
    .addEdit-direct-mail-popup-overllay{
      display:block !important;
    }
    .addEdit-direct-mail-popup{
      transform:translateX(0px) translateY(0px) !important;
    }
  }
  // .addEdit-direct-mail-popup-end

  // directmail-preview-modal
  .directmail-preview-modal{
    .modal-footer{
      justify-content:end;
      .btn{
        margin-left:0px !important;
        width:50% !important;
      }
    }
    .page-wrapper{
      .page-wrapper-height{
        padding: 15px;
        border-radius: 6px;
      }
    }
  }
  // directmail-preview-modal-end

  // directmail__listing__wrapper
  .email-template-card.directmail-template-card{
    .email-template-list-view{
      .card-header{
        .button-wrapper{
          .btn-primary{
            width:100% !important;
          }
        }
      }
    }
    .directmail__listing__wrapper{
      & > .card-body{
        padding:18px 15px 20px 15px !important;
        height:calc(100dvh - 251px);
      }
    }
    .company-email-temp-card-row{
      .company-form-card{
        .body-wrapper{
          padding:16px 15px 60px 15px !important;
        }
      }
    }
  }
  // directmail__listing__wrapper__end

  // view-direct-mail-modal
  .view-direct-mail-modal{
    .top__header{
      .left{
        width: calc(100% - 48px);
        padding-right: 12px;
      }
      .print__btn{
        width: 46px !important;
        padding: 10px 2px !important;
        span{
          display:none;
        }
      }
    }
    .rdt_Table_wrapper{
      .search-box-wrapper{
        width:100% !important;
      }
    }
  }
  // view-direct-mail-modal-end

  // create__direct__mail__page
  .create__direct__mail__page{
    .card-header{
      .right{
        .btns-wrapper{
          .btn{
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }
    .create__mass__email__form{
      .select-template-field{
        margin-top:15px;
        .form-field-wrapper{
          padding-right:0px;
        }
      }
    }
  }
  // create__direct__mail__page__end

  // add-envelope-page
  .invelope-preview-new{
    width: 1220px;
  }
  .envelope-page{
    background-color:transparent;
    border:none;
    border-radius:0px;
    overflow:hidden;
    .envelope-page-header{
      padding:12px 10px 12px 0px;
      flex-wrap:nowrap;
      box-shadow: 0px 4px 8px #d2d2d2;
      position: relative;
      z-index:4;
      .left{
        padding-left: 53px;
        .back-arrow{
          left: 8px;
          &:hover{
            background-color:#ffffff;
          }
        }
        .title{
          font-size: 21px;
        }
      }
      .right{
        .action-btn{
          margin-left:0px;
        }
        .update-save-btn{
          margin-left:8px;
        }
      }
    }
    .envelope-body{
      padding:15px 15px 36px 15px;
      height: calc(100dvh - 210px);
      &::before{
        content: "";
        position: fixed;
        bottom: 81px;
        width: 100%;
        height: 50px;
        z-index: 3;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .top-header-envelope-add{
      flex-wrap:wrap;
      .left{
        width:100%;
        padding-right:0px;
        .col-md-6{
          &:first-child{
            margin-bottom:10px;
          }
        }
      }
      .right{
        top:0px;
        margin-top:15px;
      }
    }
    .accordian-loyal-box{
      .accordian-loyal-header{
        .inner-wrapper{
          .title{
            font-size: 16px;
          }
        }
      }
    }
  }
  .envelope-preview-box{
    .inner-width-wrapper{
      background-color:#ffffff;
    }
  }
  .invelope-preview-new{
    .vertical-scale-wrapper{
      width:34px;
      .inch-scale-box{
        width: 10px;
        &::before{
          font-size: 12px;
          top: -10px;
          right: -14px;
        }
        &:after{
          font-size: 12px;
        }
        .small-scale-line{
          width:5px;
        }
        .half-scale-line{
          width:10px;
        }
        &:nth-child(6){
          &::after{
            bottom: -9px;
            right: -14px;
          }
        }
      }
    }
    .horizontal-scale-wrapper{
      margin-left: 35px;
      height:34px;
      .inch-scale-box{
        height:10px;
        &:before{
          font-size: 12px;
          left: -11px;
          top: -19px;
        }
        &:after{
          font-size: 12px;
          top:-19px;
        }
        .half-scale-line{
          height:10px;
        }
        .small-scale-line{
          height:5px;
        }
      }
    }
  }
  // add-envelope-page-end

  // envelope-listing-page
  .direct__mail__card{
    &.envelope-card{
      .mobile__scroll__wrapper{
        height:calc(100vh - 210px);
        overflow-y:auto;
        padding-bottom: 40px;
        &::before{
          content: "";
          position: fixed;
          bottom: 92px;
          width: 100%;
          height: 50px;
          z-index: 2;
          background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .card-body{
        padding:20px 15px 20px 15px !important;
        height:auto;
      }
      .loadMore-btn-wrapper{
        padding:0px 15px;
        &::before{
          display:none;
        }
      }
    }
  }
  // envelope-listing-page-end

  // events-page
  .events-page{
    overflow:hidden;
    .card-header{
      background-color:transparent !important;
      padding:0px 15px 15px 15px;
      box-shadow:0px 4px 8px #d2d2d2;
      border-bottom:none;
    }
    & > .card-body{
      padding:18px 10px 15px 10px !important;
      height:calc(100vh - 203px);
      overflow-y:auto;
      &::before{
        content: "";
        position: fixed;
        bottom: 80px;
        width: 100%;
        height: 50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
      }
    }
    .full-calender-wrapper{
      padding-top:0px;
      .fc-toolbar-chunk{
        width:100%;
      }
      .fc-toolbar-chunk{
        &:first-child{
          margin-bottom:14px;
        }
      }
      .fc-header-toolbar{
        .fc-toolbar-chunk{
          .fc-button-group{
            .fc-button{
              width:calc(25% - 8px);
            }
          }
        }
      }
      .fc{
        .fc-scrollgrid-section-body{
          table{
            height:unset !important;
          }
        }
        .fc-daygrid-day{
          padding:2px 2px;
        }
      }
      .fc-daygrid-body{
        .fc-daygrid-day-number{
          font-size: 11px;
        }
        .fc-daygrid-day-top{
          width:22px;
          height:22px;
        }
      }
      .fc-daygrid-day-bottom{
        .fc-daygrid-more-link{
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
          display: inline-block;
        }
      }
      .fc-daygrid-day-events{
        .fc-daygrid-event-harness{
          .fc-daygrid-event{
            padding:2px 6px 2px 12px;
            border-radius: 5px !important;
            &::after{
              border-radius: 5px;
            }
            &::before{
              width: 3px;
              left:5px;
            }
            .fc-event-title-container,
            .fc-event-time{
              font-size:10px !important;
            }
          }
        }
      }
      .fc{
        .fc-view-harness{
          height:unset !important;
        }
      }
      .fc-list{
        .fc-list-table{
          tr{
            th{
              background-color:transparent !important;
              .fc-list-day-cushion{
                a{
                  font-size:14px;
                }
              }
            }
            .fc-list-event-time{
              padding-left:10px;
              width:100px;
            }
            .fc-list-event-graphic{
              padding-left:4px;
              padding-right:4px;
              width:20px;
              .fc-list-event-dot{
                width:6px;
                height:6px;
              }
            }
            .fc-list-event-title{
              width:calc(100% - 121px);
            }
          }
        }
      }
    }
    .fc-scrollgrid-section-header{
      .fc-col-header-cell{
        .fc-col-header-cell-cushion{
          padding:5px 2px;
          font-size:12px;
        }
      }
    }
  }
  // events-page-end

  // add-update-event-modal
  .add-update-event-modal{
    .modal-body{
      padding:20px 15px !important;
    }
    .event-details-wrapper,
    .schedule-events-wrapper{
      background-color:transparent;
      border-radius:0px;
      padding:0px 0px;
    }
    .schedule-events-wrapper{
      .col-md-3{
        width:100%;
      }
    }
    .contact-filters-wrapper{
      background-color:transparent;
      .contact-filter-heading{
        padding:0px 0px;
        background-color:transparent;
        .search-box-wrapper{
          width:calc(100% - 100px);
        }
        .more-filter-btn{
          background-color:#eeeeee;
          span{
            display:none;
          }
          &:hover{
            background-color:#000000;
            color:#ffffff;
            &::before{
              border-left: 2px solid #ffffff;
              border-bottom: 2px solid #ffffff;
            }
          }
        }
      }
      .filter-fields-wrapper{
        padding:15px 0px 0px 0px;
        margin-top:15px;
      }
    }
    .invite-contacts-wrapper{
      padding:0px 0px;
      background-color:transparent;
      margin-bottom:0px;
      .accordion-margin.contact-accordion{
        .accordion-item{
          .accordion-button{
            padding:8px 8px 8px 10px;
          }
        }
      }
    }
    .accordion-margin.contact-accordion{
      .accordion-item{
        .event-contact-list-wrapper{
          .event-contact-col{
            width:100% !important;
          }
        }
      }
    }
    .update-rsvp-form-new{
      .logo-name-radio-wrapper{
        flex-wrap:wrap;
        justify-content:flex-start;
        border-top: 1px solid #eee;
        padding-top: 18px;
        .inner-item{
          width:100%;
          &:last-child{
            justify-content:flex-start;
            margin-top:15px;
          }
        }
      }
    }
    .modal-footer{
      display:flex;
      justify-content:space-between;
      .calcel-btn{
        margin-left:0px !important;
        width:calc(50% - 6px) !important;
      }
      .auth-login-form{
        width:calc(50% - 6px);
        .add-event-btn{
          width:100% !important;
          margin-left:0px !important;
        }
      }
    }
  }
  // add-update-event-modal-end

  // moving-contact-pipline-modal
  .moving-contact-pipline-modal{
    .modal-footer{
      display:flex;
      justify-content:space-between;
      .btn{
        margin:0px !important;
        width:calc(50% - 6px) !important;
      }
    }
  }
  // moving-contact-pipline-modal

  // custom-pipeline
  .pipeline-page-wrapper.pipeline-page-wrapper-new.kanban-view-active{
    .pipeline-kanbanView-wrapper{
      .rightCN{
        width:100%;
        .pipline-stage-kanban-view-header{
          padding-left:14px;
          padding-right:14px;
          .title{
            width:calc(100% - 160px);
            margin-bottom:0px;
          }
        }
        .pipline-stage-kanban-scroll{
          padding-left:14px;
          padding-right:14px;
          .temp-board{
            .infinite-scroll-component{
              height:calc(100dvh - 355px) !important;
            }
          }
          .temp-board{
            .temp-board-column{
              margin-right:10px;
            }
          }
        }
      }
    }
  }
  // custom-pipeline-end

  // direct-mail-template-card
  .direct-mail-template-card{
    & > .card-header{
      position: relative;
      padding:10px 15px 15px 15px !important;
      .btns-wrapper{
        width:100%;
        display:flex;
        justify-content:space-between;
        .create-folder-link{
          width:calc(50% - 6px);
          text-align:center;
        }
        .add-btn-wrapper{
          width:calc(50% - 6px);
          .btn-primary{
            width:100%;
          }
        }
      }
      .btns-wrapper{
        margin-top: 22px !important;
      }
      .grid-card-view-toggle-btns{
        position: absolute;
        top: 0px;
        right: 15px;
      }
    }
    & > .card-body{
      height:calc(100dvh - 256px);
      padding:16px 0px 30px 0px !important;
      &::before{
        content: "";
        position: fixed;
        bottom: 68px;
        width: 100%;
        height: 50px;
        z-index: 4;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .direct-mail-accordian{
      background-color: #ffffff;
      border-radius:0px;
      margin-bottom:8px;
      &:last-child{
        margin-bottom:0px;
      }
      .direct-mail-accordian-item{
        .accordion-button{
          padding-right:7px;
          padding-left:10px;
          padding-top:9px;
          padding-bottom:9px;
        }
      }
    }
  }
  // direct-mail-template-card-end

  // mass-email-template-card
  .mass-email-template-card{
    & > .card-header{
      .right{
        width:100%;
        display:flex;
        justify-content:space-between;
        margin-top: 25px;
        .btns-wrapper{
          margin-top:0px !important;
          width:calc(50% - 6px);
          .create-folder-link{
            width:100%;
          }
        }
        .btn-primary{
          margin-top:0px !important;
          margin-left:0px !important;
          width:calc(50% - 6px) !important;
        }
        .grid-card-view-toggle-btns{
          position:absolute;
          top:0px;
          right:12px;
        }
      }
    }
    & > .card-body{
      height:calc(100dvh - 252px);
      padding:16px 0px 30px 0px !important;
      &::before{
        content: "";
        position: fixed;
        bottom: 79px;
        width: 100%;
        height: 50px;
        z-index: 4;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .mass-email-template-accordian{
      background-color:#ffffff;
      border-radius:0px;
      margin-bottom:8px;
      &:last-child{
        margin-bottom:0px;
      }
      .mass-email-template-accordian-item{
        .accordion-button{
          padding-right:7px;
          padding-left:10px;
          padding-top:9px;
          padding-bottom:9px;
        }
      }
      .accordion-body{
        padding-top:14px;
      }
    }
  }
  // mass-email-template-card-end

  .send-SMS-modal{
    .modal-footer{
      display:flex;
      align-items:center;
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
        margin:0px !important;
      }
    }
  }

  // super-admin-css-start
  .compay__list__superAdmin{
    overflow:hidden;
    .card-header{
      background-color:transparent !important;
      padding:0px 15px 15px 15px;
      box-shadow: 0px 4px 8px #d2d2d2;
      position: relative;
      z-index:4;
      .card-title{
        font-size:22px !important;
        margin-bottom:12px !important;
      }
      .header__btns{
        justify-content:space-between;
        width:100%;
        .btn-group{
          width:calc(50% - 6px);
          .dropdown-toggle{
            width:100%;
          }
        }
        .btn-primary{
          width:calc(50% - 6px) !important;
          margin:0px !important;
          padding-left:10px;
          padding-right:10px;
        }
      }
    }
    .search-box-wrapper{
      .new-tab-details-design{
        width:100%;
        margin-bottom:14px;
        .horizontal-new-tab-wrapper{
          .horizontal-tabbing{
            .nav-item{
              .nav-link{
                &:hover{
                  background-color:#ffffff;
                }
              }
            }
          }
        }
      }
      .form-element-icon-wrapper{
        width:100%;
      }
    }
    .search-box-wrapper-contactList{
      margin-top:10px !important;
    }
    .card-body{
      padding-left:15px;
      padding-right:15px;
      height: calc(100dvh - 230px);
      overflow-y: auto;
      padding-bottom:32px;
      &::before{
        content: "";
        position: fixed;
        bottom: 70px;
        width: 100%;
        height: 50px;
        z-index:5;
        background-image: linear-gradient(rgba(247, 246, 240, 0), #f7f6f0);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .add-update-company-superAdmin{
    .top_header{
      background-color:transparent !important;
      border-top-left-radius:0px;
      border-top-right-radius:0px;
      position: relative;
      padding-bottom: 55px;
      overflow: visible;
      box-shadow: 0px 4px 8px #d2d2d2;
      z-index:4;
      .back-arrow{
        top: -4px;
        &:hover{
          background-color:#ffffff;
        }
      }
      .card-title{
        font-size:22px !important;
      }
      .new-tab-details-design{
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        .horizontal-new-tab-wrapper{
          .horizontal-tabbing{
            .nav-item{
              width:50%;
              .nav-link{
                padding:14px 15px;
                margin-right: 0px;
                &:hover{
                  background-color:transparent;
                }
              }
              &:last-child{
                margin-right:0px;
              }
            }
          }
        }
      }
    }
    .company-detail-card{
      padding: 15px 15px 55px 15px !important;
      height: calc(100dvh - 235px);
      overflow-y: auto;
      &::before{
        bottom: 80px;
        z-index:4;
      }
      .company-detail-row{
        .company-info{
          .auth-login-form{
            display:block;
          }
          .status_column{
            .inner-ci-field{
              position: relative;
              top: 4px;
              .form-check-input{
                top:0px;
              }
            }
          }
        }
      }
    }
   
    
    .permission-wrapper{
      .accordian-loyal-box{
        background-color:#ffffff;
      }
    }
    .sender-emails{
      padding:0px 0px 0px 0px;
      .card-header{
        display:block;
        height: 0px;
        margin-bottom: 6px;
        .card-title{
          display:none;
        }
        .card-title + div{
          margin-top:0px !important;
        }
        .btn-group{
          margin:0px !important;
        }
        .btn-primary{
          border-radius: 50px !important;
          left: 50% !important;
          transform: translateX(-50%);
          position: fixed !important;
          bottom: 88px !important;
          z-index:7;
        }
      }
      .search-box-wrapper{
        width:100%;
      }
      .rdt_Table_wrapper{
        margin-top:0px;
      }
    }
  }

  .add-sender-email-modal{
    .modal-footer{
      display:flex;
      align-items:center;
      justify-content:space-between;
      .btn{
        width:calc(50% - 6px) !important;
      }
      form{
        width:calc(50% - 6px);
        margin:0px !important;
        .btn{
          width:100% !important;
        }
      }
    }
  }
  // super-admin-css-end

  .available-notes-wrapper {
    .search-note {
      width: 100%; 
    }
    .btns-wrapper {
      flex-wrap: wrap;
      .create-folder-link { 
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .notes-filter {
        width: 100%;
        .custom_select_wrapper {
          max-width: 100%;
          div#custom_select_box_id {
            > .notes-custom-select {
              width: 100%;
            }
          }          
        }
      }
    }
  }

  .accordion-item {
    .checklist-header {
      .title {
        max-width: calc(100% - 200px);
      }
      .action-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 190px;
        .action-btn {
          width: 27px;
          height: 27px;
          padding: 5px;
        }
      }

    }
  }
  .auth-login-form {
    .header__title {
      width: calc(100% - 140px);
    }
    .attachment-btns {
      width: 195px;
      justify-content: flex-end !important;
      .attachment-SpeechToText-wrapper {
        .btn {
          padding: 0.786rem 13px;
        }
        .save-btn {
          width: inherit !important;
        }
      }
    }    
  }
  .contact__card__wrapper {
    .contactDocumentPage {
      .documentHeader {
        .add-document-btn {
          padding: 0.786rem 10px;
        }
      }
    }
    .contact-personalInfo-wrapper {
      .right-tabContant-wrapper {
        .fixed-button-wrapper {
          #add-contact-btn {
            min-width: 160px;
          }
        }
      }
    }
  }
  .add-question-modal {
    .modal-footer {
      .btn {
        width: inherit;
        min-width: 120px;
      }
    }
  }
  .add-update-note-modal {
    .attachment-SpeechToText-wrapper {
      position: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .btn {
        position: initial;
        margin: 15px 10px;
      }
    }
  }


}

@media only screen and (max-width: 575px) {
  .contact__card__wrapper{
    .contactDocumentPage{
      .documentListingRow{
        .documentListingCol{
          width:100%;
        }
      }
    }
  
  }
  // contact-notes-tabPane
  .new-tab-details-design {
    .contact-notes-tabPane {
      .contact-notes {
        .accordion-item {
          .attachment-wrapper {
            .file__card {
              width: 33.3333% !important;
              max-width: 33.3333% !important;
            }
          }
        }
      }
    }
    .company-task-manager-col{
      margin-bottom:12px;
      .company-task-manager-card{
        background-color:transparent;
        // border-bottom:1px solid #eaeaea;
        border-radius:0px;
        padding-bottom:12px;
        position: relative;
        &:before{
          content:"";
          position:absolute;
          bottom:0px;
          left:-15px;
          width:calc(100% + 30px);
          height:1px;
          background-color:#eaeaea;
        }
        .inner-card{
          padding:0px 30px 0px 0px;
        }
        .form-check-input{
          right:0px;
        }
      }
      &:last-child{
        margin-bottom:18px;
        .company-task-manager-card{
          padding-bottom:0px;
          border:none;
          &:before{
            display:none;
          }
        }
      }
    }
  }
  // contact-notes-tabPane-end

  // connect-IMAP-modal
  .connect-IMAP-modal{
    .imap__platform__wrapper{
      justify-content:space-between;
      button{
        margin-right:0px;
        width:calc(50% - 8px);
      }
    }
  }
  // connect-IMAP-modal-end

  // integration-card
  .integration-card{
    .integration-box{
      &.email-connection{
        .connect-wrapper{
          .details{
            align-items:flex-start;
            .icon-wrapper{
              width:36px;
              height:36px;
              padding:7px;
            }
            .details-text{
              padding-left:14px;
              width:calc(100% - 37px);
              .title{
                font-size:16px;
              }
              .text{
                font-size:14px;
              }
            }
          }
        }
      }
    }
  }
  // integration-card-end

  // company-user-details-page
  .user-details-page-new{
    .company-task-manager-row{
      .company-task-manager-col{
        width:100%;
      }
    }
    .permission-wrapper{
      .accordian-loyal-box{
        .accordian-loyal-body{
          .permission-row-wrapper{
            .permission-row{
              width:100%;
            }
          }
        }
      }
    }
  }
  // company-user-details-page-end

  // single-note-page
  .single-note-page{
    .attachment-wrapper{
      margin:0px -5px;
      .file__card{
        padding:0px 10px;
      }
    }
  }
  // single-note-page-end
  // pipline-contact-info-modal
  .pipline-contact-info-modal{
    .modal-body{
      & > .contact-info-box{
        padding:0px 0px;
      } 
    }
    .contact-info-wrapper{
      .contact-info-box{
        width:100%;
      }
    }
  }
  // pipline-contact-info-modal-end
}

@media only screen and (max-width:480px){
  // feature-request-preview-modal, support-ticket-preview-modal
.feature-request-preview-modal,
.support-ticket-preview-modal{
  .scroll-wrapper{
    padding:0px 15px;
  }
  .user-name{
    padding-left:15px;
  }
  .double-row-data{
    .data-row-new{
      width:100%;
    }
  }
  .data-row-new{
    &.attachment{
      .file__drop__zone_wp{
        .file-col{
          width:50%;
        }
      }
    }
  }
}
// feature-request-preview-modal,support-ticket-preview-modal end
}
// responsive-end

// print-css
@media print {
  @page{
    margin: 46px 46px 46px 46px;
  }

  .direct-mail-postcard-print-wrapper{
    .logo-wrapper{
      max-width:300px;
      max-height:350px;
      object-fit:contain;
      object-position:left;
      padding-bottom:20px;
      overflow:hidden;
    }
  }
}
// print-css-end

// error
.error {
  width: 100%;
  font-size: 0.857rem;
  color: #ea5455;
  margin-top: 0.25rem;
}

// Highlighted Text Color
.highlight-text-color {
  background-color: var(--primaryColor);
}
// input-helper-text
.input-helper-text {
  color: var(--bs-gray-600);
}

// ===========================================================================================
.mentions {
  margin: 1em 0;
  width: 100%;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
  border: 1px solid silver;
}
.mentions--multiLine .mentions__highlighter {
  padding: 9px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  min-height: 63px;
  outline: 0;
  border: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

// .suggestion-list {
//   list-style: none;
//   padding: 0;
//   margin: 4px 0;
//   border: 1px solid #ccc;
//   max-height: 150px;
//   overflow-y: auto;
//   background-color: #fff;
// }

// .suggestion-list li {
//   padding: 8px;
//   cursor: pointer;
// }

// .suggestion-list li:hover {
//   background-color: #f0f0f0;
// }

@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

// ===========================================================================================


// temp-css
.footer{
  & > p{
    span:nth-child(2){
      display:none;
    }
  }
}

.direct-mail-template-folder{
  display: flex;
  flex-wrap: wrap;
}

// Task manager Loader css


.task-overlay .overlay {
  background: #fff !important;
}

.task-overlay .loader {
  top: 15% !important;
}

.task-overlay .task__top__details__col {
  opacity: 0;
  visibility: hidden;
}

.task-overlay .e-richtexteditor .e-rte-content .e-content {
  opacity: 0;
  visibility: hidden;
}

.rightCN {
  & .full-width{
    width:100% !important;
  }
  .custom-width {
    width: 300px !important;
  }
}

.rightCN.full-width{
  .dropdown-toggle{
    margin-right :12px !important
  }
}

.rightCN.pipeline-custom-margin{
  .dropdown-toggle{
    margin-right :12px !important
  }
}

.card-header .custom-arw svg{ display: none;}

.custom-card-header.card-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between; 
  padding-left: 60px;
}

.custom-card-header.card-header div#goback {
  width: 38px;
  height: 38px;
  font-size: 0px;
  position: absolute;
  transition: 0.5s;
  border-radius: 50%;
  cursor: pointer;
  left: 10px;
}

.custom-card-header.card-header div#goback:before {
  content: '';
  position: absolute;
  top: 13px;
  width: 12px;
  height: 12px;
  border-bottom: 3px solid #000;
  left: 15px;
  border-left: 3px solid #000;
  transform: rotate(45deg);
}

.custom-card-header.card-header div#goback svg {
  display: none;
}
.custom-card-header.card-header .card-title { margin-bottom: 0;}
.custom-card-header.card-header .active-inactive-toggle { padding-right: 15px;}
.custom-card-header.card-header .active-inactive-toggle label { margin-bottom: 0; margin-right: 7px; color: #000000;}

form.report-problem-form {max-width: 600px;margin: 0 auto;padding: 0 22px;}
.report-problem-form input[type=radio] {cursor: pointer;}
.report-problem-form .radio-btn-wrapper { margin-bottom: 1rem !important;}
.submit-btn-wrapper button { width: 150px;}


.show-checklist-short-title { display: inline-block;width: 310px;white-space: nowrap;overflow: hidden !important;text-overflow: ellipsis; font-weight: 700; color: #000000;}
.show-checklist-full-title {
  color: #000000;
  font-weight: 700;
}

// 08-01-2024

.full-width {
  width: 100%;
}
.contact-notes-tabPane {
  .no-margin {
    margin-top: 0 !important;
  }
}
.print-action-wrapper {
  .btn-group {
    margin-right: 20px;
  }
}
.search-note, .print-action-wrapper {
  margin-bottom: 15px;
}
.search-note {
  margin-right: 15px;
}

// 29-12-2023
.pipline-contact-info-modal  { 
  .contact-info-box {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
.radio-switch-toggle-btn-wrapper  {
  .radio-btn-repeater {
    margin-right: 15px !important;
  }
}
.funnel-sec:last-child, .funnel-sec:nth-child(2) {
  align-items: center;
  .funnel-cn-wrapper {
    margin-top: 0;
    label {
      margin-bottom: 0;
    }
  }
}
// .horizontal-tabbing.add-update-contact-tab {
//   margin-right: 190px;
// }
.bottom-sticky-btn {
  position: relative;
  top: 48px;
  z-index:4;
}
.vertical-tab-item.archive-btn {
    background-color: var(--primaryColor);
    text-align: center;
    margin-bottom: 0px !important;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s;
    padding: 9px 13px;
    cursor: pointer;
    border-bottom: none;
    border-radius: 6px;
    color: #000000;
    width: 100%;
}
.add-task-modal-new.update-task-modal  {
  .modal-header{
    .timeCol {
      .start-stop-time-wrapper  {
        .timer-wrapper  {
          .time {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}
.taskKanbanView-scroll {
  .spinner-border {
    top: 50%;
    position: absolute;
    left: 48%;
  }
}
.add-task-modal-new.only-add-task-modal {
  .subTask-tab {
    .loyal-task-manager {
      .task-manager-main-accordion {
        
          min-height: 200px !important;
        
      }
    }
  }
}
.no-border {
  padding-top: 0 !important;
  &:before { content: none !important;}
}

// HELLO
// .new-tab-details-design {
//   .spinner-border {
//     top: 50%;
//     position: absolute;
//     left: 48%;
//   }
// }

// MJ_TEMP_FIX
// .new-tab-details-design.user-details-page-new{
//   .spinner-border {
//     left: 0;
//     position: relative;
//   }
// }

.new-tab-details-design .contact-notes-tabPane .notes-list-scroll-area {
  height: calc(100vh - 350px);
  max-height: initial;
}

// 18-01-2024
.contact-notes {
  .accordion-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left-dynamic-content {
      width: 48%;
      > div {
        padding: 7px 0;
        label {
          &:first-child {
            padding-right: 20px;
            width: 220px;
          }
          .file__card {
            margin-left: 0 !important;
            margin-right: 0 !important;
          }
        }
      }
    }
    .right-dynamic-content {
      width: 48%;
      td {
        padding: 0 !important;
      }
      p {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

@media(max-width: 991px){
  .contact-notes {
    .accordion-wrapper {
      .left-dynamic-content, .right-dynamic-content {
        width: 100%;
      }
      .right-dynamic-content {
        margin-top: 30px;
      }
    }
  }

}

// 23-01-2023

.note-wrapper {
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  .note {
    // width: 100px;
    margin-bottom: 20px;
    label {
      color: #000;
    }
    .note-inner {
      display: block;
      margin-top: 0;
      color: #000;
    }
  }
  .author-date {
    // width: calc(100% - 100px);
  }
}

@media(max-width: 767px){
  .task-title, .task-notification-wrap { 
    max-width: 600px;
  }
  .task-notification-wrap {
    margin-top: 10px;
  }
  .task-notification-wrap, .task-title {
    .form-label {
      font-size: 12px;
    }
  }
  .user-details-page-new .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .task-notification input#email {
    left: -115px;
  }
  .task-notification input#platform {
    left: -100px;
  }
  
}

@media(max-width: 600px){
  .task-notification, .task-inner > label { padding-left: 0;}
}

// d-css-start
// task-manager-new-css
.task-manger-loader{
  background-color: white; 
}
.task__list__container{
min-width: 1200px !important;
overflow-x:hidden !important;
}
.task-manager-row{
  border-bottom: 1px solid #ebe9f1;
  position: relative;
  // &:last-child{
  //   border-bottom:none;
  // }
  &.pin-row {
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--primaryColor);
      opacity: 0.05;
    }
  }

  .inner-wrapper {
    position: relative;
    // z-index:0;
  }

  &:hover {
    box-shadow: 0px 0px 6px #c6c6c6;
    position: relative;
    z-index: 4;
  }

  .task-manager-title {
    display: flex;
    justify-content: space-between;
    padding: 8px 20px 8px 0px;

    .right {
      display: flex;
      align-items: center;
    }

    .left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // width:calc(100% - 792px);
      width: calc(100% - 840px);
    }
  }

  .move-icon-cell {
    padding-left: 5px;
    width: 35px;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: auto;
      display: block;
    }
  }

  .task-number-cell {
    padding: 0px 13px 0px 5px;
    width: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .task-name-cell {
    width: calc(100% - 135px);
    padding-right: 10px;
    cursor: pointer;
  }

  .move-icon-wrapper {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;

    .drag-icon {
      position: absolute;
      top: 50%;
      width: 20px;
      transform: translate(-50%, -50%);

      &:first-child {
        left: calc(50% - 4px);
      }

      &:last-child {
        left: calc(50% + 4px);
      }
    }
  }

  .task-number {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
  }

  .task-name-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .task-name {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      color: grey;
      font-size: 14px;
      padding-right: 12px;
      white-space: pre;
      overflow: hidden;
      &.inlineEditing__On{
        width:calc(100% - 48px);
        padding-right:10px;
      }
      input.form-control{
        width:100%;
        margin-bottom:0px !important;
      }

      .inner-wrapper {
        display: inline-block;
        max-width: 100%;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 22px;
      }
    }

    .badge__wrapper{
      margin-right:8px;
    }

    .subtask-count {
      font-size: 14px;
      font-weight: 500;
      color: var(--primaryColorDark);
      position: relative;
      background-color: transparent !important;
      padding: 6px 6px;
      flex-shrink: 0;
      min-width:24px;

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background-color: var(--primaryColor);
        opacity: 0.2;
      }

      .inner-wrapper {
        position: relative;
        z-index: 2;
      }
    }
  }

  .contact-wrapper {
    display: inline-block;

    .avatar-content {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 13px;
    }

    &>div {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .task-priority {
    cursor: pointer;

    .task-priority-toggle {
      padding: 4px 7px 4px 11px;
      border-radius: 7px;
      display: inline-flex;
      align-items: center;
      width: 100px;
      font-size: 14px;

      .text {
        width: calc(100% - 18px);
        padding-right: 4px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        display: none;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .task-status {
    cursor: pointer;

    .task-status-toggle {
      padding: 4px 7px 4px 11px;
      border-radius: 7px;
      display: inline-flex;
      align-items: center;
      width: 100px;

      .text {
        width: calc(100% - 18px);
        padding-right: 4px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      &:after {
        display: none;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .task-date {
    font-size: 15px;
    color: #000000;
    display: inline-flex;
    font-weight: 600;

    input.form-control {
      border: none;
      padding: 5px 0px;
      width: 100%;
      text-align: center;
      box-shadow: unset !important;
      font-size: 15px;
      color: #000000;
      font-weight: 600;
    }

    .flatpickr-calendar {
      display: none !important;
      top: calc(100% - 3px) !important;
      z-index: 5;
      left: -71px;
    }

    .flatpickr-wrapper {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 11px;
        right: 10px;
        width: 7px;
        height: 7px;
        border-left: 1px solid #000000;
        border-bottom: 1px solid #000000;
        transform: rotate(-45deg);
        opacity: 0;
        display: none;
      }

      &:hover {
        .flatpickr-calendar {
          display: block !important;
          opacity: 1 !important;
          visibility: visible !important;
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }

  .task-assignee-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .task-assignee-toggle {
      display: flex;
      align-items: center;
      align-items: center;

      &:after {
        display: none;
      }
    }

    .avatar-group {
      display: inline-flex;

      .avatar {
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .avatar-content {
          width: 100% !important;
          height: 100% !important;
          background-color: var(--primaryColor) !important;
          color: #000000;
          font-size: 12px;
        }

        &:not(:first-child) {
          margin-left: -10px;
        }

        &:hover {
          transform: translateY(-4px) scale(1.07) !important;
        }
      }
    }

    .down__icon {
      width: 20px;
      height: 20px;
      position: relative;
      top: 1px;
      margin-left: 1px;
      cursor: pointer;
      opacity: 0;
      transition: 0.5s;
    }

    &:hover {
      .down__icon {
        opacity: 1;
      }
    }
  }

  .pitask-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.5s;
    padding: 4px;
    cursor: pointer;

    svg {
      margin-right: 0px !important;
      width: 100% !important;
      height: 100% !important;
      color: #a6a6a6 !important;
    }

    &:hover {
      background-color: #e3e3e3;

      svg {
        color: #000000 !important;
      }
    }

    &.active {
      svg {
        color: var(--primaryColor) !important;
      }
    }
  }

  .task-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:checked {
      background-color: var(--primaryColor);
    }
  }

  .task-action-wrapper {
    .task-action-icon-wrapper {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      transition: 0.5s;
      padding: 5px;
      cursor: pointer;

      svg {
        margin-right: 0px !important;
        width: 100% !important;
        height: 100% !important;
        color: #a6a6a6 !important;
      }

      &:hover {
        background-color: #e3e3e3;

        svg {
          color: #000000 !important;
        }
      }
    }
  }

  .down-up-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: 0.5s;
    padding: 4px;
    cursor: pointer;

    svg {
      margin-right: 0px !important;
      width: 100% !important;
      height: 100% !important;
      color: #a6a6a6 !important;
    }

    &:hover {
      background-color: #e3e3e3;

      svg {
        color: #000000 !important;
      }
    }
  }

  .contact-cell,
  .task-priority-cell,
  .task-status-cell,
  .task-date-cell,
  .task-assignee-cell,
  .task-snooze-cell,
  .task-pintask-cell,
  .task-checkbox-cell,
  .task-action-cell,
  .task-update-cell {
    padding: 0px 7px;
    .sortHead__wrapper{
      position: relative;
      left:6px;
    }
  }

  .contact-cell {
    width: 98px;
    display: flex;
    justify-content: center;
    & > .title{
      display:none;
    }
    .contact-wrapper{
      width:53px;
      .custom-select__control{
        padding-left:0px;
        border:none;
        border-radius:0px;
        flex-wrap:nowrap;
        width:53px !important;
        .custom-select__value-container{
          flex:unset;
          display:block;
          .custom-select__input-container{
            display:none;
          }
        }
        .avatar{
          width:32px;
          height:32px;
        }
        .custom-select__indicators{
          opacity:0;
          .custom-select__indicator-separator{
            display:none;
          }
          .custom-select__indicator{
            margin-right:0px;
          }
        }
        &:hover{
          .custom-select__indicators{
            opacity:1;
          }
        }
        &.custom-select__control--menu-is-open{
          .custom-select__indicators{
            opacity:1;
          }
          &:hover{
            .custom-select__indicators{
              opacity:1;
            }
          }
        }
      }
      .custom-select__menu{
        width:300px;
      }
    }
  }

  .task-priority-cell,
  .task-status-cell {
    width: 114px;
    display: flex;
    justify-content: center;
  }

  .task-date-cell {
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .task-assignee-cell {
    width: 112px;
    display: flex;
    justify-content: center;
  }

  .task-pintask-cell {
    width: 46px;
  }

  .task-snooze-cell {
    width: 46px;

    .task-snooze-btn {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      border-radius: 5px;
      padding: 4px;
      cursor: pointer;

      svg {
        width: 100% !important;
        height: 100% !important;
        position: relative;

        // left:2px;
        path {
          fill: #6e6b7b;
        }
      }

      &:hover {
        background-color: #e3e3e3;

        svg {
          path {
            fill: black;
          }
        }
      }
    }

    &.not-icon {
      .task-snooze-btn {
        padding: 6px;
      }
    }
  }

  .task-update-cell {
    width: 46px;

    .task-update-btn {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;

      svg {
        width: 100% !important;
        height: 100% !important;
        position: relative;
        // left:2px;
      }

      &:hover {
        background-color: #e3e3e3;
      }
    }
  }

  .task-checkbox-cell {
    width: 34px;
  }

  .task-action-cell {
    width: 46px;
  }

  .down-up-btn-cell {
    width: 32px;
  }

  .subtask-manager-row{
    .task-manager-table-header {
      .task-manager-row {
        background-color: transparent;
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: var(--primaryColor);
          opacity: 0.2;
        }

        .task-manager-title {
          position: relative;

          .right {
            .subtask-add-btns {
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
              display: flex;
              align-items: center;

              .action-btn {
                width: 34px;
                height: 34px;
                transition: 0.5s;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 7px;
                border-radius: 50%;
                cursor: pointer;

                svg {
                  width: 100%;
                  height: 100%;
                  color: #000000;
                }

                &:hover {
                  background-color: #ffffff;
                }

                &:first-child {
                  margin-right: 4px;
                  padding: 6px;
                }
              }
            }
          }
        }
      }
    }

    .accordion-body {
      padding-left: 0px;
      padding-top:0px;
      padding-bottom:0px;
      position: relative;
      background-color: transparent;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right,
            var(--primaryColor),
            rgba(163, 219, 89, 0));
        opacity: 0.09;
      }

      .task-manager-table-header,
      .subtask-add-btns-wrapper,
      .subtask-row-wrapper {
        position: relative;
        z-index: 3;
      }
      & > div:last-child{
        width:102% !important;
        .task-manager-row{
          width:99% !important;
          .task-manager-title{
            .right{
              padding-right:0px;
            }
          }
        }
        .task-manager-table-header{
          .task-manager-row{
            .task-manager-title{
              padding:14px 19px 14px 0px;
            }
          }
        }
      }
    }
    
    .subtask-add-btns-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;

      .new-subtask-btn,
      .new-subtask-details-btn {
        width: 180px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      .new-subtask-btn {
        margin-right: 6px;
      }

      .new-subtask-details-btn {
        margin-left: 6px;
      }
    }
  }
}

// mobile-task-manager-design-below-767px
.mobile-task-manager-row {
  // display: flex;
  flex-wrap: wrap;
  // padding-top:20px;
  display: none;
  padding: 0px 15px;

  .task-manager-row {
    background-color: #ffffff;
    border-radius: 14px !important;
    box-shadow: unset;
    margin-bottom: 16px !important;
    border: 1px solid #ddd;
    width: 100%;

    &:last-child {
      margin-bottom: 0px;
    }

    &:before {
      display: none;
    }

    .inner-wrapper {
      position: relative;
      z-index: 3;

      .task-manager-title {
        padding: 16px 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .top-header,
        .taskName-wrapper,
        .task-priority-status,
        .contact-date-wrapper,
        .assignee-subtask-wrapper {
          width: 100%;
        }

        .top-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: -7px;
          width: calc(100% + 7px);

          .left-wrapper {
            display: inline-flex;
            align-items: center;

            .move-icon-cell {
              border-radius: 50%;
              width: 34px;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-left: 0px;
              margin-right: 6px;

              &:hover {
                background-color: #f2f2f2;

                .drag-icon {
                  color: #000000;
                }
              }
            }

            .task-number {
              font-size: 16px;
              font-weight: 600;
              color: #000000;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              display: inline-block;
            }
          }

          .right-action {
            display: flex;
            align-items: center;

            .task-checkbox-wrapper {
              display: flex;
              margin-right: 10px;

              .switch-checkbox {
                input {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  z-index: 2;
                }

                .switch-design {
                  width: 52px;
                  height: 26px;

                  &:before {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }

          .action-btn-wrapper {
            display: flex;
            align-items: center;

            .action-btn {
              width: 32px;
              height: 32px;
              margin-right: 3px;

              &:last-child {
                margin-right: 0px;
              }

              .inner-wrapper {
                width: 100%;
                height: 100%;

                svg {
                  width: 100%;
                  height: 100%;
                  color: var(--bs-body-color);
                }
              }

              .pitask-btn {
                width: 100%;
                height: 100%;

                svg {
                  width: 100%;
                  height: 100%;
                  color: var(--bs-body-color) !important;
                }
              }

              .task-action-wrapper {
                width: 100%;
                height: 100%;

                .task-action-icon-wrapper {
                  width: 100%;
                  height: 100%;

                  svg {
                    width: 100%;
                    height: 100%;
                    color: var(--bs-body-color);
                  }
                }
              }

              &.task-snooze-btn {
                .inner-wrapper {
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px;
                  cursor: pointer;

                  svg {
                    path {
                      fill: var(--bs-body-color);
                    }
                  }

                  &:hover {
                    background-color: #f2f2f2;

                    svg {
                      path {
                        fill: #000000;
                      }
                    }
                  }
                }
              }

              &.task-update-btn {
                .inner-wrapper {
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px;
                  cursor: pointer;

                  &:hover {
                    background-color: #f2f2f2;

                    svg {
                      color: #000000;
                    }
                  }
                }
              }

              &.task-pintask-btn {
                .pitask-btn {
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px;
                  cursor: pointer;

                  svg {
                    path {
                      fill: transparent;
                      stroke-width: 2px;
                      stroke: var(--bs-body-color);
                    }
                  }

                  &:hover {
                    background-color: #f2f2f2;

                    svg {
                      path {
                        fill: transparent;
                        stroke-width: 2px;
                        stroke: #000000;
                      }
                    }
                  }

                  &.active {
                    svg {
                      path {
                        fill: var(--primaryColor);
                        stroke-width: 2px;
                        stroke: var(--primaryColor);
                      }
                    }
                  }
                }
              }

              &.task-toggle-btn {
                .task-action-icon-wrapper {
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px;
                  cursor: pointer;

                  svg {
                    color: var(--bs-body-color) !important;
                  }

                  &:hover {
                    background-color: #f2f2f2;

                    svg {
                      color: #000000 !important;
                    }
                  }
                }
              }
            }
          }
        }

        .taskName-wrapper {
          margin-top: 10px;
          margin-bottom: 10px;

          .task-name {
            // font-size: 22px;
            font-weight: 600;
            color: #000000;
            display: block;
            line-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
        }

        .task-priority-status {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          &>.task-priority,
          &>.task-status {
            display: none;
          }

          .mobile-task-priority-wrapper {
            margin-right: 6px;
            display: block !important;
          }

          .mobile-task-status-wrapper {
            display: block !important;
            margin-left: 6px;
          }

          .mobile-task-priority-wrapper,
          .mobile-task-status-wrapper {

            .task-priority,
            .task-status {
              width: 100%;
            }

            max-width:calc(50% - 6px);

            .badgeLoyal-wrapper {
              display: inline-flex;
              align-items: center;
              // padding: 4px 30px 6px 31px;
              padding: 4px 10px 6px 28px;
              border-radius: 8px;
              position: relative;
              width: 100%;
              pointer-events: none;

              &::after {
                display: none;
              }

              .dot {
                width: 11px;
                height: 11px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 6px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                z-index: 2;
              }

              .name {
                display: inline-block;
                font-size: 11px;
                font-weight: 500;
                position: relative;
                z-index: 2;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre;
              }

              .bg-wrapper {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                border-radius: 100px;
              }

              .border-wrapper {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                border-radius: 100px;
              }

              .down-arrow-btn {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 6px;
                cursor: pointer;
                display: none;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .contact-date-wrapper {
          display: flex;
          margin-bottom: 20px;

          .contact-wrapper {
            width: 50%;
            padding-right: 8px;

            .label {
              width: 100%;
              font-size: 12px;
              font-weight: 500;
              color: #000000;
              margin-bottom: 3px;
              height: auto;
            }

            .contact-details {
              width: auto;
              height: auto;
              display: flex;
              align-items: center;
            }

            .contactImg {
              width: 30px;
              height: 30px;
              margin-right: 8px;
              flex-shrink: 0;

              a {
                display: block;
                width: 100%;
                height: 100%;

                .avatar {
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-color: var(--primaryColor) !important;

                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                  }

                  .avatar-content {
                    width: 100%;
                    height: 100%;
                    font-size: 11px;
                    color: #ffffff !important;
                  }
                }
              }
            }

            .contactName {
              font-size: 11px;
              font-weight: 500;
              width: 100%;
              display: inline-block;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .date-wrapper {
            width: 50%;
            padding-left: 8px;

            .label {
              width: 100%;
              font-size: 12px;
              font-weight: 500;
              color: #000000;
              margin-bottom: 10px;
            }

            .value {
              display: flex;
            }

            .start-date,
            .end-date {
              width: 72px;
              display: inline-block;
              pointer-events: none;
              font-size: 12px;
              color: var(--bs-body-color);
              font-weight: 500;

              .form-control {
                padding: 0px 0px;
                font-size: 11px;
                color: var(--bs-body-color);
                font-weight: 500;
                height: auto;
                border: none;
                width: 72px;
              }
            }

            .end-date {
              position: relative;

              &::before {
                content: "";
                position: absolute;
                top: 7px;
                left: -16px;
                width: 6px;
                height: 2px;
                background-color: var(--bs-body-color);
              }
            }
          }
        }

        .assignee-subtask-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #e8e8e8;
          padding-top: 18px;
          padding-bottom: 4px;

          .assignee-wrapper {
            display: inline-block;

            .show>.dropdown-menu.task-assignee-dropdown {
              display: none !important;
            }

            .task-assignee-wrapper {
              .down__icon {
                display: none;
              }
            }
          }

          .subtask-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;

            .label {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              color: #000000;
            }

            .subtask-count {
              padding: 5px 10px;
              background-color: var(--primaryColor) !important;
              color: #000000;
              font-size: 14px;
              margin-left: 7px;
            }
          }
        }
      }
    }
  }
}
.task-manager-body{
  &.archived,
  &.trash{
    .mobile-row-wrapper{
      .task-manager-row{
        .inner-wrapper{
          .task-manager-title{
            .top-header{
              margin-left:0px;
              .left-wrapper{
                .move-icon-cell{
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }
}
// mobile-task-manager-design-end
// task-manager-new-css-end

@media (max-width: 991px){
  .header-navbar{
    .company-details{
      display:none;
    }
  }
}
@media (max-width: 767px){
  .header-navbar{
    .navbar-nav{
      position: relative;
      justify-content:flex-end;
      padding-left:0px;
      .dropdown-user{
        width:auto;
        max-width: 160px;
      }
      .navbar-search{
        position:absolute;
        top:0px;
        left:0px;
      }
    }
    .user-details{
      .dropdown-user-link{
        width:100%;
        .img-wrapper{
          flex-shrink:0;
        }
        .right{
          width:auto;
          max-width: calc(100% - 38px);
        }
      }
    }
  }

  .task__list__container{
    min-width:unset !important;
  }
  .task-manager-row{
    display:none;
  }
  .mobile-task-manager-row{
    display:flex;
    .task-manager-row{
      display:block;
    }
  }
  .task-manger-loader{
    background-color:transparent;
  }
  .loyal-task-manager {
    .content-right{
      .taskRowlistingHeightWrapper{
        height: calc(100dvh - 244px) !important;
        position:relative;
        padding-top:20px;
        &:before{
          content: "";
          position: fixed;
          bottom: 80px;
          width: 100%;
          height: 40px;
          z-index: 9;
          background-image: linear-gradient(rgba(247,246,240,0),#f7f6f0);
          left: 50%;
          transform: translateX(-50%);
        }
        .taskRowDataLoader{
          background-color:transparent;
          bottom:10px;
          width:100%;
        }
      }
    }
  }

  // contact page css
  .contact__card__wrapper {
    .contact-personalInfo-wrapper {
      .right-tabContant-wrapper {
        width: 100%;
        padding: 0 !important;
      }
      
    }
  }
 
  // contact page css end
}
// d-css-end

// HELLO
em-emoji-picker {
  width: 100%;    
// --background-rgb: 85, 170, 255;
//   --border-radius: 24px;
//   --category-icon-size: 24px;
//   --color-border-over: rgba(0, 0, 0, 0.1);
//   --color-border: rgba(0, 0, 0, 0.05);
//   --font-family: 'Comic Sans MS', 'Chalkboard SE', cursive;
//   --font-size: 20px;
//   --rgb-accent: 255, 105, 180;
//   --rgb-background: 262, 240, 283;
//   --rgb-color: 102, 51, 153;
//   --rgb-input: 255, 235, 235;
//   --shadow: 5px 5px 15px -8px rebeccapurple;

//   height: 50vh;
//   min-height: 400px;
//   max-height: 800px;
}

.linkAsText{
  color: #6e6b7b;
}

.digital-form-response {
  width: 100%;
  text-align: center !important;
}

.digital-form-label {
  font-size: 20px !important;
}


.font-16-4 {
  .mention-wrapper__control{
    div{
      font-size: 16.4px;
      top: 4px;
    }
    input{
      top: 4px !important;
    }
  }
}
.e-toolbar-wrapper{
  height: auto !important;
}

// Tracking Dashboard

.tracker-cards {
  background: rgba(163, 219, 89, 0.12) !important;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  .form-label {
    color: #5e5873;
  }
}

.form-tracking-close{
  position: absolute;
  right: -5px;
  top: -5px;
  color: #5e5873;
}
@media(max-width: 767px){
  .tracker-cards {
    background: rgba(163, 219, 89, 0.06) !important;
}
.app-content {
  height: calc(100vh - 70px) !important;
  .content-wrapper {
    // height: 100%;
    >div, .chatWrapper {
      // height: 100%;
    }
  }
}
}

[dir='ltr'] .spinner-border {
  border-right-color: transparent;
  animation: 0.75s linear infinite spinner-border-ltr;
}

.journey-details-title{
  p {
    font-size: larger;
    display: flex; 
    align-items: center;
    gap: 10px;
    min-width: 300px;
    b {
      display: flex; 
      align-items: center;
      gap: 8px;
      color: var(--primaryColor);
    }
  }
}


// Mobile Dashboard View

.mobile-dashboard-view {
  padding: 15px 15px;
  border: 1px solid #cccccc;
  margin: 20px 0;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  min-height: 140px;
  .mb-dashboard-icon {
    width: 60px;
    display: flex;
    align-items: center;
  }
  .mb-dashboard-con {
    width: calc(100% - 60px);
    padding-left: 20px;
    .form-label {
      font-size: 25px;
      color: var(--primaryColor);
    }
    .arw-img {
      display: block;
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }
}

.contact-companies-select-container {
  position: relative;
}

.contact-companies-select-container .action-btn.close-btn {
  position: absolute;
  bottom: 27px;
  z-index: 10;
  right: -10px !important;
  cursor: pointer;
}
.tracking-picker {
  min-width: 250px;
}
.tracking-picker .flatpickr-calendar.static {
  right: 0
}

@media(max-width: 767px){
  .contact-companies-select-container .action-btn.close-btn {
    left: inherit !important;
    right: -12px !important;
  }
}

.shared-spreadsheet-page .single-note-inner-page {
  width: 100%;
}

.indented-item {
  margin-left: 20px; /* Adjust value as needed */
  padding-left: 20px; /* Optional, for more control */
  font-size: 14px; /* Optional, make it slightly smaller for better hierarchy */
}

.more-options-dropdown-contact .dropdown-menu {
  max-height: unset; /* Prevents unnecessary height limits */
  overflow-y: hidden; /* Prevents vertical scrollbars */
  overflow-x: hidden; /* Prevents horizontal scrollbars */
}
.addGroupMember__modal .searchWrapper {
  top: 0;
}