// auth-page-wrapper
.auth-page-wrapper{
    display:flex;
    flex-wrap:wrap;
    width:100vw;
    height:100dvh;
    .left-wrapper,
    .right-wrapper{
      width:50%;
      height:100%;
      position: relative;
    }
    .left-wrapper{
      padding:150px 20px 60px 20px;
      .inner-wrapper{
        height:100%;
        overflow-y:auto;
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        justify-content:center
      }
      .logo-wrapper{
        position:absolute;
        top:20px;
        left:20px;
        .logo-link{
          display:block;
          width:200px;
          svg{
            width:100%;
          }
        }
      }
      .illustration-img-wrapper{
        display:flex;
        align-items:center;
        justify-content:center;
        .login-illustration-img{
          width:600px;
          max-width:100%;
        }
      }
    }
    .right-wrapper{
      padding:40px 0px;
      background-color:#ffffff;
      border-top-left-radius:26px;
      border-bottom-left-radius:26px;
      overflow-y:auto;
      .logo-wrapper{
        display:none;
        .logo-link{
          display:block;
          width:200px;
          svg{
            width:100%;
          }
        }
      }
      .inner-wrapper{
        width:100%;
        min-height:100%;
        padding:0px 30px;
        display:flex;
        align-items:center;
        justify-content:center;
      }
      a{
        display:inline-block;
        &:hover{
          text-decoration:underline;
          color:var(--primaryColorDark);
        }
      }
      .cn-wrapper{
        width:550px;
        max-width:100%;
        margin:0px auto;
        .header-wrapper{
          margin-bottom:25px;
          .header-inner-wrapper{
            width:400px;
            max-width:100%;
          }
          .title{
            font-size:30px;
            font-weight:700;
            color:#000000;
            margin-bottom:13px;
          }
          .normal-text{
            font-size:18px;
            line-height:26px;
          }
        }
      }
      .auth-form{
        .submit-btn{
          margin-top:20px;
        }
      }
      .auth-right-footer{
        text-align:center;
        margin-top:20px;
        font-size:16px;
      }
      .horizontal-wizard{
        margin-bottom:0px !important;
        .bs-stepper {
          box-shadow: unset;
          .bs-stepper-header {
            position: relative;
            justify-content:flex-start;
            border-bottom: none;
            padding:0px 0px;
            & > * {
              position: relative;
              z-index: 2;
            }
            .step {
              margin: 0px;
              .step-trigger {
                display: inline-flex;
                align-items: center;
                .bs-stepper-box {
                  width: 40px;
                  height: 40px;
                  padding: 0px;
                  font-size: 16px;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  background-color: #dfdfdf;
                  color: #8c8c8c;
                  box-shadow:unset;
                }
                .bs-stepper-label {
                  margin: 0px;
                  padding-left: 12px;
                  .bs-stepper-title {
                    font-size: 15px;
                    font-weight: 600;
                    color: #797979;
                    margin-bottom:5px;
                  }
                  .bs-stepper-subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    color: #828282;
                  }
                }
              }
              &.active {
                .step-trigger {
                  .bs-stepper-box {
                    color: #000000;
                  }
                  .bs-stepper-label {
                    .bs-stepper-title {
                      color: #000000;
                    }
                  }
                }
              }
              &.crossed {
                .step-trigger {
                  opacity: 1;
                  .bs-stepper-box {
                    background-color: var(--primaryColor);
                    font-size: 0px;
                    position: relative;
                    &::before {
                      content: '';
                      position: absolute;
                      top: calc(50% - 2px);
                      left: 50%;
                      transform: translate(-50%, -50%) rotate(-45deg);
                      width: 16px;
                      height: 9px;
                      border-left: 3px solid #000000;
                      border-bottom: 3px solid #000000;
                    }
                  }
                  .bs-stepper-label {
                    .bs-stepper-title {
                      color: #000000;
                    }
                  }
                }
              }
            }
            .line {
              padding: 0px;
              border-bottom: 2px dashed #cacaca;
              width: 60px;
              flex: unset;
              margin-left: 18px;
              margin-right: 18px;
              position: relative;
              svg {
                display: none;
              }
            }
            .step.crossed + .line {
              border-bottom: 2px dashed var(--primaryColor);
            }
          }
          .bs-stepper-content{
            padding:25px 0px 0px 0px !important;
          }
        }
      }
      .auth-login-form{
        margin-top:0px !important;
        .company-logo-label{
          margin-bottom:8px;
        }
        .submit-btn-wrapper{
          margin-top:20px;
          .btn-next{
            width:100% !important;
          }
        }
        .submit-btn-prev-submit-wrapper{
          margin-top:20px;
          display:flex;
          flex-wrap:wrap;
          align-items:center;
          justify-content:space-between;
          .btn-prev,
          .admin-detail-save-btn{
            width:calc(50% - 6px) !important;
          }
        }
      }
      .thank-you-wrapper{
        .icon-wrapper{
          width:70px;
          height:70px;
          position: relative;
          padding:12px;
          margin-bottom:15px;
          svg{
            width:100%;
            height:100%;
            color:var(--primaryColor);
          }
          &:before{
            content:"";
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            border-radius:50%;
            background-color:var(--primaryColor);
            opacity:0.10;
          }
        }
        .normal-text{
          font-size:16px;
          line-height:26px;
        }
        .submit-btn-wrapper{
          margin-top:20px;
        }
      }
      .thank-you-wrapper + .auth-right-footer{
        text-align:left;
      }
      .unsubscribed-text{
        font-size:18px;
        color:var(--primaryColorDark);
        display:flex;
        align-items:center;
        svg{
          width:20px;
          height:20px;
          color:var(--primaryColorDark);
          margin-right:8px;
        }
      }
      .email-verify-text{
        font-size:22px;
        color:var(--primaryColorDark);
        display:flex;
        align-items:center;
        svg{
          width:24px;
          height:24px;
          color:var(--primaryColorDark);
          margin-right:8px;
        }
      }
      .error-cn-msg{
        font-size:22px;
        color:#ea5455;
        display:flex;
        align-items:center;
        svg{
          width:24px;
          height:24px;
          color:#ea5455;
          margin-right:8px;
        }
      }
      .normal-text-verify-acc{
        font-size:16px;
        line-height:26px;
        a{
          color:var(--primaryColor);
          &:hover{
            color:var(--primaryColorDark);
            text-decoration:underline;
          }
        }
      }
    }
  }
  // auth-page-wrapper-end

@media only screen and (max-width: 1199px){
    // auth-page-wrapper
  .auth-page-wrapper{
    .right-wrapper{
      .horizontal-wizard{
        .bs-stepper{
          .bs-stepper-header{
            flex-direction: column;
            .line{
              display:none;
            }
            .step{
              width:100%;
              .step-trigger{
                padding:0.5rem 0 !important;
                width: 100%;
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
  // auth-page-wrapper-end
}

@media only screen and (max-width: 991px){
  // auth-page-wrapper
  .auth-page-wrapper{
    .imgupload-dropzon{
      background-color:white;
      align-items:flex-start;
      .left-preview{
        width:52px;
        height:52px;
        .fileIcon-wrapper{
          padding:10px 10px;
        }
      }
      .right-details {
        width: calc(100% - 52px);
        .title {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 4px;
        }
        .text {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .image-upload-wrapper {
          .upload-btn,
          .reset-btn {
            font-size: 12px;
            padding: 8px 13px 9px 13px;
          }
          .upload-btn {
            margin-right: 8px !important;
          }
        }
      }
    }
  }
  // auth-page-wrapper-end
}

@media only screen and (max-width: 767px){
  // auth-page-wrapper
  .auth-page-wrapper{
    // margin-top: -65px;
    background-color:#ffffff;
    height:calc(100dvh - 66px);
    .left-wrapper,
    .right-wrapper{
      width:100%;
    }
    .left-wrapper{
      padding:40px 20px 40px 20px;
      background-color:#f7f6f0;
      height:auto;
      .inner-wrapper{
        height:auto;
      }
      .logo-wrapper{
        display:none;
      }
    }
    .right-wrapper{
      order:-1;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding:25px 20px 40px 20px;
      height:auto;
      flex-wrap:wrap;
      .inner-wrapper{
        padding:0px 0px;
        min-height:unset;
      }
      .logo-wrapper{
        display:block;
        width:100%;
        margin-bottom:20px;
        .logo-link{
          width: 160px;
        }
      }
      .cn-wrapper{
        width:100%;
        .header-wrapper{
          .title{
            font-size:24px;
            margin-bottom: 9px;
          }
          .normal-text{
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  // auth-page-wrapper-end
}